import type { SlotProps } from "@radix-ui/react-slot";
import type { VariantProps } from "cva";
import { cva } from "cva";
import dayjs from "dayjs";
import type { MouseEvent } from "react";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import type {
  DateRange,
  DayPickerMultipleProps,
  DayPickerRangeProps,
  DayPickerSingleProps,
} from "react-day-picker";
import {
  isDayPickerMultiple,
  isDayPickerRange,
  isDayPickerSingle,
} from "react-day-picker";
import {
  PiCalendarBlankBold,
  PiCaretLeftBold,
  PiCaretRightBold,
} from "react-icons/pi";

import { cn } from "../../lib/utils";
import { Button } from "../Button/Button";
import { IconButton } from "../Button/IconButton";
import { Calendar } from "../Calendar/Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";

const readOnlyStyles =
  "read-only:tw-bg-primaryDim-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
const lockedStyles =
  "read-only:tw-bg-primary-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
const containerStyles =
  "tw-inline-flex tw-w-full tw-px-3 tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-cursor-pointer tw-bg-neutral-25 tw-transition-colors";
const fontStyles = "tw-text-sm tw-font-medium tw-font-sans tw-text-neutral-500";
const focusVisibleStyles =
  "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300";

const datePickerInputVariants = cva({
  base: [containerStyles, fontStyles, focusVisibleStyles],
  variants: {
    size: {
      sm: "tw-h-7",
      md: "tw-h-8",
      lg: "tw-h-9",
    },
  },
  defaultVariants: {
    size: "lg",
  },
});

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const MonthSelect = ({
  selected,
  onSelectMonth,
}: {
  selected: Date;
  onSelectMonth: (selectedMonth: Date) => void;
}) => {
  const [year, setYear] = useState(Number(selected.getFullYear()));

  return (
    <div className="tw-w-auto tw-p-5">
      <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-px-1">
        <IconButton
          size="lg"
          color="transparent"
          Icon={PiCaretLeftBold}
          onClick={() => setYear((value) => Number(value) - 1)}
        />
        <span className="tw-font-sans tw-text-sm tw-font-semibold tw-text-neutral-400">
          {year}
        </span>
        <IconButton
          size="lg"
          color="transparent"
          Icon={PiCaretRightBold}
          onClick={() => setYear((value) => Number(value) + 1)}
        />
      </div>
      <div className="tw-mt-4 tw-grid tw-grid-cols-3 tw-gap-1">
        {MONTHS.map((month, index) => (
          <Button
            color={
              selected.getMonth() === index &&
              Number(selected.getFullYear()) === year
                ? "secondary"
                : "grey"
            }
            onClick={() =>
              onSelectMonth(new Date(selected.setFullYear(year, index)))
            }
            className="tw-flex tw-h-8 tw-w-[5.5rem] tw-items-center tw-justify-center tw-rounded tw-px-7"
            key={month}
          >
            {month}
          </Button>
        ))}
      </div>
    </div>
  );
};

type DatePickerProps = VariantProps<typeof datePickerInputVariants> & {
  className?: string;
  placeholder?: string;
  locked?: boolean;
  readOnly?: boolean;
  closeOnDateSelect?: boolean;
  calendarProps:
    | DayPickerSingleProps
    | DayPickerMultipleProps
    | DayPickerRangeProps;
  dateFormat?: string;
  showHeader?: boolean;
};

const formatDate = (
  calendarProps: DatePickerProps["calendarProps"],
  dateFormat: string,
) => {
  if (!calendarProps.selected) {
    return;
  }

  if (isDayPickerSingle(calendarProps)) {
    return dayjs(calendarProps.selected).format(dateFormat);
  }

  if (isDayPickerMultiple(calendarProps)) {
    return calendarProps.selected
      .map((d) => dayjs(d).format(dateFormat))
      .join(", ");
  }

  if (isDayPickerRange(calendarProps)) {
    const { from, to } = calendarProps.selected;

    if (!from) {
      return;
    }

    return `${dayjs(from).format(dateFormat)} - ${
      to ? dayjs(to).format(dateFormat) : ""
    }`;
  }
};

const getSelectedDate = (calendarProps: DatePickerProps["calendarProps"]) => {
  if (isDayPickerSingle(calendarProps)) {
    return calendarProps.selected;
  }

  if (isDayPickerMultiple(calendarProps) && calendarProps.selected?.length) {
    return calendarProps.selected[0];
  }

  if (isDayPickerRange(calendarProps)) {
    return calendarProps.selected?.from;
  }

  return;
};

type Props = DatePickerProps &
  SlotProps & { defaultOpen?: boolean; onClose?: () => void };

const DatePicker = forwardRef<HTMLButtonElement, Props>(
  (
    {
      size,
      locked,
      readOnly,
      closeOnDateSelect,
      placeholder = "- Add Date -",
      calendarProps,
      // Since we are in the U.S., we default to MM/dd/yyyy
      dateFormat = "MM/DD/YYYY",
      className,
      showHeader,
      defaultOpen,
      onClose,
      ...slotProps
    },
    ref,
  ) => {
    const isMountedRef = useRef(false);

    const disabled = locked || readOnly;
    const selectedDate = getSelectedDate(calendarProps);
    const [open, setOpen] = useState(defaultOpen || false);
    const [month, setMonth] = useState(selectedDate);
    const [showMonthSelect, setShowMonthSelect] = useState(false);

    useEffect(() => {
      if (open) {
        setShowMonthSelect(false);
        setMonth(selectedDate);
        isMountedRef.current = true;
      } else if (isMountedRef.current && !open && onClose) {
        onClose();
      }
    }, [open]);

    useEffect(() => {
      if (closeOnDateSelect && open) {
        setOpen(false);
      }
    }, [calendarProps.selected]);

    const handleTodayClick = (event: MouseEvent) => {
      if (!calendarProps.onSelect) {
        return;
      }

      setShowMonthSelect(false);

      if (isDayPickerSingle(calendarProps)) {
        calendarProps.onSelect(new Date(), new Date(), {}, event);
        setMonth(new Date());
      }

      if (isDayPickerMultiple(calendarProps) && calendarProps.onSelect) {
        calendarProps.onSelect([new Date()], new Date(), {}, event);
        setMonth(new Date());
      }

      if (isDayPickerRange(calendarProps) && calendarProps.onSelect) {
        calendarProps.onSelect({ from: new Date() }, new Date(), {}, event);
        setMonth(new Date());
      }
    };

    const handleSelectMonth = (selectedMonth: Date) => {
      setMonth(selectedMonth);
      setShowMonthSelect(false);
    };

    return (
      <Popover modal open={open} onOpenChange={setOpen}>
        <PopoverTrigger
          disabled={disabled}
          className={cn(
            {
              [lockedStyles]: locked,
              [readOnlyStyles]: readOnly,
              "tw-cursor-default": disabled,
            },
            className,
          )}
          ref={ref}
          asChild
          {...slotProps}
        >
          <button
            aria-expanded={open}
            className={cn(datePickerInputVariants({ size }), {
              "tw-border-neutral-100": !open,
              "tw-border-primary-300": open,
              "tw-text-neutral-200": !getSelectedDate(calendarProps),
            })}
          >
            <div className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
              {formatDate(calendarProps, dateFormat) || placeholder}
            </div>
            {!disabled && (
              <PiCalendarBlankBold
                className={cn(
                  "tw-ml-auto tw-h-4 tw-w-4 tw-shrink-0 tw-text-neutral-500 tw-transition-transform",
                  { "tw-text-neutral-200": disabled },
                )}
              />
            )}
          </button>
        </PopoverTrigger>
        <PopoverContent className="tw-w-auto tw-py-1" align="start">
          {showMonthSelect ? (
            <MonthSelect
              selected={selectedDate || new Date()}
              onSelectMonth={handleSelectMonth}
            />
          ) : (
            <>
              {showHeader && (
                <div className="tw-border-y-0 tw-border-b tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-generals-underline tw-pb-2 tw-pl-4 tw-pt-2.5">
                  <div className="tw-mb-2 tw-text-base tw-font-semibold tw-text-primaryDim-300">
                    Selected Date
                  </div>
                  <div className="tw-font-medium tw-text-neutral-500">
                    {(selectedDate && dayjs(selectedDate).format(dateFormat)) ||
                      "No Date Selected"}
                  </div>
                </div>
              )}
              <Calendar
                initialFocus
                month={month}
                onMonthChange={handleSelectMonth}
                onClickMonth={() => setShowMonthSelect(true)}
                {...calendarProps}
              />
            </>
          )}
          <div className="tw-flex tw-w-full tw-items-center tw-justify-center tw-pb-3">
            <Button onClick={handleTodayClick}>{`Set ${
              showMonthSelect ? "This Month" : "Today"
            }`}</Button>
          </div>
        </PopoverContent>
      </Popover>
    );
  },
);

DatePicker.displayName = "DatePicker";

export { DatePicker, type DateRange };
