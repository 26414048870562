import { observer } from "mobx-react";
import { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import UserSettingsRoot from "@/components/settings/UserSettingsRoot";
import { MultiFactorAuth } from "@/features/auth";
import { CreateCompany, InviteTeam } from "@/features/company";
import Legals from "@/features/legal/pages/Legals";
import AuditTrail from "@/features/misc/AuditTrail";
import ProjectRoutes from "@/features/projects/pages/ProjectRoutes";
import DuplicateWorkspacePage from "@/features/workspace/pages/DuplicateWorkspacePage";
import { ZendeskConnect } from "@/features/zendesk";

import Dashboard from "../components/dashboard/Dashboard";
import DataLoading from "../components/DataLoading";
import IdleTimer from "../components/IdleTimer";
import MainContent from "../components/MainContent";
import WorkspaceReports from "../components/reports/WorkspaceReports";
import CompanySettingsRoot from "../components/settings/CompanySettingsRoot";
import CompanyTagsRoot from "../components/settings/CompanyTagsRoot";
import NotificationsCenter from "../components/settings/notificationsCenter/NotificationsCenter";
import ZendeskConfiguration from "../components/settings/zendesk-configuration/zendeck-configuration";
import SettingWorkspace from "../components/settingWorkspace/SettingWorkspace";
import ComplianceTemplates from "../components/table/compliance-templates/ComplianceTemplates";
import { useMainStore } from "../contexts/Store";
import { AccountsRoutes } from "../features/accounts/accounts-routes";
import { ThanksForSigningUp } from "../features/auth/pages/ThanksForSigningUp";
import { PickModules } from "../features/company/pages/PickModules";
import { HomeRoutes } from "../features/home/pages/home-routes";
import { PROJECTS_ROUTES } from "../features/projects/routes";
import { MyTasksRoutes } from "../features/tasks/pages/my-task-routes";
import { myTaskRoutes } from "../features/tasks/routes";
import { WebsiteMonitoringRoutes } from "../features/website-monitoring/pages/website-monitoring-routes";
import { websiteMonitoringRoutes } from "../features/website-monitoring/routes";
import { FEATURE_FLAG_ID } from "../stores/types/feature-flag-types";
import { TopLevelModule } from "../stores/types/module-workspaces-types";
import { appRoutes } from "./app-routes";
import RecordRedirectRoutes from "./record-redirect/redirect-routes";

const TrainingRoutes = lazy(
  () => import("../features/training/training-routes"),
);

const AttestationRoutes = lazy(() => import("@features/attestations"));
const WebMonitoringRoutes = lazy(() => import("@features/web-monitoring"));
const QuestionnairesRoutes = lazy(() => import("@features/questionnaires"));

function AuthenticatedRoutes() {
  const mainStore = useMainStore();
  const { pathname } = useLocation();

  const isZendeskModuleAdded = mainStore.moduleWorkspaces.isModuleAdded(
    TopLevelModule.ZENDESK,
  );

  const { workspaceID } = mainStore.context;
  const { canManageSettings, canViewReports, canManageAccounts } =
    mainStore.userPermissions;

  const websiteMonitoringFeatureEnabled = mainStore.featureFlags.getIsEnabled(
    FEATURE_FLAG_ID.COM_WEBSITE_MONITORING,
  );

  const newWebMonitoringEnabled = mainStore.featureFlags.getIsEnabled(
    FEATURE_FLAG_ID.GENG_SEI_WEB_MONITORING,
  );

  const newQuestionnairesEnabled = mainStore.featureFlags.getIsEnabled(
    FEATURE_FLAG_ID.GENG_SEI_WEB_MONITORING,
  );

  const newTrainingFeatureEnabled = mainStore.featureFlags.getIsEnabled(
    FEATURE_FLAG_ID.GENG_TRAINING_MODULE_REVAMP,
  );

  const newAttestationFeatureEnabled = mainStore.featureFlags.getIsEnabled(
    FEATURE_FLAG_ID.GENG_ATTESTATION_MODULE_REVAMP,
  );

  return (
    <DataLoading>
      <IdleTimer />
      <Switch>
        {/* Removes trailing slash */}
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

        <Route exact path="/">
          <Redirect to={`/workspaces/${workspaceID}/home`} />
        </Route>

        <Route path="/workspaces/:workspace_id/home">
          <MainContent>
            <HomeRoutes />
          </MainContent>
        </Route>

        <Route path="/workspaces/:workspace/modules">
          <MainContent>
            <Dashboard />
          </MainContent>
        </Route>

        <Route path="/user">
          <MainContent>
            <UserSettingsRoot />
          </MainContent>
        </Route>

        <Route path={`/workspaces/:workspace_id${PROJECTS_ROUTES.base}`}>
          <MainContent>
            <ProjectRoutes />
          </MainContent>
        </Route>

        <Route path={myTaskRoutes.base}>
          <MainContent>
            <MyTasksRoutes />
          </MainContent>
        </Route>

        <Route path="/:themis_record_type/audit_trail">
          <MainContent>
            <AuditTrail />
          </MainContent>
        </Route>

        <Route path="/:themis_record_type/:instance_id/audit_trail">
          <MainContent>
            <AuditTrail isRecordView />
          </MainContent>
        </Route>

        {websiteMonitoringFeatureEnabled && (
          <Route path={`${websiteMonitoringRoutes.base}`}>
            <MainContent>
              <WebsiteMonitoringRoutes />
            </MainContent>
          </Route>
        )}

        {canViewReports && (
          <Route path="/workspace-reports/:moduleName">
            <MainContent>
              <WorkspaceReports />
            </MainContent>
          </Route>
        )}

        {canViewReports && (
          <Route path="/workspace-reports">
            <MainContent>
              <WorkspaceReports />
            </MainContent>
          </Route>
        )}

        {canManageAccounts && (
          <Route path={appRoutes.accounts}>
            <MainContent>
              <AccountsRoutes />
            </MainContent>
          </Route>
        )}

        {newTrainingFeatureEnabled && (
          <Route path={appRoutes.training}>
            <MainContent>
              <Suspense>
                <TrainingRoutes />
              </Suspense>
            </MainContent>
          </Route>
        )}

        {newAttestationFeatureEnabled && (
          <Route path={appRoutes.attestations}>
            <MainContent>
              <Suspense>
                <AttestationRoutes />
              </Suspense>
            </MainContent>
          </Route>
        )}

        {newWebMonitoringEnabled && (
          <Route path={appRoutes.webMonitoring}>
            <MainContent>
              <Suspense>
                <WebMonitoringRoutes />
              </Suspense>
            </MainContent>
          </Route>
        )}

        {newQuestionnairesEnabled && (
          <Route path={appRoutes.questionnaires}>
            <MainContent>
              <Suspense>
                <QuestionnairesRoutes />
              </Suspense>
            </MainContent>
          </Route>
        )}

        <Route path={appRoutes.recordRedirect}>
          <MainContent>
            <Suspense>
              <RecordRedirectRoutes />
            </Suspense>
          </MainContent>
        </Route>

        <Route path="/tags">
          <MainContent>
            <CompanyTagsRoot />
          </MainContent>
        </Route>

        <Route path="/resources/compliance-templates">
          <MainContent>
            <ComplianceTemplates />
          </MainContent>
        </Route>

        <Route path="/notifications">
          <MainContent>
            <NotificationsCenter />
          </MainContent>
        </Route>

        {isZendeskModuleAdded && canManageSettings && (
          <Route path="/settings/integrations/zendesk/connect">
            <ZendeskConnect />
          </Route>
        )}

        {isZendeskModuleAdded && canManageSettings && (
          <Route
            exact
            path={[
              "/settings/integrations/zendesk/configuration",
              "/settings/integrations/zendesk/configuration/:id",
            ]}
          >
            <ZendeskConfiguration />
          </Route>
        )}

        <Route path="/settings">
          <MainContent>
            <CompanySettingsRoot />
          </MainContent>
        </Route>

        <Route path="/create-company">
          <CreateCompany />
        </Route>

        <Route path="/pick-modules">
          <PickModules />
        </Route>

        <Route path="/invite-team">
          <InviteTeam />
        </Route>

        <Route path="/duplicate-workspace">
          <DuplicateWorkspacePage />
        </Route>

        <Route path="/multi-factor-auth">
          <MultiFactorAuth />
        </Route>

        <Route path="/new-workspace">
          <SettingWorkspace />
        </Route>

        <Route path="/legals">
          <Legals />
        </Route>

        <Route path="/thanks-for-signing-up">
          <ThanksForSigningUp />
        </Route>
      </Switch>
    </DataLoading>
  );
}

export default observer(AuthenticatedRoutes);
