import { Skeleton } from "../../feedback/skeleton/skeleton";
import { Stack } from "../../layout/stack/stack";
import type { AvatarProps } from "../avatar/avatar";
import { Avatar } from "../avatar/avatar";
import { Typography } from "../typography/typography";

export interface UserLabelProps {
  initials?: string | null;
  fullName: string;
  colorSeed?: number | string | null;
  size?: AvatarProps["size"];
  loading?: boolean;
}

export function UserLabel({
  initials,
  fullName,
  colorSeed,
  loading,
  size,
}: UserLabelProps) {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {initials && (
        <Avatar size={size} colorSeed={colorSeed ?? fullName} loading={loading}>
          {initials}
        </Avatar>
      )}

      {loading ? (
        <Skeleton width="6rem" />
      ) : (
        <Typography color="inherit">{fullName}</Typography>
      )}
    </Stack>
  );
}
