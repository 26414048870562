import { faker } from "@faker-js/faker";

import type { RecordTypes } from "../models/RecordTypes";

export function createRecordTypes(): NonNullable<RecordTypes> {
  return faker.helpers.arrayElement<any>([
    "Account",
    "Contract",
    "Finding",
    "ModuleAssessment",
    "MonitoringGroup",
    "MonitoringAsset",
    "MonitoringFinding",
    "MonitoringGroupAsset",
    "MonitoringGroupAssetChild",
    "MonitoringGroupAssetChildResult",
    "Attestation",
    "Project",
    "Record",
    "Task",
    "Document",
    "Training",
  ]);
}
