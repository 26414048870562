import type { DataGridState } from "@themis/ui-library/components/data-grid/state/data-grid-state";
import type { ReactElement } from "react";
import { useIntl } from "react-intl";

import { SideBar } from "@/components/side-bar/side-bar";

interface ProjectSideBarProps {
  dataGridState: DataGridState;
  updateDataGridState: (params: Partial<DataGridState>) => void;
}

export function ProjectSideBar({
  dataGridState,
  updateDataGridState,
}: ProjectSideBarProps): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <SideBar
      dataGridState={dataGridState}
      recordType="projects"
      title={formatMessage({ defaultMessage: "Projects" })}
      updateDataGridState={updateDataGridState}
      views={[]}
    />
  );
}
