import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { Icon } from "../../Elements";

interface Props {
  moduleWorkspaceID: number | null;
}

function LibraryImport({ moduleWorkspaceID }: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);

  // Functions
  function onPopupOpen() {
    setShowPopup(true);
  }

  function onPopupClose() {
    setShowPopup(false);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleImport(event) {
    setShowPopup(false);
    const [file] = event.target.files;
    mainStore.riskRegisters.importLibrary(moduleWorkspaceID, file);
  }

  async function downloadTemplate() {
    const spreadSheetData =
      await mainStore.riskRegisters.downloadLibraryTemplate(moduleWorkspaceID);
    if (!spreadSheetData) {
      return;
    }

    const link = document.createElement("a");
    const blob = new Blob([new Uint8Array(spreadSheetData)], {
      type: "application/octet-stream",
    });
    link.href = URL.createObjectURL(blob);
    link.download = "rr_library_template.xlsx";
    link.click();
  }

  // elements
  const renderTrigger = (
    <button
      className={classNames("import-button library-import", {
        active: showPopup,
      })}
      data-testid="rr-library-import-trigger"
    >
      <Icon name="import" color="generalWhite" size="de" />
      Import
    </button>
  );

  const downloadTemplateButton = (
    <div>
      <button onClick={downloadTemplate}>
        <Icon name="download" color="generalWhite" size="de" />
        Download XLSX Template
      </button>
    </div>
  );

  const directUploadProvider = (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <DirectUploadProvider
      render={() => (
        <div className="import-csv-file-select">
          <button>
            <Icon name="upload" color="generalWhite" size="de" />
            Import CSV/XLSX File
          </button>
          <input
            type="file"
            title="Uploading..."
            onChange={(event) => handleImport(event)}
            data-testid="rr-library-import-upload-input"
          />
        </div>
      )}
    />
  );

  const renderPopupContent = (
    <div
      className="table-dropdown import-dropdown"
      data-testid="rr-library-import-popup"
    >
      <div className="import-csv-section">
        <div className="import-csv-section-title">
          <div className="users-circle">1</div>
          <h3>Download XLSX File</h3>
        </div>
        {downloadTemplateButton}
      </div>
      <div className="import-csv-section">
        <div className="import-csv-section-title">
          <div className="users-circle">2</div>
          <h3>Add / Edit info in XLSX template</h3>
        </div>
        <div className="import-csv-blurb">
          The first line in your CSV/XLSX file must contain the column headers
          as shown in the template. Any custom columns need to be manually added
          to the template. Only user editable cells can be imported.
        </div>
      </div>
      <div className="import-csv-section">
        <div className="import-csv-section-title">
          <div className="users-circle">3</div>
          <h3>Upload CSV/XLSX File</h3>
        </div>
        {directUploadProvider}
      </div>
    </div>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={onPopupOpen}
      onClose={onPopupClose}
      keepTooltipInside
      nested
    >
      {renderPopupContent}
    </Popup>
  );
}

export default observer(LibraryImport);
