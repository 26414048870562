import type { SelectionChangedEvent } from "ag-grid-community";
import type { CustomHeaderProps } from "ag-grid-react";
import type { ReactElement } from "react";
import { useEffect, useState } from "react";

import { Checkbox } from "../../inputs/checkbox/checkbox";
import { Box } from "../../layout/box/box";

export function SelectionHeader({
  api,
  column,
}: CustomHeaderProps): ReactElement | null {
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const rowCount = api.getDisplayedRowCount();

  useEffect(() => {
    const handleSelectionChanged = (event: SelectionChangedEvent) => {
      setSelectedRows(api.getSelectedRows());
    };
    api.addEventListener("selectionChanged", handleSelectionChanged);
    return () => {
      api.removeEventListener("selectionChanged", handleSelectionChanged);
    };
  }, [api, column]);

  const indeterminate =
    selectedRows.length > 0 && selectedRows.length < api.getDisplayedRowCount();
  const checked = selectedRows.length === api.getDisplayedRowCount();

  /**
   * Must be done on rows individually for it to work with infinite row model
   */
  const handleCheckboxClick = () => {
    if (checked) {
      api.forEachNode((node) => {
        node.setSelected(false);
      });
    } else {
      api.forEachNode((node) => {
        node.setSelected(true);
      });
    }
  };

  if (rowCount < 1) {
    return null;
  }

  return (
    <Box sx={{ marginX: "-4px" }}>
      <Checkbox
        size="small"
        indeterminate={indeterminate}
        checked={checked}
        sx={{
          padding: "4px",
        }}
        onClick={handleCheckboxClick}
      />
    </Box>
  );
}
