import { AssigneesSection } from "@themis/shared/components/assignees-section/assignees-section";
import { ControlledTextField } from "@themis/ui-library/components/form/controlled-text-field/controlled-text-field";
import { Grid2 } from "@themis/ui-library/components/layout/grid2/grid2";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";

import { DateFormField } from "@/components/form-fields/date-form-field/date-form-field";
import { UserSelectFormField } from "@/components/form-fields/user-select-form-field/user-select-form-field";

import type { ValidationSchema } from "../hooks/use-attestation-detail-validation-schema/use-attestation-detail-validation-schema";

export function AttestationFormFields() {
  const { formatMessage } = useIntl();

  const form = useFormContext<ValidationSchema>();

  return (
    <Grid2 container columnSpacing={3} rowSpacing={2}>
      <Grid2 size={6}>
        <ControlledTextField
          required
          label={formatMessage({ defaultMessage: "Title" })}
          control={form.control}
          name="name"
        />
      </Grid2>

      <Grid2 size={6}>
        <DateFormField
          control={form.control}
          name="dueDate"
          label={formatMessage({ defaultMessage: "Due Date" })}
        />
      </Grid2>

      <Grid2 size={6}>
        <UserSelectFormField
          label={formatMessage({ defaultMessage: "Owner" })}
          control={form.control}
          name="ownerId"
          required
        />
      </Grid2>

      <Grid2 size={12}>
        <ControlledTextField
          label={formatMessage({ defaultMessage: "Description" })}
          control={form.control}
          name="description"
          multiline
        />
      </Grid2>

      <Grid2 size={12}>
        <ControlledTextField
          label={formatMessage({ defaultMessage: "Attestation Request" })}
          control={form.control}
          name="message"
          helperText={formatMessage({
            defaultMessage:
              "(This is the language a recipient will see when they view the Attestation request)",
          })}
          multiline
          minRows={2}
        />
      </Grid2>
      <Grid2 sx={(theme) => ({ marginTop: theme.spacing(2) })} size={12}>
        <AssigneesSection />
      </Grid2>
    </Grid2>
  );
}
