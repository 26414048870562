import { faker } from "@faker-js/faker";

import type {
  LegacyWebsiteMonitoringGroupResult200,
  LegacyWebsiteMonitoringGroupResultPathParams,
  LegacyWebsiteMonitoringGroupResultQueryResponse,
} from "../models/LegacyWebsiteMonitoringGroupResult";
import { createMonitoringGroupAssetChildResult } from "./createMonitoringGroupAssetChildResult";

export function createLegacyWebsiteMonitoringGroupResultPathParams(): NonNullable<LegacyWebsiteMonitoringGroupResultPathParams> {
  return { monitoring_group_result_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupResult200(): NonNullable<LegacyWebsiteMonitoringGroupResult200> {
  return { data: createMonitoringGroupAssetChildResult() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupResultQueryResponse(): NonNullable<LegacyWebsiteMonitoringGroupResultQueryResponse> {
  return { data: createMonitoringGroupAssetChildResult() };
}
