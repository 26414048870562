import { Button, Checkbox } from "@themis/ui";
import React, { useState } from "react";
import { defineMessages, useIntl } from "react-intl";

import { Divider } from "@/components/IconMenu";
import { useMainStore } from "@/contexts/Store";
import { duplicateOptions } from "@/stores/types/duplicate-option";
import type { DuplicateOption } from "@/stores/types/duplicate-option";

interface QaDuplicateRecordProps {
  recordVersionId: number;
  onPopupClose: () => void;
}

const duplicateOptionMessages = defineMessages<string>({
  [duplicateOptions.procedure_step_names]: {
    defaultMessage: "Procedure Step Names",
  },
  [duplicateOptions.document_names]: {
    defaultMessage: "Document Names",
  },
  [duplicateOptions.sample_names]: {
    defaultMessage: "Sample Names",
  },
});

export function QaDuplicateRecord({
  recordVersionId,
  onPopupClose,
}: QaDuplicateRecordProps) {
  const mainStore = useMainStore();
  const { formatMessage } = useIntl();

  const [duplicateOptionsMap, setDuplicateOptionsMap] = useState<
    Record<string, boolean>
  >(
    Object.keys(duplicateOptions).reduce(
      (acc, key) => ({
        ...acc,
        [key]: true,
      }),
      {},
    ),
  );

  const checkboxes = Object.entries(duplicateOptions).map(([_, value]) => ({
    id: value,
    label: formatMessage(duplicateOptionMessages[value]),
  }));
  const selectedOptions = Object.keys(duplicateOptionsMap).filter(
    (key) => duplicateOptionsMap[key],
  ) as DuplicateOption[];

  const toggleCheckbox = (id: string) => {
    setDuplicateOptionsMap((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  async function duplicateRecord() {
    onPopupClose();
    await mainStore.recordVersions.duplicate(recordVersionId, selectedOptions);
  }

  return (
    <div className="tw-max-w-64 tw-rounded-xl tw-bg-white tw-py-2 tw-shadow-lg">
      <div className="tw-px-4 tw-pb-2 tw-text-sm tw-font-semibold tw-text-primaryDim-300">
        {formatMessage({
          defaultMessage: "Select sub items to include in the duplicated Test",
        })}
      </div>
      <div className="tw-flex tw-flex-col">
        {checkboxes.map(({ id, label }) => (
          <div
            key={id}
            className="tw-group tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-px-4 tw-py-2.5 tw-text-neutral-500 hover:tw-bg-primary-25 hover:tw-text-primaryDim-300"
            onClick={() => toggleCheckbox(id)}
          >
            <Checkbox
              color="secondary"
              size="md"
              checked={duplicateOptionsMap[id]}
            />
            <div className="tw-select-none tw-text-sm tw-text-inherit">
              {label}
            </div>
          </div>
        ))}
      </div>
      <Divider />
      <Button className="tw-ml-auto tw-mr-4 tw-flex" onClick={duplicateRecord}>
        {formatMessage({ defaultMessage: "Duplicate" })}
      </Button>
    </div>
  );
}
