import { http } from "msw";

import { createGetAttestersQueryResponse } from "../createGetAttesters";

export const getAttestersHandler = http.get(
  "*/attestations/:id/attesters",
  function handler(info) {
    return new Response(JSON.stringify(createGetAttestersQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
