import type { RecordLink, RecordTypePath, Relatable } from "@/api";
import { createRelatable } from "@/api/gen";

import { recordTypeToRelatableType } from "../../helpers";

interface GetIgnoredRecordsParams {
  recordLinksFrom: RecordLink[];
  recordLinksTo: RecordLink[];
  recordId: number;
  recordType: RecordTypePath;
}

export function getIgnoredRecords({
  recordLinksFrom,
  recordLinksTo,
  recordId,
  recordType,
}: GetIgnoredRecordsParams) {
  const currentRecordRelatable = createRelatable({
    id: recordId,
    relatable_type: recordTypeToRelatableType(recordType),
  });

  return recordLinksFrom
    .map((record) => record.outward_record)
    .concat(recordLinksTo.map((record) => record.record))
    .concat([currentRecordRelatable])
    .filter((record): record is Relatable => record !== null);
}
