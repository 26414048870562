import { useMutation } from "@tanstack/react-query";
import type { UseMutationOptions } from "@tanstack/react-query";

import { exportPdf } from "@/api/gen/axios/themisRecordsController";
import type {
  ExportPdfPathParams,
  ExportPdfQueryParams,
  ExportPdfQueryResponse,
} from "@/api/gen/models";

export function useExportPdf<TError = Error>(
  recordType: ExportPdfPathParams["record_type"],
  recordId: ExportPdfPathParams["record_id"],
  options?: Partial<
    UseMutationOptions<
      ExportPdfQueryResponse["data"],
      TError,
      ExportPdfQueryParams
    >
  >,
) {
  return useMutation({
    mutationFn: async (params) => {
      const response = await exportPdf(recordType, recordId, params);
      return response.data;
    },
    ...options,
  });
}
