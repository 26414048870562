import { faker } from "@faker-js/faker";

import type { ExtendedAttester } from "../models/ExtendedAttester";
import { createBaseAttester } from "./createBaseAttester";

export function createExtendedAttester(
  data?: NonNullable<Partial<ExtendedAttester>>,
): NonNullable<ExtendedAttester> {
  return Object.assign({}, createBaseAttester(), {
    status: faker.helpers.arrayElement<any>([
      "not_sent",
      "pending",
      "attested",
    ]),
    attested_at: faker.date.anytime().toISOString(),
    sent_date: faker.date.anytime().toISOString(),
    resend_count: faker.number.int(),
  });
}
