import { Breadcrumbs } from "@themis/ui";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";

import Loading from "@/components/Loading";

import { useAccount } from "../../../../api/queries/accounts/accounts";
import { useRecordLinks } from "../../../../api/queries/record-links/use-record-links";
import { ErrorContainer } from "../../../../components/ErrorContainer";
import { Header } from "../../../../components/Layout/Header";
import { PageContent } from "../../../../components/Layout/PageContent";
import { PageLayout } from "../../../../components/Layout/PageLayout";
import { RecordLinkTable } from "../../../../components/record-link-table/record-link-table";
import { useRouteWorkspaceId } from "../../../../hooks/use-route-workspace-id";
import { routes } from "../../routes";

export const AccountsReferencedIn = () => {
  const workspaceId = useRouteWorkspaceId();

  const params = useParams<{ accountId: string }>();
  const accountId = Number(params.accountId);

  const {
    data: accountData,
    isPending: isAccountPending,
    isError: isAccountError,
  } = useAccount({
    workspaceId,
    accountId,
  });

  const { formatMessage } = useIntl();

  const { data } = useRecordLinks("accounts", accountId);
  const recordLinksTo = data?.record_links_to ?? [];

  if (isAccountPending) {
    return (
      <PageContent>
        <Loading loadingLayout="small-table" />
      </PageContent>
    );
  }

  if (isAccountError) {
    return (
      <PageContent>
        <ErrorContainer
          backButtonProps={{
            linkTo: generatePath(routes.accounts.detail.recordView, {
              workspace_id: workspaceId,
              accountId,
            }),
          }}
        >
          {formatMessage({
            defaultMessage:
              "Could not load account records this account is referenced in",
          })}
        </ErrorContainer>
      </PageContent>
    );
  }

  return (
    <PageLayout>
      <Header
        title={
          <Breadcrumbs
            breadcrumbItems={[
              {
                label: "Accounts",
                to: generatePath(routes.accounts.overview, {
                  workspace_id: workspaceId,
                }),
              },
              {
                label: accountData.data.name,
                to: generatePath(routes.accounts.detail.recordView, {
                  workspace_id: workspaceId,
                  accountId,
                }),
              },
              {
                label: formatMessage({
                  defaultMessage: "Account Info - Referenced In",
                }),
              },
            ]}
          />
        }
      />
      <PageContent>
        <RecordLinkTable
          recordSelectDisabled={accountData.data.status === "inactive"}
          recordId={accountId}
          recordType="accounts"
          linkType="record"
          rows={recordLinksTo}
          showEmptyTable
        />
      </PageContent>
    </PageLayout>
  );
};
