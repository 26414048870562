import { faker } from "@faker-js/faker";

import type {
  LegacyWebsiteMonitoringGroups200,
  LegacyWebsiteMonitoringGroupsPathParams,
  LegacyWebsiteMonitoringGroupsQueryResponse,
} from "../models/LegacyWebsiteMonitoringGroups";
import { createLegacyMonitoringGroup } from "./createLegacyMonitoringGroup";

export function createLegacyWebsiteMonitoringGroupsPathParams(): NonNullable<LegacyWebsiteMonitoringGroupsPathParams> {
  return { company_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroups200(): NonNullable<LegacyWebsiteMonitoringGroups200> {
  return {
    data: faker.helpers.arrayElements([createLegacyMonitoringGroup()]) as any,
  };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupsQueryResponse(): NonNullable<LegacyWebsiteMonitoringGroupsQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createLegacyMonitoringGroup()]) as any,
  };
}
