import React from "react";
import {
  PiCaretLeftBold,
  PiCaretRightBold,
  PiDotsThreeOutlineFill,
} from "react-icons/pi";

import { cn } from "../../lib/utils";
import { Button } from "../Button/Button";
import { IconButton } from "../Button/IconButton";
import {
  ToggleButtonGroup,
  ToggleButtonGroupItem,
} from "../Button/ToggleButtonGroup";

export interface PaginationProps {
  page: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  className?: string;
  chunkSize?: number;
}

const DotsIcon = () => <PiDotsThreeOutlineFill className="tw-text-xs" />;
const CaretLeft = () => <PiCaretLeftBold className="tw-h-3.5 tw-w-3.5" />;
const CaretRight = () => <PiCaretRightBold className="tw-h-3.5 tw-w-3.5" />;

export function Pagination({
  page,
  totalPages,
  onPageChange,
  className,
  chunkSize = 5,
}: PaginationProps) {
  const chunkStart = Math.floor((page - 1) / chunkSize) * chunkSize + 1;
  const chunkEnd = Math.min(chunkStart + chunkSize - 1, totalPages);

  const position =
    totalPages - page < chunkSize
      ? chunkSize - (totalPages - page)
      : page % chunkSize || chunkSize;

  const handleSetPage = (newPage: number) => {
    if (newPage !== page) {
      onPageChange(newPage);
    }
  };

  const handlePrevPage = () => {
    if (page > 1) {
      return handleSetPage(page - 1);
    }
    return handleSetPage(1);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      return handleSetPage(page + 1);
    }
    return handleSetPage(totalPages);
  };

  const handleNextPageChunk = () => {
    const t = chunkSize - (position - 1);
    handleSetPage(page + t);
  };

  const handlePrevPageChunk = () => {
    if (totalPages - page < chunkSize) {
      const pos = chunkSize - (totalPages - page);
      handleSetPage(page - pos);
    } else {
      handleSetPage(page - position);
    }
  };

  const getToggleButton = (pageNumber: number) => {
    return (
      <ToggleButtonGroupItem
        key={pageNumber}
        value={pageNumber.toString()}
        onClick={() => handleSetPage(pageNumber)}
        aria-label={`Page ${pageNumber}`}
        className="tw-h-7 tw-w-7 tw-bg-transparent tw-text-xs data-[state=on]:tw-bg-neutral-500"
      >
        {pageNumber}
      </ToggleButtonGroupItem>
    );
  };

  const getLeftDotsButton = () => {
    return (
      <IconButton
        key="dots-left"
        Icon={DotsIcon}
        color="transparent"
        onClick={handlePrevPageChunk}
        aria-label="Previous Chunk"
        className="tw-h-7 tw-w-7 tw-items-end tw-bg-transparent tw-pb-1.5"
      />
    );
  };

  const getRightDotsButton = () => {
    return (
      <IconButton
        key="dots-right"
        Icon={DotsIcon}
        color="transparent"
        onClick={handleNextPageChunk}
        aria-label="Next Chunk"
        className="tw-h-7 tw-w-7 tw-items-end tw-bg-transparent tw-pb-1.5"
      />
    );
  };

  function createPageButtons() {
    // If there are fewer pages than the chunk size, display all pages
    if (totalPages <= chunkSize) {
      return Array.from({ length: totalPages }, (_, i) =>
        getToggleButton(i + 1),
      );
    }
    // If the current page is within the first chunk, display the first chunk, dots, and the last page
    if (page <= chunkSize) {
      return [
        ...Array.from({ length: chunkSize }, (_, i) => getToggleButton(i + 1)),
        getRightDotsButton(),
        getToggleButton(totalPages),
      ];
    }
    // If the current page is within the last chunk, display the first page, dots, and the last chunk
    if (page >= totalPages - (chunkSize - 1)) {
      return [
        getToggleButton(1),
        getLeftDotsButton(),
        ...Array.from({ length: chunkSize }, (_, i) =>
          getToggleButton(totalPages - (chunkSize - 1) + i),
        ),
      ];
    }
    // Otherwise, display the first page, dots, the current chunk, dots, and the last page
    return [
      getToggleButton(1),
      getLeftDotsButton(),
      ...Array.from({ length: chunkSize }, (_, i) =>
        getToggleButton(chunkStart + i),
      ),
      getRightDotsButton(),
      getToggleButton(totalPages),
    ];
  }

  if (totalPages < 1) {
    return <div />;
  }

  const getChunk = () => {
    if (totalPages - chunkSize < page && page > chunkSize) {
      return `${totalPages - chunkSize + 1}-${totalPages} of ${totalPages}`;
    }

    return `${chunkStart}-${chunkEnd} of ${totalPages}`;
  };

  return (
    <div className={cn("tw-flex tw-gap-2", { className })}>
      <Button
        LeftIcon={CaretLeft}
        onClick={handlePrevPage}
        disabled={page === 1}
        color="transparent"
        aria-label="Previous Page"
        className="tw-h-7 tw-text-xs disabled:tw-bg-transparent disabled:tw-text-neutral-200"
      >
        Back
      </Button>

      <ToggleButtonGroup value={page.toString()} size="md" type="single">
        {createPageButtons()}
      </ToggleButtonGroup>

      <Button
        RightIcon={CaretRight}
        onClick={handleNextPage}
        disabled={page === totalPages}
        color="transparent"
        aria-label="Next Page"
        className="tw-h-7 tw-text-xs disabled:tw-bg-transparent disabled:tw-text-neutral-200"
      >
        Next
      </Button>

      <div className="tw-flex tw-h-7 tw-items-center tw-font-sans tw-text-xs tw-font-semibold tw-text-neutral-300">
        {getChunk()}
      </div>
    </div>
  );
}
