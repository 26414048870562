import type { CustomCellEditorProps } from "ag-grid-react";
import React, { useEffect } from "react";

import { filterCellProps } from "../../../lib/utils";
import type { CheckboxProps } from "../../Form/Checkbox";
import { Checkbox } from "../../Form/Checkbox";

type Props = CustomCellEditorProps &
  CheckboxProps & {
    value: boolean;
    onValueChange: (value: boolean) => void;
  };

const CheckboxCell = ({ value, onValueChange, ...props }: Props) => {
  const filteredProps = filterCellProps({ value, onValueChange, ...props });
  const editable = Boolean(props.colDef?.editable);

  useEffect(() => {
    if (onValueChange && editable) {
      onValueChange(!value);
      props.stopEditing();
    }
  }, []);

  return (
    <Checkbox {...filteredProps} className="tw-m-auto" checked={!!value} />
  );
};

CheckboxCell.displayName = "CheckboxCell";
export default CheckboxCell;
