import { type ThemeOptions } from "@mui/material";

export const formHelpterText: ThemeOptions["components"] = {
  MuiFormHelperText: {
    styleOverrides: {
      root: ({ theme }) => ({
        margin: theme.spacing(0.5, 0, 0),
        ...theme.typography.body2,
      }),
    },
  },
};
