import type { Comment as CommentType } from "@themis/api/gen/models/comment";
import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import { isCommentWithReview } from "@themis/api/utils/comments/is-comment-with-review";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { groupBy, mapKeys } from "lodash";
import type { ReactElement } from "react";
import { useMemo } from "react";

import { useComments } from "@/api/queries/comments/use-comments";
import { useUsers } from "@/api/queries/users/use-users";

import type { CommentWithUser } from "../comment-with-user";
import { Comment } from "../comment/comment";
import { ReviewComment } from "../review-comment/review-comment";
import { CommentsEmpty } from "./comments-empty";
import { CommentsError } from "./comments-error";
import { CommentsLoading } from "./comments-loading";

const rootCommentsKey = "root";

interface CommentsListProps {
  filterFn?: (comment: CommentType, index: number) => boolean;
  recordId: number;
  recordName: string;
  recordTypePath: RecordTypePath;
  workspaceId: number;
}

export function CommentsList({
  filterFn,
  recordId,
  recordName,
  recordTypePath,
  workspaceId,
}: CommentsListProps): ReactElement {
  const {
    data: comments = [],
    isError,
    isLoading,
  } = useComments(
    {
      recordId,
      recordType: recordTypePath,
    },
    {
      select: filterFn ? (_comments) => _comments.filter(filterFn) : undefined,
    },
  );
  const { data: usersMap = {} } = useUsers(workspaceId, {
    select: (users) => mapKeys(users, (user) => user.id),
  });

  const commentsWithUsers = useMemo(() => {
    const _commentsWithUsers: CommentWithUser[] = comments.map((comment) => ({
      ...comment,
      user: comment.author_id ? usersMap[comment.author_id] : null,
    }));

    return groupBy(
      _commentsWithUsers,
      (comment) => comment.parent_id || rootCommentsKey,
    );
  }, [comments, usersMap]);

  const isEmpty = comments.length < 1;

  if (isError) {
    return <CommentsError />;
  }

  if (isLoading) {
    return <CommentsLoading />;
  }

  if (isEmpty) {
    return <CommentsEmpty />;
  }

  const renderComment = (comment: CommentWithUser) => {
    if (isCommentWithReview(comment)) {
      return <ReviewComment comment={comment} />;
    }

    return (
      <Comment
        key={comment.id}
        comment={comment}
        replies={commentsWithUsers[comment.id] || []}
        recordId={recordId}
        recordName={recordName}
        recordTypePath={recordTypePath}
        usersMap={usersMap}
      />
    );
  };

  return (
    <Stack flexGrow={1} spacing={2} px={1} marginBottom={2}>
      {commentsWithUsers[rootCommentsKey].map(renderComment)}
    </Stack>
  );
}
