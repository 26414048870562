import type { ComponentPropsWithoutRef, ReactNode } from "react";
import React from "react";

import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip/Tooltip";

export interface SimpleTooltipProps
  extends ComponentPropsWithoutRef<typeof Tooltip> {
  title: ReactNode;
  tooltipContentProps?: ComponentPropsWithoutRef<typeof TooltipContent>;
  tooltipTriggerProps?: ComponentPropsWithoutRef<typeof TooltipTrigger>;
  children: ReactNode;
  disabled?: boolean;
}

export const SimpleTooltip = ({
  children,
  title,
  tooltipTriggerProps = {},
  tooltipContentProps,
  disabled,
  ...rest
}: SimpleTooltipProps) => {
  if (disabled || !title) {
    return children;
  }

  const { asChild, ...restTooltipTriggerProps } = tooltipTriggerProps;

  return (
    <Tooltip {...rest}>
      <TooltipTrigger
        asChild={asChild === undefined ? true : asChild}
        {...restTooltipTriggerProps}
      >
        {children}
      </TooltipTrigger>
      <TooltipContent {...tooltipContentProps}>{title}</TooltipContent>
    </Tooltip>
  );
};
