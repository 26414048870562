import { faker } from "@faker-js/faker";

import type {
  ExportPdf200,
  ExportPdf403,
  ExportPdfPathParams,
  ExportPdfQueryParams,
  ExportPdfQueryResponse,
} from "../models/ExportPdf";
import { createFile } from "./createFile";
import { createRecordTypePath } from "./createRecordTypePath";

export function createExportPdfPathParams(): NonNullable<ExportPdfPathParams> {
  return {
    record_type: createRecordTypePath(),
    record_id: faker.string.alpha(),
  };
}

export function createExportPdfQueryParams(): NonNullable<ExportPdfQueryParams> {
  return {
    sections: faker.helpers.arrayElements([
      faker.helpers.arrayElement<any>([
        "record_details",
        "record_comments_summary",
        "attachments",
        "attachments_comment_summary",
      ]),
    ]) as any,
  };
}

/**
 * @description OK
 */
export function createExportPdf200(): NonNullable<ExportPdf200> {
  return { data: createFile() };
}

/**
 * @description Forbidden
 */
export function createExportPdf403(): NonNullable<ExportPdf403> {
  return {
    errors: faker.helpers.arrayElements([
      { code: faker.string.alpha(), message: faker.string.alpha() },
    ]) as any,
  };
}

/**
 * @description OK
 */
export function createExportPdfQueryResponse(): NonNullable<ExportPdfQueryResponse> {
  return { data: createFile() };
}
