import { recordTypePath } from "@themis/api/gen/models/recordTypePath";
import type { ThemisDocument } from "@themis/api/gen/models/themisDocument";
import { CommentsList } from "@themis/shared/components/comments/comments-list/comments-list";
import { CreateComment } from "@themis/shared/components/comments/create-comment/create-comment";
import { RecordCommentsProvider } from "@themis/shared/contexts/record-comments-context/record-comments-context";
import { DetailMediaLayout } from "@themis/ui-library/components/layout/detail-media-layout/detail-media-layout";
import { mapKeys } from "lodash";
import { useIntl } from "react-intl";

import { useUsers } from "@/api/queries/users/use-users";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

interface AccountsDocumentDetailCommentsProps {
  document: ThemisDocument;
}

export function AccountsDocumentDetailComments({
  document,
}: AccountsDocumentDetailCommentsProps) {
  const { formatMessage } = useIntl();
  const workspaceId = useRouteWorkspaceId();

  const { data: usersMap = {} } = useUsers(workspaceId, {
    select: (users) => mapKeys(users, (user) => user.id),
  });

  return (
    <RecordCommentsProvider commentDetailUrl={window.location.href}>
      <DetailMediaLayout.Main
        content={
          <CommentsList
            recordId={document.id}
            recordName={document.file?.file_name || ""}
            recordTypePath={recordTypePath.documents}
            workspaceId={workspaceId}
          />
        }
        footer={
          <CreateComment
            inputSize="medium"
            placeholder={formatMessage({
              defaultMessage: "Add a comment. Use @ to mention a user.",
            })}
            recordId={document.id}
            recordTypePath={recordTypePath.documents}
            usersMap={usersMap}
          />
        }
      />
    </RecordCommentsProvider>
  );
}
