import mixpanel from "mixpanel-browser";
import { useEffect } from "react";

import { useCurrentUser } from "@/api/queries/users";

export function UserTracker() {
  const { data: user } = useCurrentUser();

  useEffect(() => {
    if (user) {
      try {
        mixpanel.identify(user.id.toString());
        mixpanel.people.set({
          name: user.full_name,
          email: user.email,
        });
      } catch (error) {
        window.console.error("Mixpanel initialization error", error);
      }
    }
  }, [user]);

  return null;
}
