import type { ColDef } from "ag-grid-community";
import React from "react";

import type { MonitoringGroupAssetChildResult } from "@/api";

import { StatusTag } from "../components/StatusTag";
import { ThumbsStatus } from "../components/ThumbsStatus";
import ViewSlideButton from "../components/ViewSlideButton";

export const MONITORING_GROUP_ASSET_CHILD_RESULTS_COLUMNS_TABLE: ColDef<MonitoringGroupAssetChildResult>[] =
  [
    {
      headerName: "",
      width: 30,
      rowDrag: true,
      resizable: false,
    },
    {
      headerName: "Rule",
      cellRenderer: "textRenderCell",
      field: "rule",
      editable: false,
      minWidth: 250,
    },
    {
      cellRenderer: "textRenderCell",
      field: "reason",
      headerName: "Reason",
      editable: false,
      minWidth: 450,
    },
    {
      headerName: "Severity",
      cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => (
        <div className="tw-pl-2">
          <StatusTag status={props.data.severity || ""} />
        </div>
      ),
      editable: false,
      minWidth: 110,
      flex: 3,
    },
    {
      headerName: "Status",
      cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => (
        <div className="tw-pl-2">
          <StatusTag status={props.data.status || ""} />
        </div>
      ),
      editable: false,
      minWidth: 160,
      flex: 3,
    },
    {
      cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => (
        <ThumbsStatus
          monitoringGroupResultId={props.data.id}
          disable={props.data.status !== "unreviewed"}
        />
      ),
      minWidth: 100,
      width: 100,
      sortable: false,
      resizable: false,
      pinned: "right" as const,
      cellStyle: () => ({ justifyContent: "center" }),
    },
    {
      cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => (
        <ViewSlideButton monitoringGroupResultId={props.data.id} />
      ),
      minWidth: 40,
      width: 40,
      sortable: false,
      resizable: false,
      pinned: "right" as const,
      cellStyle: () => ({ justifyContent: "center" }),
    },
  ];

export const MONITORING_GROUP_ASSET_CHILD_RESULTS_MISSING_COLUMNS_TABLE: ColDef<MonitoringGroupAssetChildResult>[] =
  [
    {
      headerName: "",
      width: 30,
      rowDrag: true,
      resizable: false,
    },
    {
      headerName: "Copy",
      cellRenderer: "textRenderCell",
      field: "copy_title_data",
      editable: false,
      minWidth: 820,
    },
    {
      headerName: "Status",
      cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => (
        <div className="tw-pl-2">
          <StatusTag status={props.data.status || ""} />
        </div>
      ),
      editable: false,
      minWidth: 160,
      flex: 3,
    },
    {
      cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => (
        <ThumbsStatus
          monitoringGroupResultId={props.data.id}
          disable={props.data.status !== "unreviewed"}
        />
      ),
      minWidth: 100,
      width: 100,
      sortable: false,
      resizable: false,
      pinned: "right" as const,
      cellStyle: () => ({ justifyContent: "center" }),
    },
    {
      cellRenderer: (props: { data: MonitoringGroupAssetChildResult }) => (
        <ViewSlideButton monitoringGroupResultId={props.data.id} />
      ),
      minWidth: 40,
      width: 40,
      sortable: false,
      resizable: false,
      pinned: "right" as const,
      cellStyle: () => ({ justifyContent: "center" }),
    },
  ];
