import type { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { type CSSProperties, type ReactNode } from "react";
import React from "react";
import { PiDotsSixVertical } from "react-icons/pi";

import { cn } from "../../lib/utils";
import { IconButton } from "../Button/IconButton";

interface SortableItemProps {
  id: UniqueIdentifier;
  children: ReactNode;
  className?: string;
  iconPosition?: "left" | "right";
  isSortDisabled?: boolean;
}

export function SortableItem({
  id,
  children,
  className,
  iconPosition = "right",
  isSortDisabled = false,
}: SortableItemProps) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    ...(isDragging && {
      position: "relative",
      zIndex: 1,
    }),
  };

  const Icon = !isSortDisabled ? (
    <IconButton
      className="hover:tw-cursor-grab active:tw-cursor-grabbing active:tw-bg-primary-25 active:tw-text-neutral-500"
      color="transparent"
      Icon={PiDotsSixVertical}
      {...listeners}
    />
  ) : null;

  return (
    <div
      ref={!isSortDisabled ? setNodeRef : null}
      style={style}
      {...attributes}
      className={cn(
        "tw-flex tw-select-none tw-flex-row tw-items-center tw-justify-between",
        className,
      )}
    >
      {iconPosition === "left" && Icon}
      {children}
      {iconPosition === "right" && Icon}
    </div>
  );
}
