import { Link as MuiLink } from "@mui/material";
import type { LinkProps as MuiLinkProps } from "@mui/material/Link";
import type { LinkProps as RouterLinkProps } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

export interface LinkProps extends MuiLinkProps {
  to: RouterLinkProps["to"];
  replace?: RouterLinkProps["replace"];
  external?: boolean;
}

export function Link({ external, ...rest }: LinkProps) {
  const externalLinkProps: Partial<LinkProps> = external
    ? { target: "_blank", rel: "noopener noreferrer" }
    : {};

  return <MuiLink component={RouterLink} {...externalLinkProps} {...rest} />;
}
