import type { Filters, QueryFilter } from "../../data-grid-state";

export function convertFiltersToQueryParam(
  filters: Filters | undefined,
): Record<string, QueryFilter> | undefined {
  if (!filters) {
    return undefined;
  }
  return Object.entries(filters).reduce<Record<string, QueryFilter>>(
    (acc, [key, filter]) => {
      const value = Array.isArray(filter.value) ? filter.value.join(",") : "";
      acc[key] = { [filter.operand]: value };
      return acc;
    },
    {},
  );
}
