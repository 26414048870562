import type { MediaType } from "../types/media-type";

const supportedMediaTypes: Record<MediaType, string[]> = {
  pdf: ["application/pdf"],
  image: ["image/jpeg", "image/png", "image/gif", "image/webp"],
};

export function getMediaType(contentType: string): MediaType | undefined {
  for (const [type, types] of Object.entries(supportedMediaTypes)) {
    if (types.includes(contentType)) {
      return type as MediaType;
    }
  }
}
