import * as SwitchPrimitives from "@radix-ui/react-switch";
import type { VariantProps } from "cva";
import { cva } from "cva";
import * as React from "react";

import { cn } from "../../lib/utils";
import { Label } from "../Label/Label";
import type { MiniTagProps } from "../MiniTag/MiniTag";
import { MiniTag } from "../MiniTag/MiniTag";

const switchVariants = cva({
  base: [
    "tw-inline-flex tw-shrink-0 tw-cursor-pointer tw-items-center tw-rounded-full tw-border-2 tw-border-transparent tw-transition-colors",
    "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 focus-visible:tw-ring-offset-primaryDim-50",
    "disabled:tw-cursor-not-allowed disabled:tw-opacity-50 data-[state=checked]:tw-bg-primary-300",
  ],
  variants: {
    size: {
      sm: "tw-h-5 tw-w-9",
      md: "tw-h-6 tw-w-[42px]",
      lg: "tw-h-7 tw-w-12",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

type SwitchProps = VariantProps<typeof switchVariants> &
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
    left?: {
      label: string;
      value?: string | boolean;
    };
    right?: {
      label: string;
      value?: string | boolean;
    };
    labelType?: "text" | "tag";
    defaultValue?: string;
    onValueChange?: (value: string | boolean) => void;
  };

const SwitchLabel = ({
  selected,
  color,
  type,
  children,
}: {
  selected: boolean;
  color: MiniTagProps["color"];
  type: SwitchProps["labelType"];
  children: string;
}) =>
  type === "text" ? (
    <Label
      className={cn("tw-font-semibold tw-transition-colors", {
        "tw-text-neutral-500": selected,
        "tw-text-neutral-200 tw-opacity-50": !selected,
      })}
    >
      {children}
    </Label>
  ) : (
    <MiniTag
      className="tw-transition-colors"
      color={selected ? color : "grey"}
      variant={selected ? "solid" : "default"}
      size="lg"
    >
      {children}
    </MiniTag>
  );

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  SwitchProps
>(
  (
    {
      className,
      size,
      left,
      right,
      labelType = "text",
      onCheckedChange,
      checked,
      defaultChecked,
      onValueChange,
      defaultValue,
      ...props
    },
    ref,
  ) => {
    if (right && !left) {
      throw new Error("Switch: 'right' prop requires 'left' prop to be set.");
    }

    const [internalChecked, setInternalChecked] = React.useState<boolean>(
      Boolean(
        checked ||
          (defaultValue && defaultValue === right?.value) ||
          defaultChecked,
      ),
    );

    const handleSwitchChange = (isChecked: boolean) => {
      setInternalChecked(isChecked);

      // If no value is provided, acts the same as onCheckedChange
      onValueChange?.(isChecked ? right?.value || true : Boolean(left?.value));
      onCheckedChange?.(isChecked);
    };

    return (
      <div className="tw-inline-flex tw-items-center tw-justify-center tw-gap-2 tw-text-sm">
        {left && (
          <SwitchLabel
            type={labelType}
            selected={
              // If no right prop undefined and labelType is text, always show solid label
              right ? !internalChecked : labelType === "text" || internalChecked
            }
            color="primary"
          >
            {left.label}
          </SwitchLabel>
        )}
        <SwitchPrimitives.Root
          className={cn(
            switchVariants({ size }),
            {
              "data-[state=checked]:tw-bg-primary-300 data-[state=unchecked]:tw-bg-primary-300":
                right,
              "data-[state=unchecked]:tw-bg-primaryDim-50": !right,
            },
            className,
          )}
          onCheckedChange={handleSwitchChange}
          checked={checked}
          defaultChecked={internalChecked}
          {...props}
          ref={ref}
        >
          <SwitchPrimitives.Thumb
            className={cn(
              "tw-pointer-events-none tw-block tw-rounded-full tw-bg-neutral-25 tw-shadow-lg tw-ring-0 tw-transition-transform data-[state=unchecked]:tw-translate-x-1",
              {
                "tw-h-3.5 tw-w-3.5 data-[state=checked]:tw-translate-x-[1.125rem]":
                  size === "sm",
                "tw-h-4 tw-w-4 data-[state=checked]:tw-translate-x-[1.375rem]":
                  size === "md",
                "tw-h-5 tw-w-5 data-[state=checked]:tw-translate-x-6":
                  !size || size === "lg",
              },
            )}
          />
        </SwitchPrimitives.Root>
        {right && (
          <SwitchLabel
            type={labelType}
            selected={Boolean(internalChecked)}
            color="secondary"
          >
            {right.label}
          </SwitchLabel>
        )}
      </div>
    );
  },
);

Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
