import { useCallback, useMemo, useState } from "react";

import { computeGridState } from "./compute-grid-state";
import type { DataGridState } from "./data-grid-state";
import { convertFiltersToQueryParam } from "./use-filters/convert-filters-to-query-param/convert-filters-to-query-param";

/**
 * @description Use this hook to manage the state of a data grid; use usePersistedDataGridState if you need to persist the state
 * @param initialState - The initial state of the data grid
 */
export function useDataGridState(initialState: Partial<DataGridState> = {}) {
  const [state, setState] = useState<DataGridState>({
    filters: undefined,
    pageIndex: 0,
    pageSize: 10,
    sorts: undefined,
    ...initialState,
  });

  const updateState = useCallback((params: Partial<DataGridState>) => {
    setState((prevState: DataGridState) => {
      return computeGridState(prevState, params);
    });
  }, []);

  const queryParams = useMemo(() => {
    const offset =
      state.pageIndex != null && state.pageSize != null
        ? state.pageIndex * state.pageSize
        : undefined;
    return {
      filters: convertFiltersToQueryParam(state.filters),
      limit: state.pageSize,
      offset,
      sort_by: state.sorts,
    };
  }, [state]);

  return {
    dataGridState: state,
    updateDataGridState: updateState,
    dataGridQueryParams: queryParams,
  };
}
