import { useQuery } from "@tanstack/react-query";

import type { RecordTypePath } from "@/api";

import { listRecordLinks } from "../../gen/axios/recordLinksController/listRecordLinks";

export function useRecordLinks(
  recordType: RecordTypePath,
  recordId: number | undefined,
) {
  return useQuery({
    queryKey: [recordType, recordId],
    queryFn: async () => {
      if (!recordId) {
        throw new Error("Record ID is required");
      }

      const response = await listRecordLinks(recordType, recordId);
      return response.data;
    },

    enabled: Number.isFinite(recordId),
  });
}
