import { faker } from "@faker-js/faker";

import type {
  UpdateMonitoringAsset200,
  UpdateMonitoringAssetMutationRequest,
  UpdateMonitoringAssetMutationResponse,
  UpdateMonitoringAssetPathParams,
} from "../models/UpdateMonitoringAsset";
import { createMonitoringAsset } from "./createMonitoringAsset";

export function createUpdateMonitoringAssetPathParams(): NonNullable<UpdateMonitoringAssetPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateMonitoringAsset200(): NonNullable<UpdateMonitoringAsset200> {
  return { data: createMonitoringAsset() };
}

export function createUpdateMonitoringAssetMutationRequest(): NonNullable<UpdateMonitoringAssetMutationRequest> {
  return {
    monitoring_asset: {
      owner_id: faker.number.int(),
      reviewer_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
    },
  };
}

/**
 * @description OK
 */
export function createUpdateMonitoringAssetMutationResponse(): NonNullable<UpdateMonitoringAssetMutationResponse> {
  return { data: createMonitoringAsset() };
}
