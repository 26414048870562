import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  useToast,
} from "@themis/ui";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";

import type {
  FieldConfiguration,
  FieldConfigurationFieldType,
  FieldConfigurationReferenceType,
} from "../../../api/gen/models/FieldConfiguration";
import type { RecordTypePath } from "../../../api/gen/models/RecordTypePath";
import type {
  UpdateFieldMutationRequest,
  UpdateFieldMutationResponse,
} from "../../../api/gen/models/UpdateField";
import { useUpdateField } from "../../../api/queries/fields/use-update-field";
import { axiosConfigContainsError } from "../../../utils/errors";
import { CustomColumnFormFields } from "./custom-column-form-fields/custom-column-form-fields";
import type {
  DropdownColumnSchema,
  ValidationSchema,
} from "./use-validation-schema/use-validation-schema";
import { useValidationSchema } from "./use-validation-schema/use-validation-schema";

const fieldTypeToColumnTypeMap: Partial<
  Record<FieldConfigurationFieldType, ValidationSchema["columnType"]>
> = {
  text: "text",
  date: "date",
  datetime: "date",
  select: "select",
};
const referenceToColumnTypeMap: Partial<
  Record<FieldConfigurationReferenceType, ValidationSchema["columnType"]>
> = {
  ThemisDocument: "attachment",
  User: "user",
};

const getColumnType = (field: FieldConfiguration) => {
  if (field.field_type === "reference" && field.reference_type) {
    return referenceToColumnTypeMap[field.reference_type] || "text";
  }

  return fieldTypeToColumnTypeMap[field.field_type] || "text";
};

const getDefaultValues = (field: FieldConfiguration) => {
  const columnType = getColumnType(field);

  const values = {
    columnType,
    columnData: {
      requiredColumn: field?.required ?? false,
      name: field?.display_name ?? "",
      multipleSelections: field.multiselect ?? false,
      internal: field.internal_only ?? false,
    },
  };

  if (values.columnType === "select") {
    (values as DropdownColumnSchema).columnData.options =
      field.options?.map((option) => ({
        name: option.value,
        color: "",
      })) ?? [];
  }

  return values;
};

interface CustomDialogProps {
  companyId: number;
  field: FieldConfiguration;
  open: boolean;
  recordType: RecordTypePath;
  onOpenChange: (open: boolean) => void;
}

/**
 * @todo Finish color select implementation
 */
export function EditCustomColumnDialog({
  companyId,
  field,
  open,
  recordType,
  onOpenChange,
}: CustomDialogProps) {
  const { formatMessage } = useIntl();
  const toast = useToast();

  const validationSchema = useValidationSchema();
  const updateFieldMutation = useUpdateField({
    companyId,
    recordType,
  });

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: getDefaultValues(field),
  });

  const handleSaveColumn = (data: ValidationSchema) => {
    const requestData: UpdateFieldMutationRequest = {
      display_name: data.columnData.name,
      required: data.columnData.requiredColumn,
      internal_only: data.columnData.internal,
    };

    updateFieldMutation.mutate(
      { data: requestData, fieldId: field.id },
      {
        onSuccess: (updateResult: UpdateFieldMutationResponse) => {
          toast({
            content: formatMessage(
              {
                defaultMessage: 'Column "{columnName}" updated successfully!',
              },
              {
                columnName: updateResult.data.display_name,
              },
            ),
            variant: "success",
          });
          onOpenChange(false);
        },
        onError: (error) => {
          if (axiosConfigContainsError(error, "base", "Field already exists")) {
            toast({
              content: formatMessage({
                defaultMessage: "There is already a column with that name",
              }),
              variant: "error",
            });
          } else {
            toast({
              content: formatMessage({
                defaultMessage: "Failed to update column",
              }),
              variant: "error",
            });
          }
        },
      },
    );
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {formatMessage({ defaultMessage: "Manage Custom Column" })}
          </DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form noValidate onSubmit={form.handleSubmit(handleSaveColumn)}>
            <CustomColumnFormFields isEdit />

            <DialogFooter>
              <Button
                type="submit"
                color="primary"
                loading={updateFieldMutation.isPending}
              >
                {formatMessage({ defaultMessage: "Confirm" })}
              </Button>

              <Button color="transparent" onClick={() => onOpenChange(false)}>
                {formatMessage({ defaultMessage: "Cancel" })}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
