import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { AssessmentAPI, RiskAreaApi } from "@/api/legacy/risk-assessment";
import { useMainStore } from "@/contexts/Store";
import type { AssessmentDetail } from "@/features/risk-assessment/types/assessment";
import type { RiskArea } from "@/features/risk-assessment/types/risk-area";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

function useAssessmentData(activeWorkspaceId: number | undefined) {
  const mainStore = useMainStore();
  const { assessmentId } = useParams<{ assessmentId: string }>();

  const workspaceId = useRouteWorkspaceId();

  const internalWorspaceId: number | undefined = mainStore.workspaces.list.find(
    (ws) => ws.is_internal,
  )?.id;

  const [assessment, setAssessment] = useState<AssessmentDetail | undefined>();
  const [riskAreas, setRiskAreas] = useState<RiskArea[]>([]);

  const loadData = async (_activeWorkspaceId: number, recordId: string) => {
    try {
      const [assessmentData, riskAreasData, internalRiskAreasData] =
        await Promise.all([
          AssessmentAPI.get(_activeWorkspaceId, recordId),
          RiskAreaApi.getAll(String(workspaceId)),
          internalWorspaceId != null
            ? RiskAreaApi.getAll(internalWorspaceId)
            : Promise.resolve([]),
        ]);

      setAssessment(assessmentData);

      const distinctRiskAreas = uniqBy(
        riskAreasData.concat(internalRiskAreasData),
        (x) => x.id,
      );

      setRiskAreas(distinctRiskAreas);
    } catch (err) {
      mainStore.toast.setErrorFromResponse(err);
    }
  };

  const fetchAssessmentData = () => {
    if (assessmentId && activeWorkspaceId) {
      loadData(activeWorkspaceId, assessmentId);
    }
  };

  useEffect(() => {
    if (assessmentId && activeWorkspaceId) {
      loadData(activeWorkspaceId, assessmentId);
    }
  }, [assessmentId, activeWorkspaceId]);

  return {
    assessment,
    riskAreas,
    assessmentId,
    setAssessment,
    setRiskAreas,
    fetchAssessmentData,
  };
}

export default useAssessmentData;
