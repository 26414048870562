import { useParams } from "react-router-dom";

import { DueDiligenceTable } from "./due-diligence-table/due-diligence-table";

export default function DueDiligence() {
  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    accountId: string;
  }>();

  return <DueDiligenceTable workspaceId={workspace_id} accountId={accountId} />;
}
