import { Route, Switch } from "react-router-dom";

import RecordRedirectPage from "./record-redirect-page/record-redirect-page";

export default function RecordRedirectRoutes() {
  return (
    <Switch>
      <Route exact>
        <RecordRedirectPage />
      </Route>
    </Switch>
  );
}
