import { findKey, reduce } from "lodash";
import pluralize from "pluralize";

import type { EventNotificationSelectedRecipients } from "@/api/legacy/event-notifications/types";
import type { ValueLabelOption } from "@/stores/types/field-types";
import type { Department, User } from "@/stores/types/user-types";
import {
  accumulateArrayLengths,
  accumulateNumberOfNonEmptyArrays,
  toArrayLength,
} from "@/utils/utils";

export function getNumberOfRecipients(
  recipients: EventNotificationSelectedRecipients,
) {
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  return reduce(recipients, accumulateArrayLengths, 0);
}

export function addText(
  currentText: string,
  textToAdd: string,
  numberOfRecipientGroups: number,
) {
  if (!currentText) {
    return textToAdd;
  }

  if (currentText.includes(",") || numberOfRecipientGroups === 2) {
    return `${currentText} & ${textToAdd}`;
  }

  return `${currentText}, ${textToAdd}`;
}

export function getNumberOfRecipientGroups(
  recipients: EventNotificationSelectedRecipients,
) {
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  return reduce(recipients, accumulateNumberOfNonEmptyArrays, 0);
}

export function getMultipleRecipientsText(
  recipients: EventNotificationSelectedRecipients,
) {
  const numberOfRecipientGroups = getNumberOfRecipientGroups(recipients);

  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  return reduce(
    recipients,
    (currentText, recipientGroup, recipientGroupKey) => {
      if (!recipientGroup.length) {
        return currentText;
      }

      switch (recipientGroupKey) {
        case "columns":
          return addText(
            currentText,
            pluralize("column", recipientGroup.length, true),
            numberOfRecipientGroups,
          );
        case "user_ids":
          return addText(
            currentText,
            pluralize("user", recipientGroup.length, true),
            numberOfRecipientGroups,
          );
        case "department_ids":
          return addText(
            currentText,
            pluralize("dept", recipientGroup.length, true),
            numberOfRecipientGroups,
          );
        default:
      }
    },
    "",
  );
}

export function getSingleRecipientInfo(params: {
  recipients: EventNotificationSelectedRecipients;
  users: User[];
  departments: Department[];
  columns: ValueLabelOption[];
}) {
  const { recipients, users, departments, columns } = params;
  // @ts-expect-error TS(2345) FIXME: Argument of type '<T>(list: T[]) => number' is not... Remove this comment to see the full error message
  const recipientKey = findKey(recipients, toArrayLength);
  // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const [recipientValue] = recipients[recipientKey];

  switch (recipientKey) {
    case "columns":
      return {
        iconName: "user",
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        label: columns.find((column) => column.value === recipientValue).label,
      };
    case "department_ids":
      return {
        iconName: "department",
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        label: departments.find(
          (department) => department.id === recipientValue,
        ).title,
      };
    case "user_ids": {
      return users.find((user) => user.id === recipientValue);
    }
    default:
      return;
  }
}
