import type { ThemeOptions } from "@mui/material";

export const typography: ThemeOptions["typography"] = {
  fontFamily: "Poppins, sans-serif",
  fontWeightBold: 600,
  fontWeightMedium: 500,
  fontWeightRegular: 500,
  fontWeightLight: 400,
  htmlFontSize: 14,
  h1: {
    fontSize: 26,
    fontWeight: 600,
  },
  h2: {
    fontSize: 24,
    fontWeight: 600,
  },
  h3: {
    fontSize: 22,
    fontWeight: 600,
  },
  h4: {
    fontSize: 20,
    fontWeight: 600,
  },
  h5: {
    fontSize: 18,
    fontWeight: 600,
  },
  h6: {
    fontSize: 16,
    fontWeight: 600,
  },
  body1: {
    fontSize: 14,
    fontWeight: 500,
  },
  subtitle1: {
    fontSize: 14,
    fontWeight: 600,
  },
  body2: {
    fontSize: 12,
    fontWeight: 500,
  },
  subtitle2: {
    fontSize: 12,
    fontWeight: 600,
  },
  transparent: {
    opacity: 0.8,
  },
};
