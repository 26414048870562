import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "axios";
import { stringify } from "qs";

import type { RailsErrors } from "@/utils/errors";

/**
 * Subset of AxiosRequestConfig
 */
export interface RequestConfig<TData = unknown> {
  url?: string;
  method:
    | "get"
    | "put"
    | "patch"
    | "post"
    | "delete"
    | "GET"
    | "PUT"
    | "PATCH"
    | "POST"
    | "DELETE";
  params?: unknown;
  data?: TData;
  responseType?:
    | "arraybuffer"
    | "blob"
    | "document"
    | "json"
    | "text"
    | "stream";
  signal?: AbortSignal;
  headers?: AxiosRequestConfig["headers"];
}

/**
 * Subset of AxiosResponse
 */
export interface ResponseConfig<TData = unknown> {
  data: TData;
  status: number;
  statusText: string;
  headers?: AxiosResponse["headers"];
}

export type ResponseErrorConfig<TError = unknown> = AxiosError<TError>;

export const axiosInstance = axios.create({
  baseURL: "/api/frontend",
  paramsSerializer: (params) => {
    return stringify(params, {
      arrayFormat: "brackets",
      skipNulls: true,
    });
  },
});

axiosInstance.interceptors.request.use(async (request) => {
  const { default: mainStore } = await import("@/stores/Main");
  request.headers["Content-Type"] =
    request.headers["Content-Type"] || "application/json";

  const csrfToken = document.querySelector<HTMLMetaElement>(
    'meta[name="csrf-token"]',
  );
  if (csrfToken) {
    request.headers["X-CSRF-Token"] = csrfToken.content;
  }

  const accessToken = mainStore.webSessions.getAccessToken();
  if (accessToken && accessToken.length > 0) {
    request.headers["X-Session-Token"] = accessToken;
  }

  return request;
});

axiosInstance.interceptors.response.use(
  undefined,
  async (e: Error | AxiosError) => {
    const { default: mainStore } = await import("@/stores/Main");

    if (axios.isAxiosError<{ errors: RailsErrors }>(e)) {
      const { response } = e;
      const errors = response?.data.errors;
      const status = response?.status;

      if (status === 401) {
        mainStore.webSessions.handleAuthenticationError();
      }

      if (status === 403) {
        let message = errors?.message || errors?.error;

        if (Array.isArray(message)) {
          message = message.join(", ");
        }

        mainStore.toast.setErrorText(message || "Access Denied");
      }

      if (status === 422 && errors?.invalid_authenticity_token) {
        mainStore.toast.setErrorFromResponse(response);
      }
    }

    throw e;
  },
);

export const axiosClient = async <
  TData,
  TError = unknown,
  TVariables = unknown,
>(
  config: RequestConfig<TVariables>,
): Promise<ResponseConfig<TData>> => {
  const promise = axiosInstance
    .request<TVariables, ResponseConfig<TData>>({ ...config })
    .catch((e: AxiosError<TError>) => {
      throw e;
    });

  return promise;
};

export default axiosClient;
