import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import { _1_HOUR_MS } from "@/constants/duration";
import type {
  UserCompanyPermissions,
  UserModulePermissions,
  UserWorkspacePermissions,
} from "@/stores/types/user-permissions-types";

import type { RecordTypePath } from "../../gen/models";
import api from "../../legacy/api";

export type PermissionDomain = "company" | "workspace" | "module";

export const getUserPermissionsQueryKey = (
  params: UserPermissionsParams<PermissionDomain>,
): QueryKey => ["user-permissions", params];

export interface UserPermissionsParams<TDomain extends PermissionDomain> {
  domain: TDomain;
  workspaceId?: number | undefined;
  companyId?: number | undefined;
  module?: RecordTypePath;
}

export type PermissionsResult<Domain> = Domain extends "company"
  ? UserCompanyPermissions
  : Domain extends "workspace"
  ? UserWorkspacePermissions
  : Domain extends "module"
  ? UserModulePermissions
  : never;

export const useUserPermissions = <
  TDomain extends PermissionDomain,
  TResult extends PermissionsResult<TDomain>,
  TError = Error,
  TData = TResult,
>(
  params: UserPermissionsParams<TDomain>,
  options?: Partial<UseQueryOptions<TResult, TError, TData>>,
) =>
  useQuery({
    queryFn: async () => {
      const response = await getUserPermissions<TDomain, TResult>(params);
      return response.result;
    },
    enabled: params.workspaceId !== undefined && params.companyId !== undefined,
    queryKey: getUserPermissionsQueryKey(params),
    staleTime: _1_HOUR_MS * 4,
    ...options,
  });

const getUserPermissions = async <
  TDomain extends PermissionDomain,
  TResult extends PermissionsResult<TDomain>,
>(
  params: UserPermissionsParams<TDomain>,
) => {
  const { data } = await api.get<{ result: TResult }>(
    `/companies/${params.companyId}/user_permissions`,
    {
      params: {
        workspace_id: params.workspaceId,
        domain: params.domain,
        identifier: params.module,
      },
    },
  );

  return data;
};
