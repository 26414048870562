import { faker } from "@faker-js/faker";

import type {
  UpdateComment200,
  UpdateCommentMutationRequest,
  UpdateCommentMutationResponse,
  UpdateCommentPathParams,
} from "../models/UpdateComment";
import { createComment } from "./createComment";
import { createRecordTypePath } from "./createRecordTypePath";

export function createUpdateCommentPathParams(): NonNullable<UpdateCommentPathParams> {
  return {
    record_type: createRecordTypePath(),
    record_id: faker.number.int(),
    id: faker.number.int(),
  };
}

/**
 * @description The updated comment
 */
export function createUpdateComment200(): NonNullable<UpdateComment200> {
  return { data: createComment() };
}

export function createUpdateCommentMutationRequest(): NonNullable<UpdateCommentMutationRequest> {
  return {
    comment: {
      content: faker.string.alpha(),
      resolved: faker.datatype.boolean(),
    },
  };
}

/**
 * @description The updated comment
 */
export function createUpdateCommentMutationResponse(): NonNullable<UpdateCommentMutationResponse> {
  return { data: createComment() };
}
