import { Slot } from "@radix-ui/react-slot";
import { cva, cx, type VariantProps } from "cva";
import * as React from "react";
import { type IconType } from "react-icons";

import { cn } from "../../lib/utils";
import type { ButtonBaseProps } from "./ButtonBase";
import { buttonBaseVariants } from "./ButtonBase";

const iconButtonVariants = cva({
  base: ["tw-rounded-md"],
  variants: {
    variant: {
      square: "",
      vertical: "",
    },
    size: {
      sm: "tw-h-6",
      md: "tw-h-7",
      lg: "tw-h-8",
    },
  },
  compoundVariants: [
    {
      variant: "square",
      size: "sm",
      className: "tw-w-6",
    },
    {
      variant: "square",
      size: "md",
      className: "tw-w-7",
    },
    {
      variant: "square",
      size: "lg",
      className: "tw-w-8",
    },
    {
      variant: "vertical",
      size: ["sm", "md", "lg"],
      className: "tw-w-4",
    },
  ],
  defaultVariants: {
    variant: "square",
    size: "md",
  },
});

export type IconButtonProps = ButtonBaseProps &
  VariantProps<typeof iconButtonVariants> & {
    Icon: IconType;
    children?: undefined;
  };

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      color,
      disabled,
      size,
      variant,
      asChild = false,
      Icon,
      type = "button",
      loading,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(
          cx(
            buttonBaseVariants({ color, disabled }),
            iconButtonVariants({ size, variant, className }),
          ),
        )}
        data-loading={loading}
        disabled={disabled}
        ref={ref}
        type={type}
        {...props}
      >
        <Icon className="tw-h-4 tw-w-4" />
      </Comp>
    );
  },
);

IconButton.displayName = "IconButton";

export { IconButton, iconButtonVariants };
