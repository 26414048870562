import { http } from "msw";

import { createUpdateMonitoringAssetMutationResponse } from "../createUpdateMonitoringAsset";

export const updateMonitoringAssetHandler = http.put(
  "*/monitoring_assets/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createUpdateMonitoringAssetMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
