import {
  MagnifyingGlassMinus,
  MagnifyingGlassPlus,
} from "@phosphor-icons/react";
import { IconButton } from "@themis/ui-library/components/inputs/icon-button/icon-button";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import type { ReactElement } from "react";

import { useInteractiveMediaContext } from "../../interactive-media.context";

const options = {
  minZoom: 0.5,
  maxZoom: 3,
  zoomIncrement: 0.2,
} as const;

export function ZoomControls(): ReactElement {
  const { zoom } = useInteractiveMediaContext();

  const canZoomIn = zoom.level + options.zoomIncrement <= options.maxZoom;
  const canZoomOut = zoom.level - options.zoomIncrement >= options.minZoom;

  const handleZoomIn = () => {
    zoom.setLevel((zoomLevel) => zoomLevel + options.zoomIncrement);
  };

  const handleZoomOut = () => {
    zoom.setLevel((zoomLevel) => zoomLevel - options.zoomIncrement);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      spacing={0}
    >
      <IconButton
        color="inherit"
        disabled={!canZoomIn}
        size="small"
        onClick={handleZoomIn}
      >
        <MagnifyingGlassPlus fontSize="1em" />
      </IconButton>
      <IconButton
        color="inherit"
        disabled={!canZoomOut}
        size="small"
        onClick={handleZoomOut}
      >
        <MagnifyingGlassMinus fontSize="1em" />
      </IconButton>
    </Stack>
  );
}
