import MuiAvatar, {
  type AvatarProps as MuiAvatarProps,
} from "@mui/material/Avatar";
import { forwardRef } from "react";

import { styled, useTheme } from "../../../styles";
import { getPaletteColor } from "../../../utils/get-palette-color";
import { getSeedColor } from "../../../utils/get-seed-color";
import { CircularProgress } from "../../feedback/circular-progress/circular-progress";

export interface AvatarProps extends Omit<MuiAvatarProps, "color"> {
  colorSeed?: number | string | null;
  size?: "sm" | "md" | "lg";
  loading?: boolean;
  children?: React.ReactNode;
  borderless?: boolean;
}

const StyledAvatar = styled(MuiAvatar)(({ theme }) => ({
  "&[data-size=sm]": {
    width: theme.size.icon.small,
    height: theme.size.icon.small,
    fontSize: 8,
  },
  "&[data-size=md]": {
    width: theme.size.icon.medium,
    height: theme.size.icon.medium,
    fontSize: 10,
  },
  "&[data-size=lg]": {
    width: theme.size.icon.large,
    height: theme.size.icon.large,
    fontSize: 12,
  },
  "&&[data-borderless=true]": {
    border: "none",
  },
}));

export const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  (
    {
      colorSeed,
      size = "md",
      borderless,
      sx,
      onClick,
      children,
      loading,
      ...rest
    },
    ref,
  ) => {
    const theme = useTheme();

    const seedColor = getSeedColor(colorSeed);
    const palette = getPaletteColor(theme, seedColor);

    return (
      <StyledAvatar
        ref={ref}
        data-size={size}
        data-borderless={borderless}
        sx={{
          bgcolor: palette?.main,
          color: palette?.contrastText,
          cursor: onClick ? "pointer" : "default",
          ...sx,
        }}
        onClick={onClick}
        {...rest}
      >
        {loading ? <CircularProgress color="neutral" size="100%" /> : children}
      </StyledAvatar>
    );
  },
);

Avatar.displayName = "Avatar";
