import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

import type { ButtonProps } from "../../inputs/button/button";

export type LinkButtonProps = ButtonProps<Link>;

export function LinkButton({ ...rest }: LinkButtonProps) {
  return <Button component={Link} {...rest} />;
}
