import React from "react";
import { PiPlusBold } from "react-icons/pi";
import type { LinkProps } from "react-router-dom";

import { LinkButton } from "../Button/LinkButton";

interface AddNewRowLinkProps {
  className?: string;
  title: string;
  to: LinkProps["to"];
}

export function AddNewRowLink({ className, title, to }: AddNewRowLinkProps) {
  return (
    <LinkButton
      className={className}
      color="transparent"
      LeftIcon={PiPlusBold}
      to={to}
    >
      {title}...
    </LinkButton>
  );
}
