import { type ThemeOptions } from "@mui/material";

export const tabTheme: ThemeOptions["components"] = {
  MuiTab: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary,
        textTransform: "none",
        fontSize: theme.typography.body1.fontSize,
        padding: theme.spacing(0, 2),
        height: 32,
        minHeight: 32,

        "&.Mui-selected": {
          fontWeight: theme.typography.fontWeightBold,
        },
      }),
    },
  },
};
