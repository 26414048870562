import { Menu } from "@themis/ui-library/components/navigation/menu/menu";
import { MenuItem } from "@themis/ui-library/components/navigation/menu/menu-item/menu-item";
import { useIntl } from "react-intl";

import type { ExportWorkspacePopupProps } from "../ExportWorkspacePopup";

interface DownloadSpreadsheetsMenuProps {
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  onClose: () => void;
  onOpenExportMenu: () => void;
  renderDownloadFooter?: ExportWorkspacePopupProps["renderDownloadFooter"];
}

export function DownloadSpreadsheetsMenu({
  anchorEl,
  open,
  onClose,
  onOpenExportMenu,
  renderDownloadFooter,
}: DownloadSpreadsheetsMenuProps) {
  const { formatMessage } = useIntl();

  return (
    <Menu open={open} onClose={onClose} anchorEl={anchorEl}>
      <MenuItem onClick={onOpenExportMenu}>
        {formatMessage({ defaultMessage: "Download spreadsheet" })}
      </MenuItem>
      {renderDownloadFooter?.({ onClose })}
    </Menu>
  );
}
