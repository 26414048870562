import type { ThemeOptions } from "@mui/material";

export const dialogContent: ThemeOptions["components"] = {
  MuiDialogContent: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(2),
      }),
    },
  },
};
