import { Command as CommandPrimitive } from "cmdk";
import * as React from "react";

import { cn } from "../../lib/utils";

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn("-tw-mx-1 tw-h-px tw-bg-neutral-100", className)}
    {...props}
  />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

export { CommandSeparator };
