import { deepmerge } from "@mui/utils";

import { baseTheme } from "./base-theme/base-theme";

export const lightTheme = deepmerge(baseTheme, {
  colorSchemes: "light",
  palette: {
    ...baseTheme.palette,
    mode: "light",
  },
});
