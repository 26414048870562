import type { ThemeOptions } from "@mui/material";

import { backdrop } from "../theme/base-theme/components/backdrop.theme";
import { cssBaseline } from "../theme/base-theme/components/css-baseline.theme";
import { avatarGroupTheme } from "./data-display/avatar/avatar-group/avatar-group.theme";
import { avatarTheme } from "./data-display/avatar/avatar.theme";
import { chip } from "./data-display/chip/chip.theme";
import { divider } from "./data-display/divider/divider.theme";
import { listItemButtonTheme } from "./data-display/list/list-item-button/list-item-button.theme";
import { listItemIconTheme } from "./data-display/list/list-item-icon/list-item-icon.theme";
import { listItemTextTheme } from "./data-display/list/list-item-text/list-item-text.theme";
import { listSubheaderTheme } from "./data-display/list/list-subheader/list-subheader.theme";
import { listTheme } from "./data-display/list/list.theme";
import { svgIconTheme } from "./data-display/svg-icon/svg-icon.theme";
import { tooltip } from "./data-display/tooltip/tooltip.theme";
import { typography } from "./data-display/typography/typography.theme";
import { circularProgressTheme } from "./feedback/circular-progress/circular-progress.theme";
import { dialogActions } from "./feedback/dialog/dialog-actions/dialog-actions.theme";
import { dialogContentText } from "./feedback/dialog/dialog-content-text/dialog-content-text.theme";
import { dialogContent } from "./feedback/dialog/dialog-content/dialog-content.theme";
import { dialogTitle } from "./feedback/dialog/dialog-title/dialog-title.theme";
import { dialog } from "./feedback/dialog/dialog.theme";
import { skeleton } from "./feedback/skeleton/skeleton.theme";
import { formControlLabel } from "./form/form-control-label/form-control-label.theme";
import { formHelpterText } from "./form/form-helper-text/form-helper-text.theme";
import { autocomplete } from "./inputs/autocomplete/autocomplete.theme";
import { button } from "./inputs/button/button.theme";
import { checkbox } from "./inputs/checkbox/checkbox.theme";
import { iconButton } from "./inputs/icon-button/icon-button.theme";
import { inputBase } from "./inputs/input-base/input-base.theme";
import { select } from "./inputs/select/select.theme";
import { textFieldTheme } from "./inputs/text-field/text-field.theme";
import { containerTheme } from "./layout/container/container.theme";
import { stack } from "./layout/stack/stack.theme";
import { breadcrumbs } from "./navigation/breadcrumbs/breadcrumbs.theme";
import { link } from "./navigation/link/link.theme";
import { menuItemTheme } from "./navigation/menu/menu-item/menu-item.theme";
import { menuListTheme } from "./navigation/menu/menu-list/menu-list.theme";
import { menuTheme } from "./navigation/menu/menu.theme";
import { paginationItem } from "./navigation/pagination/pagination-item/pagination-item.theme";
import { pagination } from "./navigation/pagination/pagination/pagination.theme";
import { tablePagination } from "./navigation/pagination/table-pagination/table-pagination.theme";
import { tabTheme } from "./navigation/tabs/tab/tab.theme";
import { tabsTheme } from "./navigation/tabs/tabs.theme";
import { card } from "./surfaces/card/card.theme";

export const componentsBaseTheme: ThemeOptions["components"] = {
  ...autocomplete,
  ...avatarGroupTheme,
  ...avatarTheme,
  ...backdrop,
  ...breadcrumbs,
  ...button,
  ...card,
  ...checkbox,
  ...chip,
  ...containerTheme,
  ...cssBaseline,
  ...circularProgressTheme,
  ...dialog,
  ...dialogActions,
  ...dialogContent,
  ...dialogContentText,
  ...dialogTitle,
  ...divider,
  ...formControlLabel,
  ...formHelpterText,
  ...iconButton,
  ...inputBase,
  ...link,
  ...listItemButtonTheme,
  ...listItemIconTheme,
  ...listItemTextTheme,
  ...listSubheaderTheme,
  ...listTheme,
  ...menuItemTheme,
  ...menuListTheme,
  ...menuTheme,
  ...pagination,
  ...paginationItem,
  ...select,
  ...skeleton,
  ...stack,
  ...svgIconTheme,
  ...tabTheme,
  ...tablePagination,
  ...tabsTheme,
  ...textFieldTheme,
  ...tooltip,
  ...typography,
};
