import { useServerDataGridBinding } from "@themis/ui-library/components/data-grid/binding/use-server-data-grid-binding";
import { DataGrid } from "@themis/ui-library/components/data-grid/data-grid";
import type { usePersistedDataGridState } from "@themis/ui-library/components/data-grid/state/use-persisted-data-grid-state";

import type { CreateProjectMutationRequest, Project } from "@/api";
import { PageContent } from "@/components/Layout/PageContent";

import { useProjectsTableColumns } from "./use-projects-table-columns";

interface ProjectsTableProps {
  projects: Project[];
  createProject?: (project: CreateProjectMutationRequest) => void;
  workspace_id: string;
  companyId?: number;
  persistedDataGridState: ReturnType<typeof usePersistedDataGridState>;
  totalCount: number;
}

function ProjectsTable({
  projects,
  workspace_id,
  companyId,
  persistedDataGridState,
  totalCount,
}: ProjectsTableProps) {
  const columns = useProjectsTableColumns({
    companyId,
    workspace_id,
  });

  const { dataGridState, updateDataGridState } = persistedDataGridState;

  const { serverGridProps } = useServerDataGridBinding(projects, {
    dataGridState,
    pagination: true,
    total: totalCount,
    onDataGridStateChange: updateDataGridState,
  });

  return (
    <PageContent>
      <DataGrid columns={columns} {...serverGridProps} />
    </PageContent>
  );
}

export default ProjectsTable;
