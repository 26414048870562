import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";
import Paginated from "@/stores/data/Paginated";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams } from "../types/module-store-types";

export default class Procedures extends Paginated {
  mainStore: MainStore;

  // Observable objects
  auditTrails = [];
  historical = [];

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    super(mainStore);

    makeObservable(this, {
      data: observable,
      auditTrails: observable,
      historical: observable,
      sectionsMetadata: observable,

      setSectionsMetadata: action,
      setData: action,
      setAuditTrails: action,
      setHistorical: action,
      setPageForSection: action,
      upsertRecordCounts: action,
    });

    this.mainStore = mainStore;
  }

  async index({
    tableName,
    sortParams,
    page = 1,
    sectionTagId = null,
    fromUseSortingAndPagination = false,
    tableFilters = [],
  }: IndexParams) {
    if (!tableName) {
      window.console.warn(
        `Procedures#index => tableName(${tableName}) should be present!`,
      );
      return;
    }

    // Avoid extra API calls
    // - when loading Procedures for ALL SectionTags outside of page 1 (all Sections are ONLY loaded on page 1)
    if (sectionTagId === null && page !== 1) {
      return;
    }
    // - when loading Procedures for ALL SectionTags with sorting applied (each section is sorted separately)
    if (sectionTagId === null && sortParams) {
      return;
    }

    const controller = new AbortController();
    this.controller = controller;

    const params = {
      table_title: tableName,
      table_filters: tableFilters,
      section_tag_id: sectionTagId,
      page,
    };

    if (sortParams) {
      // @ts-expect-error TS(2339) FIXME: Property 'sort_field_name' does not exist on type ... Remove this comment to see the full error message
      params.sort_field_name = sortParams.field_name;
      // @ts-expect-error TS(2339) FIXME: Property 'sort_direction' does not exist on type '... Remove this comment to see the full error message
      params.sort_direction = sortParams.direction;
    }

    if (fromUseSortingAndPagination && !sectionTagId) {
      // @ts-expect-error TS(2339) FIXME: Property 'top_level_block' does not exist on type ... Remove this comment to see the full error message
      params.top_level_block = true;
    }

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/procedures`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      this.mainStore.fields.setList(response.data.fields);
      this.setPageForSection(page, sectionTagId);
      this.upsertRecordCounts(response.data.counts);

      // If we fetched data for specific SectionTag - ONLY replace RecordVersions for that SectionTag
      this.updateRecordVersions(sectionTagId, response.data);
    } catch (error) {
      window.console.warn(`"Procedures#Index for Workspace" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async create(workspaceID, sectionTagID) {
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/workspaces/${workspaceID}/procedures`,
        headers: this.mainStore.getHeaders(),
        params: {
          section_tag_id: sectionTagID,
        },
      });
    } catch (error) {
      window.console.log(`"Procedures#create" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/procedures/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Procedures#delete" error ${error}`);
    }
  }

  // POST /api/react/procedures/:id/finalize
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async finalize(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/procedures/${recordVersionID}/finalize`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
        return;
      }

      return true;
    } catch (error) {
      window.console.log(`"Procedures#finalize" error ${error}`);
    }
  }

  // POST /api/react/procedures/:id/unlock
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unlock(recordVersionID) {
    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/procedures/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });

      return data;
    } catch (error) {
      window.console.log(`"Procedures#unlock" error ${error}`);
    }
  }

  // POST /api/react/procedures/:id/archive
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async archive(recordVersionID) {
    const data = { identifier: "procedures" };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/procedures/${recordVersionID}/archive`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"Procedures#archive" error ${error}`);
    }
  }

  // PUT /api/react/procedures/:id/update_section
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async updateSection(recordVersionID, sectionTagID) {
    const data = { section_tag_id: sectionTagID, identifier: "procedures" };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/procedures/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response?.isAxiosError && response?.message.includes("403")) {
        this.mainStore.toast.setErrorText("Cannot reorder a published record.");
      }
    } catch (error) {
      this.mainStore.toast.setErrorText("Error: Unable to reorder record.");
      window.console.log(`"Procedures#updateSection" error ${error}`);
    }
  }

  // GET /api/react/procedures/archived
  async archivedList() {
    const params = { identifier: "procedures" };

    try {
      const { data } = await legacyApi({
        method: "GET",
        url: `${API_URL}/procedures/archived`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      return data;
    } catch (error) {
      window.console.log(`"Procedures#archivedList" error ${error}`);
    }
  }

  // GET /api/react/procedures/:id/historical
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async historicalList(recordVersionID) {
    try {
      const params = { identifier: "procedures" };
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/procedures/${recordVersionID}/historical`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setHistorical(response.data.record_versions);
    } catch (error) {
      this.setHistorical([]);
      window.console.log(`"Procedures#historicalList" error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAuditTrails(value) {
    if (value) {
      this.auditTrails = value;
    } else {
      this.auditTrails = [];
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setHistorical(value) {
    if (value) {
      this.historical = value;
    } else {
      this.historical = [];
    }
  }

  // Store Helpers

  cleanup() {
    super.cleanup();

    this.setAuditTrails([]);
    this.setHistorical([]);
  }

  abort() {
    this.controller?.abort();
  }
}
