import type { ThemeOptions } from "@mui/material";

export const cssBaseline: ThemeOptions["components"] = {
  MuiCssBaseline: {
    styleOverrides: () => ({
      a: {
        color: "inherit",
        textDecoration: "none",
      },
      body: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      },
      html: {
        height: "100%",
        width: "100%",
      },
    }),
  },
};
