import { LinkButton } from "@themis/ui";
import { useIntl } from "react-intl";
import { generatePath } from "react-router";

import { Typography } from "@/components/Elements";

import { useRecordLinks } from "../../../../../../api/queries/record-links/use-record-links";
import { RecordLinkTable } from "../../../../../../components/record-link-table/record-link-table";
import { useRouteWorkspaceId } from "../../../../../../hooks/use-route-workspace-id";
import {
  InfoPreviewFooter,
  NUM_PREVIEW_ITEMS,
} from "../../../../../misc/info-preview-section/info-preview-section";
import { routes } from "../../../../routes";

interface LinkedRecordsPreviewProps {
  accountId: number;
  disabled?: boolean;
}

export function LinkedRecordsPreview({
  accountId,
  disabled,
}: LinkedRecordsPreviewProps) {
  const workspaceId = useRouteWorkspaceId();

  const { formatMessage } = useIntl();

  const { data } = useRecordLinks("accounts", accountId);
  const recordLinksFrom = data?.record_links_from ?? [];
  const previewRecordLinksFrom = recordLinksFrom.slice(0, NUM_PREVIEW_ITEMS);
  const total = recordLinksFrom.length;

  return (
    <div className="tw-mt-5 tw-flex tw-flex-col tw-gap-2">
      <RecordLinkTable
        recordSelectDisabled={disabled}
        recordId={accountId}
        recordType="accounts"
        linkType="outward_record"
        rows={previewRecordLinksFrom}
      />
      {Boolean(total) && (
        <InfoPreviewFooter>
          <Typography type="h4">
            {formatMessage(
              {
                defaultMessage:
                  "Showing {numberOfShown} of {total} linked records",
              },
              {
                numberOfShown: previewRecordLinksFrom.length,
                total,
              },
            )}
          </Typography>
          {total > NUM_PREVIEW_ITEMS && (
            <LinkButton
              color="tertiary"
              size="sm"
              to={generatePath(routes.accounts.detail.outboundLinks, {
                workspace_id: workspaceId,
                accountId,
              })}
            >
              {formatMessage({ defaultMessage: "View all" })}
            </LinkButton>
          )}
        </InfoPreviewFooter>
      )}
    </div>
  );
}
