import classNames from "classnames";
import { observer } from "mobx-react";
import Pagination from "rc-pagination";
import EN_US from "rc-pagination/lib/locale/en_US";
import React, { useEffect, useRef, useState } from "react";
import { PiCaretUpBold } from "react-icons/pi";

import type { Field, RecordVersion, TableName } from "@/api";
import { Icon } from "@/components/Elements";
import SectionTag from "@/components/table/shared/SectionTag";
import { useMainStore } from "@/contexts/Store";
import { useUpdateFilter } from "@/hooks/useUpdateFilter";
import type { IndexParams } from "@/stores/types/module-store-types";
import type { Section } from "@/stores/types/section-tags";

import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import MenuActions from "../menu-actions/MenuActions";
import ArchivedPopupDelete from "../policy/ArchivedPopupDelete";
import { useCustomColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ReorderableTable from "../shared/ReorderableTable";
import Sharing from "../shared/Sharing";
import TableMenu from "../shared/tableMenu/TableMenu";
import ProcedurePopupSettings from "./ProcedurePopupSettings";

interface Props {
  activeDateRange?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  afterDelete?: (...args: any[]) => any;
  archived?: boolean;
  fields?: Field[];
  isDropdownOpen?: boolean;
  moduleWorkspaceID?: number;
  recordID?: number;
  recordType?: string;
  recordVersions?: RecordVersion[];
  sectionTag?: Section;
  showCheckbox?: boolean;
  tableName?: TableName;
  sectionId?: number;
  isPaginationVisible?: boolean;
}

const arrowLeftImage = <Icon name="chevronLeft" color="generalMidnightDark" />;
const arrowRightImage = (
  <Icon name="chevronRight" color="generalMidnightDark" />
);

function RecordsSection({
  activeDateRange,
  afterDelete,
  archived,
  fields,
  moduleWorkspaceID,
  recordID,
  recordType,
  recordVersions,
  sectionTag,
  tableName,
  sectionId = -1,
  isPaginationVisible,
  ...props
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // States
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(props.isDropdownOpen);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectMode, setSelectMode] = useState(false);
  const page = mainStore.procedures.pageForSection(sectionId);
  const count = mainStore.procedures.countOfRecordsForSection(sectionId);
  const sectionTableID = `section-table-${sectionTag?.id || 0}`;
  const { getTableFiltersParam } = useUpdateFilter();
  const filters = getTableFiltersParam();

  // Hooks
  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    useCustomColumns({
      sectionTableID,
    });

  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
    setSortBy,
  } = useSortingAndPagination({
    store: mainStore.procedures,
    page,
    sectionTagId: sectionTag?.id,
  });

  useEffect(() => {
    return () => mainStore.procedures.cleanup();
  }, []);

  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    fetchIndexForPage(1);
  }, [sortByFieldName, sortByDirection]);

  // Variables
  const { workspaceID, isAdmin, activeWorkspace } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const visibleFields = fields || mainStore.fields.visibleFields;
  const isArchived = tableName === "Archived" || archived;
  const isProceduresFinalized = tableName === "ProceduresFinalized";
  const isProceduresArchived = tableName === "ProceduresArchived";
  const isProceduresDrafts = tableName === "ProceduresDrafts";
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;

  // Functions
  const dropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const addNewProcedure = async () => {
    if (selectMode && showCheckbox) {
      return;
    }

    await mainStore.procedures.create(workspaceID, sectionTag?.id);
  };

  const deleteSectionTag = () => {
    mainStore.sectionTags.delete(moduleWorkspaceID, sectionTag?.id);
    mainStore.toast.setText("Section has been deleted!");
  };

  const renameClick = () => {
    setIsEditMode(true);
  };
  const resetMenuActions = () => {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  const onPageChange = async (newPage: number) => {
    await fetchIndexForPage(newPage);
  };

  const fetchIndexForPage = async (newPage: number) => {
    const params: IndexParams = {
      workspaceID: activeWorkspace?.id,
      page: newPage,
      tableName: tableName || undefined,
      sectionTagId: sectionId,
      ...(sortByFieldName && {
        sortParams: {
          field_name: sortByFieldName,
          direction: sortByDirection || "ASC",
        },
      }),
      ...(filters && { tableFilters: filters }),
    };

    await mainStore.procedures.index(params);
  };

  const renderFields = visibleFields.map((field) => (
    <ModuleTableColumn
      key={field.name}
      field={field}
      setSortBy={setSortBy}
      // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
      setSortByDirection={setSortByDirection}
      setSortByFieldName={setSortByFieldName}
      onDelete={deleteColumn}
      onRename={renameColumn}
    />
  ));

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderRow = (recordVersion, idx) => (
    <ModuleRecordVersion
      key={recordVersion.id}
      isLockedRow={recordVersion.table_name !== "ProceduresDrafts"}
      isLockedArchiveTable={archived}
      recordVersion={recordVersion}
      fields={visibleFields}
      moduleWorkspaceID={moduleWorkspaceID}
      tableID={recordVersion.table_id}
      tableName={recordVersion.table_name}
      currentTableName={tableName}
      archived={archived}
      moduleIdentifier="procedures"
      showCheckboxFromSlideMenu={props.showCheckbox}
      inSelectMenuActionMode={selectMode}
      isDraggable={isProceduresDrafts}
      isSorted={!!sortByFieldName}
      order={idx}
    />
  );

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const renderRows = recordVersions.map(renderRow);

  return (
    <>
      {sectionTag && (
        <div
          className={classNames("tw-flex tw-items-center", {
            "tw-ml-[18px]": isProceduresDrafts,
          })}
          data-testid="dropdown-section"
          data-record-id={recordID?.toString()}
        >
          {(isProceduresDrafts || isArchived) && (
            <div
              className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-[7px] hover:tw-rounded-md hover:tw-bg-neutral-100"
              onClick={dropdownClick}
            >
              <PiCaretUpBold
                className={classNames(
                  "tw-h-[18px] tw-w-[18px] tw-origin-center tw-text-neutral-500",
                  {
                    "tw-rotate-180": !isDropdownOpen,
                    "tw-rotate-0 ": isDropdownOpen,
                  },
                )}
              />
            </div>
          )}

          {sectionTag.title && (
            <SectionTag
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              sectionTagID={sectionTag?.id}
              sectionTagTitle={sectionTag?.title}
            />
          )}

          {archived && (
            <>
              <div className="archived-label">
                {recordType === "Policy" && (
                  <div className="label-policy">Policy</div>
                )}

                {recordType === "Procedure" && (
                  <div className="label-procedure">Procedure</div>
                )}

                {recordType === "Training" && (
                  <div className="label-policy">Training</div>
                )}
              </div>

              <ArchivedPopupDelete
                recordID={recordID}
                tableName={recordType}
                afterDelete={afterDelete}
              />

              <div className="archived-date">{activeDateRange}</div>
            </>
          )}

          {!archived && sectionTag.editable && !isEditMode && (
            <>
              {isAdmin && isProceduresDrafts && (
                <Sharing
                  moduleWorkspaceID={moduleWorkspaceID}
                  objectID={sectionTag?.id}
                  selectedDepartmentIDs={sectionTag?.department_ids}
                  selectedUserIDs={sectionTag?.user_ids}
                  tableName={tableName}
                />
              )}

              {canManageSections && (
                <ProcedurePopupSettings
                  onRename={renameClick}
                  onDelete={deleteSectionTag}
                  tableName={tableName}
                />
              )}
            </>
          )}
        </div>
      )}

      {isDropdownOpen && isProceduresDrafts && (
        <div
          className="dropdown-table draggable"
          id={sectionTableID}
          data-testid="procedures-dropdown-table"
        >
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                {!archived && (
                  <div className="procedures-list-column-first-blocks table-column-reorder">
                    {visibleFields.length > 0 && (
                      <TableMenu
                        setShowCheckbox={setShowCheckbox}
                        resetMenuActions={resetMenuActions}
                        selectMode={selectMode}
                        setSelectMode={setSelectMode}
                      />
                    )}
                  </div>
                )}

                {showCheckbox && selectMode ? (
                  <div>
                    <MenuActions resetMenuActions={resetMenuActions} />
                  </div>
                ) : (
                  <div className="list-title-table-wrap">
                    {renderFields}
                    <span className="stretch-cell" />
                  </div>
                )}
              </div>
              <AddColumnButton onAdd={createColumn} />
            </ul>
          </div>

          <ReorderableTable
            recordVersions={recordVersions}
            renderer={renderRow}
            sectionTag={sectionTag}
            isSorted={!!sortByFieldName}
            newRowName={getRecordName("procedures", moduleWorkspaces, true)}
            onAddNewRow={!archived ? addNewProcedure : undefined}
          />
          {isPaginationVisible && (
            <Pagination
              onChange={onPageChange}
              current={page}
              total={count}
              prevIcon={arrowLeftImage}
              nextIcon={arrowRightImage}
              locale={EN_US}
            />
          )}
        </div>
      )}

      {renderRows.length > 0 &&
        (isArchived || isProceduresFinalized || isProceduresArchived) && (
          <div className="dropdown-table" data-testid="archived-section">
            <div className="list-title-table">
              <ul>
                <div className="list-column-wrap">
                  <div className="list-title-table-wrap">
                    {props.showCheckbox && (
                      <div className="checkbox-title-table" />
                    )}
                    {renderFields}
                    <span className="stretch-cell" />
                  </div>
                </div>
                {isProceduresFinalized && (
                  <div className="title-first-block finalized-block" />
                )}
              </ul>
            </div>
            {renderRows}
            {isPaginationVisible && isProceduresFinalized && (
              <Pagination
                onChange={onPageChange}
                current={page}
                total={count}
                prevIcon={arrowLeftImage}
                nextIcon={arrowRightImage}
                locale={EN_US}
              />
            )}
          </div>
        )}
    </>
  );
}

export default observer(RecordsSection);
