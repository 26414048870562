import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  LegacyWebsiteMonitoringGroupChildResultsUpdateMutationRequest,
  LegacyWebsiteMonitoringGroupChildResultsUpdateMutationResponse,
  LegacyWebsiteMonitoringGroupChildResultsUpdatePathParams,
} from "../../models/LegacyWebsiteMonitoringGroupChildResultsUpdate";

/**
 * @description Update Website Monitoring - Group result
 * @summary Update Website Monitoring group result (Legacy)
 * @link /website_monitoring/:monitoring_group_asset_child_id/monitoring_group_child_results_marked_resolved
 */
export async function legacyWebsiteMonitoringGroupChildResultsUpdate(
  monitoringGroupAssetChildId: LegacyWebsiteMonitoringGroupChildResultsUpdatePathParams["monitoring_group_asset_child_id"],
  data: LegacyWebsiteMonitoringGroupChildResultsUpdateMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<LegacyWebsiteMonitoringGroupChildResultsUpdateMutationResponse>["data"]
> {
  const res = await client<
    LegacyWebsiteMonitoringGroupChildResultsUpdateMutationResponse,
    LegacyWebsiteMonitoringGroupChildResultsUpdateMutationRequest
  >({
    method: "put",
    url: `/website_monitoring/${monitoringGroupAssetChildId}/monitoring_group_child_results_marked_resolved`,
    data,
    ...options,
  });
  return res.data;
}
