import { useMemo } from "react";

import type { RecordTypePath } from "../../gen/models";
import { useFields } from "../../queries/fields/use-fields";

export function useSections(
  companyId: number | undefined,
  recordType: RecordTypePath,
) {
  const { data: sectionField } = useFields(
    { companyId, recordType },
    {
      select: (fields) =>
        fields.find((field) => field.field_type === "section"),
    },
  );

  return useMemo(() => {
    return {
      sections: sectionField?.options ?? [],
      sectionsFieldId: sectionField?.id,
    };
  }, [sectionField]);
}
