import React from "react";

import ScoreTag from "../ScoreTag";

interface RiskRegisterScoreTagProps {
  score: number;
  label: string;
  color: string;
}

function RiskRegisterScoreTag({
  score,
  label,
  color,
}: RiskRegisterScoreTagProps) {
  const scoreValue = score ?? 0;
  const fixedScore = scoreValue.toFixed(scoreValue % 1 === 0 ? 1 : 2);
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2">
      <ScoreTag color={color} label={label} />
      <div>{fixedScore}</div>
    </div>
  );
}

export default RiskRegisterScoreTag;
