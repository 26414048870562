import { faker } from "@faker-js/faker";

import type {
  LegacyWebsiteMonitoringGroupAsset200,
  LegacyWebsiteMonitoringGroupAssetPathParams,
  LegacyWebsiteMonitoringGroupAssetQueryResponse,
} from "../models/LegacyWebsiteMonitoringGroupAsset";
import { createMonitoringGroupAsset } from "./createMonitoringGroupAsset";

export function createLegacyWebsiteMonitoringGroupAssetPathParams(): NonNullable<LegacyWebsiteMonitoringGroupAssetPathParams> {
  return { monitoring_group_asset_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupAsset200(): NonNullable<LegacyWebsiteMonitoringGroupAsset200> {
  return { data: createMonitoringGroupAsset() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupAssetQueryResponse(): NonNullable<LegacyWebsiteMonitoringGroupAssetQueryResponse> {
  return { data: createMonitoringGroupAsset() };
}
