import type { PaletteColorOptions } from "@mui/material/styles/createPalette";

export const colors = {
  blue: {
    lightest: "#E9EEFF",
    light: "#B3C3FF",
    main: "#6D8CF9",
    dark: "#163ABC",
    contrastText: "#FFF",
  },
  lightBlue: {
    lightest: "#E8F4FF",
    light: "#B3D9FF",
    main: "#3399FF",
    dark: "#006EDC",
    contrastText: "#FFF",
  },
  purple: {
    lightest: "#F4ECFE",
    light: "#DFC9FB",
    main: "#AA75F0",
    dark: "#6216C2",
    contrastText: "#FFF",
  },
  fuchsia: {
    lightest: "#FCEDFC",
    light: "#FEB3FE",
    main: "#F042F0",
    dark: "#791779",
    contrastText: "#FFF",
  },
  orange: {
    lightest: "#FDE3DD",
    light: "#FFBFB3",
    main: "#F95939",
    dark: "#C32202",
    contrastText: "#FFF",
  },
  yellow: {
    lightest: "#FBF1DC",
    light: "#FEE5B4",
    main: "#FF9900",
    dark: "#CC7A00",
    contrastText: "#FFF",
  },
  green: {
    lightest: "#EDFDDD",
    light: "#99D959",
    main: "#66AA22",
    dark: "#40730C",
    contrastText: "#FFF",
  },
  mint: {
    lightest: "#DDFDF0",
    light: "#A6F2D3",
    main: "#17B575",
    dark: "#237653",
    contrastText: "#FFF",
  },
} satisfies Record<string, PaletteColorOptions & { lightest: string }>;
