import { UserLabel } from "@themis/ui-library/components/data-display/user-label/user-label";
import type { CustomCellRendererProps } from "ag-grid-react";

import {
  type Assignee,
  isContactAssignee,
  isEmailAssignee,
  isUserAssignee,
} from "../../types";

export function AssigneeNameCellRenderer({
  data,
}: CustomCellRendererProps<Assignee>) {
  if (!data) {
    return null;
  }

  if (isUserAssignee(data)) {
    return (
      <UserLabel
        initials={data.initials}
        colorSeed={data.icon_color_index}
        fullName={data.full_name ?? ""}
      />
    );
  }

  if (isContactAssignee(data)) {
    return (
      <UserLabel initials={data.initials} fullName={data.full_name ?? ""} />
    );
  }

  if (isEmailAssignee(data)) {
    return data.email;
  }

  return null;
}
