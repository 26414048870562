import { Link, type LinkProps } from "react-router-dom";

import { MenuItem, type MenuItemProps } from "../menu-item/menu-item";

export interface LinkMenuItemProps
  extends Omit<MenuItemProps, "LinkComponent" | "href"> {
  to: LinkProps["to"];
  replace?: LinkProps["replace"];
}

export function LinkMenuItem({ children, ...rest }: LinkMenuItemProps) {
  return (
    <MenuItem component={Link} {...rest}>
      {children}
    </MenuItem>
  );
}
