import {
  cn,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Popover,
  PopoverContent,
  PopoverTrigger,
  TextInput,
  useToast,
} from "@themis/ui";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import { useRef } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useIntl } from "react-intl";

import type { CompanyInfo } from "../../../../api/legacy/clearbit/types";
import { useClearbit } from "../../../../api/queries/clearbit";
import { useCreateBlob } from "../../../../api/queries/create-blob";
import { ClearbitSuggestion } from "../../../../components/table/clearbit-suggestion/clearbit-suggestion";
import type { AccountInfoSchema } from "./account-info-form/use-validation-schema";

interface WebsiteFieldProps {
  form: UseFormReturn<AccountInfoSchema>;
  disabled?: boolean;
}

export function WebsiteField({ form, disabled }: WebsiteFieldProps) {
  const { formatMessage } = useIntl();
  const websiteInputRef = useRef<HTMLInputElement>(null);
  const popoverContainerRef = useRef<HTMLDivElement | null>(null);
  const { close, isOpen, open } = useOpen();
  const toast = useToast();
  const website = form.watch("website");
  const { data: suggestions } = useClearbit(website || "");
  const { mutateAsync: createBlob } = useCreateBlob({
    onError: () => {
      toast({
        content: formatMessage({
          defaultMessage: "Something went wrong. Could not add logo.",
        }),
        variant: "error",
      });
    },
  });

  const handleSuggestionSelection = async (suggestion: CompanyInfo) => {
    form.setValue("website", suggestion.domain);
    websiteInputRef.current?.blur();

    if (popoverContainerRef.current) {
      popoverContainerRef.current.style.display = "none";
    }

    const { blob } = await createBlob({
      blob: {
        url: suggestion.logo,
      },
    });

    if (blob) {
      form.setValue("logo", {
        url: blob.file_url,
        signedId: blob.signed_id,
      });
    }
  };

  return (
    <FormField
      control={form.control}
      name="website"
      render={({ field }) => (
        <FormItem>
          <FormLabel>
            {formatMessage({ defaultMessage: "Company Website" })}
          </FormLabel>
          <Popover open={isOpen}>
            <PopoverTrigger asChild>
              <div>
                <FormControl>
                  <TextInput
                    {...field}
                    disabled={disabled}
                    autoComplete="off"
                    ref={websiteInputRef}
                    onFocus={() => open()}
                    onBlur={() => close()}
                  />
                </FormControl>
              </div>
            </PopoverTrigger>
            <PopoverContent
              className={cn(
                "tw-flex tw-w-80 tw-flex-col tw-gap-y-2 tw-bg-white tw-p-4",
                {
                  "tw-p-0": !suggestions?.length,
                },
              )}
              side="bottom"
              align="start"
              onOpenAutoFocus={(event) => event.preventDefault()}
              onMouseDown={(event) => event.preventDefault()}
              ref={popoverContainerRef}
            >
              {suggestions?.map((suggestion, index) => (
                <ClearbitSuggestion
                  key={`${suggestion.domain}-${index}`}
                  {...suggestion}
                  onSelect={handleSuggestionSelection}
                />
              ))}
            </PopoverContent>
          </Popover>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
