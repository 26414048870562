import { http } from "msw";

import { createDeleteAttestationMutationResponse } from "../createDeleteAttestation";

export const deleteAttestationHandler = http.delete(
  "*/attestations/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createDeleteAttestationMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
