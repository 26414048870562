import { http } from "msw";

import { createLegacyWebsiteMonitoringGroupChildQueryResponse } from "../createLegacyWebsiteMonitoringGroupChild";

export const legacyWebsiteMonitoringGroupChildHandler = http.get(
  "*/website_monitoring/:monitoring_group_asset_id/monitoring_group_children",
  function handler(info) {
    return new Response(
      JSON.stringify(createLegacyWebsiteMonitoringGroupChildQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
