// eslint-disable-next-line @nx/enforce-module-boundaries
import { TableWrapper } from "@themis/ui-library/components/data-grid/table-wrapper";
import { useDataGridTheme } from "@themis/ui-library/components/data-grid/use-data-grid-theme";
import { styled } from "@themis/ui-library/styles";
import type {
  CellClassParams,
  CellValueChangedEvent,
  ColDef,
  GridReadyEvent,
  HeaderClassParams,
} from "ag-grid-community";
import { AllCommunityModule } from "ag-grid-community";
import { AgGridReact, type AgGridReactProps } from "ag-grid-react";
import React, { useEffect, useMemo, useRef } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import {
  PiArrowDownBold,
  PiArrowUpBold,
  PiDotsSixVerticalBold,
} from "react-icons/pi";

import AddColumnButton from "./AddColumnButton";
import AttachmentCell from "./Cells/AttachmentCell";
import ButtonCell from "./Cells/ButtonCell";
import CheckboxCell from "./Cells/CheckboxCell";
import CustomTooltip from "./Cells/CustomTooltip";
import DatePickerCell from "./Cells/DatePickerCell";
import IconButtonCell from "./Cells/IconButtonCell";
import IdentifierCellEditor from "./Cells/IdentifierCellEditor";
import IdentifierCellRenderer from "./Cells/IdentifierCellRenderer";
import ImagePickerCell from "./Cells/ImagePickerCell";
import LinkEditorCell from "./Cells/LinkEditorCell";
import LinkRenderCell from "./Cells/LinkRenderCell";
import SelectCell from "./Cells/SelectCell";
import TextEditorCell from "./Cells/TextEditorCell";
import TextRenderCell from "./Cells/TextRenderCell";
import UsersCell from "./Cells/UsersCell";
import Header from "./Header/Header";
import type { RecordWithId } from "./types";
import { suppressKeyboardEvent } from "./utils/supress-keyboard-event";

export const TableComponent = {
  addColumnButton: AddColumnButton,
  textEditorCell: TextEditorCell,
  textRenderCell: TextRenderCell,
  buttonCell: ButtonCell,
  checkboxCell: CheckboxCell,
  selectCell: SelectCell,
  identifierCellEditor: IdentifierCellEditor,
  identifierCellRenderer: IdentifierCellRenderer,
  imagePickerCell: ImagePickerCell,
  datePickerCell: DatePickerCell,
  iconButtonCell: IconButtonCell,
  attachmentCell: AttachmentCell,
  linkRenderCell: LinkRenderCell,
  linkEditorCell: LinkEditorCell,
  customTooltip: CustomTooltip,
  usersCell: UsersCell,
  agColumnHeader: Header,
} as const;

const Wrapper = styled("div")({
  width: "100%",
  maxHeight: "100%",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
});

export interface TableProps<TData extends RecordWithId> {
  id?: string;
  columns: ColDef<TData>[];
  enablePinning?: boolean;
  rows: TData[];
  width?: string | number;
  resizableColumns?: boolean;
  rowHeight?: number;
  singleClickEdit?: boolean;
  tableProps?: AgGridReactProps<TData>;
  columnDef?: ColDef<TData>;
  rowSelection?: "single" | "multiple";
  domLayout?: AgGridReactProps<TData>["domLayout"];
  onChange?: (changeEvent: CellValueChangedEvent<TData>) => void;
}

/**
 * @deprecated Migrate to ClientDataGrid or ServerDataGrid component from @themis/ui-library
 */
export function Table<TData extends RecordWithId>({
  id,
  columns,
  enablePinning = true,
  rows,
  width = "100%",
  rowHeight = 40,
  resizableColumns = true,
  rowSelection,
  singleClickEdit = true,
  columnDef,
  tableProps,
  domLayout,
  onChange = () => {},
  ...rest
}: TableProps<TData>) {
  const gridRef = useRef<AgGridReact>(null);
  const tableTheme = useDataGridTheme();

  useEffect(() => {
    gridRef.current?.api?.setGridOption("columnDefs", columns);
  }, [columns]);

  const defaultColDef: ColDef<TData> = useMemo(
    () => ({
      suppressKeyboardEvent,
      resizable: resizableColumns,
      cellClassRules: {
        "tw-group/cell": () => true,
        "drag-column": (params: CellClassParams<TData>) =>
          params && !!params.colDef?.rowDrag,
      },
      headerClass: (params: HeaderClassParams) => {
        const colDef = params.colDef as ColDef;
        return colDef && colDef.rowDrag ? "drag-column" : "header-cell";
      },
      tooltipComponent: "customTooltip",
      lockPinned: !enablePinning,
      ...columnDef,
    }),
    [resizableColumns, enablePinning, columnDef],
  );

  function handleCellValueChanged(changeEvent: CellValueChangedEvent) {
    const { oldValue, newValue } = changeEvent;

    if (Array.isArray(newValue) && Array.isArray(oldValue)) {
      if (newValue.join() === oldValue.join()) {
        return;
      }
    }

    if (newValue === oldValue) {
      return;
    }

    onChange(changeEvent);
  }

  const rowClassRules = {
    "tw-group/row": () => true, // Apply 'group' class to all rows
  };

  // popupParent is used to set the parent element for the popup/tooltip.
  // This is required to prevent the popup/tooltip from being clipped by
  // the grid container.
  // https://www.ag-grid.com/react-data-grid/context-menu/#popup-parent
  const popupParent = useMemo<HTMLElement | null>(
    () => document.querySelector("body"),
    [],
  );

  const gridOptions = useMemo(
    () => ({
      icons: {
        rowDrag: () => renderToStaticMarkup(<PiDotsSixVerticalBold />),
        sortAscending: () => renderToStaticMarkup(<PiArrowUpBold />),
        sortDescending: () => renderToStaticMarkup(<PiArrowDownBold />),
      },
      onGridReady: (params: GridReadyEvent) => {
        params.api.sizeColumnsToFit();
      },
      suppressRowClickSelection: true,
      rowSelection,
      singleClickEdit,
      tooltipShowDelay: 500,
    }),
    [rowSelection, singleClickEdit],
  );

  return (
    <Wrapper>
      <TableWrapper
        id={id}
        className="tw-relative tw-min-w-[800px]"
        hasRows={rows.length > 0}
        style={{ width }}
        role="table"
        {...rest}
      >
        <AgGridReact
          ref={gridRef}
          theme={tableTheme}
          suppressScrollOnNewData={false}
          animateRows={false}
          getRowId={(row) => String(row.data.id)}
          components={TableComponent}
          columnDefs={columns}
          rowData={rows}
          domLayout={domLayout ?? "autoHeight"}
          rowHeight={rowHeight || 40}
          rowDragManaged
          defaultColDef={defaultColDef}
          rowClassRules={rowClassRules}
          suppressNoRowsOverlay
          onCellValueChanged={handleCellValueChanged}
          context={{ gridRef }}
          popupParent={popupParent}
          suppressCellFocus
          suppressMultiSort
          accentedSort
          gridOptions={gridOptions}
          modules={[AllCommunityModule]}
          {...tableProps}
        />
      </TableWrapper>
    </Wrapper>
  );
}
