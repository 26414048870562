import { action, makeObservable, observable } from "mobx";

import type { CreatePolicyQueryParams } from "@/api";
import { policiesService } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";
import Paginated from "@/stores/data/Paginated";
import { downloadFile } from "@/stores/helpers/AttachmentHelper";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams } from "../types/module-store-types";
import type { LibraryRecord } from "../types/policies-types";

export default class Policies extends Paginated {
  mainStore: MainStore;

  // Observable objects
  auditTrails = [];
  historical = [];
  libraryRecords: Array<LibraryRecord> = [];

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    super(mainStore);

    makeObservable(this, {
      data: observable,
      auditTrails: observable,
      historical: observable,
      libraryRecords: observable,
      sectionsMetadata: observable,

      setSectionsMetadata: action,
      setData: action,
      setAuditTrails: action,
      setHistorical: action,
      setLibraryRecords: action,
      setPageForSection: action,
      upsertRecordCounts: action,
      updateRecordVersions: action,
    });

    this.mainStore = mainStore;
  }

  async index({
    workspaceID,
    tableName,
    sortParams,
    page = 1,
    sectionTagId = null,
    tableFilters = [],
  }: IndexParams) {
    if (!workspaceID) {
      window.console.warn(
        `Policies#index => workspaceID(${workspaceID}) should be present!`,
      );
      return;
    }

    if (!tableName) {
      window.console.warn(
        `Policies#index => tableName(${tableName}) should be present!`,
      );
      return;
    }

    // Avoid extra API calls
    // - when loading Policies for ALL SectionTags outside of page 1 (all Sections are ONLY loaded on page 1)
    if (sectionTagId === null && page !== 1) {
      return;
    }
    // - when loading Policies for ALL SectionTags with sorting applied (each section is sorted separately)
    if (sectionTagId === null && sortParams) {
      return;
    }

    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        table_title: tableName,
        table_filters: tableFilters,
        section_tag_id: sectionTagId ?? undefined,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
        page,
      };

      const response = await policiesService.listPolicies(workspaceID, params, {
        signal: controller.signal,
      });

      this.mainStore.fields.setList(response.fields);
      this.setPageForSection(page, sectionTagId);
      // @ts-expect-error TS(TS2339) FIXME: Property counts does not exist on type ListPoliciesQueryResponse
      this.upsertRecordCounts(response.counts);

      // If we fetched data for specific SectionTag - ONLY replace RecordVersions for that SectionTag
      this.updateRecordVersions(sectionTagId, response);
    } catch (error) {
      window.console.warn(`"Policies#index for Workspace" error ${error}`);
    }
  }

  async create(workspaceID: number, sectionTagId?: number) {
    const params: CreatePolicyQueryParams = {
      section_tag_id: sectionTagId,
    };

    try {
      await policiesService.createPolicy(workspaceID, params);
    } catch (error) {
      window.console.warn(`"Policies#create for Workspace" error ${error}`);
    }
  }

  // DELETE /api/react/policies/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/policies/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Policies#delete" error ${error}`);
    }
  }

  // POST /api/react/policies/:id/finalize
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async finalize(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/policies/${recordVersionID}/finalize`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
        return;
      }

      return true;
    } catch (error) {
      window.console.log(`"Policies#finalize" error ${error}`);
    }
  }

  // POST /api/react/policies/:id/unlock
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unlock(recordVersionID) {
    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/policies/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });

      return data;
    } catch (error) {
      window.console.log(`"Policies#unlock" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any... Remove this comment to see the full error message
  async fetchFile(url, fileName) {
    try {
      const response = await legacyApi({
        method: "GET",
        url,
        responseType: "blob",
      });

      const blob = response.data;
      downloadFile(blob, fileName);
    } catch (error) {
      window.console.log(`"Policies#fetchFile" error ${error}`);
    }
  }

  // GET /api/react/policies/policies_library_file_url
  // @ts-expect-error TS(7006) FIXME: Parameter 'fileID' implicitly has an 'any... Remove this comment to see the full error message
  async fetchFileUrl(fileID, downloadMode = false) {
    try {
      if (!downloadMode) {
        this.cleanup();
      }

      const params = { policy_library_record_id: fileID };

      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/policies/policies_library_file_url`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      if (!downloadMode) {
        return result.data;
      }

      this.fetchFile(result.data.original.url, result.data.original.file_name);
    } catch (error) {
      window.console.log(`"Policies#policies_library_file_url" error ${error}`);
    }
  }

  // POST /api/react/policies/:id/archive
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async archive(recordVersionID) {
    const data = { identifier: "policy" };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/policies/${recordVersionID}/archive`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"Policies#archive" error ${error}`);
    }
  }

  // GET /api/react/policies/archived
  async archivedList() {
    const params = { identifier: "policy" };

    try {
      const { data } = await legacyApi({
        method: "GET",
        url: `${API_URL}/policies/archived`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      // This one is needed to hide loading animation
      if (data.fields) {
        this.mainStore.fields.setList(data.fields);
      }

      return data;
    } catch (error) {
      window.console.log(`"Policies#archivedList" error ${error}`);
    }
  }

  // GET /api/react/policies/:id/historical
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async historicalList(recordVersionID) {
    const params = { identifier: "policy" };
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/policies/${recordVersionID}/historical`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setHistorical(response.data.record_versions);
    } catch (error) {
      this.setHistorical([]);
      window.console.log(`"Policies#historicalList" error ${error}`);
    }
  }

  // PUT /api/react/policies/:id/update_section
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async updateSection(recordVersionID, sectionTagID) {
    const data = { section_tag_id: sectionTagID, identifier: "policy" };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/policies/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response?.isAxiosError && response?.message.includes("403")) {
        this.mainStore.toast.setErrorText("Cannot reorder a published record.");
      }
    } catch (error) {
      window.console.log(`"Policies#update-section" error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAuditTrails(value) {
    if (value) {
      this.auditTrails = value;
    } else {
      this.auditTrails = [];
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setHistorical(value) {
    if (value) {
      this.historical = value;
    } else {
      this.historical = [];
    }
  }

  setLibraryRecords(value: Array<LibraryRecord>) {
    if (value) {
      this.libraryRecords = value;
    } else {
      this.libraryRecords = [];
    }
  }

  abort() {
    this.controller?.abort();
  }

  // Store Helpers

  cleanup() {
    super.cleanup();
    this.setAuditTrails([]);
    this.setHistorical([]);
    this.setLibraryRecords([]);
  }
}
