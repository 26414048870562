import type {
  PermissionDomain,
  PermissionsResult,
  UserPermissionsParams,
} from "@/api/queries/users/use-user-permissions";
import { useUserPermissions } from "@/api/queries/users/use-user-permissions";

import { useCompanyId } from "./use-company-id";
import { useRouteWorkspaceId } from "./use-route-workspace-id";

type UsePermissionsParams<TDomain extends PermissionDomain> = Omit<
  UserPermissionsParams<TDomain>,
  "companyId" | "workspaceId"
>;

export function usePermissions<
  TDomain extends PermissionDomain,
  TResult extends PermissionsResult<TDomain>,
>({ domain, module }: UsePermissionsParams<TDomain>) {
  const { companyId } = useCompanyId();

  const workspaceId = useRouteWorkspaceId();

  const { data: permissions } = useUserPermissions({
    companyId,
    domain,
    module,
    workspaceId,
  });

  return permissions ?? ({} as Partial<TResult>);
}
