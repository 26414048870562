import { useSearchParams } from "@themis/shared/hooks/use-search-params/use-search-params";

import { useUsers } from "@/api/queries/users/use-users";
import type { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

export function useUserMentionsUsers() {
  const [searchParams] = useSearchParams<{
    commentsTab?: CommentTab;
  }>();

  const workspaceId = useRouteWorkspaceId();

  const isPrivateCommentsTabActive = searchParams.commentsTab === "internal";

  const { data: users = [] } = useUsers(workspaceId, {
    select: (data) =>
      data.filter(
        (user) => !isPrivateCommentsTabActive || user.is_internal_user,
      ),
  });

  return users;
}
