import { zodResolver } from "@hookform/resolvers/zod";
import { Form } from "@themis/ui";
import { AttributeCard } from "@themis/ui-library/components/data-display/attribute-card/attribute-card";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { CircularProgress } from "@themis/ui-library/components/feedback/circular-progress/circular-progress";
import { Button } from "@themis/ui-library/components/inputs/button/button";
import { Page } from "@themis/ui-library/components/layout/page/page";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { Breadcrumbs } from "@themis/ui-library/components/navigation/breadcrumbs/breadcrumbs";
import { Link } from "@themis/ui-library/components/navigation/link/link";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import { useForm, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";
import { generatePath, useHistory } from "react-router";

import type { Attestation, RecordTypePath } from "@/api";
import { useLayoutFields } from "@/api/utils/fields/use-layout-fields";
import { DeleteRecordsPopover } from "@/components/records/delete-records-popover/delete-records-popover";
import ExportButton from "@/features/misc/ExportButton";
import { useCompanyId } from "@/hooks/use-company-id";

import { DynamicValue } from "../fields/dynamic-value/dynamic-value";
import { AttestationFormFields } from "./attestation-form-fields/attestation-form-fields";
import {
  useAttestationDetailValidationSchema,
  type ValidationSchema,
} from "./hooks/use-attestation-detail-validation-schema/use-attestation-detail-validation-schema";

const recordType: RecordTypePath = "attestations";

interface OverviewModule {
  /**
   * Format the title as necessary when passing in the prop
   * since react-intl doesn't support dynamic values for formatMessage
   */
  title: string;
  route: string;
}

interface AttestationFormProps {
  handleSubmit: (values: ValidationSchema) => void;
  handleSaveAndSend: () => void;
  attestation?: Attestation;
  overview: OverviewModule;
  workspaceId: number;
}

export function AttestationForm({
  handleSubmit: parentHandleSubmit,
  handleSaveAndSend,
  attestation,
  overview,
  workspaceId,
}: AttestationFormProps) {
  const { companyId = -1 } = useCompanyId();
  const history = useHistory();
  const { customFields } = useLayoutFields({
    companyId,
    recordType: "attestations",
    workspaceId,
    layoutType: "detail",
  });

  const validationSchema = useAttestationDetailValidationSchema(customFields);

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: attestation?.name ?? undefined,
      dueDate: attestation?.due_date,
      ownerId: attestation?.owner_id ?? undefined,
      description: attestation?.description ?? undefined,
      message: attestation?.message ?? "",
    },
  });

  const deletePopover = useOpen();

  const { formatMessage, formatDate } = useIntl();

  const watch = useWatch({ control: form.control });

  const handleSubmit = form.handleSubmit(parentHandleSubmit);

  return (
    <Page.Container>
      <Page.Header
        title={
          <Breadcrumbs>
            <Link
              to={generatePath(overview.route, {
                workspace_id: workspaceId,
              })}
            >
              {overview.title}
            </Link>

            <Typography>
              {attestation?.name ||
                formatMessage({ defaultMessage: "Add new attestation" })}
            </Typography>
          </Breadcrumbs>
        }
        actions={<ExportButton recordType={recordType} />}
      />
      <Page.Content maxWidth="md">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack direction="row" alignItems="center">
            <AttributeCard
              label={formatMessage({ defaultMessage: "Status" })}
              value={
                <DynamicValue
                  record={attestation || { status: "draft" }}
                  fieldPath="status"
                  recordType="attestations"
                />
              }
            />

            <AttributeCard
              label={formatMessage({ defaultMessage: "Sent Date" })}
              value={
                attestation?.sent_date
                  ? formatDate(attestation.sent_date, {
                      dateStyle: "short",
                    })
                  : formatMessage({
                      defaultMessage: "N/A",
                    })
              }
            />
          </Stack>

          {attestation && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography color="text.secondary">
                {formatMessage({ defaultMessage: "Progress" })}
              </Typography>

              <CircularProgress
                variant="determinate"
                size={28}
                value={attestation.completion_percentage}
              />
              <Typography color="text.secondary">
                {attestation.completion_percentage}%
              </Typography>
            </Stack>
          )}
        </Stack>
        <Form {...form}>
          <Stack spacing={1} component="form" onSubmit={handleSubmit}>
            <Typography variant="h5">
              {attestation
                ? formatMessage({
                    defaultMessage: "Attestation Details",
                  })
                : formatMessage({
                    defaultMessage: "Add Attestation Details",
                  })}
            </Typography>

            <AttestationFormFields />

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
            >
              <Button type="submit">
                {formatMessage({ defaultMessage: "Save" })}
              </Button>
              <Button
                color="info"
                disabled={!watch.message}
                onClick={handleSaveAndSend}
              >
                {formatMessage({ defaultMessage: "Save & Send" })}
              </Button>
              {attestation && (
                <Button
                  color="neutral"
                  type="button"
                  onClick={deletePopover.open}
                >
                  {formatMessage({ defaultMessage: "Delete attestation" })}
                </Button>
              )}
            </Stack>
          </Stack>
        </Form>
        {attestation && (
          <DeleteRecordsPopover
            onClose={deletePopover.close}
            open={deletePopover.isOpen}
            recordType="attestations"
            ids={[attestation.id]}
            onDelete={() =>
              history.push(
                generatePath(overview.route, {
                  workspace_id: workspaceId,
                }),
              )
            }
          />
        )}
      </Page.Content>
    </Page.Container>
  );
}
