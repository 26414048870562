import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { LegacyWebsiteMonitoringGroupResultUpdateMutationRequest } from "app/javascript/api/gen/models/LegacyWebsiteMonitoringGroupResultUpdate";

import type {
  LegacyWebsiteMonitoringGroupAssetUpdateMutationRequest,
  LegacyWebsiteMonitoringGroupChildQueryParams,
  LegacyWebsiteMonitoringGroupChildResultsUpdateMutationRequest,
} from "../gen";
import {
  legacyWebsiteMonitoringGroupAsset,
  legacyWebsiteMonitoringGroupAssetUpdate,
  legacyWebsiteMonitoringGroupChild,
  legacyWebsiteMonitoringGroupChildResults,
  legacyWebsiteMonitoringGroupChildResultsUpdate,
  legacyWebsiteMonitoringGroupResult,
  legacyWebsiteMonitoringGroupResults,
  legacyWebsiteMonitoringGroupResultUpdate,
  legacyWebsiteMonitoringGroups,
} from "../gen/axios/websiteMonitoringController";

export const WEBSITE_MONITORING_GROUPS_QUERY_KEY = "website_monitoring_groups";
export const WEBSITE_MONITORING_RESULTS_QUERY_KEY =
  "website_monitoring_results";
export const WEBSITE_MONITORING_CHILDREN_QUERY_KEY =
  "website_monitoring_children";

/**
 * @deprecated
 */
export function useMonitoringGroups(companyId: number) {
  return useQuery({
    queryKey: [WEBSITE_MONITORING_GROUPS_QUERY_KEY, companyId],
    queryFn: () => legacyWebsiteMonitoringGroups(companyId),
    enabled: !!companyId,
  });
}

/**
 * @deprecated
 */
export function useMonitoringGroupAsset(monitoringGroupAssetId: number) {
  return useQuery({
    queryKey: [WEBSITE_MONITORING_GROUPS_QUERY_KEY, monitoringGroupAssetId],
    queryFn: () => legacyWebsiteMonitoringGroupAsset(monitoringGroupAssetId),
    enabled: !!monitoringGroupAssetId,
  });
}

/**
 * @deprecated
 */
export function useMonitoringGroupChildren(
  monitoringGroupAssetId: number,
  queryParams: LegacyWebsiteMonitoringGroupChildQueryParams = {},
) {
  return useQuery({
    queryKey: [
      WEBSITE_MONITORING_CHILDREN_QUERY_KEY,
      monitoringGroupAssetId,
      queryParams,
    ],
    queryFn: () =>
      legacyWebsiteMonitoringGroupChild(monitoringGroupAssetId, queryParams),
    enabled: !!monitoringGroupAssetId && !!queryParams,
    placeholderData: (prevData) => prevData,
  });
}

/**
 * @deprecated
 */
export function useMonitoringGroupChildResults(
  monitoringGroupAssetChildId: number,
) {
  return useQuery({
    queryKey: [
      WEBSITE_MONITORING_RESULTS_QUERY_KEY,
      monitoringGroupAssetChildId,
    ],
    queryFn: () =>
      legacyWebsiteMonitoringGroupChildResults(monitoringGroupAssetChildId),
    enabled: !!monitoringGroupAssetChildId,
  });
}

/**
 * @deprecated
 */
export function useMonitoringGroupResults(monitoringGroupAssetId: number) {
  return useQuery({
    queryKey: [WEBSITE_MONITORING_RESULTS_QUERY_KEY, monitoringGroupAssetId],
    queryFn: () => legacyWebsiteMonitoringGroupResults(monitoringGroupAssetId),
    enabled: !!monitoringGroupAssetId,
  });
}

/**
 * @deprecated
 */
export function useMonitoringGroupResult(monitoringGroupResultId: number) {
  return useQuery({
    queryKey: [WEBSITE_MONITORING_RESULTS_QUERY_KEY, monitoringGroupResultId],
    queryFn: () => legacyWebsiteMonitoringGroupResult(monitoringGroupResultId),
    enabled: !!monitoringGroupResultId,
  });
}

/**
 * @deprecated
 */
export function useMonitoringGroupChildResultsUpdate({
  monitoringGroupAssetChildId,
  monitoringGroupAssetId,
  onSuccess = () => {},
  onError = () => {},
}: {
  monitoringGroupAssetChildId: number;
  monitoringGroupAssetId?: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      data: LegacyWebsiteMonitoringGroupChildResultsUpdateMutationRequest,
    ) =>
      legacyWebsiteMonitoringGroupChildResultsUpdate(
        monitoringGroupAssetChildId,
        data,
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          WEBSITE_MONITORING_RESULTS_QUERY_KEY,
          monitoringGroupAssetId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          WEBSITE_MONITORING_RESULTS_QUERY_KEY,
          monitoringGroupAssetChildId,
        ],
      });
      onSuccess();
    },
    onError,
  });
}

/**
 * @deprecated
 */
export function useMonitoringGroupResultUpdate({
  monitoringGroupResultId,
  monitoringGroupAssetChildId,
  monitoringGroupAssetId,
  onSuccess = () => {},
  onError = () => {},
}: {
  monitoringGroupResultId: number;
  monitoringGroupAssetChildId?: number;
  monitoringGroupAssetId?: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (
      data: LegacyWebsiteMonitoringGroupResultUpdateMutationRequest,
    ) =>
      legacyWebsiteMonitoringGroupResultUpdate(monitoringGroupResultId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [
          WEBSITE_MONITORING_RESULTS_QUERY_KEY,
          monitoringGroupAssetId,
          monitoringGroupResultId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          WEBSITE_MONITORING_RESULTS_QUERY_KEY,
          monitoringGroupAssetId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          WEBSITE_MONITORING_RESULTS_QUERY_KEY,
          monitoringGroupAssetChildId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: [
          WEBSITE_MONITORING_RESULTS_QUERY_KEY,
          monitoringGroupResultId,
        ],
      });
      onSuccess();
    },
    onError,
  });
}

/**
 * @deprecated
 */
export function useMonitoringGroupAssetUpdate({
  companyId,
  onSuccess = () => {},
  onError = () => {},
}: {
  companyId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      monitoringGroupAssetId,
      data,
    }: {
      monitoringGroupAssetId: number;
      data: LegacyWebsiteMonitoringGroupAssetUpdateMutationRequest;
    }) => legacyWebsiteMonitoringGroupAssetUpdate(monitoringGroupAssetId, data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [WEBSITE_MONITORING_GROUPS_QUERY_KEY, companyId],
      });
      onSuccess();
    },
    onError,
  });
}
