import { faker } from "@faker-js/faker";

import type { Comment } from "../models/Comment";
import { createFile } from "./createFile";
import { createReview } from "./createReview";

export function createComment(
  data: NonNullable<Partial<Comment>> = {},
): NonNullable<Comment> {
  return {
    ...{
      self: faker.string.alpha(),
      id: faker.number.int(),
      content: faker.string.alpha(),
      created_at: faker.date.anytime().toISOString(),
      last_updated_at: faker.date.anytime().toISOString(),
      parent_id: faker.number.int(),
      private: faker.datatype.boolean(),
      author_id: faker.number.int(),
      resolved: faker.datatype.boolean(),
      tagged_user_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
      review: Object.assign({}, createReview()),
      files: faker.helpers.arrayElements([createFile()]) as any,
    },
    ...data,
  };
}
