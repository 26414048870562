import { useIntl } from "react-intl";

import type { RecordTypePath, Task } from "@/api";
import { taskableStatus } from "@/api";
import { useWorkspace } from "@/api/queries/workspaces/use-workspace";
import { useMainStore } from "@/contexts/Store";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";
import { getRelatableTypeFromRecordType } from "@/utils/get-relatable-task-from-record-type/get-relatable-task-from-record-type";

interface UseOpenTaskDetailParams {
  recordId: number;
  recordName: string;
  recordType: RecordTypePath;
}

export function useOpenTaskDetail({
  recordId,
  recordName,
  recordType,
}: UseOpenTaskDetailParams) {
  const { formatMessage } = useIntl();
  const mainStore = useMainStore();
  const { taskDetail } = mainStore;
  const workspaceId = useRouteWorkspaceId();
  const { data: workspace } = useWorkspace(workspaceId);

  const handleOpenTaskCreation = () => {
    const newTask: Partial<Task> = {
      name: formatMessage(
        { defaultMessage: "New Task for {recordName}" },
        { recordName },
      ),
      status: "not_started",
      visibility: workspace?.is_internal ? "internal" : "public",
      taskables: [
        {
          status: taskableStatus.active,
          id: 0,
          target: {
            id: recordId,
            identifier: recordId.toString(),
            relatable_type: getRelatableTypeFromRecordType(recordType),
            name: recordName || "No Name",
            path: "",
          },
        },
      ],
    };
    taskDetail.open(newTask);
  };

  return handleOpenTaskCreation;
}
