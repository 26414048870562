import { faker } from "@faker-js/faker";

import type { ThemisDocument } from "../models/ThemisDocument";
import { createFile } from "./createFile";

export function createThemisDocument(
  data: NonNullable<Partial<ThemisDocument>> = {},
): NonNullable<ThemisDocument> {
  return {
    ...{
      id: faker.number.int(),
      file: createFile(),
      preview: Object.assign({}, createFile()),
      version: faker.number.int(),
      version_history: faker.helpers.arrayElements([
        {
          id: faker.number.int(),
          version: faker.number.int(),
          filename: faker.string.alpha(),
        },
      ]) as any,
    },
    ...data,
  };
}
