import type { ReactElement, ReactNode } from "react";

import { Stack } from "../../stack/stack";

interface DetailMediaLayoutContainerProps {
  children: ReactNode;
}

export function DetailMediaLayoutContainer({
  children,
}: DetailMediaLayoutContainerProps): ReactElement {
  return (
    <Stack direction="row" overflow="auto" flexGrow={1} spacing={0}>
      {children}
    </Stack>
  );
}
