import type { ColDef } from "ag-grid-community";
import { generatePath } from "react-router-dom";

import type { MonitoringGroupAssetChild } from "@/api";

import { ReviewLinkCell } from "../components/monitoring-group-children-table/review-link-cell/review-link-cell";
import { StatusTag } from "../components/StatusTag";
import { websiteMonitoringRoutes } from "../routes";

export const MONITORING_GROUP_ASSET_CHILDREN_COLUMNS_TABLE: Array<ColDef> = [
  {
    headerName: "",
    width: 30,
    rowDrag: true,
    resizable: false,
  },
  {
    headerName: "Asset Child URL",
    field: "data",
    cellRenderer: "identifierCellRenderer",
    cellRendererParams: (props: { data: MonitoringGroupAssetChild }) => {
      const to = props.data.child_grouped_results_count
        ? generatePath(
            `${websiteMonitoringRoutes.base}${websiteMonitoringRoutes.monitoringGroups}${websiteMonitoringRoutes.monitoringGroupAssetChildResults}`,
            {
              monitoringGroupAssetChildId: props.data.id,
            },
          )
        : undefined;

      return {
        to,
        ...props,
      };
    },
    editable: false,
    minWidth: 450,
    flex: 2,
  },
  {
    headerName: "Status",
    cellRenderer: (props: { data: MonitoringGroupAssetChild }) => (
      <div className="tw-pl-2">
        <StatusTag
          status={
            Number(props.data.child_unresolved_results_count) > 0
              ? "Need Review"
              : "Resolved"
          }
        />
      </div>
    ),
    editable: false,
    minWidth: 140,
    flex: 3,
  },
  {
    headerName: "Total Findings",
    field: "child_grouped_results_count",
    cellRenderer: "textRenderCell",
    type: "integer",
    editable: false,
    minWidth: 130,
    flex: 2,
  },
  {
    headerName: "Unresolved Findings",
    field: "child_grouped_unresolved_results_count",
    cellRenderer: "textRenderCell",
    type: "integer",
    editable: false,
    minWidth: 180,
    flex: 2,
  },
  {
    headerName: "Latest Scan Date",
    cellRenderer: "datePickerCell",
    field: "monitoring_group_asset_child_updated_at",
    minWidth: 160,
  },
  {
    cellRenderer: ReviewLinkCell,
    width: 90,
    minWidth: 90,
    flex: 2,
    type: "boolean",
    pinned: "right",
  },
];
