import type { RecordTypePath, RelatableRelatableType } from "@/api";
import { recordTypePath } from "@/api";

const recordTypeMap = new Map<RecordTypePath, RelatableRelatableType>([
  [recordTypePath.accounts, "account"],
  [recordTypePath.trainings, "training"],
]);

export function getRelatableTypeFromRecordType(
  recordType: RecordTypePath,
): RelatableRelatableType {
  return recordTypeMap.get(recordType) || "record";
}
