import { Stack } from "@themis/ui";
import { useIntl } from "react-intl";

export const PoliciesLibraryDescription = () => {
  const { formatMessage } = useIntl();

  return (
    <Stack>
      <h3>{formatMessage({ defaultMessage: "What are policy templates?" })}</h3>

      <p className="tw-max-w-screen-sm">
        {formatMessage({
          defaultMessage:
            "The templates are not legal advice and should only be used as starting precedents and operational best practices. Each product and company is unique, and you should consult with an experienced lawyer licensed in the relevant jurisdiction(s) to tailor the agreement as needed.",
        })}
      </p>
    </Stack>
  );
};
