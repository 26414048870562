import { useCallback } from "react";

import { useHash } from "../../../hooks/use-hash";

function getCommentHash(id: number) {
  return `#comment-${id}`;
}

export function useFocusedComment() {
  const [hash, setHash] = useHash();

  const getIsActive = useCallback(
    (id: number) => {
      return hash === getCommentHash(id);
    },
    [hash],
  );

  const scrollToWhenActive = useCallback(
    (node: HTMLElement | null, id: number) => {
      if (node && getIsActive(id)) {
        node.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    [getIsActive],
  );

  const resetFocused = useCallback(() => {
    setHash("");
  }, [setHash]);

  const updateFocused = useCallback(
    (id: number) => {
      setHash(getCommentHash(id));
    },
    [setHash],
  );

  return {
    getIsActive,
    resetFocused,
    scrollToWhenActive,
    updateFocused,
  };
}
