import { Info } from "@phosphor-icons/react";
import { SvgIcon } from "@themis/ui-library/components/data-display/svg-icon/svg-icon";
import { EmptyStateCard } from "@themis/ui-library/components/surfaces/card/empty-state-card/empty-state-card";
import type { ReactElement } from "react";
import { useIntl } from "react-intl";

export function CommentsEmpty(): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <EmptyStateCard
      variant="elevation"
      elevation={0}
      title={
        <SvgIcon
          component={Info}
          color="neutral"
          fontSize="large"
          inheritViewBox
        />
      }
      description={formatMessage({
        defaultMessage:
          "You don't have any comments. Start leaving comments by typing below.",
      })}
      sx={{
        margin: "auto",
        paddingX: 2,
        flexShrink: 0,
      }}
    />
  );
}
