import type { UncompletedCommentsCount } from "@/api";

import type { IndexRecordsBase } from "../../../stores/types/record-types";
import type { AssessmentScoring } from "./assessment";
import type { AnswerRead } from "./questionnaire-form";
import type { RiskArea } from "./risk-area";

export enum QuestionType {
  RADIO = "radio",
  YES_NO = "yes_no",
  DOCUMENTS = "documents",
  TEXT = "text",
}

export enum RiskType {
  CONTROL = "control",
  INHERENT = "inherent",
}

interface BaseQuestionChoice {
  text: string;
  position?: number;
  base_score?: number;
  red_flag?: boolean;
}

export type QuestionChoiceRead = BaseQuestionChoice & {
  id: number;
  depending_question_ids: number[];
};

export type QuestionChoice = BaseQuestionChoice &
  Destroyable & {
    id: number;
    isNew: boolean;
  };

export type QuestionChoiceUpsert = BaseQuestionChoice &
  Destroyable & {
    id?: number;
    ref: number;
  };

export interface BaseQuestion<
  TQuestionType extends QuestionType = QuestionType,
> {
  text: string;
  description?: string;
  input_type: TQuestionType;
  risk_type: RiskType;
  risk_area_id?: RiskArea["id"];
  position?: number;
  weight?: number;
  question_group_id?: number;
  is_optional: boolean;
  assignments: Assignment[];
  responders: Assignment[];
  followup_ref_question_id?: number;
  uncompleted_comments_count?: UncompletedCommentsCount;
  has_follow_up_questions?: boolean;
}

export type QuestionRead = BaseQuestion & {
  id: number;
  question_choices: QuestionChoiceRead[];
  depending_question_choice_ids: number[];
  answer: AnswerRead | null;
  question_group_id: number;
  uncompleted_comments_count: UncompletedCommentsCount;
};

export type Question<TQuestionType extends QuestionType = QuestionType> =
  BaseQuestion<TQuestionType> &
    Destroyable & {
      id: number;
      isNew: boolean;
      choices: QuestionChoice[];
      triggeredBy: Pick<QuestionChoice, "id" | "text">[];
      triggerQuestions: Question[];
      assignments: Assignment[];
    };

export type QuestionUpsert = Omit<BaseQuestion, "assignments"> &
  Destroyable & {
    id?: number;
    question_choices_attributes: QuestionChoiceUpsert[];
    trigger_choice_refs: QuestionChoiceUpsert["ref"][];
    group_ref: number;
    assignments_attributes: Assignment[];
  };

export enum QuestionnaireStatus {
  NOT_STARTED = "not_started",
  INCOMPLETE = "incomplete",
  READY_FOR_REVIEW = "ready_for_review",
  IN_REVIEW = "in_review",
  FINALIZED = "finalized",
}

export interface BaseQuestionnaire {
  id: number;
  completed?: boolean;
  completed_at?: string;
  control_questions_count: number;
  inherent_questions_count: number;
  is_internal: boolean;
  name: string;
  workspace_id: number;
  record_version_id: number;
  status: string;
  summary?: string;
  external_id: string;
  external_password?: string;
  risk_methodology_id: number;
  completion_percentage: number;
  created_at: string;
  last_updated_at: string;
  last_updated_by_user_id?: number;
  responder_ids: number[];
  reviewer_ids: number[];
  due_date: Date | string | null;
}

export interface QuestionnaireMeta {
  total_count: number;
  page: number;
  limit: number;
  next_page: boolean;
}

export type QuestionnaireRead = BaseQuestionnaire & {
  is_template: boolean;
  assignments: Assignment[];
  questions: QuestionRead[];
  question_groups: QuestionGroupRead[];
  scoring?: AssessmentScoring;
};

export type CWQuestionnaireRead = BaseQuestionnaire & {
  id: number;
  external_id: string;
  name: string;
  status: string;
  completion_percentage: string;
  assessment_id: number;
  is_shared: boolean;
};

export type QuestionGroup = Destroyable & {
  id: number;
  isNew: boolean;
  name: string;
  position: number;
  assignments: Assignment[];
  responders: Assignment[];
  questions: Question[];
};

export type Questionnaire = BaseQuestionnaire & {
  groups: QuestionGroup[];
};

export interface QuestionGroupRead {
  id: number;
  text: string;
  position: number;
  summary?: string;
  assignments: Assignment[];
  responders: Assignment[];
}

type QuestionGroupUpsert = Omit<
  QuestionGroupRead,
  "assignments" | "responders" | "id"
> &
  Destroyable &
  Ref & {
    assignments_attributes: Assignment[];
    id?: number;
  };

interface Destroyable {
  _destroy?: boolean;
}

interface Ref {
  ref: number;
}

export interface QuestionnaireRequest {
  questionnaire: {
    name: string;
    risk_methodology_id: number;
    is_internal?: boolean;
    questions_attributes: QuestionUpsert[];
    question_groups_attributes: QuestionGroupUpsert[];
  };
  save_as_template?: boolean;
  password_protected?: boolean;
}

export interface AssigneeUpdateRequest {
  questionnaire: {
    id: number;
    question_groups_attributes: {
      id: number;
      assignments_attributes: Assignment[];
    };
  };
  name: string;
}

export interface SummaryOverallRequest {
  questionnaire: {
    id: number;
    summary?: string;
  };
}

export interface SummaryQuestionGroupRequest {
  questionnaire: {
    id: number;
    question_groups_attributes: {
      id: number;
      summary?: string;
    };
  };
}

export interface GetQuestionnaireResponse {
  questionnaire: QuestionnaireRead;
}

export type GetQuestionnairesResponse = IndexRecordsBase & {
  questionnaires: Array<QuestionnaireRead>;
  meta: QuestionnaireMeta;
};

export type GetCWQuestionnairesResponse = IndexRecordsBase & {
  questionnaires: Array<CWQuestionnaireRead>;
};

export type ReviewerType = "reviewer";
export type ResponderType = "responder";
export type AssignmentType = ReviewerType | ResponderType;

export type Assignment = Destroyable & {
  id?: number;
  user_id: number;
  assignment_type: AssignmentType;
};
