import React from "react";
import type { ReactElement } from "react";

interface ColorCircleProps {
  color: string;
  size?: number | string;
}

export function ColorCircle({
  color,
  size = 16,
}: ColorCircleProps): ReactElement {
  return (
    <div
      className="tw-mr-1 tw-rounded-full"
      style={{ backgroundColor: color, width: size, height: size }}
    />
  );
}
