import { isEqual } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import type { Option } from "react-dropdown";
import Dropdown from "react-dropdown";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";
import type {
  Framework,
  LibraryRecord,
} from "@/stores/types/control-mapping-types";

import deleteIcon from "../../../images/table-image/icon/close-icon2.svg";
import { Icon } from "../../Elements";
import LibraryTable from "./LibraryTable";

const CM_MODULE_IDENTIFIER = "control_mapping";

interface Props {
  moduleWorkspaceID: number;
  libraryType: string;
  onToggleSection?: (sectionTagId: string, isOpen: boolean) => void;
  onChangeGroupedRows?: Dispatch<
    SetStateAction<Record<string, LibraryRecord[]>>
  >;
}

function Library({
  moduleWorkspaceID,
  libraryType,
  onToggleSection,
  onChangeGroupedRows,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [search, setSearch] = useState("");
  const [currentFramework, setCurrentFramework] = useState<
    Framework | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState(true);
  // Variables
  const history = useHistory();
  const { isIW: isInternalWorkspace, workspaceID } = mainStore.context;
  const { canDeleteRecords } = mainStore.userPermissions;
  const { list: moduleWorkspacesList } = mainStore.moduleWorkspaces;
  const { libraryRecords: rows, data } = mainStore.controlMappings;
  const moduleWorkspace = moduleWorkspacesList?.find(
    (item) => item.themis_module.identifier === CM_MODULE_IDENTIFIER,
  );
  const tagTitles = mainStore.tags.tags.map((tag) => tag.title);
  const libraryIsEnabled =
    isInternalWorkspace || moduleWorkspace?.library_enabled;
  const currentModuleWorkspaceID = moduleWorkspaceID || moduleWorkspace?.id;

  const filteredRows = rows.filter((item) => {
    const includesSearch =
      search === "" ||
      item.control_category?.toLowerCase().includes(search.toLowerCase()) ||
      item.control_mapping_type?.toLowerCase().includes(search.toLowerCase()) ||
      item.title?.toLowerCase().includes(search.toLowerCase()) ||
      item.description?.toLowerCase().includes(search.toLowerCase());

    const includesFramework =
      !currentFramework ||
      item.frameworks?.some(
        (framework) => framework.title === currentFramework.title,
      );

    return includesSearch && includesFramework;
  });

  // Filter grouping
  const groupByOptions = {
    ControlCategory: {
      name: "Control Category",
      value: "control_category",
    },
    Type: {
      name: "Type",
      value: "control_mapping_type",
    },
    Frameworks: {
      name: "Frameworks",
      value: "frameworks",
    },
  };

  // @ts-expect-error TS(7006) FIXME: ...
  const handleDropdownClick = (e) => {
    switch (e.value) {
      case "Control Category":
        setCurrGroupByOption(groupByOptions.ControlCategory);
        break;
      case "Type":
        setCurrGroupByOption(groupByOptions.Type);
        break;
      case "Frameworks":
        setCurrGroupByOption(groupByOptions.Frameworks);
        break;
      default:
        setCurrGroupByOption(groupByOptions.Frameworks);
    }
  };

  const [currGroupByOption, setCurrGroupByOption] = useState(
    groupByOptions.Frameworks,
  );
  const allGroupByNames = Object.values(groupByOptions).map(
    (option) => option.name,
  );

  // Effects
  useEffect(() => {
    if (!currentModuleWorkspaceID) {
      return;
    }

    mainStore.controlMappings.setData({
      ...data,
      module_workspace_id: currentModuleWorkspaceID,
    });
    setIsLoading(true);
    mainStore.controlMappings.fetchLibrary(
      Number(currentModuleWorkspaceID),
      libraryType,
    );
    setIsLoading(false);
  }, [currentModuleWorkspaceID]);

  // Functions
  const handleFrameworkDropdownClick = (frameworkOption: Option) => {
    const framework = mainStore.tags.tags.find(
      (tag) => tag.title === frameworkOption.value,
    ) as unknown as Framework;
    setCurrentFramework(framework);
  };

  function handleMainCheckboxChange(rowIds: number[], checked: boolean) {
    mainStore.controlMappings.setLibraryRecords(
      rows.map((item) => {
        if (!rowIds.includes(item.id)) {
          return item;
        }
        return { ...item, checked };
      }),
    );
  }

  function handleRegularCheckboxChange(id: number, checked: boolean) {
    mainStore.controlMappings.setLibraryRecords(
      rows.map((item) => (item.id === id ? { ...item, checked } : item)),
    );
  }

  async function createNewLibraryItem() {
    const newItem = await mainStore.controlMappings.createLibraryItem();
    history.push(
      `/workspaces/${workspaceID}/modules/control-mapping/templates/${newItem.id}`,
    );
  }

  function handleDelete(id: number) {
    mainStore.controlMappings.deleteLibraryItem(id);
  }

  function groupByFrameworkOrOthers(
    inputRows: Array<LibraryRecord>,
    key: string,
  ) {
    const groups: Record<string, LibraryRecord[]> = {};

    for (const row of inputRows) {
      if (key === "frameworks") {
        if (row?.frameworks && row.frameworks.length > 0) {
          for (const framework of row.frameworks) {
            const title = framework.title || "~No Framework~";
            if (!groups[title]) {
              groups[title] = [];
            }
            groups[title].push(row);
          }
        } else {
          const noFrameworkGroup = "~No Framework~";
          if (!groups[noFrameworkGroup]) {
            groups[noFrameworkGroup] = [];
          }
          groups[noFrameworkGroup].push(row);
        }
      } else {
        const title =
          (row as unknown as { [key: string]: string })[key] ||
          `~No ${currGroupByOption.name}~`;
        if (!groups[title]) {
          groups[title] = [];
        }
        groups[title].push(row);
      }
    }

    return groups;
  }

  const groupedRows: Record<string, LibraryRecord[]> = groupByFrameworkOrOthers(
    filteredRows,
    currGroupByOption.value,
  );
  const sectionTitles = Object.keys(groupedRows).sort();

  useEffect(() => {
    onChangeGroupedRows?.((previousGroupedRows) => {
      if (!isEqual(previousGroupedRows, groupedRows)) {
        return groupedRows;
      }
      return previousGroupedRows;
    });
  }, [groupedRows, onChangeGroupedRows]);

  // elements
  const renderSearch = (
    <div className="rr-library-tools">
      <div className="controls-search-container  rr-library-search-container">
        <input
          type="text"
          placeholder="Search Control Library"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Icon name="search" color="generalDark" size="de" />
      </div>
    </div>
  );

  return (
    <div className="rr-library-wrapper risk-register-library">
      <div className="filter-header">
        <div className="filter-header-flex">
          <div className="group-by-wrapper column-select">
            <label htmlFor="group-by-dropdown">Grouped by</label>
            <Dropdown
              options={allGroupByNames}
              onChange={handleDropdownClick}
              value={currGroupByOption.name}
            />
          </div>
          <div className="filter-by-wrapper column-select">
            <label>Filter By Framework</label>
            <div className="filter-flex-wrapper">
              <Dropdown
                options={tagTitles}
                onChange={handleFrameworkDropdownClick}
                value={currentFramework?.title}
                placeholder="- Select -"
              />
              <img
                src={deleteIcon}
                onClick={() => setCurrentFramework(undefined)}
                alt="delete-icon"
              />
            </div>
          </div>

          <div className="filter-header-right" />
        </div>
      </div>
      <div className="rr-library-container" data-testid="rr-library-container">
        {!libraryIsEnabled && (
          <div>
            Library is disabled for this workspace. Contact Themis to enable it.
          </div>
        )}
        {libraryIsEnabled && !isLoading && (
          <>
            {renderSearch}
            {libraryType === "themis" && (
              <div className="library-explainer">
                The information and materials available through the Themis
                platform are for informational purposes only and do not
                constitute legal advice. As legal advice must be tailored to the
                specific circumstances of each case, nothing provided on this
                site should be used as a substitute for advice of competent
                counsel.
              </div>
            )}
            {!rows?.length && (
              <div className="create-new-record risk-register library empty active">
                <button
                  type="button"
                  onClick={createNewLibraryItem}
                  data-testid="risk-register-library-add-new"
                >
                  Add First Company Control Library Item +
                </button>
              </div>
            )}
            {sectionTitles.map((sectionTitle) => (
              <LibraryTable
                isOpen={false}
                key={sectionTitle}
                canDeleteRecords={canDeleteRecords}
                isInternalWorkspace={Boolean(isInternalWorkspace)}
                onDeleteRecord={handleDelete}
                onSelectAllChange={handleMainCheckboxChange}
                onSelectChange={handleRegularCheckboxChange}
                rows={groupedRows[sectionTitle] as LibraryRecord[]}
                title={sectionTitle}
                libraryType={libraryType}
                onCreate={() => createNewLibraryItem()}
                onToggleSection={onToggleSection}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default observer(Library);
