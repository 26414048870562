import { useSearchParams } from "@themis/shared/hooks/use-search-params/use-search-params";
import {
  Button,
  ConfirmationPopup,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  useToast,
} from "@themis/ui";
import { useState } from "react";
import {
  PiDotsThreeOutlineFill,
  PiDotsThreeOutlineVerticalFill,
} from "react-icons/pi";
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import type { Finding } from "@/api";
import {
  useCloseFinding,
  useDeleteFinding,
  useOpenFinding,
  useUnlinkFindingRecord,
} from "@/api/queries/findings";
import { useMainStore } from "@/contexts/Store";

import { findingRoutes } from "../pages/routes";
import type { SendFindingSearchParams } from "./FindingsList";

const STATUS_OPEN = "open";
const STATUS_CLOSED = "closed";

function ActionButton({
  finding,
  handleClose,
  handleOpen,
}: {
  finding: Finding;
  handleClose: () => void;
  handleOpen: () => void;
}) {
  const mainStore = useMainStore();
  const isIssueManagementAdded =
    mainStore.moduleWorkspaces.isModuleAdded("issue_management");

  const [searchParams, setSearchParams] =
    useSearchParams<SendFindingSearchParams>();

  if (finding.status === STATUS_OPEN && !finding.linked_records.length) {
    return (
      <>
        <Button
          aria-describedby={`finding-name-${finding.id}`}
          className="tw-w-[86px]"
          onClick={() => {
            setSearchParams({
              ...searchParams,
              send_finding: String(finding.id),
            });
          }}
          disabled={!isIssueManagementAdded}
        >
          Send
        </Button>
        <span id={`finding-name-${finding.id}`} className="tw-hidden">
          Send finding {finding.name} to Issue Management
        </span>
      </>
    );
  }

  if (finding.status === STATUS_OPEN && !!finding.linked_records.length) {
    return (
      <>
        <Button
          aria-describedby={`close-finding-${finding.id}`}
          className="tw-w-[86px]"
          color="tertiary"
          onClick={handleClose}
        >
          Close
        </Button>
        <span id={`close-finding-${finding.id}`} className="tw-hidden">
          Close finding {finding.name}
        </span>
      </>
    );
  }

  if (finding.status === STATUS_CLOSED) {
    return (
      <>
        <Button
          aria-describedby={`reopen-finding-${finding.id}`}
          className="tw-w-[86px]"
          color="tertiary"
          onClick={handleOpen}
        >
          Reopen
        </Button>
        <span id={`reopen-finding-${finding.id}`} className="tw-hidden">
          Reopen finding {finding.name}
        </span>
      </>
    );
  }
}

export function FindingActions({
  finding,
  isDetailView,
  findingsListPath,
}: {
  finding: Finding;
  isDetailView?: boolean;
  findingsListPath?: string;
}) {
  const toast = useToast();
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();
  const history = useHistory();

  const { mutate: deleteFinding } = useDeleteFinding({
    workspaceId: Number(workspace_id),
    recordId: Number(finding.record_id),
    findingId: finding.id,
    onSuccess: () => {
      toast({
        content: "Finding has been removed!",
        variant: "success",
      });

      if (findingsListPath) {
        history.push(findingsListPath);
      }
    },
    onError: () => {
      toast({
        content: "Failed to remove finding!",
        variant: "error",
      });
    },
  });
  const { mutateAsync: openFinding } = useOpenFinding({
    workspaceId: Number(workspace_id),
    recordId: Number(finding.record_id),
    findingId: finding.id,
    onSuccess: () => {
      toast({
        content: "Finding has been opened!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to open finding!",
        variant: "error",
      });
    },
  });
  const { mutateAsync: closeFinding } = useCloseFinding({
    workspaceId: Number(workspace_id),
    recordId: Number(finding.record_id),
    findingId: finding.id,
    onSuccess: () => {
      toast({
        content: "Finding has been closed!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to close finding!",
        variant: "error",
      });
    },
  });
  const { mutate: unlinkFindingRecord } = useUnlinkFindingRecord({
    workspaceId: Number(workspace_id),
    recordId: Number(finding.record_id),
    linkedRecordId: finding.linked_records[0]?.id,
    findingId: finding.id,
    onSuccess: () => {
      toast({
        content: "Record unlinked successfully!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to unlink record!",
        variant: "error",
      });
    },
  });

  const { url } = useRouteMatch();
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  function handleDelete() {
    deleteFinding();
  }

  function handleClickDelete() {
    setIsConfirmationOpen(true);
  }

  function handleCancelDelete() {
    setIsConfirmationOpen(false);
  }

  function handleOpen() {
    openFinding();
  }

  function handleClose() {
    closeFinding();
  }

  function handleUnlinkRecord() {
    unlinkFindingRecord();
  }

  return (
    <div className="tw-flex tw-items-center tw-gap-1">
      <ActionButton
        finding={finding}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton
            color={isDetailView ? "tertiary" : "transparent"}
            size="lg"
            Icon={
              isDetailView
                ? PiDotsThreeOutlineFill
                : PiDotsThreeOutlineVerticalFill
            }
            variant={isDetailView ? "square" : "vertical"}
            aria-describedby={`finding-context-menu-${finding.id}`}
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">
          {!isDetailView && (
            <DropdownMenuItem
              onClick={() =>
                history.push(
                  generatePath(`${url}${findingRoutes.details}`, {
                    findingId: finding.id,
                  }),
                )
              }
            >
              View Finding Detail
            </DropdownMenuItem>
          )}
          {finding.status === STATUS_OPEN && !finding.linked_records.length && (
            <DropdownMenuItem onClick={handleClose}>Close</DropdownMenuItem>
          )}
          {!!finding.linked_records.length && !isDetailView && (
            <DropdownMenuItem onClick={handleUnlinkRecord}>
              Unlink Record
            </DropdownMenuItem>
          )}
          <DropdownMenuItem onClick={handleClickDelete}>
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ConfirmationPopup
        anchor
        align="end"
        title="Delete Finding"
        content="Actions are non-reversible. Are you sure you want to proceed?"
        open={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
        sideOffset={20}
      />
      <span id={`finding-context-menu-${finding.id}`} className="tw-hidden">
        Finding context menu for {finding.name}
      </span>
    </div>
  );
}
