import type { Comment } from "@themis/api/gen/models/comment";
import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import { Button } from "@themis/ui-library/components/inputs/button/button";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import type { ReactElement } from "react";
import { useState } from "react";
import { useIntl } from "react-intl";

import { useUpdateComment } from "@/api/queries/comments/use-update-comment";
import type { User } from "@/stores/types/user-types";

import { CommentsInput } from "../comments-input/comments-input";

interface EditCommentProps {
  comment: Comment;
  recordId: number;
  recordTypePath: RecordTypePath;
  usersMap: Record<string, User>;
  onClose: () => void;
}

export function EditComment({
  comment,
  recordId,
  recordTypePath,
  usersMap,
  onClose,
}: EditCommentProps): ReactElement {
  const { formatMessage } = useIntl();

  const [inputValue, setInputValue] = useState(comment.content);

  const disableSubmit = !inputValue || inputValue === comment.content;

  const updateComment = useUpdateComment({
    recordId,
    recordType: recordTypePath,
  });

  const handleSubmit = () => {
    updateComment.mutate(
      {
        commentId: comment.id,
        data: {
          comment: {
            content: inputValue,
          },
        },
      },
      {
        onSuccess: () => {
          setInputValue("");
          onClose?.();
        },
      },
    );
  };

  return (
    <>
      <CommentsInput
        autoFocus
        isPending={updateComment.isPending}
        placeholder={formatMessage({ defaultMessage: "Update comment text" })}
        usersMap={usersMap}
        value={inputValue}
        onSubmit={handleSubmit}
        onValueChange={setInputValue}
      />

      <Stack direction="row" alignItems="center">
        <Button
          loading={updateComment.isPending}
          disabled={disableSubmit}
          size="small"
          onClick={handleSubmit}
        >
          {formatMessage({ defaultMessage: "Update" })}
        </Button>
        <Button color="info" size="small" onClick={onClose}>
          {formatMessage({ defaultMessage: "Cancel" })}
        </Button>
      </Stack>
    </>
  );
}
