export const routes = {
  procedures: {
    drafts: "/workspaces/:workspace_id/modules/procedures/drafts",
    finalized: "/workspaces/:workspace_id/modules/procedures/finalized",
    attestation: "/workspaces/:workspace_id/modules/procedures/attestation",
    archived: "/workspaces/:workspace_id/modules/procedures/archived",
    detail: "/workspaces/:workspace_id/modules/procedures/:record_version_id",
    historicalVersions:
      "/workspaces/:workspace_id/modules/procedures/:record_version_id/historical-versions",
    addAttestation:
      "/workspaces/:workspace_id/modules/policy/:record_version_id/attestation/new",
  },
};
