import { http } from "msw";

import { createArchiveRecordMutationResponse } from "../createArchiveRecord";

export const archiveRecordHandler = http.post(
  "*/:record_type/:record_id/archive",
  function handler(info) {
    return new Response(JSON.stringify(createArchiveRecordMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
