import { type ThemeOptions } from "@mui/material";

export const formControlLabel: ThemeOptions["components"] = {
  MuiFormControlLabel: {
    defaultProps: {
      slotProps: {
        typography: {
          color: "textSecondary",
        },
      },
    },
  },
};
