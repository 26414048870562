import React from "react";
import type { ReactElement, ReactNode } from "react";

import { Stack } from "../../../../layout/stack/stack";

interface PreviewRendererContainerProps {
  children: ReactNode;
}

export function PreviewRendererContainer({
  children,
}: PreviewRendererContainerProps): ReactElement {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        minHeight: "300px",
        width: "100%",
        p: 3,
      }}
    >
      {children}
    </Stack>
  );
}
