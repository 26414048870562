import React from "react";
import { useHistory } from "react-router-dom";

import { cn } from "../../lib/utils";
import { Tabs, TabsList, TabsTrigger } from "./Tabs";

export interface TabProps {
  name: string;
  value: string;
  key: string;
  id?: string;
  hasPermission?: boolean;
  tooltip?: string;
  dataTestId?: string;
}

export interface ButtonProps {
  tabIndex: number;
  button: React.ReactNode;
}

export interface HeaderTabsProps {
  tabs: TabProps[];
  tabButtons?: ButtonProps[];
  selectedTab: string;
  onSelectTab?: (tabName: string) => void;
  isLink?: boolean;
  tabsClassName?: string;
  tabsListClassName?: string;
  dataTestId?: string;
  border?: boolean;
}

export function HeaderTabs({
  tabs,
  tabButtons,
  selectedTab,
  onSelectTab,
  isLink,
  tabsClassName,
  tabsListClassName,
  dataTestId,
  border = false,
}: HeaderTabsProps) {
  const history = useHistory();

  return (
    <Tabs
      value={selectedTab}
      onValueChange={
        onSelectTab
          ? onSelectTab
          : (location: string) => {
              if (isLink) {
                history.push(location);
              }
            }
      }
      className={cn(
        "tw-flex tw-h-full tw-items-end tw-border-0",
        tabsClassName,
      )}
    >
      <TabsList
        className={cn(
          "tw-h-[34px] tw-gap-1 tw-rounded-none tw-border-0 tw-pb-0.5",
          {
            "tw-border-b tw-border-solid tw-border-primaryDim-100": border,
          },
          tabsListClassName,
        )}
        data-testid={dataTestId}
      >
        {tabs.map((tab, tabIndex) => {
          const tabButton = tabButtons?.find(
            (btn) => btn.tabIndex === tabIndex,
          );
          if (tab.hasPermission === false) {
            return null;
          }
          return (
            <TabsTrigger
              key={`${tab.name} trigger`}
              value={tab.value}
              className="tw-mb-[1px] tw-rounded-none tw-p-0 tw-px-3 tw-text-sm tw-font-medium tw-text-neutral-500 hover:tw-bg-primaryDim-25 data-[state=active]:tw-mb-0 data-[state=active]:tw-border-0 data-[state=active]:tw-border-b-2 data-[state=active]:tw-border-solid data-[state=active]:tw-border-primary-300 data-[state=active]:tw-bg-neutral-25 data-[state=active]:tw-font-semibold data-[state=active]:tw-text-primary-300"
              data-testid={tab.dataTestId}
            >
              {tab.name}
              {tabButton && tabButton.button}
            </TabsTrigger>
          );
        })}
      </TabsList>
    </Tabs>
  );
}
