import { faker } from "@faker-js/faker";

import type {
  LegacyWebsiteMonitoringGroupResultUpdate200,
  LegacyWebsiteMonitoringGroupResultUpdateMutationRequest,
  LegacyWebsiteMonitoringGroupResultUpdateMutationResponse,
  LegacyWebsiteMonitoringGroupResultUpdatePathParams,
} from "../models/LegacyWebsiteMonitoringGroupResultUpdate";
import { createMonitoringGroupAsset } from "./createMonitoringGroupAsset";
import { createUpdateMonitoringGroupResultRequest } from "./createUpdateMonitoringGroupResultRequest";

export function createLegacyWebsiteMonitoringGroupResultUpdatePathParams(): NonNullable<LegacyWebsiteMonitoringGroupResultUpdatePathParams> {
  return { monitoring_group_result_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupResultUpdate200(): NonNullable<LegacyWebsiteMonitoringGroupResultUpdate200> {
  return { data: createMonitoringGroupAsset() };
}

export function createLegacyWebsiteMonitoringGroupResultUpdateMutationRequest(): NonNullable<LegacyWebsiteMonitoringGroupResultUpdateMutationRequest> {
  return { data: createUpdateMonitoringGroupResultRequest() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupResultUpdateMutationResponse(): NonNullable<LegacyWebsiteMonitoringGroupResultUpdateMutationResponse> {
  return { data: createMonitoringGroupAsset() };
}
