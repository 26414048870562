import type { FieldOption } from "@themis/api/gen/models/fieldOption";
import type { ThemisPaletteColor } from "@themis/ui-library/theme/types/themis-palette";

const colorsMap: Record<string, ThemisPaletteColor> = {
  grey: "neutral",
  blue: "blue",
  lightBlue: "lightBlue",
  purple: "purple",
  fuchsia: "fuchsia",
  orange: "orange",
  yellow: "yellow",
  green: "green",
  mint: "mint",
  red: "error",
};

export function getFieldOptionPaletteColor(
  fieldOption: FieldOption,
): ThemisPaletteColor | undefined {
  const colorName: string | undefined = fieldOption.meta?.color_name;
  if (colorName) {
    return colorsMap[colorName];
  }
}
