import { type ThemeOptions } from "@mui/material";

export const listSubheaderTheme: ThemeOptions["components"] = {
  MuiListSubheader: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.text.secondary,
      }),
    },
  },
};
