import type { ThemeOptions } from "@mui/material";

import { componentsBaseTheme as componentsBase } from "../../../components/components-base.theme";
import { backdrop } from "./backdrop.theme";
import { cssBaseline } from "./css-baseline.theme";

export const components: ThemeOptions["components"] = {
  ...componentsBase,
  ...backdrop,
  ...cssBaseline,
};
