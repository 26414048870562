import type { SlotProps } from "@radix-ui/react-slot";
import type { CustomCellEditorProps } from "ag-grid-react";
import dayjs from "dayjs";
import React, { useMemo } from "react";
import type { DateRange } from "react-day-picker";

import { filterCellProps } from "../../../lib/utils";
import { DatePicker } from "../../Form/DatePicker";

interface SingleDatePickerProps {
  mode: "single";
  value?: Date | undefined;
  dateFormat?: string;
  onValueChange?: (date: Date | undefined) => void;
}
interface MultipleDatePickerProps {
  mode: "multiple";
  value?: Date[] | undefined;
  dateFormat?: string;
  onValueChange?: (date: Date[] | undefined) => void;
}
interface RangeDatePickerProps {
  mode: "range";
  value?: DateRange | undefined;
  dateFormat?: string;
  onValueChange?: (date: DateRange | undefined) => void;
}

type DatePickerProps =
  | SingleDatePickerProps
  | MultipleDatePickerProps
  | RangeDatePickerProps;

export type DatePickerCellProps = SlotProps &
  CustomCellEditorProps &
  DatePickerProps & {
    elementProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
  };

function DatePickerCell({
  value,
  onValueChange,
  mode,
  dateFormat,
  ...props
}: DatePickerCellProps) {
  const filteredProps = filterCellProps({ value, onValueChange, ...props });
  const editable = Boolean(props.colDef?.editable);

  const parseAndFormatDate = (dateString: string) => {
    try {
      // Handle ISO 8601 or other valid date strings
      const parsedDate = dayjs(dateString);
      if (parsedDate.isValid()) {
        return new Date(
          parsedDate.year(),
          parsedDate.month(),
          parsedDate.date(),
        );
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Invalid date string:", dateString);
    }

    return new Date(dateString);
  };

  const calendarProps = useMemo(() => {
    switch (mode) {
      case "multiple":
        return {
          mode: "multiple" as const,
          selected:
            Array.isArray(value) && value.length > 0
              ? value.map((date: string) => parseAndFormatDate(date))
              : undefined,
          onSelect: onValueChange as (date: Date[] | undefined) => void,
        };
      case "range":
        return {
          mode: "range" as const,
          selected:
            value && value.from && value.to
              ? {
                  from: parseAndFormatDate(value.from),
                  to: parseAndFormatDate(value.to),
                }
              : undefined,
          onSelect: onValueChange as (date: DateRange | undefined) => void,
        };
      default:
        return {
          mode: "single" as const,
          selected: value ? parseAndFormatDate(value) : undefined,
          onSelect: onValueChange as (date: Date | undefined) => void,
        };
    }
  }, [mode, value, onValueChange]);

  if (!editable && !calendarProps.selected) {
    return null;
  }

  return (
    <DatePicker
      calendarProps={calendarProps}
      dateFormat={dateFormat}
      className="tw-h-full tw-w-full tw-rounded-none tw-border-0 tw-bg-transparent read-only:tw-cursor-default read-only:tw-bg-transparent read-only:tw-text-neutral-300 group-hover/row:tw-bg-neutral-50"
      defaultOpen={
        !!(
          props.api.getFocusedCell()?.column.getColId() ===
            props.column.getColId() &&
          props.cellStartedEdit &&
          editable
        )
      }
      onClose={() => {
        props.api.stopEditing();
      }}
      readOnly={!editable}
      {...filteredProps}
    />
  );
}

export default DatePickerCell;
