import { http } from "msw";

import { createCreateReviewMutationResponse } from "../createCreateReview";

export const createReviewHandler = http.post(
  "*/:record_type/:record_id/review",
  function handler(info) {
    return new Response(JSON.stringify(createCreateReviewMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
