import { PageContainer } from "./page-container/page-container";
import { PageContent } from "./page-content/page-content";
import { PageHeader } from "./page-header/page-header";
import { PageToolbar } from "./page-toolbar/page-toolbar";

export const Page = {
  Container: PageContainer,
  Header: PageHeader,
  Toolbar: PageToolbar,
  Content: PageContent,
};
