import { faker } from "@faker-js/faker";

import type { MonitoringFinding } from "../models/MonitoringFinding";
import { createFile } from "./createFile";
import { createThemisRecord } from "./createThemisRecord";

export function createMonitoringFinding(
  data?: NonNullable<Partial<MonitoringFinding>>,
): NonNullable<MonitoringFinding> {
  return Object.assign({}, createThemisRecord(), {
    status: faker.helpers.arrayElement<any>([
      "unreviewed",
      "in_review",
      "in_remediation",
      "remediated",
      "false_positive",
      "review_changes",
    ]),
    result_type: faker.helpers.arrayElement<any>([
      "content_monitoring",
      "brand_monitoring",
      "social_media_monitoring",
      "webpage_content_change_monitoring",
    ]),
    sub_type: faker.helpers.arrayElement<any>([
      "documents",
      "images",
      "metadata",
    ]),
    compliance_copy: faker.string.alpha(),
    enforcement_actions: faker.helpers.arrayElements([undefined]) as any,
    reason: faker.string.alpha(),
    rule: faker.string.alpha(),
    recommendation: faker.string.alpha(),
    severity: faker.string.alpha(),
    attachments: faker.helpers.arrayElements([createFile()]) as any,
    policy_name: faker.string.alpha(),
    assignee_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
  });
}
