import { useSearchParams } from "@themis/shared/hooks/use-search-params/use-search-params";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useRef, useState } from "react";
import type { DraggableData, DraggableProps } from "react-draggable";
import Draggable from "react-draggable";
import type { OnChangeHandlerFunc } from "react-mentions";
import Popup from "reactjs-popup";

import type { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import CreativeViewFilePositionComment from "@/components/table/shared/creative-view/CreativeViewFilePositionComment";
import UserMentionsInput from "@/components/table/shared/UserMentionsInput";
import { useMainStore } from "@/contexts/Store";
import { getTopLevelComments } from "@/stores/helpers/CommentsHelpers";

import warningIcon from "../../../../images/table-image/icon/warning-icon.svg";
import { userColors } from "../../../constants";
import Button from "../../../form/Button";
import { isSupportedViewFileType } from "../helpers";
import { scrollIntoViewIfNeeded } from "../helpers/scrollIntoViewIfNeeded";
import CreativeViewCommentForm from "./CreativeViewCommentForm";
import CreativeViewImage from "./CreativeViewImage";
import CreativeViewPdf from "./CreativeViewPdf";
import CreativeViewTypeError from "./CreativeViewTypeError";
import CreativeViewVideo from "./CreativeViewVideo";
import CreativeViewZoomIcons from "./CreativeViewZoomIcons";

const PRISMA_ZOOM_ANIMATION_DURATION = 0.4;

export interface CreativeViewFileProps {
  commentsMode: boolean;
  externalToken?: string;
  fieldName?: string;
  fileLoading: boolean;
  hasFigmaAttachmentGroup?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersion?: any;
  type?: string;
  setFileLoading(loading: boolean): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  policyLibraryFile?: any;
}

function CreativeViewFile({
  recordVersion,
  fieldName,
  commentsMode,
  fileLoading,
  setFileLoading,
  policyLibraryFile,
}: CreativeViewFileProps) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [openedPopUp, setOpenedPopUp] = useState<"create" | "show" | null>(
    null,
  );
  const [popUpStyles, setPopUpStyles] = useState({});
  const [isDragging, setIsDragging] = useState(false);
  const [createTextValue, setCreateTextValue] = useState("");
  const [coords, setCoords] = useState<{
    x_coordinate: number;
    y_coordinate: number;
  } | null>(null);
  const [numPages, setNumPages] = useState(null);
  const [mouseDownPosition, setMouseDownPosition] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams<{
    comments_tab?: CommentTab;
    comment_id?: string;
  }>();
  const isInternalTab = searchParams.comments_tab === "internal";
  const selectedCommentID = searchParams.comment_id
    ? Number(searchParams.comment_id)
    : undefined;

  const [draggableData, setDraggableData] = useState<DraggableData>();

  // Variables
  const {
    url,
    contentType,
    attachmentGroupID,
    fileName,
    fileByteSize,
    preview,
    processed,
  } = mainStore.files;
  const { canAddComments } = mainStore.userPermissions;
  const { isCurrentWorkspaceArchived, isCurrentWorkspaceActive } =
    mainStore.workspaces;
  const currentUser = mainStore.users.user;
  const nonReplyComments = getTopLevelComments(searchParams.comments_tab, {
    comments: mainStore.comments.comments,
    privateComments: mainStore.comments.privateComments,
  });
  const selectedComment =
    selectedCommentID &&
    nonReplyComments.find((comment) => comment.id === selectedCommentID);
  const isPdf = [
    preview?.content_type,
    contentType,
    policyLibraryFile?.preview?.content_type,
  ].includes("application/pdf");
  const isLibraryPDF =
    !!policyLibraryFile && !!policyLibraryFile?.preview && isPdf;
  const isImage =
    contentType === "image/jpeg" ||
    contentType === "image/png" ||
    contentType === "image/gif";
  const isVideo =
    contentType === "video/mp4" || contentType === "video/quicktime";
  const isAudio =
    contentType === "audio/mpeg" ||
    contentType === "audio/wav" ||
    contentType === "audio/x-wav";
  const commentItemStyles = isPdf
    ? {}
    : { transform: `scale(${1 / zoomLevel})` };
  const pdf = {
    url: preview?.url || url,
    fileType: "application/pdf",
  };

  const libraryPDF = {
    url: policyLibraryFile?.preview?.url || policyLibraryFile?.url,
    fileType: "application/pdf",
  };

  // Effects
  useEffect(() => {
    setOpenedPopUp(selectedComment ? "show" : null);
  }, [selectedComment]);

  useEffect(() => {
    handleAfterCommentSelect();
  }, [openedPopUp, fileLoading, selectedCommentID]);

  // Refs
  const zoomComponentRef = useRef(null);
  const fileRef = useRef<HTMLImageElement>(null);
  const boundsRef = useRef<HTMLDivElement>();
  const selectedCommentRef = useRef<HTMLDivElement>();
  const tempCommentRef = useRef<HTMLDivElement>();
  const pdfRef = useRef<HTMLDivElement>();

  function setSelectedCommentID(id: number | undefined) {
    setSearchParams(
      { ...searchParams, comment_id: id ? String(id) : undefined },
      true,
    );
  }

  // Functions
  const handleChange: OnChangeHandlerFunc = (event) => {
    setCreateTextValue(event.target.value);
  };

  // @ts-expect-error TS(7031) FIXME: Binding element 'newNumPages' implicitly has an 'a... Remove this comment to see the full error message
  function onPdfDocumentLoadSuccess({ numPages: newNumPages }) {
    setNumPages(newNumPages);
    handleFileLoaded();
  }

  function handleFileLoaded() {
    setFileLoading(false);
    setZoomLevel(1);
  }

  function isFileTooBig() {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    return fileByteSize > 58720256; // 56 MB
  }

  function handleFileClick(e: React.MouseEvent<Element, MouseEvent>) {
    // checks
    if (!canAddComments || !commentsMode || isDragging) {
      return;
    }

    const { clientX, clientY, target } = e;
    if (
      mouseDownPosition &&
      // @ts-expect-error TS(2339) FIXME: Property 'x' does not exist on type 'never'.
      (clientX !== mouseDownPosition.x || clientY !== mouseDownPosition.y)
    ) {
      return;
    }

    // file
    const fileRect = (target as HTMLElement).getBoundingClientRect();
    const pdfRect = pdfRef.current?.getBoundingClientRect();

    // zoom
    // @ts-expect-error TS(2339) FIXME: Property 'getZoom' does not exist on type 'never'.
    const zLevel = zoomComponentRef.current?.getZoom() || 1;

    // offset
    const offsetX = (isPdf ? pdfRect?.x : fileRect.x) ?? 0;
    const offsetY = (isPdf ? pdfRect?.y : fileRect.y) ?? 0;

    const scrollY = isPdf
      ? pdfRef.current?.scrollTop ?? 0
      : fileRef.current?.scrollTop ?? 0;

    setCoords({
      x_coordinate: (clientX - offsetX) / zLevel - 4 * zLevel,
      y_coordinate: (clientY + scrollY - offsetY) / zLevel - 4 * zLevel,
    });

    setSelectedCommentID(undefined);
    setOpenedPopUp("create");
  }

  async function handleAfterCommentSelect() {
    const shift = 20;
    let target;

    if (openedPopUp === "create") {
      target = tempCommentRef;
    }
    if (openedPopUp === "show") {
      target = selectedCommentRef;
    }
    if (!target?.current) {
      return;
    }

    scrollIntoViewIfNeeded(target.current);

    const { left, top, width, height } = target.current.getBoundingClientRect();
    let styles = {
      marginLeft: left + width / 2 + shift,
      marginTop: top + height / 2 + shift,
    };

    // center selected comment for PrismaZoom
    if (openedPopUp === "show" && fileRef.current) {
      const fileRect = fileRef.current?.getBoundingClientRect();
      const bounds = boundsRef.current?.getBoundingClientRect();
      // @ts-expect-error TS(2339) FIXME: Property 'getZoom' does not exist on type 'never'.
      const zoom = zoomComponentRef.current?.getZoom() || 1;
      setPopUpStyles({ opacity: 0 });

      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      await zoomComponentRef.current.zoomToZone(
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        (left + width / 2 - fileRect.left) / zoom - bounds.width / zoom / 2,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        (top + height / 2 - fileRect.top) / zoom - bounds.height / zoom / 2,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        bounds.width / zoom,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        bounds.height / zoom,
      );

      setTimeout(() => {
        const targetRefAfterReposition =
          selectedCommentRef.current?.getBoundingClientRect();
        styles = {
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          marginLeft: targetRefAfterReposition?.left + width / 2 + shift,
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          marginTop: targetRefAfterReposition?.top + height / 2 + shift,
          // @ts-expect-error TS(2322) FIXME: Type '{ marginLeft: number; marginTop: number; opa... Remove this comment to see the full error message
          opacity: 1,
        };
        setPopUpStyles(styles);
      }, PRISMA_ZOOM_ANIMATION_DURATION * 1000);

      return;
    }

    setPopUpStyles(styles);
  }

  function handlePopUpClose() {
    handleClosePopUpForm();
    setOpenedPopUp(null);
    setSelectedCommentID(undefined);
  }

  function handleClosePopUpForm() {
    setCreateTextValue("");
  }

  function handleCreateComment() {
    const createProps = {
      ...coords,
      content: createTextValue,
      private: isInternalTab,
    };

    if (isPdf && createProps.x_coordinate && createProps.y_coordinate) {
      createProps.x_coordinate /= zoomLevel;
      createProps.y_coordinate /= zoomLevel;
    }

    mainStore.comments.create(createProps);
    handlePopUpClose();
  }

  function handleCreateReply(
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      mainStore.comments.create({
        original_comment_id: selectedCommentID,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        x_coordinate: selectedComment.x_coordinate,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        y_coordinate: selectedComment.y_coordinate,
        content: createTextValue,
      });
      handleClosePopUpForm();
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  async function handleDropComment(e, id) {
    if (!isDragging || isCurrentWorkspaceArchived) {
      return setSelectedCommentID(id);
    }

    const { clientX, clientY } = e;
    const fileRect = fileRef.current?.getBoundingClientRect();
    const pdfRect = pdfRef.current?.getBoundingClientRect();

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const offsetX = isPdf ? pdfRect.x : fileRect.x;
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const offsetY = isPdf ? pdfRect.y : fileRect.y;
    const scrollY = isPdf
      ? pdfRef.current?.scrollTop
      : fileRef.current?.scrollTop;

    const newCoords = {
      x_coordinate: Math.floor((clientX - offsetX) / zoomLevel),
      y_coordinate: Math.floor((clientY + scrollY - offsetY) / zoomLevel),
    };

    if (isPdf) {
      if (newCoords.x_coordinate < 0) {
        newCoords.x_coordinate = 0;
      }
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      if (newCoords.x_coordinate > pdfRect.width) {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        newCoords.x_coordinate = pdfRect.width;
      }
    }

    mainStore.comments.setComments(
      mainStore.comments.comments.map((item) =>
        item.id === id
          ? {
              ...item,
              x_coordinate: newCoords.x_coordinate,
              y_coordinate: newCoords.y_coordinate,
            }
          : item,
      ),
    );
    await mainStore.comments.update(id, newCoords);
    setIsDragging(false);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  function handleZoomIn(val) {
    if (zoomLevel + val > 9) {
      return;
    }

    if (isPdf) {
      mainStore.pageLoading.startLoading();
      setFileLoading(true);
      setZoomLevel(zoomLevel + val);
      return;
    }

    // @ts-expect-error TS(2339) FIXME: Property 'zoomIn' does not exist on type 'never'.
    zoomComponentRef.current?.zoomIn(val);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'val' implicitly has an 'any' type.
  function handleZoomOut(val) {
    if (zoomLevel - val < 1) {
      return;
    }

    if (isPdf) {
      mainStore.pageLoading.startLoading();
      setFileLoading(true);

      setZoomLevel(zoomLevel - val);
      return;
    }

    // @ts-expect-error TS(2339) FIXME: Property 'zoomOut' does not exist on type 'never'.
    zoomComponentRef.current?.zoomOut(val);
  }

  function handleDownload() {
    if (!fieldName) {
      return;
    }

    mainStore.files.fetchFile({
      recordVersionID: recordVersion.id,
      fieldName,
      attachmentGroupID,
      downloadMode: true,
    });
  }

  function onRenderSuccess() {
    setFileLoading(false);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'clientX' implicitly has an 'any' type.
  function handleMouseDownPosition(clientX, clientY) {
    // @ts-expect-error TS(2345) FIXME: Argument of type '{ x: any; y: any; }' is not assi... Remove this comment to see the full error message
    setMouseDownPosition({ x: clientX, y: clientY });
  }

  // elements
  const renderCommentsOverlay = nonReplyComments.map((comment) => {
    const { id, x_coordinate, y_coordinate, resolved } = comment;
    if (!x_coordinate || !y_coordinate) {
      return null;
    }
    if (comment.commentable_id !== attachmentGroupID) {
      return null;
    }
    const active = selectedCommentID === id;
    const position = {
      x: isPdf
        ? x_coordinate * zoomLevel
        : // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
          Math.min(x_coordinate, fileRef.current?.width),
      y: isPdf
        ? y_coordinate * zoomLevel
        : // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
          Math.min(y_coordinate, fileRef.current?.height),
    };

    const draggableProps: Partial<DraggableProps> = {
      // @ts-expect-error TS(2339) FIXME: Property 'getZoom' does not exist on type 'never'.
      scale: zoomComponentRef.current?.getZoom() || 1,
      bounds: "parent",
      defaultPosition: position,
      onStart: (_, data) => {
        setIsDragging(false);
        setDraggableData(data);
      },
      onStop: (e, data) => {
        if (
          draggableData &&
          (Math.abs(data.x - draggableData.x) > 5 ||
            Math.abs(data.y - draggableData.y) > 5)
        ) {
          handleDropComment(e, id);
        } else {
          setIsDragging(false);
          setSelectedCommentID(id);
        }
        setDraggableData(undefined);
      },
      onDrag: () => setIsDragging(true),
    };

    if (isPdf) {
      const pdfRect = pdfRef.current?.getBoundingClientRect();

      draggableProps.position = position;
      draggableProps.bounds = {
        left: 0,
        right: pdfRect?.width,
        top: 0,
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        bottom: pdfRect?.height + boundsRef.current?.scrollTop,
      };
    }

    return (
      <Draggable
        key={id}
        {...draggableProps}
        disabled={isCurrentWorkspaceArchived}
      >
        <div
          className="draggable-wrapper"
          data-testid="cv-comments-overlay-item"
          onClick={() => {
            setSelectedCommentID(id);
          }}
        >
          <div
            // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<HTMLDivElement | undefined>... Remove this comment to see the full error message
            ref={active ? selectedCommentRef : null}
            style={{
              ...commentItemStyles,
              background: !resolved
                ? userColors[comment.author.icon_color_index]
                : undefined,
            }}
            className={classNames("overlay-comment-item", { active, resolved })}
          >
            {comment.author.initials}
          </div>
        </div>
      </Draggable>
    );
  });

  const renderTempCircleOverlay = openedPopUp === "create" && (
    <Draggable
      bounds="parent"
      defaultPosition={
        coords ? { x: coords.x_coordinate, y: coords.y_coordinate } : undefined
      }
      disabled
    >
      <div
        className="draggable-wrapper"
        data-testid="cv-temp-circle-overlay-wrapper"
      >
        <div
          // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<HTMLDivElement | undefined>... Remove this comment to see the full error message
          ref={tempCommentRef}
          style={{
            ...commentItemStyles,
            // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
            background: userColors[currentUser.icon_color_index],
          }}
          className="overlay-comment-item"
        >
          {currentUser.initials}
        </div>
      </div>
    </Draggable>
  );

  const renderImage = (
    <CreativeViewImage
      url={url as string}
      zoomComponentRef={zoomComponentRef}
      isDragging={isDragging}
      setZoomLevel={setZoomLevel}
      fileRef={fileRef}
      handleFileLoaded={handleFileLoaded}
      handleMouseDownPosition={handleMouseDownPosition}
      commentsMode={commentsMode && canAddComments}
      handleFileClick={handleFileClick}
      fileLoading={fileLoading}
      renderCommentsOverlay={renderCommentsOverlay}
      renderTempCircleOverlay={renderTempCircleOverlay}
    />
  );

  const renderPdf = (
    <CreativeViewPdf
      commentsMode={commentsMode && canAddComments}
      // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<HTMLDivElement | undefined>... Remove this comment to see the full error message
      pdfRef={pdfRef}
      // @ts-expect-error TS(2322) FIXME: Type '{ url: string | null | undefined; fileType: ... Remove this comment to see the full error message
      pdf={pdf}
      onPdfDocumentLoadSuccess={onPdfDocumentLoadSuccess}
      handleMouseDownPosition={handleMouseDownPosition}
      handleFileClick={handleFileClick}
      // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
      numPages={numPages}
      onRenderSuccess={onRenderSuccess}
      zoomLevel={zoomLevel}
      // @ts-expect-error TS(2322) FIXME: Type '(Element | null)[]' is not assignable to typ... Remove this comment to see the full error message
      renderCommentsOverlay={renderCommentsOverlay}
      // @ts-expect-error TS(2322) FIXME: Type 'false | Element' is not assignable to type '... Remove this comment to see the full error message
      renderTempCircleOverlay={renderTempCircleOverlay}
    />
  );

  const renderLibraryPdf = (
    <CreativeViewPdf
      commentsMode={false}
      // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<HTMLDivElement | undefined>... Remove this comment to see the full error message
      pdfRef={pdfRef}
      pdf={libraryPDF}
      onPdfDocumentLoadSuccess={onPdfDocumentLoadSuccess}
      handleMouseDownPosition={handleMouseDownPosition}
      handleFileClick={handleFileClick}
      // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
      numPages={numPages}
      onRenderSuccess={onRenderSuccess}
      zoomLevel={zoomLevel}
    />
  );

  const renderFileTooBigError = (
    <div className="view-error" data-testid="cv-error-block">
      <ul className="view-error-message">
        <li>
          <img src={warningIcon} alt="warning-icon" />
        </li>
        <li>
          <div>
            Your file is too big to view in Themis.
            <br /> <br />
            You can upload files up to 128MB, but files must be less than 56MB
            to view.
            <br />
          </div>
          <Button
            title="Download File"
            onClick={(event) => {
              event.stopPropagation();
              handleDownload();
            }}
          />
        </li>
      </ul>
    </div>
  );

  const renderPopUp = (
    <Popup
      nested
      open={!isDragging && !!openedPopUp && !fileLoading}
      onClose={handlePopUpClose}
      contentStyle={popUpStyles}
      keepTooltipInside
      className="creative-view-comment-popup"
    >
      <div className="creative-popup" data-testid="cv-file-popup-container">
        {openedPopUp === "create" && (
          <div
            className="creative-popup-create"
            data-testid="cv-file-popup-create"
          >
            <CreativeViewCommentForm
              textValue={createTextValue}
              handleChange={handleChange}
              handleConfirm={handleCreateComment}
              handleReject={handlePopUpClose}
            />
          </div>
        )}
        {openedPopUp === "show" &&
          selectedComment &&
          commentsMode &&
          selectedComment.x_coordinate &&
          selectedComment.y_coordinate && (
            <div
              className="creative-popup-show"
              data-testid="cv-file-popup-show"
            >
              {selectedComment && (
                <CreativeViewFilePositionComment
                  key={[selectedCommentID, selectedComment.content].join()}
                  comment={selectedComment}
                  handlePopupClose={handlePopUpClose}
                />
              )}
              {selectedComment.replies.map((reply) => (
                <CreativeViewFilePositionComment
                  key={[reply.id, reply.content].join()}
                  reply
                  comment={reply}
                  handlePopupClose={handlePopUpClose}
                />
              ))}
              {!selectedComment.resolved && isCurrentWorkspaceActive && (
                <UserMentionsInput
                  editable
                  singleLine={false}
                  content={createTextValue}
                  onKeyDown={handleCreateReply}
                  onChange={(e) => setCreateTextValue(e.target.value)}
                  placeholder="Reply"
                  dataTestID="comment-sidebar-input-reply"
                />
              )}
            </div>
          )}
      </div>
    </Popup>
  );

  const renderMainContent = () => {
    if (isLibraryPDF) {
      return renderLibraryPdf;
    }
    if (!url) {
      return "Please attach a file to this record to view";
    }
    if (isFileTooBig()) {
      setFileLoading(false);
    }
    if (isFileTooBig()) {
      return renderFileTooBigError;
    }
    if (isPdf) {
      return renderPdf;
    }
    if (isImage) {
      return renderImage;
    }
    if (isVideo || isAudio) {
      return (
        <CreativeViewVideo
          handleFileLoaded={handleFileLoaded}
          isControls
          url={processed?.url || url}
        />
      );
    }
    setFileLoading(false);
    return <CreativeViewTypeError handleDownload={handleDownload} />;
  };

  return (
    <>
      {openedPopUp && renderPopUp}
      {((commentsMode && isSupportedViewFileType(fileName)) ||
        policyLibraryFile) && (
        <CreativeViewZoomIcons zoomIn={handleZoomIn} zoomOut={handleZoomOut} />
      )}
      <div
        className={classNames("creative-file-container", { pdf: isPdf })}
        data-testid="cv-file-container"
      >
        <div
          className="creative-draggable-bounds"
          // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<HTMLDivElement | undefined>... Remove this comment to see the full error message
          ref={boundsRef}
          data-testid="cv-bounds"
        >
          {renderMainContent()}
        </div>
      </div>
    </>
  );
}

export default observer(CreativeViewFile);
