import ButtonBase from "@mui/material/ButtonBase";
import {
  ArrowDown,
  ArrowsDownUp,
  ArrowUp,
  PushPin,
} from "@phosphor-icons/react";
import type { SortDirection } from "ag-grid-community";
import type { CustomHeaderProps } from "ag-grid-react";
import type { ReactElement } from "react";
import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { styled } from "../../../styles";
import { ListItemIcon } from "../../data-display/list/list-item-icon/list-item-icon";
import { ListItemText } from "../../data-display/list/list-item-text/list-item-text";
import { Typography } from "../../data-display/typography/typography";
import { Stack } from "../../layout/stack/stack";
import { DropdownMenu } from "../../navigation/dropdown-menu/dropdown-menu";
import { MenuItem } from "../../navigation/menu/menu-item/menu-item";

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  justifyContent: "space-between",
  width: "100%",
  padding: theme.spacing(0, 1),
  transition: theme.transitions.create(["background-color"]),

  "&:hover, &:focus": {
    backgroundColor: theme.palette.action.hover,
  },
}));

type ColumnHeaderProps = CustomHeaderProps;

export function ColumnHeader({
  context,
  column,
  displayName,
}: ColumnHeaderProps): ReactElement | null {
  const { formatMessage } = useIntl();
  const gridRef = context.gridRef?.current;

  const enableSorting = column.getColDef().sortable !== false;

  const [open, setOpen] = useState(false);
  const [sort, setSort] = useState<SortDirection | undefined>(column.getSort());

  const isPinned = column.isPinned();

  const enablePinning =
    !column.getColDef().lockPinned && !column.getColDef().lockPosition;
  const hasActions = enablePinning || enableSorting;

  useEffect(() => {
    const onSortChanged = () => {
      setSort(column.getSort());
    };
    column.addEventListener("sortChanged", onSortChanged);
    return () => {
      column.removeEventListener("sortChanged", onSortChanged);
    };
  }, [column]);

  const SortIcon = useMemo(() => {
    return {
      asc: ArrowUp,
      desc: ArrowDown,
      none: ArrowsDownUp,
    }[sort ?? "none"];
  }, [sort]);

  const handleOpenChange = (_open: boolean) => {
    setOpen(_open);
  };

  const handlePinToggleClick = () => {
    gridRef.api?.applyColumnState({
      state: [{ colId: column.getColId(), pinned: isPinned ? null : "left" }],
    });
  };

  const handleSortClick = (direction: SortDirection) => {
    gridRef.api?.applyColumnState({
      state: [{ colId: column.getColId(), sort: direction }],

      defaultState: {
        sort: null,
      },
    });
  };

  const TextContent = (
    <Typography
      color="text.secondary"
      variant="body1"
      component="span"
      textOverflow="ellipsis"
      overflow="hidden"
    >
      {displayName}
    </Typography>
  );

  if (!displayName) {
    return null;
  }

  if (!hasActions) {
    return (
      <Stack direction="row" paddingX={1}>
        {TextContent}
      </Stack>
    );
  }

  return (
    <DropdownMenu
      trigger={
        <StyledButtonBase
          type="button"
          sx={{
            backgroundColor: open ? "action.hover" : undefined,
          }}
        >
          {TextContent}
          <Stack component="span" direction="row" spacing={0}>
            {enablePinning && isPinned && <PushPin size={16} />}
            {enableSorting && <SortIcon />}
          </Stack>
        </StyledButtonBase>
      }
      onOpenChange={handleOpenChange}
    >
      {enableSorting && (
        <MenuItem
          onClick={() => handleSortClick(sort === "asc" ? null : "asc")}
        >
          <ListItemIcon>
            <ArrowUp />
          </ListItemIcon>
          <ListItemText
            slotProps={{
              primary: {
                fontWeight: "600",
              },
            }}
            primary={formatMessage({
              defaultMessage: "Sort Ascending",
            })}
          />
        </MenuItem>
      )}
      {enableSorting && (
        <MenuItem
          onClick={() => handleSortClick(sort === "desc" ? null : "desc")}
        >
          <ListItemIcon>
            <ArrowDown />
          </ListItemIcon>
          <ListItemText
            slotProps={{
              primary: {
                fontWeight: "600",
              },
            }}
            primary={formatMessage({
              defaultMessage: "Sort Descending",
            })}
          />
        </MenuItem>
      )}

      {enablePinning && (
        <MenuItem onClick={handlePinToggleClick}>
          <ListItemIcon>
            <PushPin />
          </ListItemIcon>
          <ListItemText
            slotProps={{
              primary: {
                fontWeight: "600",
              },
            }}
            primary={
              isPinned
                ? formatMessage({
                    defaultMessage: "Unpin Column",
                  })
                : formatMessage({
                    defaultMessage: "Pin Column",
                  })
            }
          />
        </MenuItem>
      )}
    </DropdownMenu>
  );
}
