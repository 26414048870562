import { type ThemeOptions } from "@mui/material";

export const menuTheme: ThemeOptions["components"] = {
  MuiMenu: {
    defaultProps: {
      anchorOrigin: {
        horizontal: "right",
        vertical: "bottom",
      },
      transformOrigin: {
        horizontal: "right",
        vertical: "top",
      },
    },
  },
};
