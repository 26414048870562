import {
  Button,
  colorSelectColors,
  FormArrayMessage,
  FormField,
  SortableList,
  Stack,
} from "@themis/ui";
import type { KeyboardEvent, ReactElement } from "react";
import React from "react";
import type { Control } from "react-hook-form";
import { useFieldArray } from "react-hook-form";
import { PiPlusBold } from "react-icons/pi";
import { useIntl } from "react-intl";

import type { ValidationSchema } from "../../../use-validation-schema/use-validation-schema";
import { DropdownColumnOption } from "./dropdown-column-option";

interface DropdownColumnOptionsProps {
  control: Control<ValidationSchema>;
}

export function DropdownColumnOptions({
  control,
}: DropdownColumnOptionsProps): ReactElement {
  const name = "columnData.options" as const;
  const { formatMessage } = useIntl();
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name,
  });

  const enableDelete = fields.length > 1;

  const addOption = () => {
    append({ name: "", color: colorSelectColors[0] });
  };

  const getShouldAutoFocus = (index: number) => {
    return fields.length > 1 && index === fields.length - 1;
  };

  const handleAddClick = () => {
    addOption();
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      addOption();
    }
  };

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <Stack className="tw-px-2" spacing="1">
          <SortableList
            classes={{
              item: "tw-py-1.5",
            }}
            iconPosition="left"
            items={fields}
            renderItem={(
              optionField: (typeof fields)[number],
              index: number,
            ) => (
              <DropdownColumnOption
                key={optionField.id}
                autoFocus={getShouldAutoFocus(index)}
                control={control}
                enableDelete={enableDelete}
                name={`${field.name}.${index}`}
                onDeleteClick={() => remove(index)}
                onKeyDown={handleKeyDown}
              />
            )}
            onReorder={replace}
            // TODO Re-enable sorting once implemented on the back
            getIsItemDisabled={() => true}
          />

          <FormArrayMessage className="tw-ml-2" />

          <Button
            color="transparent"
            LeftIcon={PiPlusBold}
            onClick={handleAddClick}
          >
            {formatMessage({ defaultMessage: "Add Option" })}
          </Button>
        </Stack>
      )}
    />
  );
}
