import { ArrowSquareOut } from "@phosphor-icons/react";
import type { ComponentRef } from "react";
import { forwardRef } from "react";

import { Button, type ButtonProps } from "../../inputs/button/button";

type ExternalLinkButtonProps = ButtonProps<"a">;

export const ExternalLinkButton = forwardRef<
  ComponentRef<"a">,
  ExternalLinkButtonProps
>(({ type, ...rest }, ref) => {
  return (
    <Button
      component="a"
      ref={ref}
      startIcon={<ArrowSquareOut />}
      rel="noreferrer noopener"
      target="_blank"
      {...rest}
    />
  );
});

ExternalLinkButton.displayName = "ExternalLinkButton";
