import type { ThemeOptions } from "@mui/material";

export const avatarTheme: ThemeOptions["components"] = {
  MuiAvatar: {
    styleOverrides: {
      root: ({ theme }) => ({
        width: theme.size.icon.medium,
        height: theme.size.icon.medium,
        fontSize: 10,
      }),
      colorDefault: ({ theme }) => ({
        color: theme.palette.common.black,
        backgroundColor: theme.palette.neutral.lightest,
      }),
    },
  },
};
