import { type ThemeOptions } from "@mui/material";

export const tabsTheme: ThemeOptions["components"] = {
  MuiTabs: {
    styleOverrides: {
      root: ({ theme }) => ({
        height: 32,
        minHeight: 32,
        marginTop: theme.spacing(1),
        padding: theme.spacing(0, 2),
        borderBottom: `1px solid ${theme.palette.divider}`,
      }),
    },
  },
};
