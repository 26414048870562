import type { RiskRating } from "../types/risk-methodology";

export function updateRiskRatingsLowRange(
  ratings: RiskRating[],
  lower_range: number,
): RiskRating[] {
  return ratings.map((rating, index) => {
    if (index === 0) {
      return {
        ...rating,
        lower_range,
        higher_range:
          rating.higher_range < lower_range ? lower_range : rating.higher_range,
      };
    }

    return {
      ...rating,
      lower_range:
        rating.lower_range < lower_range ? lower_range : rating.lower_range,
      higher_range:
        rating.higher_range < lower_range ? lower_range : rating.higher_range,
    };
  });
}
