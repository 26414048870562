import { useCreateAttestation } from "@themis/api/queries/attestations/use-create-attestation";
import { AttestationForm } from "@themis/shared/components/attestations/attestation-form";
import type { ValidationSchema } from "@themis/shared/components/attestations/hooks/use-attestation-detail-validation-schema/use-attestation-detail-validation-schema";
import { useToast } from "@themis/ui";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";

import { useCompanyId } from "@/hooks/use-company-id";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

const PoliciesAddAttestationPage = () => {
  const { companyId = -1 } = useCompanyId();
  const workspaceId = useRouteWorkspaceId();
  const { mutate } = useCreateAttestation(companyId);
  const { formatMessage } = useIntl();
  const policiesDraftPageRoute = `/workspaces/${workspaceId}/modules/policy/drafts`;
  const history = useHistory();
  const toast = useToast();

  const handleSaveAndSend = () => {};

  const handleSubmit = (values: ValidationSchema) => {
    const { ownerId } = values;

    mutate(
      {
        attestation: {
          // company_id: companyId,
          due_date: values.dueDate ?? undefined,
          description: values.description,
          message: values.message,
          name: values.name,
          owner_id: isNaN(ownerId) ? undefined : ownerId,
          workspace_id: workspaceId,
          // custom_fields: values.customFields,
        },
      },
      {
        onSuccess: () => {
          toast({
            content: formatMessage({
              defaultMessage: "Attestation created successfully!",
            }),
            variant: "success",
          });

          history.push(policiesDraftPageRoute);
        },
        onError: () => {
          toast({
            content: formatMessage({
              defaultMessage: "Failed to create attestation",
            }),
            variant: "error",
          });
        },
      },
    );
  };

  return (
    <AttestationForm
      handleSubmit={handleSubmit}
      handleSaveAndSend={handleSaveAndSend}
      overview={{
        title: formatMessage({ defaultMessage: "Policies" }),
        route: policiesDraftPageRoute,
      }}
      workspaceId={workspaceId}
    />
  );
};

export default PoliciesAddAttestationPage;
