import type { FieldOption } from "@themis/api/gen/models/fieldOption";
import { Chip } from "@themis/ui-library/components/data-display/chip/chip";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { keyBy } from "lodash";

import { getFieldOptionPaletteColor } from "../../../../utils/get-field-option-palette-color";
import type { BaseDynamicValueProps } from "../base-dynamic-value";

export const StatusValue = ({
  value,
  configuration,
}: BaseDynamicValueProps<string>) => {
  if (!value) {
    return null;
  }

  const optionsMap = keyBy(configuration.options, "key");

  const statusOptions = (Array.isArray(value) ? value : [value])
    .map((item) => (item ? optionsMap[item] : undefined))
    .filter((option) => option !== undefined) as FieldOption[];

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      {statusOptions.map((option) => (
        <Chip
          key={option.value}
          colorSeed={option.id}
          color={getFieldOptionPaletteColor(option)}
          label={option.value}
        />
      ))}
    </Stack>
  );
};
