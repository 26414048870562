import { type ThemeOptions } from "@mui/material";

export const textFieldTheme: ThemeOptions["components"] = {
  MuiTextField: {
    defaultProps: {
      fullWidth: true,
    },
    variants: [
      {
        props: { type: "number" },
        style: {
          /* Chrome, Safari, Edge, Opera */
          "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },

          /* Firefox */
          "input[type=number]": {
            MozAppearance: "textfield",
          },
        },
      },
    ],
  },
};
