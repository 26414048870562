import type { Contract } from "@themis/api/gen/models/contract";
import { MiniTag } from "@themis/ui";
import { actionsColumnDefParams } from "@themis/ui-library/components/data-grid/column-defs/actions-column-def-params";
import type { ColDef, ValueFormatterParams } from "ag-grid-community";
import type { CustomCellRendererProps } from "ag-grid-react";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ContractsActionsCell } from "../components/ContractsTable/ContractsActionsCell";
import { isContractActive } from "../utils";

export const useContractsTableColumns = () => {
  const { formatDate, formatMessage } = useIntl();

  return useMemo(
    (): ColDef<Contract>[] => [
      {
        headerName: "Contract",
        minWidth: 300,
        cellRenderer: "attachmentCell",
        field: "file",
        valueFormatter: (params) => params.value || "No file found",
      },
      {
        headerName: "Account",
        minWidth: 200,
        field: "account_name",
      },
      {
        headerName: "Status",
        minWidth: 150,
        cellRenderer: (props: CustomCellRendererProps) => {
          const isActive = isContractActive(props.data.end_date);
          const active = formatMessage({ defaultMessage: "Active" });
          const inactive = formatMessage({ defaultMessage: "Inactive" });

          return (
            <MiniTag
              size="lg"
              color={isActive ? "primary" : "red"}
              className="tw-ml-2"
            >
              {isActive ? active : inactive}
            </MiniTag>
          );
        },
      },
      {
        headerName: "Renewal Option",
        minWidth: 150,
        cellRenderer: (props: CustomCellRendererProps) => {
          const { renewal } = props.data;
          const automatic = formatMessage({ defaultMessage: "Automatic" });
          const manual = formatMessage({ defaultMessage: "Manual" });
          return (
            <MiniTag
              size="lg"
              color={renewal ? "secondary" : "primary"}
              className="tw-ml-2"
            >
              {renewal ? automatic : manual}
            </MiniTag>
          );
        },
      },
      {
        headerName: "Contract Start Date",
        minWidth: 200,
        field: "start_date",
        valueFormatter: ({ value }) => {
          return value
            ? formatDate(value, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                timeZone: "utc",
              })
            : "";
        },
      },
      {
        headerName: "Contract Review Date",
        minWidth: 200,
        field: "review_date",
        valueFormatter: ({ value }) => {
          return value
            ? formatDate(value, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                timeZone: "utc",
              })
            : "";
        },
      },
      {
        headerName: "Contract End Date",
        minWidth: 200,
        field: "end_date",
        valueFormatter: ({ value }) => {
          return value
            ? formatDate(value, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                timeZone: "utc",
              })
            : "";
        },
      },
      {
        headerName: "Termination Date",
        minWidth: 200,
        valueFormatter: (params: ValueFormatterParams) => {
          const contract = params.data;
          const onTermination = formatMessage({
            defaultMessage: "On termination date",
          });
          const daysBefore = formatMessage({
            defaultMessage: " days before termination date",
          });

          return contract.terms === "0"
            ? onTermination
            : `${contract.terms} ${daysBefore}`;
        },
      },
      {
        headerName: "Termination Details",
        field: "notes",
        minWidth: 200,
      },
      {
        ...actionsColumnDefParams,
        cellRenderer: (props: CustomCellRendererProps) => (
          <ContractsActionsCell contract={props.data} />
        ),
      },
    ],
    [formatDate, formatMessage],
  );
};
