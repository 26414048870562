import { faker } from "@faker-js/faker";

import type {
  GetPublicFormLayout200,
  GetPublicFormLayoutPathParams,
  GetPublicFormLayoutQueryResponse,
} from "../models/GetPublicFormLayout";
import { createPublicFormLayoutResponse } from "./createPublicFormLayoutResponse";

export function createGetPublicFormLayoutPathParams(): NonNullable<GetPublicFormLayoutPathParams> {
  return { module_workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createGetPublicFormLayout200(): NonNullable<GetPublicFormLayout200> {
  return createPublicFormLayoutResponse();
}

/**
 * @description OK
 */
export function createGetPublicFormLayoutQueryResponse(): NonNullable<GetPublicFormLayoutQueryResponse> {
  return createPublicFormLayoutResponse();
}
