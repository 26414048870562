import type { ThemisDocument } from "@themis/api/gen/models/themisDocument";
import { useClientDataGridBinding } from "@themis/ui-library/components/data-grid/binding/use-client-data-grid-binding";
import { DataGrid } from "@themis/ui-library/components/data-grid/data-grid";

import { useDocumentsTableColumns } from "../../../config/use-documents-table-columns/use-documents-table-columns";

interface DocumentsTableProps {
  documents: ThemisDocument[];
}

export const DocumentsTable = ({ documents = [] }: DocumentsTableProps) => {
  const columns = useDocumentsTableColumns();
  const { clientGridProps } = useClientDataGridBinding(documents);

  return (
    <DataGrid
      data-testid="documents-table"
      columns={columns}
      width="100%"
      {...clientGridProps}
    />
  );
};
