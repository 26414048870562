import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";
import useDragAndDropRecords from "@/hooks/useDragAndDropRecords";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import SectionDragContext from "../shared/SectionDragContext";
import SectionPage from "../shared/SectionPage";

interface Props {
  tabs?: React.ReactNode;
  completed: boolean;
  lockTab: boolean;
}

function ControlMappingTable({ tabs, completed, lockTab }: Props) {
  const mainStore = useMainStore();

  // Variables
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const selectedTab = lockTab ? "Completed" : "Active";

  const { onSectionChange, onRecordMoved } = useDragAndDropRecords({
    themisIdentifier: TopLevelModule.CONTROL_MAPPING,
    mainStore,
  });

  return (
    <SectionDragContext
      onSectionChange={onSectionChange}
      onRecordMoved={onRecordMoved}
    >
      <SectionPage
        isDraggable
        approvalFieldName=""
        CTASubject="Control Mapping"
        createNewRecord={async (sectionTagId?: number | null) => {
          await mainStore.controlMappings.create(sectionTagId);
        }}
        moduleIdentifier="control_mapping"
        recordName={getRecordName("control_mapping", moduleWorkspaces, true)}
        isTopLevelSectionAdded
        tabs={tabs}
        archivedWord="completed"
        archived={completed}
        lockTab={lockTab}
        selectedTab={selectedTab}
        paginated
      />
    </SectionDragContext>
  );
}

export default observer(ControlMappingTable);
