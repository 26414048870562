import { faker } from "@faker-js/faker";

import type {
  CreateTraining201,
  CreateTraining403,
  CreateTrainingMutationRequest,
  CreateTrainingMutationResponse,
  CreateTrainingPathParams,
} from "../models/CreateTraining";
import { createModifyTrainingRequest } from "./createModifyTrainingRequest";
import { createTraining } from "./createTraining";

export function createCreateTrainingPathParams(): NonNullable<CreateTrainingPathParams> {
  return { workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createCreateTraining201(): NonNullable<CreateTraining201> {
  return { data: createTraining() };
}

/**
 * @description Forbidden
 */
export function createCreateTraining403(): NonNullable<CreateTraining403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createCreateTrainingMutationRequest(): NonNullable<CreateTrainingMutationRequest> {
  return {
    training: Object.assign({}, createModifyTrainingRequest(), {
      training_materials: faker.helpers.arrayElements([
        faker.string.alpha(),
      ]) as any,
    }),
  };
}

/**
 * @description OK
 */
export function createCreateTrainingMutationResponse(): NonNullable<CreateTrainingMutationResponse> {
  return { data: createTraining() };
}
