import type { Control, FieldValues, Path } from "react-hook-form";
import { Controller } from "react-hook-form";

import type { TextFieldProps } from "../../inputs/text-field/text-field";
import { TextField } from "../../inputs/text-field/text-field";
import type { FormStaticLabelProps } from "../form-static-label/form-static-label";
import { FormStaticLabel } from "../form-static-label/form-static-label";

export type ControlledTextFieldProps<T extends FieldValues> = Omit<
  TextFieldProps,
  "value" | "onChange"
> & {
  label?: FormStaticLabelProps["label"];
  labelPlacement?: FormStaticLabelProps["labelPlacement"];
  control: Control<T>;
  name: Path<T>;
};

export function ControlledTextField<T extends FieldValues>({
  label,
  labelPlacement = "top",
  name,
  control,
  required,
  ...rest
}: ControlledTextFieldProps<T>) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) =>
        label ? (
          <FormStaticLabel
            label={label}
            labelPlacement={labelPlacement}
            required={required}
            value={
              <TextField
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                required={required}
                {...field}
                {...rest}
              />
            }
          />
        ) : (
          <TextField
            error={!!fieldState.error}
            helperText={fieldState.error?.message}
            required={required}
            {...field}
            {...rest}
          />
        )
      }
    />
  );
}
