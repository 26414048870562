import { observer } from "mobx-react";
import React from "react";

import { useMainStore } from "@/contexts/Store";

import ModuleConfig from "./ModuleConfig";
import ModuleDescription from "./ModuleDescription";
import ModuleName from "./ModuleName";
import ModuleUsers from "./ModuleUsers";

interface Props {
  active?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  headers?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onCloseUsersView?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onOpenUsersView?: (...args: any[]) => any;
}

function TableElement({
  data,
  headers,
  active,
  onOpenUsersView,
  onCloseUsersView,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleIdentifier' implicitly has an 'an... Remove this comment to see the full error message
  const removeModule = async (moduleIdentifier) => {
    await mainStore.moduleWorkspaces.removeModule(moduleIdentifier);
    if (workspaceID) {
      await mainStore.moduleWorkspaces.index(workspaceID);
    }
  };

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const renderFields = headers.map((header) => {
    const { field_name } = header;
    const width = header.width || 200;
    const style = { width };
    const value = data[field_name];

    return (
      <li key={field_name} style={style} className="modules-cells">
        {field_name === "module_name" && (
          <ModuleName
            value={value}
            identifier={data.themis_module.identifier}
          />
        )}

        {field_name === "module_description" && (
          <ModuleDescription identifier={data.themis_module.identifier} />
        )}

        {field_name === "module_users" && (
          <ModuleUsers
            active={active}
            // @ts-expect-error TS(2322) FIXME: Type '((...args: any[]) => any) | undefined' is no... Remove this comment to see the full error message
            onClick={onOpenUsersView}
            onClose={onCloseUsersView}
          />
        )}

        {field_name === "module_config" && (
          <ModuleConfig
            value={value}
            remove={() => removeModule(data.themis_module.identifier)}
          />
        )}
      </li>
    );
  });

  return (
    <div className="list-table-settings" data-testid="list-settings-row">
      <ul>
        <div className="list-table-settings-wrap">{renderFields}</div>
      </ul>
    </div>
  );
}

export default observer(TableElement);
