import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import api from "@/api/legacy/api";

interface PolicyFileUrlData {
  file_url: string;
}

interface GetPolicyFileUrlParams {
  indicator: number;
  preview?: boolean;
}

const getPolicyFileUrl = async (params: GetPolicyFileUrlParams) => {
  const { data } = await api.get<PolicyFileUrlData>(
    "/policies/policies_library_file_url",
    { params },
  );

  return data;
};

export const getPolicyFileUrlQueryKey = (params: GetPolicyFileUrlParams) => [
  "policies",
  "policies_library",
  "file_url",
  params,
];

export const usePolicyFileUrl = <TData = PolicyFileUrlData, TError = unknown>(
  params: GetPolicyFileUrlParams,
  options?: Partial<UseQueryOptions<PolicyFileUrlData, TError, TData>>,
) => {
  return useQuery({
    queryFn: () => getPolicyFileUrl(params),
    queryKey: getPolicyFileUrlQueryKey(params),
    ...options,
  });
};
