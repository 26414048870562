"use client";

import * as DialogPrimitive from "@radix-ui/react-dialog";
import * as React from "react";
import { PiXBold } from "react-icons/pi";
import { twMerge } from "tailwind-merge";

import { cn } from "../../lib/utils";
import { IconButton } from "../Button/IconButton";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "tw-fixed tw-inset-0 tw-z-[1300] tw-bg-neutral-700/80  data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0",
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    onClose?: () => void;
  }
>(({ className, children, onClose, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "tw-fixed tw-left-[50%] tw-top-[50%] tw-z-[1300] tw-box-border tw-flex tw-max-h-[80%] tw-w-full tw-max-w-lg tw-translate-x-[-50%] tw-translate-y-[-50%] tw-flex-col tw-overflow-hidden tw-border tw-bg-neutral-25 tw-duration-200 data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95 data-[state=closed]:tw-slide-out-to-left-1/2 data-[state=closed]:tw-slide-out-to-top-[48%] data-[state=open]:tw-slide-in-from-left-1/2 data-[state=open]:tw-slide-in-from-top-[48%] sm:tw-rounded-xl",
        className,
      )}
      {...props}
    >
      <div className="tw-grid tw-gap-4 tw-overflow-y-auto tw-px-5 tw-py-3 tw-font-sans">
        {children}
      </div>
      <DialogPrimitive.Close
        asChild
        className="tw-absolute tw-right-2.5 tw-top-2.5 disabled:tw-pointer-events-none"
      >
        <IconButton
          color="transparent"
          Icon={PiXBold}
          aria-label="Close Modal"
          onClick={() => onClose?.()}
        />
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "tw-mx-[-1.25rem] tw-flex tw-flex-col tw-border-0 tw-border-b tw-border-solid tw-border-primary-50 tw-px-[1.25rem] tw-pb-2 tw-text-center sm:tw-text-left",
      className,
    )}
    {...props}
  />
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "tw-mx-[-1.25rem] tw-flex tw-flex-col-reverse tw-px-[1.25rem] tw-pt-2 sm:tw-flex-row sm:tw-justify-end sm:tw-space-x-2",
      className,
    )}
    {...props}
  />
);
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={twMerge(
      "tw-text-base tw-font-semibold tw-text-neutral-500",
      className,
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={twMerge(
      "tw-font-sans tw-text-sm tw-font-semibold tw-text-neutral-300",
      className,
    )}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
