import type { SelectItem } from "@themis/ui";
import {
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Select,
} from "@themis/ui";
import React from "react";
import type { Control, FieldValues, Path } from "react-hook-form";

import { useColumnTypeOptions } from "./use-column-type-options/use-column-type-options";

export interface ColumnsTypeSelectFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  disabled?: boolean;
}

export const ColumnsTypeSelectField = <T extends FieldValues>({
  control,
  name,
  disabled,
}: ColumnsTypeSelectFieldProps<T>) => {
  const options = useColumnTypeOptions();

  return (
    <FormField
      required
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormControl>
            <Select
              disabled={disabled}
              items={options as SelectItem[]}
              selected={field.value}
              onSelect={field.onChange}
            />
          </FormControl>

          <FormMessage />
        </FormItem>
      )}
    />
  );
};
