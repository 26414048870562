import { faker } from "@faker-js/faker";

import type {
  GetComment200,
  GetCommentPathParams,
  GetCommentQueryResponse,
} from "../models/GetComment";
import { createComment } from "./createComment";
import { createRecordTypePath } from "./createRecordTypePath";

export function createGetCommentPathParams(): NonNullable<GetCommentPathParams> {
  return {
    record_type: createRecordTypePath(),
    record_id: faker.number.int(),
    id: faker.number.int(),
  };
}

/**
 * @description The comment
 */
export function createGetComment200(): NonNullable<GetComment200> {
  return { data: createComment() };
}

/**
 * @description The comment
 */
export function createGetCommentQueryResponse(): NonNullable<GetCommentQueryResponse> {
  return { data: createComment() };
}
