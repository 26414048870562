import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiGlobeBold } from "react-icons/pi";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import closeIcon2 from "../../../images/table-image/icon/close-icon2.svg";
import departmentBlue from "../../../images/table-image/icon/department-blue.svg";
import departmentIcon from "../../../images/table-image/icon/department.svg";
import { userColors } from "../../constants";

interface Props {
  objectID?: number;
  allowSharing?: boolean;
  moduleWorkspaceID?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedDepartmentIDs?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedUserIDs?: any[];
  tableName?: string;
}

function Sharing({
  objectID,
  allowSharing,
  moduleWorkspaceID,
  tableName,
  ...props
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [query, setQuery] = useState("");
  const [selectedUserIDs, setSelectedUserIDs] = useState(props.selectedUserIDs);
  const [selectedDepartmentIDs, setSelectedDepartmentIDs] = useState(
    props.selectedDepartmentIDs,
  );

  // Variables
  const { isCurrentWorkspaceArchived } = mainStore.workspaces;
  const { departments } = mainStore.departments;
  const { users } = mainStore.users;
  const filteredDepartments = departments.filter((item) =>
    item.title.toLowerCase().includes(query.toLowerCase()),
  );
  const filteredUsers = users.filter(
    (item) => item.full_name?.toLowerCase().includes(query.toLowerCase()),
  );
  const selectDepartments = filteredDepartments.filter(
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    (item) => !selectedDepartmentIDs.includes(item.id),
  );
  const selectUsers = filteredUsers.filter(
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    (item) => !selectedUserIDs.includes(item.id),
  );
  const selectRowClasses = classNames(
    "tw-cursor-pointer hover:tw-bg-primary-25",
    {
      "active-search": query.length > 0,
    },
  );

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function addUserById(id) {
    setSelectedUserIDs([...(selectedUserIDs || []), id]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function addDepartmentById(id) {
    setSelectedDepartmentIDs([...(selectedDepartmentIDs || []), id]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function removeUserById(id) {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    setSelectedUserIDs(selectedUserIDs.filter((item) => item !== id));
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function removeDepartmentById(id) {
    setSelectedDepartmentIDs(
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      selectedDepartmentIDs.filter((item) => item !== id),
    );
  }

  function onOpen() {
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    if (["ProceduresDrafts"].includes(tableName)) {
      setShowPopup(true);
    }
  }

  function onClose() {
    setShowPopup(false);
    setQuery("");

    const userIDs =
      selectedUserIDs === props.selectedUserIDs ? undefined : selectedUserIDs;
    const departmentIDs =
      selectedDepartmentIDs === props.selectedDepartmentIDs
        ? undefined
        : selectedDepartmentIDs;

    if (userIDs || departmentIDs) {
      if (moduleWorkspaceID) {
        mainStore.sectionTags.updatePermissions(
          moduleWorkspaceID,
          objectID,
          userIDs,
          departmentIDs,
        );
      }
    }
  }

  // Elements
  const listUsersElements = filteredUsers.slice(0, 1).map((elem) => (
    <div className="users-circle-block" key={elem.id}>
      {selectUsers.map((user) => {
        const style = {
          background: !user.is_contact
            ? userColors[user.icon_color_index]
            : undefined,
          borderColor: user.is_contact
            ? userColors[user.icon_color_index]
            : undefined,
        };
        const usersClass = classNames("users-circle", {
          "users-circle-contact": user.is_contact,
        });

        return (
          <div
            key={user.id}
            className={selectRowClasses}
            onClick={() => {
              addUserById(user.id);
            }}
          >
            <div className="users">
              <div className={usersClass} style={style}>
                <span>{user.initials}</span>
              </div>
              <div className="user-full-name">{user.full_name}</div>
            </div>
          </div>
        );
      })}
    </div>
  ));

  const listAvailableDepartments = filteredDepartments
    .slice(0, 1)
    .map((elem) => (
      <div className="procedures-popup-block" key={elem.id}>
        {selectDepartments.map((department) => (
          <div
            key={department.id}
            className={selectRowClasses}
            onClick={() => addDepartmentById(department.id)}
          >
            <div className="procedures-popup-element">
              <img
                src={departmentIcon}
                className="department"
                alt="department-icon"
              />
              <img
                src={departmentBlue}
                className="department-blue"
                alt="department-icon"
              />
              {department.title}
            </div>
          </div>
        ))}
      </div>
    ));

  const renderSelectedUsers = users
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    .filter((element) => selectedUserIDs.includes(element.id))
    .map((item) => (
      <li key={item.id}>
        {item.full_name}
        <span key={item.id} onClick={() => removeUserById(item.id)}>
          <img src={closeIcon2} alt="closeIcon2" />
        </span>
      </li>
    ));

  const renderSelectedDepartments = departments
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    .filter((element) => selectedDepartmentIDs.includes(element.id))
    .map((item) => (
      <li key={item.id}>
        {item.title}
        <span key={item.id} onClick={() => removeDepartmentById(item.id)}>
          <img src={closeIcon2} alt="closeIcon2" />
        </span>
      </li>
    ));

  const trigger = (
    <div
      className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-md tw-p-[7px] hover:tw-bg-neutral-100"
      data-tooltip-id="tooltip"
      data-tooltip-content="Share section"
      data-tooltip-place="top"
    >
      <PiGlobeBold className="tw-h-[18px] tw-w-[18px]  tw-text-neutral-500" />
    </div>
  );

  if (isCurrentWorkspaceArchived) {
    return null;
  }

  return (
    <>
      <Popup
        position="bottom left"
        trigger={trigger}
        open={showPopup}
        onOpen={onOpen}
        onClose={onClose}
        keepTooltipInside
      >
        {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
        {(["ProceduresDrafts", "Drafts"].includes(tableName) ||
          allowSharing) && (
          <div className="table-dropdown procedures-dropdown-popup">
            <div className="procedures-popup-list">
              <input
                type="search"
                className="procedures-search"
                placeholder="Share with Departments / User"
                onChange={(e) => setQuery(e.target.value)}
              />
              {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
              {(selectedDepartmentIDs.length > 0 ||
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                selectedUserIDs.length > 0) && (
                <div className="users-dropdown-block">
                  <ul>
                    {renderSelectedDepartments}
                    {renderSelectedUsers}
                  </ul>
                </div>
              )}
              {selectDepartments.length > 0 && (
                <div>
                  <h4>All Available Departments</h4>
                  {listAvailableDepartments}
                </div>
              )}
              {selectUsers.length > 0 && (
                <div>
                  <h4>All Available Users</h4>
                  {listUsersElements}
                </div>
              )}
            </div>
          </div>
        )}
      </Popup>
      {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
      {(selectedDepartmentIDs.length > 0 || selectedUserIDs.length > 0) && (
        <p className="label-share">
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          Shared with: {selectedDepartmentIDs.length} department,{" "}
          {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
          {selectedUserIDs.length} users
        </p>
      )}
    </>
  );
}

export default observer(Sharing);
