import { clsx } from "clsx";
import { cva, type VariantProps } from "cva";
import React from "react";

const spinnerVariants = cva({
  base: ["tw-inline-block tw-size-6 tw-animate-spin"],
  variants: {
    color: {
      parentColor: "",
      currentColor: "tw-text-current",
      primary: "tw-text-primary-300",
      secondary: "tw-text-secondary-300",
      neutral: "tw-text-neutral-300",
    },
    parentColor: {
      primary: `tw-text-white`,
      secondary: `tw-text-white`,
      tertiary: `tw-text-neutral-500`,
      grey: `tw-text-neutral-500`,
      transparent: `tw-text-neutral-500`,
      transparentWhite: `tw-text-neutral-25`,
      transparentPrimary: `tw-text-primary-300`,
      active: "tw-text-white",
      warning: `tw-text-white`,
    },
  },
  defaultVariants: {
    color: "primary",
  },
});

interface SpinnerProps extends VariantProps<typeof spinnerVariants> {
  className?: string;
}

export function Spinner({ className, color, parentColor }: SpinnerProps) {
  return (
    <div
      className={clsx(spinnerVariants({ color, parentColor }), className)}
      role="status"
      aria-label="loading"
      style={{
        width: "1.25em",
        height: "1.25em",
      }}
    >
      {/* Circular spinner svg */}
      <svg
        className="tw-h-full tw-w-full tw-text-current"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className="tw-stroke-current tw-opacity-90"
          cx="12"
          cy="12"
          r="10"
          strokeWidth="4"
          stroke="currentColor"
          strokeLinecap="round"
          strokeDasharray="31.41592653589793"
          strokeDashoffset="0"
        />
      </svg>
      <span className="tw-sr-only">Loading...</span>
    </div>
  );
}
