import { themeMaterial } from "ag-grid-community";
import { useMemo } from "react";

import { alpha, useTheme } from "../../styles";

export function useDataGridTheme() {
  const theme = useTheme();

  return useMemo(() => {
    return themeMaterial.withParams({
      accentColor: theme.palette.primary.main,
      borderRadius: theme.shape.borderRadius,
      cellHorizontalPadding: 0,
      cellHorizontalPaddingScale: 0,
      cellTextColor: theme.palette.text.primary,
      checkboxBorderWidth: 1.5,
      columnBorder: true,
      fontFamily: theme.typography.fontFamily,
      focusShadow: "none",
      headerBackgroundColor: theme.palette.background.paper,
      headerCellHoverBackgroundColor: theme.palette.background.paper,
      headerColumnBorder: true,
      headerFontWeight: theme.typography.fontWeightBold,
      headerRowBorder: false,
      headerTextColor: theme.palette.text.secondary,
      iconSize: 14,
      rowHoverColor: alpha(theme.palette.grey["100"], 0.5),
      rowBorder: true,
      oddRowBackgroundColor: theme.palette.background.paper,
      selectedRowBackgroundColor: alpha(theme.palette.primary.light, 0.2),
      spacing: 2,
      wrapperBorder: false,
      wrapperBorderRadius: 0,
    });
  }, [theme]);
}
