import { http } from "msw";

import { createGetCompanyMonitoringGroupsQueryResponse } from "../createGetCompanyMonitoringGroups";

export const getCompanyMonitoringGroupsHandler = http.get(
  "*/companies/:company_id/monitoring_groups",
  function handler(info) {
    return new Response(
      JSON.stringify(createGetCompanyMonitoringGroupsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
