import { ConfirmationDialog } from "@themis/ui-library/components/feedback/dialog/confirmation-dialog/confirmation-dialog";
import { DeleteMenuItem } from "@themis/ui-library/components/navigation/delete-menu-item/delete-menu-item";
import { MenuItem } from "@themis/ui-library/components/navigation/menu/menu-item/menu-item";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import type { CustomCellRendererProps } from "ag-grid-react";
import { useIntl } from "react-intl";

import { MoreOptionsDropdownMenu } from "../../../more-options-dropdown-menu/more-options-dropdown-menu";

interface AssigneeMoreOptionsDropdownMenuProps extends CustomCellRendererProps {
  onRemove: (id?: number) => void;
  onResend: (id?: number) => void;
  canResend: boolean;
}

export function AssigneeMoreOptionsDropdownMenu({
  onRemove,
  onResend,
  canResend,
  data,
}: AssigneeMoreOptionsDropdownMenuProps) {
  const deleteConfirm = useOpen(false);
  const { formatMessage } = useIntl();

  return (
    <>
      <MoreOptionsDropdownMenu>
        {canResend && (
          <MenuItem onClick={() => onResend(data?.id)}>
            {formatMessage({ defaultMessage: "Resend Attestation" })}
          </MenuItem>
        )}

        <DeleteMenuItem onClick={deleteConfirm.open}>
          {formatMessage({ defaultMessage: "Remove Assignee" })}
        </DeleteMenuItem>
      </MoreOptionsDropdownMenu>
      <ConfirmationDialog
        title={formatMessage({ defaultMessage: "Delete Assignee" })}
        open={deleteConfirm.isOpen}
        slotProps={{
          confirm: {
            children: formatMessage({ defaultMessage: "Yes, Delete" }),
            color: "error",
          },
        }}
        onConfirm={() => onRemove(data?.id)}
        onClose={deleteConfirm.close}
      >
        {formatMessage({
          defaultMessage:
            "Are you sure you wish to delete this assignee? Actions are non-reversible",
        })}
      </ConfirmationDialog>
    </>
  );
}
