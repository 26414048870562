import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { skipToken, useQuery } from "@tanstack/react-query";

import type { ResponseConfig } from "@/api/client";

import { showMonitoringAsset } from "../../../gen/client/websiteMonitoringController/showMonitoringAsset";
import type {
  ShowMonitoringAssetQueryParams,
  ShowMonitoringAssetQueryResponse,
} from "../../../gen/models/websiteMonitoringController/showMonitoringAsset";

export type MonitoringAssetData =
  ResponseConfig<ShowMonitoringAssetQueryResponse>["data"]["data"];

interface UseMonitoringAssetParams extends ShowMonitoringAssetQueryParams {
  assetId: string | number | undefined;
}

export const getMonitoringAssetKey = (
  params?: UseMonitoringAssetParams | undefined,
): QueryKey => ["web-monitoring", "assets", "detail", params].filter(Boolean);

export const useMonitoringAsset = <TError = Error, TData = MonitoringAssetData>(
  params: UseMonitoringAssetParams,
  options?: Partial<UseQueryOptions<MonitoringAssetData, TError, TData>>,
) => {
  const { assetId, ...restParams } = params;

  const assetIdNumber = Number.parseInt(assetId?.toString() || "");

  return useQuery({
    queryFn: isNaN(assetIdNumber)
      ? skipToken
      : async () => {
          const response = await showMonitoringAsset(assetIdNumber, {
            expand: "monitoring_findings",
            ...restParams,
          });

          return response.data;
        },
    queryKey: getMonitoringAssetKey({ ...params, assetId: assetIdNumber }),
    ...options,
  });
};
