import client from "@/api/client";
import type { RequestConfig, ResponseErrorConfig } from "@/api/client";

import type {
  ShowMonitoringAssetPathParams,
  ShowMonitoringAssetQueryParams,
  ShowMonitoringAssetQueryResponse,
} from "../../models/websiteMonitoringController/showMonitoringAsset";

export function getShowMonitoringAssetUrl(
  id: ShowMonitoringAssetPathParams["id"],
) {
  return `/monitoring_assets/${id}` as const;
}

/**
 * @description Show Monitoring Asset
 * @summary Show Monitoring Asset
 * {@link /monitoring_assets/:id}
 */
export async function showMonitoringAsset(
  id: ShowMonitoringAssetPathParams["id"],
  params?: ShowMonitoringAssetQueryParams,
  config: Partial<RequestConfig> & { client?: typeof client } = {},
) {
  const { client: request = client, ...requestConfig } = config;

  const res = await request<
    ShowMonitoringAssetQueryResponse,
    ResponseErrorConfig<Error>,
    unknown
  >({
    method: "GET",
    url: getShowMonitoringAssetUrl(id).toString(),
    params,
    ...requestConfig,
  });
  return res.data;
}
