import { faker } from "@faker-js/faker";

import type {
  GetCompanyAttestations200,
  GetCompanyAttestationsPathParams,
  GetCompanyAttestationsQueryParams,
  GetCompanyAttestationsQueryResponse,
} from "../models/GetCompanyAttestations";
import { createExtendedAttestation } from "./createExtendedAttestation";
import { createPaginatedMeta } from "./createPaginatedMeta";
import { createRecordTypes } from "./createRecordTypes";

export function createGetCompanyAttestationsPathParams(): NonNullable<GetCompanyAttestationsPathParams> {
  return { company_id: faker.number.int() };
}

export function createGetCompanyAttestationsQueryParams(): NonNullable<GetCompanyAttestationsQueryParams> {
  return {
    record_type: createRecordTypes(),
    workspace_id: faker.number.int(),
    filters: {},
    limit: faker.number.int(),
    offset: faker.number.int(),
    sort_by: {},
    expand: faker.string.alpha(),
  };
}

/**
 * @description OK
 */
export function createGetCompanyAttestations200(): NonNullable<GetCompanyAttestations200> {
  return {
    data: faker.helpers.arrayElements([createExtendedAttestation()]) as any,
    meta: createPaginatedMeta(),
  };
}

/**
 * @description OK
 */
export function createGetCompanyAttestationsQueryResponse(): NonNullable<GetCompanyAttestationsQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createExtendedAttestation()]) as any,
    meta: createPaginatedMeta(),
  };
}
