import { ListItemButton } from "@themis/ui-library/components/data-display/list/list-item-button/list-item-button";
import { ListItemIcon } from "@themis/ui-library/components/data-display/list/list-item-icon/list-item-icon";
import { ListItemText } from "@themis/ui-library/components/data-display/list/list-item-text/list-item-text";
import { ListItem } from "@themis/ui-library/components/data-display/list/list-item/list-item";
import { Checkbox } from "@themis/ui-library/components/inputs/checkbox/checkbox";

interface SectionListItemProps {
  isAllSectionsItem?: true;
  checked: boolean;
  title: string;
  onToggle: () => void;
}

export function SectionListItem({
  checked,
  isAllSectionsItem,
  title,
  onToggle,
}: SectionListItemProps) {
  return (
    <ListItem disablePadding>
      <ListItemButton sx={{ pl: isAllSectionsItem ? 2 : 4 }} onClick={onToggle}>
        <ListItemIcon sx={{ marginRight: 0.5, minWidth: 0 }}>
          <Checkbox size="small" edge="start" checked={checked} tabIndex={-1} />
        </ListItemIcon>
        <ListItemText
          slotProps={{
            primary: {
              fontSize: "htmlFontSize",
            },
          }}
        >
          {title}
        </ListItemText>
      </ListItemButton>
    </ListItem>
  );
}
