import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams } from "../types/module-store-types";
import Paginated from "./Paginated";

export default class ThemisAuditsStore extends Paginated {
  mainStore: MainStore;

  // Observable objects
  list = [];
  current = {};
  moduleWorkspaceID = null;

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    super(mainStore);

    makeObservable(this, {
      list: observable,
      current: observable,
      moduleWorkspaceID: observable,
      sectionsMetadata: observable,

      setSectionsMetadata: action,
      setList: action,
      setCurrent: action,
      setModuleWorkspaceID: action,
      setPageForSection: action,
      upsertRecordCounts: action,
      updateRecordVersions: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/themis_audits
  async index({
    sortParams,
    page = 1,
    sectionTagId = null,
    tableFilters = [],
  }: IndexParams) {
    // Avoid extra API calls
    // - when loading ThemisAudits for ALL SectionTags outside of page 1 (all Sections are ONLY loaded on page 1)
    if (sectionTagId === null && page !== 1) {
      return;
    }
    // - when loading ThemisAudits for ALL SectionTags with sorting applied (each section is sorted separately)
    if (sectionTagId === null && sortParams) {
      return;
    }

    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
        section_tag_id: sectionTagId,
        page,
      };

      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/themis_audits`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError || !response.data) {
        return;
      }

      this.setList(response.data.themis_audits);
      this.setModuleWorkspaceID(response.data.module_workspace_id);

      this.setPageForSection(page, sectionTagId);
      this.upsertRecordCounts(response.data.counts);

      // If we fetched data for specific SectionTag - ONLY replace RecordVersions for that SectionTag
      this.updateRecordVersions(sectionTagId, response.data);

      this.mainStore.fields.setList(response.data.fields);
    } catch (error) {
      window.console.log(`"ThemisAudits#index" error ${error}`);
    }
  }

  // POST /api/react/themis_audits/:section_tag_id/audit_issues
  // @ts-expect-error TS(7006) FIXME: Parameter 'sectionTagId' implicitly has an 'any' t... Remove this comment to see the full error message
  async createAuditIssue(sectionTagId) {
    if (!sectionTagId) {
      window.console.log(
        "ThemisAudit#createAuditIssue => themisAuditID is mandatory parameter!",
      );
      return;
    }

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/themis_audits/${sectionTagId}/audit_issues`,
        headers: this.mainStore.getHeaders(),
      });

      return response.data.record_version;
    } catch (error) {
      window.console.log(`ThemisAudit#createAuditIssue => error ${error}`);
    }
  }

  // DELETE /api/react/audit_issues/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'auditIssueID' implicitly has an 'any' t... Remove this comment to see the full error message
  async deleteAuditIssue(auditIssueID) {
    if (!auditIssueID) {
      window.console.log(
        "ThemisAudit#deleteAuditIssue => auditIssueID is mandatory parameter!",
      );
      return;
    }

    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/audit_issues/${auditIssueID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`ThemisAudit#deleteAuditIssue => error ${error}`);
    }
  }

  // POST /api/react/audit_issues/:audit_issues_id/reopen
  // @ts-expect-error TS(7006) FIXME: Parameter 'auditIssueID' implicitly has an 'any' t... Remove this comment to see the full error message
  async reopenAuditIssue(auditIssueID) {
    if (!auditIssueID) {
      window.console.log(
        "ThemisAudit#reopenAuditIssue => auditIssueID is mandatory parameter!",
      );
      return;
    }

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/audit_issues/${auditIssueID}/reopen`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`ThemisAudit#reopenAuditIssue => error ${error}`);
    }
  }

  // POST /api/react/audit_issues/:audit_issue_id/approve
  // @ts-expect-error TS(7006) FIXME: Parameter 'auditIssueID' implicitly has an 'any' t... Remove this comment to see the full error message
  async approveAuditIssue(auditIssueID) {
    if (!auditIssueID) {
      window.console.log(
        "ThemisAudit#approveAuditIssue => auditIssueID is mandatory parameter!",
      );
      return;
    }

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/audit_issues/${auditIssueID}/approve`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`ThemisAudit#approveAuditIssue => error ${error}`);
    }
  }

  // POST /api/react/themis_audits/:themis_audit_id/documents
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async createDocument(recordVersionID, documentParams) {
    const data = { document: documentParams };

    if (!recordVersionID) {
      window.console.log(
        "ThemisAudit#createDocument => recordVersionID is mandatory parameter!",
      );
      return;
    }

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/themis_audits/${recordVersionID}/documents`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.mainStore.recordVersions.setList(
        this.mainStore.recordVersions.list.map((item) =>
          item.id === recordVersionID
            ? {
                ...item,
                record: {
                  ...item.record,
                  documents: [...item.record.documents, response.data],
                },
              }
            : item,
        ),
      );
    } catch (error) {
      window.console.log(`ThemisAudit#createDocument => error ${error}`);
    }
  }

  // PUT /api/react/themis_audits/:themis_audit_id/documents/:document_id
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async updateDocument(recordVersionID, documentID, documentParams) {
    if (!recordVersionID || !documentID) {
      window.console.log(
        "ThemisAudit#updateDocument => recordVersionID and documentID are mandatory parameters!",
      );
      return;
    }

    const data = { document: documentParams };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/themis_audits/${recordVersionID}/documents/${documentID}`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      this.mainStore.recordVersions.setList(
        this.mainStore.recordVersions.list.map((item) =>
          item.id === recordVersionID
            ? {
                ...item,
                record: {
                  ...item.record,
                  // @ts-expect-error TS(2339) FIXME: Property 'documents' does not exist on type 'Recor... Remove this comment to see the full error message
                  documents: item.record.documents.map((doc) =>
                    doc.id === documentID ? response.data : doc,
                  ),
                },
              }
            : item,
        ),
      );
    } catch (error) {
      window.console.log(`ThemisAudit#updateDocument => error ${error}`);
    }
  }

  // DELETE /api/react/themis_audits/:themis_audit_id/documents/:document_id
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async destroyDocument(recordVersionID, documentID) {
    if (!recordVersionID || !documentID) {
      window.console.log(
        "ThemisAudit#destroyDocument => recordVersionID and documentID are mandatory parameters!",
      );
      return;
    }

    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/themis_audits/${recordVersionID}/documents/${documentID}`,
        headers: this.mainStore.getHeaders(),
      });

      this.mainStore.recordVersions.setList(
        this.mainStore.recordVersions.list.map((item) =>
          item.id === recordVersionID
            ? {
                ...item,
                record: {
                  ...item.record,
                  documents: item.record.documents.filter(
                    // @ts-expect-error TS(7006) FIXME: Parameter 'doc' implicitly has an 'any' type.
                    (doc) => doc.id !== documentID,
                  ),
                },
              }
            : item,
        ),
      );
    } catch (error) {
      window.console.log(`ThemisAudit#destroyDocument => error ${error}`);
    }
  }

  // PUT /api/react/themis_audits/:id/update_section
  async updateSection(recordVersionID: number, sectionTagID: number) {
    const data = { section_tag_id: sectionTagID, identifier: "audits" };

    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/themis_audits/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"Audits#updateSection" error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setList(value) {
    if (value) {
      this.list = value;
    } else {
      this.list = [];
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrent(value) {
    if (value) {
      this.current = value;
    } else {
      this.current = {};
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setModuleWorkspaceID(value) {
    this.moduleWorkspaceID = value;
  }

  // Store Helpers

  cleanup() {
    super.cleanup();
    this.setList([]);
    this.setCurrent({});
    this.setModuleWorkspaceID(null);
  }

  abort() {
    this.controller?.abort();
  }
}
