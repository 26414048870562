import type { Contact } from "@themis/api/gen/models/contact";
import { AddNewRowLink } from "@themis/ui";
import { useClientDataGridBinding } from "@themis/ui-library/components/data-grid/binding/use-client-data-grid-binding";
import { DataGrid } from "@themis/ui-library/components/data-grid/data-grid";
import React from "react";
import { useIntl } from "react-intl";
import { useLocation, useRouteMatch } from "react-router-dom";

import { useContactsColumns } from "../../config/use-contacts-columns";

function AccountContacts({ contacts }: { contacts: Contact[] }) {
  const { formatMessage } = useIntl();
  const { url } = useRouteMatch();
  const location = useLocation();
  const { clientGridProps } = useClientDataGridBinding(contacts);

  const columns = useContactsColumns({
    contactsRoute: url,
  });

  return (
    <div>
      <DataGrid columns={columns} {...clientGridProps} />

      <AddNewRowLink
        className="tw-mt-1"
        title={formatMessage({
          defaultMessage: "Add Contact",
          description:
            "Add button that is shown under the account contacts table",
        })}
        to={{
          pathname: `${url}/new`,
          state: { from: location.pathname },
        }}
      />
    </div>
  );
}

export { AccountContacts };
