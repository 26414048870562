import React, { useMemo } from "react";
import type { ReactElement } from "react";

import { cn } from "../../../lib/utils";
import { ColorCircle } from "../../general";
import { Select, type SelectProps } from "../select/Select";

/**
 * @todo replace with color palette from design system
 */
export const colorSelectColors = [
  "#EB2E4E",
  "#FF879B",
  "#FF9900",
  "#FDB932",
  "#66AA22",
  "#6D8CF9",
  "#6161C3",
];

interface ColorSelectProps extends Pick<SelectProps, "disabled" | "size"> {
  className?: string;
  value: string;
  onChange: (value: string) => void;
}

export function ColorSelect({
  className,
  disabled,
  size,
  value,
  onChange,
}: ColorSelectProps): ReactElement {
  const items = useMemo(
    () =>
      colorSelectColors.map((color) => ({
        label: color,
        value: color,
      })),
    [],
  );

  return (
    <Select
      popoverContentClassName="tw-min-w-0"
      className={cn("tw-rounded-2xl", className)}
      disabled={disabled}
      items={items}
      size={size}
      renderOption={(item) => (
        <div className="tw-flex tw-items-center tw-gap-2">
          <ColorCircle color={item.value} />
        </div>
      )}
      renderSelected={(item) =>
        item?.value ? <ColorCircle color={item.value} /> : null
      }
      selected={value}
      onSelect={onChange}
    />
  );
}
