import type { Review } from "@themis/api/gen/models/review";
import { isReviewWithModuleWorkspace } from "@themis/api/utils/reviews/is-review-with-module-workspace";
import { isReviewWithReviewable } from "@themis/api/utils/reviews/is-review-with-reviewable";
import { map } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { nameForThemisModuleIdentifier } from "@/components/helpers/nameForThemisModuleIdentifier";
import { useMainStore } from "@/contexts/Store";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { Typography } from "../../Elements";
import ModuleLabel from "../../shared/ModuleLabel";
import LoadingTile, { MODE } from "../content/tiles/LoadingTile";
import { ActionItemSection } from "./ActionItemSection";
import { SectionLink } from "./SectionLink";
import { SectionTitle } from "./SectionTitle";
import TitleWithContextItem from "./TitleWithContextItem";

const Reviews = () => {
  // Import MobX stores
  const mainStore = useMainStore();

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const { requestedReviews: reviews, pendingReviewCount } = mainStore.reviews;
  const currentWorkspaceID = mainStore.context.workspaceID;

  async function handleView(review: Review) {
    if (
      isReviewWithModuleWorkspace(review) &&
      review.module_workspace.workspace_id !== currentWorkspaceID
    ) {
      await mainStore.workspaces.switch(review.module_workspace.workspace_id);
    }
    if (isReviewWithReviewable(review)) {
      history.push(review.reviewable.path);
    }
  }

  useEffect(() => {
    const fetchReviews = async () => {
      setIsLoading(true);
      await mainStore.reviews.fetchRequestedReviews();
      setIsLoading(false);
    };

    fetchReviews();
  }, [mainStore]);

  return (
    <ActionItemSection>
      <div className="action-item-section__header">
        <SectionTitle>Pending my review</SectionTitle>
        <SectionLink label="View all reviews" to="/notifications/reviews" />
      </div>

      {isLoading && <LoadingTile mode={MODE.dark} />}

      {!isLoading && (
        <>
          <div className="action-item-section__overview">
            <Typography
              label={pendingReviewCount || "--"}
              size="lgh"
              weight="semiBold"
              color="generalWhite"
            />
            <Typography
              label="# of items pending review"
              size="sm"
              weight="semiBold"
              color="generalWhite"
            />
          </div>
          {map(reviews, (review) => {
            if (
              !isReviewWithReviewable(review) ||
              !isReviewWithModuleWorkspace(review)
            ) {
              return null;
            }

            const { reviewable } = review;

            return (
              <TitleWithContextItem
                key={review.id}
                globalId={reviewable.identifier}
                title={reviewable.name || "-- Untitled --"}
                context={
                  <ModuleLabel
                    moduleDisplayName={nameForThemisModuleIdentifier(
                      review.module_workspace.themis_module
                        .identifier as ModuleIdentifier,
                    )}
                    moduleIdentifier={
                      review.module_workspace.themis_module.identifier
                    }
                    color="generalWhite"
                  />
                }
                onClick={() => handleView(review)}
                data-testid="tite-with-context-item"
              />
            );
          })}
        </>
      )}
    </ActionItemSection>
  );
};

export default observer(Reviews);
