import { HeaderTabs } from "@themis/ui";
import { observer } from "mobx-react";
import React from "react";
import { useIntl } from "react-intl";

import { getSelectedTab } from "@/components/helpers/Tabs";
import { useMainStore } from "@/contexts/Store";
import type { UseFiltersReturn } from "@/hooks/useFilters";
import { useIsFeatureEnabled } from "@/hooks/useIsFeatureEnabled";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import ExportBulk from "../../../components/dashboard/ExportBulk";
import ImportBulk from "../../../components/dashboard/ImportBulk";
import SlideMenu from "../../../components/slideMenu/SlideMenu";
import EmailAttest from "../../../components/table/policy/EmailAttest";

interface Props {
  buttonDisabled?: boolean;
  filtersData?: UseFiltersReturn | Record<string, never>;
  moduleWorkspaceID?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setShowCheckbox?: (...args: any[]) => any;
  tableName?: string;
  hasModuleWriteAccess?: boolean;
  openImportSlideOut: () => void;
  isShowExport?: boolean;
}

function PoliciesTabHeader({
  tableName,
  setShowCheckbox,
  buttonDisabled,
  moduleWorkspaceID,
  filtersData,
  hasModuleWriteAccess,
  openImportSlideOut,
  isShowExport = true,
}: Props) {
  const mainStore = useMainStore();

  const isFinalized = tableName === "Finalized";
  const isDrafts = tableName === "Drafts";
  const { workspaceID } = mainStore.context;
  const { formatMessage } = useIntl();
  const hideThemisPoliciesLibraryFeatureEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_HIDE_THEMIS_POLICIES_LIBRARY,
  );

  const TABS = [
    {
      name: formatMessage({ defaultMessage: "Drafts" }),
      key: "drafts",
      value: `/workspaces/${workspaceID}/modules/policy/drafts`,
    },
    {
      name: formatMessage({ defaultMessage: "Completed" }),
      key: "finalized",
      value: `/workspaces/${workspaceID}/modules/policy/finalized`,
    },
    {
      name: formatMessage({ defaultMessage: "Attestations" }),
      key: "attestation",
      value: `/workspaces/${workspaceID}/modules/policy/attestation`,
    },
    ...(!hideThemisPoliciesLibraryFeatureEnabled
      ? [
          {
            name: formatMessage({ defaultMessage: "Themis Policies Library" }),
            key: "policies_library",
            value: `/workspaces/${workspaceID}/modules/policy/policies_library`,
          },
        ]
      : []),
  ];

  return (
    <>
      <div
        className="table-header-wrap no-scroll"
        data-testid="policies-header"
      >
        <HeaderTabs
          tabs={TABS}
          selectedTab={getSelectedTab(TABS)?.value || ""}
          isLink
        />
        <div className="import-export-buttons-container">
          {isShowExport && <ExportBulk isExportFilesEnabled />}
          {isShowExport && isDrafts && (
            <ImportBulk
              topLevelSection
              importFromWS
              openImportSlideOut={openImportSlideOut}
            />
          )}
          {isFinalized && hasModuleWriteAccess && (
            <div className="table-export-block">
              <SlideMenu
                // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                closeSlideMenu={() => setShowCheckbox(false)}
                trigger={
                  <button
                    className="table-button"
                    id="triggerId5"
                    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                    onClick={() => setShowCheckbox(true)}
                    disabled={buttonDisabled}
                    style={{ background: "#6D71F9", marginLeft: 8 }}
                  >
                    {formatMessage({
                      defaultMessage: "Send Email / Attestation",
                    })}
                  </button>
                }
              >
                <EmailAttest
                  tableName={tableName}
                  moduleWorkspaceID={moduleWorkspaceID}
                  checkboxSelection
                />
              </SlideMenu>
            </div>
          )}
          {(isDrafts || isFinalized) && filtersData?.filtersTrigger}
        </div>
      </div>
      {filtersData?.filtersViewEnabled && (
        <div className="filters-wrap">
          <div className="switch-table-wrap" />
          {filtersData?.filtersContent}
        </div>
      )}
    </>
  );
}

export default observer(PoliciesTabHeader);
