import { useToast } from "@themis/ui";
import { useIntl } from "react-intl";

import { useRecordCommentsContext } from "../../../contexts/record-comments-context/record-comments-context";

interface CopyCommentLinkParams {
  commentId: number;
}

export function useCopyCommentLink({ commentId }: CopyCommentLinkParams) {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const { commentDetailUrl } = useRecordCommentsContext() || {};

  const handleCopyLink = async () => {
    const newUrl = new URL(commentDetailUrl || window.location.href);
    newUrl.hash = `#comment-${commentId}`;

    await navigator.clipboard.writeText(newUrl.toString());
    toast({
      content: formatMessage({
        defaultMessage: "Comment link copied to clipboard",
      }),
      variant: "info",
    });
  };

  return handleCopyLink;
}
