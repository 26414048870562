import { Avatar } from "@themis/ui-library/components/data-display/avatar/avatar";

import type { User } from "@/api";

export function FindingOwnerCell({ owner }: { owner: User }) {
  return (
    <div className="tw-flex tw-items-center tw-gap-1.5 tw-overflow-hidden">
      <Avatar size="lg" colorSeed={owner.icon_color_index}>
        {owner.initials}
      </Avatar>

      <p className="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-font-semibold">
        {owner.full_name}
      </p>
    </div>
  );
}
