import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { updateLayout } from "../../gen/axios/layoutsController";
import type {
  RecordTypePath,
  UpdateLayoutMutationRequest,
  UpdateLayoutMutationResponse,
} from "../../gen/models";
import { getFieldsQueryKey } from "../fields/use-fields";
import { getTrainingsQueryKey } from "../training/use-trainings";
import { getLayoutsQueryKey } from "./use-layouts";

interface UseUpdateLayoutParams {
  companyId: number;
  id: number | undefined;
  recordType: RecordTypePath;
  workspaceId: number;
}

export function useUpdateLayout(
  { workspaceId, companyId, id, recordType }: UseUpdateLayoutParams,
  {
    onSuccess,
    ...options
  }: UseMutationOptions<
    UpdateLayoutMutationResponse,
    unknown,
    UpdateLayoutMutationRequest
  > = {},
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (request: UpdateLayoutMutationRequest) => {
      if (id === undefined) {
        throw new Error("id is required to update a layout");
      }

      return updateLayout(companyId, recordType, id, request);
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getFieldsQueryKey({ recordType, companyId }),
      });

      queryClient.invalidateQueries({
        queryKey: getLayoutsQueryKey({ companyId, recordType, workspaceId }),
      });
    },
    onSuccess: (...args) => {
      if (recordType === "trainings") {
        queryClient.invalidateQueries({
          queryKey: getTrainingsQueryKey({ workspaceId }),
        });
      }

      onSuccess?.(...args);
    },
    ...options,
  });
}
