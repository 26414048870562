import { parseAsString, parseAsStringEnum, useQueryStates } from "nuqs";
import { useCallback, useEffect, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";

import type { SortDirection, Sorts } from "../data-grid-state";

export interface LocalStorageSort {
  columnKey: string;
  direction: SortDirection;
}

export function useSort({
  localStorageKey,
}: {
  localStorageKey: string;
}): [Sorts | undefined, (newSorts: Sorts | undefined) => void] {
  const [localeStorageSort, setLocalStorageSort, clearLocalStorageSort] =
    useLocalStorage<LocalStorageSort | null>(localStorageKey, null);

  const [sort, setSort] = useQueryStates(
    {
      columnKey: parseAsString
        .withOptions({
          clearOnDefault: false,
        })
        .withDefault(localeStorageSort?.columnKey ?? ""),
      direction: parseAsStringEnum<SortDirection | "">(["asc", "desc"])
        .withOptions({
          clearOnDefault: false,
        })
        .withDefault(localeStorageSort?.direction ?? ""),
    },
    {
      urlKeys: {
        columnKey: "sortColumn",
        direction: "sortDirection",
      },
    },
  );

  // Sync URL with localStorage on mount
  useEffect(() => {
    if (
      localeStorageSort &&
      (localeStorageSort.columnKey !== sort.columnKey ||
        localeStorageSort.direction !== sort.direction)
    ) {
      setSort({
        columnKey: localeStorageSort.columnKey,
        direction: localeStorageSort.direction,
      });
    }
  }, [localeStorageSort, setSort, sort]);

  const handleSort = useCallback(
    (newSorts: Sorts | undefined) => {
      const newSortsArray = newSorts
        ? Object.entries(newSorts).map(([columnKey, direction]) => ({
            columnKey,
            direction,
          }))
        : [];

      const [newSort] = newSortsArray;

      setSort(newSort);
      if (newSort) {
        setLocalStorageSort(newSort);
      } else {
        clearLocalStorageSort();
      }
    },
    [clearLocalStorageSort, setLocalStorageSort, setSort],
  );

  const sortValue = useMemo(() => {
    if (!sort.columnKey || !sort.direction) {
      return undefined;
    }
    return {
      [sort.columnKey]: sort.direction,
    };
  }, [sort]);

  return [sortValue, handleSort];
}
