import type { FieldConfiguration } from "@/api/gen/models";

import { isCustomField } from "../get-is-custom-field";

export function getDynamicFieldPath<
  Config extends FieldConfiguration,
  Path extends string = Config["path"],
>(field: Config) {
  return isCustomField(field)
    ? (`custom_fields.${field.path as Path}` as const)
    : (field.path as Path);
}
