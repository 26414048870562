import { type ThemeOptions } from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";

export const tablePagination: ThemeOptions["components"] = {
  MuiTablePagination: {
    defaultProps: {
      rowsPerPageOptions: [10, 25, 100],
      labelRowsPerPage: "Items per page:",
      slotProps: {
        select: {
          IconComponent: CaretDown,
        },
      },
    },
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.secondary,
        border: "none",
        fontSize: theme.typography.body2.fontSize,
      }),
      input: ({ theme }) => ({
        width: "auto",
        minWidth: "unset",
        margin: theme.spacing(0, 1),
      }),
      select: ({ theme }) => ({
        padding: theme.spacing(0.5),
        color: theme.palette.info.contrastText,
        backgroundColor: theme.palette.info.main,
        borderRadius: theme.shape.borderRadius,

        "&:focus": {
          borderRadius: theme.shape.borderRadius,
        },
      }),
      selectIcon: ({ theme }) => ({
        right: theme.spacing(1),
        top: theme.spacing(1),
      }),
    },
  },
};
