import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  LegacyWebsiteMonitoringGroupResultsPathParams,
  LegacyWebsiteMonitoringGroupResultsQueryResponse,
} from "../../models/LegacyWebsiteMonitoringGroupResults";

/**
 * @description List Website Monitoring - Groups with results
 * @summary List Website Monitoring Group Results (Legacy)
 * @link /website_monitoring/:monitoring_group_asset_id/monitoring_group_results
 */
export async function legacyWebsiteMonitoringGroupResults(
  monitoringGroupAssetId: LegacyWebsiteMonitoringGroupResultsPathParams["monitoring_group_asset_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<LegacyWebsiteMonitoringGroupResultsQueryResponse>["data"]
> {
  const res = await client<LegacyWebsiteMonitoringGroupResultsQueryResponse>({
    method: "get",
    url: `/website_monitoring/${monitoringGroupAssetId}/monitoring_group_results`,
    ...options,
  });
  return res.data;
}
