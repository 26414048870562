import type { RecordTypePath } from "@/api/gen";

import { accountsQueryKey } from "../accounts";
import { FINDING_QUERY_KEYS } from "../findings";
import { PROJECTS_QUERY_KEY } from "../projects";
import { getTrainingQueryKey } from "../training/use-training";

const RECORD_TYPE_QUERY_KEY_MAP = new Map<
  RecordTypePath,
  (recordId: number) => any
>([
  ["accounts", (recordId) => accountsQueryKey.details(recordId)],
  ["trainings", (recordId) => getTrainingQueryKey(recordId.toString())],
  ["projects", (recordId) => PROJECTS_QUERY_KEY.detail(recordId)],
  ["findings", (recordId) => FINDING_QUERY_KEYS.detail(recordId)],
]);

export function getRecordQueryKey({
  recordId,
  recordType,
}: {
  recordId: number;
  recordType: RecordTypePath;
}) {
  const queryKeyFn = RECORD_TYPE_QUERY_KEY_MAP.get(recordType);

  if (!queryKeyFn) {
    throw new Error(`Invalid record type: ${recordType}`);
  }

  return queryKeyFn(recordId);
}
