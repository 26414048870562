import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class LinkedDocumentsStore {
  mainStore: MainStore;

  // Observable objects
  moduleWorkspaceID = null;
  recordVersions = [];

  // Abort controller
  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      moduleWorkspaceID: observable,
      recordVersions: observable,

      removeRecordVersion: action,
      replaceRecordVersion: action,
      setModuleWorkspaceID: action,
      setRecordVersions: action,
      updateRecordVersion: action,
    });
    this.mainStore = mainStore;
  }

  // GET /api/react/records/:record_id/linked_documents
  async index({ parentRecordID = null }: { parentRecordID: number | null }) {
    if (!parentRecordID) {
      window.console.warn(
        `LinkedDocuments#index => parentRecordID(${parentRecordID}) should be present!`,
      );
      return;
    }

    const controller = new AbortController();
    this.controller = controller;

    try {
      const { data } = await legacyApi({
        method: "GET",
        url: `${API_URL}/records/${parentRecordID}/linked_documents`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
      });

      this.mainStore.fields.setList(data.fields);
      this.mainStore.fieldOptions.setAll(data.field_options);
      this.mainStore.context.setSubModuleTableID(data.table_id);
      this.setRecordVersions(data.record_versions);

      return true;
    } catch (error) {
      window.console.log(`"LinkedDocuments#Index" error ${error}`);
      return false;
    }
  }

  // POST /api/react/records/:record_id/linked_documents
  // @ts-expect-error TS(7031) FIXME: Binding element 'parentRecordID' implicitly has an... Remove this comment to see the full error message
  async create({ parentRecordID }) {
    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/records/${parentRecordID}/linked_documents`,
        headers: this.mainStore.getHeaders(),
      });

      return data;
    } catch (error) {
      window.console.log(`"LinkedDocuments#create" error ${error}`);
      return false;
    }
  }

  // DELETE /api/react/linked_documents/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/linked_documents/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
      } else {
        this.mainStore.toast.setInfoText(response.data?.message);
      }

      this.removeRecordVersion(recordVersionID);
    } catch (error) {
      window.console.log(`"LinkedDocuments#delete" error ${error}`);
    }
  }

  // POST /api/react/linked_documents/:id/approve
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async approve(recordVersionID) {
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/linked_documents/${recordVersionID}/approve`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"LinkedDocuments#approve" error ${error}`);
    }
  }

  // DELETE /api/react/linked_documents/:id/unapprove
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unapprove(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/linked_documents/${recordVersionID}/unapprove`,
        headers: this.mainStore.getHeaders(),
      });

      // attempt to fix no rerendering
      this.mainStore.reviews.setCurrent(response.data.approval);
    } catch (error) {
      window.console.log(`"LinkedDocuments#unapprove" error ${error}`);
    }
  }

  // POST /api/react/linked_documents/:id/finalize
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async finalize(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/linked_documents/${recordVersionID}/finalize`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
        return;
      }
    } catch (error) {
      window.console.log(`"LinkedDocuments#finalize" error ${error}`);
    }
  }

  // POST /api/react/linked_documents/:id/unlock
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unlock(recordVersionID) {
    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/linked_documents/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });
      return data;
    } catch (error) {
      window.console.log(`"LinkedDocuments#unlock" error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  removeRecordVersion(recordVersionID) {
    if (!recordVersionID) {
      return;
    }
    this.recordVersions = this.recordVersions.filter(
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      (recordVersion) => recordVersion.id !== recordVersionID,
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'newRecordVersion' implicitly has an 'an... Remove this comment to see the full error message
  replaceRecordVersion(previousRecordVersionID: number, newRecordVersion) {
    // Replace RecordVersion in list if it exists, usually during unlock
    // @ts-expect-error TS(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
    this.recordVersions = this.recordVersions.map((recordVersion) =>
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      recordVersion.id === previousRecordVersionID
        ? newRecordVersion
        : recordVersion,
    );
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setModuleWorkspaceID(value) {
    this.moduleWorkspaceID = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setRecordVersions(value) {
    this.recordVersions = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'updatedRecordVersion' implicitly has an... Remove this comment to see the full error message
  updateRecordVersion(updatedRecordVersion) {
    if (!updatedRecordVersion) {
      return;
    }

    // @ts-expect-error TS(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
    this.recordVersions = [
      ...this.recordVersions.map((recordVersion) =>
        // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
        recordVersion.id === updatedRecordVersion?.id
          ? updatedRecordVersion
          : recordVersion,
      ),
    ];
  }

  // Store Helpers

  cleanup() {
    this.setRecordVersions(null);
  }

  abort() {
    this.controller?.abort();
  }
}
