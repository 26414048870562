import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import type { ResponseConfig } from "@/api/client";
import type {
  DeleteThemisDocumentMutationResponse,
  DeleteThemisDocumentPathParams,
  RecordTypePath,
} from "@/api/gen";
import { deleteThemisDocument } from "@/api/gen/axios/documentsController";

import { getRecordQueryKey } from "../../records/get-record-query-key";

interface UseDeleteCommentParams {
  recordType: RecordTypePath;
  recordId: number;
}

export function useDeleteThemisDocument<TError = Error, TContext = unknown>(
  { recordType, recordId }: UseDeleteCommentParams,
  {
    onSettled,
    ...options
  }: Partial<
    UseMutationOptions<
      ResponseConfig<DeleteThemisDocumentMutationResponse>["data"],
      TError,
      DeleteThemisDocumentPathParams["id"],
      TContext
    >
  > = {},
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id) => deleteThemisDocument(id),
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getRecordQueryKey({
          recordType,
          recordId,
        }),
      });

      onSettled?.(...args);
    },
    ...options,
  });
}
