import type { PopoverContentProps } from "@radix-ui/react-popover";
import React from "react";

import { Button } from "../Button/Button";
import {
  Popover,
  PopoverAnchor,
  PopoverContent,
  PopoverTrigger,
} from "./Popover";

export function ConfirmationPopup({
  align,
  anchor,
  children,
  content,
  open,
  title,
  onCancel,
  onConfirm,
  confirmText,
  cancelText,
  sideOffset,
}: {
  align?: PopoverContentProps["align"];
  anchor?: boolean;
  children?: React.ReactNode;
  content: string | React.ReactNode;
  open: boolean;
  title: string;
  confirmText?: string;
  cancelText?: string;
  sideOffset?: number;
  onCancel?: (
    event: React.MouseEvent<HTMLButtonElement> | KeyboardEvent,
  ) => void;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  const Component = anchor ? PopoverAnchor : PopoverTrigger;

  const id = "confirmation-popup";

  return (
    <Popover open={open}>
      <Component>{children}</Component>
      <PopoverContent
        align={align}
        aria-labelledby={`${id}-heading`}
        aria-describedby={`${id}-content`}
        onEscapeKeyDown={onCancel}
        sideOffset={sideOffset}
      >
        <div className=" tw-box-border tw-flex tw-min-w-72 tw-max-w-80 tw-flex-col tw-gap-1 tw-px-4 tw-py-3 tw-font-sans">
          <h4 id={`${id}-heading`} className="tw-text-primaryDim-400">
            {title}
          </h4>
          <div id={`${id}-content`}>{content}</div>
        </div>
        <div className="tw-h-px tw-bg-neutral-100" />
        <div className="tw-flex tw-items-center tw-gap-2 tw-px-4 tw-py-3">
          <Button onClick={onConfirm}>{confirmText || "Yes"}</Button>
          {onCancel && (
            <Button color="tertiary" onClick={onCancel}>
              {cancelText || "No"}
            </Button>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
}
