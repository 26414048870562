import { faker } from "@faker-js/faker";

import type {
  ListAccounts200,
  ListAccountsPathParams,
  ListAccountsQueryParams,
  ListAccountsQueryResponse,
} from "../models/ListAccounts";
import { createAccount } from "./createAccount";
import { createPaginatedMeta } from "./createPaginatedMeta";

export function createListAccountsPathParams(): NonNullable<ListAccountsPathParams> {
  return { workspace_id: faker.number.int() };
}

export function createListAccountsQueryParams(): NonNullable<ListAccountsQueryParams> {
  return {
    filters: {},
    limit: faker.number.int(),
    offset: faker.number.int(),
    sort_by: {},
    expand: faker.string.alpha(),
  };
}

/**
 * @description OK
 */
export function createListAccounts200(): NonNullable<ListAccounts200> {
  return {
    data: faker.helpers.arrayElements([createAccount()]) as any,
    meta: createPaginatedMeta(),
  };
}

/**
 * @description OK
 */
export function createListAccountsQueryResponse(): NonNullable<ListAccountsQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createAccount()]) as any,
    meta: createPaginatedMeta(),
  };
}
