import { zodResolver } from "@hookform/resolvers/zod";
import type { SelectProps } from "@themis/ui";
import {
  Button,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  MiniTag,
  PreviewTable,
  Select,
  TextArea,
} from "@themis/ui";
import { Avatar } from "@themis/ui-library/components/data-display/avatar/avatar";
import { format, parseISO } from "date-fns";
import isEqual from "lodash/isEqual";
import React from "react";
import type { ControllerRenderProps } from "react-hook-form";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { z } from "zod";

import type { ModuleAssessment } from "@/api";
import { moduleAssessmentComplianceStatus } from "@/api";
import type { ControlMappingsEffectiveness } from "@/api/gen/models/ControlMappingsEffectiveness";
import { useCompanyUsers } from "@/api/queries/users/use-company-users";
import { InfoPreviewSection } from "@/components/InfoPreviewSection";
import DocumentUploadButton from "@/features/control-mapping/components/Documents/DocumentUploadButton";
import mainStore from "@/stores/Main";

import { getRatingColorName } from "../config";
import { PREVIEW_DOCUMENTS_COLUMNS } from "../config/moduleAssessmentsPreviewTable";
import { ModuleAssessmentStatusTag } from "./ModuleAssessmentTable/ModuleAssessmentStatusTag";

const COMPLIANCE_STATUS: SelectProps["items"] = [
  {
    label: moduleAssessmentComplianceStatus.Compliant,
    value: moduleAssessmentComplianceStatus.Compliant,
    Component: ({ value }) => <ModuleAssessmentStatusTag status={value} />,
  },
  {
    label: moduleAssessmentComplianceStatus.Non_Compliant,
    value: moduleAssessmentComplianceStatus.Non_Compliant,
    Component: ({ value }) => <ModuleAssessmentStatusTag status={value} />,
  },
];

const formSchema = z.object({
  record_id: z.number().optional(),
  compliance_status: z.string().optional(),
  summary: z.string().optional(),
  due_date: z.string({ required_error: "Due Date is required" }).date(),
  assessment_date: z
    .string({ required_error: "Assessment Date is required" })
    .date(),
  assignee_id: z.number().nullable().optional(),
  control_effectiveness_rating: z
    .object({
      id: z.number(),
      title: z.string(),
      value: z.number(),
      color: z.string(),
    })
    .nullable()
    .optional(),
  evidences: z
    .array(
      z.object({
        id: z.number().optional(),
        file: z.string().optional(),
        preview: z.string().optional(),
        _destroy: z.boolean().optional(),
      }),
    )
    .optional(),
});

type ModuleAssessmentInfoSchema = z.infer<typeof formSchema>;

function ModuleAssessmentInfoForm({
  defaultValues,
  controlMappingsEffectiveness,
  onSubmit,
}: {
  onSubmit: (values: ModuleAssessmentInfoSchema) => void;
  controlMappingsEffectiveness?: ControlMappingsEffectiveness[];
  defaultValues?: ModuleAssessment;
}) {
  const { workspace_id, moduleAssessmentId } = useParams<{
    workspace_id: string;
    moduleAssessmentId: string;
  }>();
  const isNewModuleAssessment = !Number.isInteger(Number(moduleAssessmentId));

  const companyId = mainStore.context.companyID!;

  const { data: activeUsers } = useCompanyUsers({
    companyId,
    workspace_id: Number(workspace_id),
  });

  const form = useForm<ModuleAssessmentInfoSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      record_id: defaultValues?.record_id || 0,
      compliance_status: defaultValues?.compliance_status || "",
      summary: defaultValues?.summary || "",
      assessment_date: defaultValues?.assessment_date || undefined,
      due_date: defaultValues?.due_date || undefined,
      assignee_id: defaultValues?.assignee?.id || undefined,
      control_effectiveness_rating:
        defaultValues?.control_effectiveness_rating || undefined,
      evidences:
        defaultValues?.evidences?.map((evidence) =>
          evidence?.file?.signed_id
            ? { file: evidence.file.signed_id }
            : undefined,
        ) || undefined,
    },
  });

  function handleAssigneeSelect(
    value: number,
    field: ControllerRenderProps<ModuleAssessmentInfoSchema, "assignee_id">,
  ) {
    if (field.value === value) {
      field.onChange(null);
    } else {
      field.onChange(value);
    }
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    form.handleSubmit(onSubmit)();
  };

  const noControl = { id: 0, title: "No Control", value: -1, color: "#e1e1e3" };
  const ratingValue = defaultValues?.control_effectiveness_rating;
  const originalValue = controlMappingsEffectiveness?.find(
    (item) =>
      item?.value === ratingValue?.value &&
      item?.title === ratingValue?.title &&
      item?.color === ratingValue?.color,
  );

  const selectedOption = () => {
    if (ratingValue !== null && Object.keys(ratingValue || {}).length > 0) {
      return originalValue || ratingValue;
    }
    if (ratingValue === null || controlMappingsEffectiveness?.length === 0) {
      return null;
    }

    return noControl;
  };

  const options = () => {
    if (
      selectedOption() !== null &&
      (controlMappingsEffectiveness?.length || 0) > 0
    ) {
      if (isEqual(selectedOption(), noControl)) {
        return [noControl, ...(controlMappingsEffectiveness || [])];
      }
      if (!originalValue && ratingValue) {
        ratingValue.id = -1;
        return [
          ratingValue,
          noControl,
          ...(controlMappingsEffectiveness || []),
        ];
      }
      if (originalValue) {
        return [noControl, ...(controlMappingsEffectiveness || [])];
      }
    }
    return [noControl, ...(controlMappingsEffectiveness || [])];
  };

  return (
    <>
      <Form {...form}>
        <form
          onSubmit={handleSubmit}
          className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-3"
        >
          <FormField
            control={form.control}
            name="assessment_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Assessment Date</FormLabel>
                <FormControl>
                  <DatePicker
                    closeOnDateSelect
                    calendarProps={{
                      mode: "single",
                      selected: field.value ? parseISO(field.value) : undefined,
                      onSelect: (date) =>
                        date && field.onChange(format(date, "yyyy-MM-dd")),
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="due_date"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Due Date</FormLabel>
                <FormControl>
                  <DatePicker
                    closeOnDateSelect
                    calendarProps={{
                      mode: "single",
                      selected: field.value ? parseISO(field.value) : undefined,
                      onSelect: (date) =>
                        date && field.onChange(format(date, "yyyy-MM-dd")),
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="assignee_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Assignee</FormLabel>
                <FormControl>
                  <Select
                    searchable
                    items={
                      activeUsers?.map(
                        ({ id, full_name, initials, icon_color_index }) => ({
                          label: full_name || "",
                          value: String(id),
                          // eslint-disable-next-line react/no-unstable-nested-components
                          Component: () => (
                            <div className="tw-flex tw-items-center tw-gap-2">
                              <Avatar colorSeed={icon_color_index}>
                                {initials}
                              </Avatar>
                              {full_name}
                            </div>
                          ),
                        }),
                      ) || []
                    }
                    selected={field.value ? String(field.value) : null}
                    onSelect={(value: string) =>
                      handleAssigneeSelect(Number(value), field)
                    }
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="control_effectiveness_rating"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Control Effectiveness Rating</FormLabel>
                <FormControl>
                  <Select
                    items={
                      options()?.map((rating) => ({
                        label: rating?.title,
                        value: rating?.id.toString(),
                        // eslint-disable-next-line react/no-unstable-nested-components
                        Component: () => (
                          <MiniTag
                            size="lg"
                            color={getRatingColorName(rating?.color)}
                          >
                            {rating?.title || ""}
                          </MiniTag>
                        ),
                      })) || []
                    }
                    selected={field.value?.id.toString()}
                    onSelect={(value: string) => {
                      field.onChange(
                        options()?.find(
                          (rating: { id: number }) =>
                            rating.id.toString() === value,
                        ),
                      );
                    }}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="compliance_status"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Compliance Status</FormLabel>
                <FormControl>
                  <Select
                    items={COMPLIANCE_STATUS}
                    selected={field.value}
                    onSelect={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="summary"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Summary</FormLabel>
                <FormControl>
                  <TextArea {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Button
            className="tw-col-span-2 tw-mt-5 tw-place-self-end"
            type="submit"
          >
            Save
          </Button>
        </form>
      </Form>
      <br />
      <InfoPreviewSection
        title="Evidences"
        disabled={isNewModuleAssessment}
        actions={<DocumentUploadButton />}
        count={defaultValues?.evidences?.length}
        content={
          Boolean(defaultValues?.evidences?.length) && (
            <PreviewTable
              responsive
              columns={PREVIEW_DOCUMENTS_COLUMNS}
              // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
              rows={defaultValues?.evidences}
            />
          )
        }
      />
    </>
  );
}

export { ModuleAssessmentInfoForm, type ModuleAssessmentInfoSchema };
