import type { RecordTypePath } from "@/api";

import { useMonitoringAssetsPath } from "./records/use-monitoring-assets-path";

export interface RecordPathParams {
  recordType: string;
  recordId: string;
  workspaceId: string;
}

export type Hook = (params: RecordPathParams) => {
  path?: string;
  loading?: boolean;
};

const redirect: Partial<Record<string, Hook>> = {
  monitoring_assets: useMonitoringAssetsPath,
} as const satisfies Partial<Record<RecordTypePath, Hook>>;

export function useRecordPath(params: RecordPathParams) {
  const useRedirect = redirect[params.recordType];

  const path = useRedirect?.(params);

  return path;
}
