import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateFieldOptionMutationRequest,
  UpdateFieldOptionMutationResponse,
  UpdateFieldOptionPathParams,
} from "../../models/UpdateFieldOption";

/**
 * @description Update an existing field option
 * @summary Update an existing field option
 * @link /fields/:field_id/options/:id
 */
export async function updateFieldOption(
  fieldId: UpdateFieldOptionPathParams["field_id"],
  id: UpdateFieldOptionPathParams["id"],
  data: UpdateFieldOptionMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateFieldOptionMutationResponse>["data"]> {
  const res = await client<
    UpdateFieldOptionMutationResponse,
    UpdateFieldOptionMutationRequest
  >({
    method: "put",
    url: `/fields/${fieldId}/options/${id}`,
    data,
    ...options,
  });
  return res.data;
}
