import { faker } from "@faker-js/faker";

import type {
  GetAttestation200,
  GetAttestationPathParams,
  GetAttestationQueryResponse,
} from "../models/GetAttestation";
import { createAttestation } from "./createAttestation";

export function createGetAttestationPathParams(): NonNullable<GetAttestationPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createGetAttestation200(): NonNullable<GetAttestation200> {
  return { data: createAttestation() };
}

/**
 * @description OK
 */
export function createGetAttestationQueryResponse(): NonNullable<GetAttestationQueryResponse> {
  return { data: createAttestation() };
}
