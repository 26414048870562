import { faker } from "@faker-js/faker";

import type {
  UpdateTraining200,
  UpdateTrainingMutationRequest,
  UpdateTrainingMutationResponse,
  UpdateTrainingPathParams,
} from "../models/UpdateTraining";
import { createModifyTrainingRequest } from "./createModifyTrainingRequest";
import { createTraining } from "./createTraining";

export function createUpdateTrainingPathParams(): NonNullable<UpdateTrainingPathParams> {
  return { id: faker.string.alpha() };
}

/**
 * @description OK
 */
export function createUpdateTraining200(): NonNullable<UpdateTraining200> {
  return { data: createTraining() };
}

export function createUpdateTrainingMutationRequest(): NonNullable<UpdateTrainingMutationRequest> {
  return { training: createModifyTrainingRequest() };
}

/**
 * @description OK
 */
export function createUpdateTrainingMutationResponse(): NonNullable<UpdateTrainingMutationResponse> {
  return { data: createTraining() };
}
