import type { File } from "./File";
import { RecordTypePath } from "./RecordTypePath";

export type ExportPdfPathParams = {
  /**
   * @description Record type to fetch fields for
   */
  record_type: RecordTypePath;
  /**
   * @description The ID of the record
   * @type string
   */
  record_id: string;
};

export const exportPdfQueryParamsSections = {
  record_details: "record_details",
  record_comments_summary: "record_comments_summary",
  attachments: "attachments",
  attachments_comment_summary: "attachments_comment_summary",
} as const;
export type ExportPdfQueryParamsSections =
  (typeof exportPdfQueryParamsSections)[keyof typeof exportPdfQueryParamsSections];
export type ExportPdfQueryParams = {
  /**
   * @description Selected sections to include in PDF export
   * @type array | undefined
   */
  sections?: ExportPdfQueryParamsSections[];
};

/**
 * @description OK
 */
export type ExportPdf200 = {
  data: File;
};

/**
 * @description Forbidden
 */
export type ExportPdf403 = {
  /**
   * @type array | undefined
   */
  errors?: {
    /**
     * @description Error code identifying the type of error
     * @type string
     */
    code: string;
    /**
     * @description Human-readable error message
     * @type string
     */
    message: string;
  }[];
};

/**
 * @description OK
 */
export type ExportPdfQueryResponse = {
  data: File;
};

export type ExportPdfQuery = {
  Response: ExportPdfQueryResponse;
  PathParams: ExportPdfPathParams;
  QueryParams: ExportPdfQueryParams;
  Errors: ExportPdf403;
};
