import type { ThemeOptions } from "@mui/material";

export const checkbox: ThemeOptions["components"] = {
  MuiCheckbox: {
    defaultProps: {
      color: "primary",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.primary,
      }),
    },
  },
};
