import { recordTypePath } from "@themis/api/gen/models/recordTypePath";
import { Chip } from "@themis/ui-library/components/data-display/chip/chip";
import type { CustomCellRendererProps } from "ag-grid-react";
import { useMemo } from "react";

import type { Project } from "@/api/gen/models";
import { useSections } from "@/api/utils/sections/use-sections";
import { useCompanyId } from "@/hooks/use-company-id";

export function SectionsCell({ data }: CustomCellRendererProps<Project>) {
  const { companyId } = useCompanyId();
  const { sections } = useSections(companyId, recordTypePath.projects);
  const projectSections = data?.sections;
  const sectionsMap = useMemo(() => {
    return sections.reduce<{ key: string; value: string }[]>((acc, section) => {
      if (projectSections?.includes(section.key)) {
        return [...acc, { ...section }];
      }

      return acc;
    }, []);
  }, [sections, projectSections]);

  if (!data) {
    return null;
  }

  return (
    <div className="tw-px-2">
      {sectionsMap.map((section) => (
        <Chip
          key={section.key}
          label={section.value}
          colorSeed={section.key}
          size="small"
        />
      ))}
    </div>
  );
}
