import { Stack } from "@themis/ui";
import { type Control } from "react-hook-form";
import { useIntl } from "react-intl";

import { CheckboxFormField } from "../../../../../form-fields/checkbox-form-field/checkbox-form-field";
import { TextInputFormField } from "../../../../../form-fields/text-input-form-field/text-input-form-field";
import type { ValidationSchema } from "../../../use-validation-schema/use-validation-schema";

export interface SingleColumnControlsProps {
  control: Control<ValidationSchema>;
  isEdit?: boolean;
  showInternal: boolean;
}

export const SingleColumnControls = ({
  control,
  isEdit,
  showInternal,
  ...rest
}: SingleColumnControlsProps) => {
  const { formatMessage } = useIntl();

  return (
    <Stack spacing="2" {...rest}>
      <TextInputFormField
        required
        control={control}
        name="columnData.name"
        placeholder={formatMessage({ defaultMessage: "Column Name" })}
      />

      <CheckboxFormField
        control={control}
        name="columnData.requiredColumn"
        color="primary"
        label={formatMessage({ defaultMessage: "Required Column" })}
        size="md"
      />

      {showInternal && (
        <CheckboxFormField
          control={control}
          name="columnData.internal"
          color="primary"
          label={formatMessage({
            defaultMessage: "Visible to Internal Users only",
          })}
          size="md"
        />
      )}
    </Stack>
  );
};
