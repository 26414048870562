import { Table } from "@themis/ui";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { useIntl } from "react-intl";

import type { RecordLink, RecordTypePath } from "@/api";

import { useRecordLinkColumns } from "../../features/misc/record-link-section/use-record-link-columns/use-record-link-columns";
import { RecordLinkActions } from "./record-link-actions/record-link-actions";

interface RecordLinkTableProps {
  recordSelectDisabled?: boolean;
  linkType: "record" | "outward_record";
  recordId: number;
  recordType: RecordTypePath;
  rows: RecordLink[];
  showEmptyTable?: boolean;
}

export function RecordLinkTable({
  recordSelectDisabled = false,
  linkType,
  recordId,
  recordType,
  rows,
  showEmptyTable = false,
}: RecordLinkTableProps) {
  const { formatMessage } = useIntl();

  const columns = useRecordLinkColumns({
    type: linkType,
    recordType,
  });

  const showTable = showEmptyTable || Boolean(rows.length);

  return (
    <div className="tw-flex tw-w-full tw-flex-col tw-gap-2">
      <div className="tw-flex tw-items-center tw-justify-between">
        <Typography variant="h5">
          {linkType === "outward_record"
            ? formatMessage({
                defaultMessage: "Linked Records",
              })
            : formatMessage({
                defaultMessage: "Referenced In",
              })}
        </Typography>
        {linkType === "outward_record" && (
          <RecordLinkActions
            recordId={recordId}
            recordType={recordType}
            disabled={recordSelectDisabled}
          />
        )}
      </div>

      {showTable && <Table columns={columns} rows={rows} width="100%" />}
    </div>
  );
}
