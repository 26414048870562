import { useQueryClient } from "@tanstack/react-query";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@themis/ui";
import { Avatar } from "@themis/ui-library/components/data-display/avatar/avatar";
import { Tooltip } from "@themis/ui-library/components/data-display/tooltip/tooltip";
import { removeLocalStorageFilters } from "@themis/ui-library/components/data-grid/state/use-filters/remove-local-storage-filters/remove-local-storage-filters";
import { removeLocalStorageSort } from "@themis/ui-library/components/data-grid/state/use-sort/remove-local-storage-sort/remove-local-storage-sort";
import classNames from "classnames";
import { useState } from "react";
import { PiBellBold } from "react-icons/pi";
import { Link } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { useMainStore } from "@/contexts/Store";
import { clearLocalStorageFilters } from "@/hooks/use-filter-sort/clear-local-storage-filters/clear-local-storage-filters";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

const MENU_ITEM_CLASSES =
  "tw-pl-4 tw-flex tw-items-center tw-text-sm tw-min-h-8 tw-font-medium tw-tracking-lesswide tw-leading-5";

function UniversalHeader() {
  const mainStore = useMainStore();
  const [isAvatarOpen, setIsAvatarOpen] = useState(false);
  const { activeWorkspace } = mainStore.context;
  const queryClient = useQueryClient();
  const { shutdown, hardShutdown } = useIntercom();
  const { isCurrentWorkspaceActive } = mainStore.workspaces;

  const publicApiEnabled =
    isCurrentWorkspaceActive &&
    mainStore.featureFlags.getIsEnabled(FEATURE_FLAG_ID.COM_PUBLIC_API);

  const wsType =
    activeWorkspace && activeWorkspace.is_internal
      ? "Internal"
      : "Collaborative";
  const wsName = activeWorkspace && activeWorkspace.name;

  const handleSignOut = () => {
    mainStore.webSessions.delete();
    sessionStorage.clear();
    shutdown();
    hardShutdown();
    queryClient.clear();
    clearLocalStorageFilters(); // @sarah todo: remove this once useFiltersSort is deleted
    removeLocalStorageSort();
    removeLocalStorageFilters();
  };
  return (
    <div className="tw-flex tw-h-10 tw-min-h-10 tw-w-full tw-justify-between tw-bg-primaryDim-300">
      <div className="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-pl-4 tw-text-neutral-25">
        <label
          className="tw-text-semibold tw-text-base tw-font-semibold tw-leading-6 tw-tracking-tight"
          data-testid="dashboard-name-block"
        >
          {wsName}
        </label>
        <label className="tw-text-normal tw-text-xs tw-font-normal tw-leading-4">
          {wsType}
        </label>
      </div>
      <div className="tw-flex tw-items-center tw-gap-1 tw-pr-6">
        <span>
          <DropdownMenu onOpenChange={(isOpen) => setIsAvatarOpen(isOpen)}>
            <DropdownMenuTrigger asChild>
              <Tooltip title="Account Settings">
                <Avatar
                  colorSeed={mainStore.users.user.icon_color_index || 0}
                  aria-label="User Avatar"
                  className={classNames("tw-border-2 tw-border-solid", {
                    "tw-border-neutral-25": !isAvatarOpen,
                    "tw-border-neutral-500": isAvatarOpen,
                  })}
                  sx={{ cursor: "pointer" }}
                >
                  {mainStore.users.user.initials}
                </Avatar>
              </Tooltip>
            </DropdownMenuTrigger>

            <DropdownMenuContent className="tw-w-60" align="end">
              <Link to="/user">
                <DropdownMenuItem className={MENU_ITEM_CLASSES}>
                  Profile Details
                </DropdownMenuItem>
              </Link>
              {isCurrentWorkspaceActive && (
                <Link to="/user/integrations">
                  <DropdownMenuItem className={MENU_ITEM_CLASSES}>
                    Integration
                  </DropdownMenuItem>
                </Link>
              )}
              {publicApiEnabled && (
                <Link to="/user/api-keys">
                  <DropdownMenuItem className={MENU_ITEM_CLASSES}>
                    API Keys
                  </DropdownMenuItem>
                </Link>
              )}
              <div className="tw-border-0 tw-border-b tw-border-solid tw-border-primary-50" />
              <DropdownMenuItem
                onClick={handleSignOut}
                className={MENU_ITEM_CLASSES}
                aria-label="Sign Out"
              >
                Sign Out
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </span>
        <span
          onClick={() =>
            mainStore.notifications.setIsOpen(!mainStore.notifications.isOpen)
          }
          className="tw-ml-1 tw-mt-1 tw-flex tw-h-7 tw-w-7 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded hover:tw-bg-primaryDim-400"
        >
          <Tooltip title="Notifications">
            <div className="inline-block tw-relative">
              <PiBellBold color="white" size="16px" />

              {mainStore.notifications.unreadNotificationsCount > 0 && (
                <div className="tw-absolute tw-bottom-[21px] tw-left-[17px]">
                  <div className="tw-h-2 tw-w-2 tw-rounded-full tw-bg-red-500" />
                </div>
              )}
            </div>
          </Tooltip>
        </span>
      </div>
    </div>
  );
}

export default UniversalHeader;
