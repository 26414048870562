import { Stack } from "@themis/ui";
import type { ColDef } from "ag-grid-community";
import type { CustomCellRendererProps } from "ag-grid-react";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import type { Policy } from "@/api/queries/policies/policies-library/use-policies-library";

import { DownloadPolicyButton } from "./download-policy-button/download-policy-button";
import { ViewPolicyButton } from "./view-policy-button/view-policy-button";

export const usePoliciesLibraryColumns = (): ColDef<Policy>[] => {
  const { formatMessage } = useIntl();

  return useMemo(
    (): ColDef<Policy>[] => [
      {
        field: "title",
        headerName: formatMessage({ defaultMessage: "Title" }),
        cellRenderer: "textRenderCell",
      },
      {
        headerName: formatMessage({ defaultMessage: "Contributor" }),
        field: "contributor",
        cellRenderer: "textRenderCell",
      },
      {
        cellStyle: {
          padding: "3px 8px",
        },
        cellRenderer: ({ data }: CustomCellRendererProps<Policy>) => {
          if (!data) {
            return null;
          }

          return (
            <Stack direction="row" className="tw-items-center">
              <DownloadPolicyButton policy={data} />
              <ViewPolicyButton policy={data} />
            </Stack>
          );
        },
      },
    ],
    [formatMessage],
  );
};
