import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import { Card } from "@themis/ui-library/components/surfaces/card/card";
import { ClickAwayListener } from "@themis/ui-library/components/utils/click-away-listener/click-away-listener";
import { alpha, useTheme } from "@themis/ui-library/styles";
import type { ReactElement } from "react";
import { useState } from "react";

import type { User } from "@/stores/types/user-types";

import type { CommentWithUser } from "../../comment-with-user";
import { EditComment } from "../../edit-comment/edit-comment";
import { useFocusedComment } from "../../use-focused-comment/use-focused-comment";
import { CommentContent } from "../comment-content/comment-content";
import { ReplyHeader } from "../reply-header/reply-header";

interface ReplyProps {
  reply: CommentWithUser;
  recordId: number;
  recordName: string;
  recordTypePath: RecordTypePath;
  usersMap: Record<string, User>;
}

export function Reply({
  reply,
  recordId,
  recordName,
  recordTypePath,
  usersMap,
}: ReplyProps): ReactElement {
  const { getIsActive, resetFocused, scrollToWhenActive } = useFocusedComment();
  const theme = useTheme();

  const [isEditMode, setIsEditMode] = useState(false);

  const handleClickAway = () => {
    if (getIsActive(reply.id)) {
      resetFocused();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Card
        ref={(ref) => scrollToWhenActive(ref, reply.id)}
        component="article"
        elevation={0}
        sx={{
          backgroundColor: getIsActive(reply.id)
            ? alpha(theme.palette.secondary.main, 0.15)
            : undefined,
          padding: 1,
          transition: theme.transitions.create(["background-color"]),
        }}
      >
        <ReplyHeader
          comment={reply}
          recordId={recordId}
          recordName={recordName}
          recordTypePath={recordTypePath}
          onEditClick={() => setIsEditMode(true)}
        />

        {isEditMode ? (
          <EditComment
            comment={reply}
            recordId={recordId}
            recordTypePath={recordTypePath}
            usersMap={usersMap}
            onClose={() => setIsEditMode(false)}
          />
        ) : (
          <CommentContent comment={reply} />
        )}
      </Card>
    </ClickAwayListener>
  );
}
