import React, { useEffect, useMemo, useState } from "react";

import { useResizeObserver } from "../../hooks/useResizeObserver";
import { cn } from "../../lib/utils";
import { PdfViewer } from "./PdfViewer";

const FILE_TYPES = {
  PDF: ["application/pdf"],
  IMAGE: [
    "image/png",
    "image/avif",
    "image/gif",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/webp",
  ],
  VIDEO: ["video/mp4", "video/ogg", "video/webm", "video/quicktime"],
};

interface FileViewerProps {
  file: File | string;
  type: string;
  onClose?: () => void;
  overlay?: boolean;
}

export const FileViewer = ({
  file,
  type,
  onClose,
  overlay = true,
}: FileViewerProps) => {
  const [containerRef, setContainerRef] = useState<HTMLElement | null>();
  const observedContainer = useResizeObserver(containerRef);

  const maxWidth = observedContainer?.contentRect.width
    ? observedContainer?.contentRect.width * 0.8
    : 500;

  const handleOverlayClicked = () => {
    const closeFromOverlay = !FILE_TYPES.PDF.includes(type);

    if (!closeFromOverlay) {
      return;
    }

    onClose?.();
  };

  useEffect(() => {
    const handleKeyboardInput = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose?.();
      }
    };

    document.addEventListener("keydown", handleKeyboardInput, true);

    return () => {
      document.removeEventListener("keydown", handleKeyboardInput, true);
    };
  }, [onClose]);

  const viewerEl = useMemo(() => {
    if (FILE_TYPES.PDF.includes(type)) {
      return (
        <PdfViewer
          pdf={file}
          pageHeight={observedContainer?.contentRect.height}
          onClose={onClose}
        />
      );
    }

    if (FILE_TYPES.IMAGE.includes(type)) {
      const imageSrc =
        typeof file === "string" ? file : URL.createObjectURL(file);
      return (
        <img
          src={imageSrc}
          style={{
            maxWidth,
          }}
        />
      );
    }

    if (FILE_TYPES.VIDEO.includes(type)) {
      const videoSrc =
        typeof file === "string" ? file : URL.createObjectURL(file);
      return (
        <video
          src={videoSrc}
          style={{
            maxWidth,
          }}
          controls
        />
      );
    }

    return <div className="tw-text-white">Unsupported File Type</div>;
  }, [type, file, observedContainer?.contentRect.height, onClose, maxWidth]);

  return (
    <div
      className={cn(
        "tw-z-[102] tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-overflow-scroll",
        {
          "tw-fixed tw-right-0 tw-top-0 tw-bg-neutral-500 tw-bg-opacity-50":
            overlay,
        },
      )}
      ref={setContainerRef}
      onClick={handleOverlayClicked}
    >
      {viewerEl}
    </div>
  );
};
