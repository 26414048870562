import { ErrorStateCard } from "@themis/ui-library/components/surfaces/card/error-state-card/error-state-card";
import type { ReactElement } from "react";
import { useIntl } from "react-intl";

export function CommentsError(): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <ErrorStateCard
      variant="elevation"
      elevation={0}
      description={formatMessage({
        defaultMessage: "Could not load comments, please try again later.",
      })}
      sx={{
        margin: "auto",
        paddingX: 2,
        flexShrink: 0,
      }}
    />
  );
}
