import { useToast } from "@themis/ui";
import axios from "axios";
import { useIntl } from "react-intl";

import type { RecordTypePath, Relatable } from "@/api";
import RecordSelect from "@/features/tasks/components/Select/RecordSelect";

import { useCreateRecordLink } from "../../../api/queries/record-links/use-create-record-link";
import { useRecordLinks } from "../../../api/queries/record-links/use-record-links";
import { getIgnoredRecords } from "../../../features/misc/record-link-section/get-ignored-records/get-ignored-records";
import { useRouteWorkspaceId } from "../../../hooks/use-route-workspace-id";

interface RecordLinkActionsProps {
  recordId: number;
  recordType: RecordTypePath;
  disabled?: boolean;
}

export function RecordLinkActions({
  recordId,
  recordType,
  disabled,
}: RecordLinkActionsProps) {
  const toast = useToast();
  const { formatMessage } = useIntl();

  const workspaceId = useRouteWorkspaceId();

  const { data } = useRecordLinks(recordType, recordId);
  const recordLinksTo = data?.record_links_to ?? [];
  const recordLinksFrom = data?.record_links_from ?? [];

  const { mutateAsync: createRecordLink } = useCreateRecordLink({
    recordType,
    recordId,
  });

  const ignoredRecords = getIgnoredRecords({
    recordLinksFrom,
    recordLinksTo,
    recordId,
    recordType,
  });

  function handleCreate(selectedRecord: Relatable) {
    createRecordLink(selectedRecord, {
      onSuccess: () => {
        toast({
          content: formatMessage(
            {
              defaultMessage: "{name} has been linked!",
              description: "Success message when record is linked",
            },
            { name: selectedRecord.name },
          ),
          variant: "success",
        });
      },
      onError: (error) => {
        const error_msg =
          axios.isAxiosError(error) && error?.response?.data?.errors?.base
            ? error.response.data.errors.base
            : formatMessage(
                {
                  defaultMessage: "Failed to link {name}!",
                  description: "Error message when linking fails",
                },
                { name: selectedRecord.name },
              );
        toast({
          content: error_msg,
          variant: "error",
        });
      },
    });
  }

  return (
    <RecordSelect
      ignoredRecords={ignoredRecords}
      selectedWorkspaceId={workspaceId}
      isDisabled={disabled}
      onRecordSelect={handleCreate}
    />
  );
}
