import { Avatar } from "@themis/ui-library/components/data-display/avatar/avatar";
import { observer } from "mobx-react";

import type { RecordVersion, User } from "@/api";
import { useMainStore } from "@/contexts/Store";

import CreativeViewReviewers from "./CreativeViewReviewers";

interface Props {
  recordVersion: RecordVersion;
}

function CreativeViewSubmitterReviewers({ recordVersion }: Props) {
  const mainStore = useMainStore();

  const { allUsers } = mainStore.users;
  const submitter = allUsers?.find(
    (user) => user.id === recordVersion?.data?.submitter?.ids[0],
  ) || {
    full_name: "Not Found",
    initials: "NF",
    icon_color_index: 0,
  };

  const approvedUsers = allUsers.filter(
    (user) => recordVersion?.data?.approvers?.ids.includes(user.id),
  );

  function renderSubmitter(text: string, user: Partial<User>) {
    return (
      <span className="tw-flex tw-h-5 tw-items-end tw-space-x-3 tw-bg-neutral-25 tw-px-3 tw-py-1.5 tw-text-xs tw-font-normal">
        <div className="tw-pr-14 tw-text-neutral-300">{text}</div>
        <div className="tw-w-min-[300px] tw-flex tw-items-center tw-space-x-1">
          <Avatar size="sm" colorSeed={user.icon_color_index}>
            {user.initials}
          </Avatar>
          <span className="tw-text-neutral-500">{user.full_name}</span>
        </div>
      </span>
    );
  }

  return (
    <div className="tw-fixed tw-right-0 tw-top-[87px] tw-h-[60px] tw-w-[309px] tw-items-center tw-justify-center tw-gap-2 tw-border-[1px] tw-border-b-[5px] tw-border-solid tw-border-neutral-100 tw-bg-neutral-25">
      {renderSubmitter("Submitter", submitter)}
      <span className="tw-flex tw-h-5 tw-items-center tw-space-x-3 tw-bg-neutral-25 tw-px-3 tw-py-1.5 tw-text-xs tw-font-normal tw-text-neutral-300">
        <div className="tw-pr-10">Reviewers</div>
        {approvedUsers.length > 0 ? (
          <div className="tw-right-0">
            <CreativeViewReviewers reviewers={approvedUsers} text="" />
          </div>
        ) : (
          <div className="tw-right-0 tw-pl-4">No reviewers yet</div>
        )}
      </span>
    </div>
  );
}

export default observer(CreativeViewSubmitterReviewers);
