import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  GetAttestationPathParams,
  GetAttestationQueryResponse,
} from "../../models/GetAttestation";

/**
 * @description Get an attestation
 * @summary Get Attestation
 * @link /attestations/:id
 */
export async function getAttestation(
  id: GetAttestationPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<GetAttestationQueryResponse>["data"]> {
  const res = await client<GetAttestationQueryResponse>({
    method: "get",
    url: `/attestations/${id}`,
    ...options,
  });
  return res.data;
}
