import { Stack } from "@themis/ui";
import React from "react";
import type { ReactElement } from "react";
import { useFormContext, useWatch } from "react-hook-form";

import { useCurrentUser } from "@/api/queries/users";

import type { ValidationSchema } from "../use-validation-schema/use-validation-schema";
import { ColumnsTypeSelectField } from "./columns-type-select-field/columns-type-select-field";
import { CustomColumnControls } from "./custom-column-controls/custom-column-controls";

interface CustomColumnFormFieldsProps {
  isEdit?: boolean;
}

export function CustomColumnFormFields({
  isEdit,
}: CustomColumnFormFieldsProps): ReactElement {
  const { data: user } = useCurrentUser();
  const form = useFormContext<ValidationSchema>();
  const { columnType } = useWatch({ control: form.control });

  const showInternalOnly = !!user?.is_internal_user;

  return (
    <Stack spacing="2">
      <ColumnsTypeSelectField
        control={form.control}
        name="columnType"
        disabled={isEdit}
      />

      {columnType && (
        <CustomColumnControls
          isEdit={isEdit}
          columnType={columnType}
          control={form.control}
          showInternal={showInternalOnly}
        />
      )}
    </Stack>
  );
}
