import type { ChipProps as MuiChipProps } from "@mui/material/Chip";
import MuiChip from "@mui/material/Chip";
import type { CSSProperties } from "react";

import { getColor } from "./chip-colors";

export interface ChipProps extends Omit<MuiChipProps, "color"> {
  color?: MuiChipProps["color"];
  colorSeed?: number | string | null | "label";
  textTransform?: CSSProperties["textTransform"];
}

export function Chip({
  color,
  colorSeed,
  label,
  textTransform,
  ...rest
}: ChipProps) {
  const seedColor = getColor(colorSeed);

  return (
    <MuiChip
      color={color || seedColor}
      label={label}
      sx={{ textTransform }}
      {...rest}
    />
  );
}
