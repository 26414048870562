import type { CustomCellEditorProps } from "ag-grid-react";
import React from "react";

import type { ImagePickerProps } from "../../Form/ImagePicker";
import { ImagePicker } from "../../Form/ImagePicker";

type Props = CustomCellEditorProps &
  ImagePickerProps & {
    value: string;
    onValueChange: (value: string) => void;
    onSelectFile: (file: File) => void;
  };

function ImagePickerCell({
  value,
  onValueChange,
  onSelectFile,
  ...props
}: Props) {
  const editable = Boolean(props.colDef?.editable);

  const handleSelected = (file: File) => {
    if (onSelectFile) {
      onValueChange(file);
      onSelectFile(file);
    }
  };

  return (
    <ImagePicker
      {...props}
      className="tw-h-full tw-w-full tw-bg-white group-hover/row:tw-bg-neutral-50"
      url={value}
      onSelectFile={(file) => handleSelected(file)}
      readOnly={!editable}
    />
  );
}

export default ImagePickerCell;
