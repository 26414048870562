import Fade from "@mui/material/Fade";
import type { ReactElement } from "react";

import { alpha } from "../../../styles";
import { CircularProgress } from "../../feedback/circular-progress/circular-progress";
import type { StackProps } from "../../layout/stack/stack";
import { Stack } from "../../layout/stack/stack";

export function LoadingOverlay(props: StackProps): ReactElement {
  return (
    <Fade in>
      <Stack
        position="absolute"
        top={0}
        left={0}
        zIndex={2}
        width="100%"
        height="100%"
        role="status"
        justifyContent="center"
        alignItems="center"
        sx={(theme) => ({
          backgroundColor: alpha(theme.palette.background.paper, 0.4),
          // todo: replace once using css vars
          // backgroundColor: `rgba(${theme.vars.palette.background.paper} / 0.4)`,
        })}
        {...props}
      >
        <CircularProgress color="primary" variant="indeterminate" size={32} />
      </Stack>
    </Fade>
  );
}
