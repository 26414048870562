import type { DataWithIdAndCustomFields } from "@themis/ui";

import type { FilterFieldData } from "@/components/FilterSelect/utils/get-filters-field-data";

import { parseFilter } from "../parse-filter/parse-filter";
import { parseSortBy } from "../parse-sort-by/parse-sort-by";
import {
  type Filter,
  FilterOperand,
  type Filters,
  type Sorting,
} from "../types";

export function parseParams<T extends DataWithIdAndCustomFields>(
  searchParams: URLSearchParams,
  fieldsData: FilterFieldData<T>,
) {
  const filters: Filters<T> = {};
  let sorting: Sorting<T> | undefined;
  searchParams.forEach((value, key) => {
    if (key.startsWith("sort_by[")) {
      const parsedSortBy = parseSortBy(key, value);

      return (sorting = parsedSortBy as Sorting<T>);
    }

    if (!key.match(/filters\[[a-z_]+\]/)) {
      // ignore search params that aren't related to table filters
      return;
    }

    const fieldNameMatch = key.match(/filters\[([^\]]*)\]/);
    const fieldName = fieldNameMatch ? fieldNameMatch[1] : "";

    if (!(fieldName in fieldsData)) {
      // ignore search params that aren't related to table fields
      return;
    }

    const operandMatch = key.match(/filters\[[^\]]*\]\[([^\]]*)\]/);
    const operand = operandMatch ? operandMatch[1] : "";

    const parsedFilter = parseFilter(operand, value);
    if (parsedFilter) {
      filters[fieldName as keyof T] = parsedFilter;
    }
  });

  const listRequestQueryParams = {
    filters: Object.fromEntries(
      (Object.entries(filters) as Array<[keyof T, Filter]>).map(
        ([fieldName, filter]) => [
          fieldName,
          {
            [filter.operand]: [
              FilterOperand.ANY,
              FilterOperand.NOT,
              FilterOperand.MATCH,
            ].includes(filter.operand)
              ? (filter.value as string[]).join(",")
              : "",
          },
        ],
      ),
    ),
    ...(sorting && { sort_by: { [sorting.columnKey]: sorting.direction } }),
  };

  return {
    filters,
    sorting,
    searchParams,
    listRequestQueryParams,
  };
}
