import type { ThemeOptions } from "@mui/material";

export const skeleton: ThemeOptions["components"] = {
  MuiSkeleton: {
    styleOverrides: {
      root: {
        transform: "none",
      },
    },
  },
};
