import { recordTypePath } from "@themis/api/gen/models/recordTypePath";
import { usePersistedDataGridState } from "@themis/ui-library/components/data-grid/state/use-persisted-data-grid-state";
import { generatePath, useParams } from "react-router-dom";

import { useProjects } from "@/api/queries/projects";
import { Header } from "@/components/Layout/Header";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";
import { FilterOperand } from "@/hooks/use-filter-sort/types";
import mainStore from "@/stores/Main";
import { getLocalStorageTableKey } from "@/utils/get-local-storage-table-key/get-local-storage-table-key";

import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import { PROJECTS_LIST_PATH } from "../routes";

function ArchivedProjects() {
  const companyId = mainStore.companies.company?.id;
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const { data, isPending } = useProjects(Number(companyId), {
    filters: {
      archived_at: {
        [FilterOperand.PRESENT]: "true",
      },
    },
  });

  const projects = data?.data;

  const persistedDataGridState = usePersistedDataGridState({
    tableKey: getLocalStorageTableKey(workspace_id, recordTypePath.projects),
  });

  if (isPending) {
    return <Loading loadingLayout="table" />;
  }

  return (
    <PageLayout>
      <Header
        title={
          <div className="tw-flex tw-items-center tw-gap-2">
            Archived Projects
          </div>
        }
        backButtonLinkTo={generatePath(PROJECTS_LIST_PATH, {
          workspace_id,
        })}
      />
      {projects?.length ? (
        <ProjectsTable
          projects={projects}
          workspace_id={workspace_id}
          companyId={companyId}
          persistedDataGridState={persistedDataGridState}
          totalCount={data?.meta.total_count || 0}
        />
      ) : (
        "No archived projects"
      )}
    </PageLayout>
  );
}

export default ArchivedProjects;
