import { createTheme, ThemeProvider, useColorScheme } from "@mui/material";
import * as locales from "@mui/material/locale";
import type { ThemeProviderProps } from "@mui/material/styles/ThemeProvider";
import { useMemo } from "react";

import { darkTheme } from "../../theme/dark-theme";
import { lightTheme } from "../../theme/light-theme";

export interface MuiProvidersProps extends Omit<ThemeProviderProps, "theme"> {
  locale?: string;
}

const isSupportedLocale = (value: string): value is keyof typeof locales =>
  value in locales;

export const MuiThemeProvider = ({
  locale = window.navigator.language.replace("-", ""),
  children,
}: MuiProvidersProps) => {
  const { colorScheme } = useColorScheme();

  const theme = colorScheme === "dark" ? darkTheme : lightTheme;

  const themeWithLocale = useMemo(() => {
    if (isSupportedLocale(locale)) {
      return createTheme(theme, locales[locale]);
    }
    return theme;
  }, [locale, theme]);

  return (
    <ThemeProvider theme={themeWithLocale} noSsr>
      {/* // TODO: Enable CssBaseline everywhere */}
      {/* <CssBaseline enableColorScheme /> */}
      {children}
    </ThemeProvider>
  );
};
