import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  LegacyWebsiteMonitoringGroupsPathParams,
  LegacyWebsiteMonitoringGroupsQueryResponse,
} from "../../models/LegacyWebsiteMonitoringGroups";

/**
 * @description List Website Monitoring - Groups
 * @summary List Website Monitoring Groups (Legacy)
 * @link /companies/:company_id/website_monitoring/monitoring_groups
 */
export async function legacyWebsiteMonitoringGroups(
  companyId: LegacyWebsiteMonitoringGroupsPathParams["company_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<LegacyWebsiteMonitoringGroupsQueryResponse>["data"]> {
  const res = await client<LegacyWebsiteMonitoringGroupsQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/website_monitoring/monitoring_groups`,
    ...options,
  });
  return res.data;
}
