/* eslint-disable react/no-unstable-nested-components */
import type { SelectItem, TableProps } from "@themis/ui";
import {
  createComparatorById,
  getInitials,
  Table,
  TableComponent,
  useToast,
} from "@themis/ui";
import { Avatar } from "@themis/ui-library/components/data-display/avatar/avatar";
import type {
  NewValueParams,
  SortChangedEvent,
  ValueGetterParams,
  ValueSetterParams,
} from "ag-grid-community";
import type {
  CustomCellEditorProps,
  CustomCellRendererProps,
} from "ag-grid-react";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { generatePath, useParams } from "react-router-dom";

import type { Task, ThemisRecord, TaskStatus as TTaskStatus } from "@/api";
import { useCompany } from "@/api/queries/companies";
import { useTasks, useUpdateTask } from "@/api/queries/tasks";
import { useCompanyUsers } from "@/api/queries/users/use-company-users";
import { ErrorContainer } from "@/components/ErrorContainer";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";
import type { FilterOperand } from "@/hooks/use-filter-sort/types";

import { TaskStatus } from "../../config/status";
import { useMyTasksFilterSort } from "../../hooks/useMyTasksFilterSort";
import { TasksEmptyState } from "../TasksEmptyState";
import AssociatedRecordCell from "./AssociatedRecordCell";

type ColumnKey = keyof Task | "checkbox" | "actions";

export type TaskTableColumnKeys = Array<ColumnKey>;

type Column<TEntity extends ThemisRecord> = Omit<
  TableProps<Task>["columns"][number],
  "field"
> & {
  field?: keyof TEntity;
};

type Columns = Array<Column<Task> & { key: ColumnKey }>;

function TasksTable({
  displayedColumnKeys,
  isEditable = true,
  initialFilters,
}: {
  displayedColumnKeys: Array<ColumnKey>;
  isEditable?: boolean;
  initialFilters?: Partial<
    Record<keyof Task, Partial<Record<FilterOperand, string>>>
  > | null;
}) {
  const toast = useToast();
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const workspaceId = Number(workspace_id);

  const { data: companyData, isPending: isCompanyPending } =
    useCompany("current");
  const companyId = companyData?.data.company.id;

  const { taskDetail } = useMainStore();

  const { listRequestQueryParams, sorting, applyFilterSort } =
    useMyTasksFilterSort();

  const {
    data: users,
    isPending: isUsersPending,
    isError: isUsersError,
  } = useCompanyUsers({ companyId: Number(companyId) });

  const {
    data: tasksData,
    isPending: isTasksPending,
    isError: isTasksError,
  } = useTasks(
    Number(companyId),
    initialFilters !== null
      ? {
          ...listRequestQueryParams,
          filters: {
            ...listRequestQueryParams.filters,
            ...initialFilters,
          },
        }
      : null,
  );

  const isPending = isUsersPending || isTasksPending || isCompanyPending;

  const isError = isUsersError || isTasksError;

  const { mutateAsync: updateTask } = useUpdateTask({
    companyId: Number(companyId),
  });

  async function handleCheckboxCellChanged(
    params: NewValueParams<Task, boolean>,
  ) {
    try {
      await updateTask({
        id: params.data.id,
        task: {
          status: params.newValue
            ? TaskStatus.done.value
            : TaskStatus.in_progress.value,
        },
      });
      toast({
        content: `Task marked as "${
          params.newValue ? TaskStatus.done.label : TaskStatus.in_progress.label
        }" successfully!`,
        variant: "success",
      });
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...params.data,
            status: params.oldValue
              ? TaskStatus.done.value
              : TaskStatus.in_progress.value,
          },
        ],
      });
      toast({
        content: "There was an error changing the Task status",
        variant: "error",
      });
    }
  }

  async function handleNameCellChanged(params: NewValueParams<Task, string>) {
    try {
      await updateTask({
        id: params.data.id,
        task: {
          name: params.newValue || "",
        },
      });
      toast({
        content: `Task name changed successfully!`,
        variant: "success",
      });
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...params.data,
            name: params.oldValue || "",
          },
        ],
      });
      toast({
        content: "There was an error changing the Task name",
        variant: "error",
      });
    }
  }

  async function handleStatusCellChanged(
    params: NewValueParams<Task, TTaskStatus>,
  ) {
    if (!params.oldValue || !params.newValue) {
      // TODO: Look into if/when AgGrid will have these values be null or undefined as the types suggest
      window.console.warn(
        "AgGrid called onCellChanged with null or undefined values",
      );
      return;
    }

    try {
      await updateTask({
        id: params.data.id,
        task: {
          status: params.newValue,
        },
      });
      toast({
        content: `Task marked as "${
          TaskStatus[params.newValue].label
        }" successfully!`,
        variant: "success",
      });
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...params.data,
            status: params.oldValue,
          },
        ],
      });
      toast({
        content: "There was an error changing the Task status",
        variant: "error",
      });
    }
  }

  async function handleDateCellChanged(params: NewValueParams<Task, string>) {
    try {
      if (params.newValue?.toString() === params.oldValue?.toString()) {
        return;
      }
      await updateTask({
        id: params.data.id,
        task: {
          due_date: params.newValue || null,
        },
      });
      toast({
        content: `Task due date changed successfully!`,
        variant: "success",
      });
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...params.data,
            due_date: params.oldValue || null,
          },
        ],
      });
      toast({
        content: "There was an error changing the Task due date",
        variant: "error",
      });
    }
  }

  async function handleAssigneeCellChanged(
    params: NewValueParams<Task, string>,
  ) {
    try {
      if (params.newValue === params.oldValue) {
        return;
      }
      await updateTask({
        id: params.data.id,
        task: {
          assignee_id: params.newValue ? Number(params.newValue) : null,
        },
      });
      toast({
        content: `Task assignee changed successfully!`,
        variant: "success",
      });
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...params.data,
            assignee_id: params.oldValue ? Number(params.oldValue) : null,
          },
        ],
      });
      toast({
        content: "There was an error changing the Task assignee",
        variant: "error",
      });
    }
  }

  async function handleCollaboratorsCellChanged(
    params: NewValueParams<Task, string[]>,
  ) {
    try {
      if (!params.newValue && !params.oldValue) {
        return;
      }
      if (
        params.newValue?.every((val) => params.oldValue?.includes(val)) &&
        params.oldValue?.every((val) => params.newValue?.includes(val))
      ) {
        return;
      }
      await updateTask({
        id: params.data.id,
        task: {
          collaborator_ids: params.newValue?.map((val) => Number(val)) || [],
        },
      });
      toast({
        content: `Task collaborators changed successfully!`,
        variant: "success",
      });
    } catch (error) {
      params.api.applyTransaction({
        update: [
          {
            ...params.data,
            collaborator_ids: params.oldValue?.map((val) => Number(val)) || [],
          },
        ],
      });
      toast({
        content: "There was an error changing the Task collaborators",
        variant: "error",
      });
    }
  }

  const columns: Columns = useMemo(() => {
    const allColumns: Columns = [
      {
        key: "checkbox",
        headerName: "",
        width: 30,
        minWidth: 30,
        editable: isEditable,
        cellRenderer: TableComponent.checkboxCell,
        cellRendererParams: () => ({
          rounded: true,
        }),
        cellEditorParams: () => ({
          rounded: true,
        }),
        cellEditor: TableComponent.checkboxCell,
        valueGetter: (params: ValueGetterParams<Task>) =>
          params.data?.status === TaskStatus.done.value,
        valueSetter: (params: ValueSetterParams<Task>) => {
          params.data.status = params.newValue
            ? TaskStatus.done.value
            : TaskStatus.in_progress.value;
          return true;
        },
        sortable: false,
        onCellValueChanged: handleCheckboxCellChanged,
      },
      {
        key: "name",
        field: "name",
        headerName: "Name",
        cellRenderer: TableComponent.identifierCellRenderer,
        cellRendererParams: (props: CustomCellRendererProps<Task>) => ({
          onClick: () => taskDetail.open(props.data),
        }),
        width: 300,
        minWidth: 200,
        flex: 2,
        editable: isEditable,
        cellEditor: TableComponent.identifierCellEditor,
        cellEditorParams: (props: CustomCellRendererProps<Task>) => ({
          onClick: () => taskDetail.open(props.data),
        }),
        onCellValueChanged: handleNameCellChanged,
      },
      {
        key: "status",
        field: "status",
        headerName: "Status",
        cellRenderer: TableComponent.selectCell,
        cellRendererParams: {
          items: Object.values(TaskStatus).map((status) => ({
            label: status.label,
            value: status.value,
          })),
          renderSelected: ({ value }: Partial<SelectItem>) =>
            TaskStatus[value as keyof typeof TaskStatus].Component(),
        },
        editable: isEditable,
        cellEditor: TableComponent.selectCell,
        cellEditorParams: {
          items: Object.values(TaskStatus).map((status) => ({
            label: status.label,
            value: status.value,
          })),
          renderSelected: ({ value }: Partial<SelectItem>) =>
            TaskStatus[value as keyof typeof TaskStatus].Component(),
          defaultOpen: true,
        },
        minWidth: 120,
        onCellValueChanged: handleStatusCellChanged,
      },
      {
        key: "due_date",
        field: "due_date",
        headerName: "Due Date",
        valueGetter: (params: ValueGetterParams<Task>) =>
          params?.data?.due_date ? dayjs(params.data?.due_date).toDate() : null,
        cellRenderer: TableComponent.datePickerCell,
        cellRendererParams: {
          mode: "single",
        },
        cellEditor: TableComponent.datePickerCell,
        cellEditorParams: {
          mode: "single",
        },
        minWidth: 150,
        initialSort: "asc",
        editable: isEditable,
        onCellValueChanged: handleDateCellChanged,
      },
      {
        key: "assignee_id",
        field: "assignee_id",
        headerName: "Assignee",
        valueGetter: (params: ValueGetterParams<Task>) =>
          String(params.data?.assignee_id) || [],
        cellRenderer: TableComponent.selectCell,
        cellRendererParams: (props: CustomCellRendererProps<Task>) => {
          const userOptions =
            users?.map((user) => ({
              label: user.full_name || "No Name",
              value: String(user.id),
              Component: () => (
                <div className="tw-flex tw-items-center tw-gap-1">
                  <Avatar colorSeed={user.icon_color_index}>
                    {user.initials}
                  </Avatar>
                  <span>{user.full_name}</span>
                </div>
              ),
            })) || [];

          return {
            ...props,
            items: userOptions,
            renderSelected: ({
              label,
              value,
              Component,
            }: Partial<SelectItem>) => (
              <div className="tw-mr-1" key={value}>
                {Component &&
                  Component({
                    label: getInitials(label || ""),
                    value: value || "",
                  })}
              </div>
            ),
          };
        },
        minWidth: 200,
        flex: 2,
        comparator: createComparatorById({
          data: users || [],
          propertyKey: "full_name",
        }),
        editable: isEditable,
        cellEditor: TableComponent.selectCell,
        cellEditorParams: (props: CustomCellEditorProps<Task>) => {
          const userOptions =
            users?.map((user) => ({
              label: user.full_name || "No Name",
              value: String(user.id),
              Component: () => (
                <div className="tw-flex tw-items-center tw-gap-1">
                  <Avatar colorSeed={user.icon_color_index}>
                    {user.initials}
                  </Avatar>
                  <span>{user.full_name}</span>
                </div>
              ),
            })) || [];

          return {
            ...props,
            defaultOpen: true,
            items: userOptions,
            renderSelected: ({
              label,
              value,
              Component,
            }: Partial<SelectItem>) => (
              <div className="tw-mr-1" key={value}>
                {Component &&
                  Component({
                    label: getInitials(label || ""),
                    value: value || "",
                  })}
              </div>
            ),
          };
        },
        onCellValueChanged: handleAssigneeCellChanged,
      },
      {
        key: "collaborator_ids",
        field: "collaborator_ids",
        headerName: "Collaborators",
        valueGetter: (params: ValueGetterParams<Task>) =>
          params.data?.collaborator_ids.map(String) || [],
        cellRenderer: TableComponent.selectCell,
        cellRendererParams: (props: CustomCellRendererProps<Task>) => {
          const userOptions =
            users?.map((user) => ({
              label: user.full_name || "No Name",
              value: String(user.id),
              Component: ({ hideName }: { hideName: boolean }) => (
                <div className="tw-flex tw-items-center tw-gap-1">
                  <Avatar colorSeed={user.icon_color_index}>
                    {user.initials}
                  </Avatar>
                  {!hideName && <span>{user.full_name}</span>}
                </div>
              ),
            })) || [];

          return {
            ...props,
            multiple: true,
            items: userOptions,
            renderSelected: ({
              label,
              value,
              Component,
            }: Partial<SelectItem>) => (
              <div className="tw-mr-1" key={value}>
                {Component &&
                  Component({
                    label: getInitials(label || ""),
                    value: value || "",
                    // @ts-expect-error - TS doesn't know about the hideName prop
                    hideName: true,
                  })}
              </div>
            ),
          };
        },
        minWidth: 150,
        sortable: false,
        editable: isEditable,
        cellEditor: TableComponent.selectCell,
        cellEditorParams: (props: CustomCellRendererProps<Task>) => {
          const userOptions =
            users?.map((user) => ({
              label: user.full_name || "No Name",
              value: String(user.id),
              Component: ({ hideName }: { hideName: boolean }) => (
                <div className="tw-flex tw-items-center tw-gap-1">
                  <Avatar colorSeed={user.icon_color_index}>
                    {user.initials}
                  </Avatar>
                  {!hideName && <span>{user.full_name}</span>}
                </div>
              ),
            })) || [];

          return {
            ...props,
            multiple: true,
            items: userOptions,
            defaultOpen: true,
            renderSelected: ({
              label,
              value,
              Component,
            }: Omit<SelectItem, "Component"> & {
              Component: (params: {
                hideName: boolean;
                label: string;
                value: string;
              }) => JSX.Element;
            }) => (
              <div className="tw-mr-1" key={value}>
                {Component &&
                  Component({
                    label: getInitials(label || ""),
                    value: value || "",
                    hideName: true,
                  })}
              </div>
            ),
          };
        },
        onCellValueChanged: handleCollaboratorsCellChanged,
      },
      {
        key: "taskables",
        field: "taskables",
        headerName: "Associated Records",
        editable: false,
        cellRenderer: (params: CustomCellRendererProps<Task>) =>
          params.data && (
            <div className="tw-px-2.5">
              <AssociatedRecordCell task={params.data} />
            </div>
          ),
        minWidth: 300,
        sortable: false,
      },
    ];

    return allColumns.filter((column) =>
      displayedColumnKeys.includes(column.key),
    );
  }, [tasksData, users, sorting, displayedColumnKeys]);

  function handleSortChange(sortEvent: SortChangedEvent<Task>) {
    const sortedColumn = sortEvent.columns?.find((column) =>
      column.isSorting(),
    );

    applyFilterSort({
      sort: sortedColumn?.isSorting()
        ? {
            columnKey: sortedColumn.getColId(),
            direction: sortedColumn.getSort() as "asc" | "desc",
          }
        : null,
    });
  }

  if (isError) {
    return (
      <ErrorContainer
        backButtonProps={{
          linkTo: generatePath("/workspaces/:workspace_id/home", {
            workspace_id: workspaceId,
          }),
        }}
      >
        Could not load Tasks.
      </ErrorContainer>
    );
  }

  if (isPending) {
    return <Loading loadingLayout="table-no-add-new" />;
  }

  if (!tasksData) {
    return <TasksEmptyState />;
  }

  return tasksData.data.length ? (
    <Table
      width="100%"
      columns={columns}
      rows={tasksData.data}
      tableProps={{
        onSortChanged: handleSortChange,
      }}
    />
  ) : (
    <TasksEmptyState />
  );
}

export default observer(TasksTable);
