import type {
  GetRowIdFunc,
  GetRowIdParams,
  GridApi,
  GridReadyEvent,
} from "ag-grid-community";
import { useCallback, useMemo, useState } from "react";

import type { DataGridProps } from "../data-grid";
import type { DataGridState } from "../state/data-grid-state";
import type { ModelHasId } from "../types";

function defaultGetRowId<T extends object>({ data }: GetRowIdParams<T>) {
  return (data as any)?.id?.toString() ?? "";
}

interface BaseDataGridBindingParams<T extends object> {
  dataGridState: DataGridState;
  getRowId?: GetRowIdFunc<T>;
  isLoading?: boolean;
  pagination?: boolean;
  total: number;
  onDataGridStateChange?: (state: DataGridState) => void;
}

export type UseDataGridBindingParams<T extends object> =
  ModelHasId<T> extends true
    ? BaseDataGridBindingParams<T>
    : BaseDataGridBindingParams<T> & { getRowId: (row: T) => string };

export function useDataGridBinding<T extends object>({
  dataGridState,
  getRowId = defaultGetRowId,
  isLoading,
  pagination,
  total,
  onDataGridStateChange,
}: BaseDataGridBindingParams<T>) {
  const [gridApi, setGridApi] = useState<GridApi>();

  const handleGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api);
  }, []);

  const baseGridProps = useMemo((): DataGridProps<T> => {
    return {
      getRowId: getRowId as GetRowIdFunc<T>,
      isLoading,
      pagination,
      total,
      onGridReady: handleGridReady,
      dataGridState,
      onDataGridStateChange,
    };
  }, [
    dataGridState,
    getRowId,
    isLoading,
    handleGridReady,
    onDataGridStateChange,
    pagination,
    total,
  ]);

  return {
    gridApi,
    baseGridProps,
  };
}
