import type { DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  restrictToParentElement,
  restrictToVerticalAxis,
} from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { type ReactNode, useEffect } from "react";
import React, { useState } from "react";

import { SortableItem } from "./SortableItem";

export interface SortableListProps<T extends { id: UniqueIdentifier }> {
  classes?: {
    item: string;
  };
  className?: string;
  iconPosition?: "left" | "right";
  getIsItemDisabled?: (item: T) => boolean;
  items: Array<T>;
  renderItem: (item: T, index: number) => ReactNode;
  onReorder?: (items: Array<T>) => void;
}

function SortableList<T extends { id: UniqueIdentifier }>({
  items,
  classes,
  className,
  getIsItemDisabled,
  iconPosition = "right",
  renderItem,
  onReorder,
}: SortableListProps<T>) {
  const [localItems, setLocalItems] = useState<T[]>(items);

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setLocalItems((prev) => {
        const oldIndex = prev.findIndex((it) => it.id === active.id);
        const newIndex = prev.findIndex((it) => it.id === over?.id);
        const newArray = arrayMove(prev, oldIndex, newIndex);

        onReorder?.(newArray);
        return newArray;
      });
    }
  }

  return (
    <DndContext
      autoScroll
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={localItems}
        strategy={verticalListSortingStrategy}
      >
        <div className={className}>
          {localItems.map((item, index: number) => (
            <SortableItem
              key={item.id}
              className={classes?.item}
              id={item.id}
              iconPosition={iconPosition}
              isSortDisabled={getIsItemDisabled?.(item) ?? false}
            >
              {renderItem(item, index)}
            </SortableItem>
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
}

export { SortableList };
