import * as TabsPrimitive from "@radix-ui/react-tabs";
import type { ComponentPropsWithoutRef, ElementRef } from "react";
import React, { forwardRef } from "react";

import { cn } from "../../lib/utils";

const Tabs = forwardRef<
  ElementRef<typeof TabsPrimitive.Root>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Root>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Root
    ref={ref}
    {...props}
    className={cn(
      "tw-border-0 tw-border-b tw-border-solid tw-border-neutral-100",
      className,
    )}
  />
));

Tabs.displayName = TabsPrimitive.Root.displayName;

const TabsList = forwardRef<
  ElementRef<typeof TabsPrimitive.List>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "tw-box-border tw-inline-flex tw-h-10 tw-w-full tw-items-center tw-justify-start tw-gap-2 tw-rounded-md tw-bg-neutral-25 tw-p-1",
      className,
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "tw-inline-flex tw-h-8 tw-items-center tw-justify-center tw-whitespace-nowrap tw-rounded-3xl tw-px-3 tw-transition-all",
      "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2 ",
      "tw-font-sans tw-text-sm tw-font-semibold tw-text-primaryDim-300",
      "disabled:tw-pointer-events-none disabled:tw-opacity-50",
      "hover:tw-bg-primaryDim-25 data-[state=active]:tw-bg-primaryDim-300 data-[state=active]:tw-text-neutral-25",
      className,
    )}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "tw-ring-offset-background focus-visible:tw-ring-ring tw-mt-2 focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-offset-2",
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
