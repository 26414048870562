import { Table } from "@themis/ui";
import { useMemo } from "react";

import { usePoliciesLibrary } from "@/api/queries/policies/policies-library/use-policies-library";

import { usePoliciesLibraryColumns } from "./use-policies-library-columns/use-policies-library-columns";

export const PoliciesLibraryTable = () => {
  const { data = [] } = usePoliciesLibrary();

  const dataWithIds = useMemo(() => {
    return data.map((policy) => ({
      ...policy,
      id: policy.indicator,
    }));
  }, [data]);

  const columns = usePoliciesLibraryColumns();

  return (
    <Table
      columnDef={{ cellStyle: { padding: 8 } }}
      columns={columns}
      rows={dataWithIds}
      width="100%"
    />
  );
};
