/* eslint-disable default-param-last */
import type { AxiosResponse } from "axios";
import dayjs from "dayjs";

import type {
  AssigneeUpdateRequest,
  Assignment,
  FormQuestionUpsert,
  GetCWQuestionnairesResponse,
  GetQuestionnaireFormReadReponse,
  GetQuestionnaireResponse,
  GetQuestionnairesResponse,
  QuestionnaireAnswerPostRequest,
  QuestionnaireRead,
  QuestionnaireRequest,
  SummaryOverallRequest,
  SummaryQuestionGroupRequest,
} from "@/features/risk-assessment";

import api from "../api";

export const QuestionnaireAPI = {
  // GET /api/react/questionnaires
  async getAll(
    workspaceID: number,
    signal: AbortSignal | undefined = undefined,
    templates = false,
    searchQuery?: string,
    page?: number,
    isCollaborative?: boolean,
  ): Promise<GetQuestionnairesResponse> {
    const params: {
      template: boolean;
      name?: string;
      page?: number;
      collaborative?: boolean;
    } = {
      template: templates,
      name: searchQuery,
      page,
    };

    if (isCollaborative) {
      params.collaborative = true;
    }

    const response = await api.get<GetQuestionnairesResponse>(
      `/workspaces/${workspaceID}/questionnaires`,
      {
        signal,
        params,
      },
    );

    return response.data;
  },
  // GET /api/react/questionnaires
  async getAllCW(
    workspaceID: number,
    signal: AbortSignal | undefined = undefined,
  ): Promise<GetCWQuestionnairesResponse> {
    const response = await api.get<GetCWQuestionnairesResponse>(
      `/workspaces/${workspaceID}/questionnaires`,
      {
        signal,
      },
    );

    return response.data;
  },
  // GET /api/react/questionnaires/:id
  async get(id: number): Promise<QuestionnaireRead> {
    const response = await api.get<GetQuestionnaireResponse>(
      `/questionnaires/${id}`,
    );

    return response.data.questionnaire;
  },

  // PUT /api/react/questionnaires/:id
  async edit(
    id: number,
    questionnaire: QuestionnaireRequest,
  ): Promise<QuestionnaireRead> {
    const response = await api.put<
      QuestionnaireRequest,
      AxiosResponse<GetQuestionnaireResponse>
    >(
      // maybe get API_URL into api imported above
      `/questionnaires/${id}`,
      questionnaire,
    );

    return response.data.questionnaire;
  },

  // PUT /api/react/questionnaires/:id
  async updateSummaryQuestionGroup(
    id: number,
    questionGroupId: number,
    summary: string,
  ) {
    const payload = {
      questionnaire: {
        id,
        question_groups_attributes: {
          id: questionGroupId,
          summary,
        },
      },
    };
    const result = await api.put<
      SummaryQuestionGroupRequest,
      AxiosResponse<GetQuestionnaireResponse>
    >(`/questionnaires/${id}`, payload);
    return result.data.questionnaire;
  },

  // PUT /api/react/questionnaires/:id
  async updateSummaryOverallQuestionnaire(id: number, summary: string) {
    const payload = {
      questionnaire: {
        id,
        summary,
      },
    };
    const result = await api.put<
      SummaryOverallRequest,
      AxiosResponse<GetQuestionnaireResponse>
    >(`/questionnaires/${id}`, payload);
    return result.data.questionnaire;
  },

  // PUT /api/react/questionnaires/:id
  async updateGroupAssignments(
    id: number,
    questionGroupId: number,
    assignments: Assignment[],
  ) {
    const payload = {
      questionnaire: {
        id,
        question_groups_attributes: {
          id: questionGroupId,
          assignments_attributes: assignments,
        },
      },
    };
    const result = await api.put<
      AssigneeUpdateRequest,
      AxiosResponse<GetQuestionnaireResponse>
    >(`/questionnaires/${id}`, payload);
    return result.data.questionnaire;
  },

  // PUT /api/react/questionnaires/:id
  async updateQuestionAssignments(
    id: number,
    questionId: number,
    assignments: Assignment[],
  ) {
    const payload = {
      questionnaire: {
        id,
        questions_attributes: {
          id: questionId,
          assignments_attributes: assignments,
        },
      },
    };
    const result = await api.put<
      AssigneeUpdateRequest,
      AxiosResponse<GetQuestionnaireResponse>
    >(`/questionnaires/${id}`, payload);
    return result.data.questionnaire;
  },

  async updateQuestionResponders(
    id: number,
    questionId: number,
    assignments: Assignment[],
    isQuestion: boolean,
  ) {
    const generatePayload = () => {
      if (isQuestion) {
        return {
          questionnaire: {
            id,
            questions_attributes: {
              id: questionId,
              assignments_attributes: assignments,
            },
          },
        };
      }

      return {
        questionnaire: {
          id,
          question_groups_attributes: {
            id: questionId,
            assignments_attributes: assignments,
          },
        },
      };
    };
    const result = await api.post<
      AssigneeUpdateRequest,
      AxiosResponse<GetQuestionnaireFormReadReponse>
    >(`/questionnaires/${id}/set_responders`, generatePayload());
    return result.data.questionnaire;
  },

  // POST /api/react/workspaces/:workspace_id/questionnaires
  async create(
    workspaceID: number,
    request: QuestionnaireRequest,
  ): Promise<QuestionnaireRead> {
    const response: AxiosResponse<GetQuestionnaireResponse> = await api.post(
      `/workspaces/${workspaceID}/questionnaires`,
      request,
    );

    return response.data.questionnaire;
  },

  // POST /api/react/workspaces/:workspace_id/partners/:partner_id/questionnaires
  async createForPartner(
    workspaceID: number,
    partnerId: number,
    request: QuestionnaireRequest,
  ): Promise<QuestionnaireRead> {
    const response: AxiosResponse<GetQuestionnaireResponse> = await api.post(
      `/workspaces/${workspaceID}/partners/${partnerId}/questionnaires`,
      request,
    );

    return response.data.questionnaire;
  },

  delete(questionnaireId: number) {
    return api.delete(`/questionnaires/${questionnaireId}`);
  },

  async setInReview(questionnaireId: number): Promise<QuestionnaireRead> {
    const response: AxiosResponse<{ questionnaire: QuestionnaireRead }> =
      await api.post(`/questionnaires/${questionnaireId}/reviewing`);
    return response.data.questionnaire;
  },

  async finalize(questionnaireId: number): Promise<QuestionnaireRead> {
    const response: AxiosResponse<{ questionnaire: QuestionnaireRead }> =
      await api.post(`/questionnaires/${questionnaireId}/finalize`);
    return response.data.questionnaire;
  },

  // POST /questionnaires/:questionnaire_id/complete
  async complete(questionnaireId: number): Promise<QuestionnaireRead> {
    const response: AxiosResponse<{ questionnaire: QuestionnaireRead }> =
      await api.post(`/questionnaires/${questionnaireId}/complete`);
    return response.data.questionnaire;
  },

  // GET /api/react/workspaces/:workspace_id/questionnaires/export_template
  async downloadTemplate(workspaceID: number) {
    const response = await api.get(
      `/workspaces/${workspaceID}/questionnaires/export_template`,
    );
    const blob = new Blob([new Uint8Array(response.data)], {
      type: "application/octet-stream",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "questionnaire_template.xlsx";
    link.click();
  },

  // POST /api/react/workspaces/:workspace_id/questionnaires/import_template
  async importQuestionnaireTemplate(
    workspaceID: number,
    file: File,
    risk_methodology_id: number,
    isPublic = false,
  ) {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("risk_methodology_id", risk_methodology_id.toString());
    formData.append("is_public", isPublic.toString());
    const result = (await api.post(
      `/workspaces/${workspaceID}/questionnaires/import_template`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      // @ts-expect-error TS(7008) FIXME: Member 'questionnaire' implicitly has an 'any' typ... Remove this comment to see the full error message
    )) as { data: { questionnaire } };
    return result.data.questionnaire;
  },

  // POST /api/react/questionnaires/:id/import
  async importQuestionnaire(questionnaireId: number, file: File) {
    const formData = new FormData();
    formData.append("file", file);
    const result = (await api.post(
      `/questionnaires/${questionnaireId}/import`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
      // @ts-expect-error TS(7008) FIXME: Member 'questionnaire' implicitly has an 'any' typ... Remove this comment to see the full error message
    )) as { data: { questionnaire } };
    return result.data.questionnaire;
  },

  // GET /api/react/questionnaires/:id/export
  async downloadQuestionnaire(questionnaireId: number, as_template = false) {
    const response = await api.get(
      `/questionnaires/${questionnaireId}/export?export_as_template=${as_template}`,
    );
    const blob = new Blob([new Uint8Array(response.data)], {
      type: "application/octet-stream",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = response.headers["content-disposition"]
      .split("filename=")[1]
      .split(";")[0]
      .replace(/"/g, "");
    link.click();
  },

  sendQuestionnaireToCW(
    questionnaireID: number,
    workspaceIDs: number[],
  ): Promise<void> {
    return api.post(`/questionnaires/${questionnaireID}/send_to_cw`, {
      workspace_ids: workspaceIDs,
    });
  },

  async saveAnswers(
    questionnaireID: number,
    answeredQuestions: FormQuestionUpsert[],
    isComplete = false,
  ) {
    const response = await api.post<
      QuestionnaireAnswerPostRequest,
      AxiosResponse<GetQuestionnaireResponse>
    >(`/questionnaires/${questionnaireID}/submit_answers`, {
      completed: isComplete,
      questionnaire: {
        questions_attributes: answeredQuestions,
      },
    });

    return response.data.questionnaire;
  },

  // /api/react/workspaces/:workspace_id/questionnaires/export
  async downloadOverviewTable(workspaceID: number, isCollaborative: boolean) {
    const currentDate = dayjs().format("MM/DD/YYYY");

    const response = await api.get(
      `/workspaces/${workspaceID}/questionnaires/export_overview`,
      {
        params: isCollaborative ? { collaborative: true } : undefined,
      },
    );
    const blob = new Blob([new Uint8Array(response.data)], {
      type: "application/octet-stream",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Questionnaire_Overview_${currentDate}.xlsx`;
    link.click();
  },

  // PUT /api/react/questionnaires/:id
  updateDueDate(questionnaireID: number, date: Date | string) {
    api.put(`/questionnaires/${questionnaireID}`, {
      questionnaire: { due_date: date },
    });
  },
};
