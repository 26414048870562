import type { FieldConfiguration } from "@/api";

export interface FilterValueOption {
  value: string;
  key: string;
}

export interface FilterData {
  displayName: string;
  type: "string";
  options?: FilterValueOption[];
}

export type FilterFieldData<T> = Partial<Record<keyof T, FilterData>>;

export function getFiltersFieldData<T>(
  fields: FieldConfiguration[] | undefined,
) {
  if (!fields) {
    return;
  }

  return fields.reduce<FilterFieldData<T>>((acc, field) => {
    if (field.field_type === "text") {
      return {
        ...acc,
        [field.path]: {
          displayName: field.display_name,
          type: "string",
        },
      };
    }

    if (!field.options) {
      return acc;
    }

    return {
      ...acc,
      [field.path]: {
        displayName: field.display_name,
        options: field.options.map((option) => ({
          value: option.value,
          key: option.key,
        })),
        type: "string",
      },
    };
  }, {});
}
