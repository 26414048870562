import React from "react";

import ContentCell from "@/features/risk-assessment/components/Table/GenericTable/Cells/ContentCell";

import type { TextAnswer } from "../../../types/questionnaire-form";
import LongTextTableCell from "../../Table/GenericTable/LongTextTableCell";
import type { AnswerReviewProps } from "./AnswerReview";
import AnswerReview from "./AnswerReview";

export default function TextAnswerReview(
  props: Omit<AnswerReviewProps, "AnswerCell" | "RiskRatingCell">,
) {
  return (
    <AnswerReview
      {...props}
      AnswerCell={
        <LongTextTableCell>
          {(props.question.answer as TextAnswer | null)?.value}
        </LongTextTableCell>
      }
      RiskRatingCell={
        <ContentCell
          content={
            <div
              data-tooltip-id="tooltip"
              data-tooltip-content="This question hasn't been answered yet"
              data-tooltip-place="bottom"
            >
              N/A
            </div>
          }
        />
      }
    />
  );
}
