import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";
import type { SessionTiming } from "@/stores/types/session-timing";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

const ACCESS_TOKEN_KEY = "access-token";

export default class WebSessionsStore {
  mainStore: MainStore;

  accessToken: string | null = null;
  webSessionErrors = {};
  banner: string | null = null;
  sessionTiming: SessionTiming = {
    promptDelay: 1000 * 60 * 20,
    expirationTimeout: 1000 * 60 * 60,
  };

  constructor(mainStore: MainStore) {
    // IMPORTANT: get "accessToken" from local storage before making it observable
    this.getAccessToken();

    makeObservable(this, {
      accessToken: observable,
      webSessionErrors: observable,
      banner: observable,
      sessionTiming: observable,
      setAccessToken: action,
      getAccessToken: action,
      removeAccessToken: action,
      setWebSessionErrors: action,
      clearBanner: action,
      setBanner: action,
      setSessionTiming: action,
    });

    this.mainStore = mainStore;
  }

  async create(email: string, password: string, rememberMe = false) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userParams = {
      user: {
        email,
        password,
        timezone,
        remember_me: rememberMe,
      },
    };

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/web_sessions`,
        headers: this.mainStore.getHeaders(),
        data: userParams,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.setWebSessionErrors(result.response.data.errors);
        return;
      }

      this.setAccessToken(result.data.web_session.access_token);
      this.mainStore.users.setUser(result.data.user);
      this.clearBanner();

      return result.data.user;
    } catch (error) {
      window.console.log(`"WebSessions#Create" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'token' implicitly has an 'any' type.
  async createWithSSOToken(token) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const userParams = {
      user: {
        token,
        timezone,
      },
    };

    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/web_sessions`,
        headers: this.mainStore.getHeaders(),
        data: userParams,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.setWebSessionErrors(result.response.data.errors);
        return;
      }

      this.setAccessToken(result.data.web_session.access_token);
      this.mainStore.users.setUser(result.data.user);

      return result.data.user;
    } catch (error) {
      window.console.log(`"WebSessions#Create" error ${error}`);
    }
  }

  delete() {
    legacyApi({
      method: "DELETE",
      url: `${API_URL}/web_sessions/current`,
      headers: this.mainStore.getHeaders(),
    })
      .then(() => {
        this.removeAccessToken();
      })
      .catch(() => {
        this.removeAccessToken();
      });
  }

  handleAuthenticationError(): void {
    if (this.accessToken) {
      this.setBanner("Your session timed out due to inactivity.");
    }
    this.removeAccessToken();
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setAccessToken(value) {
    // If you are invited to Themis from the email
    // This method gets hit AFTER you submit your new password and name etc
    // but on the first page load... DataLoader() is called and sets some new info in the store.
    // so this doesn't work because this.mainStore.cleanup() is removing data that you need

    // if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
    //   window.localStorage.removeItem(ACCESS_TOKEN_KEY)
    //   this.mainStore.cleanup()
    // }

    this.accessToken = value;
    window.localStorage.setItem(ACCESS_TOKEN_KEY, value);
  }

  getAccessToken() {
    if (!this.accessToken) {
      this.accessToken = window.localStorage.getItem(ACCESS_TOKEN_KEY);
    }
    return this.accessToken;
  }

  removeAccessToken() {
    window.localStorage.removeItem(ACCESS_TOKEN_KEY);
    this.accessToken = null;

    this.mainStore.cleanup();
  }

  setWebSessionErrors(value = {}) {
    this.webSessionErrors = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
  setBanner(message) {
    this.banner = message;
  }

  setSessionTiming(sessionTiming: SessionTiming) {
    this.sessionTiming = sessionTiming;
  }

  clearBanner() {
    // @ts-expect-error TS(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
    this.banner = undefined;
  }

  // Store Helpers

  cleanup() {
    this.setWebSessionErrors({});
  }
}
