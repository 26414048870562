import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  GetCompanyAttestationsPathParams,
  GetCompanyAttestationsQueryParams,
  GetCompanyAttestationsQueryResponse,
} from "../../models/GetCompanyAttestations";

/**
 * @description Get all attestations in company across modules. Workspace ID is required.
 * @summary Get Attestations
 * @link /companies/:company_id/attestations
 */
export async function getCompanyAttestations(
  companyId: GetCompanyAttestationsPathParams["company_id"],
  params: GetCompanyAttestationsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<GetCompanyAttestationsQueryResponse>["data"]> {
  const res = await client<GetCompanyAttestationsQueryResponse>({
    method: "get",
    url: `/companies/${companyId}/attestations`,
    params,
    ...options,
  });
  return res.data;
}
