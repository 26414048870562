import Stack from "@mui/material/Stack";

import { Container } from "../../../layout/container/container";
import type { ContainerProps } from "../../../layout/container/container";

export function PageContent({
  spacing = 3,
  children,
  padding = 2,
  maxWidth = false,
  width,
  ...rest
}: ContainerProps<typeof Stack>) {
  return (
    <Container
      component={Stack}
      flexGrow={1}
      maxWidth={maxWidth}
      padding={padding}
      spacing={spacing}
      width={width}
      sx={{ "&": { width, padding } }}
      {...rest}
    >
      {children}
    </Container>
  );
}
