import { inputBaseClasses, type ThemeOptions } from "@mui/material";
import { CaretDown, X } from "@phosphor-icons/react";

import { TextField } from "../text-field/text-field";

export const autocomplete: ThemeOptions["components"] = {
  MuiAutocomplete: {
    defaultProps: {
      popupIcon: <CaretDown />,
      clearIcon: <X />,
      renderInput: TextField,
      limitTags: 2,
      slotProps: {
        chip: {
          size: "small",
        },
      },
    },
    styleOverrides: {
      root: ({ theme }) => ({
        [`.${inputBaseClasses.root}`]: {
          padding: theme.spacing(0, 1),
        },
      }),
    },
  },
};
