import { faker } from "@faker-js/faker";

import type { BaseAttester } from "../models/BaseAttester";

export function createBaseAttester(
  data: NonNullable<Partial<BaseAttester>> = {},
): NonNullable<BaseAttester> {
  return {
    ...{
      id: faker.number.int(),
      assignee_id: faker.number.int(),
      assignee_type: faker.string.alpha(),
      initials: faker.string.alpha(),
      full_name: faker.string.alpha(),
      color: faker.string.alpha(),
    },
    ...data,
  };
}
