import classNames from "classnames";
import { capitalize } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiWarning } from "react-icons/pi";
import { useIntl } from "react-intl";
import Popup from "reactjs-popup";

import { Icon } from "@/components/Elements";
import { FIELD_TYPES } from "@/components/helpers/fieldTypes";
import type { ColorName } from "@/config/theme";
import { MAX_FIELD_NAME_LENGTH } from "@/constants";
import { useMainStore } from "@/contexts/Store";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

import checkIcon from "../../../images/table-image/icon/check-icon.svg";
import type { IconName } from "../../Elements/Icon/icon";
import { useResize } from "../../useResize/useResize";
import Switch from "./Switch";

interface Icons {
  [key: string]: IconName;
}

export function iconForDataType(dataType: string): IconName {
  const icons: Icons = {
    "com.askthemis.types.v1.attachment": "attachment",
    "com.askthemis.types.v1.checkbox": "tag",
    "com.askthemis.types.v1.checklist": "checkedLinear",
    "com.askthemis.types.v1.constrained_option": "listBullet",
    "com.askthemis.types.v1.control_effectiveness_rating": "listBullet",
    "com.askthemis.types.v1.date": "calendar",
    "com.askthemis.types.v1.float": "number",
    "com.askthemis.types.v1.integer": "number",
    "com.askthemis.types.v1.link": "link",
    "com.askthemis.types.v1.long_text": "text",
    "com.askthemis.types.v1.multiline_text": "text",
    "com.askthemis.types.v1.number": "number",
    "com.askthemis.types.v1.option": "listBullet",
    "com.askthemis.types.v1.record_policy": "contact",
    "com.askthemis.types.v1.record_procedure": "contact",
    "com.askthemis.types.v1.tag_department": "tag",
    "com.askthemis.types.v1.tag_product": "tag",
    "com.askthemis.types.v1.tag_user_contact": "contact",
    "com.askthemis.types.v1.tag_user": "contact",
    "com.askthemis.types.v1.tag_verifier": "contact",
    "com.askthemis.types.v1.tag": "tag",
    "com.askthemis.types.v1.text": "text",
  };

  return icons[dataType] || "text";
}

// @ts-expect-error TS(7006) FIXME: Parameter 'computedColumnIdentifier' implicitly ha... Remove this comment to see the full error message
export function titleForComputedDataType(computedColumnIdentifier) {
  switch (computedColumnIdentifier) {
    case "record_version":
      return "Version";
    case "owner_id":
      return "Owner";
    case "meta.days_open":
      return "Days Open";
    case "questionnaires":
      return "Questionnaires";
    case "contracts":
      return "Contracts";
    case "policies_controls":
      return "Policies";
    case "procedures_controls":
      return "Procedures";
    default:
      return "Unknown";
  }
}

const FILTERABLE_TYPES = [
  "com.askthemis.types.v1.attachment",
  "com.askthemis.types.v1.date",
  "com.askthemis.types.v1.integer",
  "com.askthemis.types.v1.long_text",
  "com.askthemis.types.v1.multiline_text",
  "com.askthemis.types.v1.number",
  "com.askthemis.types.v1.option",
  "com.askthemis.types.v1.tag_department",
  "com.askthemis.types.v1.tag_product",
  "com.askthemis.types.v1.tag_user_contact",
  "com.askthemis.types.v1.tag_user",
  "com.askthemis.types.v1.text",
];

// @ts-expect-error TS(7006) FIXME: Parameter 'displayName' implicitly has an 'any' ty... Remove this comment to see the full error message
function dataTestNameFromDisplayName(displayName) {
  return `${displayName
    ?.toString()
    .toLowerCase()
    .replace(/ /g, "-")}-column-trigger`;
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onDelete?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRename?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSortByDirection?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSortByFieldName?: (...args: any[]) => any;
  sortDirection?: string;
  disableResize?: boolean;
}

function ModuleTableColumn({
  field,
  sortDirection,
  setSortByDirection,
  setSortByFieldName,
  onDelete,
  onRename,
  disableResize,
}: Props) {
  const mainStore = useMainStore();
  const { formatMessage } = useIntl();

  // Variables
  const {
    workspaceID,
    moduleWorkspaceID,
    tableID,
    isCompanySuperAdmin,
    isCompanyAdmin,
  } = mainStore.context;
  const { canManageColumns, hasModuleWriteAccess } = mainStore.userPermissions;
  const fieldName = field.name;
  const isThemisID = field.name === "id";
  const width = isThemisID ? 160 : field.width || 200;
  const displayName = field.display_name;
  const isPinned = fieldName === mainStore.fields.pinnedFieldName;
  const isCustom = field.is_custom_field;
  const isHidden = field.is_hidden;
  const isDefaultField = field.is_default_field;
  const { isCW } = mainStore.context;
  const columnData = FIELD_TYPES.find((type) => type.value === field.data_type);
  const isKriInputStart = field.kri_input_start_month;
  const isKriInputEnd = field.kri_input_end_month;
  const isInternalUserVisible = field.is_visible_to_internal_users_only;
  const rulesIndicatingRequiredField = [
    "present",
    "exists",
    "validators",
    "attachment",
    "checklist",
  ];
  const isRequired =
    // field is not always type Field therefore does not always have validation_rules
    rulesIndicatingRequiredField.some(
      (rule) => field.validation_rules?.[rule],
    ) ||
    (isCustom && field.is_required);
  const isInternalUserVisibilityEnabled =
    isCW &&
    mainStore.context.themisModuleIdentifier ===
      TopLevelModule.ISSUE_MANAGEMENT &&
    (isCompanySuperAdmin || isCompanyAdmin);

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [popUp, setPopUp] = useState(null);
  const [fieldTitle, setFieldTitle] = useState(displayName || "");

  // Pre-fetch data for Columns
  useEffect(() => {
    if (
      (workspaceID &&
        dataType === "record:policy" &&
        mainStore.records.policies.length === 0) ||
      (dataType === "record:procedure" &&
        mainStore.records.procedures.length === 0)
    ) {
      mainStore.records.index(dataType, workspaceID);
    }
  }, []);

  const ref = React.useRef();
  const options = {
    step: 1,
    axis: "horizontal",
  };
  const { initResize, size } = useResize(ref, options, width);

  const style = { width };

  const dataType = field.data_type;

  function saveNewWidth() {
    // Only persist change if new width has changed
    if (size?.width && size.width !== width) {
      mainStore.fields.updateWidth(tableID, fieldName, size.width);
    }
  }

  function closePopUp() {
    setShowPopup(false);
    setPopUp(null);
  }

  async function togglePin() {
    await mainStore.fields.togglePinnedField(moduleWorkspaceID, fieldName);
  }

  function handleCustomColumnRenameSubmit(
    event: React.FormEvent<HTMLFormElement>,
  ) {
    event.preventDefault();
    if (onRename && fieldTitle !== displayName) {
      onRename(fieldName, fieldTitle);
    }
    setShowPopup(false);
  }

  function sortColumn(sortName: SortOptions) {
    if (!filterable) {
      return;
    }
    if (setSortByDirection && setSortByFieldName && fieldName) {
      setSortByDirection(sortName);
      setSortByFieldName(fieldName);
      closePopUp();
    }
  }

  function handleClearSortValues() {
    if (setSortByFieldName) {
      setSortByFieldName(null);
    }
    if (setSortByDirection) {
      setSortByDirection(null);
    }
    closePopUp();
  }

  function pinUnpin() {
    togglePin();
    closePopUp();
  }

  useEffect(() => {
    window.addEventListener("mouseup", saveNewWidth);

    return () => {
      window.removeEventListener("mouseup", saveNewWidth);
    };
  }, [size.width]);

  const filterable = FILTERABLE_TYPES.includes(field.data_type);
  const headerClasses = classNames("list-title-wrap", {
    sorted: sortDirection,
    pinned: isPinned,
    "global-id-width global-id-column": isThemisID,
    "global-id-mr first-position": field.position === 1 && isThemisID,
  });

  const isPopupDisabled =
    [
      `/workspaces/${workspaceID}/modules/vendor-due-diligence/templates`,
      `/workspaces/${workspaceID}/modules/vendor-due-diligence/risk-assessment-templates`,
    ].includes(location.pathname) ||
    (location.pathname.startsWith(
      `/workspaces/${workspaceID}/modules/qa-tests`,
    ) &&
      location.pathname.endsWith("/linked_documents")); // LinkedDocuments are without context menu

  if (isHidden) {
    return <div />;
  }

  function showColumnIcon(): JSX.Element {
    if (isThemisID) {
      return <Icon name="globe" color="brandingHoverNavy" />;
    }

    if (isPinned || sortDirection) {
      return (
        <Icon
          name={iconForDataType(dataType)}
          color="brandingHighlightTurquoise"
        />
      );
    }

    return <Icon name={iconForDataType(dataType)} color="generalDarkGray" />;
  }

  function showPinnedIcon(): JSX.Element {
    return isThemisID ? (
      <Icon name="anchor" color="brandingHoverNavy" data-testid="anchor-icon" />
    ) : (
      <Icon
        name="anchor"
        color="brandingHighlightTurquoise"
        data-testid="anchor-icon"
      />
    );
  }

  function showSortDirectionIcon(): JSX.Element {
    let sourceIcon: IconName;
    let dataTestId: string;

    const color: ColorName = isThemisID
      ? "brandingHoverNavy"
      : "brandingHighlightTurquoise";

    if (sortDirection === "ASC") {
      sourceIcon = "arrowUp";
      dataTestId = "asc-icon";
    } else {
      sourceIcon = "arrowDown";
      dataTestId = "desc-icon";
    }

    return <Icon name={sourceIcon} color={color} data-testid={dataTestId} />;
  }

  function showKriIcon(icon: "pause" | "play"): JSX.Element {
    const color = isPinned ? "brandingHighlightTurquoise" : "generalDarkGray";
    return <Icon name={icon} color={color} size="de" />;
  }

  async function handleVisibilityToggleForInternalUsers() {
    if (!tableID) {
      return;
    }

    await mainStore.fields.updateVisibleToInternalUsers(
      tableID,
      field.name,
      !field.is_visible_to_internal_users_only,
    );
  }

  return (
    <li className={headerClasses}>
      {/* @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<undefined>' is not assignab... Remove this comment to see the full error message */}
      <div ref={ref} className="title-resize-wrap" style={style}>
        <Popup
          position="bottom right"
          trigger={
            <div className="title-trigger-wrap" data-testid="column-trigger">
              <div
                className={classNames("title-trigger-block", {
                  "global-id": isThemisID,
                })}
                data-testid={dataTestNameFromDisplayName(field.display_name)}
              >
                {isInternalUserVisible && (
                  <PiWarning
                    size="16"
                    className="tw-text-primary-300"
                    data-tooltip-id="tooltip"
                    data-tooltip-content={formatMessage({
                      defaultMessage: "Column Only Visible to Internal Users",
                    })}
                    data-tooltip-place="bottom"
                  />
                )}
                {showColumnIcon()}

                <label
                  className={
                    isThemisID ? "global-id-column-context" : "column-title"
                  }
                  title={displayName}
                >
                  {displayName}
                </label>

                {isRequired && <div className="required">*</div>}

                {isKriInputStart && showKriIcon("play")}
                {isKriInputEnd && showKriIcon("pause")}

                {isPinned && showPinnedIcon()}

                {sortDirection && showSortDirectionIcon()}
              </div>
            </div>
          }
          disabled={isPopupDisabled}
          open={showPopup}
          onOpen={() => setShowPopup(true)}
          onClose={closePopUp}
          keepTooltipInside
        >
          {!popUp && (
            <div
              className="table-dropdown title-select"
              data-testid="column-dropdown"
            >
              {hasModuleWriteAccess && canManageColumns && (
                <form
                  className="title-input-block"
                  onSubmit={handleCustomColumnRenameSubmit}
                >
                  <input
                    type="text"
                    value={fieldTitle}
                    maxLength={MAX_FIELD_NAME_LENGTH}
                    onChange={(event) => setFieldTitle(event.target.value)}
                    data-testid="column-dropdown-title-input"
                    className="custom-column-input "
                  />
                  <button
                    disabled={fieldTitle.length > MAX_FIELD_NAME_LENGTH}
                    className="check-icon"
                    data-testid="custom-column-rename-submit"
                  >
                    <img src={checkIcon} alt="icon" />
                  </button>
                </form>
              )}

              <div className="title-block">
                <h5>
                  {field.name
                    .split("_")
                    .map((name: string) =>
                      name === "id" ? "ID" : capitalize(name),
                    )
                    .join(" ")}
                </h5>
                <ul>
                  <li className="property">
                    <Icon
                      name={iconForDataType(field.data_type)}
                      color="generalLightBlue"
                    />
                    {columnData?.label
                      ? columnData?.label
                      : formatMessage({ defaultMessage: "Free Type" })}
                  </li>
                </ul>
              </div>
              <hr />
              <ul>
                <li
                  className={classNames(
                    { enabled: filterable },
                    { active: sortDirection === "ASC" },
                  )}
                  onClick={() => sortColumn("ASC")}
                  data-testid="sort-asc-button"
                >
                  {filterable ? (
                    <Icon name="arrowUp" color="generalDark" />
                  ) : (
                    <Icon name="arrowUp" color="generalDarkGray" />
                  )}
                  {formatMessage({ defaultMessage: "Sort Ascending" })}
                </li>
                <li
                  className={classNames(
                    { enabled: filterable },
                    { active: sortDirection === "DESC" },
                  )}
                  onClick={() => sortColumn("DESC")}
                  data-testid="sort-desc-button"
                >
                  {filterable ? (
                    <Icon name="arrowDown" color="generalDark" />
                  ) : (
                    <Icon name="arrowDown" color="generalDarkGray" />
                  )}
                  {formatMessage({ defaultMessage: "Sort Descending" })}
                </li>
                <li
                  className={classNames("pin", "enabled", {
                    active: isPinned,
                  })}
                  onClick={pinUnpin}
                  data-testid="pin-unpin-button"
                >
                  <Icon name="anchor" color="generalDark" />
                  {isPinned
                    ? formatMessage({ defaultMessage: "Unpin" })
                    : formatMessage({ defaultMessage: "Pin" })}
                </li>

                {sortDirection && (
                  <>
                    <hr />
                    <li
                      className="clear"
                      onClick={() => handleClearSortValues()}
                      data-testid="clear-button"
                    >
                      <Icon name="close" color="generalError" />
                      {formatMessage({ defaultMessage: "Clear Sort" })}
                    </li>
                  </>
                )}

                {isCustom && canManageColumns && !isDefaultField && (
                  <>
                    <li
                      className="enabled required-switch-row"
                      data-tooltip-left={formatMessage({
                        defaultMessage:
                          "Required columns must be completed in order to finalize or lock a record.",
                      })}
                    >
                      {formatMessage({ defaultMessage: "Required Column" })}
                      <div className="is-required-switch">
                        <Switch
                          active
                          indeterminate={false}
                          checked={field.is_required}
                          onChange={() =>
                            mainStore.fields.toggleIsRequired(
                              tableID,
                              field.name,
                            )
                          }
                        />
                      </div>
                    </li>
                    {isInternalUserVisibilityEnabled && (
                      <li className="enabled required-switch-row">
                        {formatMessage({
                          defaultMessage: "Visible to internal users only",
                        })}
                        <div className="is-required-switch">
                          <Switch
                            active
                            indeterminate={false}
                            checked={field.is_visible_to_internal_users_only}
                            onChange={handleVisibilityToggleForInternalUsers}
                          />
                        </div>
                      </li>
                    )}
                    <hr />
                    <li
                      className="clear"
                      // @ts-expect-error TS(2345) FIXME: Argument of type '"delete"' is not assignable to p... Remove this comment to see the full error message
                      onClick={() => setPopUp("delete")}
                      data-testid="column-dropdown-delete-button"
                    >
                      <Icon name="trash" color="generalError" />
                      {formatMessage({ defaultMessage: "Delete Column" })}
                    </li>
                  </>
                )}
              </ul>
            </div>
          )}

          {popUp === "delete" && (
            <div
              className="table-dropdown title-select delete-column"
              data-testid="column-dropdown-delete-popup"
            >
              <h5>
                {formatMessage({ defaultMessage: "Delete Custom Column" })}
              </h5>
              <p>
                {formatMessage({
                  defaultMessage:
                    "Do you want to remove this column permanently?",
                })}
              </p>
              <p className="danger">
                {isCW
                  ? formatMessage({
                      defaultMessage:
                        "It will be removed from the current workspace",
                    })
                  : formatMessage({
                      defaultMessage:
                        "It will be removed from internal and collaborative workspaces.",
                    })}
              </p>
              <p className="danger">
                {formatMessage({
                  defaultMessage:
                    "All the content under this column will be removed completely.",
                })}
              </p>
              <p>
                {formatMessage({
                  defaultMessage: "Are you sure you want to proceed?",
                })}
              </p>
              <div className="buttons">
                <button
                  type="button"
                  className="primary"
                  data-testid="column-dropdown-delete-popup-confirm"
                  onClick={() => {
                    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                    onDelete(fieldName);
                    closePopUp();
                  }}
                >
                  {formatMessage({
                    defaultMessage: "Yes",
                  })}
                </button>
                <button
                  type="button"
                  onClick={() => setPopUp(null)}
                  data-testid="column-dropdown-delete-popup-reject"
                >
                  {formatMessage({
                    defaultMessage: "No",
                  })}
                </button>
              </div>
            </div>
          )}
        </Popup>
      </div>
      <span
        className={classNames("resize-block", {
          hidden: disableResize || isThemisID,
        })}
        onMouseDown={initResize}
      />
    </li>
  );
}

export default observer(ModuleTableColumn);
