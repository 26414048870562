import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import type { ResponseConfig } from "@/api/client";
import {
  type CreateThemisDocumentMutationRequest,
  type CreateThemisDocumentMutationResponse,
  type CreateThemisDocumentPathParams,
} from "@/api/gen";
import { createThemisDocument } from "@/api/gen/axios/documentsController";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import { getAuditTrailQueryKey } from "../../audits/use-audit-trail";
import { getRecordQueryKey } from "../../records/get-record-query-key";
import { getRecordsQueryKey } from "../../records/get-records-query-key";

interface UseCreateThemisDocumentParams {
  recordType: CreateThemisDocumentPathParams["record_type"];
  recordId: CreateThemisDocumentPathParams["record_id"];
}

export function useCreateThemisDocument<TError = Error, TContext = unknown>(
  { recordType, recordId }: UseCreateThemisDocumentParams,
  {
    onSettled,
    ...options
  }: Partial<
    UseMutationOptions<
      ResponseConfig<CreateThemisDocumentMutationResponse>["data"],
      TError,
      CreateThemisDocumentMutationRequest,
      TContext
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const workspaceId = useRouteWorkspaceId();

  return useMutation({
    mutationFn: (data) => createThemisDocument(recordType, recordId, data),
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getRecordQueryKey({
          recordType,
          recordId,
        }),
      });
      queryClient.invalidateQueries({
        queryKey: getRecordsQueryKey({
          recordType,
          workspaceId,
        }),
      });

      queryClient.invalidateQueries({
        queryKey: getAuditTrailQueryKey(recordType, recordId),
      });

      onSettled?.(...args);
    },
    ...options,
  });
}
