import { http } from "msw";

import { createImportFilesToCreateThemisRecordsMutationResponse } from "../createImportFilesToCreateThemisRecords";

export const importFilesToCreateThemisRecordsHandler = http.post(
  "*/companies/:company_id/:record_type/import_files",
  function handler(info) {
    return new Response(
      JSON.stringify(createImportFilesToCreateThemisRecordsMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
