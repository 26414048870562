import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { PiPlusBold } from "react-icons/pi";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import TableMenu from "@/components/table/shared/tableMenu/TableMenu";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";

import Loading from "../../../components/Loading";
import { useCustomColumns } from "../../../components/table/shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../../../components/table/shared/ModuleRecordVersion";
import ModuleTableColumn from "../../../components/table/shared/ModuleTableColumn";

interface Props {
  isCompleted?: boolean;
}

function QALinkedDocuments({ isCompleted }: Props) {
  const mainStore = useMainStore();
  const { formatMessage } = useIntl();

  // State
  const [isLoading, setIsLoading] = useState(true);
  const [moduleWorkspaceID, setModuleWorkspaceID] = useState(null);

  // Variables
  const { record_version_id } = useParams<{ record_version_id: string }>();
  const parentRecordVersionID = Number(record_version_id);
  const parentRecordID = mainStore.recordVersions.list.find(
    (recordVersion) => recordVersion.id === parentRecordVersionID,
  )?.record_id;
  const { activeWorkspace, moduleWorkspaceID: parentModuleWorkspaceID } =
    mainStore.context;
  const { recordVersions } = mainStore.linkedDocuments;
  const { visibleFields } = mainStore.fields;

  async function createNewDocument() {
    if (parentRecordID) {
      await mainStore.linkedDocuments.create({ parentRecordID });
      await mainStore.linkedDocuments.index({ parentRecordID });
    }
  }

  const {
    deleteColumn,
    renameColumn,
    // @ts-expect-error TS(2339) FIXME: Property 'setSortByDirection' does not exist on ty... Remove this comment to see the full error message
    setSortByDirection,
    // @ts-expect-error TS(2339) FIXME: Property 'setSortByFieldName' does not exist on ty... Remove this comment to see the full error message
    setSortByFieldName,
    // @ts-expect-error TS(2339) FIXME: Property 'sortByDirection' does not exist on type ... Remove this comment to see the full error message
    sortByDirection,
    // @ts-expect-error TS(2339) FIXME: Property 'sortByFieldName' does not exist on type ... Remove this comment to see the full error message
    sortByFieldName,
  } = useCustomColumns({});

  useEffect(() => {
    if (!activeWorkspace) {
      return;
    }

    if (parentRecordID) {
      mainStore.linkedDocuments.index({ parentRecordID }).then(() => {
        setIsLoading(false);
      });
    } else {
      // Need to load QA Tests first, then find parentRecordID
      mainStore.qa.index({ workspaceID: activeWorkspace?.id });
    }
  }, [activeWorkspace, mainStore, parentRecordID]);

  // Grab
  useEffect(() => {
    if (!parentModuleWorkspaceID) {
      return;
    }

    const rootModuleWorkspace = mainStore.moduleWorkspaces.list.find(
      (mw) => mw.id === parentModuleWorkspaceID,
    );
    if (!rootModuleWorkspace) {
      return;
    }

    const moduleWorkspace = rootModuleWorkspace.attached_module_workspaces.find(
      (mw) => mw.themis_module?.identifier === "linked_documents",
    );
    if (moduleWorkspace?.id) {
      setModuleWorkspaceID(moduleWorkspace?.id);
      // This one is required to route dynamic updates into LinkedDocumentsStore
      mainStore.linkedDocuments.setModuleWorkspaceID(moduleWorkspace?.id);
      mainStore.context.setSubModuleTableName("linked_documents");
    }
  }, [
    parentModuleWorkspaceID,
    mainStore.moduleWorkspaces.list,
    mainStore.linkedDocuments,
    mainStore.context,
  ]);

  // Rendering
  const renderFields = visibleFields.map((field) => (
    <ModuleTableColumn
      key={field.name}
      field={field}
      sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
      setSortByDirection={setSortByDirection}
      setSortByFieldName={setSortByFieldName}
      onDelete={deleteColumn}
      onRename={renameColumn}
    />
  ));

  const renderRows = recordVersions.map((recordVersion) => {
    const status = mainStore.avroSchemas.firstValueForField(
      "status",
      // @ts-expect-error TS(2339) FIXME: Property 'data' does not exist on type 'never'.
      recordVersion.data,
    );

    return (
      <ModuleRecordVersion
        // @ts-expect-error TS(2339) FIXME: Property 'table_name' does not exist on type 'neve... Remove this comment to see the full error message
        currentTableName={recordVersion.table_name}
        fields={visibleFields}
        isLockedRow={isCompleted || status === "published"}
        // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
        key={recordVersion.id}
        moduleIdentifier="linked_documents"
        // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
        moduleWorkspaceID={moduleWorkspaceID}
        persistOnCallback
        recordVersion={recordVersion}
        // @ts-expect-error TS(2339) FIXME: Property 'table_id' does not exist on type 'never'... Remove this comment to see the full error message
        tableID={recordVersion.table_id}
        // @ts-expect-error TS(2339) FIXME: Property 'table_name' does not exist on type 'neve... Remove this comment to see the full error message
        tableName={recordVersion.table_name}
      />
    );
  });

  if (isLoading) {
    return <Loading loadingLayout="table" showTableHeader={false} />;
  }

  return (
    <>
      <AddRecordHeader onAddRecord={createNewDocument} recordName="Document" />
      <div className="obi-table">
        <div
          className="table-list-wrap im-list-wrap obi-content"
          data-testid="linked-documents-table"
        >
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="title-first-block table-column-reorder">
                  {visibleFields.length > 0 && (
                    <TableMenu
                      isSubModule
                      onlyIncludeOptions={["manageColumns"]}
                    />
                  )}
                </div>
                <div className="list-title-table-wrap">
                  {renderFields}
                  <span className="stretch-cell" />
                </div>
              </div>
              <div className="list-title-table-block" />
            </ul>
          </div>
          {renderRows}
          {!isCompleted && (
            <div
              className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
              onClick={createNewDocument}
              data-testid="add-new-record-button"
            >
              <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
              <div className="tw-neutral-300 tw-text-sm">
                {formatMessage({
                  defaultMessage: "Add Document...",
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default observer(QALinkedDocuments);
