import { Box, useTheme } from "@mui/material";
import type { CircularProgressProps as MuiCircularProgressProps } from "@mui/material/CircularProgress";
import MuiCircularProgress from "@mui/material/CircularProgress";

export { circularProgressClasses } from "@mui/material/CircularProgress";
export interface CircularProgressProps extends MuiCircularProgressProps {
  disableBackground?: boolean;
}

function CircularProgressBackground(props: MuiCircularProgressProps) {
  return (
    <MuiCircularProgress
      {...props}
      variant="determinate"
      value={100}
      sx={{
        opacity: 0.2,
        position: "absolute",
        left: 0,
      }}
    />
  );
}

export function CircularProgress({
  disableBackground,
  size,
  ...rest
}: CircularProgressProps) {
  const theme = useTheme();
  const _size = size ?? theme.size.icon.medium;

  if (disableBackground) {
    return <MuiCircularProgress size={_size} {...rest} />;
  }

  return (
    <Box position="relative" width={_size} height={_size}>
      <CircularProgressBackground size={_size} {...rest} />
      <MuiCircularProgress size={_size} {...rest} />
    </Box>
  );
}
