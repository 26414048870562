import { http } from "msw";

import { createGetPublicFormLayoutQueryResponse } from "../createGetPublicFormLayout";

export const getPublicFormLayoutHandler = http.get(
  "*/module_workspaces/:module_workspace_id/public_form_layout",
  function handler(info) {
    return new Response(
      JSON.stringify(createGetPublicFormLayoutQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
