import { IconButton, useToast } from "@themis/ui";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiStarBold, PiStarFill } from "react-icons/pi";

import {
  useCreateFavoriteModuleWorkspace,
  useDeleteFavoriteModuleWorkspace,
} from "@/api/queries/favorites";
import { useMainStore } from "@/contexts/Store";

interface Props {
  passedModuleWorkspaceID?: number;
}

function FavoriteButton({ passedModuleWorkspaceID }: Props) {
  const mainStore = useMainStore();
  const { moduleWorkspaceID: contextModuleWorkpsaceID, workspaceID } =
    mainStore.context;
  const { list } = mainStore.moduleWorkspaces;
  const { isAdmin } = mainStore.context;
  const moduleWorkspaceID = contextModuleWorkpsaceID || passedModuleWorkspaceID;
  const moduleWorkspace = list.find((mw) => mw.id === moduleWorkspaceID);
  const isFavorited = moduleWorkspace?.is_favorited;

  const [isUpdating, setIsUpdating] = useState(false);

  const toast = useToast();

  const { mutateAsync: createFavoriteModule } =
    useCreateFavoriteModuleWorkspace(
      Number(workspaceID),
      () => {
        toast({
          content: "Module has been added to favorites!",
          variant: "success",
        });
      },
      () => {
        toast({
          content:
            "Something went wrong. Could not add the module to favorites.",
          variant: "error",
        });
      },
    );

  const { mutateAsync: deleteFavoriteModule } =
    useDeleteFavoriteModuleWorkspace(
      Number(workspaceID),
      () => {
        toast({
          content: "Module has been removed from favorites!",
          variant: "success",
        });
      },
      () => {
        toast({
          content:
            "Something went wrong. Could not remove the module from favorites.",
          variant: "error",
        });
      },
    );

  async function handleChange() {
    setIsUpdating(true);
    if (isFavorited && moduleWorkspace.favorite_module_workspace) {
      const id = Number(moduleWorkspace.favorite_module_workspace.id);
      await deleteFavoriteModule(id);
      mainStore.moduleWorkspaces.removeFavoriteModuleWorkspace(id);
    } else {
      const { data } = await createFavoriteModule(Number(moduleWorkspaceID));
      mainStore.moduleWorkspaces.addFavoriteModuleWorkspace(data);
    }

    const moduleItem = list.find(
      (module) => module.id === Number(moduleWorkspaceID),
    );
    if (moduleItem) {
      moduleItem.is_favorited = !moduleItem.is_favorited;
      mainStore.moduleWorkspaces.setList([...list]);
    }
    setIsUpdating(false);
  }

  if (!isAdmin) {
    return null;
  }

  return (
    <IconButton
      Icon={isFavorited ? PiStarFill : PiStarBold}
      color="transparent"
      className={`tw-ml-2 tw-h-8 tw-w-8 ${
        isFavorited ? "[&_svg]:tw-fill-yellow-4" : "[&_svg]:tw-fill-neutral-500"
      }`}
      onClick={() => handleChange()}
      disabled={isUpdating}
    />
  );
}

export default observer(FavoriteButton);
