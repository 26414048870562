import { LinkButton } from "@themis/ui";
import type { CustomCellRendererProps } from "ag-grid-react";
import { useIntl } from "react-intl";
import { generatePath } from "react-router-dom";

import type { MonitoringGroupAssetChild } from "@/api";

import { websiteMonitoringRoutes } from "../../../routes";

export function ReviewLinkCell({
  data,
}: CustomCellRendererProps<MonitoringGroupAssetChild>) {
  const { formatMessage } = useIntl();

  const monitoringGroupAssetChildId = data?.id;

  if (!monitoringGroupAssetChildId || !data?.child_grouped_results_count) {
    return null;
  }

  return (
    <div className="tw-flex tw-w-[90px] tw-items-center tw-justify-center">
      <LinkButton
        to={generatePath(
          `${websiteMonitoringRoutes.base}${websiteMonitoringRoutes.monitoringGroups}${websiteMonitoringRoutes.monitoringGroupAssetChildResults}`,
          {
            monitoringGroupAssetChildId,
          },
        )}
        size="lg"
        color="tertiary"
      >
        {formatMessage({ defaultMessage: "Review" })}
      </LinkButton>
    </div>
  );
}
