import { listItemIconClasses } from "@mui/material/ListItemIcon";
import { listItemTextClasses } from "@mui/material/ListItemText";
import { type ThemeOptions } from "@mui/material/styles";

export const menuItemTheme: ThemeOptions["components"] = {
  MuiMenuItem: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1, 2),
        textTransform: "capitalize",
        transition: theme.transitions.create(["background-color", "color"]),
        "&:hover": {
          backgroundColor: theme.palette.info.main,
          color: theme.palette.primary.main,

          [`.${listItemTextClasses.primary}`]: {
            color: theme.palette.primary.main,
          },
        },

        "&:focus-visible": {
          backgroundColor: theme.palette.info.dark,
          color: theme.palette.primary.main,

          [`.${listItemTextClasses.primary}`]: {
            color: theme.palette.primary.main,
          },
        },

        [`.${listItemTextClasses.primary}`]: {
          transition: theme.transitions.create(["color"]),
        },

        [`.${listItemIconClasses.root}`]: {
          color: "inherit",
          minWidth: theme.size.icon.medium,
          fontSize: theme.size.icon.small,
        },
      }),
    },
  },
};
