import type { CustomCellEditorProps } from "ag-grid-react";
import React from "react";
import { PiArrowsOutSimpleBold } from "react-icons/pi";
import { useHistory } from "react-router-dom";

import { filterCellProps } from "../../../lib/utils";
import { Button } from "../../Button/Button";
import { LinkButton } from "../../Button/LinkButton";
import type { TextInputProps } from "../../Form/TextInput";
import { TextInput } from "../../Form/TextInput";

type Props = TextInputProps &
  CustomCellEditorProps & {
    to?: string;
    onClick?: () => void;
  };

function IdentifierCellEditor({
  value,
  onValueChange,
  to,
  onClick,
  ...props
}: Props) {
  const filteredProps = filterCellProps({ value, onValueChange, ...props });
  const history = useHistory();

  return (
    <div
      id="identifier-cell"
      className="tw-group/parent tw-flex tw-h-full tw-w-full tw-items-center tw-px-2 tw-font-sans tw-font-medium tw-text-neutral-500"
    >
      <div className="tw-group/cell tw-flex tw-h-full tw-w-full tw-items-center tw-justify-between">
        <TextInput
          id="identifier-cell-text-input"
          className="tw-mr-1.5 tw-h-5 tw-rounded-[4px] tw-border-0 tw-px-0.5 focus-visible:!tw-border focus-visible:!tw-border-neutral-100 focus-visible:tw-ring-0 group-hover/cell:!tw-bg-white"
          onChange={(e) => onValueChange(e.target.value)}
          placeholder="Add Text"
          value={value}
          autoFocus
          {...filteredProps}
        />
        {to ? (
          <LinkButton
            id="identifier-cell-button-edit"
            className="tw-mx-1 tw-flex tw-h-7 tw-rounded-none tw-p-0 tw-px-2 tw-font-medium"
            color="transparent"
            to={to}
            RightIcon={PiArrowsOutSimpleBold}
            onKeyDown={(event) => {
              if (event.key === " ") {
                history.push(to);
              }
            }}
            onClick={() => {
              history.push(to);
            }}
          >
            Open
          </LinkButton>
        ) : (
          <Button
            id="identifier-cell-button-edit"
            className="tw-mx-1 tw-flex tw-h-7 tw-rounded-none tw-p-0 tw-px-2 tw-font-medium"
            color="transparent"
            RightIcon={PiArrowsOutSimpleBold}
            onClick={onClick}
            onKeyDown={(event) => {
              if (event.key === " ") {
                onClick && onClick();
              }
            }}
            onMouseDown={(event) => {
              event.stopPropagation();
              event.preventDefault();
            }}
          >
            Open
          </Button>
        )}
      </div>
    </div>
  );
}

export default IdentifierCellEditor;
