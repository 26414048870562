import { Stack } from "@themis/ui-library/components/layout/stack/stack";

import type { RecordTypePath } from "@/api";

import { useRecordLinks } from "../../../api/queries/record-links/use-record-links";
import { RecordLinkTable } from "../../../components/record-link-table/record-link-table";

export function RecordLinkSection({
  addTopMargin,
  recordType,
  recordId,
  recordSelectDisabled = false,
}: {
  addTopMargin?: boolean;
  recordType: RecordTypePath;
  recordId: number;
  recordSelectDisabled?: boolean;
}) {
  const { data } = useRecordLinks(recordType, recordId);
  const recordLinksTo = data?.record_links_to ?? [];
  const recordLinksFrom = data?.record_links_from ?? [];

  return (
    <>
      <Stack spacing={2} marginTop={addTopMargin ? 2 : 0}>
        <RecordLinkTable
          recordSelectDisabled={recordSelectDisabled}
          recordId={recordId}
          recordType={recordType}
          linkType="outward_record"
          rows={recordLinksFrom}
        />
      </Stack>

      <Stack spacing={2} marginTop={addTopMargin ? 2 : 0}>
        <RecordLinkTable
          recordSelectDisabled={recordSelectDisabled}
          recordId={recordId}
          recordType={recordType}
          linkType="record"
          rows={recordLinksTo}
        />
      </Stack>
    </>
  );
}
