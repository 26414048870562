import { DownloadSimple } from "@phosphor-icons/react";
import { useToast } from "@themis/ui";
import { Divider } from "@themis/ui-library/components/data-display/divider/divider";
import { List } from "@themis/ui-library/components/data-display/list/list";
import { ListItemButton } from "@themis/ui-library/components/data-display/list/list-item-button/list-item-button";
import { ListItemText } from "@themis/ui-library/components/data-display/list/list-item-text/list-item-text";
import { ListItem } from "@themis/ui-library/components/data-display/list/list-item/list-item";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { Button } from "@themis/ui-library/components/inputs/button/button";
import { Box } from "@themis/ui-library/components/layout/box/box";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { Popover } from "@themis/ui-library/components/utils/popover/popover";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import { observer } from "mobx-react";
import type { ReactNode } from "react";
import { useRef, useState } from "react";
import { useIntl } from "react-intl";

import type { CompanyModuleRecordsReportPathParamsModuleIdentifier } from "@/api";
import { useCompanyModuleRecordsReport } from "@/api/queries/exports";
import { useMainStore } from "@/contexts/Store";

import { DownloadSpreadsheetsMenu } from "./download-spreadsheets-menu/download-spreadsheets-menu";
import { SectionListItem } from "./section-list-item/section-list-item";

const TOP_LEVEL_SECTION_ID = 0;

export interface ExportWorkspacePopupProps {
  renderDownloadFooter?: (props: { onClose: () => void }) => ReactNode;
  onOpenChange?: (isOpen: boolean) => void;
  successMessage?: string;
  moduleIdentifier: CompanyModuleRecordsReportPathParamsModuleIdentifier;
}

function ExportWorkspacePopup({
  successMessage = "Reports are preparing to be exported",
  moduleIdentifier,
  renderDownloadFooter,
}: ExportWorkspacePopupProps) {
  const { formatMessage } = useIntl();
  const toast = useToast();

  const {
    context: { isCompanyAdmin, isCompanySuperAdmin, companyID, tableID },
    tables,
    sectionTags,
  } = useMainStore();

  const ref = useRef<HTMLButtonElement>(null);
  const exportMenu = useOpen();
  const popover = useOpen();

  const allSectionTagIds = [
    TOP_LEVEL_SECTION_ID,
    ...sectionTags.orderedList.map(({ id }) => id),
  ];

  const [checkedSectionIds, setCheckedSectionIds] = useState(allSectionTagIds);

  const isAllChecked = checkedSectionIds.length === allSectionTagIds.length;

  const handleToggle = (value: number) => () => {
    setCheckedSectionIds((prevChecked) =>
      prevChecked.includes(value)
        ? prevChecked.filter((id) => value !== id)
        : [...prevChecked, value],
    );
  };

  const { mutateAsync: companyModuleRecordsReport } =
    useCompanyModuleRecordsReport({
      companyId: Number(companyID),
      moduleIdentifier,
      onSuccess: () => {
        toast({
          content: successMessage,
          variant: "success",
        });
      },
      onError: () => {
        toast({
          content: "Failed to export document!",
          variant: "error",
        });
      },
    });

  const handleAllChecked = () => {
    setCheckedSectionIds(isAllChecked ? [] : allSectionTagIds);
  };

  const handleExport = () => {
    tables.export(
      Number(tableID),
      false,
      undefined,
      undefined,
      undefined,
      checkedSectionIds,
    );
    popover.close();
  };

  const handleClose = () => {
    popover.close();
    exportMenu.close();
  };

  return (
    <>
      <Button
        ref={ref}
        startIcon={<DownloadSimple />}
        onClick={popover.open}
        color="info"
      >
        {formatMessage({ defaultMessage: "Export" })}
      </Button>
      {exportMenu.isOpen || !renderDownloadFooter ? (
        <Popover
          onClose={handleClose}
          open={popover.isOpen}
          anchorEl={ref.current}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <Box maxWidth={260}>
            <Stack paddingX={2} paddingY={1}>
              <Typography variant="h5" color="primary" fontWeight="bold">
                {formatMessage({ defaultMessage: "Export to Spreadsheet" })}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {formatMessage({
                  defaultMessage:
                    "Please select the options below before exporting.",
                })}
              </Typography>
            </Stack>
            <List>
              {(isCompanyAdmin || isCompanySuperAdmin) && (
                <ListItem disablePadding>
                  <ListItemButton onClick={() => companyModuleRecordsReport()}>
                    <ListItemText>
                      {formatMessage({
                        defaultMessage: "All Workspaces",
                      })}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              )}
            </List>
            <Divider />
            <List>
              <Typography
                paddingX={2}
                paddingY={1}
                color="primary"
                fontWeight="bold"
              >
                {formatMessage({ defaultMessage: "This Workspace" })}
              </Typography>
              <SectionListItem
                isAllSectionsItem
                checked={isAllChecked}
                title={formatMessage({ defaultMessage: "All Sections" })}
                onToggle={handleAllChecked}
              />

              <Box maxHeight={220} overflow="auto">
                <SectionListItem
                  checked={checkedSectionIds.includes(TOP_LEVEL_SECTION_ID)}
                  title={formatMessage({ defaultMessage: "Top Level Section" })}
                  onToggle={handleToggle(TOP_LEVEL_SECTION_ID)}
                />
                {sectionTags.orderedList.map(({ title, id }) => (
                  <SectionListItem
                    key={id}
                    checked={checkedSectionIds.includes(id)}
                    title={title}
                    onToggle={handleToggle(id)}
                  />
                ))}
              </Box>
            </List>
            <Box paddingX={2} paddingBottom={2}>
              <Button onClick={handleExport}>
                {formatMessage({ defaultMessage: "Export" })}
              </Button>
            </Box>
          </Box>
        </Popover>
      ) : (
        <DownloadSpreadsheetsMenu
          open={popover.isOpen}
          anchorEl={ref.current}
          onClose={handleClose}
          onOpenExportMenu={exportMenu.open}
          renderDownloadFooter={renderDownloadFooter}
        />
      )}
    </>
  );
}

export default observer(ExportWorkspacePopup);
