import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";

import AddNewSectionButton from "@/components/shared/AddNewSectionButton";
import { useMainStore } from "@/contexts/Store";
import AddRecordHeader from "@/features/misc/AddRecordHeader";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import QA from "../../../components/table/qa/QA";
import CreateFirstCTA from "../../../components/table/shared/CreateFirstCTA";
import NewSectionTag from "../../../components/table/shared/NewSectionTag";
import TestDetails from "./TestDetails";
import TestsSchedule from "./TestsSchedule";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addNewSection?: (...args: any[]) => any;
  canManageSections?: boolean;
  hideSchedule?: boolean;
  isInternal?: boolean;
  moduleWorkspaceID?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  recordVersions?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sectionTags?: any[];
  showAddNewSection?: boolean;
  showCTA?: boolean;
  tableWidth?: string;
  onCancelAddNewSection?: () => void;
}

function QAActiveTests({
  hideSchedule,
  tableWidth,
  recordVersions,
  showAddNewSection,
  moduleWorkspaceID,
  addNewSection,
  showCTA,
  isInternal,
  sectionTags,
  canManageSections,
  onCancelAddNewSection,
}: Props) {
  const mainStore = useMainStore();
  const history = useHistory();
  const showTestSchedule = !hideSchedule;
  const workspaceId = useRouteWorkspaceId();

  const addNewTest = async () => {
    if (isInternal && !sectionTags) {
      return;
    }

    const currentSectionTag = sectionTags ? sectionTags[0].id : null;
    const recordVersion = await mainStore.qa.create(
      workspaceId,
      currentSectionTag,
    );

    history.push(
      `/workspaces/${workspaceId}/modules/qa-tests/${recordVersion.id}/new-record`,
    );
  };

  return (
    <div className="qa-active-container" data-testid="qa-active-tests">
      <Switch>
        <Route exact path="/workspaces/:workspace_id/modules/qa-tests">
          <>
            <div
              style={{ width: tableWidth }}
              className="qa-active-container__wrap"
            >
              <AddRecordHeader
                onAddRecord={addNewTest}
                recordName="Test"
                canAddSection={isInternal}
                onAddSection={addNewSection}
              />
              <QA
                additionalContent={
                  <>
                    {showAddNewSection && isInternal && (
                      <NewSectionTag
                        moduleWorkspaceID={moduleWorkspaceID}
                        onCancel={onCancelAddNewSection}
                      />
                    )}

                    {isInternal && canManageSections && (
                      <div className="tw-sticky tw-left-[25px] tw-ml-[25px]">
                        <AddNewSectionButton onClick={addNewSection} />
                      </div>
                    )}

                    {showCTA && isInternal && (
                      <CreateFirstCTA subject="QA Test" />
                    )}
                  </>
                }
              />
            </div>
            {showTestSchedule && (
              <TestsSchedule
                recordVersions={recordVersions}
                sectionTags={sectionTags}
              />
            )}
          </>
        </Route>
        <Route path="/workspaces/:workspace_id/modules/qa-tests/:record_version_id">
          <TestDetails isInternal={isInternal} />
        </Route>
        <Route path="/workspaces/:workspace_id/modules/qa-tests-development/:record_version_id">
          <TestDetails isInternal={isInternal} />
        </Route>
      </Switch>
    </div>
  );
}

export default QAActiveTests;
