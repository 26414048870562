import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import type { ReactElement } from "react";
import { useIntl } from "react-intl";

import UserAvatar from "@/components/UserAvatar";

import type { CommentWithUser } from "../../comment-with-user";
import { CommentActions } from "../comment-actions/comment-actions";

interface ReplyHeaderProps {
  comment: CommentWithUser;
  recordId: number;
  recordTypePath: RecordTypePath;
  recordName: string;
  onEditClick: () => void;
}

export function ReplyHeader({
  comment,
  recordId,
  recordTypePath,
  recordName,
  onEditClick,
}: ReplyHeaderProps): ReactElement {
  const { formatDate } = useIntl();
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <UserAvatar user={comment.user} size="sm" />
        <Stack spacing={0}>
          <Typography variant="subtitle2">{comment.user?.full_name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {formatDate(comment.created_at, {
              dateStyle: "medium",
              timeStyle: "short",
            })}
          </Typography>
        </Stack>
      </Stack>

      <CommentActions
        comment={comment}
        recordId={recordId}
        recordName={recordName}
        recordTypePath={recordTypePath}
        onEditClick={onEditClick}
      />
    </Stack>
  );
}
