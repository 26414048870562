import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import React from "react";
import type { ReactElement, ReactNode } from "react";

interface FooterProps {
  children: ReactNode;
}

export function Footer({ children }: FooterProps): ReactElement {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={1}
      justifyContent="space-between"
      px={2}
      py={1}
    >
      {children}
    </Stack>
  );
}
