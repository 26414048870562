import { Button, Checkbox, TextArea, useToast } from "@themis/ui";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { PiXBold } from "react-icons/pi";

import { useCreateFollowupQuestion } from "@/api/queries/apiQuestion";
import type { Assignment } from "@/features/risk-assessment/types";

import { useFollowUpSidebar } from "../providers/followup-sidebar-provider/follow-up-sidebar-context";
import { SearchInput } from "../utils/SearchInput";

interface FollowUpPageProps {
  questionID: number;
  questionText?: string;
  questionOrder?: string;
  questionReviewers?: Assignment[];
}

export function FollowUpPage({
  questionID,
  questionText,
  questionOrder,
  questionReviewers,
}: FollowUpPageProps) {
  const { closeSidebar } = useFollowUpSidebar();

  const messageRef = useRef<HTMLTextAreaElement | null>(null);
  const resultDetailContainer = useRef<HTMLDivElement>(null);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [isIncludeAttachment, setIsIncludeAttachment] = useState(true);
  const [isQuestionRequired, setIsQuestionRequired] = useState(false);

  const [selectedAssigneeIds, setSelectedAssigneeIds] = useState<number[]>([]);
  const [selectedAssigneeEmails, setSelectedAssigneeEmails] = useState<
    string[]
  >([]);
  const [selectedReviewerIds, setSelectedReviewerIds] = useState<number[]>([]);
  const [selectedReviewerEmails, setSelectedReviewerEmails] = useState<
    string[]
  >([]);
  const [questionInputText, setQuestionInputText] = useState<string>("");

  const toast = useToast();
  const { mutateAsync: createFollowup } = useCreateFollowupQuestion({
    questionId: questionID,
    onSuccess: () => {
      toast({
        content: "1 Follow up question has been created!",
        variant: "success",
      });
      closeSidebar();
    },
    onError: () => {
      toast({
        content: "Something went wrong. Could not create a Follow up question.",
        variant: "error",
      });
    },
  });

  const onSend = async () => {
    const requestBody = {
      question_id: questionID,
      followup_question_text: questionInputText,
      required_attachment: isIncludeAttachment,
      is_optional: !isQuestionRequired,
      assignee_contact_emails: selectedAssigneeEmails || [],
      assignee_user_ids: selectedAssigneeIds || [],
      reviewer_contact_emails: selectedReviewerEmails || [],
      reviewer_user_ids: selectedReviewerIds || [],
    };

    await createFollowup(requestBody);
  };

  const handleSelectedAssigneeIdsChange = (ids: number[]) => {
    setSelectedAssigneeIds(ids);
  };
  const handleSelectedAssigneeEmailsChange = (ids: string[]) => {
    setSelectedAssigneeEmails(ids);
  };

  const handleSelectedReviewerIdsChange = (ids: number[]) => {
    setSelectedReviewerIds(ids);
  };

  const handleSelectedReviewerEmailsChange = (ids: string[]) => {
    setSelectedReviewerEmails(ids);
  };

  const handleTextInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setQuestionInputText(event.target.value);
  };

  useEffect(() => {
    if (
      (selectedAssigneeIds.length > 0 || selectedAssigneeEmails.length > 0) &&
      (selectedReviewerIds.length > 0 || selectedReviewerEmails.length > 0) &&
      questionInputText.trim().length > 0
    ) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  }, [
    selectedAssigneeIds,
    selectedAssigneeEmails,
    selectedReviewerIds,
    selectedReviewerEmails,
    questionInputText,
  ]);

  return (
    <div
      ref={resultDetailContainer}
      className={classNames("slide-animation tw-z-30 tw-h-full tw-w-[500px]", {
        "tw-flex tw-flex-col": true,
      })}
    >
      <div className="tw-flex tw-items-center tw-justify-between">
        <div className="tw-px-5 tw-py-3 tw-text-base tw-font-semibold tw-text-neutral-500">
          Follow Up Question
        </div>
        <PiXBold
          onClick={closeSidebar}
          className="tw-mr-2 tw-h-5 tw-w-5 tw-cursor-pointer tw-text-neutral-500"
        />
      </div>

      <div className="tw-flex tw-flex-col">
        <div className="tw-flex tw-items-center tw-border-0 tw-border-b tw-border-t tw-border-solid tw-border-generals-underline tw-py-2">
          <div className="tw-font-meidum tw-cursor-default tw-px-5 tw-text-sm tw-text-primaryDim-300">
            {`${questionOrder}. ${questionText}`}
          </div>
        </div>
      </div>

      <div className="email-dropdown-wrap">
        <div className="email-dropdown-block">
          <SearchInput
            title="Assignee"
            onIdsSelectionChange={handleSelectedAssigneeIdsChange}
            onEmailsSelectionChange={handleSelectedAssigneeEmailsChange}
          />
          <SearchInput
            title="Reviewer"
            onIdsSelectionChange={handleSelectedReviewerIdsChange}
            onEmailsSelectionChange={handleSelectedReviewerEmailsChange}
            questionReviewers={questionReviewers}
          />
        </div>

        <div className="tw-font-meidum tw-line-clamp-1 tw-flex tw-cursor-default tw-flex-col tw-p-5 tw-text-sm tw-text-neutral-300">
          Question
          <TextArea
            value={questionInputText}
            ref={messageRef}
            onChange={handleTextInputChange}
          />
        </div>

        <div className="tw-font-meidum tw-line-clamp-1 tw-flex tw-cursor-default tw-items-center tw-gap-2 tw-border-0 tw-p-5 tw-pb-1 tw-text-sm tw-text-neutral-300">
          <Checkbox
            size="md"
            color="primary"
            checked={isIncludeAttachment}
            onCheckedChange={() => setIsIncludeAttachment(!isIncludeAttachment)}
          />
          <p className="tw-truncate tw-text-sm tw-text-neutral-500">
            Include Attachment field
          </p>
        </div>
        <div className="tw-font-meidum tw-line-clamp-1 tw-flex tw-cursor-default tw-items-center tw-gap-2 tw-border-0 tw-border-b tw-border-solid tw-border-generals-underline tw-p-5 tw-text-sm tw-text-neutral-300">
          <Checkbox
            size="md"
            color="primary"
            checked={isQuestionRequired}
            onCheckedChange={() => setIsQuestionRequired(!isQuestionRequired)}
          />
          <p className="tw-truncate tw-text-sm tw-text-neutral-500">
            Question required
          </p>
        </div>
        <div className="tw-font-meidum tw-line-clamp-1 tw-cursor-default tw-p-5 tw-text-sm tw-text-neutral-300">
          <Button size="lg" disabled={buttonDisabled} onClick={onSend}>
            Save & Send
          </Button>
        </div>
      </div>
    </div>
  );
}
