import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  CreateAttestationMutationRequest,
  CreateAttestationMutationResponse,
  CreateAttestationPathParams,
} from "../../models/CreateAttestation";

/**
 * @description Create Attestation
 * @summary Create Attestation
 * @link /companies/:company_id/attestations
 */
export async function createAttestation(
  companyId: CreateAttestationPathParams["company_id"],
  data: CreateAttestationMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<CreateAttestationMutationResponse>["data"]> {
  const res = await client<
    CreateAttestationMutationResponse,
    CreateAttestationMutationRequest
  >({
    method: "post",
    url: `/companies/${companyId}/attestations`,
    data,
    ...options,
  });
  return res.data;
}
