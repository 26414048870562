import type { CheckedState } from "@themis/ui";
import { Button, Checkbox } from "@themis/ui";
import {
  type DataGridState,
  FilterOperand,
} from "@themis/ui-library/components/data-grid/state/data-grid-state";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import { PiPlus } from "react-icons/pi";
import { useIntl } from "react-intl";

import type { RecordTypePath } from "@/api/gen/models";
import { useSections } from "@/api/utils/sections/use-sections";
import { useCompanyId } from "@/hooks/use-company-id";

import { SectionCreationDialog } from "./section-creation-dialog/section-creation-dialog";
import { SectionsManagementDialog } from "./sections-management-dialog/sections-management-dialog";

interface SideBarSectionsProps {
  dataGridState: DataGridState;
  recordType: RecordTypePath;
  updateDataGridState: (params: Partial<DataGridState>) => void;
}

export function SideBarSections({
  recordType,
  dataGridState,
  updateDataGridState,
}: SideBarSectionsProps) {
  const { formatMessage } = useIntl();
  const { companyId } = useCompanyId();
  const { sections, sectionsFieldId } = useSections(companyId, recordType);

  const sectionsManagementDialog = useOpen();
  const sectionCreationDialog = useOpen();

  const handleCheckedChange = (sectionKey: string, checked: CheckedState) => {
    const newFilterValue = checked
      ? [...(dataGridState.filters?.sections?.value ?? []), sectionKey]
      : (dataGridState.filters?.sections?.value ?? []).filter(
          (key) => key !== sectionKey,
        );

    const { sections: _, ...otherFilters } = dataGridState.filters ?? {};
    const newState: Partial<DataGridState> = {
      filters: {
        ...otherFilters,
        ...(newFilterValue.length > 0
          ? {
              sections: {
                ...dataGridState.filters?.sections,
                operand: FilterOperand.ANY,
                value: newFilterValue,
              },
            }
          : {}),
      },
    };

    updateDataGridState(newState);
  };

  if (!sections) {
    return null;
  }

  return (
    <>
      <section className="tw-border-0 tw-border-b tw-border-solid tw-border-primary-50 tw-py-1">
        <header className="tw-flex tw-items-center tw-justify-between tw-py-1 tw-pl-3 tw-pr-1">
          <h4 className="tw-font-normal tw-text-neutral-500">
            {formatMessage({
              defaultMessage: "Sections",
            })}
          </h4>

          {sections && (
            <Button
              size="md"
              color="transparentPrimary"
              onClick={sectionsManagementDialog.open}
            >
              {formatMessage({
                defaultMessage: "Manage",
              })}
            </Button>
          )}
        </header>

        {sections.map((section) => (
          <label
            className="tw-flex tw-cursor-pointer tw-flex-row tw-items-center tw-gap-2 tw-px-2 tw-py-1.5"
            key={section.id}
          >
            <Checkbox
              size="md"
              color="primary"
              checked={Boolean(
                dataGridState?.filters?.sections?.value?.includes(section.key),
              )}
              onCheckedChange={(checked) =>
                handleCheckedChange(section.key, checked)
              }
            />
            <span className="tw-select-none tw-font-sans tw-text-sm tw-font-medium tw-text-primaryDim-300">
              {section.value}
            </span>
          </label>
        ))}

        <Button
          className="tw-w-full tw-justify-start active:tw-bg-none"
          LeftIcon={PiPlus}
          size="md"
          color="transparentPrimary"
          onClick={sectionCreationDialog.open}
        >
          {formatMessage({
            defaultMessage: "Add new",
          })}
        </Button>
      </section>

      <SectionCreationDialog
        fieldId={sectionsFieldId}
        open={sectionCreationDialog.isOpen}
        onClose={sectionCreationDialog.close}
      />

      {sections.length > 0 && (
        <SectionsManagementDialog
          fieldId={sectionsFieldId}
          open={sectionsManagementDialog.isOpen}
          onClose={sectionsManagementDialog.close}
          recordType={recordType}
          sections={sections}
        />
      )}
    </>
  );
}
