import React, { useCallback } from "react";

import { useMainStore } from "@/contexts/Store";

import { TableMenuItem } from ".";
import SectionReorderPopup from "../../SectionReorderPopup";
import TableColumnReorderPopup from "../../TableColumnReorderPopup";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClick?: (...args: any[]) => any;
  isSubModule?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onlyIncludeOptions?: any[];
  showReorderPopup?: boolean;
  showSectionReorderPopup?: boolean;
  allowHidingRequiredFields?: boolean;
  preventHidingColumnNames?: string[];
}

export const TableMenuContent = ({
  showReorderPopup,
  handleClick,
  showSectionReorderPopup,
  isSubModule,
  onlyIncludeOptions,
  allowHidingRequiredFields,
  preventHidingColumnNames,
}: Props) => {
  const mainStore = useMainStore();
  const { menuActions } = mainStore.dynamicTable;
  const actions = onlyIncludeOptions
    ? Object.keys(menuActions).filter((action) =>
        onlyIncludeOptions.includes(action),
      )
    : Object.keys(menuActions);
  const { themisModuleIdentifier } = mainStore.context;

  const getDisplayItem = useCallback(
    // @ts-expect-error TS(7006) FIXME: Parameter 'currentlyDisplayedItem' implicitly has ... Remove this comment to see the full error message
    (currentlyDisplayedItem) => {
      if (currentlyDisplayedItem === menuActions.selectIndividual) {
        switch (themisModuleIdentifier) {
          case "vendor_due_diligence":
            return "Select Individual Vendors";
          default:
            return currentlyDisplayedItem;
        }
      }
      return currentlyDisplayedItem;
    },
    [themisModuleIdentifier, menuActions],
  );

  if (showReorderPopup) {
    return (
      <TableColumnReorderPopup
        allowHidingRequiredFields={allowHidingRequiredFields}
        preventHidingColumnNames={preventHidingColumnNames}
        renderContentOnly
        isSubModule={isSubModule}
      />
    );
  } else if (showSectionReorderPopup) {
    return <SectionReorderPopup renderContentOnly isSubModule={isSubModule} />;
  }
  return (
    <div
      data-testid="table-menu-items-container"
      className="table-menu-items-container"
    >
      {actions.map((item, index) => (
        <TableMenuItem
          key={index}
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          item={menuActions[item]}
          onItemClick={handleClick}
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          displayedItem={getDisplayItem(menuActions[item])}
        />
      ))}
    </div>
  );
};
