import type { MediaFile } from "@themis/ui-library/components/data-display/media/types/media-file";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import type { ReactNode } from "react";
import { useMemo, useState } from "react";

import type { InteractiveMediaContextType } from "../../interactive-media.context";
import { InteractiveMediaContext } from "../../interactive-media.context";

interface RootProps {
  children: ReactNode;
  file: MediaFile | undefined;
}

export function Root({ children, file }: RootProps) {
  const [zoomLevel, setZoomLevel] = useState(1);

  const contextValue: InteractiveMediaContextType = useMemo(() => {
    return {
      file,
      zoom: {
        level: zoomLevel,
        setLevel: setZoomLevel,
      },
    };
  }, [file, zoomLevel]);

  return (
    <InteractiveMediaContext.Provider value={contextValue}>
      <Stack
        flexShrink={1}
        flex="1 1 100%"
        width="100%"
        maxHeight="100%"
        overflow="hidden"
        spacing={0}
      >
        {children}
      </Stack>
    </InteractiveMediaContext.Provider>
  );
}
