import mixpanel from "mixpanel-browser";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import Button from "@/components/form/Button";
import EmailInput from "@/components/form/EmailInput";
import { useMainStore } from "@/contexts/Store";
import { LegalPopup } from "@/features/legal";
import { formatErrors } from "@/utils/errors";

import Header from "../components/Header";
import PasswordFields from "../components/PasswordFields";
import { routes } from "./routes";

function SignUp() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [emailError, setEmailError] = useState<string | null>(null);
  const [emailIsPresent, setEmailIsPresent] = useState(false);
  const [checkmark, setCheckmark] = useState(false);
  const [isFormEnabled, setIsFormEnabled] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [password, setPassword] = useState<string>("");

  // Variables
  const formattedErrors = formatErrors(mainStore.users.userErrors);

  // Effects
  useEffect(() => {
    const shouldEnableForm = emailIsPresent && checkmark && passwordValid;

    setIsFormEnabled(shouldEnableForm);
  }, [emailIsPresent, checkmark, passwordValid]);

  // Refs
  const emailRef = React.createRef<HTMLInputElement>();

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();

    mainStore.users.setUserErrors({});

    // Get field name and the value
    const { name, value } = event.target;

    // Validate email as user types
    if (name === "email") {
      validateEmail(value);
    }
  }

  function handleCheckmark(event: React.ChangeEvent<HTMLInputElement>) {
    setCheckmark(event.target.checked);

    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    validateEmail(emailRef.current.value);
  }

  function onBlur(event: React.ChangeEvent<HTMLInputElement>) {
    const { name } = event.target;

    if (name === "email") {
      // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
      validateEmail(emailRef.current.value);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  function validateEmail(value) {
    const validEmailRegex = RegExp(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    );

    setEmailIsPresent(value.length > 0);

    if (validEmailRegex.test(value)) {
      setEmailError(null);
    } else if (value === "") {
      setEmailError("Should be present");
    } else {
      setEmailError("Should be valid email");
    }
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    mainStore.users.create(emailRef.current.value, password);

    mixpanel.track("Sign Up");
  }

  return (
    <div className="check-in">
      <Header />
      <div className="check-in-wrap">
        <div className="check-in-block">
          <div className="sign-up">
            <form onSubmit={handleSubmit}>
              <div className="authentication-link">
                <NavLink exact to={routes.signUp}>
                  Sign Up
                </NavLink>
                <NavLink exact to={routes.signIn}>
                  Login
                </NavLink>
              </div>
              {formattedErrors && (
                <p className="error-input">{formattedErrors}</p>
              )}
              <EmailInput
                placeholder="Company Email Address"
                name="email"
                ref={emailRef}
                onChange={handleChange}
                onBlur={onBlur}
                autoComplete="email"
              />
              {emailError ? <p className="error-input">{emailError}</p> : ""}
              <p className="sign-up-text">
                This company email address will be the main admin user, please
                be sure to double check the accuracy of this email.
              </p>

              <PasswordFields
                onValidityChange={setPasswordValid}
                onPasswordChange={setPassword}
              />
              <div className="text-checkbox">
                <div className="sign-up-checkbox">
                  <input
                    type="checkbox"
                    id="consentCheckmark"
                    name="consentCheckmark"
                    checked={checkmark}
                    onChange={handleCheckmark}
                  />
                  <label htmlFor="consentCheckmark" />
                </div>
                <div>
                  <p>
                    I acknowledge that I have read, understood and agree to
                    <br /> the following terms from themis:
                  </p>
                  <LegalPopup />
                </div>
              </div>
              <Button title="Continue" enabled={isFormEnabled} />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(SignUp);
