import { z } from "zod";

import type { FieldConfigurationWithLayout } from "@/api/utils/fields/field-configuration-with-layout";
import { optionalDateStringSchema } from "@/components/dynamic-field/use-dynamic-validation-schema/get-date-schema/get-date-schema";
import { useDynamicValidationSchema } from "@/components/dynamic-field/use-dynamic-validation-schema/use-dynamic-validation-schema";

export const useAttestationDetailValidationSchema = (
  customFields: FieldConfigurationWithLayout[] = [],
) => {
  const customFieldsSchema = useDynamicValidationSchema(customFields);

  return z.object({
    name: z.string(),
    ownerId: z.coerce.number(),
    message: z.string().optional(),
    description: z.string().optional(),
    dueDate: optionalDateStringSchema,
    customFields: customFieldsSchema,
  });
};

export type ValidationSchema = z.infer<
  ReturnType<typeof useAttestationDetailValidationSchema>
>;
