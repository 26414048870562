import { routes } from "@features/web-monitoring/routes";
import { useMonitoringAsset } from "@themis/api/queries/web-monitoring/assets/use-monitoring-asset";
import { generatePath } from "react-router";

import type { Hook } from "../use-record-path";

export const useMonitoringAssetsPath: Hook = ({ recordId, workspaceId }) => {
  const { data, isPending } = useMonitoringAsset({ assetId: recordId });

  if (data?.group_id != null && workspaceId != null) {
    const path = generatePath(routes.webMonitoring.findings.overview, {
      group_id: data.group_id,
      asset_id: data.id,
      workspace_id: workspaceId,
    });

    return { path, loading: false };
  }

  return { loading: isPending };
};
