import type { CustomCellRendererProps } from "ag-grid-react";
import { cx } from "cva";
import { debounce } from "lodash";
import React, { useRef } from "react";
import { PiArrowsOutSimpleBold } from "react-icons/pi";
import { useHistory } from "react-router-dom";

import { Button } from "../../Button/Button";
import { LinkButton } from "../../Button/LinkButton";
import { TextInput } from "../../Form/TextInput";
import { Popover, PopoverAnchor, PopoverContent } from "../../Popover/Popover";

export type IdentifierCellRendererProps = CustomCellRendererProps & {
  value: string;
  to?: string;
  onClick?: () => void;
  editable?: boolean;
  isEditing?: boolean;
};

function IdentifierCellRenderer({
  value,
  to,
  onClick,
  editable = false,
  ...props
}: IdentifierCellRendererProps) {
  const isEditable = editable || props.colDef?.editable;
  const textRef = useRef<HTMLDivElement>(null);
  const [isHovering, setIsHovering] = React.useState(false);
  const history = useHistory();

  const isTextOverflowing = () => {
    const textElement = textRef.current;
    return textElement
      ? textElement.scrollWidth > textElement.clientWidth
      : false;
  };

  const debouncedHandleMouseEnter = debounce(() => {
    setIsHovering(true);
  }, 500);

  const handleOnMouseLeave = () => {
    debouncedHandleMouseEnter.cancel();
    setIsHovering(false);
  };

  return (
    <div
      ref={textRef}
      onMouseEnter={debouncedHandleMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      id="identifier-cell"
      className="tw-group/parent tw-relative tw-flex tw-h-full tw-w-full tw-items-center tw-font-sans tw-font-medium tw-text-neutral-500"
      onFocus={() => {
        props.node.setData({
          ...props.node.data,
          isEditing: true,
        });
        props.api.startEditingCell({
          rowIndex: props.node.rowIndex || 0,
          colKey: props.column?.getColId() || "",
        });
      }}
    >
      <div className="tw-group/cell tw-hidden tw-h-full tw-w-full tw-items-center tw-justify-between tw-px-2 group-hover/parent:tw-flex">
        {isEditable ? (
          <TextInput
            id="identifier-cell-text-input"
            className="tw-does-nothing tw-mr-1.5 tw-h-5 tw-rounded-[4px] tw-border-0 tw-px-0.5 focus-visible:!tw-border focus-visible:!tw-border-neutral-100 focus-visible:tw-ring-0 group-hover/cell:!tw-bg-white"
            placeholder="Add Text"
            value={value}
            onClick={() => {
              props.node.setData({
                ...props.node.data,
                isEditing: true,
              });
              props.api.startEditingCell({
                rowIndex: props.node.rowIndex || 0,
                colKey: props.column?.getColId() || "",
              });
            }}
          />
        ) : (
          <>
            <span className="tw-overflow-hidden tw-text-ellipsis tw-text-neutral-300">
              {value}
            </span>
            <Popover open={isHovering && isTextOverflowing()}>
              <PopoverAnchor virtualRef={textRef} />
              <PopoverContent
                align="center"
                side="bottom"
                onEscapeKeyDown={() => setIsHovering(false)}
              >
                <div className="tw-max-w-[250px] tw-p-2 tw-text-sm tw-text-neutral-500">
                  {value}
                </div>
              </PopoverContent>
            </Popover>
          </>
        )}
        {to && (
          <LinkButton
            id="identifier-cell-link-button"
            className="tw-absolute tw-right-0 tw-mx-1 tw-hidden tw-h-7 tw-rounded-none tw-p-0 tw-px-2 tw-font-medium group-hover/cell:tw-flex"
            color="transparent"
            RightIcon={PiArrowsOutSimpleBold}
            to={to}
            onKeyDown={(e) => {
              if (e.key === " " || e.key === "Enter") {
                e.preventDefault();
                history.push(to);
              }
            }}
          >
            Open
          </LinkButton>
        )}
        {onClick && (
          <Button
            id="identifier-cell-button"
            className="tw-mx-1 tw-hidden tw-h-7 tw-rounded-none tw-p-0 tw-px-2 tw-font-medium group-hover/cell:tw-flex"
            color="transparent"
            RightIcon={PiArrowsOutSimpleBold}
            onMouseDown={onClick}
            onKeyDown={(e) => (e.key === "Enter" && onClick ? onClick() : null)}
          >
            Open
          </Button>
        )}
      </div>
      <span
        className={cx(
          "tw-visible tw-overflow-hidden tw-text-ellipsis !tw-px-2 group-hover/parent:tw-hidden ",
          !isEditable && "tw-text-neutral-300",
        )}
        data-testid="identifier-cell-text"
      >
        {value}
      </span>
    </div>
  );
}

export default IdentifierCellRenderer;
