import React from "react";
import { PiPlusBold } from "react-icons/pi";

import { IconButton } from "../Button/IconButton";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../DropdownMenu/DropdownMenu";

interface option {
  value: string;
  icon: React.ReactNode;
}

interface AddColumnButtonProps {
  options: option[];
  onClick: (option: string) => void;
}

function AddColumnButton({ options, onClick, ...args }: AddColumnButtonProps) {
  return (
    <DropdownMenu {...args}>
      <DropdownMenuTrigger asChild>
        <IconButton
          Icon={PiPlusBold}
          color="transparent"
          className="tw-rounded-none"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {options.map((option) => (
          <DropdownMenuItem
            key={option.value}
            onClick={() => onClick(option.value)}
            className="tw-flex tw-w-[208px] tw-items-center tw-justify-start tw-gap-1 tw-px-4 tw-py-[10px]"
          >
            {option.icon}
            <span className="tw-text-sm tw-font-medium">{option.value}</span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

export default AddColumnButton;
