import { faker } from "@faker-js/faker";

import type {
  ListThemisRecordLayouts200,
  ListThemisRecordLayouts403,
  ListThemisRecordLayoutsPathParams,
  ListThemisRecordLayoutsQueryParams,
  ListThemisRecordLayoutsQueryResponse,
} from "../models/ListThemisRecordLayouts";
import { createLayout } from "./createLayout";
import { createRecordTypePath } from "./createRecordTypePath";

export function createListThemisRecordLayoutsPathParams(): NonNullable<ListThemisRecordLayoutsPathParams> {
  return {
    company_id: faker.number.int(),
    record_type: createRecordTypePath(),
  };
}

export function createListThemisRecordLayoutsQueryParams(): NonNullable<ListThemisRecordLayoutsQueryParams> {
  return { workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createListThemisRecordLayouts200(): NonNullable<ListThemisRecordLayouts200> {
  return { data: faker.helpers.arrayElements([createLayout()]) as any };
}

/**
 * @description Forbidden
 */
export function createListThemisRecordLayouts403(): NonNullable<ListThemisRecordLayouts403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createListThemisRecordLayoutsQueryResponse(): NonNullable<ListThemisRecordLayoutsQueryResponse> {
  return { data: faker.helpers.arrayElements([createLayout()]) as any };
}
