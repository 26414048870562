import { http } from "msw";

import { createTransitionRecordMutationResponse } from "../createTransitionRecord";

export const transitionRecordHandler = http.post(
  "*/:record_type/:record_id/transition",
  function handler(info) {
    return new Response(
      JSON.stringify(createTransitionRecordMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
