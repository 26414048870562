import type { ColDef } from "ag-grid-community";

import { TextCell } from "../cells/text-cell/text-cell";
import { ColumnHeader } from "../column-header/column-header";

export const defaultColumnDefParams: Partial<ColDef> = {
  flex: 1,
  cellRenderer: TextCell,
  headerComponent: ColumnHeader,
  resizable: true,
};
