import { ListItem } from "@themis/ui-library/components/data-display/list/list-item/list-item";
import { FormControlLabel } from "@themis/ui-library/components/form/form-control-label/form-control-label";
import { FormGroup } from "@themis/ui-library/components/form/form-group/form-group";
import type { AutocompleteRenderInputParams } from "@themis/ui-library/components/inputs/autocomplete/autocomplete";
import {
  Autocomplete,
  createFilterOptions,
} from "@themis/ui-library/components/inputs/autocomplete/autocomplete";
import { Checkbox } from "@themis/ui-library/components/inputs/checkbox/checkbox";
import { TextField } from "@themis/ui-library/components/inputs/text-field/text-field";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import type { HTMLAttributes, SyntheticEvent } from "react";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

interface FiltersOption {
  title: string;
  value: string;
}

interface TextFieldFilterSelectProps {
  displayName: string | undefined;
  filters: string[];
  selectedFilters: string[];
  onSelectFilter: (newValue: string) => void;
}

const filter = createFilterOptions<FiltersOption>();

export function TextFieldFilterSelect({
  displayName,
  filters,
  selectedFilters,
  onSelectFilter,
}: TextFieldFilterSelectProps): React.ReactNode {
  const { formatMessage } = useIntl();

  const filtersOptions: FiltersOption[] = useMemo(() => {
    return filters.map((option) => ({
      value: option,
      title: option,
    }));
  }, [filters]);

  const [inputValue, setInputValue] = useState<string>("");
  const [value, setValue] = useState<string | null>(null);

  const handleSelectFilter = (
    _event: SyntheticEvent<Element, Event>,
    newValue: string | FiltersOption | null,
  ) => {
    if (newValue) {
      onSelectFilter(typeof newValue === "string" ? newValue : newValue.value);
      setInputValue("");
      setValue(null);
    }
  };

  const filterOptions: typeof filter = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue: currentInputValue } = params;

    const isExisting = options.some((option) => inputValue === option.value);
    if (currentInputValue !== "" && !isExisting) {
      filtered.push({
        title: formatMessage(
          { defaultMessage: `Add "{inputValue}"` },
          { inputValue: currentInputValue },
        ),
        value: currentInputValue,
      });
    }

    return filtered;
  };

  const renderOption = (
    props: HTMLAttributes<HTMLLIElement> & {
      key?: string | number;
    },
    option: FiltersOption,
  ) => {
    const { key, ...optionProps } = props;
    const isSelectedFilter = selectedFilters.includes(option.value);
    const isExistingOption = option.value === option.title;

    return (
      <ListItem key={key} {...optionProps}>
        {isExistingOption ? (
          <>
            <Checkbox size="small" defaultChecked={isSelectedFilter} />
            {option.value}
          </>
        ) : (
          option.title
        )}
      </ListItem>
    );
  };

  const getOptionLabel = (option: string | FiltersOption) => {
    if (typeof option === "string") {
      return option;
    }
    if (option.value) {
      return option.value;
    }
    return option.title;
  };

  const handleInputChange = (
    _event: SyntheticEvent<Element, Event>,
    newInputValue: string,
  ) => setInputValue(newInputValue);

  const handleBlur = (event: SyntheticEvent<Element, Event>) =>
    handleSelectFilter(event, inputValue);

  return (
    <Stack>
      <Autocomplete
        options={filtersOptions}
        filterOptions={filterOptions}
        value={value}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        onChange={handleSelectFilter}
        onBlur={handleBlur}
        freeSolo
        getOptionLabel={getOptionLabel}
        renderOption={renderOption}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            placeholder={formatMessage(
              { defaultMessage: "Filter {displayName}" },
              { displayName: displayName || "" },
            )}
          />
        )}
        sx={{
          px: 1,
        }}
      />

      {filtersOptions.length > 0 && (
        <Stack component={FormGroup}>
          {filtersOptions.map((option) => (
            <FormControlLabel
              key={option.value}
              slotProps={{
                typography: {
                  color: "textPrimary",
                },
              }}
              labelPlacement="end"
              control={
                <Checkbox
                  size="small"
                  checked={selectedFilters.includes(option.value)}
                  onChange={() => {
                    onSelectFilter(option.value);
                  }}
                />
              }
              label={option.value}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
}
