import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import type { DeleteFieldMutationResponse, RecordTypePath } from "../../gen";
import { deleteField } from "../../gen/axios/fieldsController";
import { getFieldsQueryKey } from "./use-fields";

interface DeleteFieldMutationParams {
  companyId: number | undefined;
  recordType: RecordTypePath;
}

type DeleteFieldMutationOptions = Partial<
  UseMutationOptions<
    DeleteFieldMutationResponse,
    unknown,
    DeleteFieldMutationResponse
  >
>;

export function useDeleteField(
  { companyId, recordType }: DeleteFieldMutationParams,
  options: DeleteFieldMutationOptions = {},
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (fieldId: number) => {
      if (companyId == null) {
        throw new Error("companyId is required");
      }

      return deleteField(companyId, recordType, fieldId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: getFieldsQueryKey(),
      });
    },
    ...options,
  });
}
