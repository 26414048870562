import { Slot } from "@radix-ui/react-slot";
import * as React from "react";
import { PiCaretRightBold } from "react-icons/pi";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";

import { cn } from "../../lib/utils";

const Breadcrumb = React.forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<"nav"> & {
    separator?: React.ReactNode;
  }
>(({ ...props }, ref) => <nav ref={ref} aria-label="breadcrumb" {...props} />);
Breadcrumb.displayName = "Breadcrumb";

const BreadcrumbList = React.forwardRef<
  HTMLOListElement,
  React.ComponentPropsWithoutRef<"ol">
>(({ className, ...props }, ref) => (
  <ol
    ref={ref}
    className={cn(
      "tw-flex tw-flex-wrap tw-items-center tw-gap-2 tw-font-sans tw-text-base tw-font-semibold tw-text-neutral-300",
      className,
    )}
    {...props}
  />
));
BreadcrumbList.displayName = "BreadcrumbList";

const BreadcrumbItem = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("tw-inline-flex tw-items-center tw-gap-2", className)}
    {...props}
  />
));
BreadcrumbItem.displayName = "BreadcrumbItem";

const BreadcrumbLink = React.forwardRef<
  HTMLAnchorElement,
  LinkProps & {
    asChild?: boolean;
  }
>(({ asChild, className, ...props }, ref) => {
  if (asChild) {
    return (
      <Slot
        ref={ref}
        className={cn(
          "tw-transition-colors hover:tw-text-neutral-500",
          className,
        )}
        {...props}
      />
    );
  }

  return (
    <Link
      ref={ref}
      className={cn(
        "tw-transition-colors hover:tw-text-neutral-500",
        className,
      )}
      {...props}
    />
  );
});
BreadcrumbLink.displayName = "BreadcrumbLink";

const BreadcrumbPage = React.forwardRef<
  HTMLSpanElement,
  React.ComponentPropsWithoutRef<"span">
>(({ className, ...props }, ref) => (
  <span
    ref={ref}
    role="link"
    aria-disabled="true"
    aria-current="page"
    className={cn("tw-whitespace-nowrap tw-text-neutral-500", className)}
    {...props}
  />
));
BreadcrumbPage.displayName = "BreadcrumbPage";

const BreadcrumbSeparator = ({
  children,
  className,
  ...props
}: React.ComponentProps<"div">) => (
  <div
    role="presentation"
    aria-hidden="true"
    className={cn("tw-flex [&>svg]:tw-size-4", className)}
    {...props}
  >
    {children ?? <PiCaretRightBold />}
  </div>
);
BreadcrumbSeparator.displayName = "BreadcrumbSeparator";

const Breadcrumbs = ({ breadcrumbItems }: BreadcrumbsProps) => (
  <Breadcrumb>
    <BreadcrumbList>
      {breadcrumbItems.map(({ label, to }, index) => (
        <BreadcrumbItem key={index}>
          {to ? (
            <BreadcrumbLink to={to}>{label}</BreadcrumbLink>
          ) : (
            <BreadcrumbPage>{label}</BreadcrumbPage>
          )}
          {index < breadcrumbItems.length - 1 && <BreadcrumbSeparator />}
        </BreadcrumbItem>
      ))}
    </BreadcrumbList>
  </Breadcrumb>
);

interface BreadcrumbsProps {
  breadcrumbItems: { label?: string; to?: LinkProps["to"] }[];
}

export { Breadcrumbs, type BreadcrumbsProps };
