export const routes = {
  policies: {
    archived: "/workspaces/:workspace_id/modules/policy/archived",
    historicalVersions:
      "/workspaces/:workspace_id/modules/policy/:record_version_id/historical-versions",
    moduleAuditTrail:
      "/workspaces/:workspace_id/modules/:moduleIdentifier/audit_trail",
    recordAuditTrail:
      "/workspaces/:workspace_id/modules/:moduleIdentifier/:record_version_id/audit_trail",
    finalized: "/workspaces/:workspace_id/modules/policy/finalized",
    attestation: "/workspaces/:workspace_id/modules/policy/attestation",
    library: "/workspaces/:workspace_id/modules/policy/policies_library",
    creativeView:
      "/workspaces/:workspace_id/modules/policy/attachment_view/:record_version_id/:file_id",
    drafts: "/workspaces/:workspace_id/modules/policy/drafts",
    mamageApprovalsSteps:
      "/workspaces/:workspace_id/modules/policy/manage-approvals-steps",
    detail: "/workspaces/:workspace_id/modules/policy/:record_version_id",
    buildChecklist:
      "/workspaces/:workspace_id/modules/:module_identifier/custom-checklists/:record_id/create/:field_name",
    template:
      "/workspaces/:workspace_id/modules/policy/document_library_preview/:file_id/",
    editBuildChecklist:
      "/workspaces/:workspace_id/modules/:module_identifier/custom-checklists/:checklist_id/update",
    editBuildChecklistTemplate:
      "/workspaces/:workspace_id/modules/:module_identifier/custom-checklists-templates/:checklist_id/update",
    addAttestation:
      "/workspaces/:workspace_id/modules/policy/:record_version_id/attestation/new",
  },
};
