import { IconButton } from "@themis/ui";
import { matchesProperty } from "lodash";
import { observer } from "mobx-react";
import { generatePath, Link, useParams } from "react-router-dom";

import type { EventNotificationRule } from "@/api/legacy/event-notifications/types";
import { useUsers } from "@/api/queries/users/use-users";
import Edit from "@/assets/svgs/edit";
import { Flex, Icon, MiniTag } from "@/components/Elements";
import type { IconName } from "@/components/Elements/Icon/icon";
import { UsersCircle } from "@/components/users-circle/users-circle";
import { useMainStore } from "@/contexts/Store";
import {
  EVENT_TAB,
  MODULE_NOTIFICATIONS_EDIT_PATH,
} from "@/features/notifications/pages/constants";
import type { ModuleNotificationParams } from "@/features/notifications/types/types";
import type { ValueLabelOption } from "@/stores/types/field-types";

import { NotificationText } from "../text/notification-text";
import {
  getMultipleRecipientsText,
  getNumberOfRecipients,
  getSingleRecipientInfo,
} from "./helpers";

// Define proper types with discriminated unions
interface ColumnRecipient {
  type: "column";
  iconName: string;
  label: string;
}

interface DepartmentRecipient {
  type: "department";
  iconName: string;
  label: string;
}

interface UserRecipient {
  type: "user";
  initials: string;
  icon_color_index: number;
  full_name: string;
}

type RecipientInfo =
  | ColumnRecipient
  | DepartmentRecipient
  | UserRecipient
  | null;

function EventNotificationRow(props: {
  eventNotification: EventNotificationRule;
  columns: ValueLabelOption[];
  events: ValueLabelOption[];
}) {
  const mainStore = useMainStore();
  const params = useParams<ModuleNotificationParams>();

  const triggerEvents = props.eventNotification.data.events;
  const { recipients } = props.eventNotification.data;
  const numberOfRecipients = getNumberOfRecipients(recipients);
  const multipleRecipients = numberOfRecipients > 1;

  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const { data: users = [], isLoading: usersIsLoading } = useUsers(
    Number(workspace_id),
  );

  // Convert the original recipient info to our new type
  const getRecipientInfo = (): RecipientInfo => {
    if (multipleRecipients || usersIsLoading) {
      return null;
    }

    const originalRecipientInfo = getSingleRecipientInfo({
      recipients,
      users,
      departments: mainStore.departments.departments,
      columns: props.columns,
    });

    if (!originalRecipientInfo) {
      return null;
    }

    if (
      "iconName" in originalRecipientInfo &&
      "label" in originalRecipientInfo
    ) {
      return {
        type:
          originalRecipientInfo.iconName === "department"
            ? "department"
            : "column",
        iconName: originalRecipientInfo.iconName,
        label: originalRecipientInfo.label,
      };
    }

    if (
      "initials" in originalRecipientInfo &&
      "full_name" in originalRecipientInfo
    ) {
      return {
        type: "user",
        initials: originalRecipientInfo.initials,
        icon_color_index: originalRecipientInfo.icon_color_index,
        full_name: originalRecipientInfo.full_name,
      };
    }

    return null;
  };

  const singleRecipientInfo = getRecipientInfo();

  const triggerEventsText =
    triggerEvents.length > 1
      ? `${triggerEvents.length} events happened`
      : props.events.find(matchesProperty("value", triggerEvents[0]))?.label ||
        `Event ${triggerEvents[0]}`;

  const getRecipientLabel = (recipientInfo: RecipientInfo): string => {
    if (!recipientInfo) {
      return "Unknown recipient";
    }
    if (recipientInfo.type === "user") {
      return recipientInfo.full_name;
    }
    return recipientInfo.label;
  };

  const recipientLabel = getRecipientLabel(singleRecipientInfo);

  return (
    <Flex
      alignCenter
      justifySpaceBetween
      className="notifications-container__single"
    >
      <Flex columnGap={6}>
        <NotificationText label="If" />
        <NotificationText label={triggerEventsText} accent />
        <NotificationText label=", send email to" />
        {multipleRecipients ? (
          <NotificationText
            label={getMultipleRecipientsText(recipients)}
            accent
          />
        ) : (
          <Flex columnGap={8} alignCenter>
            {singleRecipientInfo?.type === "user" &&
              singleRecipientInfo.initials && (
                <UsersCircle
                  initials={singleRecipientInfo.initials}
                  iconColorIndex={singleRecipientInfo.icon_color_index}
                />
              )}
            {(singleRecipientInfo?.type === "column" ||
              singleRecipientInfo?.type === "department") && (
              <Icon name={singleRecipientInfo.iconName as IconName} />
            )}
            <NotificationText label={recipientLabel} small />
          </Flex>
        )}
      </Flex>
      <Flex alignCenter columnGap={8}>
        <MiniTag
          label={props.eventNotification.enabled ? "Enabled" : "Disabled"}
          theme={props.eventNotification.enabled ? "green" : "gray"}
        />
        <Link
          className="tw-flex"
          to={{
            pathname: generatePath(MODULE_NOTIFICATIONS_EDIT_PATH, {
              ...params,
              tab: EVENT_TAB,
              notificationId: props.eventNotification.id,
            }),
            state: { fromAllTab: !params.tab },
          }}
        >
          <IconButton
            // eslint-disable-next-line react/no-unstable-nested-components
            Icon={() => <Edit width="16px" />}
            color="transparent"
            size="sm"
          />
        </Link>
      </Flex>
    </Flex>
  );
}

export default observer(EventNotificationRow);
