import type { Task } from "@/api";
import type { FilterFieldData } from "@/components/FilterSelect/utils/get-filters-field-data";

import { TASK_STATUSES } from "./status";

export const myTasksFilterData: FilterFieldData<Task> = {
  status: {
    displayName: "Status",
    type: "string",
    options: TASK_STATUSES,
  },
  assignee_id: {
    displayName: "Assigned to",
    type: "string",
  },
  collaborator_ids: {
    displayName: "Collaborators",
    type: "string",
  },
  created_by_id: {
    displayName: "Created by",
    type: "string",
  },
  archived_at: {
    displayName: "Archived At",
    type: "string",
  },
  workspace_id: {
    displayName: "Workspace",
    type: "string",
  },
  parent_id: {
    displayName: "Parent Task", // used for Project also
    type: "string",
  },
};
