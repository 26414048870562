import { type ThemeOptions } from "@mui/material";

import { colors } from "./colors";

export const palette = {
  ...colors,
  action: {
    disabled: "#8080A3",
    disabledBackground: "#EBEBF5",
  },
  contrastThreshold: 10,
  text: {
    primary: "#353549",
    secondary: "#8080A3",
    disabled: "#BCBCCF",
  },
  primary: {
    lightest: "#F0F0FE",
    light: "#C6C8FF",
    main: "#6F73F7",
    dark: "#3337CC",
    contrastText: "#FFF",
  },
  secondary: {
    lightest: "#E7F8FF",
    light: "#95D5F0",
    main: "#27AAE1",
    dark: "#125B9E",
    contrastText: "#FFF",
  },
  info: {
    lightest: "#F4F4FA",
    light: "#F4F4FA",
    main: "#e3e4fd",
    dark: "#c6c8ff",
    contrastText: "#353549",
  },
  neutral: {
    lightest: "#E4E4EB",
    light: "#BCBCCF",
    main: "#353549",
    dark: "#1E1E29",
    contrastText: "#FFF",
  },
  warning: {
    lightest: "#FBF1DC",
    light: "#FEE5B4",
    main: "#FDB932",
    dark: "#FF9900",
    contrastText: "#FFF",
  },
  error: {
    lightest: "#FDECEF",
    light: "#FBD9DF",
    main: "#EB2E4E",
    dark: "#9C001A",
    contrastText: "#FFF",
  },
  success: {
    lightest: "#EDFDDD",
    light: "#99D959",
    main: "#66AA22",
    dark: "#40730C",
    contrastText: "#FFF",
  },
  background: {
    default: "#F6F6F9",
    paper: "#FFFFFF",
  },
  grey: {
    50: "#FFFFFF",
    100: "#F6F6F9",
    200: "#E4E4EB",
    300: "#BCBCCF",
    400: "#8080A3",
    500: "#4D4D6A",
    600: "#353549",
    700: "#1E1E29",
    800: "#070810",
    900: "#000",
    A100: "#F4F4FA",
    A200: "#EBEBF5",
    A400: "#D8D8EC",
    A700: "#AFAFD9",
  },
} satisfies ThemeOptions["palette"];
