import type { ComponentType } from "react";
import type { Control, FieldValues, Path } from "react-hook-form";

import type {
  FieldConfiguration,
  FieldConfigurationReferenceType,
} from "@/api";
import { AttachmentFormField } from "@/components/form-fields/attachment-form-field/attachment-form-field";
import { UserSelectFormField } from "@/components/form-fields/user-select-form-field/user-select-form-field";

interface BaseReferenceFieldComponentProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  label: string;
  name: Path<TFieldValues>;
  required: boolean;
}

type BaseReferenceFieldComponent<
  TFieldValues extends FieldValues = FieldValues,
> = ComponentType<BaseReferenceFieldComponentProps<TFieldValues>>;

const referenceFields = {
  ThemisDocument: AttachmentFormField,
  User: UserSelectFormField,
  Attachment: () => null,
  ThemisRecord: () => null,
  Account: () => null,
  Contact: () => null,
  Contract: () => null,
  Department: () => null,
  Issue: () => null,
  Policy: () => null,
  Questionnaire: () => null,
  Workspace: () => null,
  RecordLink: () => null,
  RecordVersion: () => null,
  Review: () => null,
  Task: () => null,
  VendorChecklist: () => null,
  MonitoringAsset: () => null,
  MonitoringFinding: () => null,
  RecordReference: () => null,
} satisfies Record<
  FieldConfigurationReferenceType,
  BaseReferenceFieldComponent
>;

interface DynamicReferenceFieldProps<T extends FieldValues>
  extends BaseReferenceFieldComponentProps<T> {
  referenceType?: FieldConfiguration["reference_type"];
  multiselect?: boolean;
  disabled?: boolean;
}

export function DynamicReferenceField<T extends FieldValues>({
  control,
  label,
  name,
  referenceType,
  required,
  multiselect,
  disabled,
}: DynamicReferenceFieldProps<T>) {
  if (!referenceType) {
    return null;
  }

  const ReferenceFieldComponent = referenceFields[referenceType];

  return (
    <ReferenceFieldComponent
      control={control}
      label={label}
      name={name}
      required={required}
      multiselect={multiselect}
      disabled={disabled}
    />
  );
}
