import React, { useMemo } from "react";

import QA from "../../../components/table/qa/QA";
import { useMainStore } from "../../../contexts/Store";

function QACompleted() {
  const mainStore = useMainStore();
  const { list: recordVersions } = mainStore.recordVersions;

  const completedRecordVersions = useMemo(
    () =>
      recordVersions.filter((recordVersion) => {
        const [statusValue] = mainStore.avroSchemas.valueForField(
          "status",
          recordVersion.data,
        );

        return statusValue === "completed";
      }),
    [recordVersions],
  );

  const showEmptyMessage = useMemo(
    () => completedRecordVersions.length === 0,
    [completedRecordVersions],
  );

  return (
    <div data-testid="qa-completed" className="qa-completed">
      {showEmptyMessage ? (
        <div className="qa-empty-message">
          <h3 className="qa-empty-message-title">
            Review your Completed tests
          </h3>
          <div className="qa-empty-message-description">
            Completed tests are stored here. They can be shared with partners
            and reviewed by employees.
          </div>
        </div>
      ) : (
        <QA />
      )}
    </div>
  );
}

export default QACompleted;
