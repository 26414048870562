import type { AxiosError, AxiosResponse } from "axios";
import { action, makeObservable, observable } from "mobx";

import type { FavoriteModuleWorkspace } from "@/api/gen/models/FavoriteModuleWorkspace";
import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type {
  ModuleIdentifier,
  TopLevelModuleIdentifier,
} from "../types/module-workspaces-types";
import type { ModuleWorkspace } from "../types/workspace-types";

interface ModuleWorkspaceResponse {
  data: {
    module_workspaces: ModuleWorkspace[];
  };
}

export default class ModuleWorkspacesStore {
  mainStore: MainStore;

  list: ModuleWorkspace[] = [];
  favoriteModuleWorkspaces: FavoriteModuleWorkspace[] = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      list: observable,
      setList: action,
      updateNameInList: action,
      setFavoriteModuleWorkspaces: action,
    });

    this.mainStore = mainStore;
  }

  async index(workspaceID: string | number) {
    try {
      const response = await this.fetchModuleWorkspaces(workspaceID);
      // We use this as the other ways to fetch it is not cheap for us.
      const favorites = response.data.module_workspaces
        .map((module_workspace) => module_workspace.favorite_module_workspace)
        .filter((favorite_mw) => !!favorite_mw)
        .sort(
          (a, b) =>
            new Date(a?.created_at || "").getTime() -
            new Date(b?.created_at || "").getTime(),
        );
      this.setList(response.data.module_workspaces);
      this.setFavoriteModuleWorkspaces(favorites as FavoriteModuleWorkspace[]);
      response.data.module_workspaces.forEach((mw) =>
        this.mainStore.permissions.setModuleName(
          mw.themis_module.identifier,
          mw.name,
        ),
      );
    } catch (error) {
      window.console.log(
        `"ModuleWorkspaces#index for Workspace" error ${error}`,
      );
    }
  }

  async updateName(moduleIdentifier: ModuleIdentifier, name: string) {
    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/module_workspaces/${moduleIdentifier}`,
        headers: this.mainStore.getHeaders(),
        data: {
          module_workspace: { name },
        },
      });
      this.updateNameInList(moduleIdentifier, name);
      this.mainStore.permissions.setModuleName(moduleIdentifier, name);
    } catch (error) {
      window.console.log(
        `"ModuleWorkspaces#updateName for Workspace" error ${error}`,
      );
    }
  }

  importRecordsFromWS(
    moduleWorkspaceID: number,
    sourceWorkspaceID: number,
    targetSectionID: number | null,
  ) {
    return legacyApi({
      method: "POST",
      url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/import_records`,
      headers: this.mainStore.getHeaders(),
      data: {
        source_workspace_id: sourceWorkspaceID,
        target_section_id: targetSectionID,
      },
    });
  }

  async fetchModuleWorkspaces(
    workspaceID: string | number,
  ): Promise<ModuleWorkspaceResponse> {
    return legacyApi({
      method: "GET",
      url: `${API_URL}/workspaces/${workspaceID}/module_workspaces`,
      headers: this.mainStore.getHeaders(),
    });
  }

  async removeModule(moduleIdentifier: string) {
    const [moduleWorkspace] = this.list.filter(
      (e) => e.themis_module.identifier === moduleIdentifier,
    );

    try {
      if (moduleWorkspace) {
        moduleWorkspace.added = false;
      }

      const result: AxiosResponse & AxiosError = await legacyApi({
        method: "POST",
        url: `${API_URL}/workspaces/remove_module`,
        headers: this.mainStore.getHeaders(),
        data: {
          themis_module: moduleIdentifier,
        },
      });

      if (result.isAxiosError) {
        return;
      }
    } catch (error) {
      window.console.log(`"ModuleWorkspaces#remove_module" error ${error}`);
      if (moduleWorkspace) {
        moduleWorkspace.added = true;
      }
    }
  }

  async addModule(moduleIdentifier: string) {
    const moduleWorkspace = this.list.find(
      (mws) => mws.themis_module.identifier === moduleIdentifier,
    );
    if (!moduleWorkspace) {
      return;
    }

    try {
      moduleWorkspace.added = true;

      await legacyApi({
        method: "POST",
        url: `${API_URL}/workspaces/add_module`,
        headers: this.mainStore.getHeaders(),
        data: {
          themis_module: moduleIdentifier,
        },
      });
    } catch (error) {
      window.console.log(`"ModuleWorkspaces#add_module" error ${error}`);
      moduleWorkspace.added = false;
    }
  }

  // GET /api/react/module_workspaces/:module_workspace_id/activity_events/export
  async exportActivityEvents(moduleWorkspaceID: number) {
    try {
      this.mainStore.toast.setText("Processing table download...");

      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/activity_events/export`,
        headers: this.mainStore.getHeaders(),
      });

      return result.data;
    } catch (error) {
      this.mainStore.toast.setInfoText(
        "An error occurred during export. Please try again or contact Themis support if the problem persists.",
      );
      window.console.log(
        `"ModuleWorkspaces#exportActivityEvents" error ${error}`,
      );
    }
  }

  // Actions
  setList(value: Array<ModuleWorkspace> | null) {
    if (value) {
      this.list = value;
    } else {
      this.list = [];
    }
  }

  setFavoriteModuleWorkspaces(value: FavoriteModuleWorkspace[]) {
    this.favoriteModuleWorkspaces = value;
  }

  addFavoriteModuleWorkspace(value: FavoriteModuleWorkspace) {
    this.setList(
      this.list.map((mw) => {
        if (mw.id === value.module_workspace_id) {
          mw.favorite_module_workspace = value;
        }

        return mw;
      }),
    );
    this.setFavoriteModuleWorkspaces([...this.favoriteModuleWorkspaces, value]);
  }

  removeFavoriteModuleWorkspace(id: number) {
    this.setFavoriteModuleWorkspaces(
      this.favoriteModuleWorkspaces.filter((fw) => fw.id !== id),
    );
  }

  updateNameInList(moduleIdentifier: ModuleIdentifier, name: string) {
    const index = this.list.findIndex(
      (e) => e.themis_module.identifier === moduleIdentifier,
    );
    if (index === -1) {
      return;
    }
    this.list[index].name = name;
  }

  // Helpers

  get moduleInfo() {
    const workspacePrefix = `/workspaces/${this.mainStore.context.workspaceID}`;

    return {
      attestation: {
        url: `${workspacePrefix}/attestations`,
        basePath: "/workspaces/:workspace_id/attestations",
        name: "Attestations",
      },
      policy: {
        url: `${workspacePrefix}/modules/policy/drafts`,
        basePath: "/workspaces/:workspace_id/modules/policy",
        name: "Policies",
        description:
          "Keep your team aligned by drafting and finalizing important policies in a centralized repository.",
      },
      procedures: {
        url: `${workspacePrefix}/modules/procedures/drafts`,
        basePath: "/workspaces/:workspace_id/modules/procedures",
        name: "Procedures",
        description:
          "Keep your team aligned by drafting and finalizing important procedures in a centralized repository.",
      },
      new_product_approval: {
        url: `${workspacePrefix}/modules/change-management`,
        basePath: "/workspaces/:workspace_id/modules/change-management",
        name: "Change Management",
        description:
          "Submit and request approvals for material changes to programs, operations, products and services.",
      },
      vendor_due_diligence: {
        url: `${workspacePrefix}/modules/vendor-due-diligence/active`,
        basePath: "/workspaces/:workspace_id/modules/vendor-due-diligence",
        name: "Vendors",
        description:
          "Identify and track firm-wide issues to determine appropriate action plans, risk rate issues and create alerts.",
      },
      risk_register: {
        url: `${workspacePrefix}/modules/risk-register`,
        basePath: "/workspaces/:workspace_id/modules/risk-register",
        name: "Risk Register",
        description:
          "Create an inventory of potential company risk, assign inherent and residual ratings and link company controls used to mitigate risk",
      },
      marketing: {
        url: `${workspacePrefix}/modules/marketing`,
        basePath: "/workspaces/:workspace_id/modules/marketing",
        name: "Marketing",
        description:
          "Collaborate on sales material between departments and provide real-time comments with an easy to use interface.",
      },
      training: {
        url: `${workspacePrefix}/modules/training`,
        basePath: "/workspaces/:workspace_id/modules/training",
        name: "Training",
        description:
          "Track company wide and department specific internal communications and training programs.",
      },
      issue_management: {
        url: `${workspacePrefix}/modules/issue-management/all`,
        basePath: "/workspaces/:workspace_id/modules/issue-management",
        name: "Issue Management",
        description:
          "Track company wide issues in a single repository to make better strategy decisions.",
      },
      documents: {
        url: `${workspacePrefix}/modules/documents`,
        basePath: "/workspaces/:workspace_id/modules/documents",
        description:
          "Store important documents such as legal agreements, pricing sheets and terms and conditions so all stakeholders have access to the latest versions.",
        name: "Documents",
      },
      complaints: {
        url: `${workspacePrefix}/modules/complaints`,
        basePath: "/workspaces/:workspace_id/modules/complaints",
        name: "Complaints",
        description:
          "Track complaints from multiple sources centrally to make sure complaints are handled in a timely manner.",
      },
      audits: {
        url: `${workspacePrefix}/modules/audits`,
        basePath: "/workspaces/:workspace_id/modules/audits",
        description:
          "Store audit reports and issues across IT, Compliance and Finance so risk managers and board members have access to all outstanding company issues.",
        name: "Audits",
      },
      qa_tests_development: {
        url: `${workspacePrefix}/modules/qa-tests`,
        basePath: "/workspaces/:workspace_id/modules/qa",
        description:
          "Record internal company testing which has validated your company's compliance procedures.",
        name: "Monitoring & Testing",
      },
      conflicts_of_interest: {
        url: `${workspacePrefix}/modules/conflicts-of-interest`,
        basePath: "/workspaces/:workspace_id/conflicts-of-interest",
        description:
          "Track employees' potential conflicts of interests with automated submissions and approvals.",
        name: "Conflicts Of Interest",
      },
      finra: {
        url: `${workspacePrefix}/modules/finra/escalated`,
        basePath: "/workspaces/:workspace_id/modules/finra",
        description:
          "Log customer complaints, collaborate with customer service teams and send complaints directly to FINRA regulators for easier and manageable oversight.",
        name: "Finra Complaints",
      },
      customer_support: {
        url: `${workspacePrefix}/modules/customer-support/support`,
        basePath: "/workspaces/:workspace_id/modules/customer-support",
        description:
          "Track complaints and easily collaborate between your customer support team & compliance team.",
        name: "Customer Support",
      },
      control_mapping: {
        url: `${workspacePrefix}/modules/control-mapping`,
        basePath: "/workspaces/:workspace_id/modules/control-mapping",
        description:
          "Create an inventory of controls, identify effectiveness & perform attestation of controls to meet applicable regulatory and contractual requirements.",
        name: "Control Mapping",
      },
      risk_assessment: {
        url: `${workspacePrefix}/modules/risk-assessment`,
        basePath: "/workspaces/:workspace_id/modules/risk-assessment",
        name: "Questionnaires",
        description:
          "Create a risk matrix to determine risk associated with new products, BSA/AML and Sanctions.",
      },
      zendesk: {
        url: `${workspacePrefix}/modules/zendesk`,
        basePath: "/workspaces/:workspace_id/modules/zendesk",
        name: "Zendesk",
        description:
          "Integrate your Zendesk account to auto-import tickets to support the monitoring of issues and complaints in Themis.",
      },
      key_risk_indicators: {
        url: `${workspacePrefix}/modules/key-risk-indicators/list`,
        basePath: "/workspaces/:workspace_id/modules/key-risk-indicators",
        name: "Key Risk Indicators",
        description:
          "The Key Risk Indicator (KRI) module allows Themis users to easily track and monitor risk levels, providing valuable insights for effective risk management and decision-making.",
      },
      website_monitoring: {
        url: "/website_monitoring/monitoring_groups",
        basePath: "/website_monitoring/monitoring_groups",
        name: "Website Monitoring",
        description:
          "Remediate regulatory alerts from scans performed on websites and social media sites",
      },
      accounts: {
        url: `${workspacePrefix}/accounts`,
        basePath: "/workspaces/:workspace_id/accounts",
        name: "Accounts",
        description:
          "Manage all your Accounts (vendors, partners, 3rd parties, etc.) in one place. Required for Vendor Due Diligence and Questionnaires functionality.",
      },
    };
  }

  // Store Helpers

  isModuleAdded(moduleIdentifier: TopLevelModuleIdentifier) {
    return !!this.list.find(
      (moduleWorkSpace) =>
        moduleWorkSpace.themis_module.identifier === moduleIdentifier &&
        moduleWorkSpace.added,
    );
  }

  cleanup() {
    this.setList([]);
    this.setFavoriteModuleWorkspaces([]);
  }
}
