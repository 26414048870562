import { IconButton } from "@themis/ui";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { useIntl } from "react-intl";

import type { Policy } from "@/api/queries/policies/policies-library/use-policies-library";
import { usePolicyFileUrl } from "@/api/queries/policies/policies-library/use-policy-file-url";

interface DownloadPolicyButtonProps {
  policy: Policy;
}

export const DownloadPolicyButton = ({ policy }: DownloadPolicyButtonProps) => {
  const { formatMessage } = useIntl();

  const { refetch, isLoading } = usePolicyFileUrl(
    { indicator: policy.indicator },
    { enabled: false },
  );

  const handleDownload = async () => {
    const { data } = await refetch();

    if (data?.file_url) {
      window.open(data.file_url, "_blank", "noopener");
    }
  };

  return (
    <IconButton
      title={formatMessage({ defaultMessage: "Download" })}
      aria-label={formatMessage({ defaultMessage: "Download" })}
      color="tertiary"
      Icon={PiDownloadSimpleBold}
      onClick={handleDownload}
      loading={isLoading}
    />
  );
};
