import type { Comment } from "@themis/api/gen/models/comment";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { styled } from "@themis/ui-library/styles";
import type { ReactElement } from "react";
import { Mention, MentionsInput } from "react-mentions";

const StyledMentionsInput = styled(MentionsInput)({
  "&__control": {
    border: "none",
    color: "inherit",
  },
  "&__input": {
    padding: 0,
    color: "inherit",
    cursor: "text",
    border: "none !important",
    pointerEvents: "none",
    fontSize: "inherit",
    lineHeight: "inherit",
    fontWeight: "inherit",
  },
  "&__highlighter": {
    zIndex: 1,
    border: "none !important",
    pointerEvents: "none",
    padding: 0,
  },
});

const StyledMention = styled(Mention)(({ theme }) => ({
  color: theme.palette.secondary.main,
}));

interface CommentContentProps {
  comment: Comment;
}

export function CommentContent({ comment }: CommentContentProps): ReactElement {
  return (
    <Typography
      component="div"
      variant="body1"
      color={comment.resolved ? "grey.300" : "text.primary"}
    >
      <StyledMentionsInput disabled value={comment.content}>
        <StyledMention trigger="@" data={[]} />
      </StyledMentionsInput>
    </Typography>
  );
}
