import { Avatar } from "@themis/ui-library/components/data-display/avatar/avatar";

import type { User } from "@/api";

export default function UserBadge({
  text,
  user,
}: {
  text: string;
  user: Partial<User>;
}) {
  return (
    <span className="tw-flex tw-h-5 tw-items-center tw-space-x-3 tw-rounded-md tw-bg-neutral-50 tw-px-3 tw-py-1.5 tw-font-semibold">
      <div className="tw-text-xs tw-font-semibold tw-text-neutral-500">
        {text}
      </div>
      <div className="tw-flex tw-items-center tw-space-x-1">
        <Avatar colorSeed={user.icon_color_index}>{user.initials}</Avatar>
        <span className="tw-text-neutral-300">{user.full_name}</span>
      </div>
    </span>
  );
}
