import { http } from "msw";

import { createShowMonitoringGroupQueryResponse } from "../createShowMonitoringGroup";

export const showMonitoringGroupHandler = http.get(
  "*/monitoring_groups/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createShowMonitoringGroupQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
