import { Breadcrumbs, Button, useToast } from "@themis/ui";
import React from "react";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { useAccount } from "@/api/queries/accounts/accounts";
import {
  useContract,
  useCreateContract,
  useUpdateContract,
} from "@/api/queries/contracts";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";

import { ActiveTag } from "../components/ActiveTag";
import type { ContractInfoSchema } from "../components/Contracts/ContractInfoForm";
import { ContractInfoForm } from "../components/Contracts/ContractInfoForm";
import { routes } from "../routes";
import { isContractActive } from "../utils";
import type { AccountLocationState } from "./AccountDetails";

function StatusContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-box-border tw-flex tw-h-8 tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5">
      {children}
    </div>
  );
}

function ErrorContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-h-72 tw-flex-col tw-items-center tw-justify-center tw-gap-2 tw-text-sm tw-text-primaryDim-300">
      {children}
    </div>
  );
}

export function ContractDetails() {
  const history = useHistory();
  const location = useLocation<AccountLocationState>();
  const toast = useToast();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  /**
   * If exists, redirect to the specified URL after saving the contract
   * (e.g. when creating a contract from the vendor page, redirect back to the vendor page)
   */
  const redirectTo = searchParams.get("redirectTo");

  const { workspace_id, accountId, contractId } = useParams<{
    workspace_id: string;
    accountId: string;
    contractId: string;
  }>();

  const { data: accountData } = useAccount({
    workspaceId: Number(workspace_id),
    accountId: Number(accountId),
  });

  const account = accountData?.data;

  const {
    data: contractData,
    isLoading: isContractLoading,
    error: contractError,
  } = useContract(Number(contractId));

  /**
   * Redirect to the previous page (account details page or contracts list page)
   * after saving the contract.
   * If there is no previous page, redirect to the account details page.
   */
  const locationAfterSave =
    location.state?.from ||
    generatePath(routes.accounts.detail.recordView, {
      workspace_id,
      accountId,
    });

  const { mutateAsync: createContract } = useCreateContract({
    accountId: Number(accountId),
  });

  const { mutateAsync: updateContract } = useUpdateContract({
    contractId: Number(contractId),
  });

  async function handleCreateContract(values: ContractInfoSchema) {
    try {
      const requestBody = {
        contract: values,
      };

      await createContract(requestBody);

      toast({
        content: `Contract created successfully!`,
        variant: "success",
      });

      history.push(redirectTo || locationAfterSave);
    } catch {
      toast({
        content: "Something went wrong. Could not create contract.",
        variant: "error",
      });
    }
  }

  async function handleUpdateContract(values: ContractInfoSchema) {
    try {
      const requestBody = {
        contract: values,
      };

      await updateContract(requestBody);

      toast({
        content: `Contract updated successfully!`,
        variant: "success",
      });

      history.push(redirectTo || locationAfterSave);
    } catch {
      toast({
        content: "Something went wrong. Could not update contract.",
        variant: "error",
      });
    }
  }

  function handleSubmitForm(values: ContractInfoSchema) {
    if (!contractData) {
      handleCreateContract(values);

      return;
    }

    handleUpdateContract(values);
  }

  const header = (
    <Header
      title={
        <Breadcrumbs
          breadcrumbItems={[
            {
              label: "Accounts",
              to: generatePath(routes.accounts.overview, { workspace_id }),
            },
            {
              label: account?.name || "",
              to: generatePath(routes.accounts.detail.recordView, {
                workspace_id,
                accountId,
              }),
            },
            {
              label: !(isContractLoading || contractError)
                ? contractData?.contract?.file?.file_name || "Untitled"
                : "",
            },
          ]}
        />
      }
    />
  );

  if (isContractLoading || contractError) {
    return (
      <PageLayout>
        {header}
        <PageContent>
          {isContractLoading ? (
            <Loading loadingLayout="small-table" />
          ) : (
            <ErrorContainer>
              Could not load contract details
              <Button color="tertiary" onClick={history.goBack}>
                Go Back
              </Button>
            </ErrorContainer>
          )}
        </PageContent>
      </PageLayout>
    );
  }

  return (
    <PageLayout>
      {header}
      <PageContent>
        <div className="tw-flex tw-min-w-96 tw-flex-col tw-gap-8 tw-px-32">
          <div className="tw-flex tw-flex-row tw-gap-2">
            <StatusContainer>
              <label className="tw-text-sm tw-font-semibold tw-text-neutral-500">
                Status
              </label>
              <ActiveTag
                isActive={
                  contractData?.contract?.end_date
                    ? isContractActive(contractData?.contract?.end_date)
                    : true
                }
              />
            </StatusContainer>
          </div>
          <div className="tw-flex tw-flex-col tw-gap-2">
            <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
              Contract Info
            </h2>
            <ContractInfoForm
              onSubmit={handleSubmitForm}
              contract={contractData?.contract}
            />
          </div>
        </div>
      </PageContent>
    </PageLayout>
  );
}
