import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  MiniTag,
  PreviewTable,
  TextInput,
  useToast,
} from "@themis/ui";
import React, { useState } from "react";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  PiBookmarkSimple,
  PiCheck,
  PiDownloadSimple,
  PiPencilSimpleLine,
  PiX,
} from "react-icons/pi";
import { useParams } from "react-router-dom";

import { EDIT_SCORING_GROUP_COLUMNS } from "../../config/editGroupScoringTable";
import { SCORING_GROUP_COLUMNS } from "../../config/groupScoringTable";
import {
  exportRiskRegisterGroups,
  useUpdateRiskRegisterGroup,
} from "../../hooks";
import type { GroupScoringRegister } from "../../types/GroupScoringRegister";
import type { RiskRegisterGroup } from "../../types/RiskRegisterGroup";
import type { RiskRegisterGroupItem } from "../../types/RiskRegisterGroupItem";
import ScoreTag from "../ScoreTag";

interface ScoringGroupProps {
  group: RiskRegisterGroup;
  groupRegisters: RiskRegisterGroupItem[];
  registers: GroupScoringRegister[];
  onAddRegisters: (groupId: number) => void;
  onRemoveRegister: (groupId: number, registerId: number) => void;
  onDelete: (groupId: number) => void;
  onWeightsUpdated: () => void;
}

function ScoringGroup(props: ScoringGroupProps) {
  const [editGroupMode, setEditGroupMode] = useState(false);
  const [editWeightMode, setEditWeightMode] = useState(false);
  const [intName, setIntName] = useState(props.group.name);
  const [updatedName, setUpdatedName] = useState<string | null>();
  const [intWeights, setIntWeights] = useState<
    Array<{ id: number; weight: number }>
  >([]);
  const { workspace_id } = useParams<{ workspace_id: string }>();
  const updateGroupMutation = useUpdateRiskRegisterGroup(workspace_id);
  const toast = useToast();

  const handleUpdateWeight = (id: number, value: number, remove = false) => {
    const updateExists = !!intWeights.find((weight) => weight.id === id);
    if (updateExists) {
      if (remove) {
        setIntWeights((prev) => prev.filter((w) => w.id !== id));
      } else {
        setIntWeights(
          intWeights.map((update) => {
            if (update.id === id) {
              return { ...update, weight: value };
            }
            return update;
          }),
        );
      }
    }
    if (!remove) {
      setIntWeights((prev) => prev.concat({ id, weight: value }));
    }
  };

  function calculateTotalWeight() {
    const totalWeight = props.groupRegisters.reduce((total, register) => {
      const updated = intWeights.find((uw) => uw.id === register.id);
      if (updated) {
        return total + updated.weight;
      }
      return total + register.weight;
    }, 0);

    return totalWeight;
  }

  function createRowData(groupRegisters: RiskRegisterGroupItem[]) {
    const totalWeight = calculateTotalWeight();
    const updatedRowData = groupRegisters
      .sort((a, b) => (b.name > a.name ? -1 : 1))
      .map((r, index) => ({
        ...r,
        index,
        isError: totalWeight > 100,
        updateWeight: handleUpdateWeight,
        remove: handleRemoveRegister,
        aggregate_scores: props.registers?.find(
          (st) => st.id === r.section_tag_id,
        )!.aggregate_scores,
      }));
    return updatedRowData;
  }

  const handleCancelEditName = () => {
    setIntName(updatedName || props.group.name);
    setEditGroupMode(false);
  };

  const handleSaveEditName = async () => {
    updateGroupMutation
      .mutateAsync({ id: props.group.id, name: intName })
      .then(() => {
        setUpdatedName(intName);
      })
      .finally(() => setEditGroupMode(false));
  };

  const handleSaveWeights = () => {
    updateGroupMutation
      .mutateAsync({
        id: props.group.id,
        risk_register_group_items_attributes: intWeights,
      })
      .then(() => {
        setIntWeights([]);
        return props.onWeightsUpdated();
      })
      .finally(() => setEditWeightMode(false));
  };

  const handleRemoveRegister = (registerId: number) => {
    props.onRemoveRegister(props.group.id, registerId);
  };

  const handleDeleteGroup = () => {
    props.onDelete(props.group.id);
  };

  function createScoreBlock(
    title: string,
    score: number,
    tag?: React.ReactNode,
  ) {
    return (
      <div className="tw-relative tw-flex tw-w-full tw-flex-col tw-border-0 tw-border-r tw-border-solid tw-border-generals-underline tw-px-6 tw-py-4">
        <div className="tw-flex tw-justify-center">
          <p className="tw-text-[40px] tw-font-semibold tw-text-neutral-500">
            {score}
          </p>
        </div>
        {tag && <div className="tw-mb-2 tw-flex tw-justify-center">{tag}</div>}
        {!tag && <div className="tw-flex tw-flex-grow" />}

        <div className="tw-flex tw-justify-center">
          <p className="text-sm tw-font-semibold tw-text-neutral-300">
            {title}
          </p>
        </div>
      </div>
    );
  }

  const totalWeight = calculateTotalWeight();
  const totalWeightText = `Total Weighting: ${totalWeight}%`;

  return (
    <div className="tw-flex tw-w-full tw-max-w-[1092px] tw-flex-col tw-gap-2">
      <div className="tw-flex tw-w-full">
        <div className="tw-flex tw-w-full tw-items-center tw-justify-start tw-gap-2">
          {editGroupMode ? (
            <>
              <TextInput
                value={intName}
                size="md"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setIntName(e.target.value)
                }
                className="tw-w-[200px]"
              />
              <div>
                <IconButton
                  Icon={PiCheck}
                  color="secondary"
                  size="lg"
                  onClick={handleSaveEditName}
                />
                <IconButton
                  Icon={PiX}
                  color="transparent"
                  size="lg"
                  onClick={handleCancelEditName}
                />
              </div>
            </>
          ) : (
            <>
              <p className="tw-text-base tw-font-semibold tw-text-neutral-500">
                {updatedName || props.group.name}
              </p>
              <MiniTag
                size="lg"
                color={totalWeight === 100 ? "green" : "red"}
                variant="default"
              >
                {totalWeightText}
              </MiniTag>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <IconButton
                    Icon={FiMoreHorizontal}
                    color="transparent"
                    size="lg"
                  />
                </DropdownMenuTrigger>
                <DropdownMenuContent align="start">
                  <DropdownMenuItem
                    onClick={() => setEditGroupMode((prev) => !prev)}
                  >
                    Rename Grouping
                  </DropdownMenuItem>
                  <div className="tw-my-2 tw-w-full tw-border-b tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-generals-underline" />
                  <DropdownMenuItem onClick={handleDeleteGroup}>
                    Delete
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </>
          )}
        </div>
        <div className="tw-flex tw-w-full tw-items-center tw-justify-end tw-gap-2">
          {editWeightMode ? (
            <div
              data-tooltip-content="The total weighting must not be greater than 100 or less than 0."
              data-tooltip-id="tooltip"
              data-tooltip-place="left"
              data-tooltip-hidden={totalWeight <= 100 && totalWeight >= 0}
            >
              <Button
                color="tertiary"
                LeftIcon={PiBookmarkSimple}
                onClick={handleSaveWeights}
                disabled={totalWeight > 100 || totalWeight < 0}
              >
                Save and Exit
              </Button>
            </div>
          ) : (
            <>
              <Button
                disabled={editGroupMode}
                onClick={() => props.onAddRegisters(props.group.id)}
              >
                Add Register
              </Button>
              <Button
                color="tertiary"
                LeftIcon={PiDownloadSimple}
                disabled={editGroupMode}
                onClick={() => {
                  toast({
                    content: "The excel spreadsheet is downloading",
                    variant: "download",
                    hideCloseButton: true,
                  });
                  exportRiskRegisterGroups(workspace_id, props.group.id);
                }}
              >
                Export
              </Button>
              <Button
                color="tertiary"
                LeftIcon={PiPencilSimpleLine}
                onClick={() => setEditWeightMode(true)}
                disabled={editGroupMode}
              >
                Edit
              </Button>
            </>
          )}
        </div>
      </div>
      <div className="tw-flex tw-w-full tw-border tw-border-r-0 tw-border-solid tw-border-generals-underline ">
        {createScoreBlock(
          "Overall Risk Score & Level",
          props.group.overall_score,
        )}
        {createScoreBlock(
          "Inherent Risk Score & Level",
          props.group.inherent_score,
          <ScoreTag
            color={props.group.inherent_level.color}
            label={props.group.inherent_level.title}
          />,
        )}
        {createScoreBlock(
          "Residual Risk Score & Level",
          props.group.residual_risk_score,
          <ScoreTag
            color={props.group.residual_level.color}
            label={props.group.residual_level.title}
          />,
        )}
      </div>
      <PreviewTable
        rows={createRowData(props.groupRegisters)}
        // @ts-expect-error fix types
        columns={
          editWeightMode ? EDIT_SCORING_GROUP_COLUMNS : SCORING_GROUP_COLUMNS
        }
      />
    </div>
  );
}

export default ScoringGroup;
