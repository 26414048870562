import type { Control, FieldValues, Path } from "react-hook-form";
import { useIntl } from "react-intl";

import type { FieldConfigurationWithLayout } from "@/api/utils/fields/field-configuration-with-layout";
import { DynamicField } from "@/components/dynamic-field/dynamic-field";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

interface FieldValuesWithCustomFields extends FieldValues {
  customFields?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

interface AdditionalDetailsProps<T extends FieldValuesWithCustomFields> {
  customFields: FieldConfigurationWithLayout[];
  control: Control<T>;
  disabled?: boolean;
}

export function AdditionalDetails<T extends FieldValuesWithCustomFields>({
  control,
  customFields,
  disabled,
}: AdditionalDetailsProps<T>) {
  const { formatMessage } = useIntl();
  const workspaceId = useRouteWorkspaceId();

  return (
    <div className="tw-col-span-2 tw-flex tw-flex-col tw-gap-2">
      <h2 className="tw-pb- tw-pt-5 tw-text-lg tw-font-semibold tw-text-neutral-500">
        {formatMessage({ defaultMessage: "Additional Details" })}
      </h2>

      <div className="tw-grid tw-grid-cols-2 tw-gap-x-6 tw-gap-y-3">
        {customFields.map(({ field }) => (
          <DynamicField
            disabled={disabled}
            key={field.id}
            control={control}
            name={`customFields.${field.path}` as Path<T>}
            fieldType={field.field_type}
            options={field.options}
            multiselect={field.multiselect}
            label={field.display_name}
            referenceType={field.reference_type}
            required={field.required}
            workspaceId={workspaceId}
          />
        ))}
      </div>
    </div>
  );
}
