import type { ReactElement, ReactNode } from "react";

import { Divider } from "../../../data-display/divider/divider";
import { Box } from "../../box/box";
import { Stack } from "../../stack/stack";

interface DetailMediaLayoutMainProps {
  header?: ReactNode;
  content: ReactNode;
  footer?: ReactNode;
}

export function DetailMediaLayoutMain({
  header,
  content,
  footer,
}: DetailMediaLayoutMainProps): ReactElement {
  return (
    <Stack position="relative" width="100%" height="100%" spacing={0}>
      {header && (
        <Box flexGrow={0} flexShrink={0}>
          <Stack padding={2}>{header}</Stack>
          <Divider />
        </Box>
      )}

      <Stack height="100%" spacing={0} paddingY={1} overflow="auto">
        {content}
      </Stack>

      {footer && (
        <Stack
          justifyContent="center"
          borderTop="1px solid"
          borderColor="grey.200"
          padding={1}
        >
          {footer}
        </Stack>
      )}
    </Stack>
  );
}
