import type { RecordWithId } from "@themis/ui";

import type { CompanyUser, Contact } from "@/api";

export interface AssigneeField extends RecordWithId {
  status: "approved" | "pending";
  approvedAt?: string;
  sentAt?: string;
  numberOfResend?: number;
}
export type UserAssignee = { userId: number } & CompanyUser & AssigneeField;
export type ContactAssignee = { contactId: number } & Contact & AssigneeField;
export type EmailAssignee = { email: string } & AssigneeField;

export type Assignee = UserAssignee | ContactAssignee | EmailAssignee;

interface BaseAssigneeOption {
  value: number;
  label: string;
}
export interface UserAssigneeOption extends BaseAssigneeOption {
  record: CompanyUser;
  type: "user";
}
export interface ContactAssigneeOption extends BaseAssigneeOption {
  record: Contact;
  type: "contact";
}
export interface DepartmentAssigneeOption extends BaseAssigneeOption {
  type: "department";
}

export type AssigneeOption =
  | UserAssigneeOption
  | ContactAssigneeOption
  | DepartmentAssigneeOption;

export function isUserAssignee(assignee: Assignee): assignee is UserAssignee {
  return (assignee as UserAssignee).userId !== undefined;
}
export function isContactAssignee(
  assignee: Assignee,
): assignee is ContactAssignee {
  return (assignee as ContactAssignee).contactId !== undefined;
}
export function isEmailAssignee(assignee: Assignee): assignee is EmailAssignee {
  return (assignee as EmailAssignee).email !== undefined;
}
