import type { PropsWithChildren } from "react";
import { createContext, useCallback, useState } from "react";

import type { SnackbarProps } from "../../components/feedback/snackbar/snackbar";
import { Snackbar } from "../../components/feedback/snackbar/snackbar";
import { useOpen } from "../../hooks/use-open/use-open";

type Params = Omit<SnackbarProps, "open" | "onClose">;

export interface SnackbarContextValue {
  open: (params: Params) => void;
}

export const SnackbarContext = createContext<SnackbarContextValue | null>(null);

export function SnackbarProvider({ children }: PropsWithChildren) {
  const { close, isOpen, open } = useOpen();

  const [snackbarProps, setSnackbarProps] = useState<Params>({});

  const handleSetSnackbarProps = useCallback(
    (params: Params) => {
      setSnackbarProps(params);
      open();
    },
    [open],
  );

  return (
    <SnackbarContext.Provider value={{ open: handleSetSnackbarProps }}>
      {children}

      <Snackbar
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        open={isOpen}
        autoHideDuration={5000}
        onClose={close}
        {...snackbarProps}
      />
    </SnackbarContext.Provider>
  );
}
