import { Button } from "@themis/ui";
import { PiEyeLight } from "react-icons/pi";
import { useIntl } from "react-intl";

import type { Policy } from "@/api/queries/policies/policies-library/use-policies-library";
import { usePolicyFileUrl } from "@/api/queries/policies/policies-library/use-policy-file-url";

interface ViewPolicyButtonProps {
  policy: Policy;
}

export const ViewPolicyButton = ({ policy }: ViewPolicyButtonProps) => {
  const { formatMessage } = useIntl();

  const { refetch, isLoading } = usePolicyFileUrl(
    { indicator: policy.indicator, preview: true },
    { enabled: false },
  );

  const handlePreview = async () => {
    const { data } = await refetch();

    if (data?.file_url) {
      window.open(data?.file_url, "_blank");
    }
  };

  return (
    <Button LeftIcon={PiEyeLight} onClick={handlePreview} loading={isLoading}>
      {formatMessage({ defaultMessage: "View" })}
    </Button>
  );
};
