import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import React from "react";
import type { ReactElement, ReactNode } from "react";

interface HeaderProps {
  children: ReactNode;
}

export function Header({ children }: HeaderProps): ReactElement {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      px={2}
      py={1}
    >
      {children}
    </Stack>
  );
}
