import { ListItemAvatar } from "@themis/ui-library/components/data-display/list/list-item-avatar/list-item-avatar";
import { ListItemText } from "@themis/ui-library/components/data-display/list/list-item-text/list-item-text";
import { MenuItem } from "@themis/ui-library/components/navigation/menu/menu-item/menu-item";
import { MenuList } from "@themis/ui-library/components/navigation/menu/menu-list/menu-list";
import { Paper } from "@themis/ui-library/components/paper/paper";
import { Grow } from "@themis/ui-library/components/transitions/grow/grow";
import { styled } from "@themis/ui-library/styles";
import type { KeyboardEvent, ReactElement, ReactNode } from "react";
import { useCallback, useMemo, useRef } from "react";
import type { SuggestionDataItem } from "react-mentions";
import { Mention, MentionsInput } from "react-mentions";

import { UsersCircle } from "@/components/users-circle/users-circle";
import type { User } from "@/stores/types/user-types";

const StyledMentionsInput = styled(MentionsInput)(({ theme }) => ({
  ...theme.typography.body2,

  "&__control": {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    ...theme.typography.body2,
  },
  "&__highlighter": {
    zIndex: 1,
    pointerEvents: "none",
    padding: "7px",
    ...theme.typography.body2,
  },
  "&__input": {
    outline: "none",
    border: `1px solid ${theme.palette.grey[200]}`,
    left: "-1px !important",
    top: "-1px !important",
    borderRadius: theme.shape.borderRadius,
    opacity: 1,
    padding: "8px",
    ...theme.typography.body2,

    "&[data-disabled=true]": {
      opacity: 0.5,
    },

    "&:focus": {
      borderColor: theme.palette.primary.main,
    },

    "&::placeholder": {
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[300],
    },
  },

  "&__suggestions": {
    background: "none",
  },
}));

export interface CommentsInputProps {
  autoFocus?: boolean;
  isPending: boolean;
  placeholder: string;
  size?: "small" | "medium";
  usersMap: Record<string, User>;
  value: string;
  onSubmit: () => void;
  onValueChange: (value: string) => void;
}

export function CommentsInput({
  autoFocus,
  isPending,
  placeholder,
  size,
  usersMap,
  value,
  onSubmit,
  onValueChange,
}: CommentsInputProps): ReactElement | null {
  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  const userOptions: SuggestionDataItem[] = useMemo(() => {
    return Object.values(usersMap).map((user) => ({
      id: user.id.toString(),
      display: user.full_name,
    }));
  }, [usersMap]);

  const handleInputChange = (event: { target: { value: string } }) => {
    onValueChange(event.target.value);
  };

  const handleKeyDown = (
    event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (event.key === "Enter" && !event.shiftKey && value.trim().length > 0) {
      event.preventDefault();
      onSubmit();
    }
  };

  const setInputRef = (node: HTMLTextAreaElement | null) => {
    inputRef.current = node;

    if (node && autoFocus) {
      // react-mentions limitation
      // the focus needs to be set after the input is rendered
      setTimeout(() => {
        node.focus();
        // set the cursor to the end of the input
        node.setSelectionRange(node.value.length, node.value.length);
      }, 10);
    }
  };

  const renderSuggestions = useCallback((children: ReactNode) => {
    return (
      <Grow in>
        <Paper>
          <MenuList dense>{children}</MenuList>
        </Paper>
      </Grow>
    );
  }, []);

  const renderSuggestion = useCallback(
    (
      suggestion: SuggestionDataItem,
      _search: string,
      _highlightedDisplay: ReactNode,
      _index: number,
      focused: boolean,
      // eslint-disable-next-line max-params
    ) => {
      const user = usersMap[suggestion.id.toString()];

      if (!user) {
        return null;
      }

      return (
        <MenuItem component="div" selected={focused}>
          <ListItemAvatar sx={(theme) => ({ width: theme.size.icon.large })}>
            <UsersCircle
              initials={user.initials}
              iconColorIndex={user.icon_color_index}
            />
          </ListItemAvatar>

          <ListItemText
            primary={suggestion.display}
            secondary={user.awaiting_verification ? user.status : undefined}
          />
        </MenuItem>
      );
    },
    [usersMap],
  );

  return (
    <StyledMentionsInput
      inputRef={setInputRef}
      autoFocus={autoFocus}
      disabled={isPending}
      data-size={size}
      data-disabled={isPending}
      value={value}
      allowSuggestionsAboveCursor
      placeholder={placeholder}
      customSuggestionsContainer={renderSuggestions}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
    >
      <Mention
        trigger="@"
        className="tw-text-secondary-300"
        data={userOptions}
        renderSuggestion={renderSuggestion}
        appendSpaceOnAdd
      />
    </StyledMentionsInput>
  );
}
