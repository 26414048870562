import type { Contact } from "@themis/api/gen/models/contact";
import { actionsColumnDefParams } from "@themis/ui-library/components/data-grid/column-defs/actions-column-def-params";
import type { ColDef } from "ag-grid-community";
import type { CustomCellRendererProps } from "ag-grid-react";
import React, { useMemo } from "react";

import { ContactsActionsCell } from "../components/ContactsTable/ContactsActionCell";

interface UseContactsColumnsParams {
  contactsRoute: string;
}

export function useContactsColumns({
  contactsRoute,
}: UseContactsColumnsParams) {
  return useMemo(
    (): Array<ColDef<Contact>> => [
      {
        headerName: "First Name",
        colId: "first_name",
        field: "first_name",
        minWidth: 200,
        cellRenderer: "identifierCellRenderer",
        cellRendererParams: (props: CustomCellRendererProps) => {
          const accountId = props.data.id;
          return {
            to: `${contactsRoute}/${accountId}`,
            ...props,
          };
        },
      },
      {
        field: "middle_name",
        colId: "middle_name",
        headerName: "Middle Name",
        minWidth: 200,
      },
      {
        field: "last_name",
        colId: "last_name",
        headerName: "Last Name",
        minWidth: 200,
      },
      {
        field: "phone",
        colId: "phone",
        headerName: "Phone",
        minWidth: 200,
      },
      {
        field: "email",
        colId: "email",
        headerName: "Email",
        minWidth: 200,
      },
      {
        ...actionsColumnDefParams,
        cellRenderer: ({ data }: CustomCellRendererProps<Contact, Contact>) =>
          data ? <ContactsActionsCell contactId={data.id} /> : null,
      },
    ],
    [contactsRoute],
  );
}
