import { ScrollArea, ScrollBar } from "@themis/ui";
import { MediaViewer } from "@themis/ui-library/components/data-display/media/media-viewer/media-viewer";
import { styled } from "@themis/ui-library/styles";

import { useInteractiveMediaContext } from "../../interactive-media.context";

const StyledScrollArea = styled(ScrollArea)(({ theme }) => ({
  flex: "1 1 100%",
  backgroundColor: theme.palette.grey[100],
}));

export function Viewer() {
  const { file, zoom } = useInteractiveMediaContext();

  return (
    <StyledScrollArea type="always">
      <MediaViewer file={file} zoomLevel={zoom.level} />
      <ScrollBar orientation="horizontal" />
    </StyledScrollArea>
  );
}

export default Viewer;
