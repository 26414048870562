import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiCaretUpBold } from "react-icons/pi";

import type { Field, RecordVersion } from "@/api";
import Loading from "@/components/Loading";
import SectionTag from "@/components/table/shared/SectionTag";

import { useMainStore } from "../../../contexts/Store";
import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import { isLockedRecord } from "../../../stores/helpers/RecordVersionHelper";
import type { AggregateRiskRatings } from "../../../stores/types/risk-register-types";
import type { Section } from "../../../stores/types/section-tags";
import MenuActions from "../menu-actions/MenuActions";
import { useCustomColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ReorderableTable from "../shared/ReorderableTable";
import SectionPopupSettings from "../shared/SectionPopupSettings";
import Sharing from "../shared/Sharing";
import TableMenu from "../shared/tableMenu/TableMenu";
import AddNewButton from "./AddNewButton";
import RiskRegisterScoreChip from "./RiskRegisterScoreChip";

interface Props {
  recordVersions: RecordVersion[];
  currentScreen?: string;
  fields?: Field[];
  isDragAndDrop?: boolean;
  moduleWorkspaceID?: number;
  pageID?: number;
  sectionTag?: Section;
  tableName?: string;
  loading: boolean;
  onToggleSection?: (sectionTagId: number, isOpen?: boolean) => void;
}

function RiskRegisterSection({
  recordVersions,
  fields,
  isDragAndDrop,
  moduleWorkspaceID,
  sectionTag,
  loading,
  tableName,
  onToggleSection,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isEditMode, setIsEditMode] = useState(false);
  const { isOpen, toggle } = useOpen(true);
  const [selectMode, setSelectMode] = useState(false);

  // Variables
  const sectionTableID = `section-table-${sectionTag?.id || 0}`;
  const { isAdmin, workspaceID } = mainStore.context;
  const { canManageSections } = mainStore.userPermissions;
  const { aggregateScores } = mainStore.riskRegisters;

  const defaultSectionTags = ["General"];
  const isDefaultSectionTag = !defaultSectionTags.includes(
    sectionTag?.title || "",
  );

  // hooks
  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    useCustomColumns({
      sectionTableID,
    });
  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
  } = useSortingAndPagination({
    store: mainStore.riskRegisters,
    sectionTagId: sectionTag?.id,
  });

  // Functions
  const resetMenuActions = () => {
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  const dropdownClick = (open: boolean) => {
    toggle();
    if (sectionTag?.id) {
      onToggleSection?.(sectionTag.id, open);
    }
  };
  const renameClick = () => {
    setIsEditMode(true);
  };

  const deleteSectionTag = () => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    mainStore.sectionTags.delete(moduleWorkspaceID, sectionTag.id);
    mainStore.toast.setText("Section has been deleted!");
  };

  // elements
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderRow = (recordVersion) => {
    const status = mainStore.avroSchemas.firstValueForField(
      "status",
      recordVersion?.data,
    );

    if (!loading) {
      return (
        <ModuleRecordVersion
          key={recordVersion.id}
          moduleWorkspaceID={moduleWorkspaceID}
          fields={fields}
          recordVersion={recordVersion}
          tableID={recordVersion.table_id}
          tableName={recordVersion.table_name}
          moduleIdentifier="risk_register"
          currentTableName={recordVersion.table_name}
          inSelectMenuActionMode={selectMode}
          isLockedRow={status === "completed"}
          // @ts-expect-error TS(2322) FIXME: Type 'boolean | null' is not assignable to type 'b... Remove this comment to see the full error message
          isArchivedRow={isLockedRecord(recordVersion)}
        />
      );
    }

    // Return null if loading
    return <Loading loadingLayout="table-no-add-new" />;
  };

  // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
  const renderFields = fields.map((field) => (
    <ModuleTableColumn
      key={field.name}
      field={field}
      // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
      setSortByDirection={setSortByDirection}
      setSortByFieldName={setSortByFieldName}
      onDelete={deleteColumn}
      onRename={renameColumn}
    />
  ));

  const header = (
    <div className="list-virtual-header">
      <div className="list-title-table">
        <ul>
          <div className="list-column-wrap">
            <div className="procedures-list-column-first-blocks table-column-reorder">
              {fields && fields.length > 0 && (
                <TableMenu
                  setShowCheckbox={setSelectMode}
                  resetMenuActions={resetMenuActions}
                  selectMode={selectMode}
                  setSelectMode={setSelectMode}
                />
              )}
            </div>
            <div className="list-title-table-wrap">
              {selectMode ? (
                <div>
                  <MenuActions resetMenuActions={resetMenuActions} />
                </div>
              ) : (
                <div className="list-title-table-wrap virtualized-table-header">
                  {renderFields}
                  <span className="stretch-cell" />
                </div>
              )}
            </div>
          </div>
          <AddColumnButton onAdd={createColumn} />
        </ul>
      </div>
    </div>
  );

  function riskChips() {
    const riskValues: AggregateRiskRatings =
      aggregateScores[sectionTag?.id || 0];
    if (!riskValues) {
      return;
    }
    const chips = [];

    if (riskValues) {
      if (riskValues.inherent && riskValues.inherent?.value) {
        chips.push(
          <RiskRegisterScoreChip
            section="Inherent"
            title={riskValues.inherent?.title}
            value={riskValues.inherent?.value}
            color={riskValues.inherent?.color}
          />,
        );
      }
      if (riskValues.residual && riskValues.residual?.value) {
        chips.push(
          <RiskRegisterScoreChip
            section="Residual"
            title={riskValues.residual?.title}
            value={riskValues.residual?.value}
            color={riskValues.residual?.color}
          />,
        );
      }
    }

    if (!riskValues?.residual) {
      chips.push(
        <span
          key="rr-score-chip-N/A"
          className="rr-score-chip"
          style={{ backgroundColor: "#e63956", color: "white" }}
          data-tooltip-id="tooltip"
          data-tooltip-content="Check that all cells in the matrix have been assigned a rating."
          data-tooltip-place="right"
        >
          Residual: N/A
        </span>,
      );
    }
    if (!riskValues?.inherent) {
      chips.push(
        <span
          key="rr-score-chip-N/A"
          className="rr-score-chip"
          style={{ backgroundColor: "#e63956", color: "white" }}
          data-tooltip-id="tooltip"
          data-tooltip-content="Check that all cells in the matrix have been assigned a rating."
          data-tooltip-place="right"
        >
          Inherent: N/A
        </span>,
      );
    }

    return chips;
  }

  return (
    <>
      {sectionTag && (
        <div className="tw-flex" data-testid="dropdown-section">
          <div
            className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-[7px] hover:tw-rounded-md hover:tw-bg-neutral-100"
            onClick={() => dropdownClick(!isOpen)}
          >
            <PiCaretUpBold
              className={classNames(
                "tw-h-[18px] tw-w-[18px] tw-origin-center  tw-text-neutral-500",
                {
                  "tw-rotate-180": !isOpen,
                  "tw-rotate-0 ": isOpen,
                },
              )}
            />
          </div>
          {sectionTag?.title && (
            <SectionTag
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              sectionTagID={sectionTag?.id}
              sectionTagTitle={sectionTag?.title}
            />
          )}

          {sectionTag?.editable && !isEditMode && (
            <>
              {isAdmin && (
                <Sharing
                  moduleWorkspaceID={moduleWorkspaceID}
                  objectID={sectionTag.id}
                  selectedDepartmentIDs={sectionTag.department_ids}
                  selectedUserIDs={sectionTag.user_ids}
                  tableName={tableName}
                  allowSharing
                />
              )}

              {canManageSections && isDefaultSectionTag && (
                <SectionPopupSettings
                  onRename={renameClick}
                  onDelete={deleteSectionTag}
                />
              )}
            </>
          )}
          <div key="title-chips">{riskChips()}</div>
        </div>
      )}

      {isOpen && (
        <div className="virtual-table-container">
          <div
            className={classNames("dropdown-table", {
              draggable: isDragAndDrop,
            })}
            data-testid="procedures-dropdown-table"
            id={sectionTableID}
          >
            <ReorderableTable
              recordVersions={recordVersions}
              renderer={renderRow}
              sectionTag={sectionTag}
              isSorted={!!sortByFieldName}
              header={header}
              virtualized
              newRowOverride={
                <AddNewButton
                  workspaceID={workspaceID}
                  sectionTag={sectionTag}
                />
              }
            />
          </div>
        </div>
      )}
    </>
  );
}

export default observer(RiskRegisterSection);
