import type { ColDef } from "ag-grid-community";

export const getMoreOptionsColumn = <T>(
  params: ColDef<T>["cellRendererParams"],
) => ({
  headerName: "",
  minWidth: 64,
  width: 64,
  sortable: false,
  resizable: false,
  pinned: "right" as const,
  ...params,
});
