import { isEmpty } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import Loading from "../Loading";
import InputCell from "../table/shared/cell-type/InputCell";
import ViewDepartmentSelect from "../table/shared/dynamic-form/ViewDepartmentSelect";
import ViewRolesSelect from "../table/shared/dynamic-form/ViewRolesSelect";

const CompanyUserDetails = () => {
  const mainStore = useMainStore();
  const history = useHistory();

  const { activeWorkspace, isIW } = mainStore.context;

  const search = new URLSearchParams(location.search);
  const isInternalUserDetailView = search.get("in") === "1";

  const { user_id } = useParams<{ user_id?: string }>();

  const selectedUser = useMemo(
    () =>
      user_id
        ? mainStore.users.allUsers?.find((user) => user.id === Number(user_id))
        : null,
    [user_id, mainStore.users.allUsers],
  );

  useEffect(() => {
    if (isEmpty(selectedUser)) {
      history.push("/settings/users");
    }
  }, []);

  useEffect(() => {
    const activeWorkspaceID = activeWorkspace?.id;
    if (activeWorkspaceID) {
      const payload = {
        workspaceID: activeWorkspaceID,
        showAllUsers: false,
      };

      if (!isIW || !isInternalUserDetailView) {
        payload.showAllUsers = true;
      }

      mainStore.users.indexForSettings(payload);
    }
  }, [activeWorkspace, isInternalUserDetailView, isIW]);

  useEffect(() => {
    if (selectedUser) {
      mainStore.users.setSelectedUser(selectedUser);
    }
  }, [selectedUser]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  const handleNameChange = (fieldName, _, value) => {
    mainStore.users.updateSelectedUser(fieldName, value);
  };

  if (isEmpty(selectedUser)) {
    return <Loading loadingLayout="sections" showTableHeader={false} />;
  }

  return (
    <div>
      <div className="user-detail-view">
        <section>
          <div className="section-header">
            <div className="title">User Details</div>
          </div>
          <div className="section-row two-cols">
            <div className="column column-input" data-testid="email-address">
              <h4>Email Address</h4>
              {/* @ts-expect-error TS(2741) FIXME: Property 'width' is missing in type '{ disabled: t... Remove this comment to see the full error message */}
              <InputCell
                disabled
                fieldName="email"
                dataTestId="Email Address"
                initialValue={selectedUser?.email}
                hasErrors={selectedUser?.email?.length === 0}
              />
            </div>
            <div className="column column-input" data-testid="full-name">
              <h4>Full Name</h4>
              {/* @ts-expect-error TS(2741) FIXME: Property 'width' is missing in type '{ fieldName: ... Remove this comment to see the full error message */}
              <InputCell
                disabled={false}
                fieldName="full_name"
                initialValue={selectedUser?.full_name || ""}
                hasErrors={
                  !mainStore.users.selectedUser?.full_name?.trim().length
                }
                dataTestId="Full Name"
                onDataChange={handleNameChange}
                hasShouldPersist
              />
            </div>
            <div className="column column-select" data-testid="department">
              <ViewDepartmentSelect
                fieldName="department"
                locked={false}
                initialValue={selectedUser?.department}
                isUserSection
              />
            </div>
          </div>
        </section>
        <section className="user-roles-section">
          <ViewRolesSelect user={selectedUser} />
        </section>
      </div>
    </div>
  );
};

export default observer(CompanyUserDetails);
