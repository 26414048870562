import { Command as CommandPrimitive } from "cmdk";
import * as React from "react";
import { PiMagnifyingGlassBold } from "react-icons/pi";

import { cn } from "../../lib/utils";

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => (
  <div className="tw-relative">
    <PiMagnifyingGlassBold className="tw-absolute tw-right-3 tw-top-3 tw-h-4 tw-w-4 tw-text-neutral-500" />
    <CommandPrimitive.Input
      ref={ref}
      className={cn(
        "tw-ring-none tw-box-border tw-flex tw-h-10 tw-w-full tw-rounded-md tw-border-none tw-bg-transparent tw-px-4 tw-font-sans tw-outline-none",
        "tw-text-sm tw-font-medium tw-text-neutral-500",
        "placeholder:tw-font-sans placeholder:tw-text-sm placeholder:tw-font-medium placeholder:tw-text-neutral-200 placeholder:tw-opacity-100",
        "disabled:tw-cursor-not-allowed disabled:tw-opacity-50",
        className,
      )}
      {...props}
    />
  </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

export { CommandInput };
