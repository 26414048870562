import { CheerioTableParser } from "html-tables-to-json";
import { camelCase, capitalize, cloneDeep, startCase } from "lodash";

import type { Field } from "@/api";

import { getTableFiltersParam } from "../../../../stores/helpers/getTableFiltersParam";
import { TopLevelModule } from "../../../../stores/types/module-workspaces-types";
import {
  defaultAllTimeStartDate,
  INTERVAL_OPTIONS,
  tableHeader,
  TIME_RANGE_OPTIONS,
} from "./constants";
import {
  filterForIsCondition,
  filterForIsWithinCondition,
} from "./createFilter";
import { Dashboards } from "./types";
import { allIssuesUrl } from "./urlPaths";

export const createDateFilter = ({
  field,
  startDate,
  endDate,
  groupFieldName,
  filterValue,
  dashboard = undefined,
}: {
  field: Field;
  startDate: Date;
  endDate: Date;
  groupFieldName?: string;
  filterValue?: string;
  dashboard?: Dashboards;
}) => {
  const filters = [
    filterForIsWithinCondition({
      field,
      startDate,
      endDate,
      reverse: false,
    }),
  ];

  if (groupFieldName !== "status") {
    if (groupFieldName === "department") {
      filters.push(
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ name: string; condition: strin... Remove this comment to see the full error message
        filterForIsCondition({
          name: "department",
          // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
          labels: [tableHeader[filterValue] || capitalize(filterValue)],
          // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          values: [filterValue],
        }),
      );
    } else if (groupFieldName) {
      filters.push(
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ name: string; condition: strin... Remove this comment to see the full error message
        filterForIsCondition({
          name: groupFieldName,
          // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
          labels: [tableHeader[filterValue] || capitalize(filterValue)],
          // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          values: [filterValue],
        }),
      );
    }
  }

  if (dashboard === Dashboards.TIME_TO_CLOSE) {
    filters.push(
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ name: string; condition: strin... Remove this comment to see the full error message
      filterForIsCondition({
        name: "status",
        labels: ["Closed"],
        values: ["closed"],
      }),
    );
  }

  return filters;
};

const createOverdueDateFilter = ({
  field,
  startDate,
  endDate,
  startDueDate,
  endDueDate,
}: {
  field: Field;
  startDate: Date;
  endDate: Date;
  startDueDate?: Date;
  endDueDate?: Date;
}) => {
  const filters = [
    filterForIsWithinCondition({
      field,
      startDate,
      endDate,
      reverse: false,
    }),
    filterForIsCondition({
      name: "status",
      labels: ["Open", "Re-opened"],
      values: ["open", "re_opened"],
    }),
  ];

  if (startDueDate && endDueDate) {
    filters.push(
      filterForIsWithinCondition({
        name: "due_date",
        startDate: startDueDate,
        endDate: endDueDate,
        reverse: true,
      }),
    );
  }

  return filters;
};

// @ts-expect-error TS(7006) FIXME: Parameter 'groupedByOrDashboardName' implicitly ha... Remove this comment to see the full error message
const getFieldName = (groupedByOrDashboardName, label) => {
  switch (groupedByOrDashboardName) {
    case "status":
      if (label === "closed") {
        return "last_closed_at";
      }
      if (label === "open") {
        return "created_at";
      }
      return "last_reopened_at";
    case "source":
    case "department":
    case "overdue":
      return "created_at";
    case "time_to_close":
      return "last_closed_at";
    default:
      return "created_at";
  }
};

export const createTileLink = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  workspaceID,
  // @ts-expect-error TS(7031) FIXME: Binding element 'label' implicitly has an 'any' ty... Remove this comment to see the full error message
  label,
  // @ts-expect-error TS(7031) FIXME: Binding element 'fields' implicitly has an 'any' t... Remove this comment to see the full error message
  fields,
  // @ts-expect-error TS(7031) FIXME: Binding element 'departments' implicitly has an 'a... Remove this comment to see the full error message
  departments,
  // @ts-expect-error TS(7031) FIXME: Binding element 'startDate' implicitly has an 'any... Remove this comment to see the full error message
  startDate,
  // @ts-expect-error TS(7031) FIXME: Binding element 'endDate' implicitly has an 'any' ... Remove this comment to see the full error message
  endDate,
  // @ts-expect-error TS(7031) FIXME: Binding element 'identifier' implicitly has an 'an... Remove this comment to see the full error message
  identifier,
  groupFieldName = "status",
  startDueDate = null,
  endDueDate = null,
  dashboard = null,
}) => {
  let url = "";
  const start = startDate ? startDate : defaultAllTimeStartDate;
  const end = endDate ? endDate : new Date();

  const fieldName = getFieldName(dashboard || groupFieldName, label);
  // @ts-expect-error TS(7006) FIXME: Parameter 'f' implicitly has an 'any' type.
  const field: Field = fields?.find((f) => f.name === fieldName);

  let filterValue = label;

  switch (groupFieldName) {
    case "department":
      filterValue = departments.find(
        // @ts-expect-error TS(7006) FIXME: Parameter 'department' implicitly has an 'any' typ... Remove this comment to see the full error message
        (department) => department.title === filterValue,
      )?.id;
      break;
    case "source":
      filterValue = filterValue.toLowerCase();
      break;
    default:
      filterValue = filterValue.toLowerCase();
      break;
  }

  const existingFilter = getTableFiltersParam();

  if (field) {
    const dateFilter =
      groupFieldName === "overdue"
        ? createOverdueDateFilter({
            field,
            startDate: start,
            endDate: end,
            // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'Date | unde... Remove this comment to see the full error message
            startDueDate,
            // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'Date | unde... Remove this comment to see the full error message
            endDueDate,
          })
        : createDateFilter({
            field,
            startDate: start,
            endDate: end,
            groupFieldName,
            filterValue,
            // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'Dashboards ... Remove this comment to see the full error message
            dashboard,
          });

    const newFilter = existingFilter
      ? [...dateFilter, ...existingFilter]
      : dateFilter;
    const filterToParam = `tableFilters=${encodeURIComponent(
      JSON.stringify(newFilter),
    )}`;

    switch (identifier) {
      case TopLevelModule.ISSUE_MANAGEMENT:
        url = `/workspaces/${workspaceID}${allIssuesUrl}`;
        break;
      default:
        break;
    }
    return `${url}?${filterToParam}`;
  }

  return url;
};

export const dateRangeStringToDateRange = (
  dateRangeString: any,
  intervalDisplayName: any,
  totalTimeRangeString: any,
) => {
  const [totalStartDate, totalEndDate] =
    timeRangeStringToDateRange(totalTimeRangeString);

  const interval = getInterval(
    intervalDisplayName,
    totalStartDate,
    totalEndDate,
  );

  let startDate;
  let endDate;

  switch (interval) {
    case "day":
      startDate = new Date(dateRangeString);
      endDate = new Date(dateRangeString);
      break;

    case "week":
      [startDate, endDate] = dateRangeString
        .split("-")
        // @ts-expect-error TS(7006) FIXME: Parameter 'dateString' implicitly has an 'any' typ... Remove this comment to see the full error message
        .map((dateString) => new Date(dateString));
      break;

    case "month":
      startDate = new Date(dateRangeString);
      endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
      break;

    case "quarter": {
      const [, qYear] = dateRangeString.split(" ");
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const quarterStartMonth = { Q1: 0, Q2: 3, Q3: 6, Q4: 9 }[
        dateRangeString.slice(0, 2)
      ];
      startDate = new Date(qYear, quarterStartMonth, 1);
      endDate = new Date(qYear, quarterStartMonth + 3, 0);
      break;
    }
    case "year":
      startDate = new Date(`01/01/${dateRangeString}`);
      endDate = new Date(`12/31/${dateRangeString}`);
      break;

    default:
      break;
  }

  if (totalStartDate > startDate) {
    startDate = totalStartDate;
  }
  if (totalEndDate < endDate) {
    endDate = totalEndDate;
  }

  return [startDate, endDate];
};

// @ts-expect-error TS(7006) FIXME: Parameter 'timeRangeString' implicitly has an 'any... Remove this comment to see the full error message
export const timeRangeStringToDateRange = (timeRangeString) => {
  if (timeRangeString === "All Time") {
    return [defaultAllTimeStartDate, new Date()];
  } else if (timeRangeString.includes("Custom")) {
    const startDateStr = timeRangeString.substring(
      8,
      timeRangeString.length - 14,
    );
    const endDateStr = timeRangeString.substring(
      21,
      timeRangeString.length - 1,
    );
    return [new Date(startDateStr), new Date(endDateStr)];
  }
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const numDays = TIME_RANGE_OPTIONS[timeRangeString];
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - numDays);
  const endDate = new Date();
  return [startDate, endDate];
};

// @ts-expect-error TS(7006) FIXME: Parameter 'intervalDisplayName' implicitly has an ... Remove this comment to see the full error message
export const getInterval = (intervalDisplayName, startDate, endDate) => {
  if (intervalDisplayName !== "Auto") {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return INTERVAL_OPTIONS[intervalDisplayName];
  }

  const timeDifference = Math.abs(
    (startDate || new Date(2020, 1, 1)) - endDate,
  );
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  if (daysDifference <= 7) {
    return "day";
  } else if (daysDifference <= 30) {
    return "week";
  } else if (daysDifference <= 548) {
    return "month";
  }
  return "year";
};

// @ts-expect-error TS(7006) FIXME: Parameter 'labels' implicitly has an 'any' type.
export const sortData = (labels, data) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'label' implicitly has an 'any' type.
  const objArray = labels.map((label, index) => ({
    label,
    data: data[index] || 0,
  }));

  // @ts-expect-error TS(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
  const sortedObjArray = objArray.sort((a, b) => {
    if (a.data > b.data) {
      return -1;
    }

    if (a.data === b.data) {
      return 0;
    }

    return 1;
  });

  // @ts-expect-error TS(7034) FIXME: Variable 'sortedLabel' implicitly has type 'any[]'... Remove this comment to see the full error message
  const sortedLabel = [];
  // @ts-expect-error TS(7034) FIXME: Variable 'sortedData' implicitly has type 'any[]' ... Remove this comment to see the full error message
  const sortedData = [];

  // @ts-expect-error TS(7006) FIXME: Parameter 'd' implicitly has an 'any' type.
  sortedObjArray.forEach((d) => {
    sortedLabel.push(d.label);
    sortedData.push(d.data);
  });

  return {
    // @ts-expect-error TS(7005) FIXME: Variable 'sortedLabel' implicitly has an 'any[]' t... Remove this comment to see the full error message
    labels: sortedLabel,
    // @ts-expect-error TS(7005) FIXME: Variable 'sortedData' implicitly has an 'any[]' ty... Remove this comment to see the full error message
    data: sortedData,
  };
};

// @ts-expect-error TS(7031) FIXME: Binding element 'labels' implicitly has an 'any' t... Remove this comment to see the full error message
export const moveNAPositionToLast = ({ labels, data }) => {
  const newLabels = cloneDeep(labels);
  const newData = cloneDeep(data);
  const naIndex = newLabels.indexOf("N/A");

  if (naIndex > -1) {
    const naLabel = newLabels.splice(naIndex, 1);
    const naData = newData.splice(naIndex, 1);

    newLabels.push(naLabel[0]);
    newData.push(naData[0]);
  }

  return {
    labels: newLabels,
    data: newData,
  };
};

// @ts-expect-error TS(7006) FIXME: Parameter 'fieldOptions' implicitly has an 'any' t... Remove this comment to see the full error message
export const mapHeader = (fieldOptions, imSourceGroupName, name) => {
  const options = fieldOptions?.[imSourceGroupName];

  // @ts-expect-error TS(7006) FIXME: Parameter 'f' implicitly has an 'any' type.
  const option = options?.find((f) => f.name === name);

  if (option) {
    return option.display_name === "Open" ? "New" : option.display_name;
  }
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return tableHeader[name] || startCase(camelCase(name)) || capitalize(name);
};

// converts <table> elements to objects formatted for the endpoint /api/report/export_to_excel
export const formJsonFromTable = (table: HTMLElement) => {
  const serializedTable: string = table.outerHTML;

  const tableParser = new CheerioTableParser();
  const [parsedTable] = tableParser.parse(serializedTable);

  const [columnHeaders] = parsedTable;
  const bodyRows: string[][] = parsedTable.slice(1, parsedTable.length - 1);
  const totalsRow: string[] = parsedTable[parsedTable.length - 1];

  const obj = {};

  for (let column = 1; column < columnHeaders.length; column++) {
    const currColumnHeader = columnHeaders[column];
    const data = {};

    for (let row = 0; row < bodyRows.length; row++) {
      const [currRowHeader] = bodyRows[row];
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      data[`${currRowHeader}`] = bodyRows[row][column];
    }

    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    obj[currColumnHeader] = { data };
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    // eslint-disable-next-line dot-notation
    obj[currColumnHeader]["total"] = totalsRow[column];
  }
  return obj;
};
