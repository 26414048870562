import { type ThemeOptions } from "@mui/material";

export const link: ThemeOptions["components"] = {
  MuiLink: {
    defaultProps: {
      underline: "none",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.secondary,
        "&:hover": {
          textDecoration: "none",
          color: theme.palette.text.primary,
        },
      }),
    },
  },
};
