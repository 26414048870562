import React from "react";
import { PiArchiveBold } from "react-icons/pi";

import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";

import { FilterOperand } from "../../../hooks/use-filter-sort/types";
import TasksTable from "../components/table/TasksTable";
import { archivedTaskTableColumnKeys } from "../config/myTasksTableConfig";
import { myTaskRoutes } from "../routes";

export default function Archived() {
  return (
    <PageLayout>
      <Header
        backButtonLinkTo={myTaskRoutes.base}
        title={
          <div className="tw-flex tw-items-center tw-gap-2">
            <PiArchiveBold />
            Archived Tasks
          </div>
        }
      />
      <PageContent>
        <TasksTable
          initialFilters={{
            archived_at: { [FilterOperand.PRESENT]: "true" },
          }}
          displayedColumnKeys={archivedTaskTableColumnKeys}
          isEditable={false}
        />
      </PageContent>
    </PageLayout>
  );
}
