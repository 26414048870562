import { Label, useToast } from "@themis/ui";
import React from "react";
import { useIntl } from "react-intl";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import type { CreateAccountMutationRequest } from "@/api";
import { useAccount, useCreateAccount } from "@/api/queries/accounts/accounts";
import { useCompany } from "@/api/queries/companies";
import { useLayoutFields } from "@/api/utils/fields/use-layout-fields";
import { InfoPreviewSection } from "@/components/InfoPreviewSection";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import { AccountInfoForm } from "../../components/AccountInfo/account-info-form/AccountInfoForm";
import type { AccountInfoSchema } from "../../components/AccountInfo/account-info-form/use-validation-schema";
import { ActiveTag } from "../../components/ActiveTag";
import { routes } from "../../routes";
import type { AccountLocationState } from "../AccountDetails";

function FormContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-min-w-96 tw-flex-col tw-gap-8 tw-px-32">
      {children}
    </div>
  );
}

function StatusContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-bg-neutral-50 tw-px-2.5 tw-py-1.5">
      {children}
    </div>
  );
}

function AccountInfoContainer({ children }: { children: React.ReactNode }) {
  return <div className="tw-flex tw-flex-col tw-gap-2">{children}</div>;
}

export function NewChildAccountInfo() {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const history = useHistory();
  const location = useLocation<AccountLocationState>();
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const workspaceId = useRouteWorkspaceId();

  const { data: company } = useCompany("current");

  const companyId = Number(company?.data.company.id);

  const { customFields } = useLayoutFields({
    companyId,
    recordType: "accounts",
    workspaceId,
    layoutType: "detail",
  });

  const { data: parentAccountData } = useAccount({
    workspaceId,
    accountId: Number(accountId),
  });

  const parentAccount = parentAccountData?.data;

  const { mutateAsync: createAccount } = useCreateAccount({
    workspaceId,
  });

  /**
   * Redirect to the previous page (account details page or child accounts list page)
   * after saving the child account.
   * If there is no previous page, redirect to the account details page.
   */
  const locationAfterSave =
    location.state?.from ||
    generatePath(routes.accounts.detail.subAccounts.overview, {
      workspace_id: workspaceId,
      accountId,
    });

  const handleCreateChildAccount = async (
    newAccountData: CreateAccountMutationRequest,
  ) => {
    try {
      newAccountData.account.parent_account_id = Number(accountId);
      await createAccount(newAccountData);

      history.push(locationAfterSave);

      toast({
        content: `New Sub Account "${newAccountData.account.name}" has been added!`,
        variant: "success",
      });
    } catch {
      toast({
        content: "Something went wrong. Could not create sub account.",
        variant: "error",
      });
    }
  };

  const handleSubmitForm = (values: AccountInfoSchema) => {
    const newAccountData = {
      ...values,
      logo: values.logo?.signedId ?? undefined,
      custom_fields: {},
    };

    handleCreateChildAccount({ account: newAccountData });
  };

  return (
    <FormContainer>
      <div className="tw-flex tw-flex-row tw-gap-2">
        <StatusContainer>
          <Label className="tw-font-semibold tw-text-neutral-500">Status</Label>
          <ActiveTag isActive={parentAccount?.status === "active"} />
        </StatusContainer>
        <StatusContainer>
          <Label className="tw-font-semibold tw-text-neutral-500">
            {formatMessage({ defaultMessage: "Parent Account" })}
          </Label>
          <p className="tw-text-neutral-300">{parentAccount?.name || ""}</p>
        </StatusContainer>
      </div>
      <AccountInfoContainer>
        <h2 className="tw-text-lg tw-font-semibold tw-text-neutral-500">
          {formatMessage({ defaultMessage: "Account Info" })}
        </h2>

        {customFields && (
          <AccountInfoForm
            onSubmit={handleSubmitForm}
            customFields={customFields}
          />
        )}
      </AccountInfoContainer>
      <InfoPreviewSection title="Contacts" disabled />
      <InfoPreviewSection title="Contracts" disabled />
      <InfoPreviewSection
        title="Sub Accounts"
        disabled
        countDescription="Account"
      />
      <InfoPreviewSection title="Documents" disabled />
    </FormContainer>
  );
}
