import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import React from "react";
import { twMerge } from "tailwind-merge";

const TooltipProvider = TooltipPrimitive.Provider;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger>
>(({ children, ...props }, ref) => (
  <TooltipPrimitive.Trigger ref={ref} {...props} className="tw-font-sans">
    {children}
  </TooltipPrimitive.Trigger>
));
TooltipTrigger.displayName = TooltipPrimitive.Trigger.displayName;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content> & {
    disableArrow?: boolean;
  }
>(({ className, sideOffset = 4, disableArrow, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    role="tooltip"
    className={twMerge(
      "tw-z-[1500] tw-overflow-hidden tw-rounded-md tw-bg-neutral-500 tw-px-6 tw-py-4 tw-font-sans tw-text-sm tw-text-neutral-25 tw-shadow-dropdown tw-animate-in tw-fade-in-0 tw-zoom-in-95 data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=closed]:tw-zoom-out-95 data-[side=bottom]:tw-slide-in-from-top-2 data-[side=left]:tw-slide-in-from-right-2 data-[side=right]:tw-slide-in-from-left-2 data-[side=top]:tw-slide-in-from-bottom-2",
      className,
    )}
    {...props}
  >
    {props.children}
    {!disableArrow && (
      <TooltipPrimitive.Arrow className="tw-fill-neutral-500" />
    )}
  </TooltipPrimitive.Content>
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
