import { useQueryClient } from "@tanstack/react-query";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import plusIcon from "../../../images/table-image/icon/plus.svg";
import { DropdownOptions } from "../../shared/DropdownOptions";
import { WorkspaceNavigationElements } from "./WorkspaceNavigationElements";

function WorkspaceNavigation() {
  // Import MobX stores
  const mainStore = useMainStore();
  const queryClient = useQueryClient();

  // Variables
  const { active_workspace: activeWorkspace, roles } = mainStore.users.user;
  const history = useHistory();

  function canAddWorkspace() {
    if (!activeWorkspace?.is_internal) {
      return;
    }

    return roles?.some(
      (role) =>
        role.permission_set.data?.company?.actions?.add_remove_workspaces?.state
          .edit || role.permission_set.data?.company?.all?.state.edit,
    );
  }

  // Functions
  async function switchWorkspace(event: React.MouseEvent, workspaceID: number) {
    event.preventDefault();
    const workspace = mainStore.workspaces.list.find(
      (ws) => ws.id === Number(workspaceID),
    );

    queryClient.clear();

    history.push(`/workspaces/${workspaceID}/home`);
    // @ts-expect-error TS(2345)
    await mainStore.workspaces.changeToNewWorkspace(workspace);
  }

  const workspaces = mainStore.workspaces.list.filter(
    (workspace) =>
      !workspace.is_archived || workspace.id === activeWorkspace?.id,
  );

  useEffect(() => {
    if (workspaces.length === 0) {
      mainStore.workspaces.index();
    }
  }, []);

  return (
    <div className="workspace-navigation-list">
      <WorkspaceNavigationElements
        isInternal
        workspaces={workspaces}
        switchWorkspace={switchWorkspace}
      />
      <div className="workspace-navigation-divider" />
      <WorkspaceNavigationElements
        workspaces={workspaces}
        switchWorkspace={switchWorkspace}
      />
      {canAddWorkspace() && (
        <Popup
          trigger={
            <div
              className="add-new-workspace"
              data-testid="add-new-workspace-button"
            >
              <img src={plusIcon} alt="plus-icon" />
            </div>
          }
          on="click"
          position="right top"
          arrow={false}
        >
          <DropdownOptions
            options={[
              { label: "Create New Workspace", destination: "/new-workspace" },
              {
                label: "Duplicate From Existing Workspace",
                destination: "/duplicate-workspace",
              },
            ]}
          />
        </Popup>
      )}
    </div>
  );
}

export default observer(WorkspaceNavigation);
