import "react-dropdown/style.css";

import { IconButton, Pagination, Select, Table } from "@themis/ui";
import { Avatar } from "@themis/ui-library/components/data-display/avatar/avatar";
import type { ColDef } from "ag-grid-community";
import { observer } from "mobx-react";
import { useState } from "react";
import { PiXBold } from "react-icons/pi";
import { useLocation, useParams } from "react-router-dom";

import type { Audit, User } from "@/api/gen";
import { PAGE_SIZE, useAuditTrail } from "@/api/queries/auditTrail";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import { useMainStore } from "@/contexts/Store";

interface Props {
  isRecordView: boolean;
}

export const AUDIT_TRAIL_COLUMNS_TABLE: ColDef<Audit>[] = [
  {
    field: "record_name",
    headerName: "Record Name",
    width: 200,
    type: "string",
    cellRenderer: "textRenderCell",
    sortable: false,
  },
  {
    field: "action",
    headerName: "Action",
    width: 200,
    type: "string",
    cellRenderer: "textRenderCell",
    sortable: false,
  },
  {
    field: "user",
    headerName: "User",
    width: 200,
    cellRenderer: (props: { data: Audit }) => {
      const user = props.data.user as User;
      return user ? (
        <div className="tw-mx-2 tw-flex tw-items-center tw-space-x-2 tw-font-medium tw-text-neutral-300">
          <Avatar colorSeed={user.icon_color_index}>{user.initials}</Avatar>
          <span>{user?.full_name}</span>
        </div>
      ) : (
        <div className="tw-px-2 tw-font-sans tw-font-medium tw-text-neutral-500 read-only:tw-text-neutral-300">
          System
        </div>
      );
    },
    sortable: false,
  },
  {
    field: "description",
    headerName: "Activity",
    width: 400,
    type: "string",
    cellRenderer: "textRenderCell",
    sortable: false,
  },
  {
    field: "created_at",
    headerName: "Time",
    cellRenderer: "textRenderCell",
    valueFormatter: (params: { value: string }) =>
      new Date(params.value).toLocaleString(),
    width: 200,
    sortable: false,
  },
];

export const ACTIONS = ["create", "update", "destroy"];

function AuditTrail({ isRecordView }: Props) {
  // Import Mobx stores
  const mainStore = useMainStore();
  const companyID = mainStore.context.companyID!;

  // State
  const [currentAction, setCurrentAction] = useState<string | undefined>(
    undefined,
  );
  const [page, setPage] = useState(1);

  const location = useLocation<{ from: string }>();

  const { themis_record_type: recordType, instance_id } = useParams<{
    themis_record_type: string;
    instance_id: string;
  }>();
  const recordID = Number(instance_id);

  const { data } = useAuditTrail({
    isRecordView,
    companyID,
    recordType,
    recordID,
    page,
    currentAction,
  });

  const audits = data?.data || [];
  const totalCount = data?.meta?.total_count || 0;

  return (
    <PageLayout>
      <Header
        title={isRecordView ? "Record Audit Trail" : "Module Audit Trail"}
        backButtonLinkTo={location.state?.from || "/"}
      />
      <PageContent>
        <div className="tw-mb-5 tw-flex tw-items-center">
          <Select
            className="tw-w-48"
            size="sm"
            placeholder="Filter by action"
            items={ACTIONS.map((action) => ({ value: action, label: action }))}
            selected={currentAction}
            onSelect={(value) => {
              setCurrentAction(value);
            }}
            renderSelected={() => currentAction}
          />
          <IconButton
            onClick={() => setCurrentAction(undefined)}
            color="primary"
            Icon={PiXBold}
          />
        </div>
        <Table columns={AUDIT_TRAIL_COLUMNS_TABLE} rows={audits} />
        <div className="tw-mt-5 tw-flex tw-justify-end">
          <Pagination
            page={page}
            totalPages={Math.ceil(totalCount / PAGE_SIZE)}
            onPageChange={(newPage) => setPage(newPage)}
          />
        </div>
      </PageContent>
    </PageLayout>
  );
}

AuditTrail.defaultProps = {
  isRecordView: false,
};

export default observer(AuditTrail);
