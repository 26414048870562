import type {
  FieldConfiguration,
  FieldConfigurationFieldType,
  ThemisRecord,
} from "@/api";
import type { FieldConfigurationWithLayout } from "@/api/utils/fields/field-configuration-with-layout";

import { getReferenceDefaultValue } from "./get-reference-default-value/get-reference-default-value";

const getStringDefaultValue = (value: unknown, field: FieldConfiguration) => {
  if (field.multiselect) {
    return value ?? [];
  }
  return value ?? "";
};

const getNumberDefaultValue = (value: unknown, field: FieldConfiguration) => {
  if (field.multiselect) {
    return value ?? [];
  }
  return value ?? null;
};

const getters: Record<
  FieldConfigurationFieldType,
  (value: unknown, config: FieldConfiguration) => unknown
> = {
  date: getStringDefaultValue,
  datetime: getStringDefaultValue,
  reference: getReferenceDefaultValue,
  select: getStringDefaultValue,
  section: getStringDefaultValue,
  status: getStringDefaultValue,
  text: getStringDefaultValue,
  textarea: getStringDefaultValue,
  decimal: getNumberDefaultValue,
  numeric: getNumberDefaultValue,
  reviews: () => null,
  computed: () => null,
};

export function getDynamicDefaultFormValues(
  fieldConfig: FieldConfigurationWithLayout[],
  customFields: ThemisRecord["custom_fields"] = {},
) {
  return fieldConfig.reduce<Record<string, unknown>>((acc, { field }) => {
    const customFieldValue = customFields[field.path];

    const getDefaultValue = getters[field.field_type];

    const defaultValue = getDefaultValue(customFieldValue, field);

    acc[field.path] = defaultValue;

    return acc;
  }, {});
}
