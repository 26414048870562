import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@themis/ui";
import type { ConfirmationDialogProps } from "@themis/ui-library/components/feedback/dialog/confirmation-dialog/confirmation-dialog";
import { ConfirmationDialog } from "@themis/ui-library/components/feedback/dialog/confirmation-dialog/confirmation-dialog";
import { ControlledTextField } from "@themis/ui-library/components/form/controlled-text-field/controlled-text-field";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { z } from "zod";

import type { FieldOption, RecordTypePath } from "@/api";
import { useUpdateFieldOptions } from "@/api/queries/fields/use-update-field-options";

interface SectionsManagementDialogProps {
  fieldId: number | undefined;
  open: ConfirmationDialogProps["open"];
  onClose: () => void;
  recordType: RecordTypePath;
  sections: FieldOption[];
}

const getDefaultValues = (sections: FieldOption[]) => {
  return sections.reduce<Record<string, string>>((acc, section) => {
    acc[section.id] = section.value;
    return acc;
  }, {});
};

const createSchema = (sections: FieldOption[]) => {
  const schemaObject = sections.reduce<Record<string, z.ZodString>>(
    (acc, section) => {
      acc[section.id] = z.string().min(1, "Section name cannot be empty");
      return acc;
    },
    {},
  );
  return z.object(schemaObject);
};

export function SectionsManagementDialog({
  fieldId,
  open,
  onClose,
  recordType,
  sections,
  ...rest
}: SectionsManagementDialogProps) {
  const { formatMessage } = useIntl();

  const toast = useToast();

  const defaultValues = getDefaultValues(sections);
  const schema = createSchema(sections);

  const {
    mutate: updateFieldOptions,
    isPending,
    reset,
  } = useUpdateFieldOptions({
    recordType,
  });

  const form = useForm<Record<string, string>>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const handleSave = form.handleSubmit((data) => {
    if (!fieldId) {
      throw new Error("Field ID is required");
    }

    const changedValues = Object.entries(data)
      .filter(([id, value]) => value !== defaultValues[id])
      .map(([id, value]) => ({
        fieldId,
        id: Number.parseInt(id),
        data: {
          option: {
            value,
          },
        },
      }));

    updateFieldOptions(changedValues, {
      onSuccess: () => {
        toast({
          variant: "success",
          content: formatMessage({
            defaultMessage: "Sections have been updated.",
          }),
        });
        reset();
        onClose();
      },
      onError: () => {
        toast({
          variant: "error",
          content: formatMessage({
            defaultMessage: "Sections could not be updated.",
          }),
        });
      },
    });
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  return (
    <FormProvider {...form}>
      <ConfirmationDialog
        open={open}
        onClose={handleClose}
        title={formatMessage({ defaultMessage: "Manage Sections" })}
        slotProps={{
          confirm: {
            loading: isPending,
            children: formatMessage({ defaultMessage: "Save" }),
            disabled: !form.formState.isDirty,
          },
          cancel: {
            disabled: isPending,
            children: formatMessage({ defaultMessage: "Close" }),
          },
        }}
        onConfirm={handleSave}
        {...rest}
      >
        <Stack spacing={2}>
          {sections.map((section) => (
            <ControlledTextField
              key={section.id}
              control={form.control}
              name={String(section.id)}
            />
          ))}
        </Stack>
      </ConfirmationDialog>
    </FormProvider>
  );
}
