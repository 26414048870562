import type { TypographyProps } from "@mui/material";
import { Icon, Stack, Typography } from "@mui/material";

export interface RequiredLabelProps extends TypographyProps {
  required?: boolean;
}

const REQUIRED_CHAR = "*";

export function RequiredLabel({
  required,
  children,
  component = "span",
  fontSize = "inherit",
  fontWeight = "inherit",
  color = "text.secondary",
  ...rest
}: RequiredLabelProps) {
  const label =
    typeof children === "string" ? (
      <Typography
        component={component}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={color}
        {...rest}
      >
        {children}
      </Typography>
    ) : (
      children
    );

  return (
    <Stack direction="row" spacing={0} alignItems="flex-start">
      {label}

      {required && (
        <Icon color="error" fontSize="inherit">
          {REQUIRED_CHAR}
        </Icon>
      )}
    </Stack>
  );
}
