import { useParams } from "react-router-dom";

export function useRouteAccountId(): number | undefined {
  const params = useParams<{ accountId: string }>();

  if (params.accountId === "new") {
    return;
  }

  const accountId = Number.parseInt(params.accountId);

  if (isNaN(accountId)) {
    throw new Error("Account id not found in route");
  }

  return accountId;
}
