import type { VariantProps } from "cva";
import { cva } from "cva";
import React from "react";

const stackVariants = cva({
  base: ["tw-flex"],
  variants: {
    direction: {
      row: "tw-flex-row",
      column: "tw-flex-col",
    },
    spacing: {
      "0": "tw-gap-0",
      "0.5": "tw-gap-1",
      "1": "tw-gap-2",
      "2": "tw-gap-4",
      "3": "tw-gap-6",
      "4": "tw-gap-8",
      "5": "tw-gap-10",
      "6": "tw-gap-12",
    },
    wrap: {
      true: "tw-flex-wrap",
      false: "tw-flex-nowrap",
    },
  },
  defaultVariants: {
    direction: "column",
    spacing: "1",
    wrap: false,
  },
});

export interface StackProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof stackVariants> {}

/**
 * @deprecated Use Stack from "@themis/ui-library/stack"
 */
export function Stack({
  className,
  children,
  direction = "column",
  spacing = "1",
  wrap,
  ...rest
}: StackProps) {
  return (
    <div
      className={stackVariants({ direction, spacing, className, wrap })}
      {...rest}
    >
      {children}
    </div>
  );
}
