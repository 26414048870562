import { faker } from "@faker-js/faker";

import type { MonitoringAsset } from "../models/MonitoringAsset";
import { createFile } from "./createFile";
import { createMonitoringFinding } from "./createMonitoringFinding";
import { createReviewableProperties } from "./createReviewableProperties";
import { createThemisRecord } from "./createThemisRecord";

export function createMonitoringAsset(
  data?: NonNullable<Partial<MonitoringAsset>>,
): NonNullable<MonitoringAsset> {
  return Object.assign({}, createThemisRecord(), createReviewableProperties(), {
    group_id: faker.number.float(),
    url: faker.string.alpha(),
    status: faker.helpers.arrayElement<any>([
      "no_results",
      "in_review",
      "action_required",
      "completed",
    ]),
    asset_type: faker.string.alpha(),
    policies: faker.helpers.arrayElements([faker.string.alpha()]) as any,
    attachments: faker.helpers.arrayElements([createFile()]) as any,
    total_findings_count: faker.number.int(),
    resolved_findings_count: faker.number.int(),
    monitoring_findings: faker.helpers.arrayElements([
      createMonitoringFinding(),
    ]) as any,
  });
}
