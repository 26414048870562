import type { RecordTypePath } from "../../api/gen/models/RecordTypePath";
import type { RecordTypes } from "../../api/gen/models/RecordTypes";

export function getRecordTypePath(recordType: RecordTypes): RecordTypePath {
  const recordTypeToPathMap: Record<RecordTypes, RecordTypePath> = {
    Account: "accounts",
    Finding: "findings",
    ModuleAssessment: "module_assessments",
    MonitoringGroup: "monitoring_groups",
    MonitoringGroupAsset: "monitoring_assets",
    MonitoringGroupAssetChild: "monitoring_findings",
    MonitoringGroupAssetChildResult: "monitoring_findings",
    MonitoringAsset: "monitoring_assets",
    MonitoringFinding: "monitoring_findings",
    Project: "projects",
    Task: "tasks",
    Training: "trainings",
    Record: "records",
    Attestation: "attestations",
    Contract: "records",
    Document: "records",
  };

  const path = recordTypeToPathMap[recordType];
  if (!path) {
    throw new Error(`No path mapping found for record type: ${recordType}`);
  }

  return path;
}
