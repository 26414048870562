import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdatePublicFormLayoutMutationRequest,
  UpdatePublicFormLayoutMutationResponse,
  UpdatePublicFormLayoutPathParams,
} from "../../models/UpdatePublicFormLayout";

/**
 * @description Update public form layout
 * @summary Update public form layout
 * @link /module_workspaces/:module_workspace_id/public_form_layout
 */
export async function updatePublicFormLayout(
  moduleWorkspaceId: UpdatePublicFormLayoutPathParams["module_workspace_id"],
  data?: UpdatePublicFormLayoutMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdatePublicFormLayoutMutationResponse>["data"]> {
  const res = await client<
    UpdatePublicFormLayoutMutationResponse,
    UpdatePublicFormLayoutMutationRequest
  >({
    method: "put",
    url: `/module_workspaces/${moduleWorkspaceId}/public_form_layout`,
    data,
    ...options,
  });
  return res.data;
}
