import dayjs from "dayjs";

import BrexJsonData from "../../components/finra.json";
import {
  formatDateForCellValue,
  stringToDate,
  stringToTimestamp,
} from "../../components/helpers/DateFormatters";
import {
  INHERENT_MATRIX_TITLE,
  RESIDUAL_MATRIX_TITLE,
} from "../../components/table/risk-register/Constants";
import type { MainStore } from "../Main";

export default class AvroSchemasStore {
  mainStore: MainStore;

  constructor(mainStore: MainStore) {
    this.mainStore = mainStore;
  }

  // =========================================
  // ================ Helpers ================
  // =========================================

  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  serializeValue(fieldName, value, fieldsToUse = null) {
    const field = (fieldsToUse || this.mainStore.fields.list).find(
      (elem) => elem.name === fieldName,
    );
    if (!field) {
      return null;
    }

    const fieldType = field.data_type;

    switch (fieldType) {
      case "com.askthemis.types.v1.finra_product_code":
      case "com.askthemis.types.v1.finra_problem_code":
        return { code: value };

      case "com.askthemis.types.v1.finra_brex_category":
      case "com.askthemis.types.v1.finra_brex_sub_category":
        return { id: value };

      case "com.askthemis.types.v1.date":
        return { value: formatDateForCellValue(value) };

      case "com.askthemis.types.v1.finra_dispute_amount":
        return { option: value.option, dollar_amount: value.dollarAmount };

      case "com.askthemis.types.v1.integer":
      case "com.askthemis.types.v1.text":
      case "com.askthemis.types.v1.long_text":
      case "com.askthemis.types.v1.multiline_text":
      case "com.askthemis.types.v1.number":
        return { value };
      case "com.askthemis.types.v1.float":
        return { value: parseFloat(value) };
      case "com.askthemis.types.v1.link":
        return { url: value };

      case "com.askthemis.types.v1.checkbox":
        return { checked: Boolean(value) };

      case "com.askthemis.types.v1.constrained_option":
      case "com.askthemis.types.v1.option":
      case "com.askthemis.types.v1.themis_module":
        return { options: value };

      case "com.askthemis.types.v1.checklist":
        return null;

      case "com.askthemis.types.v1.action_plans":
        return null;

      case "com.askthemis.types.v1.operational_controls":
      case "com.askthemis.types.v1.related_risks":
        return null;

      case "com.askthemis.types.v1.computed":
        return null; // read only, can't be set

      case "com.askthemis.types.v1.record_policy":
      case "com.askthemis.types.v1.record_procedure":
      case "com.askthemis.types.v1.review":
      case "com.askthemis.types.v1.tag_department":
      case "com.askthemis.types.v1.tag_product":
      case "com.askthemis.types.v1.tag":
      case "com.askthemis.types.v1.tag_risk_type":
      case "com.askthemis.types.v1.tag_main_category":
      case "com.askthemis.types.v1.tag_control_mapping_type":
      case "com.askthemis.types.v1.tag_control_category":
      case "com.askthemis.types.v1.tag_user_contact":
      case "com.askthemis.types.v1.tag_user":
        return { ids: value };

      case "com.askthemis.types.v1.tag_verifier":
        return null; // read only, can be set or updated only by backend

      case "com.askthemis.types.v1.attachment":
        return null;

      case "com.askthemis.types.v1.control_effectiveness_rating":
        return { value: value.value, title: value.title, color: value.color };

      default:
        window.console.warn(`WARNING: implement serializer for ${fieldType}!`);
        return null;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  valueForField(fieldName, data, fieldsToUse = null) {
    let fields = this.mainStore.fields.list;
    if (fieldsToUse && Array.isArray(fieldsToUse)) {
      fields = fieldsToUse;
    }

    // Normalize inputs
    if (!fieldName) {
      return null;
    }
    if (!data) {
      return null;
    }

    const field = fields.find((elem) => elem.name === fieldName);

    if (!field) {
      return null;
    }

    const fieldType = field.data_type;
    const isDefaultField = field.is_default_field;
    const dataValue = data[fieldName];

    switch (fieldType) {
      case "com.askthemis.types.v1.date":
        return isDefaultField
          ? stringToTimestamp(dataValue?.value)
          : stringToDate(dataValue?.value);

      case "com.askthemis.types.v1.finra_dispute_amount":
        return dataValue || {};

      case "com.askthemis.types.v1.integer":
      case "com.askthemis.types.v1.text":
      case "com.askthemis.types.v1.long_text":
      case "com.askthemis.types.v1.multiline_text":
      case "com.askthemis.types.v1.number":
        return dataValue?.value;
      case "com.askthemis.types.v1.float":
        return dataValue?.value || dataValue?.value === 0
          ? parseFloat(dataValue?.value)
          : null;
      case "com.askthemis.types.v1.link":
        return dataValue?.url;

      case "com.askthemis.types.v1.checkbox":
        return Boolean(dataValue?.checked);

      case "com.askthemis.types.v1.constrained_option":
      case "com.askthemis.types.v1.option":
      case "com.askthemis.types.v1.themis_module":
        return dataValue?.options || [];

      case "com.askthemis.types.v1.finra_product_code":
      case "com.askthemis.types.v1.finra_problem_code":
        return dataValue?.code;

      case "com.askthemis.types.v1.finra_brex_category":
      case "com.askthemis.types.v1.finra_brex_sub_category":
        return dataValue?.id;

      case "com.askthemis.types.v1.record_policy":
      case "com.askthemis.types.v1.record_procedure":
      case "com.askthemis.types.v1.review":
      case "com.askthemis.types.v1.tag_department":
      case "com.askthemis.types.v1.tag_product":
      case "com.askthemis.types.v1.tag":
      case "com.askthemis.types.v1.tag_risk_type":
      case "com.askthemis.types.v1.tag_main_category":
      case "com.askthemis.types.v1.tag_control_mapping_type":
      case "com.askthemis.types.v1.tag_control_category":
      case "com.askthemis.types.v1.tag_user_contact":
      case "com.askthemis.types.v1.tag_user":
        return dataValue?.ids || [];

      case "com.askthemis.types.v1.tag_verifier":
      case "com.askthemis.types.v1.control_effectiveness_rating":
        return dataValue || {};

      case "com.askthemis.types.v1.attachment":
        return null; // Nothing to return, data is shipped in a form of AttachmentGroups

      case "com.askthemis.types.v1.checklist":
        return null; // Nothing to return, data is present on RecordVersions objects

      case "com.askthemis.types.v1.action_plans":
        return null; // Nothing to return, data is present on RecordVersions objects

      case "com.askthemis.types.v1.operational_controls":
      case "com.askthemis.types.v1.related_risks":
        return null; // Nothing to return, data is present on Record objects

      case "com.askthemis.types.v1.computed":
        return null; // Nothing to return, data is calculated separately

      case "com.askthemis.types.v1.module_controls":
        return null;

      default:
        window.console.warn(
          `WARNING: valueForField not implemented for ${fieldType}!`,
        );
    }

    return null;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldType' implicitly has an 'any' type... Remove this comment to see the full error message
  getAvailableOptionsForFieldType(fieldType, fieldName) {
    switch (fieldType) {
      case "com.askthemis.types.v1.tag_risk_type":
        return this.mainStore.riskTypes.riskTypes.map((item) => ({
          label: item.title,
          value: item.id,
        }));
      case "com.askthemis.types.v1.tag_main_category":
        return this.mainStore.mainCategories.mainCategories.map((item) => ({
          // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'.
          label: item.title,
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          value: item.id,
        }));
      case "com.askthemis.types.v1.tag_department":
        return this.mainStore.departments.departments.map((item) => ({
          label: item.title,
          value: item.id,
        }));
      case "com.askthemis.types.v1.tag_user":
      case "com.askthemis.types.v1.tag_user_contact":
      case "com.askthemis.types.v1.tag_verifier":
      case "com.askthemis.types.v1.review":
        return this.mainStore.contacts.list
          .concat(this.mainStore.users.allUsers)
          .map((item) => ({ label: item.full_name, value: item.id }));
      case "com.askthemis.types.v1.attachment":
        return [{ label: "Attachments", value: "attachments" }];
      case "com.askthemis.types.v1.option":
      case "com.askthemis.types.v1.constrained_option":
        if (fieldName === "impact") {
          const matrix =
            this.mainStore.riskRegisters.data?.scoring_matrices?.find(
              (item) => item.name === INHERENT_MATRIX_TITLE,
            );
          const options = matrix?.scoring_matrix_rows?.slice()?.reverse();

          return options?.map((option, index) => ({
            label: option.title,
            value: index + 1,
          }));
        }

        if (fieldName === "likelihood") {
          const matrix =
            this.mainStore.riskRegisters.data?.scoring_matrices?.find(
              (item) => item.name === INHERENT_MATRIX_TITLE,
            );
          const options = matrix?.scoring_matrix_columns;

          return options?.map((option, index) => ({
            label: option.title,
            value: index + 1,
          }));
        }

        if (fieldName === "control_rating") {
          const matrix =
            this.mainStore.riskRegisters.data?.scoring_matrices?.find(
              (item) => item.name === RESIDUAL_MATRIX_TITLE,
            );
          const options = matrix?.scoring_matrix_columns || [];

          return options?.map((option, index) => ({
            label: option.title,
            value: index + 1,
          }));
        }

        return this.mainStore.fieldOptions
          .filteredOptionsForField(fieldName)
          ?.map((item) => ({ label: item.display_name, value: item.name }));
      case "com.askthemis.types.v1.tag_control_category":
        return this.mainStore.controlCategories.controlCategories.map(
          // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'.
          (item) => ({ label: item.title, value: item.id }),
        );
      case "com.askthemis.types.v1.tag_control_mapping_type":
        return this.mainStore.controlMappingTypes.controlMappingTypes.map(
          // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'.
          (item) => ({ label: item.title, value: item.id }),
        );
      case "com.askthemis.types.v1.date": {
        const date = new Date();

        return [
          {
            label: "1 week",
            value: dayjs(date).startOf("day").subtract(1, "weeks"),
          },
          {
            label: "1 month",
            value: dayjs(date).startOf("day").subtract(1, "months"),
          },
          {
            label: "3 months",
            value: dayjs(date).startOf("day").subtract(3, "months"),
          },
          {
            label: "6 months",
            value: dayjs(date).startOf("day").subtract(6, "months"),
          },
        ];
      }
      case "com.askthemis.types.v1.tag_product":
        return this.mainStore.products.products.map((item) => ({
          // @ts-expect-error TS(2339) FIXME: Property 'title' does not exist on type 'never'.
          label: item.title,
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          value: item.id,
        }));
      case "com.askthemis.types.v1.tag":
        return this.mainStore.tags.tags.map((item) => ({
          label: item.title,
          value: item.id,
        }));
      case "com.askthemis.types.v1.record_policy":
        return this.mainStore.records.policies.map((item) => ({
          // @ts-expect-error TS(2339) FIXME: Property 'meta' does not exist on type 'never'.
          label: item.meta?.name,
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          value: item.id,
        }));
      case "com.askthemis.types.v1.record_procedure":
        return this.mainStore.records.procedures.map((item) => ({
          // @ts-expect-error TS(2339) FIXME: Property 'meta' does not exist on type 'never'.
          label: item.meta?.name,
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          value: item.id,
        }));
      case "com.askthemis.types.v1.finra_brex_category":
        return BrexJsonData.brexCategories.map((item) => ({
          label: item.title,
          value: item.id,
        }));
      case "com.askthemis.types.v1.finra_brex_sub_category":
        return BrexJsonData.brexSubCategories.map((item) => ({
          label: item.title,
          value: item.id,
        }));
      case "com.askthemis.types.v1.finra_dispute_amount": {
        return this.mainStore.fieldOptions
          .optionsForField(fieldName)
          ?.filter((item) => item.display_name !== "dollar_amount")
          ?.map((item) => ({
            label: item.display_name,
            value: { option: item.name },
          }));
      }
      case "com.askthemis.types.v1.finra_product_code": {
        return BrexJsonData.productCodes.map((item) => ({
          label:
            item?.code === -1 ? item?.title : `${item.code} - ${item?.title}`,
          value: item.id - 1,
        }));
      }
      case "com.askthemis.types.v1.finra_problem_code": {
        return BrexJsonData.problemCodes.map((item) => ({
          label:
            item?.code === -1 ? item?.title : `${item.code} - ${item?.title}`,
          value: item.id - 1,
        }));
      }
      case "com.askthemis.types.v1.control_effectiveness_rating": {
        return [];
      }
      default:
        return [];
    }
  }

  // When you just need the first ID or options - you can use this method
  // If value for field is not array - you will receive null
  // @ts-expect-error TS(7006) FIXME: Parameter 'data' implicitly has an 'any' type.
  firstValueForField(fieldName: string, data, fieldsToUse = null) {
    const potentialArray = this.valueForField(fieldName, data, fieldsToUse);

    if (Array.isArray(potentialArray) && potentialArray.length > 0) {
      return potentialArray[0];
    }

    return null;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  displayNameForField(fieldName) {
    const field = this.mainStore.fields.list.find(
      (elem) => elem.name === fieldName,
    );
    if (!field) {
      return null;
    }

    return field.display_name;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldName' implicitly has an 'any' type... Remove this comment to see the full error message
  isMultiselectField(fieldName) {
    const field = this.mainStore.fields.list.find(
      (elem) => elem.name === fieldName,
    );
    if (!field) {
      return false;
    }

    return field.is_multiselect;
  }
}
