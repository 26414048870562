import { type ThemeOptions } from "@mui/material";

export const listItemTextTheme: ThemeOptions["components"] = {
  MuiListItemText: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: "inherit",
        transition: theme.transitions.create(["color"]),
        "&:hover": {
          color: theme.palette.primary.main,
        },
      }),
    },
  },
};
