import { WarningCircle } from "@phosphor-icons/react";
import { useIntl } from "react-intl";

import { SvgIcon } from "../../../data-display/svg-icon/svg-icon";
import { Button } from "../../../inputs/button/button";
import { LinkButton } from "../../../navigation/link-button/link-button";
import type { EmptyStateCardProps } from "../empty-state-card/empty-state-card";
import { EmptyStateCard } from "../empty-state-card/empty-state-card";

interface ErrorStateCardProps extends EmptyStateCardProps {
  onRetry?: () => void;
  goHomePath?: string;
}

export function ErrorStateCard({
  onRetry,
  goHomePath,
  children,
  ...rest
}: ErrorStateCardProps) {
  const { formatMessage } = useIntl();

  return (
    <EmptyStateCard
      title={
        <SvgIcon component={WarningCircle} fontSize="large" inheritViewBox />
      }
      description={formatMessage({
        defaultMessage: "Sorry, an error occured.",
      })}
      {...rest}
    >
      {children}

      {onRetry && (
        <Button color="info" onClick={onRetry}>
          {formatMessage({ defaultMessage: "Try again" })}
        </Button>
      )}

      {goHomePath && (
        <LinkButton color="secondary" to={goHomePath}>
          {formatMessage({ defaultMessage: "Go home" })}
        </LinkButton>
      )}
    </EmptyStateCard>
  );
}
