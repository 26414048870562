export const routes = {
  accounts: {
    overview: "/workspaces/:workspace_id/accounts",
    contracts: "/workspaces/:workspace_id/accounts/contracts",
    detail: {
      recordView: "/workspaces/:workspace_id/accounts/:accountId",
      contacts: {
        overview: "/workspaces/:workspace_id/accounts/:accountId/contacts",
        detail:
          "/workspaces/:workspace_id/accounts/:accountId/contacts/:contactId",
      },
      contracts: {
        overview: "/workspaces/:workspace_id/accounts/:accountId/contracts",
        detail:
          "/workspaces/:workspace_id/accounts/:accountId/contracts/:contractId",
      },
      subAccounts: {
        overview: "/workspaces/:workspace_id/accounts/:accountId/sub-accounts",
        addNew:
          "/workspaces/:workspace_id/accounts/:accountId/sub-accounts/new",
      },
      documents: {
        overview: "/workspaces/:workspace_id/accounts/:accountId/attachments",
        detail:
          "/workspaces/:workspace_id/accounts/:accountId/attachments/detail",
      },
      inboundLinks:
        "/workspaces/:workspace_id/accounts/:accountId/referenced-in",
      outboundLinks:
        "/workspaces/:workspace_id/accounts/:accountId/linked-records",
      dueDiligence:
        "/workspaces/:workspace_id/accounts/:accountId/due-diligence",
      questionnaires:
        "/workspaces/:workspace_id/accounts/:accountId/questionnaires",
      activity: "/workspaces/:workspace_id/accounts/:accountId/activity",
    },
  },
  questionnaires:
    "/workspaces/:workspace_id/modules/risk-assessment/questionnaires/:questionnaireId/review",
} as const;
