import { MiniTag, Stack } from "@themis/ui";
import { keyBy } from "lodash";

import { useCompany } from "@/api/queries/companies";
import { useDepartments } from "@/api/queries/departments/use-departments";

import type { BaseDynamicValueProps } from "../../base-dynamic-value";
import type { GetReferenceType } from "../../field-configuration-types";

type ReferenceType = GetReferenceType<"Department">;

export const DepartmentValue = ({
  value,
}: BaseDynamicValueProps<ReferenceType>) => {
  const { data: company } = useCompany("current");

  const { data: departmentsMap } = useDepartments(company?.data.company.id, {
    select: (data) => keyBy(data, "id"),
    enabled: !!value,
  });

  if (!value) {
    return null;
  }

  const departments = (Array.isArray(value) ? value : [value])
    .map((item) => (item ? departmentsMap?.[item.id] : undefined))
    .filter((item) => item !== undefined);

  return (
    <Stack direction="row" wrap spacing="0.5">
      {departments.map((department) => (
        <MiniTag key={department?.id} colorIndex={department?.id}>
          {department?.title}
        </MiniTag>
      ))}
    </Stack>
  );
};
