import type { Filter } from "../types";
import { FilterOperand } from "../types";

export function stringifyFilterValue(filter: Filter) {
  try {
    if (
      [FilterOperand.ANY, FilterOperand.NOT, FilterOperand.MATCH].includes(
        filter.operand,
      )
    ) {
      return (filter.value as string[]).join(",");
    }

    if (
      [FilterOperand.PRESENT, FilterOperand.MISSING].includes(filter.operand)
    ) {
      return "true";
    }

    throw new Error(`Invalid filter operand: ${filter.operand}`);
  } catch (error) {
    window.console.info(`Non-parsable filter: ${filter}`, error);
    return "";
  }
}
