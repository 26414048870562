import type { ThemeOptions } from "@mui/material";

export const tooltip: ThemeOptions["components"] = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: ({ theme }) => ({
        fontSize: theme.typography.body2.fontSize,
      }),
    },
  },
};
