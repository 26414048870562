import type { IconButtonProps } from "@mui/material";
import { Link as LinkIcon } from "@phosphor-icons/react";
import { forwardRef } from "react";

import { IconButton } from "../../inputs/icon-button/icon-button";

export interface ExternalLinkIconButtonProps
  extends Omit<IconButtonProps<"a">, "href"> {
  to: NonNullable<IconButtonProps<"a">["href"]>;
}

export const ExternalLinkIconButton = forwardRef<
  HTMLAnchorElement,
  ExternalLinkIconButtonProps
>(({ to, ...rest }, ref) => {
  const externalLinkProps: Partial<ExternalLinkIconButtonProps> = {
    target: "_blank",
    rel: "noopener noreferrer",
    children: <LinkIcon />,
  };

  return (
    <IconButton
      component="a"
      href={to}
      ref={ref}
      {...externalLinkProps}
      {...rest}
    />
  );
});

ExternalLinkIconButton.displayName = "ExternalLinkIconButton";
