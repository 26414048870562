import type { ThemeOptions } from "@mui/material";

export const typography: ThemeOptions["components"] = {
  MuiTypography: {
    defaultProps: {
      maxWidth: "34rem",
      variantMapping: {
        h1: "h1",
        h2: "h2",
        h3: "h2",
        h4: "h2",
        h5: "h2",
        h6: "h2",
        subtitle1: "span",
        subtitle2: "span",
        body1: "span",
        body2: "span",
      },
    },
  },
};
