import { faker } from "@faker-js/faker";

import type { LegacyMonitoringGroup } from "../models/LegacyMonitoringGroup";
import { createMonitoringGroupAsset } from "./createMonitoringGroupAsset";

export function createLegacyMonitoringGroup(
  data: NonNullable<Partial<LegacyMonitoringGroup>> = {},
): NonNullable<LegacyMonitoringGroup> {
  return {
    ...{
      id: faker.number.int(),
      company_id: faker.number.int(),
      group_name: faker.string.alpha(),
      group_status: faker.string.alpha(),
      metadata: {},
      monitoring_group_uid: faker.string.alpha(),
      monitoring_group_created_at: faker.date.anytime().toISOString(),
      monitoring_group_updated_at: faker.date.anytime().toISOString(),
      created_at: faker.date.anytime().toISOString(),
      updated_at: faker.date.anytime().toISOString(),
      assets: faker.helpers.arrayElements([
        createMonitoringGroupAsset(),
      ]) as any,
    },
    ...data,
  };
}
