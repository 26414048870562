import { faker } from "@faker-js/faker";

import type { Training } from "../models/Training";
import { createDepartment } from "./createDepartment";
import { createRecordShow } from "./createRecordShow";
import { createThemisDocument } from "./createThemisDocument";
import { createThemisRecord } from "./createThemisRecord";

export function createTraining(
  data?: NonNullable<Partial<Training>>,
): NonNullable<Training> {
  return Object.assign({}, createThemisRecord(), {
    name: faker.string.alpha(),
    sections: faker.helpers.arrayElements([faker.string.alpha()]) as any,
    status: faker.helpers.arrayElement<any>([
      "draft",
      "ready_to_review",
      "submitter_action_needed",
      "approver_action_needed",
      "ready_to_finalize",
      "complete",
    ]),
    notes: faker.string.alpha(),
    training_type: faker.string.alpha(),
    comments_count: faker.number.int(),
    training_materials: faker.helpers.arrayElements([
      createThemisDocument(),
    ]) as any,
    policy: Object.assign({}, createRecordShow()),
    department: Object.assign({}, createDepartment()),
  });
}
