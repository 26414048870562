import { Info } from "@phosphor-icons/react";
import type { ReactNode } from "react";

import { styled } from "../../../../styles";
import { Tooltip } from "../../../data-display/tooltip/tooltip";
import { Typography } from "../../../data-display/typography/typography";
import type { StackProps } from "../../stack/stack";
import { Stack } from "../../stack/stack";

export interface PageHeaderProps extends Omit<StackProps, "title"> {
  title: ReactNode;
  actions?: ReactNode;
  showBorderBottom?: boolean;
  info?: ReactNode;
}

const StyledStack = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  minHeight: theme.height.toolbar,
  justifyContent: "space-between",
  alignItems: "center",
  flexShrink: 0,
  "&[data-show-border-bottom=true]": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export function PageHeader({
  title,
  info,
  actions,
  showBorderBottom,
  ...rest
}: PageHeaderProps) {
  return (
    <StyledStack
      direction="row"
      data-show-border-bottom={showBorderBottom}
      {...rest}
    >
      <Stack direction="row" alignItems="center">
        {typeof title === "string" ? (
          <Typography variant="h5">{title}</Typography>
        ) : (
          title
        )}

        {info && (
          <Tooltip title={info}>
            <Info />
          </Tooltip>
        )}
      </Stack>

      {actions}
    </StyledStack>
  );
}
