import Pagination from "@mui/material/Pagination";
import type { TablePaginationProps as MuiTablePaginationProps } from "@mui/material/TablePagination";
import MuiTablePagination from "@mui/material/TablePagination";
import type { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import type { ElementType } from "react";

type TablePaginationProps = MuiTablePaginationProps & {};

const ActionsComponent: ElementType<TablePaginationActionsProps> = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  getItemAriaLabel,
  slotProps,
  backIconButtonProps,
  nextIconButtonProps,
  onChange,
  color,
  classes,
  ...rest
}) => {
  return (
    <Pagination
      page={page + 1}
      siblingCount={1}
      boundaryCount={1}
      count={Math.ceil(count / rowsPerPage)}
      onChange={(_e, p) => onPageChange(null, p - 1)}
      {...rest}
    />
  );
};

export const TablePagination = ({ ...rest }: TablePaginationProps) => {
  return (
    <MuiTablePagination
      component="div"
      ActionsComponent={ActionsComponent}
      sx={{
        flexShrink: 0,
      }}
      {...rest}
    />
  );
};
