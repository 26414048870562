import type { ColDef } from "ag-grid-community";
import type { CustomCellRendererProps } from "ag-grid-react";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { getMoreOptionsColumn } from "../../../utils/get-more-options-column";
import { StatusCell } from "../../status-cell/status-cell";
import type { Assignee } from "../types";
import { AssigneeMoreOptionsDropdownMenu } from "./assignee-more-options-dropdown-menu/assignee-more-options-dropdown-menu";
import { AssigneeNameCellRenderer } from "./assignee-name-cell-render/assignee-name-cell-render";

interface UseAssigneesColumnsParams {
  onRemove: (id: number) => void;
  onResend: (id: number) => void;
  canResend?: boolean;
}

export function useAssigneesColumns({
  onRemove,
  onResend,
  canResend,
}: UseAssigneesColumnsParams) {
  const { formatDate } = useIntl();
  return useMemo(
    (): Array<ColDef<Assignee>> => [
      {
        headerName: "Name",
        colId: "name",
        field: "userId",
        cellStyle: { padding: "0 8px" },
        cellRenderer: AssigneeNameCellRenderer,
      },
      {
        headerName: "Status",
        colId: "satus",
        field: "status",
        maxWidth: 100,
        cellStyle: { padding: "0 8px" },
        cellRenderer: StatusCell,
      },
      {
        headerName: "Approved Date",
        colId: "approvedAt",
        field: "approvedAt",
        maxWidth: 100,
        cellStyle: { padding: "0 8px" },
        valueFormatter: ({ value }) =>
          value
            ? formatDate(value, {
                dateStyle: "medium",
                timeStyle: "medium",
                timeZone: "utc",
              })
            : "",
      },
      {
        headerName: "Sent Date",
        colId: "sentAt",
        field: "sentAt",
        maxWidth: 100,
        cellStyle: { padding: "0 8px" },
        valueFormatter: ({ value }) =>
          value
            ? formatDate(value, {
                dateStyle: "medium",
                timeStyle: "medium",
                timeZone: "utc",
              })
            : "",
      },
      {
        headerName: "# of Resend Attestation",
        colId: "numberOfResend",
        field: "numberOfResend",
        maxWidth: 100,
        cellStyle: { padding: "0 8px" },
      },
      getMoreOptionsColumn({
        cellRenderer: AssigneeMoreOptionsDropdownMenu,
        cellRendererParams: (props: CustomCellRendererProps) => ({
          ...props,
          onRemove,
          onResend,
          canResend,
        }),
      }),
    ],
    [onRemove, onResend, canResend, formatDate],
  );
}
