import {
  ConfirmationPopup,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  useToast,
} from "@themis/ui";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import axios from "axios";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useIntl } from "react-intl";

import type { RecordLink, RecordTypePath } from "@/api";

import { useDeleteRecordLink } from "../../../../api/queries/record-links/use-delete-record-link";

const DROPDOWN_ITEM_STYLE =
  "tw-flex tw-w-[228px] tw-items-center tw-justify-start tw-gap-1 tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primary-50 tw-px-4 tw-py-[10px] tw-justify-between";

interface RecordLinkActionCellProps {
  recordLink: RecordLink;
  recordType: RecordTypePath;
}

export function RecordLinkActionsCell({
  recordLink,
  recordType,
}: RecordLinkActionCellProps) {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const objectDisplayName = formatMessage({
    defaultMessage: "Record Link",
    description: "Display name for record link object",
  });

  const { mutate: deleteRecordLink } = useDeleteRecordLink(recordType);

  const confirmation = useOpen();

  function handleDelete() {
    confirmation.close();

    deleteRecordLink(recordLink.id, {
      onSuccess: () => {
        toast({
          content: formatMessage(
            {
              defaultMessage: "{objectName} has been removed!",
              description: "Success message when object is removed",
            },
            { objectName: objectDisplayName },
          ),
          variant: "success",
        });
      },
      onError: (error) => {
        const errorMsg =
          axios.isAxiosError(error) && error?.response?.data?.errors?.base
            ? error.response.data.errors.base
            : formatMessage(
                {
                  defaultMessage: "Failed to remove {objectName}!",
                  description: "Error message when object removal fails",
                },
                { objectName: objectDisplayName },
              );
        toast({
          content: errorMsg,
          variant: "error",
        });
      },
    });
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton
            aria-label={formatMessage(
              {
                defaultMessage: "{objectName} Actions",
                description: "Aria label for actions button",
              },
              { objectName: objectDisplayName },
            )}
            aria-describedby={`record-link-id-${recordLink.id}`}
            Icon={PiDotsThreeOutlineVerticalFill}
            color="transparent"
            className="tw-h-8 tw-w-8 tw-rounded-md"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="tw-py-0">
          <DropdownMenuItem
            key="Unlink"
            aria-label={formatMessage(
              {
                defaultMessage: "Unlink {objectName}",
                description: "Aria label for unlink action",
              },
              { objectName: objectDisplayName },
            )}
            onClick={confirmation.open}
            className={`${DROPDOWN_ITEM_STYLE} tw-border-b-0`}
          >
            <span className="tw-text-sm tw-font-medium">
              {formatMessage({
                defaultMessage: "Unlink",
                description: "Label for unlink action",
              })}
            </span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <ConfirmationPopup
        anchor
        align="end"
        title={formatMessage(
          {
            defaultMessage: "Delete {objectName}",
            description: "Title for delete confirmation popup",
          },
          { objectName: objectDisplayName },
        )}
        content={formatMessage({
          defaultMessage:
            "Actions are non-reversible. Are you sure you want to proceed?",
          description: "Warning message for delete confirmation",
        })}
        open={confirmation.isOpen}
        onCancel={confirmation.close}
        onConfirm={handleDelete}
      />
    </>
  );
}
