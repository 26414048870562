import { Skeleton } from "@themis/ui-library/components/feedback/skeleton/skeleton";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import type { ReactElement } from "react";

export function CommentsLoading(): ReactElement {
  return (
    <Stack spacing={3} paddingX={2} role="status">
      <Stack direction="row" alignItems="center">
        <Skeleton variant="circular" width={24} height={24} />
        <Stack>
          <Skeleton height={12} variant="rounded" width={120} />
          <Skeleton height={12} variant="rounded" width={300} />
        </Stack>
      </Stack>

      <Skeleton width="100%" height={30} />
    </Stack>
  );
}
