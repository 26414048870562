import type { ReactElement, SetStateAction } from "react";
import React, { useEffect, useState } from "react";

import type { Field } from "@/api";

import FiltersContent from "../components/filters/FiltersContent";
import FiltersTrigger from "../components/filters/FiltersTrigger";
import { useMainStore } from "../contexts/Store";
import { useUpdateFilter } from "./useUpdateFilter";

interface FiltersTriggerProps {
  onClick: () => void;
}

interface FilterContentProps {
  fields: Field[];
}

export interface UseFiltersReturn {
  initialLoad: boolean;
  filtersViewEnabled: boolean;
  filtersTrigger: ReactElement<FiltersTriggerProps>;
  filtersContent: ReactElement<FilterContentProps>;
  setFiltersViewEnabled: React.Dispatch<SetStateAction<boolean>>;
}

interface Props {
  fields: Field[];
  skipCleanup?: boolean;
}

export function useFilters({
  fields,
  skipCleanup = false,
}: Props): UseFiltersReturn {
  // Import MobX stores
  const mainStore = useMainStore();

  const { setTableFiltersParam } = useUpdateFilter();

  // vars
  const queryString = window.location.search;

  const urlParams = new URLSearchParams(queryString);
  const tableFilters = urlParams.get("tableFilters");
  urlParams.delete("tableFilters");

  // state
  const [initialLoad, setInitialLoad] = useState(true);
  const [filtersViewEnabled, setFiltersViewEnabled] = useState(!!tableFilters);

  // effects
  // cleanup filters on unmount
  useEffect(
    () =>
      function cleanup() {
        if (skipCleanup) {
          return;
        }
        mainStore.filters.cleanup();
      },
    [mainStore.filters, skipCleanup],
  );

  useEffect(() => {
    setInitialLoad(true);
  }, [window.location.pathname]);

  // set filters from url on mount
  useEffect(() => {
    if (tableFilters) {
      const filtersFromUrl = queryStringToObject(tableFilters);
      setTableFiltersParam(filtersFromUrl);
    }
  }, []);

  // funcs
  function supportedFieldsFilter(field: Field) {
    // not supported types: [computed]
    if (field.computed_column_identifier) {
      return false;

      // not supported data_types:
      // 'com.askthemis.types.v1.checklist'
      // 'com.askthemis.types.v1.action_plans'
      // 'com.askthemis.types.v1.module_controls'
      // 'com.askthemis.types.v1.operational_controls'
      // 'com.askthemis.types.v1.related_risks'
      // 'com.askthemis.types.v1.control_effectiveness_rating'
    } else if (
      [
        "com.askthemis.types.v1.checklist",
        "com.askthemis.types.v1.action_plans",
        "com.askthemis.types.v1.module_controls",
        "com.askthemis.types.v1.operational_controls",
        "com.askthemis.types.v1.related_risks",
        "com.askthemis.types.v1.control_effectiveness_rating",
      ].includes(field.data_type)
    ) {
      return false;
    }
    return true;
  }

  function queryStringToObject(string: string) {
    return JSON.parse(decodeURIComponent(string));
  }

  // elements
  // todo later - remove from hook ?
  const filtersTrigger: ReactElement<FiltersTriggerProps> = (
    <FiltersTrigger
      onClick={() => setFiltersViewEnabled(!filtersViewEnabled)}
    />
  );

  const filtersContent: ReactElement<FilterContentProps> = (
    <FiltersContent fields={fields.filter(supportedFieldsFilter)} />
  );

  return {
    initialLoad,
    filtersViewEnabled,
    filtersTrigger,
    filtersContent,
    setFiltersViewEnabled,
  };
}
