import React from "react";
import { renderToStaticMarkup } from "react-dom/server";

import { cn } from "../../lib/utils";
import type { PreviewTableProps } from "./PreviewTable";
import type { DataWithIdAndCustomFields, PreviewTableColumn } from "./types";

const idCellStyles = "tw-mr-1 tw-rounded tw-bg-blue-1 tw-text-blue-4";
const lockedCellStyles = "tw-bg-primary-25 tw-text-primaryDim-300";
const readOnlyCellStyles = "tw-bg-neutral-25 tw-text-neutral-300";
const actionsCellStyles =
  "tw-sticky tw-right-0 tw-flex tw-gap-1 tw-bg-neutral-25 tw-pl-2 tw-pr-0";
const middleCellStyles =
  "tw-border-l-0 tw-border-r tw-border-solid tw-border-neutral-100";
const rowStartCellStyles =
  "tw-rounded-l tw-border-x tw-border-solid tw-border-neutral-100";
const rowEndCellStyles =
  "tw-rounded-r tw-border-l-0 tw-border-r tw-border-solid tw-border-neutral-100";
const cellPaddingStyles = "tw-px-2.5 tw-py-1";

function TableCellContainer<R extends DataWithIdAndCustomFields>({
  children,
  isRowEnd,
  isRowStart,
  isTextContent,
  isTruncated,
  noCellPadding,
  type,
  customCellClassName = "",
  customRowClassName = "",
}: {
  children?: React.ReactNode;
  isRowEnd?: boolean;
  isRowStart?: boolean;
  isTextContent?: boolean;
  isTruncated?: boolean;
  noCellPadding?: boolean;
  type: PreviewTableColumn<R>["type"];
  customCellClassName?: string;
  customRowClassName?: string;
}) {
  const isIdCell = type === "id";
  const isLockedCell = type === "locked";
  const isReadOnlyCell = type === "readOnly";
  const isActionsCell = type === "actions";
  const isMiddleCell =
    !isRowEnd && !isRowStart && (isLockedCell || isReadOnlyCell);
  const isCustomCell = !!customCellClassName;
  const isCustomRow = !!customRowClassName;

  return (
    <div
      className={cn(
        "tw-relative tw-box-border tw-flex tw-min-h-10 tw-items-center tw-border-y",
        {
          // Added because text will not overflow as ellipsis in responsive mode
          [customCellClassName]: isCustomCell,
          [customRowClassName]: isCustomRow,
          "tw-min-w-0": !isCustomCell && isTruncated,
          [idCellStyles]: !isCustomCell && isIdCell,
          [lockedCellStyles]: !isCustomCell && isLockedCell,
          [readOnlyCellStyles]: !isCustomCell && isReadOnlyCell,
          [actionsCellStyles]: !isCustomCell && isActionsCell,
          [middleCellStyles]: !isCustomCell && isMiddleCell,
          [rowStartCellStyles]: !isCustomRow && isRowStart,
          [rowEndCellStyles]: !isCustomRow && isRowEnd,
          [cellPaddingStyles]: !noCellPadding,
        },
      )}
    >
      {isTextContent ? (
        <p
          title={renderToStaticMarkup(children)}
          className="tw-line-clamp-2 tw-text-sm tw-font-semibold"
        >
          {children}
        </p>
      ) : (
        children
      )}
    </div>
  );
}

function TableCellContent<R extends DataWithIdAndCustomFields>({
  columnKey,
  isCustomColumn,
  row,
  Component,
}: {
  columnKey?: keyof R | keyof R["custom_fields"];
  isCustomColumn?: boolean;
  row: R;
  Component: PreviewTableColumn<R>["Component"];
}) {
  if (Component) {
    return Component(row);
  }

  if (!columnKey) {
    throw new Error("Row key is required for columns without a component");
  }

  if (isCustomColumn) {
    const value = row.custom_fields?.[columnKey as string];

    return Array.isArray(value) ? value.join(", ") : value;
  }

  return row[columnKey as keyof R] as React.ReactNode;
}

export function TableRow<R extends DataWithIdAndCustomFields>({
  row,
  rowActions,
  columns,
  componentOverrides,
  customFieldComponentOverrides,
  customCellClassName,
  customRowClassName,
}: {
  row: R;
  rowActions?: PreviewTableProps<R>["rowActions"];
  columns: PreviewTableColumn<R>[];
  componentOverrides?: PreviewTableProps<R>["componentOverrides"];
  customFieldComponentOverrides?: PreviewTableProps<R>["customFieldComponentOverrides"];
  customCellClassName?: string;
  customRowClassName?: string;
}) {
  return (
    <>
      {columns.map(
        ({
          isCustomColumn,
          isRowEnd,
          isRowStart,
          isTruncated,
          key,
          title,
          type,
          noCellPadding,
          Component,
        }) => {
          function getComponent() {
            if (isCustomColumn && key) {
              return (
                customFieldComponentOverrides?.[key as string] || Component
              );
            }

            const ComponentOverride = componentOverrides?.[key as keyof R];

            return ComponentOverride ? ComponentOverride : Component;
          }

          return (
            <TableCellContainer
              key={type === "actions" ? "actions" : title}
              isRowEnd={isRowEnd}
              isRowStart={isRowStart}
              isTextContent={
                !getComponent() || typeof getComponent()?.(row) === "string"
              }
              isTruncated={isTruncated || !getComponent()}
              type={type}
              customCellClassName={customCellClassName}
              customRowClassName={customRowClassName}
              noCellPadding={noCellPadding}
            >
              <TableCellContent
                columnKey={key}
                isCustomColumn={isCustomColumn}
                row={row}
                Component={getComponent()}
              />
            </TableCellContainer>
          );
        },
      )}
      {rowActions && (
        <TableCellContainer type="actions">
          {rowActions(row)}
        </TableCellContainer>
      )}
    </>
  );
}
