import type { Palette } from "@mui/material";

import { hashString } from "./hash-string";

export const BASE_COLORS = [
  // IMPORTANT: Keep this list in-sync with app/services/seed_colors_service.rb
  "blue",
  "error",
  "fuchsia",
  "green",
  "info",
  "lightBlue",
  "mint",
  "neutral",
  "orange",
  "primary",
  "purple",
  "secondary",
  "success",
  "warning",
  "yellow",
] as const satisfies (keyof Palette)[];

const isValidColor = <TColor extends string>(
  color: string,
  colors: TColor[],
): color is TColor => {
  return !!colors.find((c) => c === color);
};

export const getSeedColor = <TColor extends string>(
  seed: number | string | undefined | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  colors: TColor[] = BASE_COLORS as any,
): TColor | undefined => {
  if (!seed) {
    return undefined;
  }

  if (typeof seed === "number") {
    return colors[seed % colors.length];
  }

  if (isValidColor(seed, colors)) {
    return seed;
  }

  const seedIndex = hashString(seed);
  return colors[seedIndex % colors.length];
};
