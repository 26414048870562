import { useLayoutEffect, useState } from "react";

export function useResizeObserver<T extends HTMLElement = HTMLElement>(
  element?: T | null,
) {
  const [entry, setEntry] = useState<ResizeObserverEntry | null | undefined>();

  useLayoutEffect(() => {
    if (!element) {
      // Trigger re-render with null entry when element is undefined
      setEntry(null);
      return;
    }

    const observer = new ResizeObserver(([observerEntry]) => {
      setEntry(observerEntry);
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [element]);

  return entry;
}
