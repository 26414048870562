import { cva, type VariantProps } from "cva";
import type { ButtonHTMLAttributes } from "react";

const defaultStyles =
  "tw-inline-flex tw-border-none tw-shrink-0 tw-items-center tw-justify-center tw-gap-1 tw-whitespace-nowrap tw-rounded-md tw-text-sm tw-font-semibold tw-font-sans tw-transition-colors tw-w-min";

const activeStyles = "active:tw-bg-neutral-500 active:tw-text-white";

const focusStyles =
  "focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring";

const buttonBaseVariants = cva({
  base: [defaultStyles, activeStyles, focusStyles],
  variants: {
    color: {
      primary: `tw-bg-primary-300 hover:tw-bg-primary-500 tw-text-white`,
      secondary: `tw-bg-secondary-300 hover:tw-bg-secondary-500 tw-text-white`,
      tertiary: `tw-bg-primary-50 hover:tw-bg-primary-100 tw-text-neutral-500`,
      grey: `tw-bg-neutral-50 hover:tw-bg-primary-25 tw-text-neutral-500`,
      transparent: `tw-bg-transparent hover:tw-bg-primary-25 tw-text-neutral-500`,
      transparentWhite: `tw-bg-transparent hover:tw-bg-primary-25 hover:tw-text-neutral-500 tw-text-neutral-25`,
      transparentPrimary: `tw-bg-transparent hover:tw-bg-primary-25 tw-text-primary-300`,
      active: "tw-bg-neutral-500 tw-text-white",
      warning: `tw-bg-warning-400 hover:tw-bg-warning-500 tw-text-white`,
    },
    disabled: {
      true: "tw-opacity-50 tw-cursor-not-allowed",
      false: "",
    },
    loading: {
      true: "tw-opacity-90 tw-text-opacity-15 tw-pointer-events-none",
      false: "",
    },
  },
  compoundVariants: [
    {
      disabled: true,
      loading: false,
      class: "tw-bg-primaryDim-50 tw-text-neutral-300 tw-pointer-events-none",
    },
  ],
  defaultVariants: {
    disabled: false,
    color: "primary",
  },
});

export type ButtonBaseProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonBaseVariants> & {
    asChild?: boolean;
  };

export { buttonBaseVariants };
