import { Button, useToast } from "@themis/ui";
import { Menu } from "@themis/ui-library/components/navigation/menu/menu";
import { MenuItem } from "@themis/ui-library/components/navigation/menu/menu-item/menu-item";
import { Popover } from "@themis/ui-library/components/utils/popover/popover";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import { snakeCase } from "lodash";
import { observer } from "mobx-react";
import React, { useMemo, useRef } from "react";
import { useIntl } from "react-intl";

import { recordTypeForThemisModuleIdentifier } from "@/components/helpers/nameForThemisModuleIdentifier";
import { FieldsSelector } from "@/components/table/shared/share-link/fields-selector";
import { useMainStore } from "@/contexts/Store";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

interface ShareLinkProps {
  moduleIdentifier: ModuleIdentifier;
}

const ALLOWED_DATA_TYPES = [
  "com.askthemis.types.v1.attachment",
  "com.askthemis.types.v1.constrained_option",
  "com.askthemis.types.v1.date",
  "com.askthemis.types.v1.float",
  "com.askthemis.types.v1.integer",
  "com.askthemis.types.v1.link",
  "com.askthemis.types.v1.long_text",
  "com.askthemis.types.v1.multiline_text",
  "com.askthemis.types.v1.option",
  "com.askthemis.types.v1.text",
];

function ShareLink({ moduleIdentifier }: ShareLinkProps) {
  const mainStore = useMainStore();
  const ref = useRef(null);
  const { formatMessage } = useIntl();
  const toast = useToast();
  const menu = useOpen();
  const popover = useOpen();

  const { moduleWorkspaceID, isCompanySuperAdmin, isCompanyAdmin } =
    mainStore.context;
  const { visibleFields } = mainStore.fields;
  const filteredFields = visibleFields.filter(
    (field) =>
      ALLOWED_DATA_TYPES.includes(field.data_type) &&
      !field.is_default_field &&
      !field.is_computed_column &&
      field.is_user_editable &&
      field.name !== "status",
  );
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const companyName = mainStore.companies.company.name
    ?.split(" ")
    ?.join("-")
    .toLowerCase();

  const moduleRecord = recordTypeForThemisModuleIdentifier(moduleIdentifier);
  const singularModuleIdentifier = snakeCase(moduleRecord.singular);

  const modulePath = useMemo(() => {
    switch (moduleIdentifier) {
      case TopLevelModule.COMPLAINTS:
        return "complaints";
      case TopLevelModule.CONFLICTS_OF_INTEREST:
        return "conflict_of_interest";
      default:
        return singularModuleIdentifier;
    }
  }, [moduleIdentifier]);

  // Functions
  async function getToken() {
    return await mainStore.moduleAccessTokens
      .find_or_create(moduleWorkspaceID)
      .then((res) => res);
  }

  async function copyLink() {
    menu.close();

    const token = await getToken();
    if (token) {
      const shareLink = `${window.location.host}/${companyName}/submit_${modulePath}/${token}`;
      setTimeout(async () => {
        await navigator.clipboard.writeText(shareLink);
        toast({
          content: `You’ve copied the ${moduleRecord.singular} link!`,
          variant: "success",
        });
      });
    }
  }

  return (
    <>
      <Button onClick={menu.open} ref={ref} disabled={!hasModuleWriteAccess}>
        {formatMessage({ defaultMessage: "Share Link" })}
      </Button>

      <Menu
        anchorEl={ref.current}
        open={menu.isOpen}
        onClose={() => menu.close()}
        slotProps={{
          paper: {
            sx: (theme) => ({
              width: 260,
              mt: theme.spacing(0.5),
            }),
          },
        }}
      >
        <MenuItem onClick={copyLink}>
          {formatMessage({ defaultMessage: "Copy Link" })}
        </MenuItem>
        {(isCompanySuperAdmin || isCompanyAdmin) && (
          <MenuItem
            onClick={() => {
              popover.open();
              menu.close();
            }}
          >
            {formatMessage({ defaultMessage: "Format Link" })}
          </MenuItem>
        )}
      </Menu>

      <Popover
        open={popover.isOpen}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => popover.close()}
        sx={(theme) => ({
          mt: theme.spacing(0.5),
        })}
      >
        <FieldsSelector
          fields={filteredFields}
          moduleWorkspaceId={Number(moduleWorkspaceID)}
          onClose={() => popover.close()}
          onBackToMenu={() => {
            popover.close();
            menu.open();
          }}
          isOpen={popover.isOpen}
        />
      </Popover>
    </>
  );
}

export default observer(ShareLink);
