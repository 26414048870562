import type { CompanyUser } from "@/api";
import { generateUniqueId } from "@/utils/utils";

import type {
  AssigneeField,
  AssigneeOption,
  ContactAssignee,
  EmailAssignee,
  UserAssignee,
} from "../types";

export function getAssigneeFromOption(
  option: AssigneeOption | string,
  users: CompanyUser[],
) {
  const newAssignee: AssigneeField = {
    // temporary id, will be provided by the BE when the record is saved
    id: generateUniqueId(),
    status: "pending",
    numberOfResend: 0,
  };

  if (typeof option === "string") {
    const emailAssignee: EmailAssignee = {
      ...newAssignee,
      email: option,
    };

    return [emailAssignee];
  }
  if (option.type === "user") {
    const userAssignee: UserAssignee = {
      ...option.record,
      ...newAssignee,
      userId: option.value,
    };

    return [userAssignee];
  }
  if (option.type === "contact") {
    const contactAssignee: ContactAssignee = {
      ...option.record,
      ...newAssignee,
      contactId: option.value,
    };

    return [contactAssignee];
  }
  if (option.type === "department") {
    // We don't add department assignee directly, we add all users in the department
    const departmentUsers =
      users?.filter((user) => user.department?.id === option.value) || [];

    const userAssignees: UserAssignee[] = departmentUsers.map((user) => ({
      ...user,
      ...newAssignee,
      id: generateUniqueId(),
      userId: user.id,
    }));

    return userAssignees;
  }
  return [];
}
