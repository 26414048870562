import {
  Button,
  cn,
  Command,
  CommandItem,
  CommandList,
  useToast,
} from "@themis/ui";
import { useDialogs } from "@themis/ui-library/hooks/use-dialogs/use-dialogs";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import type { Column, SortDirection } from "ag-grid-community";
import type { CustomHeaderProps } from "ag-grid-react";
import React from "react";
import type { ReactElement } from "react";
import { PiArrowDown, PiArrowUp, PiPushPin } from "react-icons/pi";
import { useIntl } from "react-intl";

import type { FieldConfiguration } from "../../../api/gen/models/FieldConfiguration";
import type { RecordTypePath } from "../../../api/gen/models/RecordTypePath";
import { useDeleteField } from "../../../api/queries/fields/use-delete-field";
import { useCompanyId } from "../../../hooks/use-company-id";
import { usePermissions } from "../../../hooks/use-permissions";
import { EditCustomColumnDialog } from "../custom-column-dialog/edit-custom-column-dialog";
import { useColumnTypeOptions } from "../use-column-type-options";

interface ColumnHeaderMenuProps {
  context: CustomHeaderProps["context"];
  column: Column;
  field: FieldConfiguration;
  recordType: RecordTypePath;
  onClose: () => void;
}

export function ColumnHeaderMenu({
  context,
  column,
  field,
  recordType,
  onClose,
}: ColumnHeaderMenuProps): ReactElement {
  const { formatMessage } = useIntl();
  const dialogs = useDialogs();
  const toast = useToast();
  const manageState = useOpen();
  const deleteConfirmation = useOpen();
  const { companyId } = useCompanyId();

  const gridRef = context.gridRef?.current;
  const isPinned = column.isPinned();
  const enablePinning =
    !column.getColDef().lockPinned && !column.getColDef().lockPosition;

  const enableSorting = column.getColDef().sortable;
  const sort = column.getSort();

  const modulePermissions = usePermissions({
    domain: "module",
    module: recordType,
  });

  const canUpdateColumns =
    !!modulePermissions.add_remove_columns && !!field?.custom;

  const deleteField = useDeleteField({
    companyId,
    recordType,
  });

  const { Icon: ColumnTypeIcon, label: columnTypeLabel } = useColumnTypeOptions(
    field?.field_type,
  );

  const handleDeleteClick = async () => {
    const confirmed = await dialogs.confirm(
      formatMessage(
        {
          defaultMessage: "Permanently delete {name} Custom Column?",
        },
        {
          name: <strong>{field?.display_name ?? ""}</strong>,
        },
      ),
      {
        severity: "error",
        okText: formatMessage({
          defaultMessage: "Yes, Delete",
        }),
        title: formatMessage({
          defaultMessage: "Delete Custom Column",
        }),
      },
    );

    if (confirmed) {
      try {
        await deleteField.mutateAsync(field.id);
        toast({
          content: formatMessage({
            defaultMessage: "Custom Column deleted",
          }),
          variant: "success",
        });
        onClose();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("error", e);
        toast({
          content: formatMessage({
            defaultMessage: "Failed to delete Custom Column",
          }),
          variant: "error",
        });
      }
    }
  };

  const handleManageClick = () => {
    manageState.open();
  };

  const handlePinToggleClick = () => {
    gridRef.api?.applyColumnState({
      state: [{ colId: column.getColId(), pinned: isPinned ? null : "left" }],
    });
  };

  const handleSortClick = (direction: SortDirection) => {
    gridRef.api?.applyColumnState({
      state: [{ colId: column.getColId(), sort: direction }],

      defaultState: {
        sort: null,
      },
    });
    onClose();
  };

  return (
    <>
      <div
        className={cn(
          "tw-box-border tw-flex tw-min-w-60 tw-max-w-80 tw-flex-col tw-font-sans",
          { "tw-hidden": deleteConfirmation.isOpen },
        )}
      >
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-border-0 tw-border-b tw-border-solid tw-border-neutral-100 tw-px-4 tw-py-2.5">
          {ColumnTypeIcon && (
            <ColumnTypeIcon className="tw-text-neutral-500" size={18} />
          )}

          <h3 className="tw-font-sans tw-font-semibold tw-text-neutral-500">
            {columnTypeLabel}
          </h3>

          {canUpdateColumns && (
            <Button
              className="tw-my-[-4px] tw-ml-auto tw-mr-[-8px]"
              color="transparentPrimary"
              onClick={handleManageClick}
            >
              {formatMessage({
                defaultMessage: "Manage",
              })}
            </Button>
          )}
        </div>

        <Command tabIndex={0} className="tw-rounded-none tw-outline-none">
          <CommandList>
            {enableSorting && (
              <CommandItem
                className="tw-cursor-pointer tw-gap-2 tw-py-2.5 tw-font-semibold"
                onSelect={() => handleSortClick(sort === "asc" ? null : "asc")}
              >
                <PiArrowUp size={16} />
                {formatMessage({
                  defaultMessage: "Sort Ascending",
                })}
              </CommandItem>
            )}
            {enableSorting && (
              <CommandItem
                className="tw-cursor-pointer tw-gap-2 tw-py-2.5 tw-font-semibold"
                onSelect={() =>
                  handleSortClick(sort === "desc" ? null : "desc")
                }
              >
                <PiArrowDown size={16} />
                {formatMessage({
                  defaultMessage: "Sort Descending",
                })}
              </CommandItem>
            )}
            {enablePinning && (
              <CommandItem
                className="tw-cursor-pointer tw-gap-2 tw-py-2.5 tw-font-semibold"
                onSelect={handlePinToggleClick}
              >
                <PiPushPin size={16} />

                {isPinned
                  ? formatMessage({
                      defaultMessage: "Unpin Column",
                    })
                  : formatMessage({
                      defaultMessage: "Pin Column",
                    })}
              </CommandItem>
            )}
            {canUpdateColumns && (
              <CommandItem
                className="tw-cursor-pointer tw-gap-2 tw-py-2.5 tw-font-semibold tw-text-red-500 hover:tw-bg-red-50 hover:tw-text-red-500 aria-selected:tw-bg-red-50 aria-selected:tw-text-red-500"
                disabled={deleteField.isPending}
                onSelect={handleDeleteClick}
              >
                {formatMessage({
                  defaultMessage: "Delete Column",
                })}
              </CommandItem>
            )}
          </CommandList>
        </Command>
      </div>

      {companyId && field && manageState.isOpen && (
        <EditCustomColumnDialog
          companyId={companyId}
          field={field}
          open
          recordType={recordType}
          onOpenChange={manageState.onIsOpenChange}
        />
      )}
    </>
  );
}
