import { styled } from "../../styles";

const styledProps = ["hasRows", "rowSelectionEnabled"];
export const TableWrapper = styled("div", {
  shouldForwardProp: (propName: string) => !styledProps.includes(propName),
})<{ hasRows: boolean; rowSelectionEnabled?: boolean }>(
  ({ theme, hasRows, rowSelectionEnabled }) => ({
    position: "relative",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    overflow: "hidden",
    paddingTop: 1,
    paddingBottom: 1,

    "& > div": {
      display: "flex",
      flexDirection: "column",
      maxWidth: "100%",
      maxHeight: "100%",
      overflow: "hidden",
    },

    ".ag-root-wrapper": {
      maxWidth: "100%",
      maxHeight: "100%",
      borderLeft: "none",
      borderRight: "none",
      overflowY: "auto",

      ".ag-root": {
        overflow: "visible",
      },
    },

    ".ag-header": {
      position: "sticky",
      top: 0,
      zIndex: 1,
    },

    "&:before, .ag-body-viewport:after, .ag-header:after": {
      display: "block",
      content: "''",
      position: "absolute",
      height: 1,
      width: rowSelectionEnabled ? "calc(100% - 40px)" : "100%",
      left: rowSelectionEnabled ? 40 : 0,
      backgroundColor: theme.palette.divider,
    },
    "&:before": {
      top: 0,
    },
    ".ag-body-viewport:after, .ag-header:after": {
      bottom: 0,
    },
    ".ag-body-viewport:after": {
      display: hasRows ? "block" : "none",
    },

    ".selection-cell, .selection-header": {
      backgroundColor: theme.palette.background.paper,
      boxShadow: `0px 1px 0 0px ${theme.palette.background.paper}`,
      borderRight: "none !important",

      "&:before, &:after": {
        display: "none !important",
      },
    },

    ".ag-layout-auto-height .ag-center-cols-viewport": {
      minHeight: "40px !important",
    },
    ".ag-header-cell-comp-wrapper": {
      alignItems: "stretch",
    },
    ".ag-cell": {
      display: "flex",
      alignItems: "center",

      ".ag-cell-wrapper": {
        width: "100%",
        height: "100%",
        alignItems: "center",
        display: "flex",
      },
    },

    ".ag-pinned-left-header": {
      borderRight: "none",
    },

    ".ag-pinned-right-header": {
      ".ag-header-cell:after": {
        display: "none",
      },
    },
  }),
);
