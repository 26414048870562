import type { PublicFormLayoutResponse } from "../models/PublicFormLayoutResponse";

export function createPublicFormLayoutResponse(
  data: NonNullable<Partial<PublicFormLayoutResponse>> = {},
): NonNullable<PublicFormLayoutResponse> {
  return {
    ...{ data: {} },
    ...data,
  };
}
