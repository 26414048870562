import { cx, type VariantProps } from "class-variance-authority";
import React from "react";
import { type IconType } from "react-icons";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";

import { cn } from "../../lib/utils";
import type { ButtonBaseProps } from "./ButtonBase";
import { buttonBaseVariants } from "./ButtonBase";
import { iconButtonVariants } from "./IconButton";

export type LinkIconButtonProps = LinkProps &
  ButtonBaseProps &
  VariantProps<typeof iconButtonVariants> & {
    Icon: IconType;
    children?: undefined;
  };

const LinkIconButton = React.forwardRef<HTMLAnchorElement, LinkIconButtonProps>(
  ({ className, color, size, variant, Icon, ...props }, ref) => {
    return (
      <Link
        className={cn(
          cx(
            buttonBaseVariants({ color }),
            iconButtonVariants({ size, variant, className }),
          ),
        )}
        ref={ref}
        {...props}
      >
        <Icon className="tw-h-4 tw-w-4" />
      </Link>
    );
  },
);

LinkIconButton.displayName = "LinkIconButton";

export { LinkIconButton, iconButtonVariants };
