import "./questionnaire-due-date-modal.scss";

import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  DatePicker,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@themis/ui";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { QuestionnaireAPI } from "@/api/legacy/risk-assessment/QuestionnaireApi";
import { Modal } from "@/components/Elements";
import { useMainStore } from "@/contexts/Store";
import type {
  CWQuestionnaireRead,
  QuestionnaireRead,
} from "@/features/risk-assessment/types/questionnaire";

interface Props {
  questionnaire: QuestionnaireRead | CWQuestionnaireRead | null;
  onClose: (date: Date | undefined) => void;
}

const formSchema = z.object({
  due_date: z
    .string()
    .nullable()
    .refine((val) => {
      if (!val) {
        return true;
      }
      const date = dayjs(val);
      return !date.isBefore(new Date());
    }),
});
type DueDateSchema = z.infer<typeof formSchema>;

function QuestionnaireDueDateModalBase({
  questionnaire,
  onClose,
}: Props & { questionnaire: NonNullable<Props["questionnaire"]> }) {
  const mainStore = useMainStore();
  const [dateChanged, setDateChanged] = useState(false);

  const form = useForm<DueDateSchema>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      due_date: questionnaire.due_date
        ? questionnaire.due_date.toString()
        : null,
    },
  });

  async function saveRatingsHelper(newForm: DueDateSchema) {
    const date = newForm.due_date ? dayjs(newForm.due_date) : null;
    if (!questionnaire || !date) {
      return;
    }

    const newDate = dayjs(date.toISOString()).toDate();

    try {
      await QuestionnaireAPI.updateDueDate(questionnaire.id, newDate);
      onClose(newDate);
    } catch {
      mainStore.toast.setErrorText("Someting went wrong");
    }
  }

  async function saveRatings() {
    form.handleSubmit(saveRatingsHelper)();
  }

  useEffect(() => {
    form.trigger();
  }, [form]);

  return (
    <Modal
      title="Edit Due Date"
      modalClassnames="questionnaire-due-date-modal tw-max-h-[260px] tw-max-w-[480px] !tw-px-[26px] !tw-py-6 !tw-rounded-xl"
      open={questionnaire !== null}
      onClose={() => onClose(undefined)}
    >
      <div className="tw-flex tw-gap-1">
        <div className="tw-text-xs tw-font-medium tw-text-neutral-300">
          Questionnaire:
        </div>
        <div className="tw-text-xs tw-font-medium tw-text-neutral-500">
          {questionnaire.name}
        </div>
      </div>
      <div className="tw-mt-[36px]" />
      <Form {...form}>
        <FormField
          control={form.control}
          name="due_date"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Due Date</FormLabel>
              <FormControl>
                <DatePicker
                  calendarProps={{
                    mode: "single",
                    selected: field.value
                      ? dayjs(field.value).toDate()
                      : undefined,
                    onSelect: (newDate) => {
                      setDateChanged(true);
                      if (newDate) {
                        field.onChange(newDate.toISOString() || null);
                      }
                      form.trigger();
                    },
                  }}
                />
              </FormControl>
            </FormItem>
          )}
        />
      </Form>
      <div className="tw-mt-9 tw-flex tw-justify-end">
        <Button
          size="lg"
          disabled={!dateChanged}
          onClick={saveRatings}
          aria-label="Update"
        >
          Update
        </Button>
      </div>
    </Modal>
  );
}

function QuestionnaireDueDateModal({ questionnaire, ...rest }: Props) {
  if (!questionnaire) {
    return null;
  }

  return (
    <QuestionnaireDueDateModalBase questionnaire={questionnaire} {...rest} />
  );
}

export default QuestionnaireDueDateModal;
