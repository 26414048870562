import { observer } from "mobx-react";
import { useCallback, useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import { useMainStore } from "../contexts/Store";

interface AppDataLoaderProps {
  production: boolean;
}

const AppDataLoader = ({ production }: AppDataLoaderProps) => {
  const mainStore = useMainStore();
  const { accessToken } = mainStore.webSessions;
  const { boot } = useIntercom();

  const loadAppWideData = useCallback(async () => {
    if (!accessToken) {
      return;
    }

    const company = await mainStore.companies.show();
    const user = await mainStore.users.show();

    if (production) {
      window.pendo.initialize({
        visitor: {
          id: `user-${user.id}`,
          email: user.email,
          full_name: user.full_name,
        },
        account: {
          id: `company-${company.id}`,
          name: company.name,
          is_paying: company.is_paying,
        },
      });

      if (user.intercom_user_hash) {
        boot({
          userId: user.id?.toString(),
          userHash: user.intercom_user_hash,
          name: user.full_name || "",
          email: user.email,
        });
      }
    }

    mainStore.featureFlags.fetchFeatureFlags();
  }, [accessToken, boot, mainStore, production]);

  useEffect(() => {
    loadAppWideData();
  }, [accessToken]);

  return null;
};

export default observer(AppDataLoader);
