import { sortBy } from "lodash";

export const defaultSort = <T extends { label: string }>(
  sort: "asc" | "desc",
  items: T[],
) => {
  const _items = [...items];

  const sortDirection = sort === "asc" ? 1 : -1;

  return _items.sort((a, b) => {
    const compareValue = a.label.localeCompare(b.label, undefined, {
      sensitivity: "base",
      caseFirst: "false",
      numeric: true,
      usage: "sort",
    });

    return sortDirection * compareValue;
  });
};

export const selectionSort = <T extends { label: string; value: string }>(
  sort: "asc" | "desc",
  items: T[],
  selected: string[],
) => {
  const _items = [...items];

  const defaultSortedItems = defaultSort(sort, _items);

  return sortBy(defaultSortedItems, (item) => !selected.includes(item.value));
};

export const selectSorts = {
  defaultSort,
  selectionSort,
};
