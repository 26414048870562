import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  ImagePicker,
  useToast,
} from "@themis/ui";
import { DirectUploadProvider } from "react-activestorage-provider";
import type { Control, FieldValues, Path } from "react-hook-form";
import { useIntl } from "react-intl";

interface ImageFormFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label: string;
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

export function ImageFormField<T extends FieldValues>({
  control,
  name,
  label,
  required,
  placeholder,
  disabled,
}: ImageFormFieldProps<T>) {
  const { formatMessage } = useIntl();
  const toast = useToast();

  const handleError = () => {
    toast({
      content: formatMessage({
        defaultMessage:
          "Error uploading file. Please try again or use a different file type.",
      }),
      variant: "error",
    });
  };

  return (
    <FormField
      control={control}
      name={name}
      required={required}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <DirectUploadProvider
              onSuccess={(signedIds: string[]) => {
                field.onChange({
                  signedId: signedIds[0],
                });
              }}
              onError={handleError}
              render={({ handleUpload, ready }) => (
                <ImagePicker
                  locked={disabled}
                  readOnly={!ready}
                  className="tw-h-36"
                  onSelectFile={(file) => {
                    handleUpload([file]);
                  }}
                  url={field.value.url}
                  defaultUrl={field.value.url}
                  placeholder={placeholder}
                  placeholderSubtext={formatMessage({
                    defaultMessage: "Drop file here or click to select.",
                  })}
                />
              )}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
