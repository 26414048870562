import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import type {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
} from "@tanstack/react-query";

import {
  type GetPublicFormLayoutPathParams,
  type GetPublicFormLayoutQueryResponse,
} from "@/api";
import { getPublicFormLayout } from "@/api/gen/axios/publicFormLayoutController";

export const getPublicFormLayoutQueryKey = (
  moduleWorkspaceId?: GetPublicFormLayoutPathParams["module_workspace_id"],
): QueryKey => ["public-form-layout", moduleWorkspaceId].filter(Boolean);

export const usePublicFormLayout = <
  TError = Error,
  TData = GetPublicFormLayoutQueryResponse["data"],
>(
  moduleWorkspaceId:
    | GetPublicFormLayoutPathParams["module_workspace_id"]
    | undefined,
  options?: Partial<
    UseQueryOptions<GetPublicFormLayoutQueryResponse["data"], TError, TData>
  >,
) =>
  useQuery({
    queryFn: moduleWorkspaceId
      ? async () => {
          const response = await getPublicFormLayout(moduleWorkspaceId);

          return response.data;
        }
      : skipToken,
    queryKey: getPublicFormLayoutQueryKey(moduleWorkspaceId),
    ...options,
  });

export const usePublicFormLayoutMutation = <TError = Error>({
  onSettled,
  ...options
}: Partial<
  UseMutationOptions<
    GetPublicFormLayoutQueryResponse["data"],
    TError,
    GetPublicFormLayoutPathParams["module_workspace_id"]
  >
> = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (moduleWorkspaceId: number) => {
      const response = await getPublicFormLayout(moduleWorkspaceId);
      return response.data;
    },
    onSettled: (...args) => {
      const [, , moduleWorkspaceId] = args;

      queryClient.invalidateQueries({
        queryKey: getPublicFormLayoutQueryKey(moduleWorkspaceId),
      });

      onSettled?.(...args);
    },
    ...options,
  });
};
