import type { DataWithIdAndCustomFields } from "@themis/ui";

import type { RecordTypePath } from "@/api";
import type { FilterFieldData } from "@/components/FilterSelect/utils/get-filters-field-data";

import { getLocalStorageFiltersKey } from "../save-filters-to-local-storage/save-filters-to-local-storage";
import type { Filters } from "../types";
import { FilterOperand } from "../types";

interface GetFiltersFromLocalStorageParams<T> {
  recordType: RecordTypePath;
  workspaceId: string;
  fieldsData: FilterFieldData<T>;
}

export function getFiltersFromLocalStorage<
  T extends DataWithIdAndCustomFields,
>({
  recordType,
  workspaceId,
  fieldsData,
}: GetFiltersFromLocalStorageParams<T>): {
  filters: Filters<T>;
  listRequestQueryParams: {
    filters: {
      [fieldName: string]: {
        [operand: string]: string;
      };
    };
  };
} {
  const storageKey = getLocalStorageFiltersKey();
  const filtersString = localStorage.getItem(storageKey);
  const storedData = filtersString ? JSON.parse(filtersString) : {};

  const storedFilters: Filters<T> = storedData[workspaceId]?.[recordType] || {};

  const filters: Filters<T> = {};
  Object.entries(storedFilters).forEach(([fieldName, filterValue]) => {
    if (!(fieldName in fieldsData)) {
      return;
    }

    filters[fieldName as keyof T] = filterValue;
  });

  const listRequestQueryParams = {
    filters: Object.fromEntries(
      Object.entries(filters).map(([fieldName, filter]) => {
        if (!filter) {
          return [fieldName, {}];
        }
        return [
          fieldName,
          {
            [filter.operand]: [
              FilterOperand.ANY,
              FilterOperand.NOT,
              FilterOperand.MATCH,
            ].includes(filter.operand)
              ? (filter.value as string[]).join(",")
              : "",
          },
        ];
      }),
    ),
  };

  return { filters, listRequestQueryParams };
}
