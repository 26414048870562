import { http } from "msw";

import { createShowMonitoringAssetQueryResponse } from "../createShowMonitoringAsset";

export const showMonitoringAssetHandler = http.get(
  "*/monitoring_assets/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createShowMonitoringAssetQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
