import { observer } from "mobx-react";

import LocationTracker from "../components/LocationTracker";
import { useMainStore } from "../contexts/Store";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import UnauthenticatedRoutes from "./UnauthenticatedRoutes";

function ApplicationRouting() {
  const mainStore = useMainStore();

  return (
    <>
      {Object.keys(mainStore.users.user).length > 0 && <AuthenticatedRoutes />}
      <UnauthenticatedRoutes />
      <LocationTracker />
    </>
  );
}

export default observer(ApplicationRouting);
