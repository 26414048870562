import { cn } from "@themis/ui";
import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { PiCaretUpBold } from "react-icons/pi";
import { useHistory } from "react-router-dom";

import type { Field, RecordVersion, TableName } from "@/api";
import SectionTag from "@/components/table/shared/SectionTag";
import { useMainStore } from "@/contexts/Store";
import { DEFAULT_CW_SECTION_TAG_TITLE } from "@/features/qa/constants";
import type { Section } from "@/stores/types/section-tags";

import { useSortingAndPagination } from "../../../hooks/useSortingAndPagination";
import { getRecordName } from "../../helpers/nameForThemisModuleIdentifier";
import MenuActions from "../menu-actions/MenuActions";
import { useCustomColumns } from "../shared/custom-columns/CustomColumns";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";
import ReorderableTable from "../shared/ReorderableTable";
import SectionPopupSettings from "../shared/SectionPopupSettings";
import TableMenu from "../shared/tableMenu/TableMenu";

interface Props {
  fields?: Field[];
  isDropdownOpen?: boolean;
  isCompletedTab: boolean;
  moduleWorkspaceID?: number;
  recordVersions?: RecordVersion[];
  sectionTag?: Section;
  tableName?: TableName;
  workspaceID?: number;
}

function QASection({
  fields,
  moduleWorkspaceID,
  recordVersions,
  sectionTag,
  tableName,
  isCompletedTab,
  workspaceID,
  ...props
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [isDropdownOpen, setIsDropdownOpen] = useState(props.isDropdownOpen);
  const [inEditMode, setInEditMode] = useState(false);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectMode, setSelectMode] = useState(false);

  // Hooks
  const history = useHistory();

  // Variables
  const { canManageSections } = mainStore.userPermissions;
  const { visibleFields } = mainStore.fields;
  const { isIW } = mainStore.context;
  const sectionTableID = `section-table-${sectionTag?.id || 0}`;
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;
  const isCollaborativeSectionTag = !sectionTag?.is_internal;
  const isDefaultCollaborativeSectionTag =
    sectionTag?.title === DEFAULT_CW_SECTION_TAG_TITLE &&
    isCollaborativeSectionTag;

  const { createColumn, renameColumn, deleteColumn, AddColumnButton } =
    useCustomColumns({
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ store: QA; tableName: "Drafts"... Remove this comment to see the full error message
      store: mainStore.qa,
      tableName,
      moduleWorkspaceID,
      sectionTableID,
    });

  // Hooks
  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
  } = useSortingAndPagination({
    store: mainStore.qa,
    sectionTagId: sectionTag?.id,
  });

  const resetMenuActions = () => {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  const dropdownClick = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const addNewQAPlan = async () => {
    if (workspaceID) {
      const recordVersion = await mainStore.qa.create(
        workspaceID,
        sectionTag?.id,
      );
      history.push(
        `/workspaces/${workspaceID}/modules/qa-tests/${recordVersion.id}/new-record`,
      );
    }
  };

  const renameClick = () => {
    setInEditMode(true);
  };

  const deleteSectionTag = async () => {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    await mainStore.sectionTags.delete(moduleWorkspaceID, sectionTag.id);
    mainStore.toast.setText("Section has been deleted!");
  };

  const renderFields = visibleFields.map((field) => (
    <ModuleTableColumn
      key={field.name}
      field={field}
      // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
      sortDirection={field.name === sortByFieldName ? sortByDirection : ""}
      setSortByDirection={setSortByDirection}
      setSortByFieldName={setSortByFieldName}
      onDelete={deleteColumn}
      onRename={renameColumn}
    />
  ));

  const filteredRecordVersions = () =>
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    recordVersions.filter((recordVersion) => {
      const statusValue = mainStore.avroSchemas.firstValueForField(
        "status",
        recordVersion.data,
      );
      const completed = statusValue === "completed";
      return isCompletedTab ? completed : !completed;
    });

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersion' implicitly has an 'any' ... Remove this comment to see the full error message
  const renderRow = (recordVersion, idx) => {
    const statusValue = mainStore.avroSchemas.firstValueForField(
      "status",
      recordVersion.data,
    );
    const completed = statusValue === "completed";
    // @ts-expect-error TS(7006) FIXME: Parameter 'columnTitle' implicitly has an 'any' ty... Remove this comment to see the full error message
    function minDateFunction(columnTitle) {
      if (columnTitle === "Target Completion Date") {
        return mainStore.avroSchemas.valueForField(
          "start_date",
          recordVersion.data,
        );
      }
    }

    // @ts-expect-error TS(7006) FIXME: Parameter 'columnTitle' implicitly has an 'any' ty... Remove this comment to see the full error message
    function maxDateFunction(columnTitle) {
      if (columnTitle === "Start Date") {
        return mainStore.avroSchemas.valueForField(
          "target_completion_date",
          recordVersion.data,
        );
      }
    }

    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        fields={fields}
        inSelectMenuActionMode={selectMode}
        isDraggable={isIW}
        isLockedArchiveTable={completed}
        isLockedRow={completed}
        maxDateFunction={maxDateFunction}
        minDateFunction={minDateFunction}
        moduleIdentifier="qa_tests_development"
        moduleWorkspaceID={moduleWorkspaceID}
        order={idx}
        recordVersion={recordVersion}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
      />
    );
  };

  const QAColumnButtonClasses = classNames("qa-column-button", {
    "qa-column-button-visible": isIW,
  });

  return (
    <>
      {sectionTag && (
        <div className="tw-ml-[18px] tw-flex" data-testid="dropdown-section">
          <div
            className="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-p-[7px] hover:tw-rounded-md hover:tw-bg-neutral-100"
            onClick={dropdownClick}
          >
            <PiCaretUpBold
              className={classNames(
                "tw-h-[18px] tw-w-[18px] tw-origin-center tw-text-neutral-500",
                {
                  "tw-rotate-180": !isDropdownOpen,
                  "tw-rotate-0 ": isDropdownOpen,
                },
              )}
            />
          </div>
          {sectionTag?.title && (
            <SectionTag
              isEditMode={inEditMode}
              setIsEditMode={setInEditMode}
              sectionTagID={sectionTag?.id}
              sectionTagTitle={sectionTag?.title}
            />
          )}

          {sectionTag?.editable && !inEditMode && canManageSections && isIW && (
            <SectionPopupSettings
              onRename={renameClick}
              onDelete={deleteSectionTag}
            />
          )}
        </div>
      )}

      {isDropdownOpen && (
        <div
          className={cn("dropdown-table", {
            draggable: isIW,
          })}
          data-testid="qa-dropdown-table"
          id={sectionTableID}
        >
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="procedures-list-column-first-blocks table-column-reorder">
                  {visibleFields.length > 0 && (
                    <TableMenu
                      setShowCheckbox={setShowCheckbox}
                      resetMenuActions={resetMenuActions}
                      selectMode={selectMode}
                      setSelectMode={setSelectMode}
                    />
                  )}
                </div>
                {showCheckbox && selectMode ? (
                  <div>
                    <MenuActions resetMenuActions={resetMenuActions} />
                  </div>
                ) : (
                  <div className="list-title-table-wrap">
                    {renderFields}
                    <span className="stretch-cell" />
                  </div>
                )}
              </div>
              <div className={QAColumnButtonClasses}>
                <AddColumnButton onAdd={createColumn} />
              </div>
            </ul>
          </div>

          {!isCompletedTab && (
            <ReorderableTable
              recordVersions={filteredRecordVersions()}
              isSorted={!!sortByFieldName}
              renderer={renderRow}
              sectionTag={sectionTag}
              onAddNewRow={
                isIW || isDefaultCollaborativeSectionTag
                  ? addNewQAPlan
                  : undefined
              }
              newRowName={getRecordName(
                "qa_tests_development",
                moduleWorkspaces,
                true,
              )}
            />
          )}

          {isCompletedTab && (
            <ReorderableTable
              recordVersions={filteredRecordVersions()}
              isSorted={!!sortByFieldName}
              renderer={renderRow}
              sectionTag={sectionTag}
            />
          )}
        </div>
      )}
    </>
  );
}

QASection.defaultProps = {
  fields: [],
};

export default observer(QASection);
