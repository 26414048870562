import { useMemo } from "react";
import { generatePath, useParams } from "react-router";

import { useAccount } from "@/api/queries/accounts";

import { routes } from "../../routes";

export function useAccountDocumentDetailPath(
  documentId: number,
): string | undefined {
  const { workspace_id, accountId } = useParams<{
    accountId: string;
    workspace_id: string;
  }>();

  const { data: accountData } = useAccount({
    workspaceId: Number(workspace_id),
    accountId: Number(accountId),
    queryParams: {
      expand: "documents",
    },
  });

  const account = accountData?.data;

  const accountDocumentDetailPath = useMemo(() => {
    if (!account?.documents?.length) {
      return;
    }

    const documentIndex = account.documents.findIndex(
      (document) => document.id === documentId,
    );

    const path = generatePath(routes.accounts.detail.documents.detail, {
      workspace_id,
      accountId,
    });

    const url = new URL(path, window.location.origin);
    url.searchParams.set("document_index", documentIndex.toString());

    return url.pathname + url.search;
  }, [account, documentId, workspace_id, accountId]);

  return accountDocumentDetailPath;
}
