import type { TypographyProps as MuiTypographyProps } from "@mui/material/Typography";
import MuiTypography from "@mui/material/Typography";
import type { ForwardedRef } from "react";
import { forwardRef } from "react";

import { styled } from "../../../styles";

export { typographyClasses } from "@mui/material/Typography";

export interface TypographyProps extends MuiTypographyProps {
  fontWeight?: "light" | "medium" | "bold";
}

const StyledTypography = styled(MuiTypography)(({ theme }) => ({
  "&[data-font-weight=light]": {
    fontWeight: theme.typography.fontWeightLight,
  },
  "&[data-font-weight=medium]": {
    fontWeight: theme.typography.fontWeightMedium,
  },
  "&[data-font-weight=bold]": {
    fontWeight: theme.typography.fontWeightBold,
  },
}));

function _Typography(
  { color = "textPrimary", fontWeight, ...rest }: TypographyProps,
  ref: ForwardedRef<HTMLElement>,
) {
  return (
    <StyledTypography
      ref={ref}
      color={color}
      data-font-weight={fontWeight}
      {...rest}
    />
  );
}

export const Typography = forwardRef(_Typography);
