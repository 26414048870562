import { z } from "zod";

import type {
  FieldConfiguration,
  FieldConfigurationReferenceType,
} from "@/api";

import { getThemisDocumentSchema } from "./get-themis-document-schema/get-themis-document-schema";
import { getUserSchema } from "./get-user-schema/get-user-schema";

const getters: Record<
  FieldConfigurationReferenceType,
  ((field: FieldConfiguration, requiredMessage: string) => z.ZodType) | null
> = {
  ThemisDocument: getThemisDocumentSchema,
  Account: null,
  Attachment: null,
  RecordReference: null,
  ThemisRecord: null,
  Contact: null,
  Contract: null,
  Department: null,
  Issue: null,
  Policy: null,
  Questionnaire: null,
  RecordLink: null,
  RecordVersion: null,
  Review: null,
  Task: null,
  User: getUserSchema,
  VendorChecklist: null,
  MonitoringAsset: null,
  MonitoringFinding: null,
  Workspace: null,
};

export const getReferenceSchema = (
  field: FieldConfiguration,
  requiredMessage: string,
) => {
  if (!field.reference_type) {
    return z.any();
  }

  const getSchema = getters[field.reference_type];
  if (getSchema) {
    return getSchema(field, requiredMessage);
  }

  return z.any();
};
