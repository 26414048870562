import { Plus } from "@phosphor-icons/react";
import { FilePicker, useToast } from "@themis/ui";
import { IconButton } from "@themis/ui-library/components/inputs/icon-button/icon-button";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import type { RenderProps } from "react-activestorage-provider";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { recordTypePath } from "@/api";
import { useCreateThemisDocument } from "@/api/queries/documents/use-create-themis-document/use-create-themis-document";
import { getUploadLoadingAndPercentage } from "@/utils/get-upload-loading-and-percentage/get-upload-loading-and-percentage";

// TODO: Replace with generic component

export function DocumentsActions({ disabled = false }: { disabled?: boolean }) {
  const toast = useToast();
  const { formatMessage } = useIntl();
  const filePicker = useOpen();

  const { accountId } = useParams<{
    accountId: string;
  }>();

  const { mutateAsync: createDocument } = useCreateThemisDocument({
    recordId: Number(accountId),
    recordType: recordTypePath.accounts,
  });

  async function handleFileChange(signedIds: string[]) {
    try {
      await Promise.all(
        signedIds.map((signedId) =>
          createDocument({ data: { file: signedId, field_name: "documents" } }),
        ),
      );

      filePicker.close();

      toast({
        content: formatMessage(
          {
            defaultMessage:
              "{count, plural, =1 {Attachment has} other {Attachments have}} been added!",
          },
          {
            count: signedIds.length,
          },
        ),
        variant: "success",
      });
    } catch (error) {
      toast({
        content: formatMessage(
          {
            defaultMessage:
              "Something went wrong. Could not add {count, plural, =1 {attachment} other {attachments}}.",
          },
          { count: signedIds.length },
        ),
        variant: "error",
      });
    }
  }

  return (
    <DirectUploadProvider
      multiple
      onSuccess={handleFileChange}
      render={({ handleUpload, ready, uploads }: RenderProps) => {
        const { isLoading, percentage } =
          getUploadLoadingAndPercentage(uploads);

        return (
          <FilePicker
            multiple
            isOpen={filePicker.isOpen}
            onClose={filePicker.close}
            isLoading={isLoading}
            percentage={percentage}
            readOnly={!ready}
            onSelectFiles={(selectedFiles) => handleUpload(selectedFiles)}
            trigger={
              <IconButton
                disabled={disabled}
                size="small"
                color="primary"
                onClick={filePicker.open}
              >
                <Plus weight="bold" />
              </IconButton>
            }
            alignPopover="end"
          />
        );
      }}
    />
  );
}
