import type { RecordVersion } from "@/api";

import type { MainStore } from "../Main";

interface StoreData {
  module_workspace_id: number | undefined;
  record_versions: RecordVersion[];
}

interface SectionMetadata {
  [key: number]: {
    countOfRecords: number;
    currentPage: number;
  };
}

export default class Paginated {
  mainStore: MainStore;

  constructor(mainStore: MainStore) {
    this.mainStore = mainStore;
  }

  // Observables
  data: StoreData = {
    module_workspace_id: undefined,
    record_versions: [],
  };
  sectionsMetadata: SectionMetadata = {};

  // Actions
  setData(value: StoreData) {
    this.data = value;
    this.mainStore.recordVersions.setList(value.record_versions);
  }

  setSectionsMetadata(value: SectionMetadata) {
    this.sectionsMetadata = value;
  }

  setPageForSection(page: number, sectionTagId: number | null) {
    if (sectionTagId === null) {
      return;
    }

    this.sectionsMetadata = {
      ...this.sectionsMetadata,
      [sectionTagId]: {
        ...this.sectionsMetadata[sectionTagId],
        currentPage: page,
      },
    };
  }

  upsertRecordCounts(newCounts: Record<number, number>) {
    const newSectionsMetadata = { ...this.sectionsMetadata };

    Object.entries(newCounts).forEach(([sectionTagId, countOfRecords]) => {
      const id = Number(sectionTagId);
      newSectionsMetadata[id] = newSectionsMetadata[id] || {
        countOfRecords: 0,
        currentPage: 1,
      };
      newSectionsMetadata[id].countOfRecords = countOfRecords;
    });

    this.sectionsMetadata = newSectionsMetadata;
  }

  // Store Helpers
  countOfRecordsForSection(sectionTagId: number): number {
    return this.sectionsMetadata[sectionTagId]?.countOfRecords || 0;
  }

  pageForSection(sectionTagId: number): number {
    return this.sectionsMetadata[sectionTagId]?.currentPage || 1;
  }

  updateRecordVersions<TData extends StoreData>(
    sectionTagId: number | null,
    responseData: TData,
  ): void {
    if (sectionTagId === null || !this.data?.record_versions) {
      this.setData(responseData);
      return;
    }

    const filterRecords = (item: RecordVersion) =>
      sectionTagId === 0
        ? item.section_tag_id !== null
        : item.section_tag_id !== sectionTagId;

    const addRecords = (item: RecordVersion) =>
      sectionTagId === 0
        ? item.section_tag_id === null
        : item.section_tag_id === sectionTagId;

    this.setData({
      ...this.data,
      record_versions: [
        ...this.data.record_versions.filter(filterRecords),
        ...responseData.record_versions.filter(addRecords),
      ],
    });
  }

  cleanup() {
    this.setData({
      module_workspace_id: undefined,
      record_versions: [],
    });
    this.setSectionsMetadata({});
  }
}
