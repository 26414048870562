import { http } from "msw";

import { createShowMonitoringFindingQueryResponse } from "../createShowMonitoringFinding";

export const showMonitoringFindingHandler = http.get(
  "*/monitoring_findings/:id",
  function handler(info) {
    return new Response(
      JSON.stringify(createShowMonitoringFindingQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
