import { faker } from "@faker-js/faker";

import type {
  CreateThemisDocument201,
  CreateThemisDocument403,
  CreateThemisDocumentMutationRequest,
  CreateThemisDocumentMutationResponse,
  CreateThemisDocumentPathParams,
} from "../models/CreateThemisDocument";
import { createRecordTypePath } from "./createRecordTypePath";
import { createThemisDocument } from "./createThemisDocument";

export function createCreateThemisDocumentPathParams(): NonNullable<CreateThemisDocumentPathParams> {
  return { record_type: createRecordTypePath(), record_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createCreateThemisDocument201(): NonNullable<CreateThemisDocument201> {
  return { data: createThemisDocument() };
}

/**
 * @description Forbidden
 */
export function createCreateThemisDocument403(): NonNullable<CreateThemisDocument403> {
  return { errors: {} };
}

export function createCreateThemisDocumentMutationRequest(): NonNullable<CreateThemisDocumentMutationRequest> {
  return {
    data: { file: faker.string.alpha(), field_name: faker.string.alpha() },
  };
}

/**
 * @description OK
 */
export function createCreateThemisDocumentMutationResponse(): NonNullable<CreateThemisDocumentMutationResponse> {
  return { data: createThemisDocument() };
}
