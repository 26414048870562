import type { Review } from "@themis/api/gen/models/review";
import { isReviewWithModuleWorkspace } from "@themis/api/utils/reviews/is-review-with-module-workspace";
import { isReviewWithReviewable } from "@themis/api/utils/reviews/is-review-with-reviewable";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { MiniTag } from "../../../Elements";
import { getWorkspaceLogo } from "../../../helpers/getWorkspaceLogo";
import { iconForThemisModuleIdentifier } from "../../../helpers/iconForThemisModuleIdentifier";
import Loading from "../../../Loading";

const AVAILABLE_FILTERS = [
  { id: "requestedReviews", filterName: "Requested" },
  { id: "approvedReviews", filterName: "Approved" },
  { id: "allReviews", filterName: "All Reviews" },
];

function AllReviews() {
  // Import MobX stores
  const mainStore = useMainStore();

  // Hooks
  const history = useHistory();

  // Variables
  const { reviews } = mainStore;
  const { list: workspaces } = mainStore.workspaces;
  const currentWorkspaceID = mainStore.context.workspaceID;

  // State
  const [selectedFilter, setSelectedFilter] = useState("requestedReviews");
  const [isLoading, setIsLoading] = useState(true);

  // Hooks
  useEffect(() => {
    fetchReviews();
  }, []);

  // Variables
  const totalCount = reviews.allReviews.length || 0;

  // Functions
  const fetchReviews = async () => {
    setIsLoading(true);
    await mainStore.reviews.fetchAllReviews();

    setIsLoading(false);
  };

  const handleChangeFilter = (filter_id: string) =>
    setSelectedFilter(filter_id);

  const getWorkspace = (wID: number) => {
    const workspace = workspaces.find((ws) => ws.id === wID);

    if (workspace) {
      const { logo } = getWorkspaceLogo(workspace);

      return {
        id: workspace.id,
        name: workspace.is_internal ? workspace.company.name : workspace.name,
        logo,
      };
    }

    return null;
  };

  async function handleView(review: Review) {
    if (
      isReviewWithModuleWorkspace(review) &&
      review.module_workspace.workspace_id !== currentWorkspaceID
    ) {
      await mainStore.workspaces.switch(review.module_workspace.workspace_id);
    }

    if (isReviewWithReviewable(review)) {
      history.push(review.reviewable.path);
    }
  }

  return (
    <>
      {isLoading && (
        <Loading loadingLayout="small-table" showTableHeader={false} />
      )}
      {!isLoading && (
        <div className="reviews">
          {totalCount > 0 ? (
            <>
              <div className="reviews-filter">
                {AVAILABLE_FILTERS.map((filter) => (
                  <div
                    key={filter.id}
                    className={classNames("reviews-filter-tab", {
                      "reviews-filter-tab-active": filter.id === selectedFilter,
                    })}
                    onClick={() => handleChangeFilter(filter.id)}
                  >
                    {filter.filterName}
                    <span className="reviews-filter-tab-item-count">
                      {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                      {reviews[filter.id]?.length || 0}
                    </span>
                  </div>
                ))}
              </div>

              <div className="reviews-list">
                {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                {(reviews[selectedFilter] || []).map((review: Review) => {
                  const { reviewable } = review;
                  if (!isReviewWithModuleWorkspace(review)) {
                    return null;
                  }
                  const moduleWorkspace = review.module_workspace;
                  const workspace = getWorkspace(moduleWorkspace.workspace_id);
                  return (
                    <div key={review.id} className="reviews-item">
                      <div className="reviews-item-content">
                        <div className="reviews-item-info">
                          {reviewable?.identifier && (
                            <MiniTag
                              label={reviewable.identifier}
                              theme="navy"
                            />
                          )}
                          <div className="reviews-item-name">
                            {reviewable?.name || "-- Untitled --"}
                          </div>
                          {(workspace || moduleWorkspace) && (
                            <div className="reviews-item-workspace-and-module">
                              {workspace && (
                                <div className="reviews-item-workspace">
                                  <img
                                    src={workspace.logo}
                                    alt={workspace.name}
                                  />
                                  {workspace.name}
                                </div>
                              )}
                              {moduleWorkspace && (
                                <div className="reviews-item-module">
                                  <img
                                    src={iconForThemisModuleIdentifier(
                                      /* @ts-expect-error OpenAPI generated code for identifier, not compatible with enum */
                                      moduleWorkspace.themis_module.identifier,
                                    )}
                                    alt={moduleWorkspace.themis_module.name}
                                  />
                                  {moduleWorkspace.name}
                                </div>
                              )}
                            </div>
                          )}
                        </div>

                        <button
                          className="reviews-item-view-button"
                          onClick={() => handleView(review)}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <div className="reviews-empty-message">
              <h3>No Review Items created / assigned yet</h3>
              <p>
                You don&lsquo;t have any tagged review items, they will be shown
                here once available.
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default observer(AllReviews);
