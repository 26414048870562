import type { ColDef } from "ag-grid-community";

export const actionsColumnDefParams = {
  headerName: "",
  colId: "actions",
  valueGetter: () => "",
  valueFormatter: () => "",
  width: 100,
  headerComponent: null,
  sortable: false,
  resizable: false,
  pinned: "right",
  lockVisible: true,
  lockPosition: true,
  cellStyle: () => ({ justifyContent: "center", padding: "3px 8px" }),
} satisfies ColDef;
