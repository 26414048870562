import { faker } from "@faker-js/faker";

import type {
  QuestionnaireExport200,
  QuestionnaireExport403,
  QuestionnaireExportPathParams,
  QuestionnaireExportQueryParams,
  QuestionnaireExportQueryResponse,
} from "../models/QuestionnaireExport";
import { createFile } from "./createFile";

export function createQuestionnaireExportPathParams(): NonNullable<QuestionnaireExportPathParams> {
  return { id: faker.number.int() };
}

export function createQuestionnaireExportQueryParams(): NonNullable<QuestionnaireExportQueryParams> {
  return {
    as_template: faker.datatype.boolean(),
    options: faker.helpers.arrayElements([
      faker.helpers.arrayElement<any>([
        "questionnaire_xlsx",
        "questionnaire_summary_pdf",
        "documents_xlsx",
        "documents",
      ]),
    ]) as any,
  };
}

/**
 * @description OK
 */
export function createQuestionnaireExport200(): NonNullable<QuestionnaireExport200> {
  return { data: createFile() };
}

/**
 * @description Forbidden
 */
export function createQuestionnaireExport403(): NonNullable<QuestionnaireExport403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createQuestionnaireExportQueryResponse(): NonNullable<QuestionnaireExportQueryResponse> {
  return { data: createFile() };
}
