import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import type { ReactElement } from "react";

import { selectField, useFields } from "@/api/queries/fields/use-fields";
import { useCompanyId } from "@/hooks/use-company-id";

import { BaseDynamicValue } from "./base-dynamic-value";
import type { UnknownRecord } from "./field-configuration-types";

interface DynamicValueProps<TRecord extends UnknownRecord = UnknownRecord> {
  record: TRecord;
  fieldPath: keyof TRecord;
  recordType: RecordTypePath;
}

export function DynamicValue<TRecord extends UnknownRecord>({
  fieldPath,
  record,
  recordType,
  ...rest
}: DynamicValueProps<TRecord>): ReactElement | null {
  const { companyId } = useCompanyId();

  const { data: configuration } = useFields(
    { recordType, companyId },
    { select: selectField(fieldPath.toString()) },
  );

  if (!configuration) {
    return null;
  }

  return (
    <BaseDynamicValue configuration={configuration} record={record} {...rest} />
  );
}
