import { IconButton } from "@themis/ui-library/components/inputs/icon-button/icon-button";
import type { VariantProps } from "cva";
import { cva } from "cva";
import { debounce } from "lodash";
import * as React from "react";
import { PiCaretLeft } from "react-icons/pi";

import { cn } from "../../lib/utils";
import type { ButtonProps } from "../Button/Button";
import { Button } from "../Button/Button";
import { IconButton as LegacyIconButton } from "../Button/IconButton";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../Command";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";

const defaultStyles =
  "tw-flex tw-w-full tw-bg-neutral-25 tw-px-3 tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-transition-colors";
const fontStyles = "tw-text-sm tw-font-semibold tw-font-sans";
const focusStyles =
  "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300";
const readOnlyStyles =
  "read-only:tw-bg-primaryDim-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
const lockedStyles =
  "read-only:tw-bg-primary-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";

const DEBOUNCE_TIMEOUT = 250;

const selectInputVariants = cva({
  base: [defaultStyles, fontStyles, focusStyles],
  variants: {
    size: {
      sm: "tw-h-7",
      md: "tw-h-8",
      lg: "tw-h-9",
    },
  },
  defaultVariants: {
    size: "lg",
  },
});

interface MultiStepSelectItem {
  label: string;
  value: number | string;
  other?: string;
}

interface MultiStepSelectStepProps {
  title?: string;
  subtitle?: string;
  searchable?: boolean;
  onScroll?: (search: string) => void;
  onSearchChange?: (search: string) => void;
  Component?: (
    label: string,
    value: string,
    other?: string,
  ) => React.JSX.Element;
  items: Array<MultiStepSelectItem>;
}

type MultiStepSelectProps = VariantProps<typeof selectInputVariants> & {
  steps: Array<MultiStepSelectStepProps>;
  initialActiveStep: number;
  onSelect?: (step: number, value: string) => void;
  onGoBack?: (step: number) => void;
  className?: string;
  locked?: boolean;
  readOnly?: boolean;
  buttonContent?: string | React.ReactNode;
  buttonProps?: Partial<ButtonProps>;
};

const MultiStepSelect = React.forwardRef<
  HTMLButtonElement,
  MultiStepSelectProps
>(
  (
    {
      steps,
      initialActiveStep = 0,
      onSelect = () => {},
      onGoBack = () => {},
      className,
      locked,
      readOnly,
      buttonContent = "Select",
      buttonProps,
    },
    ref,
  ) => {
    const [activeStep, setActiveStep] = React.useState(initialActiveStep);
    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");

    const disabled = locked || readOnly;

    const resetValues = () => {
      setActiveStep(initialActiveStep);
      setSearch("");
      setOpen(false);
    };

    const handleSelect = (value: string) => {
      if (activeStep < steps.length - 1) {
        setActiveStep((prev) => prev + 1);
        onSelect(activeStep, value);
      } else {
        resetValues();
        // Since we hide the selected item from the list, this ensures list doesn't glitch before closing popup
        setTimeout(() => onSelect(activeStep, value), 100);
      }
    };

    const goBack = () => {
      onGoBack(activeStep);
      setActiveStep(activeStep - 1);
    };

    const {
      title,
      subtitle,
      searchable,
      items,
      Component,
      onScroll,
      onSearchChange,
    } = steps[activeStep];

    const debouncedHandleFetchSearchResults = React.useMemo(
      () =>
        debounce((keyword: string) => {
          if (onSearchChange) {
            onSearchChange(keyword);
          }
        }, DEBOUNCE_TIMEOUT),
      [onSearchChange],
    );

    const onScrollChange = (e: React.UIEvent<HTMLDivElement>) => {
      if (
        onScroll &&
        e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
          e.currentTarget.scrollHeight
      ) {
        onScroll(search);
      }
    };

    const onTextChange = (value: string) => {
      setSearch(value);
      debouncedHandleFetchSearchResults(value);
    };

    return (
      <Popover
        open={open}
        onOpenChange={(val: boolean) => {
          setOpen(val);
          if (!val) {
            resetValues();
          }
        }}
      >
        <PopoverTrigger
          disabled={disabled}
          className={cn(
            {
              [lockedStyles]: locked,
              [readOnlyStyles]: readOnly,
              "tw-cursor-default": disabled,
            },
            className,
          )}
          ref={ref}
          asChild
          onClick={() => setOpen(true)}
        >
          {typeof buttonContent === "string" ? (
            <Button {...buttonProps} disabled={disabled} size="sm">
              {buttonContent}
            </Button>
          ) : (
            <IconButton disabled={disabled} size="small" color="primary">
              {buttonContent}
            </IconButton>
          )}
        </PopoverTrigger>
        <PopoverContent align="start" side="left" className="tw-min-w-60">
          {(activeStep > initialActiveStep || title) && (
            <div className="tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-100">
              <div className="tw-flex tw-items-center tw-justify-between tw-rounded-t-md tw-bg-neutral-25 tw-p-1">
                <div className="tw-flex tw-items-center">
                  {activeStep > initialActiveStep && (
                    <LegacyIconButton
                      Icon={PiCaretLeft}
                      onClick={goBack}
                      className="[&_svg]:tw-fill-primaryDim-300"
                      color="transparent"
                      size="lg"
                      popup-select-button="true"
                    />
                  )}

                  {title && (
                    <div className="tw-text-sm tw-font-semibold tw-text-primaryDim-300">
                      {title}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          <Command
            tabIndex={0}
            className="tw-outline-none"
            shouldFilter={!onSearchChange}
          >
            {searchable && (
              <CommandInput
                autoFocus
                value={search}
                onValueChange={onTextChange}
                placeholder="Search..."
              />
            )}
            {subtitle && (
              <div
                className={cn(
                  "tw-w-full tw-px-4 tw-pt-1 tw-text-sm tw-font-semibold tw-text-primaryDim-300",
                  {
                    "tw-border-x-0 tw-border-b-0 tw-border-t tw-border-solid tw-border-neutral-100":
                      searchable,
                  },
                )}
              >
                {subtitle}
              </div>
            )}
            <CommandList
              className="tw-max-h-72 tw-overflow-y-auto"
              onScroll={onScrollChange}
            >
              {searchable && <CommandEmpty>No results.</CommandEmpty>}
              <CommandGroup>
                {items.map(({ value, label, other }) => (
                  <CommandItem
                    key={value + label}
                    value={value.toString()}
                    keywords={[label]}
                    onSelect={handleSelect}
                  >
                    {Component
                      ? Component(label, value.toString(), other)
                      : label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    );
  },
);

MultiStepSelect.displayName = "MultiStepSelect";

export {
  MultiStepSelect,
  type MultiStepSelectProps,
  type MultiStepSelectItem,
  type MultiStepSelectStepProps,
};
