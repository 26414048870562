import { zodResolver } from "@hookform/resolvers/zod";
import { useToast } from "@themis/ui";
import type { ConfirmationDialogProps } from "@themis/ui-library/components/feedback/dialog/confirmation-dialog/confirmation-dialog";
import { ConfirmationDialog } from "@themis/ui-library/components/feedback/dialog/confirmation-dialog/confirmation-dialog";
import { ControlledTextField } from "@themis/ui-library/components/form/controlled-text-field/controlled-text-field";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { snakeCase } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { z } from "zod";

import { useCreateFieldOption } from "@/api/queries/fields/use-create-field-option";

interface SectionCreationDialogProps {
  fieldId: number | undefined;
  open: ConfirmationDialogProps["open"];
  onClose: () => void;
}

const schema = z.object({
  sectionName: z.string().min(1),
});

export function SectionCreationDialog({
  fieldId,
  open,
  onClose,
  ...rest
}: SectionCreationDialogProps) {
  const { formatMessage } = useIntl();

  const toast = useToast();

  const {
    mutate: createFieldOption,
    isPending,
    reset,
  } = useCreateFieldOption();

  const form = useForm<Record<string, string>>({
    defaultValues: { sectionName: "" },
    resolver: zodResolver(schema),
  });

  const handleCreateSection = form.handleSubmit((data) => {
    if (!fieldId) {
      throw new Error("Field ID is required");
    }

    const newFieldOptionKey = snakeCase(data.sectionName);

    createFieldOption(
      {
        fieldId,
        data: { option: { value: data.sectionName, key: newFieldOptionKey } },
      },
      {
        onSuccess: () => {
          toast({
            variant: "success",
            content: formatMessage({
              defaultMessage: "Section has been created.",
            }),
          });
          reset();
          onClose();
        },
        onError: () => {
          toast({
            variant: "error",
            content: formatMessage({
              defaultMessage: "Section could not be created.",
            }),
          });
        },
      },
    );
  });

  const handleClose = () => {
    form.reset();
    onClose();
  };

  return (
    <FormProvider {...form}>
      <ConfirmationDialog
        open={open}
        onClose={handleClose}
        title={formatMessage({ defaultMessage: "Create New Section" })}
        slotProps={{
          confirm: {
            loading: isPending,
            children: formatMessage({ defaultMessage: "Create" }),
          },
          cancel: {
            disabled: isPending,
            children: formatMessage({ defaultMessage: "Close" }),
          },
        }}
        onConfirm={handleCreateSection}
        {...rest}
      >
        <Stack spacing={2}>
          <ControlledTextField
            control={form.control}
            name="sectionName"
            required
            placeholder={formatMessage({
              defaultMessage: 'Section examples "Banking" or "Audit2024"...',
            })}
          />
        </Stack>
      </ConfirmationDialog>
    </FormProvider>
  );
}
