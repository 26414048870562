import type { VariantProps } from "cva";
import { cva } from "cva";
import * as React from "react";

import { cn } from "../../lib/utils";

const defaultStyles =
  "tw-flex tw-w-full tw-box-border tw-px-3 tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-bg-transparent tw-transition-colors";
const fontStyles = "tw-text-sm tw-text-neutral-500 tw-font-medium tw-font-sans";
const placeHolderStyles =
  "placeholder:tw-font-medium placeholder:tw-text-sm placeholder:tw-text-neutral-200 placeholder:tw-font-sans placeholder:tw-opacity-100";
const disabledStyles = "disabled:tw-cursor-not-allowed disabled:tw-opacity-50";
const focusStyles =
  "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300";
const readOnlyStyles =
  "read-only:tw-bg-primaryDim-25 read-only:tw-border-primaryDim-25 read-only:tw-text-neutral-300  read-only:focus:tw-ring-0 read-only:focus-visible:tw-border-neutral-100";
const lockedStyles =
  "read-only:tw-bg-primary-25 read-only:tw-border-primary-25 read-only:tw-text-primaryDim-300 read-only:focus-visible:tw-border-neutral-100  read-only:focus:tw-ring-0";

const textInputVariants = cva({
  base: [
    defaultStyles,
    fontStyles,
    placeHolderStyles,
    disabledStyles,
    focusStyles,
  ],
  variants: {
    size: {
      sm: "tw-h-7",
      md: "tw-h-8",
      lg: "tw-h-9",
    },
  },
  defaultVariants: {
    size: "lg",
  },
});

type InputType = "text" | "email" | "password" | "tel";
type InputAttributes = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "size"
>;

export type TextInputProps = InputAttributes &
  VariantProps<typeof textInputVariants> & {
    type?: InputType;
    locked?: boolean;
    readOnly?: boolean;
  };

const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      size,
      placeholder = "Type here",
      type = "text",
      locked,
      readOnly,
      onChange = () => {},
      ...props
    },
    ref,
  ) => (
    <input
      className={cn(
        textInputVariants({ size }),
        {
          [lockedStyles]: locked,
          [readOnlyStyles]: readOnly,
        },
        className,
      )}
      type={type}
      placeholder={`- ${placeholder} -`}
      onChange={onChange}
      ref={ref}
      readOnly={readOnly || locked}
      {...props}
    />
  ),
);

TextInput.displayName = "TextInput";

export { TextInput, textInputVariants };
