import { faker } from "@faker-js/faker";

import type {
  ListThemisRecordFields200,
  ListThemisRecordFields403,
  ListThemisRecordFieldsPathParams,
  ListThemisRecordFieldsQueryResponse,
} from "../models/ListThemisRecordFields";
import { createFieldConfiguration } from "./createFieldConfiguration";
import { createRecordTypePath } from "./createRecordTypePath";

export function createListThemisRecordFieldsPathParams(): NonNullable<ListThemisRecordFieldsPathParams> {
  return {
    company_id: faker.number.int(),
    record_type: createRecordTypePath(),
  };
}

/**
 * @description OK
 */
export function createListThemisRecordFields200(): NonNullable<ListThemisRecordFields200> {
  return {
    data: faker.helpers.arrayElements([createFieldConfiguration()]) as any,
  };
}

/**
 * @description Forbidden
 */
export function createListThemisRecordFields403(): NonNullable<ListThemisRecordFields403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

/**
 * @description OK
 */
export function createListThemisRecordFieldsQueryResponse(): NonNullable<ListThemisRecordFieldsQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createFieldConfiguration()]) as any,
  };
}
