import { DownloadSimple, Eye } from "@phosphor-icons/react";
import type { File } from "@themis/api/gen/models/file";
import { Tooltip } from "@themis/ui-library/components/data-display/tooltip/tooltip";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { ExternalLinkButton } from "@themis/ui-library/components/navigation/external-link-button/external-link-button";
import { ExternalLinkIconButton } from "@themis/ui-library/components/navigation/external-link-icon-button/external-link-icon-button";
import type { ReactElement } from "react";
import { useIntl } from "react-intl";

interface CommentFileProps {
  file: File;
}

export function CommentFile({ file }: CommentFileProps): ReactElement {
  const { formatMessage } = useIntl();

  return (
    <Stack direction="row" spacing={0}>
      <ExternalLinkButton
        color="secondary"
        variant="text"
        href={file.file_url}
        startIcon={null}
        size="small"
      >
        {file.file_name}
      </ExternalLinkButton>

      <Tooltip title={formatMessage({ defaultMessage: "Download" })}>
        <ExternalLinkIconButton
          color="neutral"
          size="small"
          download
          to={file.file_url}
        >
          <DownloadSimple weight="bold" />
        </ExternalLinkIconButton>
      </Tooltip>

      <Tooltip title={formatMessage({ defaultMessage: "View" })}>
        <ExternalLinkIconButton color="neutral" size="small" to={file.file_url}>
          <Eye weight="bold" />
        </ExternalLinkIconButton>
      </Tooltip>
    </Stack>
  );
}
