import type { ChipProps } from "@mui/material";

import { BASE_COLORS, getSeedColor } from "../../../utils/get-seed-color";

export const colors = [
  ...BASE_COLORS,
  "default",
] as const satisfies ChipProps["color"][];

export const getColor = (
  seed: number | string | undefined | null,
): ChipProps["color"] | undefined => {
  return getSeedColor(seed, colors);
};
