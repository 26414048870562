import type { DragEvent } from "react";
import React, { forwardRef, useState } from "react";
import { LuFilePlus2 } from "react-icons/lu";

import { cn } from "../../lib/utils";

const ACCEPTED_FORMATS =
  "image/jpeg, .jpeg, image/png, .png, image/gif, .gif, image/bmp, .bmp, image/webp, .webp, image/svg+xml, .svg";

const defaultStyles =
  "tw-flex tw-cursor-pointer tw-justify-center tw-items-center tw-w-full tw-box-border tw-h-32 tw-p-7 tw-rounded-md tw-border tw-border-dashed tw-border-neutral-100 tw-bg-transparent tw-transition-colors";
const fontStyles = "tw-text-xs tw-font-semibold tw-font-sans";
const textStyles = "tw-flex tw-max-w-60 tw-text-center tw-text-primaryDim-300";
const focusStyles =
  "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-solid focus-visible:tw-border-primary-300";
const lockedStyles =
  "disabled:tw-bg-primary-25 disabled:tw-cursor-default disabled:tw-border-solid";
const readOnlyStyles =
  "disabled:tw-bg-primaryDim-25 disabled:tw-cursor-default disabled:tw-border-solid";
const imageSelectedStyles = "!tw-border-solid";
const draggingStyles =
  "tw-rounded-md !tw-border-solid tw-border-secondary-300 !tw-bg-secondary-50 tw-text-neutral-500";

export interface ImagePickerProps {
  url?: string;
  defaultUrl?: string;
  className?: string;
  readOnly?: boolean;
  locked?: boolean;
  onSelectFile?: (file: File) => void;
  placeholder?: string;
  placeholderSubtext?: string;
}

const ImagePicker = forwardRef<HTMLButtonElement, ImagePickerProps>(
  (
    {
      url,
      defaultUrl,
      locked,
      readOnly,
      onSelectFile = () => {},
      className = "",
      placeholder = "Upload here",
      placeholderSubtext = "",
    },
    ref,
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const [dragging, setDragging] = useState(false);
    const [internalValue, setInternalValue] = useState<string>(
      defaultUrl || "",
    );

    const handleFileSelect = (file: File) => {
      onSelectFile(file);
      setInternalValue(URL.createObjectURL(file));
    };

    const handleFileDrop = (event: DragEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setDragging(false);

      if (!event.dataTransfer.files.length) {
        return;
      }

      if (!ACCEPTED_FORMATS.includes(event.dataTransfer.files[0].type)) {
        return;
      }

      handleFileSelect(event.dataTransfer.files[0]);
    };

    const handleDragOver = (event: DragEvent<HTMLButtonElement>) => {
      event.preventDefault();
      setDragging(true);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (!files?.length) {
        return;
      }

      handleFileSelect(files[0]);
    };

    const handleButtonClick = () => {
      inputRef.current?.click();
    };

    const renderImage = () => (
      <img
        src={url || internalValue}
        alt="logo-image"
        className="tw-h-full tw-w-full tw-object-contain"
      />
    );

    const renderPlaceholder = () => {
      if (dragging) {
        return (
          <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-2">
            <LuFilePlus2 className="tw-h-6 tw-w-6 tw-text-neutral-500" />
            <p className={cn(textStyles, "!tw-text-neutral-500")}>
              Drop file here
            </p>
          </div>
        );
      }

      return (
        <p className={cn(textStyles)}>
          {placeholder}
          <br />
          {placeholderSubtext}
        </p>
      );
    };

    return (
      <>
        <button
          type="button"
          className={cn(
            defaultStyles,
            focusStyles,
            fontStyles,
            {
              [lockedStyles]: locked,
              [readOnlyStyles]: readOnly,
              [imageSelectedStyles]: url || internalValue,
              [draggingStyles]: dragging,
            },
            className,
          )}
          onClick={handleButtonClick}
          onDrop={handleFileDrop}
          onDragOver={handleDragOver}
          onDragExit={() => setDragging(false)}
          disabled={locked || readOnly}
          ref={ref}
        >
          {url || internalValue ? renderImage() : renderPlaceholder()}
        </button>
        <input
          placeholder={placeholder}
          ref={inputRef}
          type="file"
          onChange={handleInputChange}
          className={cn("tw-hidden", className)}
          accept={ACCEPTED_FORMATS}
        />
      </>
    );
  },
);

ImagePicker.displayName = "ImagePicker";

export { ImagePicker };
