import { find, get, keys, map } from "lodash";
import { action, makeObservable, observable } from "mobx";

import type { PermissionSet } from "@/api";
import { permissionsService } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import { nameForThemisModuleIdentifier } from "../../components/helpers/nameForThemisModuleIdentifier";
import type { MainStore } from "../Main";
import type { ModuleIdentifier } from "../types/module-workspaces-types";
import type { User } from "../types/user-types";

export default class Permissions {
  mainStore: MainStore;

  // Observable objects
  permissionSets: PermissionSet[] = [];
  permissionGroups = [];
  modules = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      permissionSets: observable,
      permissionGroups: observable,
      modules: observable,
      setPermissionSets: action,
      setPermissionGroups: action,
    });

    this.mainStore = mainStore;
  }

  async index(companyID: number) {
    const response = await permissionsService.listPermissionSets(companyID);
    this.setPermissionSets(response);
  }

  async getPermissionGroups() {
    try {
      if (this.permissionGroups.length > 0) {
        return this.permissionGroups;
      }
      const { data } = await legacyApi({
        method: "GET",
        url: `${API_URL}/permission_sets/permissions`,
        headers: this.mainStore.getHeaders(),
      });
      this.setPermissionGroups(data);
      return data;
    } catch (error) {
      window.console.log(`"Permissions#Index" error ${error}`);
    }
  }

  async getPermissionSet(id: string) {
    try {
      const { data } = await legacyApi({
        method: "GET",
        url: `${API_URL}/permission_sets/${id}`,
        headers: this.mainStore.getHeaders(),
      });
      return data;
    } catch (error) {
      window.console.log(`"Permissions#GetPermissionSet" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'payload' implicitly has an 'any' type.
  async createPermissionSet(payload) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/permission_sets`,
        headers: this.mainStore.getHeaders(),
        data: payload,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        throw response.response.data.errors.base;
      }
    } catch (error) {
      window.console.log(`"Permissions#CreatePermissionSet" error ${error}`);
      throw error;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'permissionSetID' implicitly has an 'any... Remove this comment to see the full error message
  async updatePermissionSet(permissionSetID: string, payload) {
    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/permission_sets/${permissionSetID}`,
        headers: this.mainStore.getHeaders(),
        data: payload,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        throw response.response.data.errors.base;
      }
    } catch (error) {
      window.console.log(`"Permissions#CreatePermissionSet" error ${error}`);
      throw error;
    }
  }

  async deletePermissionSet(id: string) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/permission_sets/${id}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        throw response.response.data.errors;
      } else {
        this.setPermissionSets(
          this.permissionSets.filter(
            (permissionSet) => permissionSet.id !== id,
          ),
        );
      }
    } catch (error) {
      window.console.log(`"Permissions#DeletePermissionSet" error ${error}`);
      throw error;
    }
  }

  async getUsersAccessibleToModule(moduleWorkspaceID: number): Promise<User[]> {
    const params = {
      module_workspace_id: moduleWorkspaceID,
    };

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/permission_sets/users`,
        headers: this.mainStore.getHeaders(),
        params,
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        throw response.response.data.errors.base;
      } else {
        return response.data.users;
      }
    } catch (error) {
      window.console.log(`"Permissions#DeletePermissionSet" error ${error}`);
      throw error;
    }
  }

  // Store Helpers
  setPermissionSets(sets: PermissionSet[] = []) {
    this.permissionSets = sets;
  }

  setModuleName(moduleIdentifier: ModuleIdentifier, name: string) {
    const index = this.modules.findIndex(
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      (module) => module.id === moduleIdentifier,
    );
    if (index === -1) {
      return;
    }
    // @ts-expect-error TS(2339) FIXME: Property 'name' does not exist on type 'never'.
    this.modules[index].name = name;
  }

  setPermissionGroups(groups = []) {
    this.permissionGroups = groups;

    const moduleTargets = get(
      find(groups, (group) => keys(group)[0] === "module"),
      "module.targets",
    );
    const modules = map(moduleTargets, (target) => {
      const [moduleIdentifier] = keys(target);

      return {
        id: moduleIdentifier,
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        name: nameForThemisModuleIdentifier(moduleIdentifier),
      };
    });

    // @ts-expect-error TS(2322) FIXME: Type '{ id: string; name: any; }[]' is not assigna... Remove this comment to see the full error message
    this.modules = modules;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cleanup() {}
}
