import { observer } from "mobx-react";
import { PiPlusBold } from "react-icons/pi";
import { generatePath, useParams } from "react-router-dom";

import { useProject } from "@/api/queries/projects";
import { ErrorContainer } from "@/components/ErrorContainer";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";
import { useMainStore } from "@/contexts/Store";
import StatusFilterButton from "@/features/tasks/components/StatusFilterButton";
import TasksTable from "@/features/tasks/components/table/TasksTable";
import { projectTasksTableColumnKeys } from "@/features/tasks/config/myTasksTableConfig";
import { FilterOperand } from "@/hooks/use-filter-sort/types";

import ProjectHeaderTabs from "../components/ProjectHeaderTabs";
import { PROJECTS_ARCHIVE_PATH, PROJECTS_LIST_PATH } from "../routes";

function ProjectTasks() {
  const { taskDetail } = useMainStore();

  const { workspace_id, project_id } = useParams<{
    workspace_id: string;
    project_id: string;
  }>();

  const {
    data: project,
    isLoading,
    isError,
    error,
  } = useProject({
    workspaceId: Number(workspace_id),
    projectId: Number(project_id),
  });

  const isArchived = Boolean(project?.data.archived_at);

  if (isLoading || !project) {
    return (
      <PageContent>
        <Loading loadingLayout="small-table" />
      </PageContent>
    );
  }

  if (isError) {
    return (
      <PageContent>
        <ErrorContainer
          backButtonProps={{
            linkTo: generatePath("/workspaces/:workspace_id/home", {
              workspace_id,
            }),
          }}
        >
          Could not load project tasks.
        </ErrorContainer>
      </PageContent>
    );
  }

  return (
    <PageLayout>
      <Header
        title={
          <div className="tw-align-center tw-flex tw-gap-1">
            <span>Projects</span>
            {!(isLoading || error) && (
              <>
                <span className="tw-text-xl tw-font-medium tw-text-neutral-200">
                  /
                </span>
                <span className="tw-truncate">{project?.data.name}</span>
              </>
            )}
          </div>
        }
        backButtonLinkTo={
          isArchived
            ? generatePath(PROJECTS_ARCHIVE_PATH, { workspace_id })
            : generatePath(PROJECTS_LIST_PATH, { workspace_id })
        }
      />
      <ProjectHeaderTabs rightHeaderSlot={<StatusFilterButton />} />
      <PageContent>
        <TasksTable
          initialFilters={{
            archived_at: {
              [FilterOperand.MISSING]: "true",
            },
            parent_id: {
              [FilterOperand.ANY]: project_id,
            },
          }}
          displayedColumnKeys={projectTasksTableColumnKeys}
        />
        {project.data.tasks.length > 0 && (
          <div
            className="tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-4 tw-px-[7px] tw-py-[6px]"
            onClick={() =>
              taskDetail.open({
                parent_id: project?.data.id,
                workspace_id: project?.data.workspace_id,
                visibility: project?.data.visibility,
              })
            }
          >
            <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
            <div className="tw-neutral-300 tw-text-sm">Add Task</div>
          </div>
        )}
      </PageContent>
    </PageLayout>
  );
}

export default observer(ProjectTasks);
