import type { ComponentType } from "react";
import type { Control } from "react-hook-form";

import type { ValidationSchema } from "../../use-validation-schema/use-validation-schema";
import { DropdownColumnControls } from "./dropdown-column-controls/dropdown-column-controls";
import { MultipleColumnControls } from "./multiple-column-controls/multiple-column-controls";
import { SingleColumnControls } from "./single-column-controls/single-column-controls";

type BaseColumnComponent = ComponentType<{
  control: Control<ValidationSchema>;
  isEdit?: boolean;
  showInternal: boolean;
}>;

const columns = {
  attachment: SingleColumnControls,
  checklist: SingleColumnControls,
  date: SingleColumnControls,
  select: DropdownColumnControls,
  link: SingleColumnControls,
  text: SingleColumnControls,
  user: MultipleColumnControls,
} satisfies Record<ValidationSchema["columnType"], BaseColumnComponent>;

interface CustomColumnFieldProps<T extends keyof typeof columns> {
  columnType: T;
  control: Control<ValidationSchema>;
  isEdit?: boolean;
  showInternal: boolean;
}

export const CustomColumnControls = <T extends keyof typeof columns>({
  columnType,
  control,
  isEdit,
  showInternal,
}: CustomColumnFieldProps<T>) => {
  const ColumnComponent: BaseColumnComponent = columns[columnType];

  const props: Omit<CustomColumnFieldProps<T>, "columnType"> = {
    control,
    isEdit,
    showInternal,
  };

  return <ColumnComponent {...props} />;
};
