import { useQuery } from "@tanstack/react-query";
import type { RecordWithId } from "@themis/ui";

import api from "@/api/legacy/api";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

export interface Policy extends RecordWithId {
  contributor: string;
  created_at: string;
  indicator: number;
  module_workspace_id: number;
  title: string;
}

const getPoliciesLibrary = async (workspaceId: number) => {
  const { data } = await api.get<{
    attachment_library_records: Policy[] | undefined;
  }>("/policies/policies_library", {
    params: {
      module_workspace_id: workspaceId,
    },
  });

  return data.attachment_library_records ?? [];
};

const getPoliciesLibraryQueryKey = (workspaceId: number) => [
  "policies",
  "policies-library",
  workspaceId,
];

export const usePoliciesLibrary = () => {
  const workspaceId = useRouteWorkspaceId();

  return useQuery({
    queryKey: getPoliciesLibraryQueryKey(workspaceId),
    queryFn: () => getPoliciesLibrary(workspaceId),
  });
};
