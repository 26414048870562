import type { ThemeOptions } from "@mui/material";

export const iconButton: ThemeOptions["components"] = {
  MuiIconButton: {
    defaultProps: {},
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: theme.typography.fontWeightBold,
        borderRadius: theme.shape.borderRadius,
        padding: 6,
      }),
      sizeSmall: () => ({
        fontSize: 16,
      }),
      sizeMedium: () => ({
        fontSize: 20,
      }),
      sizeLarge: () => ({
        fontSize: 24,
      }),
    },
    variants: [
      {
        props: { color: "primary" },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          "&:hover": {
            backgroundColor: theme.palette.primary.dark,
          },
        }),
      },
      {
        props: { color: "secondary" },
        style: ({ theme }) => ({
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.secondary.contrastText,
          "&:hover": {
            backgroundColor: theme.palette.secondary.dark,
          },
        }),
      },
    ],
  },
};
