import type { DialogProps } from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import { X } from "@phosphor-icons/react";
import type { ReactNode } from "react";

import { Container } from "../../../layout/container/container";

export interface SimpleDialogProps extends Omit<DialogProps, "title"> {
  actions?: ReactNode;
  title?: ReactNode;
}

const CloseIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
}));

export function SimpleDialog({
  title,
  open,
  onClose,
  actions,
  children,
  ...rest
}: SimpleDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      component={Container}
      maxWidth="sm"
      {...rest}
    >
      <CloseIconButton onClick={() => onClose?.({}, "escapeKeyDown")}>
        <X />
      </CloseIconButton>

      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent>{children}</DialogContent>

      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}
