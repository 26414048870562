import type { ReviewReviewType } from "@themis/api/gen/models/review";
import type { ThemisPaletteColor } from "@themis/ui-library/theme/types/themis-palette";

const statusColors: Record<ReviewReviewType, ThemisPaletteColor> = {
  approval: "success",
  denial: "warning",
  request_change: "secondary",
  review_change: "warning",
  completed_action: "neutral",
  obsolete: "neutral",
  pending: "neutral",
  pending_action: "neutral",
};

export function getReviewStatusColor(status: ReviewReviewType | undefined) {
  return status ? statusColors[status] : "neutral";
}
