import { Slot } from "@radix-ui/react-slot";
import { cva, cx, type VariantProps } from "cva";
import * as React from "react";
import { type IconType } from "react-icons";

import { cn } from "../../lib/utils";
import { Spinner } from "../loaders/spinner";
import type { ButtonBaseProps } from "./ButtonBase";
import { buttonBaseVariants } from "./ButtonBase";

const buttonVariants = cva({
  variants: {
    size: {
      sm: "tw-px-2 tw-h-7 tw-text-xs tw-gap-1",
      md: "tw-px-3 tw-h-8 tw-gap-1.5",
      lg: "tw-px-4 tw-h-9 tw-gap-2",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export type ButtonProps = ButtonBaseProps &
  VariantProps<typeof buttonVariants> & {
    LeftIcon?: IconType;
    RightIcon?: IconType;
  };

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      children,
      color = "primary",
      loading,
      disabled,
      size = "md",
      asChild = false,
      LeftIcon,
      RightIcon,
      type = "button",
      ...props
    },
    ref,
  ) => {
    const Component = asChild ? Slot : "button";

    return (
      <Component
        className={cn(
          cx(
            buttonBaseVariants({ color, disabled, loading }),
            buttonVariants({ size, className }),
          ),
        )}
        disabled={disabled || loading}
        aria-busy={loading}
        ref={ref}
        type={type}
        {...props}
      >
        {LeftIcon && <LeftIcon className="tw-h-4 tw-w-4" />}
        {children}
        {RightIcon && <RightIcon className="tw-h-4 tw-w-4" />}
        {loading && (
          <Spinner
            className="tw-absolute tw-text-sm"
            color="parentColor"
            parentColor={color}
          />
        )}
      </Component>
    );
  },
);

Button.displayName = "Button";

export { Button, buttonVariants };
