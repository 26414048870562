import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import * as React from "react";
import { PiCheckBold } from "react-icons/pi";

import { cn } from "../../lib/utils";

export type CheckboxProps = React.ComponentPropsWithoutRef<
  typeof CheckboxPrimitive.Root
> & {
  color?: "black" | "primary" | "secondary";
  size?: "sm" | "md" | "lg";
  rounded?: boolean;
};

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(
  (
    { className, color = "black", rounded = false, size = "lg", ...props },
    ref,
  ) => (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        "focus-visible:tw-ring-ring tw-peer tw-box-content tw-shrink-0 tw-overflow-hidden tw-rounded-sm tw-border-2 tw-border-solid tw-border-neutral-700 tw-p-0 tw-ring-primaryDim-200 focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-primaryDim-400 disabled:tw-cursor-not-allowed disabled:tw-opacity-50 data-[state=checked]:tw-text-neutral-500",
        { "tw-border-1 tw-rounded-full": rounded },
        {
          "data-[state=checked]:tw-border-primary-300": color === "primary",
        },
        {
          "data-[state=checked]:tw-border-secondary-300": color === "secondary",
        },
        { "tw-h-4 tw-w-4": size === "lg" },
        { "tw-h-3 tw-w-3": size === "md" },
        { "tw-h-2 tw-w-2": size === "sm" },
        className,
      )}
      {...props}
    >
      <div
        className={cn({
          "tw-h-4 tw-w-4": size === "lg",
          "tw-h-3 tw-w-3": size === "md",
          "tw-h-2 tw-w-2": size === "sm",
        })}
      >
        <CheckboxPrimitive.Indicator
          className={cn(
            "tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-current",
            { "tw-bg-primary-300": color === "primary" },
            { "tw-bg-secondary-300": color === "secondary" },
          )}
        >
          <PiCheckBold
            className={cn("tw-font-semibold", {
              "tw-h-3 tw-w-3": rounded || size === "md",
              "tw-h-4 tw-w-4": size === "lg",
              "tw-h-2 tw-w-2": size === "sm",
              "tw-fill-neutral-25":
                color === "primary" || color === "secondary",
            })}
          />
        </CheckboxPrimitive.Indicator>
      </div>
    </CheckboxPrimitive.Root>
  ),
);

Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
