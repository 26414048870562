import type { Theme } from "@mui/material";

const isKeyOfPaletteColor = (
  color: string | undefined,
  theme: Theme,
): color is keyof Theme["palette"] => !!color && color in theme.palette;

export function getPaletteColor(theme: Theme, color: string | undefined) {
  if (!isKeyOfPaletteColor(color, theme)) {
    return undefined;
  }

  const paletteColor = theme.palette[color];

  if (typeof paletteColor !== "object" || !("main" in paletteColor)) {
    return undefined;
  }

  return paletteColor;
}
