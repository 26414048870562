import { faker } from "@faker-js/faker";

import type { MonitoringGroup } from "../models/MonitoringGroup";
import { createMonitoringAsset } from "./createMonitoringAsset";
import { createThemisRecord } from "./createThemisRecord";

export function createMonitoringGroup(
  data?: NonNullable<Partial<MonitoringGroup>>,
): NonNullable<MonitoringGroup> {
  return Object.assign({}, createThemisRecord(), {
    status: faker.helpers.arrayElement<any>(["active", "paused"]),
    latest_scan_date: faker.date.anytime().toISOString(),
    total_findings_count: faker.number.int(),
    resolved_findings_count: faker.number.int(),
    monitoring_assets: faker.helpers.arrayElements([
      createMonitoringAsset(),
    ]) as any,
  });
}
