import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import * as attestationsController from "app/javascript/api/gen/axios/attestationsController";
import type {
  GetCompanyAttestationsPathParams,
  GetCompanyAttestationsQueryResponse,
} from "app/javascript/api/gen/models/GetCompanyAttestations";
import { useRouteWorkspaceId } from "app/javascript/hooks/use-route-workspace-id";

export const getAttestationsQueryKey = (
  params?: GetCompanyAttestationsPathParams,
): QueryKey => ["attestations", "list", params];

export const useAttestations = <
  TError = Error,
  TData = GetCompanyAttestationsQueryResponse["data"],
>(
  { company_id }: GetCompanyAttestationsPathParams,
  options?: Partial<
    UseQueryOptions<GetCompanyAttestationsQueryResponse["data"], TError, TData>
  >,
) => {
  const workspaceId = useRouteWorkspaceId();

  return useQuery({
    queryFn: async () => {
      const response = await attestationsController.getCompanyAttestations(
        company_id,
        { workspace_id: workspaceId },
      );

      return response.data;
    },
    queryKey: getAttestationsQueryKey({ company_id }),
    ...options,
  });
};
