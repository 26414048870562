import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

export interface ModuleWorkspace {
  name: string;
  themis_module: {
    identifier: string;
    name: string;
  };
}

const moduleIdentifiersToName: Record<ModuleIdentifier, string> = {
  action_plans: "Action Plans",
  audits: "Audits",
  attestation: "Attestation",
  complaints: "Complaints",
  conflicts_of_interest: "Conflicts Of Interest",
  control_mapping: "Control Mapping",
  customer_support: "Customer Support",
  documents: "Documents",
  finra: "Finra",
  issue_management: "Issue Management",
  marketing: "Marketing",
  new_product_approval: "Change Management",
  policy: "Policy",
  procedures: "Procedures",
  qa_tests_development: "Monitoring & Testing",
  risk_assessment: "Questionnaires",
  risk_register: "Risk Register",
  training: "Training",
  vendor_due_diligence: "Vendor Due Diligence",
  zendesk: "Zendesk",
  key_risk_indicators: "Key Risk Indicators",
  linked_documents: "Linked Documents",
  qa_findings: "Findings",
  qa_procedures: "Procedure Steps",
  samples: "Samples",
  questionnaires: "Questionnaires",
  values: "Values",
  accounts: "Accounts",
  website_monitoring: "Website Monitoring",
};

type RecordTypes = Record<
  ModuleIdentifier,
  {
    singular: string;
    plural: string;
  }
>;

export const recordTypes: RecordTypes = {
  action_plans: { singular: "Action Plan", plural: "Action Plans" },
  audits: { singular: "Audit", plural: "Audits" },
  attestation: { singular: "Attestation", plural: "Attestations" },
  complaints: { singular: "Complaint", plural: "Complaints" },
  conflicts_of_interest: { singular: "Conflict", plural: "Conflicts" },
  control_mapping: { singular: "Control Mapping", plural: "Control Mappings" },
  customer_support: {
    singular: "Customer Support",
    plural: "Customer Supports",
  },
  documents: { singular: "Document", plural: "Documents" },
  finra: { singular: "Finra Complaint", plural: "Finra Complaints" },
  issue_management: { singular: "Issue", plural: "Issues" },
  linked_documents: { singular: "Linked Document", plural: "Linked Documents" },
  marketing: { singular: "Creative", plural: "Creatives" },
  new_product_approval: {
    singular: "Change Management",
    plural: "Change Management",
  },
  policy: { singular: "Policy", plural: "Policies" },
  procedures: { singular: "Procedure", plural: "Procedures" },
  qa_findings: { singular: "QA Findings", plural: "QA Findings" },
  qa_procedures: { singular: "QA Procedures", plural: "QA Procedures" },
  qa_tests_development: { singular: "Test", plural: "Test" },
  questionnaires: { singular: "Questionnaire", plural: "Questionnaires" },
  risk_assessment: { singular: "Partner", plural: "Partners" },
  risk_register: { singular: "Risk", plural: "Risks" },
  samples: { singular: "Sample", plural: "Samples" },
  training: { singular: "Training", plural: "Trainings" },
  vendor_due_diligence: { singular: "Vendor", plural: "Vendors" },
  zendesk: { singular: "Zendesk", plural: "Zendesk" },
  key_risk_indicators: {
    singular: "Key Risk Indicator",
    plural: "Key Risk Indicators",
  },
  values: {
    singular: "Value",
    plural: "Values",
  },
  website_monitoring: {
    singular: "Monitoring",
    plural: "Monitoring",
  },
  accounts: {
    singular: "Account",
    plural: "Accounts",
  },
};

const recordTypesInternalNames: RecordTypes = {
  action_plans: { singular: "Action Plan", plural: "Action Plans" },
  audits: { singular: "Report", plural: "Reports" },
  attestation: { singular: "Attestation", plural: "Attestations" },
  complaints: { singular: "Complaint", plural: "Complaints" },
  conflicts_of_interest: { singular: "Form", plural: "Forms" },
  control_mapping: { singular: "Control", plural: "Controls" },
  customer_support: { singular: "Complaint", plural: "Complaints" },
  documents: { singular: "Document", plural: "Documents" },
  finra: { singular: "Complaint", plural: "Complaints" },
  issue_management: { singular: "Issue", plural: "Issues" },
  linked_documents: { singular: "Linked Document", plural: "Linked Documents" },
  marketing: { singular: "Creative", plural: "Creatives" },
  new_product_approval: { singular: "Section", plural: "Reviewers" },
  policy: { singular: "Policy", plural: "Policies" },
  procedures: { singular: "Procedure", plural: "Procedures" },
  qa_findings: { singular: "QA Finding", plural: "QA Findings" },
  qa_procedures: { singular: "QA Procedure", plural: "QA Procedures" },
  qa_tests_development: { singular: "Test", plural: "Test" },
  questionnaires: { singular: "Questionnaire", plural: "Questionnaires" },
  risk_assessment: { singular: "Partner", plural: "Partners" },
  risk_register: { singular: "Risk Event", plural: "Risk Events" },
  samples: { singular: "Sample", plural: "Samples" },
  training: { singular: "Training", plural: "Trainings" },
  vendor_due_diligence: { singular: "Vendor", plural: "Vendors" },
  zendesk: { singular: "Zendesk", plural: "Zendesk" },
  key_risk_indicators: {
    singular: "Key Risk Indicator",
    plural: "Key Risk Indicators",
  },
  values: {
    singular: "Value",
    plural: "Values",
  },
  website_monitoring: {
    singular: "Monitoring",
    plural: "Monitoring",
  },
  accounts: {
    singular: "Account",
    plural: "Accounts",
  },
};

export const nameFromModuleWorkspace = (
  themisModuleIdentifier: ModuleIdentifier,
  moduleWorkspaces: Array<ModuleWorkspace>,
): string => {
  if (moduleWorkspaces.length > 0) {
    const moduleWorkspace = moduleWorkspaces.find(
      (workspace) =>
        workspace.themis_module.identifier === themisModuleIdentifier,
    );
    if (moduleWorkspace) {
      return moduleWorkspace.name;
    }
  }
  return nameForThemisModuleIdentifier(themisModuleIdentifier);
};

export const nameForThemisModuleIdentifier = (
  themisModuleIdentifier: ModuleIdentifier,
) => {
  const moduleName = moduleIdentifiersToName[themisModuleIdentifier];
  if (moduleName) {
    return moduleName;
  }

  window.console.warn(
    `UNKNOWN THEMIS MODULE in nameForThemisModuleIdentifier: ${themisModuleIdentifier}`,
  );
  return "";
};

export const recordTypeForThemisModuleIdentifier = (
  themisModuleIdentifier: ModuleIdentifier,
) => {
  const recordType = recordTypes[themisModuleIdentifier];
  if (recordType) {
    return recordType;
  }

  window.console.warn(
    `UNKNOWN THEMIS MODULE in recordTypeForThemisModuleIdentifier: ${themisModuleIdentifier}`,
  );
  return {
    singular: "",
    plural: "",
  };
};

export const getRecordName = (
  themisModuleIdentifier: ModuleIdentifier,
  moduleWorkspaces: Array<ModuleWorkspace>,
  needSingular: boolean,
): string => {
  const name =
    recordTypesInternalNames[themisModuleIdentifier][
      needSingular ? "singular" : "plural"
    ];
  return name;
};
