import type { Comment } from "@themis/api/gen/models/comment";
import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import type { Task } from "@themis/api/gen/models/task";
import { taskableStatus } from "@themis/api/gen/models/taskable";

import { selectUser, useUsers } from "@/api/queries/users/use-users";
import { useWorkspace } from "@/api/queries/workspaces/use-workspace";
import { useMainStore } from "@/contexts/Store";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";
import { getRelatableTypeFromRecordType } from "@/utils/get-relatable-task-from-record-type/get-relatable-task-from-record-type";

import { useRecordCommentsContext } from "../../../contexts/record-comments-context/record-comments-context";

function formatCommentContent(inputString: string) {
  const regexPattern = /@\[(.*?)\]\(\d+\)/g;

  const resultString = inputString.replace(regexPattern, (_match, p1) => p1);

  return resultString;
}

interface UseCreateTaskFromCommentParams {
  comment: Comment;
  recordId: number;
  recordName: string;
  recordType: RecordTypePath;
}

export function useCreateTaskFromComment({
  comment,
  recordId,
  recordName,
  recordType,
}: UseCreateTaskFromCommentParams) {
  const mainStore = useMainStore();
  const { taskDetail } = mainStore;
  const workspaceId = useRouteWorkspaceId();
  const { data: workspace } = useWorkspace(workspaceId);
  const { commentDetailUrl } = useRecordCommentsContext() || {};

  const { data: author } = useUsers(workspaceId, {
    select: selectUser(comment.author_id ?? -1),
  });

  return () => {
    const commentUrl = new URL(commentDetailUrl || window.location.href);
    commentUrl.hash = `#comment-${comment.id}`;

    const readableContent = formatCommentContent(comment.content);

    const newTask: Partial<Task> = {
      name: `${readableContent}`,
      description: `@${author?.full_name} said:\n> ${readableContent}\n\n${commentUrl.toString()}`,
      status: "not_started",
      visibility:
        comment.private || Boolean(workspace?.is_internal)
          ? "internal"
          : "public",
      assignee_id:
        comment.tagged_user_ids.length === 1
          ? comment.tagged_user_ids[0]
          : null,
      taskables: [
        {
          status: taskableStatus.active,
          id: 0,
          target: {
            id: recordId,
            identifier: comment.id.toString(),
            relatable_type: getRelatableTypeFromRecordType(recordType),
            name: recordName || "No Name",
            path: "",
          },
        },
      ],
    };
    taskDetail.open(newTask);
  };
}
