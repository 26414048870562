import { faker } from "@faker-js/faker";

import type { ReviewableProperties } from "../models/ReviewableProperties";
import { createThemisRecordReview } from "./createThemisRecordReview";

export function createReviewableProperties(
  data: NonNullable<Partial<ReviewableProperties>> = {},
): NonNullable<ReviewableProperties> {
  return {
    ...{
      reviews: faker.helpers.arrayElements([createThemisRecordReview()]) as any,
      reviewer_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
    },
    ...data,
  };
}
