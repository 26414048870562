import type { ColDef } from "ag-grid-community";

import { SelectionHeader } from "../selection-header/selection-header";

export const selectionColumnDefParams: Partial<ColDef> = {
  cellClass: "selection-cell",
  headerClass: "selection-header",
  headerComponent: SelectionHeader,
  lockPosition: true,
  pinned: "left",
  width: 40,
  flex: 0,
};
