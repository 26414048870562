import { faker } from "@faker-js/faker";

import type { ThemisRecordReview } from "../models/ThemisRecordReview";

export function createThemisRecordReview(
  data: NonNullable<Partial<ThemisRecordReview>> = {},
): NonNullable<ThemisRecordReview> {
  return {
    ...{
      id: faker.number.int(),
      approved_at: faker.date.anytime().toISOString(),
      review_type: faker.helpers.arrayElement<any>([
        "approval",
        "denial",
        "pending",
      ]),
      user_id: faker.number.int(),
    },
    ...data,
  };
}
