import { LinkSimple as LinkIcon } from "@phosphor-icons/react";
import { Chip } from "@themis/ui-library/components/data-display/chip/chip";
import { ListItemText } from "@themis/ui-library/components/data-display/list/list-item-text/list-item-text";
import { ListSubheader } from "@themis/ui-library/components/data-display/list/list-subheader/list-subheader";
import { SvgIcon } from "@themis/ui-library/components/data-display/svg-icon/svg-icon";
import {
  Typography,
  typographyClasses,
} from "@themis/ui-library/components/data-display/typography/typography";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { Link } from "@themis/ui-library/components/navigation/link/link";
import { LinkMenuItem } from "@themis/ui-library/components/navigation/menu/link-menu-item/link-menu-item";
import { Popover } from "@themis/ui-library/components/utils/popover/popover";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import { useRef } from "react";
import { useIntl } from "react-intl";

import type { RecordReference } from "@/api";

import type { BaseDynamicValueProps } from "../../base-dynamic-value";

export const ThemisRecordValue = ({
  value,
}: BaseDynamicValueProps<RecordReference>) => {
  const { formatMessage } = useIntl();

  const ref = useRef<HTMLDivElement>(null);
  const popover = useOpen();

  const records = Array.isArray(value) ? value : [value];

  if (!records.length) {
    return (
      <Typography color="text.secondary">
        {formatMessage({ defaultMessage: "N/A" })}
      </Typography>
    );
  }

  const [firstRecord] = records;

  if (records.length === 1) {
    return (
      <Link color="secondary.main" to={firstRecord?.url ?? "#"}>
        {firstRecord?.name}
      </Link>
    );
  }

  return (
    <div onMouseEnter={popover.open} onMouseLeave={popover.close}>
      <Stack
        ref={ref}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography color="secondary.main" noWrap>
          {firstRecord?.name}
        </Typography>

        <Chip color="default" label={records.length} />
      </Stack>

      <Popover
        anchorEl={ref.current?.closest(".ag-cell")}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          root: {
            sx: {
              // Root covers the whole viewport otherwise making the mouse state to always be "over" the popover
              position: "static",
            },
          },
        }}
        hideBackdrop
        open={popover.isOpen}
      >
        <ListSubheader>
          {formatMessage(
            { defaultMessage: "{count} associated records" },
            { count: records?.length },
          )}
        </ListSubheader>

        {records.map((record) => (
          <LinkMenuItem
            key={record?.id}
            sx={{
              maxWidth: "248px",
            }}
            to={record?.url ?? "#"}
          >
            <ListItemText
              sx={(theme) => ({
                [`.${typographyClasses.root}`]: {
                  color: theme.palette.secondary.main,
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              })}
            >
              {record?.name}
            </ListItemText>

            <SvgIcon
              component={LinkIcon}
              color="action"
              fontSize="small"
              inheritViewBox
            />
          </LinkMenuItem>
        ))}
      </Popover>
    </div>
  );
};
