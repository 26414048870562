import { useMutation, useQueryClient } from "@tanstack/react-query";
import type { UseMutationOptions } from "@tanstack/react-query";

import type {
  UpdatePublicFormLayoutMutationRequest,
  UpdatePublicFormLayoutMutationResponse,
  UpdatePublicFormLayoutPathParams,
} from "@/api";
import { updatePublicFormLayout } from "@/api/gen/axios/publicFormLayoutController";
import { getPublicFormLayoutQueryKey } from "@/api/queries/share-link/use-public-form-layout";

export const useUpdatePublicFormLayout = <TError = Error>(
  moduleWorkspaceId: UpdatePublicFormLayoutPathParams["module_workspace_id"],
  {
    onSettled,
    ...options
  }: Partial<
    UseMutationOptions<
      UpdatePublicFormLayoutMutationResponse["data"],
      TError,
      UpdatePublicFormLayoutMutationRequest
    >
  > = {},
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params) => {
      const response = await updatePublicFormLayout(moduleWorkspaceId, params);
      return response.data;
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getPublicFormLayoutQueryKey(moduleWorkspaceId),
      });

      onSettled?.(...args);
    },
    ...options,
  });
};
