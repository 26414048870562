import { faker } from "@faker-js/faker";

import type {
  ShowMonitoringAsset200,
  ShowMonitoringAssetPathParams,
  ShowMonitoringAssetQueryParams,
  ShowMonitoringAssetQueryResponse,
} from "../models/ShowMonitoringAsset";
import { createMonitoringAsset } from "./createMonitoringAsset";

export function createShowMonitoringAssetPathParams(): NonNullable<ShowMonitoringAssetPathParams> {
  return { id: faker.number.int() };
}

export function createShowMonitoringAssetQueryParams(): NonNullable<ShowMonitoringAssetQueryParams> {
  return { expand: faker.string.alpha() };
}

/**
 * @description OK
 */
export function createShowMonitoringAsset200(): NonNullable<ShowMonitoringAsset200> {
  return { data: createMonitoringAsset() };
}

/**
 * @description OK
 */
export function createShowMonitoringAssetQueryResponse(): NonNullable<ShowMonitoringAssetQueryResponse> {
  return { data: createMonitoringAsset() };
}
