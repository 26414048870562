import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  LegacyWebsiteMonitoringGroupResultUpdateMutationRequest,
  LegacyWebsiteMonitoringGroupResultUpdateMutationResponse,
  LegacyWebsiteMonitoringGroupResultUpdatePathParams,
} from "../../models/LegacyWebsiteMonitoringGroupResultUpdate";

/**
 * @description Update Website Monitoring - Group result
 * @summary Update Website Monitoring group result (Legacy)
 * @link /website_monitoring/:monitoring_group_result_id/monitoring_group_result_update
 */
export async function legacyWebsiteMonitoringGroupResultUpdate(
  monitoringGroupResultId: LegacyWebsiteMonitoringGroupResultUpdatePathParams["monitoring_group_result_id"],
  data: LegacyWebsiteMonitoringGroupResultUpdateMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<LegacyWebsiteMonitoringGroupResultUpdateMutationResponse>["data"]
> {
  const res = await client<
    LegacyWebsiteMonitoringGroupResultUpdateMutationResponse,
    LegacyWebsiteMonitoringGroupResultUpdateMutationRequest
  >({
    method: "put",
    url: `/website_monitoring/${monitoringGroupResultId}/monitoring_group_result_update`,
    data,
    ...options,
  });
  return res.data;
}
