import { type ThemeOptions, typographyClasses } from "@mui/material";
import { CaretRight } from "@phosphor-icons/react";

export const breadcrumbs: ThemeOptions["components"] = {
  MuiBreadcrumbs: {
    defaultProps: {
      separator: <CaretRight />,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        fontSize: theme.typography.h5.fontSize,
        maxWidth: "none",
      }),
      separator: () => ({
        margin: "0 0",
      }),
      li: ({ theme }) => ({
        [`.${typographyClasses.root}`]: {
          fontSize: theme.typography.h5.fontSize,
          fontWeight: theme.typography.h5.fontWeight,
        },
        p: {
          color: theme.palette.text.primary,
        },
      }),
    },
  },
};
