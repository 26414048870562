import type { FormatDateOptions } from "react-intl";
import { useIntl } from "react-intl";

import type { BaseDynamicValueProps } from "../base-dynamic-value";

interface DynamicDateValueProps extends BaseDynamicValueProps<string> {
  formatOptions?: FormatDateOptions;
}

const defaultFormatOptions: FormatDateOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  timeZone: "UTC",
};

export const DateValue = ({ formatOptions, value }: DynamicDateValueProps) => {
  const { formatDate } = useIntl();

  const dates = (Array.isArray(value) ? value : [value]).filter(
    (date) => !!date,
  );

  return (
    <ul>
      {dates.map((date, i) => (
        <li key={`${date}-${i}`}>
          {date
            ? formatDate(date, formatOptions ?? defaultFormatOptions)
            : null}
        </li>
      ))}
    </ul>
  );
};
