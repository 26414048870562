import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { Checkbox } from "@themis/ui-library/components/inputs/checkbox/checkbox";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import type { ChangeEvent, ReactElement } from "react";
import { useIntl } from "react-intl";

import { useUpdateComment } from "@/api/queries/comments/use-update-comment";
import { useCurrentUser } from "@/api/queries/users";
import UserAvatar from "@/components/UserAvatar";
import { usePermissions } from "@/hooks/use-permissions";

import type { CommentWithUser } from "../../comment-with-user";
import { CommentActions } from "../comment-actions/comment-actions";

interface CommentHeaderProps {
  comment: CommentWithUser;
  recordId: number;
  recordTypePath: RecordTypePath;
  recordName: string;
  onEditClick: () => void;
}

export function CommentHeader({
  comment,
  recordId,
  recordName,
  recordTypePath,
  onEditClick,
}: CommentHeaderProps): ReactElement {
  const { formatDate } = useIntl();
  const { mutate: updateComment } = useUpdateComment({
    recordType: recordTypePath,
    recordId,
  });
  const { data: currentUser } = useCurrentUser();
  const workspacePermissions = usePermissions({
    domain: "workspace",
  });

  const isAuthor = currentUser?.id === comment.author_id;
  const isUserTagged =
    !!currentUser?.id && comment.tagged_user_ids.includes(currentUser?.id);

  const canResolveComment =
    isAuthor || workspacePermissions.manage_comments || isUserTagged;

  const handleResolveComment = (
    _event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    updateComment({
      commentId: comment.id,
      data: {
        comment: {
          resolved: checked,
        },
      },
    });
  };

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center">
        <UserAvatar user={comment.user} />
        <Stack spacing={0}>
          <Typography variant="subtitle2">{comment.user?.full_name}</Typography>
          <Typography variant="body2" color="text.secondary">
            {formatDate(comment.created_at, {
              dateStyle: "medium",
              timeStyle: "short",
            })}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" spacing={0}>
        {canResolveComment && (
          <Checkbox
            size="small"
            checked={comment.resolved}
            onChange={handleResolveComment}
          />
        )}
        <CommentActions
          comment={comment}
          recordId={recordId}
          recordName={recordName}
          recordTypePath={recordTypePath}
          onEditClick={onEditClick}
        />
      </Stack>
    </Stack>
  );
}
