import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ArchiveRecordMutationResponse,
  ArchiveRecordPathParams,
} from "../../models/ArchiveRecord";

/**
 * @description Archives the specified record
 * @summary Archive a record
 * @link /:record_type/:record_id/archive
 */
export async function archiveRecord(
  recordType: ArchiveRecordPathParams["record_type"],
  recordId: ArchiveRecordPathParams["record_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ArchiveRecordMutationResponse>["data"]> {
  const res = await client<ArchiveRecordMutationResponse>({
    method: "post",
    url: `/${recordType}/${recordId}/archive`,
    ...options,
  });
  return res.data;
}
