import type { CustomCellRendererProps } from "@themis/ui";
import { actionsColumnDefParams } from "@themis/ui-library/components/data-grid/column-defs/actions-column-def-params";
import type { ColDef } from "ag-grid-community";
import { useMemo } from "react";

import type { RecordLink, RecordTypePath } from "@/api";

import { RecordLinkActionsCell } from "../record-link-actions-cell/record-link-actions-cell";

interface UseRecordLinkColumnsParams {
  type: "outward_record" | "record";
  recordType: RecordTypePath;
}

export function useRecordLinkColumns({
  type,
  recordType,
}: UseRecordLinkColumnsParams): ColDef<RecordLink>[] {
  return useMemo(
    () => [
      {
        headerName: "Title",
        field: `${type}.name`,
        cellRenderer: "identifierCellRenderer",
        cellRendererParams: (props: CustomCellRendererProps<RecordLink>) => {
          const record = props.data?.[type];
          return record
            ? { ...props, to: record.path }
            : { ...props, value: "No Access" };
        },
      },
      {
        ...actionsColumnDefParams,
        maxWidth: 42,
        cellRenderer: (props: CustomCellRendererProps<RecordLink>) => {
          if (!props.data?.[type] || type === "record") {
            return null;
          }
          return (
            <RecordLinkActionsCell
              recordLink={props.data}
              recordType={recordType}
            />
          );
        },
      },
    ],
    [recordType, type],
  );
}
