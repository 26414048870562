import { LoadingOverlay } from "@themis/ui-library/components/data-grid/loading-overlay/loading-overlay";
import { Redirect, useParams } from "react-router";

import { useRecordPath } from "../use-record-path/use-record-path";

export default function RecordRedirectPage() {
  const { workspace_id, record_id, record_type } = useParams<{
    workspace_id: string;
    record_id: string;
    record_type: string;
  }>();

  const recordPath = useRecordPath({
    recordId: record_id,
    recordType: record_type,
    workspaceId: workspace_id,
  });

  if (recordPath?.loading) {
    return <LoadingOverlay />;
  }

  if (recordPath?.path) {
    return <Redirect to={recordPath.path} />;
  }

  if (!recordPath?.path && recordPath?.loading === false) {
    return <Redirect to="/" />;
  }

  return null;
}
