import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { getLayoutsQueryKey } from "@/api/queries/layouts/use-layouts";

import type {
  RecordTypePath,
  UpdateFieldMutationRequest,
  UpdateFieldMutationResponse,
  UpdateFieldPathParams,
} from "../../gen";
import { updateField } from "../../gen/axios/fieldsController";
import { getRecordsQueryKey } from "../records/get-records-query-key";
import { getFieldsQueryKey } from "./use-fields";

interface UpdateFieldMutationParams {
  companyId: number | undefined;
  recordType: RecordTypePath;
}

type UpdateFieldMutationOptions = Partial<
  UseMutationOptions<
    UpdateFieldMutationResponse,
    unknown,
    { data: UpdateFieldMutationRequest; fieldId: UpdateFieldPathParams["id"] }
  >
>;

export function useUpdateField(
  { companyId, recordType }: UpdateFieldMutationParams,
  options: UpdateFieldMutationOptions = {},
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ data, fieldId }) => {
      if (companyId == null) {
        throw new Error("companyId is required");
      }

      return updateField(companyId, recordType, fieldId, data);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: getFieldsQueryKey(),
          refetchType: "all",
        }),
        queryClient.invalidateQueries({
          queryKey: getLayoutsQueryKey(),
          refetchType: "all",
        }),
      ]);
      await queryClient.invalidateQueries({
        queryKey: getRecordsQueryKey({ recordType }),
        refetchType: "all",
      });
    },
    ...options,
  });
}
