import type {
  ButtonTypeMap,
  ButtonProps as MuiButtonProps,
} from "@mui/material/Button";
import MuiButton from "@mui/material/Button";
import type { ElementType, Ref } from "react";
import { forwardRef } from "react";

import { Box } from "../../layout/box/box";

export type ButtonProps<
  Component extends ElementType = ButtonTypeMap["defaultComponent"],
> = MuiButtonProps<Component> & {
  noEllipsis?: boolean;
};

function BaseButton<T extends ElementType>(
  { noEllipsis, children, ...rest }: ButtonProps<T>,
  ref?: Ref<HTMLElement>,
) {
  const _children =
    typeof children !== "string" || noEllipsis ? (
      children
    ) : (
      <Box
        component="span"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        sx={{ overflowX: "hidden" }}
      >
        {children}
      </Box>
    );

  return (
    <MuiButton ref={ref as Ref<HTMLButtonElement>} {...rest}>
      {_children}
    </MuiButton>
  );
}

export const Button = forwardRef(BaseButton) as typeof BaseButton;
export { buttonClasses } from "@mui/material/Button";
