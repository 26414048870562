import type { ReactNode } from "react";
import { createContext, useContext, useMemo } from "react";

interface RecordCommentsContextType {
  commentDetailUrl?: string;
}

export const RecordCommentsContext =
  createContext<RecordCommentsContextType | null>(null);

export function RecordCommentsProvider({
  children,
  commentDetailUrl,
}: {
  children: ReactNode;
  commentDetailUrl: string;
}) {
  const contextValue = useMemo(() => {
    return {
      commentDetailUrl,
    };
  }, [commentDetailUrl]);

  return (
    <RecordCommentsContext.Provider value={contextValue}>
      {children}
    </RecordCommentsContext.Provider>
  );
}

export function useRecordCommentsContext() {
  const context = useContext(RecordCommentsContext);

  return context;
}
