import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  LegacyWebsiteMonitoringGroupChildPathParams,
  LegacyWebsiteMonitoringGroupChildQueryParams,
  LegacyWebsiteMonitoringGroupChildQueryResponse,
} from "../../models/LegacyWebsiteMonitoringGroupChild";

/**
 * @description List Website Monitoring - Groups with children
 * @summary List Website Monitoring Group with children
 * @link /website_monitoring/:monitoring_group_asset_id/monitoring_group_children
 */
export async function legacyWebsiteMonitoringGroupChild(
  monitoringGroupAssetId: LegacyWebsiteMonitoringGroupChildPathParams["monitoring_group_asset_id"],
  params?: LegacyWebsiteMonitoringGroupChildQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<LegacyWebsiteMonitoringGroupChildQueryResponse>["data"]
> {
  const res = await client<LegacyWebsiteMonitoringGroupChildQueryResponse>({
    method: "get",
    url: `/website_monitoring/${monitoringGroupAssetId}/monitoring_group_children`,
    params,
    ...options,
  });
  return res.data;
}
