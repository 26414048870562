import type { ActiveStorageFileUpload } from "react-activestorage-provider";

const getPercentage = (upload: ActiveStorageFileUpload) => {
  if (upload.state === "uploading") {
    return Math.round(upload.progress);
  }

  if (upload.state === "finished") {
    return 100;
  }

  return 0;
};

export const getUploadLoadingAndPercentage = (
  uploads: ActiveStorageFileUpload[],
) => {
  const isLoading = uploads.some(
    (upload) => upload.state === "uploading" || upload.state === "waiting",
  );

  const percentage = Math.round(
    uploads.reduce(
      (acc, upload) => acc + getPercentage(upload) / uploads.length,
      0,
    ),
  );

  return { isLoading, percentage };
};
