import { useToast } from "@themis/ui";
import { Divider } from "@themis/ui-library/components/data-display/divider/divider";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { Dialog } from "@themis/ui-library/components/feedback/dialog/dialog";
import { DialogActions } from "@themis/ui-library/components/feedback/dialog/dialog-actions/dialog-actions";
import { DialogContent } from "@themis/ui-library/components/feedback/dialog/dialog-content/dialog-content";
import { FormControlLabel } from "@themis/ui-library/components/form/form-control-label/form-control-label";
import { FormGroup } from "@themis/ui-library/components/form/form-group/form-group";
import { Button } from "@themis/ui-library/components/inputs/button/button";
import { Checkbox } from "@themis/ui-library/components/inputs/checkbox/checkbox";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { Controller, useForm } from "react-hook-form";
import { PiX } from "react-icons/pi";
import { defineMessages, useIntl } from "react-intl";

import type { ExportPdfPathParams, ExportPdfQueryParamsSections } from "@/api";
import { exportPdfQueryParamsSections } from "@/api";
import { useExportPdf } from "@/api/queries/export-pdf/use-export-pdf";
import { useFetchFile } from "@/api/queries/use-fetch-file";
import { downloadFile } from "@/stores/helpers/AttachmentHelper";

interface PdfExportProps {
  recordType: ExportPdfPathParams["record_type"];
  recordId: ExportPdfPathParams["record_id"];
  open: boolean;
  onClose: () => void;
}

type PdfExportFormSchema = Record<ExportPdfQueryParamsSections, boolean>;

const exportOptionMessages = defineMessages<string>({
  [exportPdfQueryParamsSections.record_details]: {
    defaultMessage: "Record Details",
  },
  [exportPdfQueryParamsSections.record_comments_summary]: {
    defaultMessage: "Record Comments Summary",
  },
  [exportPdfQueryParamsSections.attachments]: {
    defaultMessage: "Attachment(s)",
  },
  [exportPdfQueryParamsSections.attachments_comment_summary]: {
    defaultMessage: "Attachments Comment Summary",
  },
});

export function PdfExport({
  recordType,
  recordId,
  open,
  onClose,
}: PdfExportProps) {
  const { formatMessage } = useIntl();
  const { control, handleSubmit, watch, setValue } = useForm<
    Record<ExportPdfQueryParamsSections, boolean>
  >({
    defaultValues: Object.values(exportPdfQueryParamsSections).reduce(
      (acc, key) => {
        acc[key] = true;
        return acc;
      },
      {} as PdfExportFormSchema,
    ),
  });
  const toast = useToast();
  const { mutateAsync: fetchFile } = useFetchFile();
  const { mutateAsync: getPdfReport } = useExportPdf(recordType, recordId, {
    onSuccess: () => {
      toast({
        content: formatMessage({
          defaultMessage: "PDF Report has been exported!",
        }),
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: formatMessage({
          defaultMessage: "Failed to export PDF Report!",
        }),
        variant: "error",
      });
    },
  });

  const values = watch();
  const allChecked = Object.values(values).every(Boolean);
  const isAnyCheckboxChecked = Object.values(values).some(Boolean);

  const handleSelectAll = () => {
    const newValue = !allChecked;
    Object.values(exportPdfQueryParamsSections).forEach((key) => {
      setValue(key, newValue);
    });
  };

  const onSubmit = async (formData: PdfExportFormSchema) => {
    onClose();
    toast({
      content: formatMessage({
        defaultMessage: "PDF is exporting...",
      }),
      variant: "success",
    });

    const serializedData = {
      sections: (
        Object.keys(formData) as ExportPdfQueryParamsSections[]
      ).filter((key) => formData[key]),
    };

    const data = await getPdfReport(serializedData);

    if (!data) {
      toast({
        content: formatMessage({
          defaultMessage: "Failed to export PDF Report!",
        }),
        variant: "error",
      });

      return;
    }

    const blob = await fetchFile(data.file_url);
    downloadFile(blob, data.file_name);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent
          sx={(theme) => ({
            p: theme.spacing(3),
          })}
        >
          <Stack
            justifyContent="space-between"
            direction="row"
            alignItems="center"
            sx={(theme) => ({
              pb: theme.spacing(4),
            })}
          >
            <Stack spacing={0}>
              <Typography variant="h6">
                {formatMessage({
                  defaultMessage: "Export PDF Report",
                })}
              </Typography>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  color: theme.palette.text.secondary,
                })}
              >
                {formatMessage({
                  defaultMessage: "Select sections to include in your export",
                })}
              </Typography>
            </Stack>
            <PiX onClick={onClose} className="tw-cursor-pointer" size={16} />
          </Stack>
          <Stack component={FormGroup} spacing={0}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={allChecked}
                  onChange={handleSelectAll}
                />
              }
              label={
                <Typography>
                  {formatMessage({
                    defaultMessage: "Export Full PDF Report",
                  })}
                </Typography>
              }
            />
            <Divider
              sx={(theme) => ({
                mx: theme.spacing(-3),
                my: theme.spacing(1),
              })}
            />
            {Object.entries(exportPdfQueryParamsSections).map(
              ([key, value]) => (
                <Controller
                  key={key}
                  name={value as ExportPdfQueryParamsSections}
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          {...field}
                          checked={field.value}
                        />
                      }
                      label={
                        <Typography>
                          {formatMessage(exportOptionMessages[value])}
                        </Typography>
                      }
                    />
                  )}
                />
              ),
            )}
          </Stack>
        </DialogContent>
        <DialogActions
          sx={(theme) => ({
            p: theme.spacing(1, 3, 3, 3),
          })}
        >
          <Button type="submit" disabled={!isAnyCheckboxChecked}>
            {formatMessage({
              defaultMessage: "Export",
            })}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
