import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import type {
  CreateRecordLinkMutationResponse,
  DeleteRecordLinkPathParams,
  RecordTypePath,
} from "@/api/gen";
import { deleteRecordLink } from "@/api/gen/axios/recordLinksController";

export function useDeleteRecordLink<TError = Error, TContext = unknown>(
  recordType: RecordTypePath,
  {
    onSuccess,
    onError,
    ...options
  }: UseMutationOptions<
    CreateRecordLinkMutationResponse,
    TError,
    DeleteRecordLinkPathParams["id"],
    TContext
  > = {},
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (recordLinkId) => deleteRecordLink(recordLinkId),
    onSuccess: (...args) => {
      queryClient.invalidateQueries({
        queryKey: [recordType],
      });

      onSuccess?.(...args);
    },
    onError,
    ...options,
  });
}
