import { useClientDataGridBinding } from "@themis/ui-library/components/data-grid/binding/use-client-data-grid-binding";
import { DataGrid } from "@themis/ui-library/components/data-grid/data-grid";
import { useMemo } from "react";

import { useAccountVendors } from "@/api/queries/accounts";

import { selectTableRows } from "./select-table-rows/select-table-rows";
import { useDueDiligenceTableColumns } from "./use-due-diligence-table-columns/use-due-diligence-table-columns";

interface DueDiligenceTableProps {
  workspaceId: string;
  accountId: string;
}

export const DueDiligenceTable = ({
  workspaceId,
  accountId,
}: DueDiligenceTableProps) => {
  const { data } = useAccountVendors({
    workspaceId: Number(workspaceId),
    accountId: Number(accountId),
  });
  const tableRows = useMemo(() => selectTableRows(data), [data]);

  const columns = useDueDiligenceTableColumns(workspaceId);

  const { clientGridProps } = useClientDataGridBinding(tableRows);

  return (
    <DataGrid
      data-testid="due-diligence-table"
      columns={columns}
      width="100%"
      {...clientGridProps}
    />
  );
};
