import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams, ModuleStore } from "../types/module-store-types";

export default class QA implements ModuleStore {
  mainStore: MainStore;

  data = {
    isInternal: true,
    recordVersions: {
      list: [
        {
          id: 1,
        },
      ],
    },
  };
  qaTableID = undefined;
  qaTableName = undefined;

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      qaTableID: observable,
      qaTableName: observable,

      setData: action,
      setQATableID: action,
      setQATableName: action,
      setTestSubRecords: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/qa_tests_developments
  async index({ sortParams, tableFilters = [] }: IndexParams) {
    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
      };

      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/qa_tests_developments`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      if (response.data) {
        this.setData(response.data);
        this.mainStore.recordVersions.setList(response.data.record_versions);
      }
    } catch (error) {
      window.console.warn(`"QA #Index for Workspace" error ${error}`);
    }
  }

  // GET /api/react/qa_test_procedures
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async indexTestProcedures(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/qa_test_procedures?record_version_id=${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
      this.setTestSubRecords(response.data);
      this.mainStore.fields.setList(response.data.fields);
      return response.data;
    } catch (error) {
      window.console.warn(
        `"QA #indexTestProcedures for Workspace" error ${error}`,
      );
    }
  }

  // GET /api/react/qa_test_procedures
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async indexTestFindings(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/qa_test_findings?record_version_id=${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
      this.setTestSubRecords(response.data);
      this.mainStore.fields.setList(response.data.fields);
      return response.data;
    } catch (error) {
      window.console.warn(
        `"QA #indexTestFindings for Workspace" error ${error}`,
      );
    }
  }

  // POST /api/react/qa_tests_developments/:qa_tests_development_id/pdf_report
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async generateReport(recordVersionID) {
    try {
      const { data } = await legacyApi({
        method: "POST",
        url: `${API_URL}/qa_tests_developments/${recordVersionID}/pdf_report`,
        headers: this.mainStore.getHeaders(),
      });
      return data;
    } catch (error) {
      window.console.warn(`"QA #recordVersionID for Workspace" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async createQATestFindings(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/qa_test_findings`,
        headers: this.mainStore.getHeaders(),
        data: { record_version_id: recordVersionID },
      });
      return response.data;
    } catch (error) {
      window.console.warn(
        `"QA #createQATestFindings for Workspace" error ${error}`,
      );
    }
  }

  // GET /api/react/qa_test_procedures
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async testProcedureSteps(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/qa_test_procedures?record_version_id=${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });

      return response.data.record_versions;
    } catch (error) {
      window.console.warn(
        `"QA #indexTestProceduresSteps for Workspace" error ${error}`,
      );
    }
  }

  // POST /api/react/qa_tests_developments/:qa_tests_development_id/complete
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async completeQATest(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/qa_tests_developments/${recordVersionID}/complete`,
        headers: this.mainStore.getHeaders(),
      });
      return response.data;
    } catch (error) {
      window.console.warn(`"QA #completeQATest for Workspace" error ${error}`);
    }
  }

  // PUT /api/react/qa_tests_developments/:qa_tests_development_id/unlock
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unlockQATest(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/qa_tests_developments/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });
      return response.data;
    } catch (error) {
      window.console.warn(`"QA #unlockQATest for Workspace" error ${error}`);
    }
  }

  // POST /api/react/qa_tests_developments
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async createQATestProcedure(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/qa_test_procedures`,
        headers: this.mainStore.getHeaders(),
        data: { record_version_id: recordVersionID },
      });
      return response.data;
    } catch (error) {
      window.console.warn(
        `"QA #createQATestProcedure for Workspace" error ${error}`,
      );
    }
  }

  // POST /api/react/qa_tests_developments
  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async create(workspaceID, sectionTagId) {
    const data = { section_tag_id: sectionTagId };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/qa_tests_developments`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      return response.data.record_version;
    } catch (error) {
      window.console.log(`"QA #create" error ${error}`);
    }
  }

  // DELETE /api/react/qa_tests_developments/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/qa_tests_developments/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"QA#delete" error ${error}`);
    }
  }

  // DELETE /api/react/records/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async deleteProcedureStep(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/records/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"QA#delete" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async deleteTestFindings(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/qa_test_findings/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Test Finding#delete" error ${error}`);
    }
  }

  // PUT /api/react/qa_test_procedures/:qa_test_procedure_id/close
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async closeQAProcedure(recordVersionID) {
    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/qa_test_procedures/${recordVersionID}/close`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"QA#closeQAProcedure" error ${error}`);
    }
  }

  // PUT /api/react/qa_test_procedures/:qa_test_procedure_id/unlock
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async reopenQAProcedure(recordVersionID) {
    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/qa_test_procedures/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"QA#closeQAProcedure" error ${error}`);
    }
  }

  async sendFindingsToIM(
    // @ts-expect-error TS(7006) FIXME: Parameter 'parentRecordVersionID' implicitly has a... Remove this comment to see the full error message
    parentRecordVersionID,
    // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionIDs' implicitly has an 'an... Remove this comment to see the full error message
    recordVersionIDs,
    workspaceID = null,
  ) {
    const params = {
      parent_rv_id: parentRecordVersionID,
      ids: recordVersionIDs,
      workspace_id: workspaceID,
    };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/qa_test_findings/send_records`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (!response.isAxiosError) {
        this.mainStore.toast.setText(
          `${recordVersionIDs.length} Issues has been send to Issue Management!`,
        );
        this.mainStore.dynamicTable.clearSelectedRecordVersionIDs();
      }
    } catch (error) {
      window.console.log(
        `"send test findings to issue management" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async closeFinding(recordVersionID) {
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/qa_test_findings/${recordVersionID}/close`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"close finding" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async indexTestSamples(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/samples?record_version_id=${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
      this.setTestSubRecords(response.data);
      this.mainStore.fields.setList(response.data.fields);
      return response.data;
    } catch (error) {
      window.console.warn(
        `"QA #indexTestSamples for Workspace" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async createTestSample(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/samples`,
        headers: this.mainStore.getHeaders(),
        data: { record_version_id: recordVersionID },
      });
      return response.data;
    } catch (error) {
      window.console.warn(
        `"QA #createTestSample for Workspace" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async deleteTestSample(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/samples/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
        data: { id: recordVersionID },
      });
      return response.data;
    } catch (error) {
      window.console.warn(
        `"QA #createTestSample for Workspace" error ${error}`,
      );
    }
  }

  // PUT /api/react/qa_tests_developments/:id/update_section
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async updateSection(recordVersionID, sectionTagID) {
    const data = {
      section_tag_id: sectionTagID,
      identifier: "qa_tests_development",
    };

    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/qa_tests_developments/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"QaTest#updateSection" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setQATableID(value) {
    this.qaTableID = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setQATableName(value) {
    this.qaTableName = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setTestSubRecords(value) {
    const newValue = value || { record_versions: [] };
    this.mainStore.recordVersions.setList(newValue.record_versions, true);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;
    this.mainStore.fields.setList(value.fields);
    this.mainStore.fieldOptions.setAll(value.field_options);
    this.mainStore.recordVersions.setList(value.record_versions, true);
    this.mainStore.sectionTags.setList(value.section_tags);
  }

  cleanup() {
    this.setData({
      fields: [],
      field_options: [],
      record_versions: [],
      section_tags: [],
    });
    this.setTestSubRecords({ record_versions: [] });
  }

  abort() {
    this.controller?.abort();
  }
}
