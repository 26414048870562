import { useCallback } from "react";

import type { DataGridState } from "./data-grid-state";
import { useDataGridState } from "./use-data-grid-state";
import { useFilters } from "./use-filters/use-filters";
import { useSort } from "./use-sort/use-sort";

interface UsePersistedDataGridStateParams {
  tableKey: string;
  initialState?: Partial<DataGridState>;
}

export function usePersistedDataGridState({
  tableKey,
  initialState,
}: UsePersistedDataGridStateParams) {
  const [initialSorts, handlePersistSort] = useSort({
    localStorageKey: `sort-${tableKey}`,
  });
  const [initialFilters, handlePersistFilters] = useFilters({
    localStorageKey: `filters-${tableKey}`,
  });

  const { dataGridState, updateDataGridState, dataGridQueryParams } =
    useDataGridState({
      ...initialState,
      sorts: initialSorts,
      filters: initialFilters,
    });

  const onUpdateDataGridState = useCallback(
    (params: Partial<DataGridState>) => {
      handlePersistSort(params.sorts);
      handlePersistFilters(params.filters);
      updateDataGridState(params);
    },
    [handlePersistFilters, handlePersistSort, updateDataGridState],
  );

  return {
    dataGridState,
    dataGridQueryParams,
    updateDataGridState: onUpdateDataGridState,
  };
}
