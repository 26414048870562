export const routes = {
  webMonitoring: {
    groups: "/workspaces/:workspace_id/web-monitoring",
    assets: "/workspaces/:workspace_id/web-monitoring/:group_id",
    findings: {
      overview:
        "/workspaces/:workspace_id/web-monitoring/:group_id/assets/:asset_id/findings",
      detail:
        "/workspaces/:workspace_id/web-monitoring/:group_id/assets/:asset_id/findings/detail",
    },
  },
} as const;
