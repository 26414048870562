import type { ReactElement, ReactNode } from "react";

import { Stack } from "../../stack/stack";

interface DetailMediaLayoutMediaProps {
  children: ReactNode;
}

export function DetailMediaLayoutMedia({
  children,
}: DetailMediaLayoutMediaProps): ReactElement {
  return (
    <Stack
      padding={0}
      width="1200px"
      maxWidth="60%"
      flexGrow={0}
      flexShrink={0}
      height="100%"
      alignSelf="stretch"
    >
      {children}
    </Stack>
  );
}
