import type { ThemeOptions } from "@mui/material";
import { CaretDown } from "@phosphor-icons/react";

export const select: ThemeOptions["components"] = {
  MuiSelect: {
    defaultProps: {
      IconComponent: CaretDown,
    },
    styleOverrides: {
      root: ({ theme }) => ({
        borderRadius: theme.shape.borderRadius,
        "&:focus": {
          borderRadius: theme.shape.borderRadius,
        },
      }),
    },
  },
};
