import { faker } from "@faker-js/faker";

import type {
  UpdatePublicFormLayout200,
  UpdatePublicFormLayoutMutationRequest,
  UpdatePublicFormLayoutMutationResponse,
  UpdatePublicFormLayoutPathParams,
} from "../models/UpdatePublicFormLayout";
import { createPublicFormLayoutRequest } from "./createPublicFormLayoutRequest";
import { createPublicFormLayoutResponse } from "./createPublicFormLayoutResponse";

export function createUpdatePublicFormLayoutPathParams(): NonNullable<UpdatePublicFormLayoutPathParams> {
  return { module_workspace_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdatePublicFormLayout200(): NonNullable<UpdatePublicFormLayout200> {
  return createPublicFormLayoutResponse();
}

export function createUpdatePublicFormLayoutMutationRequest(): NonNullable<UpdatePublicFormLayoutMutationRequest> {
  return createPublicFormLayoutRequest();
}

/**
 * @description OK
 */
export function createUpdatePublicFormLayoutMutationResponse(): NonNullable<UpdatePublicFormLayoutMutationResponse> {
  return createPublicFormLayoutResponse();
}
