export const routes = {
  QUESTIONNAIRE_FORM_PATH: "/forms/questionnaires",
  BASE_PATH: "/workspaces/:workspace_id/modules/risk-assessment",
  DASHBOARD_PATH: "/workspaces/:workspace_id/modules/risk-assessment/dashboard",
  COLLABORATIVE_DASHBOARD_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/collaborative",
  DETAIL_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:assessmentId",
  CREATE_ATTACHMENT_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/attachment_view/:record_version_id/:file_id",

  PARTNERS_PATH: "/workspaces/:workspace_id/modules/risk-assessment/partners",
  PARTNERS_DETAILS_NEW_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/add-new-partners",
  PARTNERS_DETAILS_EDIT_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id",
  PARTNERS_QUESTIONNAIRES_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/questionnaires",
  PARTNERS_QUESTIONNAIRES_CREATE_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/questionnaires/create",
  PARTNERS_QUESTIONNAIRES_CREATE_WITH_TEMPLATE_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/questionnaires/create/:template_id",
  PARTNERS_SCORING_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/scoring",
  PARTNERS_ASSESSMENTS_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/assessments",
  PARTNERS_DOCUMENTS_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/documents",
  CW_PARTNERS_DOCUMENTS_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/documents",

  QUESTIONNAIRE_TEMPLATE_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/questionnaire-template",
  QUESTIONNAIRE_TEMPLATE_CREATION_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/questionnaire-template/create",
  QUESTIONNAIRE_TEMPLATE_EDIT_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/questionnaire-template/:templateId",
  PARTNERS_QUESTIONNAIRE_REVIEW_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/partner-questionnaire/review/:questionnaireId",
  INTERNAL_QUESTIONNAIRE_REVIEW_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/internal-questionnaire/review/:questionnaireId",

  QUESTIONNAIRE_REVIEW_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/questionnaires/:questionnaireId/review",
  QUESTIONNAIRE_SCORING_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/:record_version_id/questionnaires/:questionnaireId/scoring",

  CUSTOMER_QUESTIONNAIRE_DETAIL_REVIEW_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/partner-questionnaire/:questionnaireId/review",
  CUSTOMER_QUESTIONNAIRE_SCORING_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/partner-questionnaire/:questionnaireId/scoring",

  METHODOLOGY_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/risk-methodology",
  METHODOLOGY_CREATION_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/risk-methodologies/create",
  METHODOLOGY_EDIT_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/risk-methodologies/:riskMethodologyId",

  RISK_AREA_PATH:
    "/workspaces/:workspace_id/modules/risk-assessment/templates/risk-area",
};
