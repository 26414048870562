import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  LegacyWebsiteMonitoringGroupAssetUpdateMutationRequest,
  LegacyWebsiteMonitoringGroupAssetUpdateMutationResponse,
  LegacyWebsiteMonitoringGroupAssetUpdatePathParams,
} from "../../models/LegacyWebsiteMonitoringGroupAssetUpdate";

/**
 * @description Update Website Monitoring - Group asset
 * @summary Update Website Monitoring group asset (Legacy)
 * @link /website_monitoring/:monitoring_group_asset_id/monitoring_group_asset_update
 */
export async function legacyWebsiteMonitoringGroupAssetUpdate(
  monitoringGroupAssetId: LegacyWebsiteMonitoringGroupAssetUpdatePathParams["monitoring_group_asset_id"],
  data: LegacyWebsiteMonitoringGroupAssetUpdateMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<LegacyWebsiteMonitoringGroupAssetUpdateMutationResponse>["data"]
> {
  const res = await client<
    LegacyWebsiteMonitoringGroupAssetUpdateMutationResponse,
    LegacyWebsiteMonitoringGroupAssetUpdateMutationRequest
  >({
    method: "put",
    url: `/website_monitoring/${monitoringGroupAssetId}/monitoring_group_asset_update`,
    data,
    ...options,
  });
  return res.data;
}
