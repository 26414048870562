import type { MenuItemProps } from "@mui/material/MenuItem";
import type { ReactElement } from "react";

import { styled } from "../../../styles";
import { MenuItem } from "../menu/menu-item/menu-item";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.main,
  },
}));

export function DeleteMenuItem(props: MenuItemProps): ReactElement {
  return <StyledMenuItem {...props} />;
}
