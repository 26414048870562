import type { AxiosResponse } from "axios";
import { action, makeObservable, observable } from "mobx";

import type { RecordVersion } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";
import { fetchRecordVersion } from "@/api/legacy/record-versions";
import type { CellError } from "@/stores/types/record-types";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { DuplicateOption } from "../types/duplicate-option";

export default class RecordVersionsStore {
  mainStore: MainStore;

  list: RecordVersion[] = [];
  current?: RecordVersion | null = null;
  cellsErrors: CellError[] = [];
  newRecordVersionErrors = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      list: observable,
      current: observable,
      cellsErrors: observable,
      newRecordVersionErrors: observable,

      setList: action,
      resetList: action,
      setCurrent: action,
      setCellsErrors: action,
      setNewRecordVersionErrors: action,
      createUpdateRecordVersion: action,
      replaceRecordVersion: action,
      moveRecordVersionLocally: action,
      deleteRecordVersionByID: action,
      deleteByRecordID: action,
    });

    this.mainStore = mainStore;
  }

  // API

  // GET /api/react/record_versions/:id
  async fetch(recordVersionID: number, fetchOnly = false) {
    try {
      const { data } = await legacyApi({
        method: "GET",
        url: `${API_URL}/record_versions/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });

      if (fetchOnly) {
        return data.record_version;
      }

      // Update current RecordVersion and fields for it
      this.setCurrent(data.record_version);
      this.mainStore.fields.setList(data.fields);

      // Update in the list
      this.createUpdateRecordVersion(data.record_version);

      return data;
    } catch (error) {
      window.console.log(`"RecordVersions#fetch" error ${error}`);
    }
  }

  /** GET /api/react/record_versions/:id
   * @param recordVersionID
   * @returns RecordVersion object or null
   */
  async fetchRecordVersionByID(recordVersionID: number) {
    try {
      const data = await fetchRecordVersion(recordVersionID);
      return data.record_version;
    } catch (error) {
      return null;
    }
  }

  async fetchPublic(external_token: string) {
    try {
      const { data } = await legacyApi({
        method: "GET",
        url: `${API_URL}/record_versions/${external_token}/show_public`,
        headers: this.mainStore.getHeaders(),
      });

      // Update current
      this.setCurrent(data.record_version);

      // Update in the list
      this.createUpdateRecordVersion(data.record_version);
    } catch (error) {
      window.console.log(`"RecordVersions#fetchPublic" error ${error}`);
    }
  }

  // PUT /api/react/record_versions/:id
  async update({
    recordVersionID = null,
    fieldName = null,
    value = null,
  }: {
    recordVersionID?: number | string | null;
    fieldName?: string | null;
    value: Record<string, unknown> | null;
  }) {
    if (!recordVersionID || !fieldName || !value) {
      window.console.warn(
        `RecordVersions#update": recordVersionID(${recordVersionID}), fieldName(${fieldName}), value(${value}) should be present`,
      );
      return;
    }

    // Reset errors before update
    this.setCellsErrors([]);

    const params: Record<string, unknown> = {};
    params[fieldName] = value;
    const recordVersionParams = { data: params };

    try {
      const { data } = await legacyApi({
        method: "PUT",
        url: `${API_URL}/record_versions/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
        data: { record_version: recordVersionParams },
      });

      return data?.record_version;
    } catch (error) {
      window.console.log(`"RecordVersions#update" error ${error}`);

      return false;
    }
  }

  // PUT /api/react/record_versions/:id
  async updateFields(
    recordVersionId: number,
    fieldUpdates: Array<{ fieldName: string; value: unknown }>,
  ) {
    if (!recordVersionId || fieldUpdates.length === 0) {
      return;
    }

    // Reset errors before update
    this.setCellsErrors([]);

    const params = fieldUpdates.reduce(
      (current, fieldUpdate) => ({
        ...current,
        [fieldUpdate.fieldName]: fieldUpdate.value,
      }),
      {},
    );
    const recordVersionParams = { data: params };

    try {
      const { data } = (await legacyApi({
        method: "PUT",
        url: `${API_URL}/record_versions/${recordVersionId}`,
        headers: this.mainStore.getHeaders(),
        data: { record_version: recordVersionParams },
      })) as { data: { record_version: RecordVersion } };

      return data?.record_version;
    } catch (error) {
      window.console.log(`"RecordVersions#updateFields" error ${error}`);

      return false;
    }
  }

  // DELETE /api/react/record_versions/:id
  async delete(recordVersionID: number) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/record_versions/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"RecordVersions#delete" error ${error}`);
    }
  }

  // DELETE /api/react/:identifier/bulk_delete
  async bulkDelete(recordVersionIDs: number[], themisModuleRoute: string) {
    const data = { record_version_ids: recordVersionIDs };
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/${themisModuleRoute}/bulk_delete`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"${themisModuleRoute}#bulk_delete" error ${error}`);
    }
  }

  // PUT /api/react/:identifier/bulk_move
  async bulkMove({
    // @ts-expect-error TS(7031) FIXME: Binding element 'recordVersionIDs' implicitly has ... Remove this comment to see the full error message
    workspaceID,
    // @ts-expect-error TS(7031) FIXME: Binding element 'recordVersionIDs' implicitly has ... Remove this comment to see the full error message
    recordVersionIDs,
    // @ts-expect-error TS(7031) FIXME: Binding element 'folderID' implicitly has an 'any'... Remove this comment to see the full error message
    folderID,
    // @ts-expect-error TS(7031) FIXME: Binding element 'currentFolderID' implicitly has a... Remove this comment to see the full error message
    currentFolderID,
    // @ts-expect-error TS(7031) FIXME: Binding element 'themisModuleRoute' implicitly has... Remove this comment to see the full error message
    themisModuleRoute,
  }) {
    const data = {
      record_version_ids: recordVersionIDs,
      section_tag_id: folderID,
    };

    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/${themisModuleRoute}/bulk_move`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      await this.mainStore.documents.index({
        workspaceID,
        folderID: currentFolderID,
      });
    } catch (error) {
      window.console.log(`"${themisModuleRoute}#bulk_move" error ${error}`);
    }
  }

  // POST /api/react/shared_records
  async share(
    shareableID: number,
    shareableType: string,
    workspaceIDs: number[],
  ) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/shared_records`,
        headers: this.mainStore.getHeaders(),
        data: {
          shared_record: {
            workspace_ids: workspaceIDs,
            shareable_id: shareableID,
            shareable_type: shareableType,
          },
        },
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        const toastError = response?.response?.data?.errors?.base[0] || "error";
        this.mainStore.toast.setErrorText(toastError);
        return;
      }

      this.mainStore.toast.setInfoText("Record successfully shared");
    } catch (error) {
      window.console.log(`"RecordVersions#share" error ${error}`);
    }
  }

  // POST /api/react/record_versions/:id/send_record
  async send(recordVersionID: number, workspaceIDs: number[]) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/send_record`,
        headers: this.mainStore.getHeaders(),
        data: { workspace_ids: workspaceIDs },
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        const toastError = response?.response?.data?.errors?.base || "error";
        this.mainStore.toast.setErrorText(toastError);
        return;
      }

      this.mainStore.toast.setInfoText("Record successfully shared");
    } catch (error) {
      window.console.log(`"RecordVersions#send" error ${error}`);
    }
  }

  // POST /api/react/record_versions/:id/reposition
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async reorder(recordVersionID: number, payload) {
    try {
      this.moveRecordVersionLocally(
        recordVersionID,
        payload.following_record_id,
      );
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/reposition`,
        headers: this.mainStore.getHeaders(),
        data: payload,
      });

      if (response.data?.record_version) {
        this.replaceRecordVersion(
          recordVersionID,
          response.data.record_version,
        );
      }
    } catch (error) {
      window.console.log(`"RecordVersions#reorder" error ${error}`);
    }
  }

  async duplicate(recordVersionID: number, options?: DuplicateOption[]) {
    try {
      const response: AxiosResponse<{ record_version: RecordVersion }> =
        await legacyApi({
          method: "POST",
          url: `${API_URL}/record_versions/${recordVersionID}/duplicate`,
          data: { options },
          headers: this.mainStore.getHeaders(),
        });

      if (response.data.record_version) {
        this.mainStore.toast.setText("Record has been duplicated!");
      } else {
        this.mainStore.toast.setErrorText("An unexpected error occurred.");
      }
    } catch (error) {
      window.console.log(`"RecordVersions#duplicate" error ${error}`);
    }
  }

  async getLatestRecordVersion(recordVersionID: number) {
    // Clean up before request
    this.mainStore.fieldOptions.setAll({});
    this.mainStore.fields.setList([]);
    this.setCurrent(null);

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/record_versions/latest_record_version/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
      const { data } = response;
      this.mainStore.fieldOptions.setAll(data.field_options);
      this.mainStore.fields.setList(data.fields);
      this.setCurrent(data.record_version);
      return data;
    } catch (error) {
      window.console.log(
        `"RecordVersions#getLatestRecordVersion" error ${error}`,
      );
    }
  }

  // Actions

  moveRecordVersionLocally(recordVersionId: number, followingId: number) {
    const itemToAdd = this.list.find((r) => r.id === recordVersionId);
    if (!itemToAdd) {
      return;
    }

    let isAdded = false;
    const newList: RecordVersion[] = [];
    for (const rv of this.list) {
      if (rv.id === recordVersionId) {
        continue;
      }
      if (rv.record_id === followingId) {
        isAdded = true;
        newList.push(itemToAdd);
      }
      newList.push(rv);
    }
    if (!isAdded) {
      newList.push(itemToAdd);
    }
    this.list = newList;
  }

  setList(value: RecordVersion[] | undefined, forceUpdate = false) {
    if (Array.isArray(value) && value.length > 0) {
      // Make sure current moduleWorkspaceID matches records in JSON response
      const responseModuleWorkspaceID = value[0]?.record?.module_workspace_id;
      const currentModuleWorkspaceID = this.mainStore.context.moduleWorkspaceID;

      if (
        forceUpdate ||
        responseModuleWorkspaceID === undefined ||
        responseModuleWorkspaceID === currentModuleWorkspaceID
      ) {
        this.list = value;
      }
    } else {
      this.list = [];
    }
  }

  resetList() {
    this.list = [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrent(value) {
    this.current = value || null;
  }

  setCellsErrors(value: CellError[]) {
    if (value && Array.isArray(value)) {
      this.cellsErrors = value;
    } else {
      this.cellsErrors = [];
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setNewRecordVersionErrors(value) {
    if (value && Array.isArray(value)) {
      // @ts-expect-error TS(2322) FIXME: Type 'any[]' is not assignable to type 'never[]'.
      this.newRecordVersionErrors = value;
    } else {
      this.newRecordVersionErrors = [];
    }
  }

  createUpdateRecordVersion(
    newRecordVersion: RecordVersion,
    onlyIfCurrent = false,
  ) {
    // Filter out data, so "support" Users can't see data of each other in Complaints module
    const { isSupport, themisModuleIdentifier, tableName } =
      this.mainStore.context;
    const { id: userID } = this.mainStore.users.user;
    const { owner_id: newRecordOwnerID } = newRecordVersion.record;

    // TODO: LinkedDocuments special logic, until we migrate to Module-specific data streams everywhere
    const recordModuleWorkspaceID =
      newRecordVersion.record?.module_workspace_id;
    if (
      this.mainStore.linkedDocuments.moduleWorkspaceID ===
      recordModuleWorkspaceID
    ) {
      this.mainStore.linkedDocuments.updateRecordVersion(newRecordVersion);
      return;
    }

    // In FINRA and Customer Support modules users with "Support" Role can only see their own tasks
    const finraCondition =
      themisModuleIdentifier === "finra" && tableName === "FINRA Escalated";
    const customerSupportCondition =
      themisModuleIdentifier === "customer_support" &&
      tableName === "Customer Support";
    if (
      isSupport &&
      (finraCondition || customerSupportCondition) &&
      userID !== newRecordOwnerID
    ) {
      return;
    }

    // Modify RecordVersion in list if it exists
    const alreadyExists = Boolean(
      this.list.find(
        (recordVersion) => recordVersion.id === newRecordVersion.id,
      ),
    );
    if (!alreadyExists) {
      if (!this.shouldAddNewRecordToList(newRecordVersion)) {
        return;
      }

      this.list = [newRecordVersion, ...this.list];
    } else {
      // Update
      this.list = this.list.map((recordVersion) =>
        recordVersion.id === newRecordVersion.id
          ? newRecordVersion
          : recordVersion,
      );
    }

    if (!onlyIfCurrent || newRecordVersion?.id === this.current?.id) {
      this.setCurrent(newRecordVersion);
    }
  }

  replaceRecordVersion(
    previousRecordVersionID: number,
    newRecordVersion: RecordVersion,
  ) {
    // TODO: LinkedDocuments special logic, until we migrate to Module-specific data streams everywhere
    const recordModuleWorkspaceID =
      newRecordVersion.record?.module_workspace_id;
    if (
      this.mainStore.linkedDocuments.moduleWorkspaceID ===
      recordModuleWorkspaceID
    ) {
      this.mainStore.linkedDocuments.replaceRecordVersion(
        previousRecordVersionID,
        newRecordVersion,
      );
      return;
    }

    // Replace RecordVersion in list if it exists, usually during unlock
    this.list = this.list.map((recordVersion) =>
      recordVersion.id === previousRecordVersionID
        ? newRecordVersion
        : recordVersion,
    );
  }

  deleteRecordVersionByID(deletedRecordVersionId: number) {
    // Delete RecordVersion in list if it exists
    this.list = this.list.filter(
      (recordVersion) => recordVersion.id !== deletedRecordVersionId,
    );
  }

  deleteByRecordID(deletedRecordID: number) {
    // Delete RecordVersion in list if it exists
    this.list = this.list.filter(
      (recordVersion) => recordVersion.record_id !== deletedRecordID,
    );
  }

  // Store Helpers
  errorsForField(recordVersionId?: number, fieldName?: string) {
    if (recordVersionId && this.cellsErrors) {
      // Errors for persisted RecordVersion
      return this.cellsErrors.find(
        (error) =>
          error.record_version_id === recordVersionId &&
          error.field_name === fieldName,
      );
    }
    // Errors for new RecordVersion (not persisted)
    return this.newRecordVersionErrors?.find(
      // @ts-expect-error TS(2339) FIXME: Property 'field_name' does not exist on type 'neve... Remove this comment to see the full error message
      (error) => error.field_name === fieldName,
    );
  }

  shouldAddNewRecordToList(newRecordVersion: RecordVersion) {
    const { tableID, subModuleTableID, themisModuleIdentifier } =
      this.mainStore.context;

    // Insert
    if (![tableID, subModuleTableID].includes(newRecordVersion.table_id)) {
      return false;
    }

    // TODO: Workaround for ActiveRecord bug
    // `after_commit :broadcast_create, on: :create` is called on old RecordVersion after replacing creative
    const otherRecordVersionIDs = this.list
      .filter(
        (recordVersion) =>
          recordVersion.record.id === newRecordVersion.record.id,
      )
      .map((recordVersion) => recordVersion.id);

    const maxRecordVersionID = Math.max(...otherRecordVersionIDs);

    if (maxRecordVersionID && newRecordVersion.id < maxRecordVersionID) {
      return false;
    }

    if (
      themisModuleIdentifier === "documents" &&
      this.mainStore.documents.folderID !== newRecordVersion.section_tag_id
    ) {
      return false;
    }

    return true;
  }

  // Helpers

  cleanup() {
    this.resetList();
    this.setCurrent(null);
    this.setCellsErrors([]);
  }
}
