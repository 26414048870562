import type { Account } from "@themis/api/gen/models/account";
import { InteractiveMedia } from "@themis/interactive-media";
import type { MediaFile } from "@themis/ui-library/components/data-display/media/types/media-file";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { Button } from "@themis/ui-library/components/inputs/button/button";
import { DetailMediaLayout } from "@themis/ui-library/components/layout/detail-media-layout/detail-media-layout";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { LinkButton } from "@themis/ui-library/components/navigation/link-button/link-button";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";

import { routes } from "../../../routes";
import { AccountsDocumentDetailComments } from "./accounts-document-detail-comments/accounts-document-detail-comments";

interface AccountDocumentContentProps {
  documentIndex: number;
  onChangeDocumentIndex: (index: number) => void;
  account: Account;
}

export function AccountDocumentContent({
  documentIndex,
  onChangeDocumentIndex,
  account,
}: AccountDocumentContentProps) {
  const { formatMessage } = useIntl();

  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const documents = account.documents ?? [];

  const document = documents[documentIndex];

  const file: MediaFile | undefined = document?.file ?? undefined;

  if (!file) {
    return null;
  }

  return (
    <DetailMediaLayout.Container>
      <AccountsDocumentDetailComments document={document} />

      <DetailMediaLayout.Media>
        {file && (
          <InteractiveMedia.Root file={file}>
            <InteractiveMedia.Header>
              <Stack direction="row" alignItems="center">
                <InteractiveMedia.DownloadButton />
              </Stack>
            </InteractiveMedia.Header>

            <InteractiveMedia.TitleBar />

            <InteractiveMedia.Viewer />

            <InteractiveMedia.Footer>
              <InteractiveMedia.UploadDate />
              <InteractiveMedia.ZoomControls />

              <Stack direction="row" alignItems="center">
                <Typography variant="body2" color="text.secondary">
                  {formatMessage(
                    {
                      defaultMessage:
                        "Reviewing {documentsPosition} of {documentsCount}",
                    },
                    {
                      documentsPosition: documentIndex + 1,
                      documentsCount: documents.length,
                    },
                  )}
                </Typography>

                {documentIndex > 0 && (
                  <Button
                    color="info"
                    onClick={() => onChangeDocumentIndex(documentIndex - 1)}
                  >
                    {formatMessage({ defaultMessage: "Back" })}
                  </Button>
                )}

                {documentIndex < documents.length - 1 && (
                  <Button
                    color="info"
                    onClick={() => onChangeDocumentIndex(documentIndex + 1)}
                  >
                    {formatMessage({ defaultMessage: "Next" })}
                  </Button>
                )}

                {documentIndex === documents.length - 1 && (
                  <LinkButton
                    color="primary"
                    to={generatePath(
                      routes.accounts.detail.documents.overview,
                      {
                        workspace_id,
                        accountId: account.id,
                      },
                    )}
                  >
                    {formatMessage({
                      defaultMessage: "Return to Attachments",
                    })}
                  </LinkButton>
                )}
              </Stack>
            </InteractiveMedia.Footer>
          </InteractiveMedia.Root>
        )}
      </DetailMediaLayout.Media>
    </DetailMediaLayout.Container>
  );
}
