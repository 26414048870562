import { faker } from "@faker-js/faker";

import type {
  LegacyWebsiteMonitoringGroupResults200,
  LegacyWebsiteMonitoringGroupResultsPathParams,
  LegacyWebsiteMonitoringGroupResultsQueryResponse,
} from "../models/LegacyWebsiteMonitoringGroupResults";
import { createMonitoringGroupAsset } from "./createMonitoringGroupAsset";

export function createLegacyWebsiteMonitoringGroupResultsPathParams(): NonNullable<LegacyWebsiteMonitoringGroupResultsPathParams> {
  return { monitoring_group_asset_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupResults200(): NonNullable<LegacyWebsiteMonitoringGroupResults200> {
  return { data: createMonitoringGroupAsset() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupResultsQueryResponse(): NonNullable<LegacyWebsiteMonitoringGroupResultsQueryResponse> {
  return { data: createMonitoringGroupAsset() };
}
