import { TableTooltip } from "@themis/ui";
import { useDetectOverflow } from "@themis/ui-library/hooks/use-detect-overflow/use-detect-overflow";
import React from "react";

import type { BaseDynamicValueProps } from "../base-dynamic-value";

export const TextValue = ({ value }: BaseDynamicValueProps<string>) => {
  const { measureElement, isOverflowing } = useDetectOverflow();
  const text = Array.isArray(value) ? value.join(", ") : value;

  return (
    <TableTooltip content={text} disabled={!isOverflowing}>
      <div
        className="tw-w-full tw-overflow-hidden tw-overflow-ellipsis tw-text-nowrap"
        ref={measureElement}
      >
        <span>{text}</span>
      </div>
    </TableTooltip>
  );
};
