import { faker } from "@faker-js/faker";

import type {
  LegacyWebsiteMonitoringGroupAssetUpdate200,
  LegacyWebsiteMonitoringGroupAssetUpdateMutationRequest,
  LegacyWebsiteMonitoringGroupAssetUpdateMutationResponse,
  LegacyWebsiteMonitoringGroupAssetUpdatePathParams,
} from "../models/LegacyWebsiteMonitoringGroupAssetUpdate";
import { createMonitoringGroupAsset } from "./createMonitoringGroupAsset";
import { createUpdateMonitoringGroupAssetRequest } from "./createUpdateMonitoringGroupAssetRequest";

export function createLegacyWebsiteMonitoringGroupAssetUpdatePathParams(): NonNullable<LegacyWebsiteMonitoringGroupAssetUpdatePathParams> {
  return { monitoring_group_asset_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupAssetUpdate200(): NonNullable<LegacyWebsiteMonitoringGroupAssetUpdate200> {
  return { data: createMonitoringGroupAsset() };
}

export function createLegacyWebsiteMonitoringGroupAssetUpdateMutationRequest(): NonNullable<LegacyWebsiteMonitoringGroupAssetUpdateMutationRequest> {
  return { data: createUpdateMonitoringGroupAssetRequest() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupAssetUpdateMutationResponse(): NonNullable<LegacyWebsiteMonitoringGroupAssetUpdateMutationResponse> {
  return { data: createMonitoringGroupAsset() };
}
