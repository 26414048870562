import { deepmerge } from "@mui/utils";

import { baseTheme } from "./base-theme/base-theme";

export const darkTheme = deepmerge(baseTheme, {
  colorSchemes: "dark",
  palette: {
    ...baseTheme.palette,
    mode: "dark",
  },
});
