import { http } from "msw";

import { createCreateAttestationMutationResponse } from "../createCreateAttestation";

export const createAttestationHandler = http.post(
  "*/companies/:company_id/attestations",
  function handler(info) {
    return new Response(
      JSON.stringify(createCreateAttestationMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
