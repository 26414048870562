import type { CustomCellEditorProps } from "ag-grid-react";
import { type ClassValue, clsx } from "clsx";
import omit from "lodash/omit";
import { extendTailwindMerge } from "tailwind-merge";

// tailwind-merge was not working correctly because we did not include the prefix.
const twMerge = extendTailwindMerge({
  prefix: "tw-",
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getInitials(name: string) {
  return name
    .split(" ")
    .map((n) => n[0])
    .join("");
}

export function filterCellProps<T extends CustomCellEditorProps>(props: T) {
  // List of props to omit
  const omittedProps = [
    "api",
    "columnApi",
    "context",
    "node",
    "column",
    "rowIndex",
    "cellStartedEdit",
    "charPress",
    "eventKey",
    "valueFormatted",
    "getValue",
    "setValue",
    "formatValue",
    "initialValue",
    "parseValue",
    "refreshCell",
    "colDef",
    "eGridCell",
    "eParentOfValue",
    "registerRowDragger",
    "setTooltip",
    "stopEditing",
    "onValueChange",
    "selected",
    "onSelect",
    "useFormatter",
    "initialValue",
  ] as const;

  return omit(props, omittedProps);
}

export function handleDownload(url: string, fileName: string) {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const localUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = localUrl;
      link.download = fileName || "downloaded-file";
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
}
