import { observer } from "mobx-react";
import { useEffect } from "react";
import { useIntl } from "react-intl";

import { useMainStore } from "../../../contexts/Store";
import lightbulb from "../../../images/table-image/icon/lightbulb-icon.svg";
import ListSettings from "../settings/ListSettings";
import ListTitleSettings from "../settings/ListTitleSettings";
import GlobalIdColumn from "../shared/GlobalIdColumn";
import Table from "../Table";

const getHeaders = (nameTitle = "") => [
  {
    field_name: "attestation_status",
    title: "Attestation Status",
    disabled: false,
  },
  {
    field_name: "meta.name",
    title: nameTitle,
    icon: null,
    disabled: true,
  },
  {
    field_name: "record_version",
    title: "Version",
    icon: null,
    disabled: true,
  },
  {
    field_name: "attestation_completion",
    title: "Completion %",
    icon: null,
    disabled: true,
  },
  {
    field_name: "initial_attestation_date",
    title: "Initial Attestation Date",
    icon: null,
    disabled: true,
  },
];

interface Props {
  group?: string;
  title?: string;
}

function AttestationsList({ group, title = "" }: Props) {
  const { formatMessage } = useIntl();

  const {
    attestations,
    context: { moduleWorkspaceID },
    fields: { fieldsMap },
  } = useMainStore();

  const load = () => {
    if (group && moduleWorkspaceID) {
      attestations.index(moduleWorkspaceID, group);
    }
  };

  useEffect(() => {
    load();
  }, [group, moduleWorkspaceID]);

  const headers = getHeaders(fieldsMap.name?.display_name);

  const renderHeaders = (
    <>
      <GlobalIdColumn />
      {headers.map((header) => (
        <ListTitleSettings
          key={header.field_name}
          title={header.title}
          fieldName={header.field_name}
        />
      ))}
    </>
  );

  const renderRows = attestations.list.map((item) => (
    <ListSettings
      // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
      key={item.id}
      reload={load}
      headers={headers}
      data={item}
      model="Attestation"
      // @ts-expect-error TS(2339) FIXME: Property 'global_id' does not exist on type 'never... Remove this comment to see the full error message
      globalID={item.global_id}
    />
  ));

  return (
    <>
      {renderRows.length > 0 && (
        <Table>
          <div className="settings-wrap company-users-settings-container">
            <div className="settings-content-wrap attestation-content-wrap">
              <div className="list-title-table">
                <ul>
                  <div className="list-column-wrap">
                    <div className="list-title-table-wrap">
                      {renderHeaders}
                      <span className="stretch-cell" />
                    </div>
                  </div>
                  <div className="list-title-table-block" />
                </ul>
              </div>
              {renderRows}
            </div>
          </div>
        </Table>
      )}

      {renderRows.length === 0 && (
        <div className="review-attested-policies">
          <h4>
            <img src={lightbulb} alt="lightbulb-icon" />
            {formatMessage(
              { defaultMessage: "Review your attested {title}" },
              {
                title: title.toLowerCase(),
              },
            )}
          </h4>
          <p>
            {formatMessage(
              {
                defaultMessage: `Attested {title} are stored here. These {title} can be shared with partners and attested by employees. Use this feature by selecting the "Send Email/Attestation" option in the "Completed" tab.`,
              },
              {
                title: title.toLowerCase(),
              },
            )}
          </p>
        </div>
      )}
    </>
  );
}

export default observer(AttestationsList);
