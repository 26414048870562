import { UserLabel } from "@themis/ui-library/components/data-display/user-label/user-label";
import type { CustomCellEditorProps } from "ag-grid-react";
import { isArray, keyBy } from "lodash";
import { useMemo } from "react";

import { filterCellProps } from "../../../lib/utils";
import { Select } from "../../Form/select/Select";
import type { SelectProps } from "../../Form/select/Select";
import { MiniTag } from "../../MiniTag/MiniTag";

export interface User {
  id: string;
  initials: string;
  full_name: string;
  email: string;
  icon_color_index: number;
  status: string;
}

type Props = SelectProps &
  CustomCellEditorProps & {
    users: User[];
  };

const UsersCell = ({
  users,
  value,
  onValueChange,
  defaultOpen,
  multiple,
  ...props
}: Props) => {
  const { selected, onSelect, ...restProps } = props;
  const filteredProps = filterCellProps({ value, onValueChange, ...restProps });
  const editable = Boolean(props.colDef?.editable);

  const usersMap = useMemo(() => keyBy(users, "id"), [users]);

  const userOptions = users
    ? users.map((user) => ({
        label: user.full_name,
        value: user.id.toString(),
      }))
    : [];

  const selectedValues = isArray(value) ? value.map(String) : value?.toString();

  return (
    <Select
      className="tw-h-full tw-rounded-none tw-border-none tw-bg-transparent read-only:tw-bg-transparent group-hover/row:tw-bg-neutral-50"
      selected={selectedValues}
      items={userOptions}
      onSelect={onValueChange}
      multiple={multiple}
      defaultOpen={!!(defaultOpen && editable)}
      onPopupClosed={() => props.stopEditing && props.stopEditing()}
      hideCaret
      readOnly={!editable}
      placeholder={editable ? "- Select User -" : ""}
      renderOption={(option) => {
        const user = usersMap[option.value];

        if (!user?.full_name) {
          return null;
        }

        return (
          <UserLabel
            initials={user.initials}
            colorSeed={user.icon_color_index}
            fullName={user.full_name}
          />
        );
      }}
      renderSelected={
        multiple
          ? ({ value: userId }) => {
              const user = userId ? usersMap[userId] : null;

              if (!user) {
                return null;
              }

              return (
                <div className="tw-mr-1" key={userId}>
                  <MiniTag colorIndex={user.icon_color_index}>
                    {user.initials}
                  </MiniTag>
                </div>
              );
            }
          : undefined
      }
      {...filteredProps}
    />
  );
};

export default UsersCell;
