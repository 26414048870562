import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import type { ResponseConfig } from "@/api/client";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import type {
  Comment,
  UpdateCommentMutationRequest,
  UpdateCommentMutationResponse,
  UpdateCommentPathParams,
} from "../../gen";
import { updateComment } from "../../gen/axios/commentsController";
import { getRecordsQueryKey } from "../records/get-records-query-key";
import { getCommentsQueryKey } from "./use-comments";

interface UseUpdateCommentParams {
  recordType: UpdateCommentPathParams["record_type"];
  recordId: UpdateCommentPathParams["record_id"];
}

interface MutationFnParams {
  commentId: UpdateCommentPathParams["id"];
  data: UpdateCommentMutationRequest;
}

export function useUpdateComment<TError = Error>(
  { recordType, recordId }: UseUpdateCommentParams,
  {
    onSuccess,
    ...options
  }: Partial<
    UseMutationOptions<
      ResponseConfig<UpdateCommentMutationResponse>["data"],
      TError,
      MutationFnParams,
      { previousComments: Comment[] | undefined }
    >
  > = {},
) {
  const queryClient = useQueryClient();
  const workspaceId = useRouteWorkspaceId();

  return useMutation({
    ...options,
    throwOnError: true,
    mutationFn: ({ commentId, data }) =>
      updateComment(recordType, recordId, commentId, data),
    onMutate: async ({ commentId, data }) => {
      const commentsQueryKey = getCommentsQueryKey({ recordType, recordId });

      await queryClient.cancelQueries({
        queryKey: commentsQueryKey,
      });

      const previousComments: Comment[] | undefined =
        queryClient.getQueryData(commentsQueryKey);

      queryClient.setQueryData(commentsQueryKey, (oldData: Comment[]) =>
        oldData.map((comment) =>
          comment.id === commentId ? { ...comment, ...data.comment } : comment,
        ),
      );

      return { previousComments };
    },
    onError: (_error, _variables, context) => {
      queryClient.setQueryData(
        getCommentsQueryKey({ recordType, recordId }),
        context?.previousComments,
      );
    },
    onSuccess: (...args) => {
      if (recordType !== "documents") {
        queryClient.invalidateQueries({
          queryKey: getRecordsQueryKey({ recordType, workspaceId }),
        });
      }
      queryClient.invalidateQueries({
        queryKey: getCommentsQueryKey({ recordType, recordId }),
      });
      onSuccess?.(...args);
    },
  });
}
