export const recordTypePath = {
  projects: "projects",
  findings: "findings",
  tasks: "tasks",
  module_assessments: "module_assessments",
  accounts: "accounts",
  trainings: "trainings",
  records: "records",
  attestations: "attestations",
  monitoring_groups: "monitoring_groups",
  monitoring_assets: "monitoring_assets",
  monitoring_findings: "monitoring_findings",
  documents: "documents",
} as const;

export type RecordTypePath =
  (typeof recordTypePath)[keyof typeof recordTypePath];
