import kebabCase from "lodash/kebabCase";
import { observer } from "mobx-react";
import React, { type Dispatch, type SetStateAction } from "react";
import { CircularProgressbar } from "react-circular-progressbar";

import iconMap from "@/components/helpers/moduleIcons";
import { AccountsDependentModuleIdentifiers } from "@/constants/accounts-dependent-module-identifiers";
import { useMainStore } from "@/contexts/Store";
import {
  type ModuleIdentifier,
  TopLevelModule,
} from "@/stores/types/module-workspaces-types";

import DefaultIcon from "../../../images/setting-workspace/icon/Default Icon - V1.svg";
import { nameFromModuleWorkspace } from "../../helpers/nameForThemisModuleIdentifier";

interface Props {
  selectedModules: ModuleIdentifier[];
  setSelectedModules: Dispatch<SetStateAction<ModuleIdentifier[]>>;
  setWorkspaceStep: Dispatch<SetStateAction<number>>;
}

const SelectCollaborativeWorkspaceModules = ({
  selectedModules,
  setSelectedModules,
  setWorkspaceStep,
}: Props) => {
  const mainStore = useMainStore();
  const moduleWorkspaces = mainStore.moduleWorkspaces.list;

  const moduleIdentifierOptions: ModuleIdentifier[] = [
    "policy",
    "procedures",
    "documents",
    "accounts",
    "marketing",
    "audits",
    "vendor_due_diligence",
    "complaints",
    "issue_management",
    "qa_tests_development",
    "new_product_approval",
    "risk_register",
    "training",
    "key_risk_indicators",
    "risk_assessment",
  ];

  function onModulesChange(event: React.ChangeEvent<HTMLInputElement>) {
    const identifier = event.target.name as ModuleIdentifier;

    if (event.target.checked) {
      if (
        AccountsDependentModuleIdentifiers.includes(identifier) &&
        !selectedModules.includes(TopLevelModule.ACCOUNTS)
      ) {
        setSelectedModules((prev) => [
          ...prev,
          identifier,
          TopLevelModule.ACCOUNTS,
        ]);
      } else {
        setSelectedModules((prev) => [...prev, identifier]);
      }
    } else {
      const identifiersToRemove =
        identifier === TopLevelModule.ACCOUNTS
          ? [...AccountsDependentModuleIdentifiers, identifier]
          : [identifier];
      setSelectedModules((prev) =>
        prev.filter((item) => !identifiersToRemove.includes(item)),
      );
    }
  }

  const renderModuleRows = moduleIdentifierOptions.map((moduleIdentifier) => (
    <div
      className="internal-modules-block"
      key={moduleIdentifier}
      data-testid={`${kebabCase(moduleIdentifier)}-block`}
    >
      <img className="w-48" src={iconMap[moduleIdentifier]} alt={DefaultIcon} />
      <h4>{nameFromModuleWorkspace(moduleIdentifier, moduleWorkspaces)}</h4>
      <div className="toggle-checkbox">
        <label className="switch">
          <input
            type="checkbox"
            name={moduleIdentifier}
            checked={selectedModules?.includes(moduleIdentifier)}
            onChange={onModulesChange}
            data-testid={`${kebabCase(moduleIdentifier)}-checkbox`}
          />
          <span className="slider" />
        </label>
      </div>
    </div>
  ));

  return (
    <>
      <div className="setting-workspace-progressbar">
        <div className="progressbar">
          <CircularProgressbar value={50} strokeWidth={15} />
        </div>
        <p>Select Internal Modules</p>
      </div>
      <p className="setup-workspace-text">
        Add any of the modules below to your collaborative
        <br /> workspace
      </p>
      <div className="internal-modules-wrap">{renderModuleRows}</div>

      <div className="workspace-buttons">
        <button
          className="back"
          onClick={() => setWorkspaceStep(2)}
          data-testid="back-button"
        >
          Back
        </button>
        <button
          className="active"
          onClick={() => setWorkspaceStep(4)}
          data-testid="next-button"
        >
          Continue
        </button>
      </div>
    </>
  );
};

export default observer(SelectCollaborativeWorkspaceModules);
