import React from "react";
import { Route, Switch } from "react-router-dom";

import { AccountDocumentDetailPage } from "./pages/account-document-detail-page/account-document-detail-page";
import { AccountDetails } from "./pages/AccountDetails";
import { Accounts } from "./pages/Accounts";
import { AccountsLinkedRecords } from "./pages/accounts-linked-records/accounts-linked-records";
import { AccountsReferencedIn } from "./pages/accounts-referenced-in/accounts-referenced-in";
import { AllContracts } from "./pages/AllContracts";
import { ContactDetails } from "./pages/ContactDetails";
import { ContractDetails } from "./pages/ContractDetails";
import { Documents } from "./pages/documents/documents";
import { NewChildAccountDetails } from "./pages/NewChildAccountDetails";
import { routes } from "./routes";

export function AccountsRoutes() {
  return (
    <Switch>
      <Route exact path={routes.accounts.contracts}>
        <AllContracts />
      </Route>

      <Route exact path={routes.accounts.detail.contracts.detail}>
        <ContractDetails />
      </Route>

      <Route exact path={routes.accounts.detail.contacts.detail}>
        <ContactDetails />
      </Route>

      <Route path={routes.accounts.detail.subAccounts.addNew}>
        <NewChildAccountDetails />
      </Route>

      <Route exact path={routes.accounts.detail.documents.overview}>
        <Documents />
      </Route>

      <Route exact path={routes.accounts.detail.documents.detail}>
        <AccountDocumentDetailPage />
      </Route>

      <Route path={routes.accounts.detail.outboundLinks}>
        <AccountsLinkedRecords />
      </Route>

      <Route path={routes.accounts.detail.inboundLinks}>
        <AccountsReferencedIn />
      </Route>

      <Route path={routes.accounts.detail.recordView}>
        <AccountDetails />
      </Route>

      <Route exact path={routes.accounts.overview}>
        <Accounts />
      </Route>
    </Switch>
  );
}
