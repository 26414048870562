import { Network } from "@phosphor-icons/react";
import { SvgIcon } from "@themis/ui-library/components/data-display/svg-icon/svg-icon";
import { UserLabel } from "@themis/ui-library/components/data-display/user-label/user-label";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";

import type { AssigneeOption as AssigneeOptionType } from "../../types";

interface AssigneeOptionProps {
  option: AssigneeOptionType;
}

export const AssigneeOption = ({ option }: AssigneeOptionProps) => {
  if (option.type === "user") {
    return (
      <UserLabel
        initials={option.record.initials}
        colorSeed={option.record.icon_color_index}
        fullName={option.record.full_name ?? ""}
      />
    );
  }
  if (option.type === "contact") {
    return (
      <UserLabel
        initials={option.record.initials}
        fullName={option.record.full_name ?? ""}
      />
    );
  }
  if (option.type === "department") {
    return (
      <Stack direction="row" spacing={0.5} alignItems="center">
        <SvgIcon
          component={Network}
          color="neutral"
          fontSize="small"
          inheritViewBox
        />
        <span>{option.label}</span>
      </Stack>
    );
  }
  return null;
};
