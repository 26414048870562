import { styled } from "../../../../styles";
import { Stack, type StackProps } from "../../../layout/stack/stack";

const StyledStack = styled(Stack)(({ theme }) => ({
  borderColor: theme.palette.divider,
  borderStyle: "solid",
  borderWidth: "1px 0",
  minHeight: theme.height.toolbar,
  alignItems: "center",
  padding: theme.spacing(1),
}));

export function PageToolbar({ children, ...rest }: StackProps) {
  return (
    <StyledStack direction="row" {...rest}>
      {children}
    </StyledStack>
  );
}
