import * as TogglePrimitive from "@radix-ui/react-toggle";
import { cva, type VariantProps } from "cva";
import * as React from "react";

import { cn } from "../../lib/utils";

const defaultStyles =
  "tw-inline-flex tw-border-none tw-items-center tw-justify-center tw-gap-1 tw-whitespace-nowrap tw-rounded-md tw-text-sm tw-font-semibold tw-transition-colors";
const activeStyles = "active:tw-bg-neutral-500 active:tw-text-neutral-25";
const disabledStyles =
  "disabled:tw-bg-primaryDim-50 disabled:tw-text-neutral-300 disabled:tw-pointer-events-none";
const focusStyles =
  "focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-ring-ring";
const defaultHoverStyles = "hover:tw-bg-primary-100";
const transparentHoverStyles = "hover:tw-bg-primary-25";
const defaultColorStyles =
  "tw-bg-primary-50 hover:tw-bg-primary-100 tw-text-neutral-500";
const transparentColorStyles = "tw-bg-transparent tw-text-neutral-500";
const defaultStateStyles =
  "data-[state=on]:tw-bg-secondary-300 data-[state=on]:hover:tw-bg-secondary-500 data-[state=on]:tw-text-white";
const transparentStateStyles =
  "data-[state=on]:tw-bg-neutral-500 data-[state=on]:tw-text-neutral-25";

const toggleButtonVariants = cva({
  base: [defaultStyles, activeStyles, disabledStyles, focusStyles],
  variants: {
    size: {
      sm: "tw-h-6 tw-min-w-6",
      md: "tw-h-7 tw-min-w-7",
      lg: "tw-h-8 tw-min-w-8",
    },
    variant: {
      default: [defaultColorStyles, defaultStateStyles, defaultHoverStyles],
      transparent: [
        transparentColorStyles,
        transparentStateStyles,
        transparentHoverStyles,
      ],
    },
  },
  defaultVariants: {
    size: "md",
    variant: "default",
  },
});

const ToggleButton = React.forwardRef<
  React.ElementRef<typeof TogglePrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TogglePrimitive.Root> &
    VariantProps<typeof toggleButtonVariants>
>(({ className, size, variant, ...props }, ref) => (
  <TogglePrimitive.Root
    ref={ref}
    className={cn(toggleButtonVariants({ size, className, variant }))}
    {...props}
  />
));

ToggleButton.displayName = "ToggleButton";

export { ToggleButton, toggleButtonVariants };
