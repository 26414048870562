import type { AutocompleteRenderInputParams } from "@themis/ui-library/components/inputs/autocomplete/autocomplete";
import { Autocomplete } from "@themis/ui-library/components/inputs/autocomplete/autocomplete";
import { TextField } from "@themis/ui-library/components/inputs/text-field/text-field";
import { useState } from "react";
import { useIntl } from "react-intl";

import { validateEmail } from "@/components/helpers/EmailValidator";

import type { AssigneeOption as AssigneeOptionType } from "../types";
import { AssigneeOption } from "./assignee-option/assignee-option";

interface AssigneeSearchProps {
  onChange: (item: AssigneeOptionType | string) => void;
  options: AssigneeOptionType[];
}

export function AssigneeSearch({ onChange, options }: AssigneeSearchProps) {
  const { formatMessage } = useIntl();
  const [emailError, setEmailError] = useState(false);

  const handleKeyDown = (
    event: React.KeyboardEvent & {
      defaultMuiPrevented?: boolean;
    },
  ) => {
    if (event.key === "Enter") {
      // We don't want Mui's default behavior, instead we want to add the email to the list of assignees
      event.defaultMuiPrevented = true;
      event.preventDefault();
      const email = (event.target as HTMLInputElement).value;
      if (validateEmail(email)) {
        onChange(email);
      } else {
        setEmailError(true);
      }
    }
  };

  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => `${option.value}`}
      renderOption={(props, option) => (
        <li {...props} key={`${option.type}-${option.value}`}>
          <AssigneeOption option={option} />
        </li>
      )}
      onChange={(_, value) => onChange(value)}
      disableClearable
      forcePopupIcon={false}
      onKeyDown={handleKeyDown}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          sx={{
            width: "24rem",
          }}
          error={emailError}
          placeholder={formatMessage({
            defaultMessage: "Add Assignee / Department / Contact / Email",
          })}
        />
      )}
    />
  );
}
