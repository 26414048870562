import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  GetPublicFormLayoutPathParams,
  GetPublicFormLayoutQueryResponse,
} from "../../models/GetPublicFormLayout";

/**
 * @description Fetch public form layout
 * @summary Fetch public form layout
 * @link /module_workspaces/:module_workspace_id/public_form_layout
 */
export async function getPublicFormLayout(
  moduleWorkspaceId: GetPublicFormLayoutPathParams["module_workspace_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<GetPublicFormLayoutQueryResponse>["data"]> {
  const res = await client<GetPublicFormLayoutQueryResponse>({
    method: "get",
    url: `/module_workspaces/${moduleWorkspaceId}/public_form_layout`,
    ...options,
  });
  return res.data;
}
