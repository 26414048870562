import { observer } from "mobx-react";
import React, { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

function IdleTimer() {
  const mainStore = useMainStore();
  const [showIdle, setShowIdle] = useState(false);
  const { sessionTiming } = mainStore.webSessions;

  // Fired before the timeout expires if promptTimeout is set
  const onPrompt = () => {
    setShowIdle(true);
  };

  // Fired after timeout expires if promptTimeout is not set or if no activity occurs during promptTimeout
  const onIdle = () => {
    mainStore.webSessions.delete();
  };

  // Fired when state changes from "idle" to "active"
  const onActive = () => {
    setShowIdle(false);
  };

  // Fired on any user activity (mouse, keyboard, switched back to tab with website)
  const onAction = () => {
    setShowIdle(false);
    activate();
  };

  const { activate } = useIdleTimer({
    onPrompt,
    onIdle,
    onActive,
    onAction,
    timeout: sessionTiming.expirationTimeout,
    promptBeforeIdle:
      sessionTiming.expirationTimeout - sessionTiming.promptDelay,
  });

  return (
    <div>
      {showIdle && (
        <Popup position="center center" open={showIdle} keepTooltipInside>
          <div className="idle-dropdown">
            <p className="idle-dropdown-block">
              You have not interacted with this page in a while. You will be
              logged out if the session stays idle.
            </p>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default observer(IdleTimer);
