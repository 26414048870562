import type { Dispatch, SetStateAction } from "react";
import { useCallback } from "react";
import { useToggle } from "usehooks-ts";

interface OpenState {
  open: () => void;
  close: () => void;
  toggle: () => void;
  onIsOpenChange: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

export const useOpen = (defaultOpen = false): OpenState => {
  const [isOpen, toggle, setIsOpen] = useToggle(defaultOpen);

  const open = useCallback(() => setIsOpen(true), [setIsOpen]);

  const close = useCallback(() => setIsOpen(false), [setIsOpen]);

  return { close, isOpen, open, toggle, onIsOpenChange: setIsOpen };
};
