import { Command as CommandPrimitive } from "cmdk";
import * as React from "react";

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className="tw-py-6 tw-text-center tw-font-sans tw-text-sm tw-text-neutral-400"
    {...props}
  />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

export { CommandEmpty };
