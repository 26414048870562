import type { ReviewReviewType } from "@themis/api/gen/models/review";
import { defineMessages } from "react-intl";

export const reviewStatusLabelMessages = defineMessages<ReviewReviewType>({
  approval: {
    defaultMessage: "Resolved",
  },
  denial: {
    defaultMessage: "Denial",
  },
  pending: {
    defaultMessage: "Pending",
  },
  request_change: {
    defaultMessage: "Request Change",
  },
  review_change: {
    defaultMessage: "Review Change",
  },
  obsolete: {
    defaultMessage: "Obsolete",
  },
  pending_action: {
    defaultMessage: "Pending Action",
  },
  completed_action: {
    defaultMessage: "Completed Action",
  },
});
