import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import { useClientDataGridBinding } from "@themis/ui-library/components/data-grid/binding/use-client-data-grid-binding";
import { DataGrid } from "@themis/ui-library/components/data-grid/data-grid";

import { useAuditTrail } from "@/api/queries/audits/use-audit-trail";

import { useActivityTableColumns } from "./use-activity-table-columns/use-activity-table-columns";

interface ActivityTableProps {
  recordId: number;
  recordType: RecordTypePath;
}

export function ActivityTable({ recordId, recordType }: ActivityTableProps) {
  const { data: audit } = useAuditTrail(recordType, Number(recordId));
  const columns = useActivityTableColumns();
  const { clientGridProps } = useClientDataGridBinding(audit, {});

  return <DataGrid columns={columns} {...clientGridProps} />;
}
