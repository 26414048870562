export type SortDirection = "asc" | "desc";

export enum FilterOperand {
  ANY = "any",
  NOT = "not",
  MATCH = "match",
  PRESENT = "present",
  MISSING = "missing",
}

export type Filter =
  | {
      operand: FilterOperand.ANY | FilterOperand.NOT | FilterOperand.MATCH;
      value: string[];
    }
  | {
      operand: FilterOperand.PRESENT | FilterOperand.MISSING;
      value?: never;
    };

export type QueryFilter = Partial<Record<FilterOperand, string>>;

export type Filters = Record<string, Filter>;
export type Sorts = Record<string, SortDirection>;

export interface DataGridState {
  filters: Filters | undefined;
  pageIndex: number | undefined;
  pageSize: number | undefined;
  sorts: Sorts | undefined;
}
