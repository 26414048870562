import { ConfirmationPopup, useToast } from "@themis/ui";
import { capitalize } from "lodash";
import { defineMessages, useIntl } from "react-intl";

import type { RecordTypePath } from "@/api";

import { useCompany } from "../../../api/queries/companies";
import { useDeleteRecords } from "../../../api/queries/records/use-delete-records";

interface DeleteRecordsPopoverProps {
  recordType: RecordTypePath;
  ids: number[];
  open: boolean;
  onClose: () => void;
  onDelete?: () => void;
}

export function DeleteRecordsPopover({
  recordType,
  ids,
  open,
  onClose,
  onDelete,
}: DeleteRecordsPopoverProps) {
  const { formatMessage } = useIntl();

  const { data: queryData } = useCompany("current");
  const companyId = queryData?.data.company.id;

  const toast = useToast();

  const pluralizedRecordsText = formatMessage(recordTypeMessages[recordType], {
    count: ids.length,
  });

  const { mutate } = useDeleteRecords({
    recordType,
    companyId: companyId ?? -1,
  });

  function handleConfirmDelete() {
    if (ids.length === 0 || !companyId) {
      return;
    }

    mutate(
      { ids },
      {
        onSuccess: () => {
          toast({
            content: capitalize(
              formatMessage(
                {
                  defaultMessage:
                    "{records} {count, plural, one {has} other {have}} been removed!",
                },
                {
                  records: pluralizedRecordsText,
                  count: ids.length,
                },
              ),
            ),
            variant: "success",
          });

          onDelete?.();
        },
        onError: () => {
          toast({
            content: formatMessage(
              { defaultMessage: "Failed to delete {record}!" },
              { record: pluralizedRecordsText },
            ),
            variant: "error",
          });
        },
      },
    );

    onClose();
  }

  return (
    <ConfirmationPopup
      anchor
      align="end"
      title={formatMessage(
        { defaultMessage: "Delete {records}" },
        { records: pluralizedRecordsText },
      )}
      content={formatMessage(
        {
          defaultMessage:
            "Are you sure you want to delete the selected {records}? Actions are not reversible.",
        },
        { records: pluralizedRecordsText },
      )}
      open={open}
      onCancel={onClose}
      onConfirm={handleConfirmDelete}
    />
  );
}

const recordTypeMessages = defineMessages<RecordTypePath>({
  projects: {
    defaultMessage: "{count, plural, one {project} other {projects}}",
  },
  documents: {
    defaultMessage: "{count, plural, one {document} other {documents}}",
  },
  tasks: {
    defaultMessage: "{count, plural, one {task} other {tasks}}",
  },
  findings: {
    defaultMessage: "{count, plural, one {finding} other {findings}}",
  },
  module_assessments: {
    defaultMessage: "{count, plural, one {assessment} other {assessments}}",
  },
  accounts: {
    defaultMessage: "{count, plural, one {account} other {accounts}}",
  },
  trainings: {
    defaultMessage: "{count, plural, one {training} other {trainings}}",
  },
  records: {
    defaultMessage: "{count, plural, one {record} other {records}}",
  },
  attestations: {
    defaultMessage: "{count, plural, one {attestation} other {attestations}}",
  },
  monitoring_groups: {
    defaultMessage:
      "{count, plural, one {monitoring group} other {monitoring groups}}",
  },
  monitoring_assets: {
    defaultMessage:
      "{count, plural, one {monitoring asset} other {monitoring assets}}",
  },
  monitoring_findings: {
    defaultMessage:
      "{count, plural, one {monitoring finding} other {monitoring findings}}",
  },
});
