export const fieldFormat = {
  accounting: "accounting",
  financial: "financial",
  number: "number",
  percentage: "percentage",
} as const;

export type FieldFormat = (typeof fieldFormat)[keyof typeof fieldFormat];

export type Field = {
  /**
   * @description Identifier of computed column
   * @type string
   */
  computed_column_identifier: string | null;
  /**
   * @description Field name
   * @type string
   */
  name: string;
  /**
   * @description Field data type
   * @type string
   */
  data_type: string;
  /**
   * @description Display name
   * @type string
   */
  display_name: string;
  /**
   * @description Format for fields of type \"com.askthemis.types.v1.number\"
   * @type string
   */
  format: FieldFormat | null;
  /**
   * @description Indicates that field is computed
   * @type boolean
   */
  is_computed_column: boolean;
  /**
   * @description Indicates that field is custom
   * @type boolean
   */
  is_custom_field: boolean;
  /**
   * @description Indicates that field is default
   * @type boolean
   */
  is_default_field: boolean;
  /**
   * @description Works on \".attachment\" data type fields; allows creation of file versions
   * @type boolean | undefined
   */
  is_file_versioning_enabled?: boolean;
  /**
   * @description Indicates that field is hidden
   * @type boolean
   */
  is_hidden: boolean;
  /**
   * @description Indicates that field allows multiple values
   * @type boolean
   */
  is_multiselect: boolean;
  /**
   * @description Indicates that field is required
   * @type boolean
   */
  is_required: boolean;
  /**
   * @description Indicates that field is editable by user
   * @type boolean
   */
  is_user_editable: boolean;
  /**
   * @description Is this field visible only to internal Users?
   * @type boolean
   */
  is_visible_to_internal_users_only: boolean;
  /**
   * @description The field\'s position relative to other fields
   * @type number
   */
  position: number;
  /**
   * @description Validation rules
   * @type object
   */
  validation_rules: {
    /**
     * @type object | undefined
     */
    exists?: object;
    /**
     * @type object | undefined
     */
    greater_than_or_equal_to?: {
      /**
       * @type object
       */
      options: {
        /**
         * @type string
         */
        other_field_name: string;
      };
    };
    /**
     * @type object | undefined
     */
    less_than_or_equal_to?: {
      /**
       * @type object
       */
      options: {
        /**
         * @type string
         */
        other_field_name: string;
      };
    };
    /**
     * @type object | undefined
     */
    present?: object;
    /**
     * @type object | undefined
     */
    approvers?: object;
    /**
     * @type object | undefined
     */
    validators?: object;
    /**
     * @type object | undefined
     */
    date_is_not_in_the_past?: {
      /**
       * @type object
       */
      options: {
        /**
         * @type string | undefined
         */
        custom_message?: string;
      };
    };
    /**
     * @type object | undefined
     */
    date_is_not_in_the_future?: {
      /**
       * @type object
       */
      options: {
        /**
         * @type string | undefined
         */
        custom_message?: string;
      };
    };
    /**
     * @type object | undefined
     */
    length?: {
      /**
       * @type object
       */
      options: {
        /**
         * @type integer | undefined
         */
        maximum?: number;
        /**
         * @type integer | undefined
         */
        minimum?: number;
      };
    };
    /**
     * @type object | undefined
     */
    one_choice?: {
      /**
       * @type object
       */
      options: {
        /**
         * @type string
         */
        other_field_name: string;
      };
    };
    /**
     * @type object | undefined
     */
    unique_finra_submission?: object;
    /**
     * @type object | undefined
     */
    attachment?: object;
    /**
     * @type object | undefined
     */
    checklist?: object;
  };
  /**
   * @description Width of the field\'s column in pixels
   * @type integer
   */
  width: number;
};
