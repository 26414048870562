import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { useClientDataGridBinding } from "@themis/ui-library/components/data-grid/binding/use-client-data-grid-binding";
import { DataGrid } from "@themis/ui-library/components/data-grid/data-grid";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { EmptyStateCard } from "@themis/ui-library/components/surfaces/card/empty-state-card/empty-state-card";
import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { useDepartments } from "@/api/queries/departments/use-departments";
import { useCompanyUsers } from "@/api/queries/users/use-company-users";
import { useCompanyId } from "@/hooks/use-company-id";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import { AssigneeSearch } from "./assignee-search/assignee-search";
import { getAssigneeFromOption } from "./get-assignee-from-option/get-assignee-from-option";
import type {
  Assignee,
  AssigneeOption,
  DepartmentAssigneeOption,
  UserAssigneeOption,
} from "./types";
import { useAssigneesColumns } from "./use-assignees-columns/use-assignees-columns";

export function AssigneesSection() {
  const workspaceId = useRouteWorkspaceId();
  const { companyId } = useCompanyId();
  const { formatMessage } = useIntl();
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const { data: departments } = useDepartments(companyId);
  const [assignees, setAssignees] = useState<Assignee[]>([]);
  const { clientGridProps } = useClientDataGridBinding(assignees);
  const { data: users } = useCompanyUsers({
    companyId,
    workspace_id: workspaceId,
    view: "active",
  });

  const options: AssigneeOption[] = useMemo(
    () => [
      ...(departments || [])
        .filter(({ id }) => users?.some((user) => user?.department?.id === id))
        .map(
          (department): DepartmentAssigneeOption => ({
            value: department.id,
            label: department.title,
            type: "department",
          }),
        ),
      ...(users || []).map(
        (user): UserAssigneeOption => ({
          value: user.id,
          label: user.full_name ?? "",
          type: "user",
          record: user,
        }),
      ),
      // TODO: @MaxCloutier add contacts once BE is ready
    ],
    [departments, users],
  );

  const handleRemove = useCallback((id: number) => {
    setAssignees((prevAssignees) =>
      prevAssignees.filter((assignee) => assignee.id !== id),
    );
  }, []);

  const handleResend = useCallback((id: number) => {
    // TODO: @MaxCloutier implement resend logic once BE is ready
  }, []);

  const columns = useAssigneesColumns({
    onRemove: handleRemove,
    onResend: handleResend,
    canResend: false,
  });

  const handleAddAssignee = (option: AssigneeOption | string) => {
    setAssignees((prevAssignees) => [
      ...prevAssignees,
      ...getAssigneeFromOption(option, users || []),
    ]);
  };

  return (
    <Stack spacing={1}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">
          {formatMessage({ defaultMessage: "Assignees" })}
        </Typography>
      </Stack>

      {assignees.length ? (
        // TODO: @MaxCloutier handle add more assignees
        <DataGrid
          columns={columns}
          {...clientGridProps}
          selection="multiple"
          onSelectionChanged={(rows) =>
            setSelectedRowIds(rows.map((row) => row.id))
          }
        />
      ) : (
        <EmptyStateCard
          title={formatMessage({ defaultMessage: "Add your first Assignee" })}
          description={formatMessage({
            defaultMessage:
              "To get started, add your first Assignee by clicking “Add Assignee” field or “Import Assignee” on top right.",
          })}
        >
          <AssigneeSearch options={options} onChange={handleAddAssignee} />
        </EmptyStateCard>
      )}
    </Stack>
  );
}
