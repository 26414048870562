import { faker } from "@faker-js/faker";

import type {
  ShowMonitoringGroup200,
  ShowMonitoringGroupPathParams,
  ShowMonitoringGroupQueryParams,
  ShowMonitoringGroupQueryResponse,
} from "../models/ShowMonitoringGroup";
import { createMonitoringGroup } from "./createMonitoringGroup";

export function createShowMonitoringGroupPathParams(): NonNullable<ShowMonitoringGroupPathParams> {
  return { id: faker.number.int() };
}

export function createShowMonitoringGroupQueryParams(): NonNullable<ShowMonitoringGroupQueryParams> {
  return { expand: faker.string.alpha() };
}

/**
 * @description OK
 */
export function createShowMonitoringGroup200(): NonNullable<ShowMonitoringGroup200> {
  return { data: createMonitoringGroup() };
}

/**
 * @description OK
 */
export function createShowMonitoringGroupQueryResponse(): NonNullable<ShowMonitoringGroupQueryResponse> {
  return { data: createMonitoringGroup() };
}
