import { faker } from "@faker-js/faker";

import type {
  CreateRecordLink201,
  CreateRecordLinkMutationRequest,
  CreateRecordLinkMutationResponse,
  CreateRecordLinkPathParams,
} from "../models/CreateRecordLink";
import { createRecordLink } from "./createRecordLink";
import { createRecordTypePath } from "./createRecordTypePath";

export function createCreateRecordLinkPathParams(): NonNullable<CreateRecordLinkPathParams> {
  return { record_type: createRecordTypePath(), record_id: faker.number.int() };
}

/**
 * @description Record Link created
 */
export function createCreateRecordLink201(): NonNullable<CreateRecordLink201> {
  return { data: createRecordLink() };
}

export function createCreateRecordLinkMutationRequest(): NonNullable<CreateRecordLinkMutationRequest> {
  return {
    record_link: {
      outward_record_id: faker.number.int(),
      outward_record_type: createRecordTypePath(),
      link_type: faker.string.alpha(),
    },
  };
}

/**
 * @description Record Link created
 */
export function createCreateRecordLinkMutationResponse(): NonNullable<CreateRecordLinkMutationResponse> {
  return { data: createRecordLink() };
}
