import { http } from "msw";

import { createGetCompanyAttestationsQueryResponse } from "../createGetCompanyAttestations";

export const getCompanyAttestationsHandler = http.get(
  "*/companies/:company_id/attestations",
  function handler(info) {
    return new Response(
      JSON.stringify(createGetCompanyAttestationsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
