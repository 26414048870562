import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { useIntl } from "react-intl";

import { useInteractiveMediaContext } from "../../interactive-media.context";

export function UploadDate() {
  const { formatDate, formatMessage } = useIntl();
  const { file } = useInteractiveMediaContext();

  if (!file) {
    return null;
  }

  return (
    <Typography variant="body2" color="text.secondary">
      {formatMessage(
        { defaultMessage: "Uploaded on {date}" },
        {
          date: formatDate(file.created_at, {
            dateStyle: "medium",
            timeStyle: "short",
          }),
        },
      )}
    </Typography>
  );
}
