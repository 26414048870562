import { useState } from "react";

import { checkContentOverflows } from "../../utils/check-content-overflows";

export function useDetectOverflow() {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [visibleChildCount, setVisibleChildCount] = useState(0);

  const measureElement = (textElement: HTMLDivElement | null) => {
    setIsOverflowing(() => {
      if (textElement) {
        return checkContentOverflows(textElement);
      }

      return false;
    });

    setVisibleChildCount(() => {
      if (textElement) {
        return Array.from(textElement.children).reduce((acc, child) => {
          if (child instanceof HTMLElement) {
            // check that the whole content of the child is visible
            const childRightPoint = child.offsetLeft + child.scrollWidth;

            if (childRightPoint < textElement.clientWidth) {
              return acc + 1;
            }
          }

          return acc;
        }, 0);
      }

      return 0;
    });
  };

  return {
    measureElement,
    isOverflowing,
    visibleChildCount,
  };
}
