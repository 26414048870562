import * as ToggleGroupPrimitive from "@radix-ui/react-toggle-group";
import type { VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "../../lib/utils";
import { toggleButtonVariants } from "./ToggleButton";

const ToggleButtonGroupContext = React.createContext<
  VariantProps<typeof toggleButtonVariants>
>({
  size: "md",
  variant: "default",
});

const ToggleButtonGroup = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root> &
    VariantProps<typeof toggleButtonVariants>
>(({ className, size, variant, children, ...props }, ref) => (
  <ToggleGroupPrimitive.Root
    ref={ref}
    className={cn(
      "tw-flex tw-items-center tw-justify-center tw-gap-1",
      className,
    )}
    {...props}
  >
    <ToggleButtonGroupContext.Provider value={{ size, variant }}>
      {children}
    </ToggleButtonGroupContext.Provider>
  </ToggleGroupPrimitive.Root>
));

ToggleButtonGroup.displayName = "ToggleButtonGroup";

const ToggleButtonGroupItem = React.forwardRef<
  React.ElementRef<typeof ToggleGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item> &
    VariantProps<typeof toggleButtonVariants>
>(({ className, children, size, variant, ...props }, ref) => {
  const context = React.useContext(ToggleButtonGroupContext);

  return (
    <ToggleGroupPrimitive.Item
      ref={ref}
      className={cn(
        toggleButtonVariants({
          size: context.size || size,
          variant: context.variant || variant,
        }),
        className,
      )}
      {...props}
    >
      {children}
    </ToggleGroupPrimitive.Item>
  );
});

ToggleButtonGroupItem.displayName = "ToggleButtonGroupItem";

export { ToggleButtonGroup, ToggleButtonGroupItem };
