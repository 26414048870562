import type { AxiosResponse } from "axios";
import axios from "axios";
import { action, makeObservable, observable } from "mobx";

import type { Field, RecordVersion } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { SelectOption } from "../types/field-types";
import type { IndexParams } from "../types/module-store-types";
import type { RiskRegisterScoringMatrix } from "../types/risk-register-types";
import Paginated from "./Paginated";

const EMPTY_CONTROLS_DATA = {
  modules: [],
  changed: false,
};

const EMPTY_OPERATIONAL_CONTROLS_DATA = {
  selectedIDs: [],
  changed: false,
};

const EMPTY_RELATED_RISKS_DATA = {
  selectedIDs: [],
  changed: false,
};

type ApiResponse = AxiosResponse<{
  rr_scoring_matrices?: RiskRegisterScoringMatrix[];
  record_version?: RecordVersion;
  rr_fields?: Field[];
  rr_records?: RecordVersion[];
  rr_field_options?: SelectOption[];
  selected_records_ids?: number[];
}>;

export default class IssueManagement extends Paginated {
  mainStore: MainStore;

  // Observable objects
  lastTab = "Active";
  moduleWorkspaceID = null;
  controlsData = EMPTY_CONTROLS_DATA;
  operationalControlsData = EMPTY_OPERATIONAL_CONTROLS_DATA;
  relatedRisksData = EMPTY_OPERATIONAL_CONTROLS_DATA;

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    super(mainStore);

    makeObservable(this, {
      data: observable,
      moduleWorkspaceID: observable,
      controlsData: observable,
      operationalControlsData: observable,
      relatedRisksData: observable,
      sectionsMetadata: observable,

      setSectionsMetadata: action,
      setData: action,
      setControlsData: action,
      resetControlsData: action,
      setOperationalControlsData: action,
      resetOperationalControlsData: action,
      setRelatedRisksData: action,
      resetRelatedRisksData: action,
      lastTab: observable,
      setModuleWorkspaceID: action,
      setSubRecords: action,
      setLastTab: action,
      setPageForSection: action,
      upsertRecordCounts: action,
      updateRecordVersions: action,
    });

    this.mainStore = mainStore;
  }

  // @route GET /api/react/workspaces/:workspace_id/issue_management {format: "json"} (api_react_workspace_issue_management_index)
  async index({
    tab = this.lastTab,
    sortParams,
    page = 1,
    sectionTagId = null,
    tableFilters = [],
    workspaceID, // needed consult about this
  }: IndexParams) {
    // Avoid extra API calls
    // - when loading IssueManagement for ALL SectionTags outside of page 1 (all Sections are ONLY loaded on page 1)
    if (sectionTagId === null && page !== 1) {
      return;
    }
    // - when loading IssueManagement for ALL SectionTags with sorting applied (each section is sorted separately)
    if (sectionTagId === null && sortParams) {
      return;
    }

    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        tab,
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
        section_tag_id: sectionTagId,
        page,
      };

      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${workspaceID}/issue_management`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }

      this.setModuleWorkspaceID(response.data.module_workspace_id);
      this.setPageForSection(page, sectionTagId);
      this.upsertRecordCounts(response.data.counts);

      // If we fetched data for specific SectionTag - ONLY replace RecordVersions for that SectionTag
      this.updateRecordVersions(sectionTagId, response.data);

      this.mainStore.fields.setList(response.data.fields);
    } catch (error) {
      window.console.log(
        `"IssueManagement#Index for Workspace" error ${error}`,
      );
    }
  }

  // POST /api/react/workspaces/:workspace_id/issue_management
  async create({
    workspaceID,
    tableName,
    sectionTagID,
    issueData,
    files,
  }: {
    workspaceID: number;
    tableName: string;
    sectionTagID?: number;
    issueData?: unknown;
    files?: unknown;
  }) {
    const data = {
      table_title: tableName,
      ...(sectionTagID ? { section_tag_id: sectionTagID } : {}),
      data: issueData,
      ...(files ? { attachments: files } : {}),
    };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/workspaces/${workspaceID}/issue_management`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        return response.response.data;
      }

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      return response.data.record_version;
    } catch (error) {
      window.console.log(`"IssueManagement#create" error ${error}`);
    }
  }

  // @route PUT /api/react/issue_management/:id/finalize
  async finalize(recordVersionID: number) {
    try {
      const result = await legacyApi({
        method: "PUT",
        url: `${API_URL}/issue_management/${recordVersionID}/finalize`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        if (result.response.data.cells_errors) {
          this.mainStore.recordVersions.setCellsErrors(
            // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
            result.response.data.cells_errors,
          );
        } else {
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          this.mainStore.toast.setErrorFromResponse(result.response);
        }
        return result;
      }
    } catch (error) {
      window.console.log(`"IssueManagement#finalize" error ${error}`);
    }
  }

  // PUT /api/react/issue_management/:id/reopen
  async reopen(recordVersionID: number) {
    try {
      const result = await legacyApi({
        method: "PUT",
        url: `${API_URL}/issue_management/${recordVersionID}/reopen`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(result.response);
        return;
      }
    } catch (error) {
      window.console.log(`"IssueManagement#reopen" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/issue_management/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }
    } catch (error) {
      window.console.log(`"IssueManagement#delete" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async fetchControlsData(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/record_versions/${recordVersionID}/controls/modules`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      this.setControlsData(response.data, true);
    } catch (error) {
      window.console.log(`"IssueManagement#fetchControlsData" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async saveControlsData(recordVersionID) {
    const { modules } = this.controlsData;

    const data = {
      controls_data: {
        records_ids: modules
          // @ts-expect-error TS(2339) FIXME: Property 'checked' does not exist on type 'never'.
          .filter((item) => item.checked && item.selected_ids.length)
          // @ts-expect-error TS(2339) FIXME: Property 'selected_ids' does not exist on type 'ne... Remove this comment to see the full error message
          .map((item) => item.selected_ids)
          .flat(),
      },
    };

    try {
      const res = await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/controls/modules`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (res.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(res.response);
        return;
      }

      const { record_version: recordVersion } = res.data;
      this.mainStore.recordVersions.setList(
        this.mainStore.recordVersions.list.map((item) =>
          item.id === recordVersionID ? recordVersion : item,
        ),
      );
      this.setControlsData(this.controlsData, true);
    } catch (error) {
      window.console.log(`"IssueManagement#saveControlsData" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async fetchOperationalControlsData(recordVersionID, allStatus = null) {
    const params = { all_status: allStatus };

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/record_versions/${recordVersionID}/controls/operational`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      return response.data;
    } catch (error) {
      window.console.log(
        `"IssueManagement#fetchOperationalControlsData" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async saveOperationalControls(recordVersionID) {
    const data = {
      controls_data: {
        record_versions_ids: this.operationalControlsData.selectedIDs,
      },
    };

    try {
      const res = await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/controls/operational`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (!res.isAxiosError) {
        const { record_version: recordVersion } = res.data;
        this.mainStore.recordVersions.setList(
          this.mainStore.recordVersions.list.map((item) =>
            item.id === recordVersionID ? recordVersion : item,
          ),
        );
        this.setOperationalControlsData(
          this.operationalControlsData.selectedIDs,
          false,
        );
        this.mainStore.toast.setInfoText("Operational Controls data saved!");
      } else {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(res.response);
        return;
      }
    } catch (error) {
      window.console.log(
        `"IssueManagement#saveOperationalControls" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async fetchRelatedRisksData(recordVersionID) {
    try {
      const response: ApiResponse = await legacyApi({
        method: "GET",
        url: `${API_URL}/record_versions/${recordVersionID}/controls/risks`,
        headers: this.mainStore.getHeaders(),
      });

      if (axios.isAxiosError(response)) {
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      this.mainStore.riskRegisters.setData(
        { scoring_matrices: response.data.rr_scoring_matrices },
        false,
      );

      return response.data;
    } catch (error) {
      window.console.log(
        `"IssueManagement#fetchRelatedRisksData" error ${error}`,
      );
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async saveRelatedRisks(recordVersionID) {
    const data = {
      controls_data: {
        records_ids: this.relatedRisksData.selectedIDs,
      },
    };

    try {
      const res: ApiResponse = await legacyApi({
        method: "POST",
        url: `${API_URL}/record_versions/${recordVersionID}/controls/risks`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      if (!axios.isAxiosError(res)) {
        const { record_version: recordVersion } = res.data;
        this.mainStore.recordVersions.setList(
          this.mainStore.recordVersions.list.map((item) =>
            item.id === recordVersion?.id ? recordVersion : item,
          ),
        );
        this.setRelatedRisksData(this.relatedRisksData.selectedIDs, false);
        this.mainStore.toast.setInfoText("Related Risks data saved!");
      } else {
        this.mainStore.toast.setErrorFromResponse(res.response);
        return;
      }
    } catch (error) {
      window.console.log(`"IssueManagement#saveRelatedRisks" error ${error}`);
    }
  }

  // GET /api/react/action_plans
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async indexActionPlans(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/issue_management/${recordVersionID}/action_plans`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      this.setSubRecords(response.data);
      this.mainStore.fields.setList(response.data.fields);
      return response.data as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    } catch (error) {
      window.console.warn(
        `"IM #indexActionPlans for Workspace" error ${error}`,
      );
    }
  }

  // POST /api/react/action_plans
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async createActionPlan(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/issue_management/${recordVersionID}/action_plans`,
        headers: this.mainStore.getHeaders(),
        data: { record_version_id: recordVersionID },
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }

      return response.data as any; // eslint-disable-line @typescript-eslint/no-explicit-any
    } catch (error) {
      window.console.warn(
        `"IM #createActionPlan for Workspace" error ${error}`,
      );
    }
  }

  // DELETE /api/react/action_plans
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async deleteActionPlan(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/action_plans/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }
    } catch (error) {
      window.console.log(`"IM #deleteActionPlan" error ${error}`);
    }
  }

  // POST /api/react/action_plans/:id/close
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async closeActionPlan(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/action_plans/${recordVersionID}/close`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        if (response.response.data.cells_errors) {
          this.mainStore.recordVersions.setCellsErrors(
            // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
            response.response.data.cells_errors,
          );
        } else {
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          this.mainStore.toast.setErrorFromResponse(response.response);
        }
      }
    } catch (error) {
      window.console.log(`"IM#closeActionPlan" error ${error}`);
    }
  }

  // POST /api/react/action_plans/:id/unlock
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unlockActionPlan(recordVersionID) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/action_plans/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
        this.mainStore.toast.setErrorFromResponse(response.response);
        return;
      }
    } catch (error) {
      window.console.log(`"IM#unlockActionPlan" error ${error}`);
    }
  }

  // PUT /api/react/issue_management/:id/update_section
  async updateSection(recordVersionID: number, sectionTagID: number) {
    const data = {
      section_tag_id: sectionTagID,
      identifier: "issue_management",
    };

    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/issue_management/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });
      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response?.isAxiosError && response?.message.includes("403")) {
        this.mainStore.toast.setErrorText("Error: Could not reorder record.");
      }
    } catch (error) {
      this.mainStore.toast.setErrorText("Error: Unable to reorder record.");
      window.console.log(`"IssueManagement#updateSection" error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;

    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setSubRecords(value) {
    const newValue = value || { record_versions: [] };
    this.mainStore.recordVersions.setList(newValue.record_versions, true);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setLastTab(value) {
    if (value) {
      this.lastTab = value;
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setModuleWorkspaceID(value) {
    this.moduleWorkspaceID = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setControlsData(value, initialRequest = false) {
    this.controlsData = { ...value, changed: !initialRequest };
  }

  resetControlsData() {
    this.controlsData = EMPTY_CONTROLS_DATA;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  setOperationalControlsData(ids, changed = true) {
    this.operationalControlsData = { selectedIDs: ids, changed };
  }

  resetOperationalControlsData() {
    this.operationalControlsData = EMPTY_OPERATIONAL_CONTROLS_DATA;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  setRelatedRisksData(ids, changed = true) {
    this.relatedRisksData = { selectedIDs: ids, changed };
  }

  resetRelatedRisksData() {
    this.relatedRisksData = EMPTY_RELATED_RISKS_DATA;
  }

  // Store Helpers

  cleanup() {
    super.cleanup();
    this.setModuleWorkspaceID(null);
    this.resetControlsData();
    this.resetOperationalControlsData();
    this.resetRelatedRisksData();
    this.setSubRecords({ record_versions: [] });
  }

  abort() {
    this.controller?.abort();
  }
}
