import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import React from "react";

import { cn } from "../../lib/utils";

const DropdownMenu = DropdownMenuPrimitive.Root;

const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger;

const dropdownMenuContentDefaultStyles =
  "tw-shadow-dropdown tw-font-sans tw-z-[1300] tw-min-w-32 tw-overflow-hidden tw-rounded-md tw-bg-neutral-25 tw-py-2";
const dropdownMenuContentAnimationStyles =
  "data-[state=open]:tw-animate-in data-[state=closed]:tw-animate-out data-[state=closed]:tw-fade-out-0 data-[state=open]:tw-fade-in-0 data-[state=closed]:tw-zoom-out-95 data-[state=open]:tw-zoom-in-95 data-[side=bottom]:tw-slide-in-from-top-2 data-[side=left]:tw-slide-in-from-right-2 data-[side=right]:tw-slide-in-from-left-2 data-[side=top]:tw-slide-in-from-bottom-2";

const DropdownMenuContent = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        [dropdownMenuContentDefaultStyles, dropdownMenuContentAnimationStyles],
        className,
      )}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
));

DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const dropdownMenuItemDefaultStyles =
  "tw-relative tw-flex tw-cursor-pointer tw-select-none tw-items-center tw-rounded-sm tw-px-4 tw-py-1 tw-outline-none tw-transition-colors";
const dropdownMenuItemFontStyles =
  "tw-text-sm tw-font-medium tw-text-neutral-500";
const dropdownMenuItemFocusStyles =
  "focus:tw-bg-primary-25 focus:tw-text-primary-300";
const dropdownMenuItemDisabledStyles =
  "data-[disabled]:tw-pointer-events-none data-[disabled]:tw-opacity-50";

const DropdownMenuItem = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean;
  }
>(({ className, inset, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      [
        dropdownMenuItemDefaultStyles,
        dropdownMenuItemFontStyles,
        dropdownMenuItemFocusStyles,
        dropdownMenuItemDisabledStyles,
      ],
      inset && "pl-8",
      className,
    )}
    {...props}
  />
));

DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const dropdownMenuSeparatorStyles =
  "-tw-mx-1 tw-my-2 tw-h-px tw-bg-neutral-100";

const DropdownMenuSeparator = React.forwardRef<
  React.ElementRef<typeof DropdownMenuPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <DropdownMenuPrimitive.Separator
    ref={ref}
    className={cn(dropdownMenuSeparatorStyles, className)}
    {...props}
  />
));

DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
};
