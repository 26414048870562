import type { ThemeOptions } from "@mui/material";

export const avatarGroupTheme: ThemeOptions["components"] = {
  MuiAvatarGroup: {
    defaultProps: {
      spacing: 3,
    },
    styleOverrides: {
      root: () => ({
        alignItems: "center",
      }),
      avatar: () => ({
        border: "none",
      }),
    },
  },
};
