import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  UpdateMutationRequest,
  UpdateMutationResponse,
  UpdatePathParams,
} from "../../models/Update";

/**
 * @description Update an Attestation
 * @summary Update an Attestation
 * @link /attestations/:id
 */
export async function update(
  id: UpdatePathParams["id"],
  data: UpdateMutationRequest,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<UpdateMutationResponse>["data"]> {
  const res = await client<UpdateMutationResponse, UpdateMutationRequest>({
    method: "put",
    url: `/attestations/${id}`,
    data,
    ...options,
  });
  return res.data;
}
