import type { ContainerProps } from "@mui/material";

import { styled } from "../../../../styles";

const StyledContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  color: theme.palette.common.black,
  backgroundColor: theme.palette.common.white,
  height: "100%",
  overflowY: "auto",
  "&": {
    padding: "0 0",
    boxSizing: "border-box",
  },

  "& *": {
    boxSizing: "border-box",
  },
  "*": {
    boxSizing: "border-box",
  },
}));

export function PageContainer({ children, ...rest }: ContainerProps) {
  return <StyledContainer {...rest}>{children}</StyledContainer>;
}
