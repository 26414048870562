import classNames from "classnames";
import { kebabCase } from "lodash";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

interface Props {
  recordVersionID: number;
  pinned: boolean;
  isPositionFirst: boolean;
  themisID: string;
}

function ThemisID({
  recordVersionID,
  pinned,
  themisID,
  isPositionFirst,
}: Props) {
  // Import Mobx stores
  const mainStore = useMainStore();

  // hooks
  const location = useLocation();
  const { record_version_id: parentRecordVersionID } = useParams<{
    record_version_id: string;
  }>();

  // Variables
  const [showPopup, setShowPopup] = useState(false);

  const { themisModuleIdentifier, subModuleTableName, workspaceID } =
    mainStore.context;
  const detailSubLinkModules = ["action_plans", "procedures", "issues"];

  const detailLinkModules = [
    "policy",
    "procedures",
    "issue_management",
    "complaints",
    "control_mapping",
    "vendor_due_diligence",
    "documents",
    "qa_tests_development",
    "marketing",
    "risk_register",
    "audits",
    "training",
    "risk_assessment",
    "new_product_approval",
    "customer_support",
    "conflicts_of_interest",
    "finra",
    TopLevelModule.KEY_RISK_INDICATORS,
  ];

  let isDetailLinkEnabled = detailLinkModules.includes(
    themisModuleIdentifier as string,
  );

  const isArchived = location.pathname.includes("archived");
  const isNotReladedLink =
    location.pathname.includes("operational-controls") ||
    location.pathname.includes("related-risks") ||
    location.pathname.includes("historical-versions");

  let tooltipMessage = "Detail View Coming Soon";
  if (isArchived) {
    isDetailLinkEnabled = false;
    tooltipMessage = "Detail View is not available for archived records";
  }

  if (isNotReladedLink) {
    isDetailLinkEnabled = false;
  }

  if (
    subModuleTableName !== null &&
    parentRecordVersionID &&
    !detailSubLinkModules.includes(subModuleTableName)
  ) {
    isDetailLinkEnabled = false;
  }

  const getThemisModuleName = () => {
    if (themisModuleIdentifier === "new_product_approval") {
      return "change_management";
    }

    if (
      themisModuleIdentifier === "qa_tests_development" &&
      subModuleTableName &&
      detailSubLinkModules.includes(subModuleTableName)
    ) {
      return "qa-tests";
    }

    return themisModuleIdentifier;
  };

  const getDetailsViewUrl = (): string => {
    if (!isDetailLinkEnabled) {
      return "#";
    }

    const baseURL = `/workspaces/${workspaceID}/modules/${kebabCase(
      // @ts-expect-error TS(2345) FIXME: Argument of type '"audits" | "complaints" | "confl... Remove this comment to see the full error message
      getThemisModuleName(),
    )}`;

    if (parentRecordVersionID && subModuleTableName) {
      if (getThemisModuleName() === "qa-tests") {
        return `${baseURL}/${parentRecordVersionID}/view-${kebabCase(
          subModuleTableName,
        )}/${recordVersionID}`;
      }

      return `${baseURL}/${parentRecordVersionID}/${kebabCase(
        subModuleTableName,
      )}/${recordVersionID}`;
    }

    return `${baseURL}/${recordVersionID}`;
  };

  return (
    <Popup
      position="top center"
      trigger={
        <li
          aria-disabled={!isDetailLinkEnabled}
          className={classNames(
            "global-id-cell global-id-width cell computed-columns read-only",
            { pinned, "global-id-mr first-position": isPositionFirst },
          )}
          data-testid={themisID}
        >
          <Link
            to={getDetailsViewUrl()}
            className={classNames(
              "cell-content global-id-context",
              !isDetailLinkEnabled && "tw-pointer-events-none",
            )}
            data-testid={`global-id-${recordVersionID}`}
          >
            {themisID}
          </Link>
        </li>
      }
      open={showPopup}
      onOpen={() => setShowPopup(true)}
      onClose={() => setShowPopup(false)}
      on="hover"
      arrow={false}
      disabled={isDetailLinkEnabled}
    >
      <div className="global-id-popup-wrap">
        {!isNotReladedLink && (
          <span className="global-id-popup-wrap__content">
            {tooltipMessage}
          </span>
        )}
      </div>
    </Popup>
  );
}

export default observer(ThemisID);
