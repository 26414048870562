import type { ThemisDocument } from "@themis/api/gen/models/themisDocument";
import { actionsColumnDefParams } from "@themis/ui-library/components/data-grid/column-defs/actions-column-def-params";
import type { ColDef } from "ag-grid-community";
import type { CustomCellRendererProps } from "ag-grid-react";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { DATE_FORMAT } from "@/constants";

import { ActionsCell } from "./actions-cell/actions-cell";

export const useDocumentsTableColumns = (): ColDef<ThemisDocument>[] => {
  const { formatMessage } = useIntl();

  return useMemo(
    (): ColDef<ThemisDocument>[] => [
      {
        sortable: false,
        field: "file.file_name",
        headerName: formatMessage({ defaultMessage: "Name" }),
        minWidth: 180,
        flex: 1,
      },
      {
        sortable: false,
        field: "file.created_at",
        headerName: formatMessage({ defaultMessage: "Upload Date" }),
        minWidth: 120,
        flex: 1,
        resizable: false,
        valueFormatter: (params: { value: string }) =>
          dayjs(params.value).format(DATE_FORMAT),
      },
      {
        ...actionsColumnDefParams,
        cellRenderer: (props: CustomCellRendererProps) => (
          <ActionsCell
            documentFile={props.data.file}
            documentId={props.data.id}
          />
        ),
      },
    ],
    [formatMessage],
  );
};
