import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import type { ReactNode } from "react";
import React, { useCallback, useEffect, useState } from "react";
import { PiCaretLeftBold, PiCaretRightBold, PiXBold } from "react-icons/pi";
import { Document, Page, pdfjs } from "react-pdf";

import { cn } from "../../lib/utils";
import { IconButton } from "../Button/IconButton";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const PaginationContainer = ({ children }: { children: ReactNode }) => (
  <div className="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-3 tw-rounded tw-bg-white tw-py-2">
    {children}
  </div>
);

const CloseButtonContainer = ({ children }: { children: ReactNode }) => (
  <div className="tw-absolute tw-right-0 tw-top-1.5 tw-z-50 tw-box-border tw-flex tw-w-full tw-justify-end tw-px-2">
    {children}
  </div>
);

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const PAGE_SCALE = 0.9;

type PDFFile = string | File | null;

const PdfViewer = ({
  pdf,
  pageHeight,
  onClose = () => {},
}: {
  pdf: PDFFile;
  pageHeight?: number;
  onClose?: () => void;
}) => {
  const [numPages, setNumPages] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [renderedPageNumber, setRenderedPageNumber] = useState<number>();

  const isLoading = renderedPageNumber !== pageNumber;

  function handleDocumentLoadSuccess({
    numPages: nextNumPages,
  }: pdfjs.PDFDocumentProxy): void {
    setNumPages(nextNumPages);
  }

  const handleNextPage = useCallback(() => {
    if (numPages === 1) {
      return;
    }

    setPageNumber((prev) => (prev === numPages ? 1 : prev + 1));
  }, [numPages]);

  const handlePreviousPage = useCallback(() => {
    if (numPages === 1) {
      return;
    }

    setPageNumber((prev) => (prev === 1 ? numPages : prev - 1));
  }, [numPages]);

  useEffect(() => {
    const handleKeyboardInput = (event: KeyboardEvent) => {
      if (event.key === "ArrowDown" || event.key === "ArrowRight") {
        handleNextPage();
      }

      if (event.key === "ArrowUp" || event.key === "ArrowLeft") {
        handlePreviousPage();
      }
    };

    document.addEventListener("keydown", handleKeyboardInput, true);

    return () => {
      document.removeEventListener("keydown", handleKeyboardInput, true);
    };
  }, [handleNextPage, handlePreviousPage]);

  return (
    <Document
      className="tw-relative"
      file={pdf}
      onLoadSuccess={handleDocumentLoadSuccess}
      options={options}
    >
      <CloseButtonContainer>
        <IconButton
          Icon={PiXBold}
          size="lg"
          color="tertiary"
          onClick={onClose}
        />
      </CloseButtonContainer>

      {/* Showing previous page until new page is finished rendering to av oid flickering */}
      {isLoading && renderedPageNumber ? (
        <Page
          key={renderedPageNumber}
          pageNumber={renderedPageNumber}
          height={pageHeight}
          scale={PAGE_SCALE}
          loading={null}
        />
      ) : null}

      <Page
        // Hiding the current page until it's finished rendering helps avoid flickering
        className={cn({ "tw-hidden": isLoading })}
        key={pageNumber}
        pageNumber={pageNumber}
        height={pageHeight}
        scale={PAGE_SCALE}
        loading={null}
        onRenderSuccess={() => setRenderedPageNumber(pageNumber)}
      />

      <PaginationContainer>
        <IconButton
          disabled={numPages === 1}
          Icon={PiCaretLeftBold}
          color="transparent"
          onClick={handlePreviousPage}
        />
        <span className="tw-flex tw-items-center tw-text-center tw-text-neutral-500">
          {pageNumber} of {numPages}
        </span>
        <IconButton
          disabled={numPages === 1}
          Icon={PiCaretRightBold}
          color="transparent"
          onClick={handleNextPage}
        />
      </PaginationContainer>
    </Document>
  );
};

export { PdfViewer };
