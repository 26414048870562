import { paginationItemClasses, type ThemeOptions } from "@mui/material";

export const paginationItem: ThemeOptions["components"] = {
  MuiPaginationItem: {
    defaultProps: {
      size: "small",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        [`&.${paginationItemClasses.selected}`]: {
          backgroundColor: theme.palette.grey["600"],
          color: theme.palette.common.white,

          "&:hover": {
            backgroundColor: theme.palette.grey["800"],
          },
        },
      }),
    },
  },
};
