import {
  ConfirmationPopup,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  useToast,
} from "@themis/ui";
import { useState } from "react";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { useIntl } from "react-intl";
import { generatePath, Link, useParams, useRouteMatch } from "react-router-dom";

import type { Contract } from "@/api";
import { useDeleteContract } from "@/api/queries/contracts";

import { routes } from "../../routes";

const DROPDOWN_ITEM_STYLE =
  "tw-flex tw-w-[228px] tw-items-center tw-justify-start tw-gap-1 tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primary-50 tw-px-4 tw-py-[10px] tw-justify-between";

export function ContractsActionsCell({ contract }: { contract: Contract }) {
  const isAllContracts = Boolean(useRouteMatch(routes.accounts.contracts));
  const { formatMessage } = useIntl();

  const toast = useToast();
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const { mutate: deleteContract } = useDeleteContract({
    workspaceId: isAllContracts ? Number(workspace_id) : undefined,
    accountId: !isAllContracts ? contract.account_id : undefined,
    onSuccess: () => {
      toast({
        content: "Contract has been removed!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to remove contract!",
        variant: "error",
      });
    },
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  function handleDelete() {
    setIsConfirmationOpen(false);
    deleteContract(contract.id);
  }

  function handleClickDelete() {
    setIsConfirmationOpen(true);
  }

  function handleCancelDelete() {
    setIsConfirmationOpen(false);
  }

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton
            aria-label={formatMessage({ defaultMessage: "Contracts Actions" })}
            aria-describedby={`contract-id-${contract.id}`}
            Icon={PiDotsThreeOutlineVerticalFill}
            color="transparent"
            className="tw-h-8 tw-w-8 tw-rounded-md"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="tw-py-0">
          <DropdownMenuItem
            key="Edit"
            aria-label={formatMessage({
              defaultMessage: "Edit Contract",
            })}
            className={`${DROPDOWN_ITEM_STYLE} tw-w-full tw-border-b-0 tw-p-0`}
          >
            <Link
              className="tw-h-full tw-w-full tw-px-4 tw-py-2.5"
              to={generatePath(routes.accounts.detail.contracts.detail, {
                workspace_id,
                accountId: contract.account_id,
                contractId: contract.id,
              })}
            >
              {formatMessage({ defaultMessage: "Edit" })}
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem
            key="Delete"
            aria-label={formatMessage({
              defaultMessage: "Delete Contract",
            })}
            onClick={handleClickDelete}
            className={`${DROPDOWN_ITEM_STYLE} tw-border-b-0`}
          >
            <span className="tw-text-sm tw-font-medium">
              {formatMessage({ defaultMessage: "Delete" })}
            </span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ConfirmationPopup
        anchor
        align="end"
        title="Delete Contract"
        content="Actions are non-reversible. Are you sure you want to proceed?"
        open={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
      />
    </>
  );
}
