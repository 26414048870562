import type { Questionnaire } from "@themis/api/gen/models/questionnaire";
import { useClientDataGridBinding } from "@themis/ui-library/components/data-grid/binding/use-client-data-grid-binding";
import { DataGrid } from "@themis/ui-library/components/data-grid/data-grid";
import React, { useMemo } from "react";

import { useAccount } from "@/api/queries/accounts";
import { useRiskMethodologies } from "@/api/queries/riskMethodologies";
import { useUsers } from "@/api/queries/users/use-users";
import type { RiskMethodology } from "@/features/risk-assessment";
import { getDefinitionsFromScores } from "@/features/risk-assessment";

import { columns } from "./TableColumns";

interface AccountQuestionnairesProps {
  accountId: number;
  workspaceId: number;
}

function AccountQuestionnaires({
  accountId,
  workspaceId,
}: AccountQuestionnairesProps) {
  const { data: accountData, isLoading: accountIsLoading } = useAccount({
    workspaceId,
    accountId,
    queryParams: {
      expand: "questionnaires",
    },
  });
  const { data: users = [], isLoading: usersIsLoading } = useUsers(workspaceId);

  const { data: riskData, isLoading: riskIsLoading } =
    useRiskMethodologies(workspaceId);

  const riskMethodologies: RiskMethodology[] =
    riskData?.data.risk_methodologies;

  const tableRows: Questionnaire[] = useMemo(
    () =>
      accountData?.data.questionnaires?.map((questionnaire: Questionnaire) => {
        const methodology = riskMethodologies?.find(
          (rm) => rm.id === questionnaire.risk_methodology_id,
        );

        const {
          overallRiskRating,
          controlRiskRating,
          inherentRiskRating,
          residualRiskRating,
        } = getDefinitionsFromScores(questionnaire.scoring, methodology);

        return {
          ...questionnaire,
          overallRiskRating,
          controlRiskRating,
          inherentRiskRating,
          residualRiskRating,
        };
      }) || [],
    [accountData, riskMethodologies],
  );

  const { clientGridProps } = useClientDataGridBinding(tableRows);

  if (accountIsLoading || usersIsLoading || riskIsLoading) {
    return null;
  }

  return (
    <DataGrid
      columns={columns(users, workspaceId)}
      width="100%"
      {...clientGridProps}
    />
  );
}

export default AccountQuestionnaires;
