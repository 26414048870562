import { capitalize } from "lodash";
import pluralize, { singular } from "pluralize";

import type { RecordTypePath, RelatableRelatableType } from "@/api";

export const recordTypeToDisplayName: { [key: string]: string } = {
  accounts: "Account",
  trainings: "Training",
};

// "themis_record" -> "ThemisRecord", "training" -> "Training", "record" -> "Record"
export function relatableTypeToTargetType(relatableType: string): string {
  return relatableType
    .split("_")
    .map((word) => capitalize(word))
    .join("");
}

// "trainings" -> "training"
export function recordTypeToRelatableType(
  recordType: string,
): RelatableRelatableType {
  return singular(recordType) as RelatableRelatableType;
}

// record -> records, training -> trainings
export function relatableTypeToRecordType(
  relatableType: RelatableRelatableType,
): RecordTypePath {
  return pluralize(relatableType) as RecordTypePath;
}
