import { faker } from "@faker-js/faker";

import type { Project } from "../models/Project";
import { createTaskReference } from "./createTaskReference";
import { createThemisRecord } from "./createThemisRecord";

export function createProject(
  data?: NonNullable<Partial<Project>>,
): NonNullable<Project> {
  return Object.assign({}, createThemisRecord(), {
    id: faker.number.int(),
    name: faker.string.alpha(),
    sections: faker.helpers.arrayElements([faker.string.alpha()]) as any,
    description: faker.string.alpha(),
    status: faker.helpers.arrayElement<any>([
      "not_started",
      "in_progress",
      "done",
    ]),
    due_date: faker.string.alpha(),
    created_at: faker.date.anytime().toISOString(),
    completed_at: faker.date.anytime().toISOString(),
    assignee_id: faker.number.int(),
    workspace_id: faker.number.int(),
    labels: faker.helpers.arrayElements([faker.string.alpha()]) as any,
    tasks: faker.helpers.arrayElements([
      faker.helpers.arrayElement<any>([{}, createTaskReference()]),
    ]) as any,
    archived_at: faker.date.anytime().toISOString(),
    visibility: faker.helpers.arrayElement<any>(["internal", "public"]),
  });
}
