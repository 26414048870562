import { Command as CommandPrimitive } from "cmdk";
import * as React from "react";
import { twMerge } from "tailwind-merge";

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={twMerge(
      "tw-relative tw-flex tw-cursor-default tw-select-none tw-items-center tw-px-4 tw-py-1.5 tw-font-sans tw-text-sm tw-font-medium tw-text-neutral-500 tw-outline-none",
      "aria-selected:tw-bg-primary-25 aria-selected:tw-text-primary-300",
      "data-[disabled='true']:tw-pointer-events-none data-[disabled='true']:tw-opacity-50",
      className,
    )}
    {...props}
  />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

export { CommandItem };
