import type { DataWithIdAndCustomFields } from "@themis/ui";
import { isEmpty } from "lodash";

import type { RecordTypePath } from "@/api";

import type { Filters } from "../types";

export function getLocalStorageFiltersKey() {
  return "filters";
}

interface SaveFiltersToLocalStorageParams<T> {
  filters: Filters<T>;
  recordType: RecordTypePath;
  workspaceId: string;
}

export function saveFiltersToLocalStorage<T extends DataWithIdAndCustomFields>({
  filters,
  recordType,
  workspaceId,
}: SaveFiltersToLocalStorageParams<T>) {
  const cleanedFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, filterObj]) => {
      if (!filterObj) {
        return false;
      }
      const [[, value]] = Object.entries(filterObj);
      return value !== undefined;
    }),
  );

  const storageKey = getLocalStorageFiltersKey();
  const existingData = localStorage.getItem(storageKey);
  const parsedData = existingData ? JSON.parse(existingData) : {};

  if (isEmpty(cleanedFilters)) {
    if (parsedData[workspaceId]?.[recordType]) {
      delete parsedData[workspaceId][recordType];
      if (isEmpty(parsedData[workspaceId])) {
        delete parsedData[workspaceId];
      }
      if (isEmpty(parsedData)) {
        localStorage.removeItem(storageKey);
      } else {
        localStorage.setItem(storageKey, JSON.stringify(parsedData));
      }
    }
    return;
  }

  const updatedData = {
    ...parsedData,
    [workspaceId]: {
      ...(parsedData[workspaceId] || {}),
      [recordType]: cleanedFilters,
    },
  };

  localStorage.setItem(storageKey, JSON.stringify(updatedData));
}
