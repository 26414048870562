import type { SelectItem } from "@themis/ui";
import { MiniTag } from "@themis/ui";
import { useMemo } from "react";
import type { Control, FieldValues, Path } from "react-hook-form";
import { useIntl } from "react-intl";

import type { RecordTypePath } from "@/api/gen/models";
import { useSections } from "@/api/utils/sections/use-sections";
import { SelectFormField } from "@/components/form-fields/select-form-field/select-form-field";
import { useCompanyId } from "@/hooks/use-company-id";
import { hashString } from "@/utils/hash-string";

interface SectionsFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  recordType: RecordTypePath;
}

export const SectionsField = <T extends FieldValues>({
  control,
  name,
  recordType,
}: SectionsFieldProps<T>) => {
  const { formatMessage } = useIntl();
  const { companyId } = useCompanyId();

  const { sections } = useSections(companyId, recordType);

  const options = useMemo((): SelectItem[] => {
    return sections.map((section) => ({
      label: section.value,
      value: section.key,
    }));
  }, [sections]);

  return (
    <SelectFormField
      label={formatMessage({ defaultMessage: "Sections" })}
      control={control}
      name={name}
      items={options}
      multiselect
      title={formatMessage({ defaultMessage: "Select sections" })}
      renderSelected={(item) => (
        <MiniTag key={item.value} colorIndex={hashString(item.value ?? "")}>
          {item.label}
        </MiniTag>
      )}
    />
  );
};
