"use client";

import { format } from "date-fns";
import * as React from "react";
import type { CaptionLabelProps } from "react-day-picker";
import { DayPicker } from "react-day-picker";
import { PiCaretLeftBold, PiCaretRightBold } from "react-icons/pi";

import { cn } from "../../lib/utils";
import { Button } from "../Button/Button";
import { buttonBaseVariants } from "../Button/ButtonBase";

/* eslint-disable react/no-unstable-nested-components */

export type CalendarProps = React.ComponentProps<typeof DayPicker> & {
  onClickMonth?: () => void;
};

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  onClickMonth,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("tw-p-2 tw-shadow-none", className)}
      classNames={{
        months:
          "tw-flex tw-flex-col sm:tw-flex-row tw-space-y-4 sm:tw-space-x-4 sm:tw-space-y-0",
        month: "tw-space-y-4",
        caption: "tw-flex tw-justify-center tw-relative tw-items-center",
        caption_label: "tw-text-sm tw-font-semibold tw-text-neutral-600",
        nav: "tw-space-x-1 tw-flex tw-items-center",
        nav_button: cn(
          buttonBaseVariants({ color: "transparent" }),
          "tw-h-7 tw-w-7 tw-p-0",
        ),
        nav_button_previous: "tw-absolute tw-left-1",
        nav_button_next: "tw-absolute tw-right-1",
        table: "tw-w-full tw-border-collapse tw-space-y-1",
        head_row: "tw-flex",
        head_cell:
          "tw-text-neutral-300 tw-rounded-md tw-w-9 tw-font-semibold tw-text-[0.65rem]",
        row: "tw-flex tw-w-full tw-mt-1",
        cell: "tw-h-7 tw-w-9 tw-text-center tw-flex-grow tw-text-xs tw-p-0 tw-relative focus-within:tw-relative focus-within:tw-z-20",
        day: cn(
          buttonBaseVariants({ color: "grey" }),
          "tw-h-7 tw-w-9 tw-p-0 tw-font-normal aria-selected:tw-bg-secondary-300 aria-selected:hover:tw-bg-secondary-400 aria-selected:tw-text-neutral-25",
        ),
        day_range_end: "day-range-end",
        day_today: "tw-text-primary-300",
        ...classNames,
      }}
      components={{
        IconLeft: () => <PiCaretLeftBold className="tw-h-4 tw-w-4" />,
        IconRight: () => <PiCaretRightBold className="tw-h-4 tw-w-4" />,
        CaptionLabel: ({ displayMonth }: CaptionLabelProps) => (
          <Button color="transparent" onClick={onClickMonth}>
            {format(displayMonth, "MMM yyyy")}
          </Button>
        ),
      }}
      {...props}
    />
  );
}

Calendar.displayName = "Calendar";

export { Calendar };
