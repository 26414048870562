import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";

import { useInteractiveMediaContext } from "../../interactive-media.context";

export function TitleBar() {
  const { file } = useInteractiveMediaContext();

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      bgcolor="text.primary"
      py={1}
    >
      <Typography color="common.white" fontWeight="bold" variant="h6">
        {file?.file_name}
      </Typography>
    </Stack>
  );
}
