import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { uniq } from "lodash";

import type { Field, RecordVersion } from "@/api";

interface KRIValues {
  date: { value: string };
  value: { value: string };
}

interface QuarterDates {
  [quarter: string]: { month: string; day: string };
}

interface FieldWithKriInterval extends Field {
  kri_interval_type?: string;
}

dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(isBetween);
const monthYear = "MMM YYYY";
export const THRESHOLDS = ["min_threshold", "max_threshold"];
export const KRI_INPUT_WIDTH = 120;
const QUARTERS = ["Q1", "Q2", "Q3", "Q4"];

const KRI_HEADERS = [
  "id",
  "name",
  "description",
  "reporting_interval",
  ...THRESHOLDS,
];

// We assign each quarter a specific date, the last date of the quarter
const QUARTER_DATES: QuarterDates = {
  Q1: { month: "03", day: "31" },
  Q2: { month: "06", day: "30" },
  Q3: { month: "09", day: "30" },
  Q4: { month: "12", day: "31" },
};

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

// Takes in a quarter field header eg "Q1 2024" and returns a date-formatted string
export const quarterToDateString = (quarter: string) => {
  const q = quarter.slice(0, 2);
  const year: string = quarter.slice(3, quarter.length);
  const { month } = QUARTER_DATES[q];
  const { day } = QUARTER_DATES[q];

  return `${year}-${month}-${day}`;
};

// Places Months and Quarters in the table title/header row
export const fillInMonths = (dates: string[], custom?: boolean) => {
  const result = [];

  const first =
    dates.length >= 12 || custom
      ? dayjs(dates[0], "MMM YYYY")
      : dayjs().subtract(1, "year").set("month", 0);
  const last = dates[dates.length - 1]
    ? dayjs(dates[dates.length - 1], "MMM YYYY")
    : dayjs().set("day", 1);

  let currentMonth = first;
  while (currentMonth.isBefore(last)) {
    const quarterMonths = [0, 3, 6, 9];

    if (quarterMonths.includes(currentMonth.month())) {
      result.push(`Q${currentMonth.quarter()} ${currentMonth.year()}`);
    }

    result.push(currentMonth.format(monthYear));

    currentMonth = currentMonth.add(1, "month");
  }
  result.push(currentMonth.format(monthYear));

  return result.reverse();
};

export const getMonths = (
  recordVersions?: RecordVersion[],
  customStartDate?: Date,
  customEndDate?: Date,
) => {
  if (!recordVersions) {
    return [];
  }

  const valuesRecordVersions: RecordVersion[] = [];

  recordVersions.forEach((recordVersion) => {
    const childRecordVersions: RecordVersion[] | undefined =
      recordVersion.serialized_child_record_versions;

    if (childRecordVersions) {
      valuesRecordVersions.push(...childRecordVersions);
    }
  });

  let dates: string[] = [];
  valuesRecordVersions.forEach((recordVersion) => {
    const date = (recordVersion.data as KRIValues)?.date?.value;
    if (date) {
      dates.push(date);
    }
  });

  if (customStartDate) {
    const endOfPreviousMonth = dayjs(customStartDate)
      .startOf("month")
      .format("YYYY-MM-DD");

    const filteredDates = dates.filter((date) =>
      dayjs(date).isAfter(dayjs(endOfPreviousMonth)),
    );
    filteredDates.push(endOfPreviousMonth);
    dates = filteredDates;
  }
  if (customEndDate) {
    const beginningOfNextMonth = dayjs(customEndDate)
      .startOf("month")
      .format("YYYY-MM-DD");

    const filteredDates = dates.filter((date) =>
      dayjs(date).isBefore(dayjs(beginningOfNextMonth)),
    );
    filteredDates.push(beginningOfNextMonth);
    dates = filteredDates;
  } else {
    // If end date is not selected - it should go at least to current month
    const beginningOfNextMonth = dayjs().set("day", 1).format("YYYY-MM-DD");
    dates.push(beginningOfNextMonth);
  }

  dates = dates
    .filter((date) => date !== "Invalid Date")
    .sort(
      (a: string, b: string) => new Date(a).valueOf() - new Date(b).valueOf(),
    );

  const uniqueDates = uniq(dates.map((date) => dayjs(date).format(monthYear)));

  return fillInMonths(uniqueDates, !!(customStartDate || customEndDate));
};

export const getKriIntervalType = (name: string) => {
  if (MONTHS.includes(name.slice(0, 3))) {
    return "monthly";
  } else if (QUARTERS.includes(name.slice(0, 2))) {
    return "quarterly";
  }
};

export const convertToFields = (list: string[]): Field[] =>
  list.map((name: string) => ({
    name,
    data_type: "com.askthemis.types.v1.float",
    format: null,
    display_name: name,
    computed_column_identifier: null,
    is_computed_column: false,
    is_custom_field: false,
    is_default_field: false,
    is_hidden: false,
    width: 200,
    is_multiselect: false,
    is_required: false,
    is_user_editable: true,
    is_visible_to_internal_users_only: false,
    position: 0,
    validation_rules: {},
    kri_interval_type: getKriIntervalType(name),
  }));

export const mergeChildRecords = (recordVersion: RecordVersion) => {
  const result = { ...recordVersion };
  const resultData = { ...result.data };

  // @ts-expect-error TS(7006) FIXME: Property 'child_record_versions' does not exist on type 'RecordVersion'.
  recordVersion.serialized_child_record_versions?.forEach((rv) => {
    const date = (rv.data as KRIValues)?.date?.value;
    const value = (rv.data as KRIValues)?.value?.value;
    const dateDayjs = dayjs(date);
    let title = dateDayjs.format(monthYear);

    if (!date) {
      return;
    }

    // For the FE to understand that the value is supposed to be a quarter, it has to match the defined field headers, which are "Qn YYYY", so this is translating the saved date into the column header.
    if (
      date?.slice(-5) === `${QUARTER_DATES.Q1.month}-${QUARTER_DATES.Q1.day}`
    ) {
      title = `Q1 ${dateDayjs.format("YYYY")}`;
    } else if (
      date.slice(-5) === `${QUARTER_DATES.Q2.month}-${QUARTER_DATES.Q2.day}`
    ) {
      title = `Q2 ${dateDayjs.format("YYYY")}`;
    } else if (
      date.slice(-5) === `${QUARTER_DATES.Q3.month}-${QUARTER_DATES.Q3.day}`
    ) {
      title = `Q3 ${dateDayjs.format("YYYY")}`;
    } else if (
      date.slice(-5) === `${QUARTER_DATES.Q4.month}-${QUARTER_DATES.Q4.day}`
    ) {
      title = `Q4 ${dateDayjs.format("YYYY")}`;
    }

    resultData[title] = { value, id: rv.id };
  });

  return { ...result, data: resultData };
};

// KRI input cells can be locked/disabled if the interval type doesn't match the field/column name (quarter vs monthly) or if the date of the field/column is outside the KRIs date range
export const isKriCellLocked = (
  recordVersion: RecordVersion | undefined,
  kriIntervalType: string,
  fieldName: string,
): boolean => {
  const reportingInterval: string =
    recordVersion?.data?.reporting_interval?.options?.[0];

  const startDate: string = dayjs(
    recordVersion?.data?.start_date?.value,
  ).format("YYYY-MM");
  const endDate: string =
    recordVersion?.data?.end_date?.value === undefined
      ? dayjs().add(10, "years").format("YYYY-MM") // if there is no inputted end date, set the end to 10 years from now to prevent next month's cell from disabling
      : dayjs(recordVersion?.data?.end_date?.value).format("YYYY-MM");

  const dateStringFromFieldName = isFieldNameAQuarter(fieldName)
    ? dayjs(new Date(quarterToDateString(fieldName)))
        .subtract(2, "months")
        .format("YYYY-MM")
    : dayjs(new Date(`1 ${fieldName}`)).format("YYYY-MM");

  const withinDateRange = dayjs(dateStringFromFieldName).isBetween(
    startDate,
    endDate,
    undefined,
    "[]",
  );

  const lockedByIntervalType =
    (reportingInterval === "monthly" && kriIntervalType === "quarterly") ||
    (reportingInterval === "quarterly" && kriIntervalType === "monthly");

  if (lockedByIntervalType || !withinDateRange) {
    return true;
  }
  return false;
};

export const kriFields = (fields?: Field[]) => {
  if (!fields) {
    return [];
  }

  const result = fields.filter(
    (field) =>
      KRI_HEADERS.includes(field.name) && !THRESHOLDS.includes(field.name),
  );

  return result.map((field) => {
    if (["name", "description", "reporting_interval"].includes(field.name)) {
      return { ...field, is_user_editable: false };
    }
    return field;
  });
};

// Checks for a fieldName like "Q1 2023" or "Q3 2024"
export const isFieldNameAQuarter = (fieldName: string) => {
  if (fieldName.length !== 7) {
    return false;
  }
  if (!QUARTERS.includes(fieldName.slice(0, 2))) {
    return false;
  }
  if (Number.isNaN(fieldName.slice(3, 7)) === true) {
    return false;
  }

  return true;
};

export const isFieldNameAMonth = (fieldName: string) => {
  if (fieldName.length !== 8) {
    return false;
  }
  if (!MONTHS.includes(fieldName.slice(0, 3))) {
    return false;
  }
  if (Number.isNaN(fieldName.slice(5, 8)) === true) {
    return false;
  }
  return true;
};

// Updates the width property of only the monthly or quarterly input cells
export const modifyInputCellWidths = (fields: FieldWithKriInterval[]) =>
  fields.map((field) => {
    if (field.kri_interval_type) {
      return { ...field, width: KRI_INPUT_WIDTH };
    }
    return field;
  });
