import { createTheme } from "@mui/material";

// Importing modules to ensure proper type definitions
import "../material.module";
import { components } from "./components/components";
import { palette } from "./palette";
import { typography } from "./typography";

export const baseTheme = createTheme({
  width: {
    input: "14rem",
    dialog: "30rem",
  },
  height: {
    toolbar: 50,
  },
  size: {
    icon: {
      small: 18,
      medium: 24,
      large: 28,
    },
  },
  shape: {
    borderRadius: 6,
  },
  components,
  palette,
  typography,
});
