import {
  type QueryKey,
  useQuery,
  type UseQueryOptions,
} from "@tanstack/react-query";

import type {
  ListThemisCustomAuditsForThemisRecordPathParams,
  ListThemisCustomAuditsForThemisRecordQueryResponse,
} from "@/api/gen";
import { listThemisCustomAuditsForThemisRecord } from "@/api/gen/axios/auditsController";

export const getAuditTrailQueryKey = (
  recordType: ListThemisCustomAuditsForThemisRecordPathParams["record_type"],
  recordId: ListThemisCustomAuditsForThemisRecordPathParams["record_id"],
): QueryKey => ["audits", recordType, recordId];

export const useAuditTrail = <
  TError = Error,
  TData = ListThemisCustomAuditsForThemisRecordQueryResponse["data"],
>(
  recordType: ListThemisCustomAuditsForThemisRecordPathParams["record_type"],
  recordId: ListThemisCustomAuditsForThemisRecordPathParams["record_id"],
  options?: Partial<
    UseQueryOptions<
      ListThemisCustomAuditsForThemisRecordQueryResponse["data"],
      TError,
      TData
    >
  >,
) =>
  useQuery({
    queryFn: async () => {
      const response = await listThemisCustomAuditsForThemisRecord(
        recordType,
        recordId,
      );
      return response.data;
    },
    queryKey: getAuditTrailQueryKey(recordType, recordId),
    ...options,
  });
