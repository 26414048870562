import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import {
  useLinkToCreate,
  useLinkToEditTemplate,
} from "../../../../hooks/useChecklistNavigatePath";
import closeIcon from "../../../../images/table-image/icon/close-icon2.svg";
import eyeIcon from "../../../../images/table-image/icon/eye.svg";
import plusIcon from "../../../../images/table-image/icon/plus.svg";
import starIcon from "../../../../images/table-image/icon/star-icon.svg";

interface Props {
  recordID: number;
  fieldName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleClose?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSelect?: (...args: any[]) => any;
  isCustomField?: boolean;
  modulePath?: string;
}

function SavedChecklistsSidebar({
  recordID,
  handleClose,
  handleSelect,
  fieldName,
  isCustomField,
  modulePath,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [openedChecklist, setOpenedChecklist] = useState(null);

  // Variables
  const { moduleWorkspaceID, workspaceID } = mainStore.context;
  const { checklists } = mainStore.changeManagements;
  const { templateChecklists } = mainStore.checklists;

  useEffect(() => {
    if (isCustomField) {
      mainStore.checklists.fetchChecklists(moduleWorkspaceID, fieldName);
    } else {
      mainStore.changeManagements.fetchAvailableChecklists(moduleWorkspaceID);
    }
  }, [moduleWorkspaceID, isCustomField, fieldName]);

  const checklistsList = useMemo(() => {
    if (isCustomField) {
      return templateChecklists;
    }
    return checklists;
  }, [isCustomField, checklists, templateChecklists]);

  const linkToCreate = useLinkToCreate(
    workspaceID!,
    modulePath!,
    recordID,
    Boolean(isCustomField),
    fieldName!,
  );

  // Elements
  const renderHeading = (
    <div className="heading" data-testid="checklist-select-sidebar-heading">
      <h3>Templates / Checklist</h3>
      <img src={closeIcon} alt="cm-sidebar-close" onClick={handleClose} />
    </div>
  );

  const renderCreateNew = (
    <div className="create-new-block">
      <Link to={linkToCreate}>
        <img src={plusIcon} alt="plus-icon" />
        <span>Create Checklist / Template</span>
      </Link>
    </div>
  );

  const renderDescription = (
    <div className="description">
      <img src={starIcon} alt="star-icon" />
      <span>
        Templates are reusable. Once selected you can update questions freely
        like a one-time checklist.
      </span>
    </div>
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'checklist' implicitly has an 'any' type... Remove this comment to see the full error message
  const renderChecklist = (checklist) => (
    <div
      key={checklist.id}
      data-testid="checklist-select-sidebar-item"
      className="checklist-item"
    >
      <div className="checklist-preview">
        <div className="left">
          <p>{checklist.name}</p>
          <span>{checklist.checklist_questions.length} check items</span>
        </div>
        <div className="right">
          <div
            className="img-container"
            data-testid="checklist-select-sidebar-item-open"
            onClick={() =>
              setOpenedChecklist(
                openedChecklist === checklist.id ? null : checklist.id,
              )
            }
          >
            <img
              src={openedChecklist === checklist.id ? closeIcon : eyeIcon}
              alt="eye"
            />
          </div>
          <Link
            to={useLinkToEditTemplate(
              workspaceID!,
              modulePath!,
              checklist.id,
              Boolean(isCustomField),
              fieldName!,
            )}
          >
            Edit
          </Link>
          <button
            type="button"
            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onClick={() => handleSelect(checklist.id)}
            data-testid="checklist-select-sidebar-item-submit"
          >
            Select
          </button>
        </div>
      </div>
      {openedChecklist === checklist.id && (
        <div
          className="checklist-questions"
          data-testid="checklist-select-sidebar-item-questions"
        >
          {/* @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type. */}
          {checklist.checklist_questions.map((item) => (
            <p key={item.id}>
              {isCustomField
                ? item.question?.name
                : item.new_product_question?.name}
            </p>
          ))}
        </div>
      )}
    </div>
  );

  return (
    <section
      data-testid="checklist-select-sidebar"
      className="checklists-sidebar"
    >
      {renderHeading}
      {renderCreateNew}
      {renderDescription}
      {checklistsList.map(renderChecklist)}
    </section>
  );
}

SavedChecklistsSidebar.defaultProps = {
  isCustomField: false,
  handleClose: () => {},
  handleSelect: () => {},
};

export default observer(SavedChecklistsSidebar);
