import type { Account } from "@themis/api/gen/models/account";
import { getCommentsCellColumnDef } from "@themis/shared/utils/get-comments-cell-column-def";
import { actionsColumnDefParams } from "@themis/ui-library/components/data-grid/column-defs/actions-column-def-params";
import type { ColDef, ValueFormatterParams } from "ag-grid-community";
import type { CustomCellRendererProps } from "ag-grid-react";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { generatePath } from "react-router-dom";

import { useCompany } from "../../../api/queries/companies";
import { useLayoutFields } from "../../../api/utils/fields/use-layout-fields";
import { getDynamicTableColumns } from "../../../utils/table/get-dynamic-table-columns/get-dynamic-table-columns";
import { AccountActionsCell } from "../components/AccountsTable/AccountActionsCell";
import { AccountContactCell } from "../components/AccountsTable/AccountContactCell";
import { routes } from "../routes";

interface UseAccountsTableColumnsParams {
  workspaceId: number;
}

export function useAccountsTableColumns({
  workspaceId,
}: UseAccountsTableColumnsParams) {
  const { formatMessage } = useIntl();

  const { data: companyResult } = useCompany("current");

  const {
    layoutFields = [],
    isPending: isColumnsPending,
    isError: isColumnsError,
  } = useLayoutFields({
    companyId: Number(companyResult?.data.company.id),
    recordType: "accounts",
    workspaceId,
    layoutType: "table",
  });

  const columnOverrides: Partial<Record<keyof Account, ColDef<Account>>> =
    useMemo(
      () => ({
        name: {
          editable: false,
          pinned: "left",
          lockPosition: "left",
          cellRenderer: "identifierCellRenderer",
          cellRendererParams: (props: CustomCellRendererProps) => {
            if (!props.data?.id) {
              return null;
            }

            return {
              to: generatePath(routes.accounts.detail.recordView, {
                workspace_id: workspaceId,
                accountId: props.data.id,
              }),
              ...props,
            };
          },
        },
        account_types: {
          cellRendererParams: {
            colorIndexFn: (key: string | undefined, index: number) => index,
          },
          valueFormatter: ({
            value,
          }: ValueFormatterParams<Account, string[]>) =>
            value?.join(", ") ?? "",
        },
        parent: {
          cellRendererParams: (props: CustomCellRendererProps<Account>) => {
            const value: string =
              props.value?.name || formatMessage({ defaultMessage: "N/A" });
            return { value };
          },
          valueFormatter: ({ value }) =>
            value?.name || formatMessage({ defaultMessage: "N/A" }),
        },
        main_contact: {
          cellStyle: () => ({ paddingLeft: "8px" }),
          cellRenderer: (props: CustomCellRendererProps) =>
            props.value ? (
              <AccountContactCell contact={props.value} display="name" />
            ) : null,
        },
      }),
      [formatMessage, workspaceId],
    );

  const columns = useMemo(() => {
    const additionalColumns: (ColDef<Account> & { order?: number })[] = [
      getCommentsCellColumnDef("accounts", "comments_count"),
      {
        ...actionsColumnDefParams,
        width: 42,
        cellRenderer: (props: CustomCellRendererProps) => {
          if (!props.data) {
            return null;
          }

          return <AccountActionsCell account={props.data} />;
        },
      },
    ];

    return getDynamicTableColumns<Account>({
      recordType: "accounts",
      layoutFields,
      columnOverrides,
      additionalColumns,
    });
  }, [columnOverrides, formatMessage, layoutFields, workspaceId]);

  return {
    columns,
    isColumnsPending,
    isColumnsError,
  };
}
