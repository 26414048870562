import { Stack } from "@themis/ui";
import { noop } from "lodash";
import { useIntl } from "react-intl";

import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import DashboardContent from "@/components/shared/DashboardContent/dashboard-content";
import DashboardContentWrapper from "@/components/shared/DashboardContentWrapper";
import DashboardHeader from "@/components/shared/DashboardHeader";
import HeadSelect from "@/components/table/shared/HeadSelect";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import PoliciesTabHeader from "../../components/PoliciesTabHeader";
import { PoliciesLibraryDescription } from "./policies-library-description/policies-library-description";
import { PoliciesLibraryTable } from "./policies-library-table/policies-library-table";

export default function PoliciesLibraryPage() {
  const { formatMessage } = useIntl();

  const workspaceId = useRouteWorkspaceId();

  return (
    <DashboardContent>
      <DashboardHeader
        title={formatMessage({ defaultMessage: "Themis Policies Library" })}
        LeftActionBar={<ViewModuleUsers />}
        RightActionBar={<HeadSelect />}
      />

      <DashboardContentWrapper>
        <PoliciesTabHeader
          tableName="Hello"
          moduleWorkspaceID={workspaceId}
          openImportSlideOut={noop}
          isShowExport={false}
        />

        <Stack spacing="4" className="tw-p-4">
          <PoliciesLibraryDescription />

          <PoliciesLibraryTable />
        </Stack>
      </DashboardContentWrapper>
    </DashboardContent>
  );
}
