import { faker } from "@faker-js/faker";

import type { FieldOption } from "../models/FieldOption";

export function createFieldOption(
  data: NonNullable<Partial<FieldOption>> = {},
): NonNullable<FieldOption> {
  return {
    ...{
      id: faker.number.int(),
      key: faker.string.alpha(),
      value: faker.string.alpha(),
      position: faker.number.int(),
      meta: {},
      custom: faker.datatype.boolean(),
    },
    ...data,
  };
}
