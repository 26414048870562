import { HeaderTabs } from "@themis/ui";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { PiWarning } from "react-icons/pi";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";

import { getSelectedTab } from "@/components/helpers/Tabs";
import CommentsSlideMenu from "@/components/table/shared/comments/CommentsSlideMenu";

import ExportBulk from "../../../components/dashboard/ExportBulk";
import ImportBulk from "../../../components/dashboard/ImportBulk";
import { useMainStore } from "../../../contexts/Store";

interface Props {
  isInternal?: boolean;
  recordVersionID: number;
  showProceduresTab: boolean;
}

function TestDetailsHeader({
  recordVersionID,
  isInternal,
  showProceduresTab,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const { workspaceID } = mainStore.context;

  // Hooks
  const location = useLocation();

  const isRecordViewMatch = Boolean(
    useRouteMatch({
      path: `/workspaces/${workspaceID}/modules/qa-tests-development/${recordVersionID}`,
      exact: true,
    }),
  );

  const recordVersion =
    isRecordViewMatch &&
    (mainStore.recordVersions.list.find(
      (rv) => rv.id === Number(recordVersionID),
    ) ||
      mainStore.recordVersions.current);

  const warningImage = [
    {
      tabIndex: 1, // "Procedure Steps" index
      button: <PiWarning size="20" color="#6F73F7" />,
    },
  ];

  useEffect(() => {
    mainStore.recordVersions.resetList();

    if (recordVersionID) {
      (async () => {
        await mainStore.qa.testProcedureSteps(recordVersionID);
      })();
    }
  }, [recordVersionID, location]);

  const TABS = [
    {
      name: "Record View",
      key: "record",
      value: `/workspaces/${workspaceID}/modules/qa-tests-development/${recordVersionID}`,
    },
    {
      name: "Procedure Steps",
      key: "procedures",
      value: `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/procedures`,
      hasPermission: isInternal || showProceduresTab,
      dataTestId: "qa-procedures-tab",
    },
    {
      name: "Findings",
      key: "findings",
      value: `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/findings`,
      dataTestId: "qa-findings-tab",
    },
    {
      name: "Documents",
      key: "linked_documents",
      value: `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/linked_documents`,
    },
    {
      name: "Samples",
      key: "samples",
      value: `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/samples`,
      dataTestId: "samples-tab",
    },
  ];

  return (
    <div
      className="table-header-wrap no-scroll test-details-header"
      data-testid="qa-test-details-header"
    >
      <HeaderTabs
        tabs={TABS}
        selectedTab={getSelectedTab(TABS)?.value || ""}
        isLink
        tabButtons={showProceduresTab ? warningImage : []}
      />
      <div className="import-export-buttons-container">
        <Switch>
          <Route
            exact
            path={[
              `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/record`,
              `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/procedures`,
              `/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/samples`,
            ]}
          >
            <>
              <ExportBulk
                parentRecordVersionID={Number(recordVersionID)}
                subModuleDownload
              />
              <ImportBulk
                subModuleDownload
                parentRecordVersionID={Number(recordVersionID)}
              />
            </>
          </Route>
          <Route
            exact
            path={`/workspaces/${workspaceID}/modules/qa-tests/${recordVersionID}/linked_documents`}
          />
        </Switch>
        {isRecordViewMatch && recordVersion && (
          <CommentsSlideMenu
            renderCommentsButton
            globalID={recordVersion.global_id}
            recordID={recordVersion.record_id}
            uncompletedCommentsCount={recordVersion.uncompleted_comments_count}
            taskableType="Record"
          />
        )}
      </div>
    </div>
  );
}

export default observer(TestDetailsHeader);
