import { UserLabel } from "@themis/ui-library/components/data-display/user-label/user-label";
import type { ColDef, ValueFormatterParams } from "ag-grid-community";
import type { CustomCellRendererProps } from "ag-grid-react";
import dayjs from "dayjs";
import { capitalize } from "lodash";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import type { Audit } from "@/api/gen/models/Audit";

export const useActivityTableColumns = () => {
  const { formatMessage } = useIntl();

  return useMemo(
    (): ColDef[] => [
      {
        headerName: formatMessage({ defaultMessage: "Action" }),
        colId: "action",
        field: "action",
        minWidth: 150,
        flex: 1,
        valueFormatter: ({ value }: ValueFormatterParams) => capitalize(value),
      },
      {
        headerName: formatMessage({ defaultMessage: "User" }),
        colId: "created_at",
        field: "user",
        minWidth: 200,
        flex: 1,
        cellStyle: { padding: "0 8px" },
        cellRenderer: UserLabel,
        cellRendererParams: (
          props: CustomCellRendererProps<Audit, Audit["user"]>,
        ) => ({
          initials: props.data?.user?.initials,
          colorSeed: props.data?.user?.icon_color_index,
          fullName: props.data?.user?.full_name,
          ...props,
        }),
      },
      {
        headerName: formatMessage({ defaultMessage: "Activity" }),
        colId: "description",
        field: "description",
        minWidth: 300,
        flex: 1,
      },
      {
        headerName: formatMessage({ defaultMessage: "Time" }),
        colId: "created_at",
        field: "created_at",
        minWidth: 150,
        flex: 1,
        valueFormatter: (params: { value: string }) =>
          dayjs(params.value).format("MM/DD/YYYY h:mm A"),
      },
    ],
    [formatMessage],
  );
};
