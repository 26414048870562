import "./risk-methodology-builder.scss";

import classnames from "classnames";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";

import { Flex, Typography } from "@/components/Elements";

import { useMainStore } from "../../../../../contexts/Store";

interface ScoringRangeProps {
  lowRange: number;
  highRange: number;
  setLowRange: (value: number) => void;
  setHighRange: (value: number) => void;
  dataTestID?: string;
}

const MAX_CHAR = 4;

function SectionRange({
  lowRange,
  highRange,
  setHighRange,
  setLowRange,
  dataTestID,
}: ScoringRangeProps) {
  const mainStore = useMainStore();

  const inputClassess = (isDisabled: boolean) =>
    classnames("section-value-input", {
      "section-value-input__disabled": isDisabled,
      "ra-risk-rating-error": !isDisabled && errorText.length,
    });

  const [clonedHighRange, setClonedHighRange] = useState<number | string>(
    highRange,
  );

  const [clonedLowRange, setClonedLowRange] = useState<number | string>(
    lowRange,
  );

  const [lastValidHighValue, setLastValidHighValue] =
    useState<number>(highRange);

  const [lastValidLowValue, setLastValidLowValue] = useState<number>(highRange);

  const [errorText, setErrorText] = useState("");

  const handleChangeHighValue = (val: string) => {
    if (val.length > MAX_CHAR) {
      return;
    }

    // only accept blank value or whole numbers
    if (isNaN(Number(val))) {
      return;
    }

    setClonedHighRange(val);
    const parsedValue = parseFloat(val);
    if (!Number.isNaN(parsedValue) && parsedValue > lowRange) {
      setLastValidHighValue(parsedValue);
    }
  };

  const handleChangeLowValue = (val: string) => {
    if (isNaN(Number(val))) {
      return;
    }

    setClonedLowRange(val);

    const parsedValue = parseFloat(val);
    if (
      !Number.isNaN(parsedValue) &&
      parsedValue < highRange &&
      parsedValue >= 0 &&
      parsedValue <= 1
    ) {
      setLastValidLowValue(parsedValue);
    }
  };

  const handleBlurHighValue = () => {
    const parsedValue = parseFloat(clonedHighRange as string);
    setErrorText("");

    if (Number.isNaN(parsedValue)) {
      setClonedHighRange(lastValidHighValue);
      setErrorText("The high end of the range cannot be blank");
    }

    if (parsedValue <= lowRange) {
      setClonedHighRange(lastValidHighValue);
      setErrorText(
        "The high end of the range cannot be lower than or equal to the low end",
      );
    }

    setHighRange(lastValidHighValue);
  };

  const handleBlurLowValue = () => {
    const parsedValue = parseFloat(clonedLowRange as string);
    setErrorText("");

    if (Number.isNaN(parsedValue)) {
      setClonedLowRange(lastValidLowValue);
      setErrorText("The low end of the range cannot be blank");
    }

    if (parsedValue >= highRange) {
      setClonedLowRange(lastValidLowValue);
      setErrorText(
        "The low end of the range cannot be higher than or equal to the high end",
      );
    }

    if (parsedValue < 0 || parsedValue > 1) {
      setClonedLowRange(lastValidLowValue);
      setErrorText(
        "The low end of the range cannot be lower than 0 or higher than 1",
      );
    }

    setLowRange(lastValidLowValue);
  };

  useEffect(() => {
    if (clonedHighRange !== highRange) {
      setClonedHighRange(highRange);
    }
    if (clonedLowRange !== lowRange) {
      setClonedLowRange(lowRange);
    }
  }, [highRange, lowRange]);

  useEffect(() => {
    if (errorText.length === 0) {
      return;
    }

    mainStore.toast.setErrorText(errorText);
  }, [errorText]);

  return (
    <Flex justifySpaceBetween fullWidth data-testid={dataTestID}>
      <Typography
        label="Section Range"
        color="generalMidnightDark"
        size="lg"
        weight="semiBold"
        data-testid={`${dataTestID}-title`}
      />
      <div className="section-range">
        <span className="section-range-values">
          <span className="section-range-label">Low</span>
          <input
            className={inputClassess(false)}
            onChange={(e) => handleChangeLowValue(e.target.value)}
            onBlur={handleBlurLowValue}
            value={clonedLowRange}
            data-testid={`${dataTestID}-low-range`}
          />
          <label
            htmlFor="high-range-input"
            className="section-range-label section-range-label__dash"
          >
            - High
          </label>
          <input
            id="high-range-input"
            className={inputClassess(false)}
            onChange={(e) => handleChangeHighValue(e.target.value)}
            onBlur={handleBlurHighValue}
            value={clonedHighRange}
            data-testid={`${dataTestID}-high-range`}
          />
        </span>
      </div>
    </Flex>
  );
}

export default observer(SectionRange);
