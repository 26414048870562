import { useHistory, useLocation } from "react-router-dom";

export function useHash(): [string, (newHash: string) => void] {
  const history = useHistory();
  const { hash, search } = useLocation();

  const setHash = (newHash: string) => {
    // update hash while keeping search params
    history.push({ hash: newHash, search });
  };

  return [hash, setHash];
}
