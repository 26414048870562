import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";

export default class ModuleConfigurationsStore {
  mainStore: MainStore;

  current = {};

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      current: observable,

      setCurrent: action,
      updateModuleConfiguration: action,
    });

    this.mainStore = mainStore;
  }

  // GET /api/react/module_workspaces/:id/module_configuration
  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleWorkspaceID' implicitly has an 'a... Remove this comment to see the full error message
  async fetch(moduleWorkspaceID) {
    try {
      const { data } = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/module_configuration`,
        headers: this.mainStore.getHeaders(),
      });

      this.setCurrent(data.module_configuration);
      this.updateModuleConfiguration(data.module_configuration);

      return true;
    } catch (error) {
      window.console.log(`"ModuleConfiguration#fetch" error ${error}`);

      return false;
    }
  }

  // PUT/PATCH /api/react/module_configuration/:id
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  async update(id, params) {
    const moduleConfigurationParams = {
      module_configuration: params,
    };

    try {
      const { data } = await legacyApi({
        method: "PUT",
        url: `${API_URL}/module_configurations/${id}`,
        headers: this.mainStore.getHeaders(),
        data: moduleConfigurationParams,
      });

      const moduleConfiguration = data?.module_configuration;
      if (moduleConfiguration) {
        this.updateModuleConfiguration(moduleConfiguration);
      }
    } catch (error) {
      window.console.log(`"ModuleConfiguration#update" error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setCurrent(value) {
    this.current = value || {};
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleConfiguration' implicitly has an ... Remove this comment to see the full error message
  updateModuleConfiguration(moduleConfiguration) {
    this.mainStore.moduleWorkspaces.list = [
      ...this.mainStore.moduleWorkspaces.list.map((moduleWorkspace) =>
        moduleWorkspace.id === moduleConfiguration.module_workspace_id
          ? { ...moduleWorkspace, module_configuration: moduleConfiguration }
          : moduleWorkspace,
      ),
    ];
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  cleanup() {}
}
