import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import { LONG_API_FORMAT, SHORT_API_FORMAT } from "@/components/constants";

dayjs.extend(utc);

export const stringToDate = (value?: dayjs.ConfigType | null) => {
  if (!value) {
    return null;
  }

  const dateCandidate = dayjs(value, SHORT_API_FORMAT);
  if (dateCandidate.isValid()) {
    return dateCandidate.toDate();
  }

  return null;
};

export const stringToDateAndTime = (value?: dayjs.ConfigType) =>
  value ? dayjs.utc(value, LONG_API_FORMAT).toDate() : null;

export const stringToDateAndTimeSSSZ = (value?: dayjs.ConfigType) =>
  value ? dayjs.utc(value).toDate() : null;

export const stringToTimestamp = (value?: dayjs.ConfigType) =>
  value ? dayjs.utc(value, "YYYY-MM-DDTHH:mm:ss.000").toDate() : null;

export const formatDate = (
  date?: Date | null,
  options?: Record<string, string>,
) => {
  if (date) {
    return date.toLocaleDateString(
      "en-US",
      options ?? {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
    );
  }
  return null;
};

export const formatDateToTheSecond = (date?: Date | null) => {
  if (date) {
    return date.toLocaleDateString("en-US", {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });
  }
  return null;
};

export const formatDateForCellValue = (date?: Date, otherFormat?: boolean) => {
  if (date) {
    const yyyy = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      date,
    );
    const mm = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
    const dd = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    return otherFormat ? `${mm}/${dd}/${yyyy}` : `${yyyy}-${mm}-${dd}`;
  }
  return null;
};

export const formatDateAndTime = (date?: Date | null) => {
  if (date) {
    const yyyy = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      date,
    );
    const mm = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
    const dd = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    const time = new Intl.DateTimeFormat("en", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
    return `${time.toLowerCase()} ${mm}/${dd}/${yyyy}`;
  }
  return null;
};

export const formatTimeAfterDate = (date?: Date | null) => {
  if (date) {
    const yyyy = new Intl.DateTimeFormat("en", { year: "numeric" }).format(
      date,
    );
    const mm = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(date);
    const dd = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    const time = new Intl.DateTimeFormat("en", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    }).format(date);
    return `${mm}/${dd}/${yyyy} ${time.toLowerCase()}`;
  }
  return null;
};

export const formatTimeAfterLongDate = (
  date: Date | null,
  options: {
    timeZone?: string;
    removeAt?: boolean;
    monthFormat?: Intl.DateTimeFormatOptions["month"];
  } = {},
) => {
  const { timeZone, removeAt, monthFormat } = options;

  if (date) {
    const monthName = new Intl.DateTimeFormat("en", {
      month: monthFormat || "long",
      timeZone,
    }).format(date);
    const dd = new Intl.DateTimeFormat("en", {
      day: "2-digit",
      timeZone,
    }).format(date);
    const yyyy = new Intl.DateTimeFormat("en", {
      year: "numeric",
      timeZone,
    }).format(date);
    const time = new Intl.DateTimeFormat("en", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
      timeZone,
    }).format(date);
    return `${monthName} ${dd}, ${yyyy}, ${removeAt ? "" : "at "}${time}`;
  }
  return null;
};

export const differenceBetweenDates = (
  startDate?: number,
  endDate?: number,
  defaultValue?: number,
) => {
  if (!startDate || !endDate) {
    return defaultValue;
  }
  let dayDifference = 0;
  const timeDifference = endDate - startDate;
  if (timeDifference >= 0) {
    dayDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  }

  return dayDifference;
};
