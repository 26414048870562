import { faker } from "@faker-js/faker";

import type {
  RecordsByFieldOption200,
  RecordsByFieldOptionPathParams,
  RecordsByFieldOptionQueryParams,
  RecordsByFieldOptionQueryResponse,
} from "../models/RecordsByFieldOption";
import { createRecordTypePath } from "./createRecordTypePath";

export function createRecordsByFieldOptionPathParams(): NonNullable<RecordsByFieldOptionPathParams> {
  return {
    company_id: faker.number.int(),
    record_type: createRecordTypePath(),
  };
}

export function createRecordsByFieldOptionQueryParams(): NonNullable<RecordsByFieldOptionQueryParams> {
  return {
    workspace_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
    field_name: faker.string.alpha(),
    from_date: faker.string.alpha(),
    to_date: faker.string.alpha(),
  };
}

/**
 * @description A JSON object containing the counts of the records grouped by the specified field option
 */
export function createRecordsByFieldOption200(): NonNullable<RecordsByFieldOption200> {
  return { data: {} };
}

/**
 * @description A JSON object containing the counts of the records grouped by the specified field option
 */
export function createRecordsByFieldOptionQueryResponse(): NonNullable<RecordsByFieldOptionQueryResponse> {
  return { data: {} };
}
