import { Breadcrumbs } from "@themis/ui";
import React from "react";
import { generatePath, useParams } from "react-router-dom";

import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";

import { routes } from "../../routes";
import { NewChildAccountInfo } from "./NewChildAccountInfo";

export function NewChildAccountDetails() {
  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    accountId: string;
  }>();

  return (
    <PageLayout>
      <Header
        title={
          <Breadcrumbs
            breadcrumbItems={[
              {
                label: "Accounts",
                to: generatePath(routes.accounts.detail.recordView, {
                  workspace_id,
                  accountId,
                }),
              },
              {
                label: "Untitled",
              },
            ]}
          />
        }
      />
      <PageContent>
        <NewChildAccountInfo />
      </PageContent>
    </PageLayout>
  );
}
