import type { CompanyUser } from "@themis/api/gen/models/companyUser";
import { TableTooltip } from "@themis/ui";
import { Avatar } from "@themis/ui-library/components/data-display/avatar/avatar";
import { UserLabel } from "@themis/ui-library/components/data-display/user-label/user-label";
import { useDetectOverflow } from "@themis/ui-library/hooks/use-detect-overflow/use-detect-overflow";
import { keyBy } from "lodash";

import { useCompany } from "@/api/queries/companies";
import { useCompanyUsers } from "@/api/queries/users/use-company-users";

import type { BaseDynamicValueProps } from "../../base-dynamic-value";
import type { GetReferenceType } from "../../field-configuration-types";

type ReferenceType = GetReferenceType<"User">;

export const UserValue = ({ value }: BaseDynamicValueProps<ReferenceType>) => {
  const { data: company } = useCompany("current");
  const { isOverflowing, measureElement, visibleChildCount } =
    useDetectOverflow();

  const { data: usersMap = {} } = useCompanyUsers(
    { companyId: company?.data.company.id },
    {
      select: (data) => keyBy(data, "id"),
      enabled: !!value,
    },
  );

  const options = (Array.isArray(value) ? value : [value])
    .map((item) => (item ? usersMap[item] : undefined))
    .filter((item) => item !== undefined) as CompanyUser[];

  const isMultiple = Array.isArray(value);

  if (!value) {
    return null;
  }

  const notVisibleChildCount = options.length - visibleChildCount;

  const renderTags = (limit?: number) => {
    if (!isMultiple) {
      const [user] = options;
      if (user) {
        return (
          <UserLabel
            fullName={user.full_name ?? ""}
            initials={user.initials}
            colorSeed={user.icon_color_index}
          />
        );
      }

      return null;
    }

    return (
      <>
        {options.slice(0, limit).map((user) => (
          <Avatar key={user.id} colorSeed={user.icon_color_index} size="lg">
            {user.initials}
          </Avatar>
        ))}
      </>
    );
  };

  const tooltipContent = (
    <div className="tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-font-normal">
      {options.map((user) => (
        <UserLabel
          key={user.id}
          fullName={user.full_name ?? ""}
          initials={user.initials}
          colorSeed={user.icon_color_index}
        />
      ))}
    </div>
  );

  return (
    <div className="tw-relative tw-w-full">
      <TableTooltip content={tooltipContent} disabled={!isOverflowing}>
        <div className="tw-flex tw-w-full tw-flex-nowrap tw-gap-1 tw-overflow-hidden tw-overflow-ellipsis tw-text-nowrap tw-font-normal">
          {renderTags(visibleChildCount)}

          {notVisibleChildCount > 0 && (
            <Avatar size="lg">+{notVisibleChildCount}</Avatar>
          )}
        </div>
      </TableTooltip>

      {/* Invisible element used to calculate how many items can be seen */}
      <div
        className="tw-pointer-events-none tw-absolute tw-left-0 tw-right-8 tw-top-0 tw-flex tw-flex-nowrap tw-gap-1 tw-overflow-hidden tw-overflow-ellipsis tw-text-nowrap tw-font-normal"
        style={{ visibility: "hidden" }}
        ref={measureElement}
      >
        {renderTags()}
      </div>
    </div>
  );
};
