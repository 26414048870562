import type { VariantProps } from "cva";
import { cva } from "cva";
import * as React from "react";

import { cn } from "../../lib/utils";

const defaultStyles =
  "tw-flex tw-w-full tw-box-border tw-resize-y tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-bg-transparent tw-transition-colors";
const fontStyles = "tw-text-sm tw-font-medium tw-font-sans tw-text-neutral-500";
const placeHolderStyles =
  "placeholder:tw-font-medium placeholder:tw-text-sm placeholder:tw-text-neutral-200 placeholder:tw-font-sans placeholder:tw-opacity-100";
const disabledStyles = "disabled:tw-cursor-not-allowed disabled:tw-opacity-50";
const focusStyles =
  "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300";
const readOnlyStyles =
  "read-only:tw-bg-primaryDim-25 read-only:tw-border-primaryDim-25 read-only:tw-text-neutral-300 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
const lockedStyles =
  "read-only:tw-bg-primary-25 read-only:tw-border-primaryDim-25 read-only:tw-text-primaryDim-300 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";

const textAreaVariants = cva({
  base: [
    defaultStyles,
    fontStyles,
    placeHolderStyles,
    disabledStyles,
    focusStyles,
  ],
  variants: {
    size: {
      sm: "tw-min-h-9 tw-px-3 tw-py-[0.4375rem]",
      md: "tw-min-h-24 tw-p-3",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  VariantProps<typeof textAreaVariants> & {
    readOnly?: boolean;
    locked?: boolean;
    placeholder?: string;
    value?: string | number | null | undefined;
  };

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    { locked, readOnly, placeholder = "Type here", className, size, ...props },
    ref,
  ) => {
    return (
      <textarea
        className={cn(
          textAreaVariants({ size }),
          {
            [lockedStyles]: locked,
            [readOnlyStyles]: readOnly,
          },
          className,
        )}
        readOnly={locked || readOnly}
        placeholder={`- ${placeholder} -`}
        ref={ref}
        {...props}
      />
    );
  },
);
TextArea.displayName = "TextArea";

export { TextArea };
