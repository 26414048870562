import React, { useRef } from "react";
import type { IconType } from "react-icons";
import { PiArrowDown, PiArrowUp, PiPlusBold } from "react-icons/pi";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";

import { cn } from "../../lib/utils";
import { TableRow } from "./TableRow";
import type {
  DataWithIdAndCustomFields,
  PreviewTableColumn,
  Sort,
} from "./types";

/**
 * @returns A container for the table that sets the width of the columns
 */
function TableContainer<R extends DataWithIdAndCustomFields>({
  children,
  columns,
  responsive,
  hasRowActions,
}: {
  children: React.ReactNode;
  columns: PreviewTableColumn<R>[];
  responsive?: boolean;
  hasRowActions?: boolean;
}) {
  const columnWidths = columns.map(({ width, type }) => {
    if (type === "actions") {
      return `auto`;
    }

    return `${width}${responsive ? "fr" : "px"}`;
  });

  if (hasRowActions) {
    columnWidths.push("auto");
  }

  return (
    <div
      role="grid"
      style={{
        gridTemplateColumns: columnWidths.join(" "),
      }}
      className={cn("tw-grid tw-overflow-auto", {
        "tw-w-full": responsive,
      })}
    >
      {children}
    </div>
  );
}

function HeaderCellContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-sticky tw-top-0 tw-z-[10] tw-bg-neutral-25">
      {children}
    </div>
  );
}

function TableHeaderCell({
  children,
  Icon,
  isIdColumn,
  isRowStart,
  isRowEnd,
  sortDirection,
  onClick,
}: {
  children: string;
  Icon?: IconType;
  isIdColumn?: boolean;
  isRowStart?: boolean;
  isRowEnd?: boolean;
  sortDirection?: "asc" | "desc" | null;
  onClick?: () => void;
}) {
  return (
    <HeaderCellContainer>
      <div
        onClick={onClick}
        className={cn(
          "tw-box-border tw-flex tw-h-8 tw-items-center tw-gap-1.5 tw-text-nowrap tw-px-2 tw-text-sm tw-font-medium",
          {
            "tw-border-l-solid tw-rounded-l tw-border-l": isRowStart,
            "tw-rounded-r tw-border-r": isRowEnd,
            "tw-mr-1 tw-rounded tw-bg-blue-1 tw-text-blue-4": isIdColumn,
            "tw-border-x-0 tw-border-y tw-border-solid tw-border-neutral-100 tw-bg-neutral-25 tw-text-neutral-300":
              !isIdColumn,
            "tw-cursor-pointer hover:tw-bg-neutral-50": onClick,
          },
        )}
      >
        {Icon && <Icon className="tw-h-4 tw-w-4" />}
        {children}
        {sortDirection === "asc" && (
          <PiArrowUp className="tw-ml-auto tw-h-4 tw-w-4" />
        )}
        {sortDirection === "desc" && (
          <PiArrowDown className="tw-ml-auto tw-h-4 tw-w-4" />
        )}
      </div>
    </HeaderCellContainer>
  );
}

/**
 * @returns A gap between the last header and the right side of the table
 */
const LastHeaderGap = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="tw-sticky tw-right-0 tw-top-0 tw-z-10 tw-bg-neutral-25"
    />
  );
});

LastHeaderGap.displayName = "LastHeaderGap";

/**
 * @returns A gap between the header and the first row
 */
function GridRowGap() {
  return <div className="tw-col-span-full tw-h-1 tw-bg-neutral-25" />;
}

const HeaderBottomGap = React.forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="tw-sticky tw-top-8 tw-z-10 tw-col-span-full tw-h-1 tw-bg-neutral-25"
    />
  );
});

HeaderBottomGap.displayName = "HeaderBottomGap";

export interface PreviewTableProps<R extends DataWithIdAndCustomFields> {
  addRow?: {
    text: string;
    linkTo: LinkProps["to"];
  };
  columns: PreviewTableColumn<R>[];
  columnIconOverrides?: Partial<Record<keyof R, IconType>>;
  customFieldColumnIconOverrides?: Partial<Record<string, IconType>>;
  componentOverrides?: Partial<Record<keyof R, (data: R) => React.ReactNode>>;
  customFieldComponentOverrides?: Partial<
    Record<string, (data: R) => React.ReactNode>
  >;
  responsive?: boolean;
  rows?: R[];
  rowActions?: (data: R) => React.ReactNode;
  sort?: Sort<R>;
  onSortChange?: (sort: Sort<R> | null) => void;
  headerDisplay?: boolean;
  customCellClassName?: string;
  customRowClassName?: string;
}

export function PreviewTable<R extends DataWithIdAndCustomFields>({
  addRow,
  columns,
  columnIconOverrides,
  customFieldColumnIconOverrides,
  componentOverrides,
  customFieldComponentOverrides,
  responsive,
  rows = [],
  rowActions,
  sort,
  onSortChange = () => {},
  headerDisplay = true,
  customCellClassName,
  customRowClassName,
}: PreviewTableProps<R>) {
  const lastHeaderGapRef = useRef<HTMLDivElement>(null);
  const headerBottomGapRef = useRef<HTMLDivElement>(null);

  const orderAwareColumns: PreviewTableColumn<R>[] = columns.map(
    (column, index) => {
      if (column.type === "actions" || column.type === "id") {
        return column;
      }

      let isRowStart = false;

      if (index === 0) {
        isRowStart = true;
      } else if (index === 1 && columns[0].type === "id") {
        isRowStart = true;
      }

      let isRowEnd = false;

      if (index === columns.length - 1) {
        isRowEnd = true;
      } else if (
        index === columns.length - 2 &&
        columns[columns.length - 1].type === "actions"
      ) {
        isRowEnd = true;
      }

      return {
        ...column,
        isRowStart,
        isRowEnd,
      };
    },
  );

  function handleSortChange(
    columnKey: keyof R | keyof R["custom_fields"] | undefined,
  ) {
    if (!columnKey) {
      return;
    }

    if (sort === undefined || sort.columnKey !== columnKey) {
      onSortChange({ columnKey, direction: "asc" });
      return;
    }

    onSortChange(
      sort.direction === "asc" ? { columnKey, direction: "desc" } : null,
    );
  }

  return (
    <>
      <TableContainer
        columns={orderAwareColumns}
        responsive={responsive}
        hasRowActions={!!rowActions}
      >
        {headerDisplay && (
          <>
            {orderAwareColumns.map(
              ({
                title,
                isRowStart,
                isRowEnd,
                type,
                Icon,
                key,
                isCustomColumn,
              }) => {
                function getIcon() {
                  if (isCustomColumn && key) {
                    const customFieldColumnIconOverride =
                      customFieldColumnIconOverrides?.[key as string];

                    return customFieldColumnIconOverride || Icon;
                  }

                  const columnIconOverride =
                    columnIconOverrides?.[key as keyof R];

                  return columnIconOverride || Icon;
                }

                return type === "actions" ? (
                  <LastHeaderGap key="actions" ref={lastHeaderGapRef} />
                ) : (
                  <TableHeaderCell
                    key={title}
                    Icon={getIcon()}
                    isIdColumn={type === "id"}
                    isRowStart={isRowStart}
                    isRowEnd={isRowEnd}
                    sortDirection={
                      sort !== undefined && sort.columnKey === key
                        ? sort.direction
                        : undefined
                    }
                    onClick={key && (() => handleSortChange(key))}
                  >
                    {title}
                  </TableHeaderCell>
                );
              },
            )}
            {rowActions && <LastHeaderGap ref={lastHeaderGapRef} />}
            <HeaderBottomGap ref={headerBottomGapRef} />
          </>
        )}
        {rows.map((row, index) => (
          <React.Fragment key={row.id}>
            {Boolean(index || addRow) && <GridRowGap />}
            <TableRow
              row={row}
              rowActions={rowActions}
              columns={orderAwareColumns}
              customCellClassName={customCellClassName}
              customRowClassName={customRowClassName}
              componentOverrides={componentOverrides}
              customFieldComponentOverrides={customFieldComponentOverrides}
            />
          </React.Fragment>
        ))}
      </TableContainer>
      {addRow && (
        <Link to={addRow.linkTo} className="tw-cursor-auto">
          <div
            className="tw-sticky tw-left-0 tw-ml-0 tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
            data-testid="add-new-record-button"
          >
            <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
            <div className="tw-neutral-300 tw-text-sm">{addRow.text}...</div>
          </div>
        </Link>
      )}
    </>
  );
}
