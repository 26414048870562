import { http } from "msw";

import { createLegacyWebsiteMonitoringGroupResultQueryResponse } from "../createLegacyWebsiteMonitoringGroupResult";

export const legacyWebsiteMonitoringGroupResultHandler = http.get(
  "*/website_monitoring/:monitoring_group_result_id/monitoring_group_result",
  function handler(info) {
    return new Response(
      JSON.stringify(createLegacyWebsiteMonitoringGroupResultQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
