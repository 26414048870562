import type { IGetRowsParams } from "ag-grid-community";
import { useMemo } from "react";

import type { DataGridProps } from "../data-grid";
import type { DataGridState } from "../state/data-grid-state";
import type { UseDataGridBindingParams } from "./use-data-grid-binding";
import { useDataGridBinding } from "./use-data-grid-binding";

export type UseServerDataGridBindingParams<T extends object> =
  UseDataGridBindingParams<T> & {
    onDataGridStateChange: (state: DataGridState) => void;
  };

export function useServerDataGridBinding<T extends object>(
  data: T[] | undefined,
  { dataGridState, total, ...otherParams }: UseServerDataGridBindingParams<T>,
) {
  const { baseGridProps } = useDataGridBinding({
    dataGridState,
    total,
    ...otherParams,
  });

  const datasource = useMemo(() => {
    return {
      rowCount: total,
      getRows: (params: IGetRowsParams) => {
        if (data) {
          // everything is already done server so we just need to return the rows
          params.successCallback(data, data.length);
        }
      },
    };
  }, [data, total]);

  const serverGridProps: DataGridProps<T> = useMemo(() => {
    return {
      ...baseGridProps,
      datasource,
      infiniteInitialRowCount: Math.min(
        dataGridState.pageSize ?? 1,
        total || 1,
      ),
      rowModelType: "infinite",
      total,
    };
  }, [baseGridProps, datasource, dataGridState, total]);

  return {
    serverGridProps,
  };
}
