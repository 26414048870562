import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import type {
  RecordTypePath,
  UpdateFieldOptionMutationResponse,
} from "../../gen";
import { updateFieldOption } from "../../gen/axios/fieldsController";
import { getLayoutsQueryKey } from "../layouts/use-layouts";
import { getRecordsQueryKey } from "../records/get-records-query-key";
import { getFieldsQueryKey } from "./use-fields";
import type { UpdateFieldOptionMutationParams } from "./use-update-field-option";

export function useUpdateFieldOptions({
  recordType,
  options = {},
}: {
  recordType: RecordTypePath;
  options?: Partial<
    UseMutationOptions<
      UpdateFieldOptionMutationResponse[],
      unknown,
      UpdateFieldOptionMutationParams[]
    >
  >;
}) {
  const { onSettled, ...restOptions } = options;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (fieldUpdates) => {
      const updates = fieldUpdates.map((fieldUpdate) =>
        updateFieldOption(
          fieldUpdate.fieldId,
          fieldUpdate.id,
          fieldUpdate.data,
        ),
      );

      return Promise.all(updates);
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getFieldsQueryKey(),
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: getLayoutsQueryKey(),
        refetchType: "all",
      });
      queryClient.invalidateQueries({
        queryKey: getRecordsQueryKey({ recordType }),
        refetchType: "all",
      });
      options.onSettled?.(...args);
    },
    ...restOptions,
  });
}
