import { MiniTag } from "@themis/ui";
import { useIntl } from "react-intl";

import type { BaseDynamicValueProps } from "../../base-dynamic-value";
import type { GetReferenceType } from "../../field-configuration-types";

type ReferenceType = GetReferenceType<"Policy">;

export const PolicyValue = (_props: BaseDynamicValueProps<ReferenceType>) => {
  const { formatMessage } = useIntl();

  return (
    <MiniTag color="grey" variant="default">
      {formatMessage({ defaultMessage: "Coming soon" })}
    </MiniTag>
  );
};
