import { z } from "zod";

import type { FieldConfiguration } from "@/api";

const getSingleNumberSchema = (
  field: FieldConfiguration,
  requiredMessage: string,
) => {
  const schema = z.number({ message: requiredMessage });

  if (field.required) {
    return schema;
  }

  return schema.optional();
};

const getMultiNumberSchema = (
  field: FieldConfiguration,
  requiredMessage: string,
) => {
  const schema = z.array(z.number(), { message: requiredMessage });

  if (field.required) {
    return schema;
  }

  return schema.optional();
};

export const getNumberSchema = (
  field: FieldConfiguration,
  requiredMessage: string,
) => {
  if (field.multiselect) {
    return getMultiNumberSchema(field, requiredMessage);
  }

  return getSingleNumberSchema(field, requiredMessage);
};
