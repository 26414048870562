import {
  skipToken,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";

import type {
  CreateAccountMutationRequest,
  ListAccountsQueryParams,
  ShowAccountQueryParams,
} from "@/api/gen/models";

import {
  createAccount,
  deleteAccount,
  listAccounts,
  listAccountsWithLimitedResponse,
  showAccount,
  updateAccount,
} from "../../gen/axios/accountsController";
import type { ModifyAccountRequest } from "../../gen/models/ModifyAccountRequest";
import { getAuditTrailQueryKey } from "../audits/use-audit-trail";

export const accountsQueryKey = {
  all: ["accounts"] as const,
  list: ({
    workspaceId,
    queryParams,
  }: {
    workspaceId: number;
    queryParams?: ListAccountsQueryParams | null;
  }) => [...accountsQueryKey.all, "list", workspaceId, queryParams] as const,
  listLimited: (moduleWorkspaceId: number) =>
    [...accountsQueryKey.all, "listLimited", moduleWorkspaceId] as const,
  details: (accountId: number, queryParams: ShowAccountQueryParams = {}) =>
    [...accountsQueryKey.all, "details", accountId, queryParams] as const,
};

export function useAccounts(
  workspaceId: number,
  queryParams?: ListAccountsQueryParams | null,
) {
  return useQuery({
    queryKey: accountsQueryKey.list({
      workspaceId,
      queryParams,
    }),
    queryFn:
      !!workspaceId && queryParams !== null
        ? () => listAccounts(workspaceId, queryParams || undefined)
        : skipToken,
  });
}

export function useAccountsList(moduleWorkspaceId: number) {
  return useQuery({
    queryKey: accountsQueryKey.listLimited(moduleWorkspaceId),
    queryFn: moduleWorkspaceId
      ? () => listAccountsWithLimitedResponse(moduleWorkspaceId)
      : skipToken,
  });
}

export function useAccount({
  workspaceId,
  accountId,
  queryParams = {},
}: {
  workspaceId: number;
  accountId: number | undefined;
  queryParams?: ShowAccountQueryParams;
}) {
  return useQuery({
    queryKey: accountsQueryKey.details(accountId || 0, queryParams),
    queryFn:
      !!workspaceId && !!accountId
        ? () => showAccount(workspaceId, accountId, queryParams)
        : skipToken,
  });
}

export function useAccountDueDiligence({
  workspaceId,
  accountId,
}: {
  workspaceId: number;
  accountId: number;
}) {
  return useQuery({
    queryKey: accountsQueryKey.details(accountId),
    queryFn: () =>
      showAccount(workspaceId, accountId, {
        expand: "questionnaires,vendors",
      }),
    enabled: !!workspaceId && !!accountId,
  });
}

export function useCreateAccount({
  workspaceId,
  ...options
}: {
  workspaceId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (account: CreateAccountMutationRequest) =>
      createAccount(workspaceId, account),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: accountsQueryKey.all,
      });
    },
    ...options,
  });
}

export function useUpdateAccount({
  workspaceId,
  accountId,
  ...options
}: {
  workspaceId: number;
  accountId: number | undefined;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (account: ModifyAccountRequest) => {
      if (!accountId) {
        throw new Error("Account ID is required");
      }

      return updateAccount(workspaceId, accountId, { account });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: accountsQueryKey.all,
      });

      if (accountId) {
        queryClient.invalidateQueries({
          queryKey: getAuditTrailQueryKey("accounts", accountId),
        });
      }
    },
    ...options,
  });
}

export function useDeleteAccount({
  workspaceId,
  ...options
}: {
  workspaceId: number;
  onSuccess?: () => void;
  onError?: () => void;
}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => deleteAccount(workspaceId, id),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: accountsQueryKey.all,
      });
    },
    ...options,
  });
}
