import { Plus } from "@phosphor-icons/react";
import type { ThemisDocument } from "@themis/api/gen/models/themisDocument";
import { FilePicker, useToast } from "@themis/ui";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { IconButton } from "@themis/ui-library/components/inputs/icon-button/icon-button";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { useMemo } from "react";
import { DirectUploadProvider } from "react-activestorage-provider";
import { useIntl } from "react-intl";

import { useCreateThemisDocument } from "@/api/queries/documents/use-create-themis-document/use-create-themis-document";
import { getRecordTypePath } from "@/utils/record-type-to-record-type-path/record-type-to-record-type-path";

import type { BaseDynamicValueProps } from "../../base-dynamic-value";
import type { GetReferenceType } from "../../field-configuration-types";

type ReferenceType = GetReferenceType<"ThemisDocument">;

export const DocumentValue = ({
  value,
  record,
  configuration,
}: BaseDynamicValueProps<ReferenceType>) => {
  const themisDocuments = useMemo(() => {
    if (value) {
      return Array.isArray(value) ? value : [value];
    }

    return [];
  }, [value]);

  const files = useMemo(() => {
    const _themisDocuments = themisDocuments.filter(
      (item) => !!item && !!item.file,
    ) as Array<NonNullable<ThemisDocument> & { file: NonNullable<File> }>;
    return _themisDocuments.map((themisDocument) => ({
      name: themisDocument.file.file_name,
      type: themisDocument.file.content_type,
      url: themisDocument.file.file_url,
    }));
  }, [themisDocuments]);

  const toast = useToast();
  const { formatMessage } = useIntl();

  const { mutateAsync: createDocument } = useCreateThemisDocument(
    {
      recordId: record.id,
      recordType: getRecordTypePath(record.type),
    },
    {
      onSuccess: () => {
        toast({
          content: formatMessage({
            defaultMessage: "Attachment has been added!",
          }),
          variant: "success",
        });
      },
      onError: () => {
        toast({
          content: formatMessage({
            defaultMessage: "Something went wrong. Could not add attachment.",
          }),
          variant: "error",
        });
      },
    },
  );

  async function handleFileChange(signedIds: string[]) {
    const requestBody = { document: { file: signedIds[0] } };

    const { file } = requestBody.document;

    await createDocument({ data: { file, field_name: configuration.name } });
  }

  return files.length > 0 ? (
    <div>
      {files.map((file) => (
        <FilePicker
          key={file.url}
          file={file}
          className="tw-h-full tw-w-full tw-rounded-none tw-border-none group-hover/row:tw-bg-neutral-50"
          isOpen={false}
          readOnly
        />
      ))}
    </div>
  ) : (
    <DirectUploadProvider
      onSuccess={handleFileChange}
      render={({ handleUpload, ready, uploads }) => {
        const [upload] = uploads;
        return (
          <FilePicker
            isLoading={
              upload?.state === "uploading" || upload?.state === "waiting"
            }
            percentage={Math.round(uploads[0]?.progress) || 0}
            readOnly={!ready}
            onSelectFile={(selectedFile) => handleUpload([selectedFile])}
            trigger={
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                gap={1}
              >
                <Typography variant="body1" color="info">
                  {formatMessage({
                    defaultMessage: "Add file",
                  })}
                </Typography>
                <IconButton size="small" color="info">
                  <Plus weight="bold" />
                </IconButton>
              </Stack>
            }
            alignPopover="center"
          />
        );
      }}
    />
  );
};
