import { faker } from "@faker-js/faker";

import type {
  UpdateThemisDocument200,
  UpdateThemisDocumentMutationRequest,
  UpdateThemisDocumentMutationResponse,
  UpdateThemisDocumentPathParams,
} from "../models/UpdateThemisDocument";
import { createThemisDocument } from "./createThemisDocument";

export function createUpdateThemisDocumentPathParams(): NonNullable<UpdateThemisDocumentPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createUpdateThemisDocument200(): NonNullable<UpdateThemisDocument200> {
  return { data: createThemisDocument() };
}

export function createUpdateThemisDocumentMutationRequest(): NonNullable<UpdateThemisDocumentMutationRequest> {
  return { data: { file: faker.string.alpha() } };
}

/**
 * @description OK
 */
export function createUpdateThemisDocumentMutationResponse(): NonNullable<UpdateThemisDocumentMutationResponse> {
  return { data: createThemisDocument() };
}
