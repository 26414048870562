import { type ThemeOptions } from "@mui/material";

export const listItemButtonTheme: ThemeOptions["components"] = {
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1, 2),
        textTransform: "capitalize",
        transition: theme.transitions.create(["background-color"]),

        "&:hover": {
          backgroundColor: theme.palette.info.main,
          color: theme.palette.primary.main,
        },
        "&:focus-visible": {
          backgroundColor: theme.palette.info.dark,
          color: theme.palette.primary.main,
        },
      }),
    },
  },
};
