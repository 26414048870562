import type { PreviewTableProps } from "@themis/ui";
import { format, parseISO } from "date-fns";

import type { Finding } from "@/api";

import { FindingActiveTag } from "../components/FindingActiveTag";
import { FindingOwnerCell } from "../components/FindingsTable/FindingOwnerCell";
import { FindingTitleCell } from "../components/FindingsTable/FindingTitleCell";
import { RiskLevelTypeTag } from "../components/RiskLevelTypeTag";
import { SourceTypeTag } from "../components/SourceTypeTag";

export const FINDINGS_TABLE_COMPONENT_OVERRIDES: PreviewTableProps<Finding>["componentOverrides"] =
  {
    name: (finding) => (
      <FindingTitleCell
        title={finding.name}
        linkedRecords={finding.linked_records}
      />
    ),
    status: (finding) => <FindingActiveTag status={finding.status} />,
    source: (finding) =>
      finding.source?.length ? <SourceTypeTag type={finding.source} /> : null,
    risk_level: (finding) =>
      finding.risk_level?.length ? (
        <RiskLevelTypeTag type={finding.risk_level} />
      ) : null,
    date_identified: (finding) =>
      finding.date_identified
        ? format(parseISO(finding.date_identified), "MM/dd/yyyy")
        : null,
    due_date: (finding) =>
      finding.due_date
        ? format(parseISO(finding.due_date), "MM/dd/yyyy")
        : null,
    owner: (finding) =>
      finding.owner ? <FindingOwnerCell owner={finding.owner} /> : null,
    supporting_evidence: (finding) =>
      finding.supporting_evidence
        ? finding.supporting_evidence?.file?.file_name
        : null,
  } as const;
