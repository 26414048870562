import { faker } from "@faker-js/faker";

import type {
  RecordsByMonthByWorkspace200,
  RecordsByMonthByWorkspacePathParams,
  RecordsByMonthByWorkspaceQueryParams,
  RecordsByMonthByWorkspaceQueryResponse,
} from "../models/RecordsByMonthByWorkspace";
import { createRecordTypePath } from "./createRecordTypePath";

export function createRecordsByMonthByWorkspacePathParams(): NonNullable<RecordsByMonthByWorkspacePathParams> {
  return {
    company_id: faker.number.int(),
    record_type: createRecordTypePath(),
  };
}

export function createRecordsByMonthByWorkspaceQueryParams(): NonNullable<RecordsByMonthByWorkspaceQueryParams> {
  return {
    workspace_ids: faker.helpers.arrayElements([faker.number.int()]) as any,
    date_field_name: faker.string.alpha(),
    from_date: faker.string.alpha(),
    to_date: faker.string.alpha(),
  };
}

/**
 * @description A JSON object containing the counts of the records grouped by month & workspace
 */
export function createRecordsByMonthByWorkspace200(): NonNullable<RecordsByMonthByWorkspace200> {
  return { data: faker.helpers.arrayElements([undefined]) as any };
}

/**
 * @description A JSON object containing the counts of the records grouped by month & workspace
 */
export function createRecordsByMonthByWorkspaceQueryResponse(): NonNullable<RecordsByMonthByWorkspaceQueryResponse> {
  return { data: faker.helpers.arrayElements([undefined]) as any };
}
