import classNames from "classnames";
import { noop } from "lodash";
import { observer } from "mobx-react";
import Pagination from "rc-pagination";
import React, { useEffect, useRef, useState } from "react";

import type { Field, RecordVersion, TableName } from "@/api";
import { useMainStore } from "@/contexts/Store";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";
import { useSortingAndPagination } from "@/hooks/useSortingAndPagination";
import { getTableFiltersParam } from "@/stores/helpers/getTableFiltersParam";
import { isLockedRecord } from "@/stores/helpers/RecordVersionHelper";
import type {
  IndexParams,
  ModuleStore,
  ModuleStorePaginated,
} from "@/stores/types/module-store-types";
import type { TopLevelModuleIdentifier } from "@/stores/types/module-workspaces-types";
import type { Section } from "@/stores/types/section-tags";

import { Icon } from "../../Elements";
import AddNewButton from "../control-mapping/AddNewButton";
import MenuActions from "../menu-actions/MenuActions";
import { useCustomColumns } from "./custom-columns/CustomColumns";
import ModuleRecordVersion from "./ModuleRecordVersion";
import ModuleTableColumn from "./ModuleTableColumn";
import ReorderableTable from "./ReorderableTable";
import SectionDropdownTitle from "./SectionDropdownTitle";
import TableMenu from "./tableMenu/TableMenu";

interface Props {
  addNewButton?: (sectionTagId: number) => JSX.Element;
  approvalFieldName?: string;
  archived?: boolean;
  archivedWord?: string;
  createRecord: (sectionTagId?: number | null) => void;
  dataTestId?: string;
  dropdownTitle?: string;
  editable?: boolean;
  fields?: Field[];
  isDraggable?: boolean;
  isDropdownOpen?: boolean;
  isTopLevelSection?: boolean;
  maxDateFunction?: (name: string, recordVersion: RecordVersion) => Date | null;
  minDateFunction?: (name: string, recordVersion: RecordVersion) => Date | null;
  moduleIdentifier: TopLevelModuleIdentifier;
  moduleWorkspaceID: number;
  paginated?: boolean;
  recordName?: string;
  recordVersions?: RecordVersion[];
  rolePrefix?: string;
  sectionTag?: Section;
  selectedTab?: string;
  setCustomColumns?: boolean;
  showCheckbox?: boolean;
  tableName?: TableName;
}

function SectionDropdown({
  approvalFieldName,
  archived,
  archivedWord,
  createRecord,
  dataTestId,
  dropdownTitle,
  editable = true,
  fields,
  isDraggable,
  isDropdownOpen: isDropdownOpenProp,
  isTopLevelSection = false,
  maxDateFunction,
  minDateFunction,
  moduleIdentifier,
  moduleWorkspaceID,
  paginated = false,
  recordName,
  recordVersions,
  rolePrefix,
  sectionTag,
  selectedTab,
  setCustomColumns,
  tableName,
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();
  const filters = getTableFiltersParam();

  // State
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(isDropdownOpenProp);
  const [showCheckbox, setShowCheckbox] = useState(false);
  const [selectMode, setSelectMode] = useState(false);

  // Variables
  const { canManageSections } = mainStore.userPermissions;
  const moduleStore = mainStore.getStore(moduleIdentifier) as ModuleStore & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
  };
  const moduleStorePaginated = mainStore.getStore(
    moduleIdentifier,
  ) as ModuleStorePaginated & {
    data: unknown; // use mainStore.getStore(moduleIdentifier)
  };

  const sectionTableID = `section-table-${sectionTag?.id || 0}`;
  const workspaceId = useRouteWorkspaceId();
  const isFirstRender = useRef(true);
  const page = moduleStorePaginated.pageForSection(sectionTag?.id || 0);
  const count = moduleStorePaginated.countOfRecordsForSection(
    sectionTag?.id || 0,
  );
  const {
    sortByFieldName,
    sortByDirection,
    setSortByFieldName,
    setSortByDirection,
  } = useSortingAndPagination({
    store: moduleStore,
    page,
    sectionTagId: sectionTag?.id,
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    fetchIndexPagination(1);
  }, [sortByFieldName, sortByDirection]);

  // Hooks
  const { renameColumn, createColumn, deleteColumn, AddColumnButton } =
    useCustomColumns({
      sectionTableID,
    });

  const onPageChange = async (newPage: number) => {
    await fetchIndexPagination(newPage);
  };

  const fetchIndexPagination = async (newPage: number) => {
    const params: IndexParams = {
      workspaceID: workspaceId,
      page: newPage,
      tableName,
      tab: selectedTab,
      sectionTagId: sectionTag?.id || 0,
      ...(sortByFieldName && {
        sortParams: {
          field_name: sortByFieldName,
          direction: sortByDirection || "ASC",
        },
      }),
      ...(filters && { tableFilters: filters }),
    };

    await moduleStore.index(params);
  };

  const resetMenuActions = () => {
    setShowCheckbox(false);
    setSelectMode(false);
    mainStore.dynamicTable.clearSelectedRecordVersionIDs();
  };

  const dropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const addNewRecord = () => {
    createRecord(sectionTag?.id);
  };

  const arrowLeftImage = (
    <Icon name="chevronLeft" color="generalMidnightDark" />
  );
  const arrowRightImage = (
    <Icon name="chevronRight" color="generalMidnightDark" />
  );

  const deleteSection = () => {
    mainStore.sectionTags.delete(moduleWorkspaceID, sectionTag?.id);
    mainStore.toast.setText("Section has been deleted!");
  };

  const renderRow = (recordVersion: RecordVersion, idx: number) => {
    const isApproved =
      (archivedWord?.length === 0 && archived) ||
      Boolean(
        mainStore.avroSchemas.valueForField(
          approvalFieldName,
          recordVersion.data,
        )?.verifier_id,
      ) ||
      (!!archivedWord?.length && recordVersion?.status === archivedWord);

    const isLockedTable =
      moduleIdentifier === "control_mapping" &&
      isLockedRecord(recordVersion, moduleIdentifier);

    return (
      <ModuleRecordVersion
        key={recordVersion.id}
        isArchivedRow={isApproved}
        recordVersion={recordVersion}
        fields={mainStore.fields.visibleFields}
        moduleWorkspaceID={moduleWorkspaceID}
        tableID={recordVersion.table_id}
        tableName={recordVersion.table_name}
        currentTableName={tableName}
        moduleIdentifier={moduleIdentifier}
        inSelectMenuActionMode={selectMode}
        maxDateFunction={(columnTitle) =>
          maxDateFunction && maxDateFunction(columnTitle, recordVersion)
        }
        minDateFunction={(columnTitle) =>
          minDateFunction && minDateFunction(columnTitle, recordVersion)
        }
        isLockedRow={!!isLockedTable}
        isDraggable={isDraggable}
        order={idx}
        isSorted={!!sortByFieldName}
      />
    );
  };

  const renderFields = fields?.map((field) => (
    <ModuleTableColumn
      key={field.name}
      field={field}
      onDelete={deleteColumn}
      onRename={renameColumn}
      setSortByDirection={setSortByDirection}
      setSortByFieldName={setSortByFieldName}
      sortDirection={
        field.name === sortByFieldName ? (sortByDirection as string) : undefined
      }
    />
  ));

  return (
    <div data-testid={dataTestId}>
      {!isTopLevelSection && (
        <SectionDropdownTitle
          archived={archived}
          canManageSections={canManageSections}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          isEditable={editable}
          isOpen={!!isDropdownOpen}
          moduleWorkspaceId={moduleWorkspaceID?.toString()}
          onDelete={deleteSection}
          onToggleOpen={dropdownClick}
          tableName={tableName}
          title={dropdownTitle ?? ""}
          rolePrefix={rolePrefix}
          sectionTag={sectionTag}
        />
      )}

      {isDropdownOpen && (
        <>
          <div
            className={classNames("dropdown-table", { draggable: isDraggable })}
            data-testid="section-table"
            id={sectionTableID}
          >
            <div className="list-title-table">
              <ul>
                <div className="list-column-wrap">
                  <div className="procedures-list-column-first-blocks table-column-reorder">
                    {Boolean(fields?.length) && (
                      <TableMenu
                        setShowCheckbox={setShowCheckbox}
                        resetMenuActions={resetMenuActions}
                        selectMode={selectMode}
                        setSelectMode={setSelectMode}
                      />
                    )}
                  </div>
                  {showCheckbox && selectMode ? (
                    <div>
                      <MenuActions resetMenuActions={resetMenuActions} />
                    </div>
                  ) : (
                    <div className="list-title-table-wrap">
                      {renderFields}
                      <span className="stretch-cell" />
                    </div>
                  )}
                </div>
                {!setCustomColumns ? (
                  <AddColumnButton onAdd={createColumn} />
                ) : (
                  <div className="title-first-block finalized-block training" />
                )}
              </ul>
            </div>
            <ReorderableTable
              recordVersions={recordVersions}
              isSorted={!!sortByFieldName}
              renderer={renderRow}
              sectionTag={sectionTag}
              newRowName={recordName}
              onAddNewRow={
                !(moduleIdentifier === "control_mapping") && !archived
                  ? addNewRecord
                  : undefined
              }
            />
            {moduleIdentifier === "control_mapping" && !archived && (
              <AddNewButton sectionTagId={sectionTag?.id} />
            )}
          </div>
          {paginated && (
            <div className="tw-mb-5 tw-ml-[18px]">
              <Pagination
                onChange={onPageChange}
                current={page}
                total={count}
                prevIcon={arrowLeftImage}
                nextIcon={arrowRightImage}
                showPrevNextJumpers
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

SectionDropdown.defaultValue = {
  maxDateFunction: noop,
  minDateFunction: noop,
};

export default observer(SectionDropdown);
