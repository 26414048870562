import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  colorSelectColors,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  Form,
  useToast,
} from "@themis/ui";
import { snakeCase } from "lodash";
import { useEffect } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useIntl } from "react-intl";

import type { CreateFieldMutationRequest } from "../../../api/gen/models/CreateField";
import type { FieldConfigurationReferenceType } from "../../../api/gen/models/FieldConfiguration";
import type { RecordTypePath } from "../../../api/gen/models/RecordTypePath";
import { useCreateField } from "../../../api/queries/fields/use-create-field";
import { axiosConfigContainsError } from "../../../utils/errors";
import { CustomColumnFormFields } from "./custom-column-form-fields/custom-column-form-fields";
import type { ValidationSchema } from "./use-validation-schema/use-validation-schema";
import { useValidationSchema } from "./use-validation-schema/use-validation-schema";

const columnTypeToReferenceTypeMap: Partial<
  Record<ValidationSchema["columnType"], FieldConfigurationReferenceType>
> = {
  attachment: "ThemisDocument",
  user: "User",
};

interface CustomDialogProps {
  companyId: number;
  open: boolean;
  recordType: RecordTypePath;
  onOpenChange: (open: boolean) => void;
}

/**
 * @todo Finish color select implementation
 */
export function CustomColumnDialog({
  companyId,
  open,
  recordType,
  onOpenChange,
}: CustomDialogProps) {
  const { formatMessage } = useIntl();
  const toast = useToast();

  const validationSchema = useValidationSchema();
  const createFieldMutation = useCreateField({
    companyId,
    recordType,
  });

  const form = useForm<ValidationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      columnType: "text",
      columnData: {
        requiredColumn: false,
        name: "",
        multipleSelections: false,
        internal: false,
      },
    },
  });

  const { columnData, columnType } = useWatch({ control: form.control });

  useEffect(() => {
    if (columnType !== "select") {
      return;
    }

    const options = columnData?.options ?? [];
    if (options.length < 1) {
      form.setValue("columnData.options", [
        { name: "", color: colorSelectColors[0] },
      ]);
    }
  }, [columnData, columnType, form]);

  const handleSaveColumn = (data: ValidationSchema) => {
    const isReferenceType =
      data.columnType === "attachment" || data.columnType === "user";

    const hasMultiSelect =
      data.columnType === "select" ||
      (data.columnType === "user" && data.columnData.multipleSelections);

    const requestData: CreateFieldMutationRequest = {
      field: {
        display_name: data.columnData.name,
        field_type: isReferenceType ? "reference" : data.columnType,
        reference_type: columnTypeToReferenceTypeMap[data.columnType],
        required: data.columnData.requiredColumn,
        internal_only: data.columnData.internal,
      },
    };

    if (data.columnType === "select") {
      requestData.field.options = data.columnData.options.map((option) => ({
        key: snakeCase(option.name),
        value: option.name,
      }));
    }

    if (hasMultiSelect) {
      requestData.field.multiselect = data.columnData.multipleSelections;
    }

    if (data.columnType) {
      createFieldMutation.mutate(requestData, {
        onSuccess: (createResult) => {
          toast({
            content: formatMessage(
              {
                defaultMessage: 'Column "{columnName}" created successfully!',
              },
              {
                columnName: createResult.data.display_name,
              },
            ),
            variant: "success",
          });
          onOpenChange(false);
        },
        onError: (error) => {
          if (axiosConfigContainsError(error, "base", "Field already exists")) {
            toast({
              content: formatMessage({
                defaultMessage: "There is already a column with that name",
              }),
              variant: "error",
            });
          } else {
            toast({
              content: formatMessage({
                defaultMessage: "Failed to create column",
              }),
              variant: "error",
            });
          }
        },
      });
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {formatMessage({ defaultMessage: "Create Custom Column" })}
          </DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form noValidate onSubmit={form.handleSubmit(handleSaveColumn)}>
            <CustomColumnFormFields />

            <DialogFooter>
              <Button
                type="submit"
                color="primary"
                loading={createFieldMutation.isPending}
              >
                {formatMessage({ defaultMessage: "Confirm" })}
              </Button>

              <Button color="transparent" onClick={() => onOpenChange(false)}>
                {formatMessage({ defaultMessage: "Cancel" })}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
