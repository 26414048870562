import { faker } from "@faker-js/faker";

import type { FieldConfiguration } from "../models/FieldConfiguration";
import { createFieldOption } from "./createFieldOption";

export function createFieldConfiguration(
  data: NonNullable<Partial<FieldConfiguration>> = {},
): NonNullable<FieldConfiguration> {
  return {
    ...{
      id: faker.number.int(),
      custom: faker.datatype.boolean(),
      display_name: faker.string.alpha(),
      field_type: faker.helpers.arrayElement<any>([
        "text",
        "textarea",
        "select",
        "numeric",
        "decimal",
        "section",
        "status",
        "date",
        "datetime",
        "reference",
        "computed",
        "reviews",
      ]),
      multiselect: faker.datatype.boolean(),
      name: faker.string.alpha(),
      path: faker.string.alpha(),
      options: faker.helpers.arrayElements([createFieldOption()]) as any,
      reference_type: faker.helpers.arrayElement<any>([
        "Account",
        "Attachment",
        "Contact",
        "Contract",
        "Department",
        "Issue",
        "MonitoringAsset",
        "MonitoringFinding",
        "Policy",
        "Questionnaire",
        "RecordLink",
        "RecordReference",
        "RecordVersion",
        "Review",
        "Task",
        "ThemisDocument",
        "ThemisRecord",
        "User",
        "VendorChecklist",
        "Workspace",
      ]),
      required: faker.datatype.boolean(),
      user_editable: faker.datatype.boolean(),
      internal_only: faker.datatype.boolean(),
      sortable: faker.datatype.boolean(),
    },
    ...data,
  };
}
