import { useCallback, useState } from "react";

export function useDownloadFile() {
  const [isLoading, setIsLoading] = useState(false);

  const downloadFile = useCallback(async (url: string, fileName: string) => {
    setIsLoading(true);
    const response = await fetch(url, {
      headers: {
        "Cache-Control": "no-cache",
      },
      mode: "cors",
      method: "get",
    });

    if (response.ok) {
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
    setIsLoading(false);
  }, []);

  return {
    downloadFile,
    isLoading,
  };
}
