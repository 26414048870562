import { faker } from "@faker-js/faker";

import type {
  LegacyWebsiteMonitoringGroupChildResults200,
  LegacyWebsiteMonitoringGroupChildResultsPathParams,
  LegacyWebsiteMonitoringGroupChildResultsQueryParams,
  LegacyWebsiteMonitoringGroupChildResultsQueryResponse,
} from "../models/LegacyWebsiteMonitoringGroupChildResults";
import { createMonitoringGroupAssetChild } from "./createMonitoringGroupAssetChild";

export function createLegacyWebsiteMonitoringGroupChildResultsPathParams(): NonNullable<LegacyWebsiteMonitoringGroupChildResultsPathParams> {
  return { monitoring_group_asset_child_id: faker.number.int() };
}

export function createLegacyWebsiteMonitoringGroupChildResultsQueryParams(): NonNullable<LegacyWebsiteMonitoringGroupChildResultsQueryParams> {
  return {
    filters: {},
    limit: faker.number.int(),
    offset: faker.number.int(),
    sort_by: {},
  };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupChildResults200(): NonNullable<LegacyWebsiteMonitoringGroupChildResults200> {
  return { data: createMonitoringGroupAssetChild() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupChildResultsQueryResponse(): NonNullable<LegacyWebsiteMonitoringGroupChildResultsQueryResponse> {
  return { data: createMonitoringGroupAssetChild() };
}
