import { parseAsString, useQueryState } from "nuqs";
import { useCallback, useEffect, useMemo } from "react";
import { useLocalStorage } from "usehooks-ts";

import type { Filters } from "../data-grid-state";

export function useFilters({
  localStorageKey,
}: {
  localStorageKey: string;
}): [Filters | undefined, (newFilters: Filters | undefined) => void] {
  const [
    localeStorageFilters,
    setLocalStorageFilters,
    clearLocalStorageFilters,
  ] = useLocalStorage<Filters | null>(localStorageKey, null);

  const [urlFilters, setUrlFilters] = useQueryState(
    "filters",
    parseAsString
      .withOptions({
        clearOnDefault: false,
      })
      .withDefault(
        localeStorageFilters ? JSON.stringify(localeStorageFilters) : "",
      ),
  );

  // Sync URL with localStorage on mount
  useEffect(() => {
    if (localeStorageFilters) {
      const filtersString = JSON.stringify(localeStorageFilters);
      if (filtersString !== urlFilters) {
        setUrlFilters(filtersString);
      }
    }
  }, [localeStorageFilters, setUrlFilters, urlFilters]);

  const handleFilters = useCallback(
    (newFilters: Filters | undefined) => {
      if (newFilters && Object.keys(newFilters).length > 0) {
        const filtersString = JSON.stringify(newFilters);
        setUrlFilters(filtersString);
        setLocalStorageFilters(newFilters);
      } else {
        clearLocalStorageFilters();
        setUrlFilters(null);
      }
    },
    [clearLocalStorageFilters, setLocalStorageFilters, setUrlFilters],
  );

  const filtersValue = useMemo(() => {
    if (!urlFilters) {
      return undefined;
    }
    try {
      return JSON.parse(urlFilters);
    } catch {
      return undefined;
    }
  }, [urlFilters]);

  return [filtersValue, handleFilters];
}
