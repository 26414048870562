import { useState } from "react";
import type { ReactElement } from "react";
import { Document, Page } from "react-pdf";
import type { DocumentCallback } from "react-pdf/src/shared/types";

import { styled } from "../../../../../../styles";
import { Box } from "../../../../../layout/box/box";
import { Card } from "../../../../../surfaces/card/card";
import type { BasePreviewRendererProps } from "../base-preview-renderer-props";

type PdfPreviewRendererProps = BasePreviewRendererProps;

const StyledDocument = styled(Document)();

export function PdfPreviewRenderer({
  file,
  previewWidth,
  zoomLevel,
  onError,
  onLoaded,
}: PdfPreviewRendererProps): ReactElement {
  const [numPages, setNumPages] = useState(0);

  const handleLoadSuccess = (document: DocumentCallback) => {
    setNumPages(document.numPages);

    onLoaded();
  };

  return (
    <Box width="100%">
      <StyledDocument
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
          alignItems: "center",
        }}
        file={file.file_url}
        onLoadSuccess={handleLoadSuccess}
        onLoadError={onError}
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Card
            key={index}
            sx={{
              p: 0,
            }}
            component={Page}
            variant="outlined"
            pageIndex={index}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            scale={zoomLevel}
            width={previewWidth}
          />
        ))}
      </StyledDocument>
    </Box>
  );
}
