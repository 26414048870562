import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  ExportPdfPathParams,
  ExportPdfQueryParams,
  ExportPdfQueryResponse,
} from "../../models/ExportPdf";

/**
 * @description Export object in PDF
 * @summary Export object in PDF
 * @link /:record_type/:record_id/pdf_reports
 */
export async function exportPdf(
  recordType: ExportPdfPathParams["record_type"],
  recordId: ExportPdfPathParams["record_id"],
  params?: ExportPdfQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<ExportPdfQueryResponse>["data"]> {
  const res = await client<ExportPdfQueryResponse>({
    method: "get",
    url: `/${recordType}/${recordId}/pdf_reports`,
    params,
    ...options,
  });
  return res.data;
}
