import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@themis/ui";
import { useState } from "react";
import { PiGearBold } from "react-icons/pi";

import { useCurrentUser } from "@/api/queries/users";
import { getTimeSensitiveGreeting } from "@/components/helpers/time";
import { Header } from "@/components/Layout/Header";

import { ManageWorkspacesModal } from "./ManageWorkspacesModal";

export function HomeHeader({
  hasManageWorkspaces,
}: {
  hasManageWorkspaces?: boolean;
}) {
  const { data: user } = useCurrentUser();

  const [isManageWorkspacesOpen, setIsManageWorkspacesOpen] = useState(false);

  return (
    <Header
      hideBottomBorder
      title={
        <div role="presentation" className="tw-text-base">
          {`${getTimeSensitiveGreeting()}, ${user?.full_name}`}
        </div>
      }
      HeaderOptions={
        hasManageWorkspaces && (
          <>
            <TooltipProvider>
              <div className="tw-flex tw-items-center">
                <Tooltip delayDuration={700}>
                  <TooltipTrigger>
                    <Button
                      LeftIcon={PiGearBold}
                      color="transparent"
                      onClick={() => setIsManageWorkspacesOpen(true)}
                    >
                      Manage
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent align="end" side="top">
                    <p className="tw-w-[280px] tw-items-center tw-p-2 tw-text-center">
                      Select which Workspace data to include in the dashboard
                      views
                    </p>
                  </TooltipContent>
                </Tooltip>
              </div>
            </TooltipProvider>

            {isManageWorkspacesOpen && (
              <ManageWorkspacesModal
                onClose={() => setIsManageWorkspacesOpen(false)}
              />
            )}
          </>
        )
      }
    />
  );
}
