import { faker } from "@faker-js/faker";

import type {
  GetAttesters200,
  GetAttestersPathParams,
  GetAttestersQueryParams,
  GetAttestersQueryResponse,
} from "../models/GetAttesters";
import { createExtendedAttester } from "./createExtendedAttester";
import { createPaginatedMeta } from "./createPaginatedMeta";

export function createGetAttestersPathParams(): NonNullable<GetAttestersPathParams> {
  return { id: faker.number.int() };
}

export function createGetAttestersQueryParams(): NonNullable<GetAttestersQueryParams> {
  return { limit: faker.number.int(), offset: faker.number.int(), sort_by: {} };
}

/**
 * @description OK
 */
export function createGetAttesters200(): NonNullable<GetAttesters200> {
  return {
    data: faker.helpers.arrayElements([createExtendedAttester()]) as any,
    meta: createPaginatedMeta(),
  };
}

/**
 * @description OK
 */
export function createGetAttestersQueryResponse(): NonNullable<GetAttestersQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createExtendedAttester()]) as any,
    meta: createPaginatedMeta(),
  };
}
