import type { ThemeOptions } from "@mui/material";

export const svgIconTheme: ThemeOptions["components"] = {
  MuiSvgIcon: {
    variants: [
      {
        props: { fontSize: "large" },
        style: ({ theme }) => ({
          fontSize: theme.size.icon.large,
        }),
      },
      {
        props: { fontSize: "medium" },
        style: ({ theme }) => ({
          fontSize: theme.size.icon.medium,
        }),
      },
      {
        props: { fontSize: "small" },
        style: ({ theme }) => ({
          fontSize: theme.size.icon.small,
        }),
      },
    ],
  },
};
