import { ChatCircleDots } from "@phosphor-icons/react";
import type { ListCommentsPathParams } from "@themis/api/gen/models/commentsController/listComments";
import { buttonClasses } from "@themis/ui-library/components/inputs/button/button";
import { ToggleButton } from "@themis/ui-library/components/inputs/toggle-button/toggle-button";
import { styled } from "@themis/ui-library/styles";
import { useIntl } from "react-intl";

import { useCommentsRouteParams } from "../../../hooks/use-comments-route-params/use-comments-route-params";

const StyledButton = styled(ToggleButton)(({ theme }) => ({
  width: "100%",
  height: "100%",
  borderRadius: 0,

  [`.${buttonClasses.startIcon}`]: {
    marginRight: theme.spacing(0.5),
  },
}));

interface CommentsCellProps {
  commentsCount: number;
  recordType: ListCommentsPathParams["record_type"];
  recordId: ListCommentsPathParams["record_id"];
}

export function CommentsCell({
  commentsCount,
  recordId,
  recordType,
}: CommentsCellProps) {
  const { formatMessage } = useIntl();

  const {
    close: closeComments,
    isOpen,
    open: openComments,
    selectedRecordId,
  } = useCommentsRouteParams();

  const handleClick = () => {
    if (!isOpen) {
      openComments(recordId);
    } else {
      closeComments();
    }
  };

  return (
    <StyledButton
      active={isOpen && recordId === selectedRecordId}
      size="small"
      startIcon={<ChatCircleDots weight="bold" />}
      aria-label={formatMessage(
        {
          defaultMessage: "{recordType} comments",
        },
        { recordType },
      )}
      onClick={handleClick}
    >
      <span>{commentsCount}</span>
    </StyledButton>
  );
}
