import type { ReactNode } from "react";
import { createContext, useContext } from "react";

interface FormControlStateContextType {
  disabled: boolean;
}

export const FormControlStateContext =
  createContext<FormControlStateContextType>({ disabled: false });

interface FormControlStateContextProviderProps {
  children: ReactNode;
  disabled: boolean;
}

export function FormControlStateContextProvider({
  children,
  disabled,
}: FormControlStateContextProviderProps) {
  return (
    <FormControlStateContext.Provider value={{ disabled }}>
      {children}
    </FormControlStateContext.Provider>
  );
}

export function useFormControlStateContext() {
  return useContext(FormControlStateContext);
}
