import type { CustomCellEditorProps } from "ag-grid-react";
import React from "react";

import { filterCellProps } from "../../../lib/utils";
import { Select } from "../../Form/select/Select";
import type { SelectProps } from "../../Form/select/Select";

type Props = SelectProps & CustomCellEditorProps;

const SelectCell = ({
  items,
  multiple,
  onValueChange,
  value,
  defaultOpen,
  ...props
}: Props) => {
  const { selected, onSelect, ...restProps } = props;
  const filteredProps = filterCellProps({ value, onValueChange, ...restProps });
  const editable = Boolean(props.colDef?.editable);

  return (
    <Select
      className="tw-h-full tw-rounded-none tw-border-none tw-bg-transparent read-only:tw-bg-transparent group-hover/row:tw-bg-neutral-50"
      selected={value}
      items={items}
      onSelect={onValueChange}
      multiple={multiple}
      defaultOpen={!!(defaultOpen && editable)}
      onPopupClosed={() => props.stopEditing && props.stopEditing()}
      hideCaret
      readOnly={!editable}
      {...filteredProps}
    />
  );
};

export default SelectCell;
