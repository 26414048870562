import type { PopoverContentProps, SelectItem } from "@themis/ui";
import { Select } from "@themis/ui";

export function TimeFrameSelect({
  options,
  selectedValue,
  onSelect,
  alignItemList,
  classNameList,
}: {
  options: SelectItem[];
  selectedValue: string;
  onSelect: (value: string) => void;
  alignItemList?: PopoverContentProps["align"];
  classNameList?: string;
}) {
  return (
    <Select
      className="tw-w-[200px]"
      alignItemList={alignItemList}
      popoverContentClassName={classNameList}
      items={options}
      onSelect={onSelect}
      selected={selectedValue}
    />
  );
}
