import { cn } from "@themis/ui";
import axios from "axios";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { downloadFile } from "../../../../stores/helpers/AttachmentHelper";
import { Icon } from "../../../Elements";
import ConfirmationDialog from "../../shared/ConfirmationDialog/confirmation-dialog";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  files: any[];
  description?: string;
  descriptionIcon?: boolean;
  fieldName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleRemoveFile?: (...args: any[]) => any;
  sectionID?: number;
}

function SectionTagViewDocument({
  files,
  handleRemoveFile,
  description,
  descriptionIcon,
  sectionID,
  fieldName,
}: Props) {
  const mainStore = useMainStore();
  const { formatMessage } = useIntl();
  const { workspaceID } = mainStore.context;
  const [showPopup, setShowPopup] = useState(false);
  const [viewPopup, setViewPopup] = useState("menu");
  const cellError = mainStore.recordVersions.errorsForField(
    undefined,
    fieldName,
  );
  const hasErrors = Boolean(cellError);

  const formattedDescription =
    description ||
    formatMessage({
      defaultMessage:
        "No Documents added yet. Click “Upload Document” to add one here.",
    });

  useEffect(() => {
    if (!showPopup) {
      setViewPopup("menu");
    }
  }, [showPopup]);

  // @ts-expect-error TS(7006) FIXME: Parameter 'filename' implicitly has an 'any' type.
  const onDownload = async (filename, fileURL, fileData = null) => {
    let downloadResponse;
    if (!fileData) {
      downloadResponse = await axios({
        method: "GET",
        url: fileURL,
        responseType: "blob",
      });
    }

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const blob = fileData ? fileData : downloadResponse.data;
    downloadFile(blob, filename);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'deleteFileId' implicitly has an 'any' t... Remove this comment to see the full error message
  const onDelete = (deleteFileId) => {
    // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    handleRemoveFile(deleteFileId, fieldName);
    setShowPopup(false);
  };

  if (files?.length === 0) {
    return (
      <div
        className={cn("table-row empty", {
          "has-errors": hasErrors,
        })}
        data-testid="no-documents"
      >
        {descriptionIcon && (
          <Icon
            name="starSolid"
            className="star-icon"
            color="generalDark"
            size="de"
          />
        )}
        {formattedDescription}
      </div>
    );
  }

  return (
    <>
      {files.map((file, index) => (
        <div key={`file-name-${index}`} className="table-row document">
          {file.name}
          <div className="table-row-actions">
            {file.url && (
              <Popup
                trigger={
                  <div data-testid="view-attachment">
                    <NavLink
                      data-testid="link"
                      to={`/workspaces/${workspaceID}/modules/change-management/section-view/${sectionID}/attachment_view/${file.id}`}
                      className="link-npa-section link-creative"
                    >
                      <Icon name="eye" color="generalDark" size="de" />
                    </NavLink>
                  </div>
                }
                on="hover"
                position="top center"
              >
                <div className="table-dropdown policy-document-tooltip">
                  {formatMessage({
                    defaultMessage: "View Attachment",
                  })}
                </div>
              </Popup>
            )}

            <Popup
              // eslint-disable-next-line react/no-unstable-nested-components
              trigger={() => (
                <div
                  data-testid="download-attachment"
                  onClick={() => onDownload(file.name, file.url, file.fileData)}
                >
                  <Icon name="download" color="generalDark" size="de" />
                </div>
              )}
              on="hover"
              position="top center"
            >
              <div className="table-dropdown policy-document-tooltip">
                {formatMessage({
                  defaultMessage: "Download Attachment",
                })}
              </div>
            </Popup>

            <Popup
              // eslint-disable-next-line react/no-unstable-nested-components
              trigger={() => (
                <div className="more" data-testid="attachment-more-actions">
                  <Icon name="moreHorizontal" color="generalDark" size="de" />
                </div>
              )}
              // @ts-expect-error TS(2367) FIXME: This condition will always return 'false' since th... Remove this comment to see the full error message
              open={showPopup === index}
              onOpen={() => setShowPopup(true)}
              onClose={() => setShowPopup(false)}
              keepTooltipInside
            >
              {viewPopup === "menu" && (
                <ul className="table-dropdown policy-document-menu">
                  <li
                    data-testid="delete-attachment"
                    onClick={() => setViewPopup("delete")}
                  >
                    <Icon
                      name="trash"
                      className="delete-icon"
                      color="generalDark"
                      size="de"
                    />
                    {formatMessage({
                      defaultMessage: "Delete",
                    })}
                  </li>
                </ul>
              )}
              {viewPopup === "delete" && (
                <ConfirmationDialog
                  containerClass="table-dropdown"
                  heading={formatMessage({
                    defaultMessage: "Delete Attachment",
                  })}
                  content={formatMessage({
                    defaultMessage:
                      "Are you sure you want to delete this attachment?",
                  })}
                  handleConfirm={() => onDelete(file.id)}
                  handleReject={() => setShowPopup(false)}
                />
              )}
            </Popup>
          </div>
        </div>
      ))}
    </>
  );
}

export default observer(SectionTagViewDocument);
