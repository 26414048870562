import { IconButton, MiniTag } from "@themis/ui";
import { Avatar } from "@themis/ui-library/components/data-display/avatar/avatar";
import {
  format,
  formatDistanceToNow,
  isAfter,
  startOfSecond,
  subWeeks,
} from "date-fns";
import { PiArchiveLight, PiCheckBold, PiNotification } from "react-icons/pi";
import { useHistory } from "react-router-dom";

import type { UpdateNotificationRequest } from "@/api/gen/models/UpdateNotificationRequest";
import { useUpdateNotification } from "@/api/queries/notifications";
import ModuleLabel from "@/components/shared/ModuleLabel";
import UserMentionsInput from "@/components/table/shared/UserMentionsInput";
import ThemisLogo from "@/images/logo.svg";
import mainStore from "@/stores/Main";

interface NotificationItemProps {
  id: number;
  title: string;
  actorColorIndex: number;
  actorInitials: string;
  body?: string | null;
  createdAt: string;
  unread: boolean;
  link: string;
  moduleIdentifier: string;
  moduleDisplayName: string;
  themisId?: string;
}

export function NotificationItem({
  id,
  title,
  actorColorIndex,
  actorInitials,
  body,
  createdAt,
  unread,
  link,
  moduleIdentifier,
  moduleDisplayName,
  themisId,
}: NotificationItemProps) {
  const history = useHistory();
  const { mutateAsync: updateNotification } = useUpdateNotification(id);

  function formatCreatedAtTime() {
    return isAfter(subWeeks(new Date(), 1), new Date(createdAt))
      ? format(new Date(createdAt), "MM/dd/yyyy")
      : formatDistanceToNow(startOfSecond(new Date(createdAt)), {
          addSuffix: true,
        });
  }

  function handleNotificationClick() {
    if (link) {
      history.push(link);
    }
    handleUpdate("read_at", new Date().toString());
    mainStore.notifications.setIsOpen(false);
  }

  function handleUpdate(fieldName: string, value: string | null) {
    const data: UpdateNotificationRequest = {
      notification: { [fieldName]: value },
    };
    updateNotification(data);
  }

  const renderActionButtons = (
    <div className="tw-absolute tw-right-0 tw-top-0 tw-mr-2 tw-mt-2 tw-hidden tw-flex-col tw-items-start group-hover:tw-flex">
      <div className="tw-relative tw-flex">
        {unread ? (
          <IconButton
            Icon={PiCheckBold}
            color="secondary"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              handleUpdate("read_at", new Date().toString());
            }}
            data-tooltip-id="tooltip"
            data-tooltip-content="Mark as read"
            data-tooltip-place="bottom"
          />
        ) : (
          <IconButton
            Icon={PiNotification}
            color="secondary"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              handleUpdate("read_at", null);
            }}
            data-tooltip-id="tooltip"
            data-tooltip-content="Mark as unread"
            data-tooltip-place="bottom"
          />
        )}
        <IconButton
          Icon={PiArchiveLight}
          color="secondary"
          size="sm"
          className="tw-ml-1"
          onClick={(e) => {
            e.stopPropagation();
            handleUpdate("dismissed_at", new Date().toString());
          }}
          data-tooltip-id="tooltip"
          data-tooltip-content="Dismiss"
          data-tooltip-place="bottom"
        />
      </div>
    </div>
  );

  const renderUnreadMarker = (
    <div className="tw-absolute tw-right-[8px] tw-top-[8px] tw-h-2 tw-w-2 tw-rounded-full tw-bg-red-500 group-hover:tw-hidden" />
  );

  const renderIcon = (
    <div>
      {actorInitials && actorColorIndex ? (
        <Avatar className="tw-mr-2" colorSeed={actorColorIndex}>
          {actorInitials}
        </Avatar>
      ) : (
        <img
          className="tw-mr-2 tw-h-[24px] tw-w-[24px]"
          src={ThemisLogo}
          alt="themis-logo"
        />
      )}
    </div>
  );

  return (
    <div
      className={`tw-group tw-relative tw-border-0 tw-border-b tw-border-solid tw-border-gray-600 tw-py-[16px] tw-pl-[20px] tw-pr-[40px] ${
        link ? "tw-cursor-pointer" : ""
      }`}
      onClick={() => handleNotificationClick()}
    >
      <div className="tw-flex tw-items-start">
        {renderIcon}
        <div className="tw-mr-8 tw-flex-grow">
          <div>
            <h4
              className={`tw-font-semibold ${
                unread ? "tw-text-white" : "tw-text-gray"
              }`}
            >
              {title}
            </h4>
            <div className="tw-mt-1 tw-flex">
              {themisId && (
                <MiniTag size="sm" color="navy">
                  {themisId}
                </MiniTag>
              )}
              {moduleIdentifier && (
                <div className="tw-ml-3">
                  <ModuleLabel
                    moduleDisplayName={moduleDisplayName}
                    moduleIdentifier={moduleIdentifier}
                    color="gray-suit"
                  />
                </div>
              )}
            </div>
            {body && (
              <div className="notification-mention tw-mt-2">
                <UserMentionsInput
                  singleLine={false}
                  editable={false}
                  content={body}
                  dataTestID="notification-body-mentions"
                />
              </div>
            )}
            <p
              className={`tw-mt-2 tw-max-w-full tw-text-xs ${
                unread ? "tw-text-gray-300" : "tw-text-gray"
              }`}
            >
              {formatCreatedAtTime()}
            </p>
          </div>
        </div>
        {renderActionButtons}
        {unread && renderUnreadMarker}
      </div>
    </div>
  );
}
