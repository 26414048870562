import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  LegacyWebsiteMonitoringGroupChildResultsPathParams,
  LegacyWebsiteMonitoringGroupChildResultsQueryParams,
  LegacyWebsiteMonitoringGroupChildResultsQueryResponse,
} from "../../models/LegacyWebsiteMonitoringGroupChildResults";

/**
 * @description List Website Monitoring - Groups with children
 * @summary List Website Monitoring Group Child Results (Legacy)
 * @link /website_monitoring/:monitoring_group_asset_child_id/monitoring_group_child_results
 */
export async function legacyWebsiteMonitoringGroupChildResults(
  monitoringGroupAssetChildId: LegacyWebsiteMonitoringGroupChildResultsPathParams["monitoring_group_asset_child_id"],
  params?: LegacyWebsiteMonitoringGroupChildResultsQueryParams,
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<LegacyWebsiteMonitoringGroupChildResultsQueryResponse>["data"]
> {
  const res =
    await client<LegacyWebsiteMonitoringGroupChildResultsQueryResponse>({
      method: "get",
      url: `/website_monitoring/${monitoringGroupAssetChildId}/monitoring_group_child_results`,
      params,
      ...options,
    });
  return res.data;
}
