import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createFieldOption } from "../../gen/axios/fieldsController";
import type {
  CreateFieldOptionMutationRequest,
  CreateFieldOptionMutationResponse,
  CreateFieldOptionPathParams,
} from "../../gen/models";
import { getFieldsQueryKey } from "./use-fields";

export interface CreateFieldOptionMutationParams {
  data: CreateFieldOptionMutationRequest;
  fieldId: CreateFieldOptionPathParams["field_id"];
}

type CreateFieldOptionMutationOptions = Partial<
  UseMutationOptions<
    CreateFieldOptionMutationResponse,
    unknown,
    CreateFieldOptionMutationParams
  >
>;

export function useCreateFieldOption(
  options: CreateFieldOptionMutationOptions = {},
) {
  const queryClient = useQueryClient();

  const { onSettled, ...restOptions } = options;

  return useMutation({
    mutationFn: ({ data, fieldId }) => createFieldOption(fieldId, data),
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getFieldsQueryKey(),
        refetchType: "all",
      });
      options.onSettled?.(...args);
    },
    ...restOptions,
  });
}
