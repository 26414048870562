import type { Account } from "@themis/api/gen/models/account";
import { LinkButton, SimpleTooltip } from "@themis/ui";
import { useClientDataGridBinding } from "@themis/ui-library/components/data-grid/binding/use-client-data-grid-binding";
import { DataGrid } from "@themis/ui-library/components/data-grid/data-grid";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";

import { Typography } from "@/components/Elements";

import {
  InfoPreviewActions,
  InfoPreviewFooter,
  InfoPreviewHeader,
  InfoPreviewSection,
  InfoPreviewTitle,
  NUM_PREVIEW_ITEMS,
} from "../../../../../misc/info-preview-section/info-preview-section";
import { useDocumentsTableColumns } from "../../../../config/use-documents-table-columns/use-documents-table-columns";
import { routes } from "../../../../routes";
import { DocumentsActions } from "../../../documents/documents-actions/documents-actions";

export function DocumentsPreview({ account }: { account?: Account }) {
  const { formatMessage } = useIntl();
  const { workspace_id, accountId } = useParams<{
    workspace_id: string;
    accountId: string;
  }>();
  const columns = useDocumentsTableColumns();

  const previewDocuments = account?.documents?.slice(0, NUM_PREVIEW_ITEMS);

  const { clientGridProps } = useClientDataGridBinding(previewDocuments);

  return (
    <InfoPreviewSection>
      <InfoPreviewHeader>
        <InfoPreviewTitle>
          {formatMessage({
            defaultMessage: "Attachments",
            description: "Attachments section title",
          })}
        </InfoPreviewTitle>
        <SimpleTooltip
          disabled={!account}
          tooltipTriggerProps={{ asChild: true }}
          title={
            account
              ? formatMessage({
                  defaultMessage: "Account is inactive",
                })
              : formatMessage({
                  defaultMessage: "Available after record saved",
                })
          }
        >
          <InfoPreviewActions>
            <DocumentsActions
              disabled={!account || account.status === "inactive"}
            />
          </InfoPreviewActions>
        </SimpleTooltip>
      </InfoPreviewHeader>
      {account && previewDocuments && previewDocuments.length > 0 && (
        <>
          <DataGrid
            data-testid="documents-table"
            columns={columns}
            width="100%"
            {...clientGridProps}
          />
          <InfoPreviewFooter>
            <Typography type="h4">
              {formatMessage(
                {
                  defaultMessage:
                    "Showing {numberOfShown} of {total} attachments",
                },
                {
                  numberOfShown: previewDocuments?.length ?? 0,
                  total: account?.documents?.length ?? 0,
                },
              )}
            </Typography>
            {(account?.documents?.length ?? 0) > NUM_PREVIEW_ITEMS && (
              <LinkButton
                color="tertiary"
                size="sm"
                to={generatePath(routes.accounts.detail.documents.overview, {
                  workspace_id,
                  accountId,
                })}
              >
                {formatMessage({ defaultMessage: "View all" })}
              </LinkButton>
            )}
          </InfoPreviewFooter>
        </>
      )}
    </InfoPreviewSection>
  );
}
