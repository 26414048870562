import { faker } from "@faker-js/faker";

import type {
  LegacyWebsiteMonitoringGroupChild200,
  LegacyWebsiteMonitoringGroupChildPathParams,
  LegacyWebsiteMonitoringGroupChildQueryParams,
  LegacyWebsiteMonitoringGroupChildQueryResponse,
} from "../models/LegacyWebsiteMonitoringGroupChild";
import { createMonitoringGroupAssetChild } from "./createMonitoringGroupAssetChild";

export function createLegacyWebsiteMonitoringGroupChildPathParams(): NonNullable<LegacyWebsiteMonitoringGroupChildPathParams> {
  return { monitoring_group_asset_id: faker.number.int() };
}

export function createLegacyWebsiteMonitoringGroupChildQueryParams(): NonNullable<LegacyWebsiteMonitoringGroupChildQueryParams> {
  return {
    filters: {},
    limit: faker.number.int(),
    offset: faker.number.int(),
    sort_by: {},
  };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupChild200(): NonNullable<LegacyWebsiteMonitoringGroupChild200> {
  return {
    data: faker.helpers.arrayElements([
      createMonitoringGroupAssetChild(),
    ]) as any,
  };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupChildQueryResponse(): NonNullable<LegacyWebsiteMonitoringGroupChildQueryResponse> {
  return {
    data: faker.helpers.arrayElements([
      createMonitoringGroupAssetChild(),
    ]) as any,
  };
}
