import type { ReactElement, ReactNode } from "react";

import { styled } from "../../../styles";
import { Typography } from "../../data-display/typography/typography";
import type { StackProps } from "../../layout/stack/stack";
import { Stack } from "../../layout/stack/stack";
import { RequiredLabel } from "../required-label/required-label";

export interface FormStaticLabelProps extends StackProps {
  label: ReactNode;
  value: ReactNode;
  required?: boolean;
  labelPlacement?: "start" | "end" | "top" | "bottom";
}

const StyledStack = styled(Stack)(({ theme }) => ({
  "&[data-label-placement=start]": {
    alignItems: "center",
  },
  "&[data-label-placement=end]": {
    alignItems: "center",
  },
  "&[data-label-placement=top]": {},
  "&[data-label-placement=bottom]": {},
}));

const directions: Record<
  NonNullable<FormStaticLabelProps["labelPlacement"]>,
  StackProps["direction"]
> = {
  start: "row",
  end: "row-reverse",
  top: "column",
  bottom: "column-reverse",
};

export function FormStaticLabel({
  label,
  labelPlacement = "start",
  value,
  required,
  ...rest
}: FormStaticLabelProps): ReactElement {
  return (
    <StyledStack
      direction={directions[labelPlacement]}
      data-label-placement={labelPlacement}
      {...rest}
    >
      <Typography
        component="div"
        color="textSecondary"
        variant="subtitle2"
        minWidth="10rem"
      >
        <RequiredLabel required={required}>{label}</RequiredLabel>
      </Typography>

      {typeof value === "string" ? (
        <Typography variant="body1">{value}</Typography>
      ) : (
        value
      )}
    </StyledStack>
  );
}
