import { isEmpty } from "lodash";
import { action, makeObservable, observable } from "mobx";

import type { ColorScheme } from "@/api";
import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { ModuleFieldOptions } from "../types/field-types";

export default class FieldOptionsStore {
  mainStore: MainStore;

  // Observable objects
  all: ModuleFieldOptions = {};
  colorSchemes: ColorScheme[] = [];

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      all: observable,
      colorSchemes: observable,

      setAll: action,
      setColorSchemes: action,
    });

    this.mainStore = mainStore;
  }

  // Actions

  setAll(value: ModuleFieldOptions) {
    // Do not reset field options in public form
    if (this.mainStore.isPublicForm && isEmpty(value)) {
      return;
    }

    if (isEmpty(value)) {
      this.all = {};
    } else {
      this.all = value;
    }
  }

  setColorSchemes(value: ColorScheme[]) {
    this.colorSchemes = value;
  }

  // API

  // GET /api/react/module_workspaces/:module_workspace_id/field_options
  async index(moduleWorkspaceID: number) {
    if (!moduleWorkspaceID) {
      return;
    }

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/field_options`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }

      this.setAll(response.data.field_options);
    } catch (error) {
      window.console.log(`"FieldOptions#index" error ${error}`);
    }
  }

  // POST /api/react/module_workspaces/:module_workspace_id/field_options/:field_name
  async create(
    moduleWorkspaceID: number,
    fieldName: string,
    optionDisplayName: string,
  ) {
    const params = { display_name: optionDisplayName };
    const data = { option: params };

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/field_options/${fieldName}`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }

      this.setAll(response.data.field_options);
    } catch (error) {
      window.console.log(`"FieldOptions#create" error ${error}`);
    }
  }

  // DELETE /api/react/module_workspaces/:module_workspace_id/field_options/:field_name
  async delete(
    moduleWorkspaceID: number,
    fieldName: string,
    optionName: string,
  ) {
    const params = { name: optionName };
    const data = { option: params };

    try {
      const response = await legacyApi({
        method: "DELETE",
        url: `${API_URL}/module_workspaces/${moduleWorkspaceID}/field_options/${fieldName}`,
        headers: this.mainStore.getHeaders(),
        data,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        return;
      }

      this.setAll(response.data.field_options);
    } catch (error) {
      window.console.log(`"FieldOptions#delete" error ${error}`);
    }
  }

  // Store Helpers

  optionsForField(fieldName: string) {
    return this.all[fieldName] || [];
  }

  filteredOptionsForField(fieldName: string) {
    const optionForFiltered =
      fieldName === "status"
        ? this.all[fieldName].filter((item) => item.name !== "archived")
        : this.all[fieldName];
    return optionForFiltered || [];
  }

  findColorScheme(colorSchemeID: number) {
    return this.colorSchemes.find(
      (colorScheme) => colorScheme.id === colorSchemeID,
    );
  }

  cleanup() {
    this.setAll({});
  }
}
