import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";

/**
 * @description Get a unique key to be used for keying local storage state for a table based on the workspace id and the record type path
 * @param workspaceId - The id of the workspace
 * @param recordTypePath - The path of the record type
 * @returns A unique key for the table
 */
export function getLocalStorageTableKey(
  workspaceId: string | number,
  recordTypePath: RecordTypePath,
) {
  return `${workspaceId}-${recordTypePath}`;
}
