import type { FieldFormat } from "@themis/api/gen/models/field";
import { fieldFormat } from "@themis/api/gen/models/field";
import { Divider } from "@themis/ui-library/components/data-display/divider/divider";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import Popup from "reactjs-popup";

import { Icon } from "@/components/Elements";
import { FIELD_TYPES_CUSTOM_COLUMN } from "@/components/helpers/fieldTypes";
import { iconForDataType } from "@/components/table/shared/ModuleTableColumn";
import { MAX_FIELD_NAME_LENGTH } from "@/constants";
import { useMainStore } from "@/contexts/Store";
import { useModuleDetection } from "@/hooks/useModuleDetection";
import { TopLevelModule } from "@/stores/types/module-workspaces-types";

import plusBlackIcon from "../../../../images/table-image/icon/plus-black.svg";
import plusIcon from "../../../../images/table-image/icon/plus.svg";
import Switch from "../Switch";
import { NumberFormatRadioGroup } from "./number-format-radio-group/number-format-radio-group";

export interface AddColumnButtonProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAdd: (...args: any[]) => any;
}

function AddColumnButton({ onAdd }: AddColumnButtonProps) {
  const mainStore = useMainStore();
  const location = useLocation();
  const currentModule = useModuleDetection(location);
  const { formatMessage } = useIntl();

  // State
  const [showPopup, setShowPopup] = useState(false);
  const [columnName, setColumnName] = useState("");
  const [isMultiselect, setIsMultiselect] = useState(true);
  const [onlyInternalWorkspace, setOnlyInternalWorkspace] = useState(true);
  const [isInternalUserVisible, setIsInternalUserVisible] = useState(false);
  const [include, setInclude] = useState<{
    users: boolean;
    contacts: boolean;
  } | null>(null);
  const [columnType, setColumnType] = useState<string | null>(null);
  const [isRequired, setIsRequired] = useState(false);
  const [numberFormat, setNumberFormat] = useState<FieldFormat>(
    fieldFormat.number,
  );

  const { canManageColumns } = mainStore.userPermissions;
  const { isCurrentWorkspaceArchived } = mainStore.workspaces;

  // Variables
  const { isIW, isCW, isCompanySuperAdmin, isCompanyAdmin } = mainStore.context;
  const selectedType = columnType
    ? FIELD_TYPES_CUSTOM_COLUMN.find(
        (fieldType) => fieldType.value === columnType,
      )
    : undefined;
  const isInternalUserVisibilityEnabled =
    isCW &&
    mainStore.context.themisModuleIdentifier ===
      TopLevelModule.ISSUE_MANAGEMENT &&
    (isCompanySuperAdmin || isCompanyAdmin);

  // Functions
  function closePopUp() {
    setShowPopup(false);
    setColumnType(null);
    setColumnName("");
    setIsRequired(false);
    setIsInternalUserVisible(false);
  }

  function openPopUp() {
    setShowPopup(true);
  }

  function handleSubmit() {
    onAdd({
      data_type: columnType,
      display_name: columnName,
      is_multiselect: isMultiselect,
      only_internal_workspace: onlyInternalWorkspace,
      is_required: isRequired,
      ...(include && { include_field: include }),
      ...(isInternalUserVisibilityEnabled && {
        is_visible_to_internal_users_only: isInternalUserVisible,
      }),
      ...(columnType === "com.askthemis.types.v1.number" && {
        format: numberFormat,
      }),
    });

    closePopUp();
  }

  const handleChangeNumberFormat = (value: FieldFormat) => {
    setNumberFormat(value);
  };

  useEffect(() => {
    if (selectedType?.value === "com.askthemis.types.v1.tag_user") {
      setInclude({ users: true, contacts: false });
    } else {
      setInclude(null);
    }
  }, [selectedType]);

  // Rendering
  const renderTrigger = (
    <div
      className={classNames("list-plus create-column-trigger", {
        active: showPopup,
        "pointer-events-none": !canManageColumns || isCurrentWorkspaceArchived,
      })}
      onMouseDown={() => setShowPopup(false)}
      data-testid="create-column-trigger"
    >
      <div
        className={classNames(
          {
            hidden: !canManageColumns,
            "create-column-button--disabled": isCurrentWorkspaceArchived,
          },
          "create-column-button",
        )}
        data-tooltip-id="tooltip"
        data-tooltip-content="Add custom column"
        data-tooltip-place="right"
      >
        <img src={showPopup ? plusIcon : plusBlackIcon} alt="plus-icon" />
      </div>
    </div>
  );

  const renderTitleBlock = (
    <div className="title-input-block">
      <input
        type="text"
        value={columnName}
        maxLength={MAX_FIELD_NAME_LENGTH}
        placeholder={formatMessage({ defaultMessage: "Enter Column Name" })}
        data-testid="create-column-name-input"
        onChange={(e) => setColumnName(e.target.value)}
      />
    </div>
  );

  const renderColumnTypeContent = () => {
    if (!selectedType) {
      return null;
    }
    const { value, label } = selectedType;

    return (
      <div className="title-block">
        <h5>{formatMessage({ defaultMessage: "Property" })}</h5>
        <ul>
          <li className="enabled">
            <Icon
              name={iconForDataType(selectedType.value)}
              color="generalMidnightDark"
            />
            {label}
            <button
              className="column-type-switch"
              type="button"
              onClick={() => setColumnType(null)}
            >
              <Icon name="switch" color="generalMidnightDark" />
            </button>
          </li>
        </ul>

        {value === "com.askthemis.types.v1.text" && (
          <p className="column-type-description">
            {formatMessage({
              defaultMessage:
                "Free text allows you to optionally prefill each new cell with a default value",
            })}
          </p>
        )}

        {value === "com.askthemis.types.v1.tag_user" && (
          <>
            <hr className="add-column-seperator" />
            <div className="column-type-option-additional-content">
              <h5>
                {formatMessage({
                  defaultMessage: "User type",
                })}
              </h5>
              <div className="switch-row-container">
                <p>
                  {formatMessage({
                    defaultMessage: "Users",
                  })}
                </p>
                <Switch
                  active
                  indeterminate={false}
                  checked={include?.users}
                  onChange={(event) =>
                    setInclude((prev) =>
                      prev
                        ? {
                            ...prev,
                            users: event.target.checked,
                          }
                        : null,
                    )
                  }
                />
              </div>
              <div className="switch-row-container">
                <p>
                  {formatMessage({
                    defaultMessage: "Contacts",
                  })}
                </p>
                <Switch
                  active
                  indeterminate={false}
                  checked={include?.contacts}
                  onChange={(event) =>
                    setInclude((prev) =>
                      prev
                        ? {
                            ...prev,
                            contacts: event.target.checked,
                          }
                        : null,
                    )
                  }
                />
              </div>
            </div>
            <hr className="add-column-seperator" />
          </>
        )}

        {[
          "com.askthemis.types.v1.option",
          "com.askthemis.types.v1.tag_user",
        ].includes(value) && (
          <div className="column-type-option-additional-content">
            <h5>
              {formatMessage({
                defaultMessage: "Options",
              })}
            </h5>
            <div className="switch-row-container">
              <p>
                {formatMessage({
                  defaultMessage: "Allow Multiple Selection",
                })}
              </p>
              <Switch
                active
                indeterminate={false}
                checked={isMultiselect}
                onChange={(event) => setIsMultiselect(event.target.checked)}
              />
            </div>
          </div>
        )}

        {value === "com.askthemis.types.v1.attachment" && (
          <div className="column-type-option-additional-content">
            <h5>
              {formatMessage({
                defaultMessage: "Options",
              })}
            </h5>
            <div className="switch-row-container">
              <p>
                {formatMessage({
                  defaultMessage: "Allow Multiple Attachments",
                })}
              </p>
              <Switch
                active
                indeterminate={false}
                checked={isMultiselect}
                onChange={(event) => setIsMultiselect(event.target.checked)}
              />
            </div>
          </div>
        )}

        {value === "com.askthemis.types.v1.link" && (
          <p className="column-type-description">
            {formatMessage({
              defaultMessage:
                "Link allows you to copy and paste external URL for quick access / reference",
            })}
          </p>
        )}

        {isIW && (
          <div className="column-type-option-additional-content">
            <div className="switch-row-container">
              <p>
                {formatMessage({
                  defaultMessage: "Add to Internal Workspace Only",
                })}
              </p>
              <Switch
                active
                indeterminate={false}
                checked={onlyInternalWorkspace}
                onChange={(event) =>
                  setOnlyInternalWorkspace(event.target.checked)
                }
              />
            </div>
          </div>
        )}
        {isInternalUserVisibilityEnabled && (
          <div className="column-type-option-additional-content">
            <div className="switch-row-container">
              <p>
                {formatMessage({
                  defaultMessage: "Visible to internal users only",
                })}
              </p>
              <Switch
                active
                indeterminate={false}
                checked={isInternalUserVisible}
                onChange={(event) =>
                  setIsInternalUserVisible(event.target.checked)
                }
              />
            </div>
          </div>
        )}
        <li
          className="enabled required-switch-row"
          data-tooltip-left={formatMessage({
            defaultMessage:
              "Required columns must be completed in order to finalize or lock a record.",
          })}
        >
          {formatMessage({
            defaultMessage: "Required Column",
          })}
          <div className="is-required-switch">
            <Switch
              active
              indeterminate={false}
              checked={isRequired}
              onChange={() => setIsRequired(!isRequired)}
            />
          </div>
        </li>
        {value === "com.askthemis.types.v1.number" && (
          <>
            <Divider />
            <NumberFormatRadioGroup
              value={numberFormat}
              onChange={handleChangeNumberFormat}
            />
          </>
        )}
      </div>
    );
  };

  const renderColumnTypes = (
    <ul>
      {FIELD_TYPES_CUSTOM_COLUMN.filter((type) => {
        // Filter out checklist type if we're in the training module
        if (
          currentModule === "training" &&
          type.value === "com.askthemis.types.v1.checklist"
        ) {
          return false;
        }
        return true;
      }).map((type) => (
        <li
          className="enabled"
          key={type.value}
          onClick={() => setColumnType(type.value)}
          data-testid={`create-${type.code}-column-type-item`}
        >
          <Icon
            name={iconForDataType(type.value)}
            color="generalMidnightDark"
          />
          {type.label}
        </li>
      ))}
    </ul>
  );

  const renderSubmitButton = (
    <button
      type="button"
      disabled={
        !columnName ||
        !columnType ||
        columnName.length > MAX_FIELD_NAME_LENGTH ||
        (selectedType?.value === "com.askthemis.types.v1.tag_user" &&
          Boolean(include) &&
          !include?.users &&
          !include?.contacts)
      }
      className="add-column-button"
      onClick={handleSubmit}
      data-testid="create-column-submit"
    >
      {formatMessage({
        defaultMessage: "Create Column",
      })}
    </button>
  );

  return (
    <Popup
      position="bottom right"
      trigger={renderTrigger}
      open={showPopup}
      onOpen={openPopUp}
      onClose={closePopUp}
      keepTooltipInside
    >
      <div
        className="table-dropdown title-select custom-columns-popup"
        data-testid="create-column-popup"
      >
        {renderTitleBlock}
        {columnType ? renderColumnTypeContent() : renderColumnTypes}
        {renderSubmitButton}
      </div>
    </Popup>
  );
}

export default observer(AddColumnButton);
