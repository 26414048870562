import React from "react";
import {
  PiDownloadSimpleBold,
  PiInfoBold,
  PiThumbsUpBold,
  PiWarningBold,
  PiXBold,
} from "react-icons/pi";
import { toast as sonnerToast } from "sonner";

import { cn } from "../../lib/utils";
import { IconButton } from "../Button/IconButton";

type ToastVariant = "success" | "error" | "download" | "info";

const variantIconMap = {
  success: {
    Icon: PiThumbsUpBold,
    gradientStyle: "tw-fill-[url(#gradientV3)]",
  },
  error: {
    Icon: PiWarningBold,
    gradientStyle: "tw-fill-[url(#gradientLavender)]",
  },
  download: {
    Icon: PiDownloadSimpleBold,
    gradientStyle: "tw-fill-[url(#gradientV3)]",
  },
  info: {
    Icon: PiInfoBold,
    gradientStyle: "tw-fill-[url(#gradientV3)]",
  },
} as const;

function Gradients() {
  return (
    <>
      <svg className="tw-absolute tw-h-0 tw-w-0">
        <linearGradient id="gradientV3" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop stopColor="#E3AEFF" offset="0%" />
          <stop stopColor="#36B5FF" offset="100%" />
        </linearGradient>
      </svg>
      <svg className="tw-absolute tw-h-0 tw-w-0">
        <linearGradient
          id="gradientLavender"
          x1="100%"
          y1="0%"
          x2="100%"
          y2="100%"
        >
          <stop stopColor="#9B9BED" offset="0%" />
          <stop stopColor="#FF77A8" offset="100%" />
        </linearGradient>
      </svg>
    </>
  );
}

export function useToast() {
  function toast({
    content,
    variant,
    hideCloseButton,
    onClick,
  }: {
    content: string;
    variant: ToastVariant;
    hideCloseButton?: boolean;
    onClick?: () => void;
  }) {
    return sonnerToast.custom((toastId) => {
      const { Icon, gradientStyle } = variantIconMap[variant];

      function handleClose(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        sonnerToast.dismiss(toastId);
      }

      return (
        <div
          onClick={() => {
            if (hideCloseButton) {
              sonnerToast.dismiss(toastId);
            }

            onClick && onClick();
          }}
          className={cn(
            "tw-inline-flex tw-max-w-xl tw-items-center tw-justify-center tw-gap-2 tw-rounded-md tw-bg-neutral-500 tw-px-6 tw-py-4 tw-font-sans tw-text-sm tw-font-medium tw-text-neutral-25 tw-shadow-dropdown",
            { "tw-cursor-pointer": onClick },
          )}
        >
          <Gradients />
          <Icon className={cn("tw-h-5 tw-w-5 tw-shrink-0", gradientStyle)} />
          {content}
          {!hideCloseButton && (
            <IconButton
              size="sm"
              color="transparentWhite"
              Icon={PiXBold}
              onClick={(e) => handleClose(e)}
            />
          )}
        </div>
      );
    });
  }

  return toast;
}
