import { cx, type VariantProps } from "class-variance-authority";
import React from "react";
import type { ReactNode } from "react";
import { type IconType } from "react-icons";
import type { LinkProps } from "react-router-dom";
import { Link } from "react-router-dom";

import { cn } from "../../lib/utils";
import { buttonVariants } from "./Button";
import { buttonBaseVariants } from "./ButtonBase";

export type LinkButtonProps = LinkProps &
  VariantProps<typeof buttonVariants> &
  VariantProps<typeof buttonBaseVariants> & {
    children?: ReactNode;
    LeftIcon?: IconType;
    RightIcon?: IconType;
  };

const LinkButton = React.forwardRef<HTMLAnchorElement, LinkButtonProps>(
  (
    { className, children, color, size = "md", LeftIcon, RightIcon, ...props },
    ref,
  ) => (
    <Link
      className={cn(
        cx(
          "tw-no-underline",
          buttonBaseVariants({ color }),
          buttonVariants({ size, className }),
        ),
      )}
      ref={ref}
      {...props}
    >
      {LeftIcon && <LeftIcon className="tw-h-4 tw-w-4" />}
      {children}
      {RightIcon && <RightIcon className="tw-h-4 tw-w-4" />}
    </Link>
  ),
);

LinkButton.displayName = "LinkButton";

export { LinkButton };
