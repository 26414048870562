import type {
  MouseEvent,
  MouseEventHandler,
  ReactElement,
  ReactNode,
} from "react";
import { Children, cloneElement, isValidElement, useRef } from "react";

import { useOpen } from "../../../hooks/use-open/use-open";
import type { MenuProps } from "../menu/menu";
import { Menu } from "../menu/menu";

export interface DropdownMenuProps extends Omit<MenuProps, "open"> {
  trigger: ReactElement;
  children: ReactNode;
  onClose?: (event: MouseEvent) => void;
  onOpenChange?: (open: boolean) => void;
}

interface ItemElementProps {
  onClick?: MouseEventHandler;
}

export function DropdownMenu({
  trigger,
  children,
  onClose,
  onOpenChange,
  ...otherProps
}: DropdownMenuProps) {
  const buttonRef = useRef();
  const menuState = useOpen();

  const handleClose = (event: MouseEvent) => {
    onClose?.(event);

    menuState.close();
    onOpenChange?.(false);
  };

  const handleItemClick = (event: MouseEvent, itemProps: ItemElementProps) => {
    itemProps.onClick?.(event);
    menuState.close();
    onOpenChange?.(false);
  };

  const handleTriggerClick = () => {
    menuState.open();
    onOpenChange?.(true);
  };

  return (
    <>
      {cloneElement(trigger, {
        ref: buttonRef,
        onClick: handleTriggerClick,
      })}

      <Menu
        anchorEl={buttonRef.current}
        open={menuState.isOpen}
        onClose={handleClose}
        {...otherProps}
      >
        {Children.toArray(children).map((child) =>
          isValidElement<ItemElementProps>(child)
            ? cloneElement(child, {
                onClick: (event: MouseEvent) =>
                  handleItemClick(event, child.props),
              })
            : child,
        )}
      </Menu>
    </>
  );
}
