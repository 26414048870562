import { observer } from "mobx-react";
import React, { useEffect } from "react";

import type { FieldConfiguration, FieldOption } from "@/api";
import { useFields } from "@/api/queries/fields/use-fields";
import { useMainStore } from "@/contexts/Store";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import PieChart from "../sharedGraphs/pie-chart";
import RecordsByMonthStackedBar from "../sharedGraphs/records-by-month-stacked-bar";
import RecordsByMonthStackedBarWorkspaces from "../sharedGraphs/records-by-month-stacked-bar-workspaces";
import WorkspaceSelection from "../workspaceSelection/WorkspaceSelection";

const ReportsPage = ({
  identifier,
  defaultDateColumn,
  defaultGroupColumn,
  tableName,
  showPieChart = true,
}: {
  identifier: string;
  defaultDateColumn: string;
  defaultGroupColumn: string;
  showPieChart?: boolean;
  tableName?: string;
}) => {
  const mainStore = useMainStore();

  const { data: fields, isPending: isColumnsPending } = useFields({
    companyId: mainStore.context.companyID!,
    recordType: "trainings",
  });

  const workspaceIDs = mainStore.reports.selectedWorkspaceIDs;
  useEffect(() => {
    if (
      mainStore.featureFlags.getIsEnabled(
        FEATURE_FLAG_ID.GENG_TRAINING_MODULE_REVAMP,
      ) &&
      identifier === "training"
    ) {
      const optionFields = fields?.filter(
        (field: FieldConfiguration) =>
          field.field_type === "select" || field.field_type === "status",
      );
      mainStore.fields.setOptionFields(optionFields);
      mainStore.fields.setAllGroupableFields(optionFields);
      // Restructure options to match the format expected by the existing components
      const formattedOptions = optionFields?.reduce(
        (acc: { [key: string]: FieldOption[] }, field: FieldConfiguration) => {
          acc[field.path] = (field.options || []).map((option: FieldOption) => {
            const { key, value, ...rest } = option;
            return {
              name: key,
              display_name: value,
              ...rest,
            } as unknown as FieldOption;
          });
          return acc;
        },
        {},
      );
      mainStore.reports.setAllCurrentFieldOptions(formattedOptions);
    } else {
      const timeoutId = setTimeout(() => {
        mainStore.fields.getAllFieldsByDataType({
          workspaceIDs: workspaceIDs.map((id) => parseInt(id.toString())),
          identifier,
        });
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [workspaceIDs, isColumnsPending]);

  return (
    <div className="report-dashboard-wrap">
      <WorkspaceSelection />
      <RecordsByMonthStackedBarWorkspaces
        identifier={identifier}
        dateColumnDefault={defaultDateColumn}
      />
      <RecordsByMonthStackedBar
        identifier={identifier}
        dateColumnDefault={defaultDateColumn}
        groupColumnDefault={defaultGroupColumn}
        tableName={tableName}
      />
      {showPieChart && (
        <PieChart identifier={identifier} columnDefault={defaultGroupColumn} />
      )}
    </div>
  );
};

export default observer(ReportsPage);
