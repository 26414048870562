import React from "react";

interface CustomLinkProps {
  to: string;
  className: string;
  children: React.ReactNode;
}

function CustomLink({ to, className, children }: CustomLinkProps) {
  return to ? (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={to}
      className={className}
    >
      {children}
    </a>
  ) : (
    <div className={className}>{children}</div>
  );
}

export default CustomLink;
