import { Tab } from "@themis/ui-library/components/navigation/tabs/tab/tab";
import { Tabs } from "@themis/ui-library/components/navigation/tabs/tabs";
import React from "react";
import { useIntl } from "react-intl";
import { generatePath, Link, useLocation, useParams } from "react-router-dom";

import { routes } from "../routes";

interface AccountTabsProps {
  RightComponent?: React.ReactNode;
}

function AccountsHeader({ RightComponent }: AccountTabsProps) {
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const location = useLocation();
  const tabValue = location.pathname;

  const { formatMessage } = useIntl();

  const overviewPath = generatePath(routes.accounts.overview, { workspace_id });
  const contractsPath = generatePath(routes.accounts.contracts, {
    workspace_id,
  });

  return (
    <div className="tw-flex tw-h-12 tw-w-auto tw-items-center tw-justify-between tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primaryDim-100 tw-bg-neutral-25 tw-px-5 tw-py-0">
      <Tabs value={tabValue} role="navigation">
        <Tab
          label={formatMessage({ defaultMessage: "Accounts" })}
          value={overviewPath}
          to={overviewPath}
          component={Link}
        />
        <Tab
          label={formatMessage({ defaultMessage: "Contracts" })}
          value={contractsPath}
          to={contractsPath}
          component={Link}
        />
      </Tabs>

      {RightComponent}
    </div>
  );
}

export default AccountsHeader;
