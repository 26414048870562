import type { IconType } from "react-icons";
import {
  PiCalendarBlankBold,
  PiLinkBold,
  PiListBulletsBold,
  PiNumberSquareOne,
  PiTagBold,
  PiTextAlignLeft,
  PiTextColumnsBold,
} from "react-icons/pi";
import { useIntl } from "react-intl";

import type { FieldConfigurationFieldType } from "../../api/gen/models/FieldConfiguration";

type ColumnTypeOptionsType = {
  [type in FieldConfigurationFieldType]: {
    Icon: IconType | null;
    label: string | null;
  };
};

export function useColumnTypeOptions(
  type: FieldConfigurationFieldType | undefined,
) {
  const { formatMessage } = useIntl();

  const columnTypeOptions: ColumnTypeOptionsType = {
    date: {
      Icon: PiCalendarBlankBold,
      label: formatMessage({ defaultMessage: "Date" }),
    },
    datetime: {
      Icon: PiCalendarBlankBold,
      label: formatMessage({ defaultMessage: "Date & Time" }),
    },
    decimal: {
      Icon: PiNumberSquareOne,
      label: formatMessage({ defaultMessage: "Number" }),
    },
    numeric: {
      Icon: PiNumberSquareOne,
      label: formatMessage({ defaultMessage: "Number" }),
    },
    reference: {
      Icon: PiLinkBold,
      label: formatMessage({ defaultMessage: "Reference" }),
    },
    section: {
      Icon: PiTagBold,
      label: formatMessage({ defaultMessage: "Section" }),
    },
    select: {
      Icon: PiListBulletsBold,
      label: formatMessage({ defaultMessage: "Dropdown" }),
    },
    status: {
      Icon: PiTextColumnsBold,
      label: formatMessage({ defaultMessage: "Status" }),
    },
    text: {
      Icon: PiTextAlignLeft,
      label: formatMessage({ defaultMessage: "Text" }),
    },
    textarea: {
      Icon: PiTextAlignLeft,
      label: formatMessage({ defaultMessage: "Text" }),
    },
    reviews: {
      Icon: null,
      label: null,
    },
    computed: {
      Icon: null,
      label: null,
    },
  };

  return type
    ? columnTypeOptions[type]
    : {
        Icon: null,
        label: null,
      };
}
