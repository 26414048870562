import { DotsThree } from "@phosphor-icons/react";
import { IconButton } from "@themis/ui-library/components/inputs/icon-button/icon-button";
import { DropdownMenu } from "@themis/ui-library/components/navigation/dropdown-menu/dropdown-menu";
import type { PropsWithChildren } from "react";

export function MoreOptionsDropdownMenu({ children }: PropsWithChildren) {
  return (
    <DropdownMenu
      trigger={
        <IconButton color="inherit">
          <DotsThree weight="bold" />
        </IconButton>
      }
    >
      {children}
    </DropdownMenu>
  );
}
