import type { CustomCellEditorProps } from "ag-grid-react";
import React, { useRef, useState } from "react";

import type { FilePickerProps } from "../../Form/FilePicker";
import { FilePicker } from "../../Form/FilePicker";

type Props = CustomCellEditorProps &
  FilePickerProps & {
    value: string;
    setValue: (file: File | undefined) => void;
    handleSelected: (file: File) => void;
  };

function AttachmentCell({ value, setValue, handleSelected, ...props }: Props) {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const pickerRef = useRef<HTMLButtonElement>(null);
  const editable = Boolean(props.colDef?.editable);

  const handleSelectedPlaceholder = (file: File) => {
    let _progress = 0;
    setProgress(0);
    setLoading(true);

    const fakeLoading = setInterval(() => {
      if (_progress < 100) {
        _progress += 1;
        setProgress(_progress);
        return;
      }

      clearInterval(fakeLoading);
      pickerRef.current?.click();
      setValue(file);
      setProgress(0);
      setLoading(false);
    }, 15);
  };

  const file = {
    name: value?.file_name,
    type: value?.content_type,
    url: value?.file_url,
  };

  return (
    <div className="tw-h-full tw-w-full">
      <FilePicker
        ref={pickerRef}
        onSelectFile={handleSelected || handleSelectedPlaceholder}
        onRemoveFile={() => setValue(undefined)}
        percentage={progress}
        isLoading={loading}
        file={file}
        className="tw-h-full tw-w-full tw-rounded-none tw-border-none group-hover/row:tw-bg-neutral-50"
        isOpen={
          props.api.getFocusedCell()?.column.getColId() ===
          props.column.getColId()
        }
        readOnly={!editable}
        {...props}
      />
    </div>
  );
}

export default AttachmentCell;
