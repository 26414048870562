import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { useRouteWorkspaceId } from "../../../hooks/use-route-workspace-id";
import type { ResponseConfig } from "../../client";
import type {
  DestroyManyMutationResponse,
  DestroyManyPathParams,
  DestroyManyQueryParams,
} from "../../gen";
import { destroyMany } from "../../gen/axios/themisRecordsController";
import { getRecordsQueryKey } from "./get-records-query-key";

interface UseDeleteBulkRecordsParams {
  recordType: DestroyManyPathParams["record_type"];
  companyId: DestroyManyPathParams["company_id"];
}

export const useDeleteRecords = <TError = Error, TContext = unknown>(
  { companyId, recordType }: UseDeleteBulkRecordsParams,
  {
    onSettled,
    ...options
  }: Partial<
    UseMutationOptions<
      ResponseConfig<DestroyManyMutationResponse>,
      TError,
      DestroyManyQueryParams,
      TContext
    >
  > = {},
) => {
  const workspaceId = useRouteWorkspaceId();

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (params) => destroyMany(companyId, recordType, params),
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getRecordsQueryKey({ recordType, workspaceId }),
      });

      onSettled?.(...args);
    },
    ...options,
  });
};
