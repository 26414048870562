import { faker } from "@faker-js/faker";

import type { Attestation } from "../models/Attestation";
import { createThemisRecord } from "./createThemisRecord";

export function createAttestation(
  data?: NonNullable<Partial<Attestation>>,
): NonNullable<Attestation> {
  return Object.assign({}, createThemisRecord(), {
    status: faker.helpers.arrayElement<any>([
      "draft",
      "in_progress",
      "completed",
    ]),
    description: faker.string.alpha(),
    message: faker.string.alpha(),
    owner_id: faker.number.int(),
    due_date: faker.string.alpha(),
    sent_date: faker.string.alpha(),
    completion_percentage: faker.number.float(),
  });
}
