import { http } from "msw";

import { createExportPdfQueryResponse } from "../createExportPdf";

export const exportPdfHandler = http.get(
  "*/:record_type/:record_id/pdf_reports",
  function handler(info) {
    return new Response(JSON.stringify(createExportPdfQueryResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
