import type { ReactNode } from "react";
import { forwardRef } from "react";

import { styled } from "../../../../styles";
import type { BoxProps } from "../../../layout/box/box";
import { Box } from "../../../layout/box/box";

const StyledBox = styled(Box)({
  boxSizing: "border-box",

  "*": {
    boxSizing: "border-box",
  },
});

interface DefaultCellWrapperProps extends BoxProps {
  children: ReactNode;
}

export const DefaultCellWrapper = forwardRef<
  HTMLDivElement,
  DefaultCellWrapperProps
>(
  // eslint-disable-next-line prefer-arrow-callback
  function DefaultCellWrapper({ children, ...otherProps }, ref) {
    return (
      <StyledBox
        paddingY={0.5}
        paddingX={1}
        width="100%"
        ref={ref}
        {...otherProps}
      >
        {children}
      </StyledBox>
    );
  },
);
