import { http } from "msw";

import { createUpdatePublicFormLayoutMutationResponse } from "../createUpdatePublicFormLayout";

export const updatePublicFormLayoutHandler = http.put(
  "*/module_workspaces/:module_workspace_id/public_form_layout",
  function handler(info) {
    return new Response(
      JSON.stringify(createUpdatePublicFormLayoutMutationResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
