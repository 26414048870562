import "./questionnaire-scoring.scss";

import React from "react";

import { Typography } from "@/components/Elements";
import { getDefinitionsFromScores } from "@/features/risk-assessment/utils/scoring-helpers";

import type { AssessmentScoring as Scoring } from "../../../types/assessment";
import type { RiskArea } from "../../../types/risk-area";
import type { RiskMethodology } from "../../../types/risk-methodology";
import RiskAreaScoring from "../../Assessment/AssessmentScoring/RiskAreaScoring";
import MiniTag from "../../MiniTag/MiniTag";

interface Props {
  scoring: Scoring;
  riskMethodology: RiskMethodology;
  riskAreas: RiskArea[];
  headerText?: string;
}

function QuestionnaireScoring({ riskAreas, scoring, riskMethodology }: Props) {
  const {
    inherentRiskRating,
    residualRiskRating,
    overallRiskRating,
    controlRiskRating,
  } = getDefinitionsFromScores(scoring, riskMethodology);

  return (
    <div className="questionnaire-scoring">
      <Typography
        label="Overall Scoring"
        color="generalMidnightDark"
        weight="semiBold"
        className="questionnaire-scoring__overall-scoring-text"
      />
      <div className="questionnaire-scoring__overall-scoring">
        {overallRiskRating && (
          <div>
            <div className="questionnaire-scoring__score">
              <MiniTag
                color={overallRiskRating.color}
                label={overallRiskRating.text}
                variant="pill"
              />
            </div>
            <Typography
              label={scoring.overall_risk_score}
              color="generalMidnightDark"
              size="xlh"
              weight="semiBold"
            />
            <Typography
              label="Overall Risk Score & Level"
              color="extrasBlueGrayDarker"
              weight="semiBold"
            />
          </div>
        )}
        {inherentRiskRating && (
          <div>
            <div className="questionnaire-scoring__score">
              <MiniTag
                color={inherentRiskRating.color}
                label={inherentRiskRating.text}
                variant="pill"
              />
            </div>
            <Typography
              label={scoring.inherent_risk_score}
              color="generalMidnightDark"
              size="xlh"
              weight="semiBold"
            />
            <Typography
              label="Inherent Risk Score & Level"
              color="extrasBlueGrayDarker"
              weight="semiBold"
            />
          </div>
        )}
        {controlRiskRating && (
          <div>
            <div className="questionnaire-scoring__score">
              <MiniTag
                color={controlRiskRating.color}
                label={controlRiskRating.text}
                variant="pill"
              />
            </div>
            <Typography
              label={scoring.control_risk_score}
              color="generalMidnightDark"
              size="xlh"
              weight="semiBold"
            />
            <Typography
              label="Control Risk Score & Level"
              color="extrasBlueGrayDarker"
              weight="semiBold"
            />
          </div>
        )}
        {residualRiskRating && (
          <div>
            <MiniTag
              color={residualRiskRating.color}
              label={residualRiskRating.text}
              variant="pill"
            />
            <Typography
              label="Residual Risk Level"
              color="extrasBlueGrayDarker"
              weight="semiBold"
            />
          </div>
        )}
      </div>
      <RiskAreaScoring
        riskAreas={riskAreas}
        scoring={scoring}
        riskMethodology={riskMethodology}
      />
    </div>
  );
}

export default QuestionnaireScoring;
