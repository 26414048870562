import "./partners-questionnaires.scss";

import { parseISO } from "date-fns";
import { filter } from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useMemo, useState } from "react";
import { generatePath, useHistory, useParams } from "react-router-dom";

import { QuestionnaireAPI } from "@/api/legacy/risk-assessment";
import ViewModuleUsers from "@/components/dashboard/ViewModuleUsers";
import { Button } from "@/components/Elements";
import DashboardHeader from "@/components/shared/DashboardHeader";
import { useMainStore } from "@/contexts/Store";
import {
  areGroupsValidForSubmit,
  copyQuestion,
  createNewGroup,
  mapQuestionsToGroups,
  markQuestionDestroyed,
  prepareQuestionnaireUpsertRequest,
} from "@/stores/helpers/RiskAssessmentHelpers";

import FlexDashboardContent from "../components/FlexDashboardContent/FlexDashboardContent";
import FlexDashboardContentWrapper from "../components/FlexDashboardContentWrapper/FlexDashboardContentWrapper";
import ModuleHeader from "../components/ModuleHeader/ModuleHeader";
import PopupMenu from "../components/PopupMenu/PopupMenu";
import PopupMenuContentItem from "../components/PopupMenu/PopupMenuContentItem";
import PopupMenuHeader from "../components/PopupMenu/PopupMenuHeader";
import QuestionnaireBuilder from "../components/Questionnaires/QuestionnaireBuilder/QuestionnaireBuilder";
import QuestionnaireProgress from "../components/Questionnaires/QuestionnaireProgress/QuestionnaireProgress";
import { REVIEWER } from "../config";
import { routes } from "../routes";
import type {
  Question,
  QuestionGroup,
  Questionnaire,
  ResponderType,
  ReviewerType,
} from "../types";
import { QuestionnaireStatus } from "../types";

const PartnersQuestionnaireCreatePage = () => {
  const mainStore = useMainStore();
  const history = useHistory();
  const { record_version_id, template_id, workspace_id } = useParams<{
    workspace_id: string;
    record_version_id: string;
    template_id: string;
  }>();

  const [activeGroupId, setActiveGroupId] = useState<number>();
  const [dueDate, setDueDate] = useState<Date | null>(null);

  const [showSaveQuestionnairePopupOpen, setShowSaveQuestionnairePopupOpen] =
    useState(false);

  // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>(null);
  // const originalQuestionnaireRef = useRef<Questionnaire | null>(null);
  const [isInternal, setIsInternal] = useState(
    questionnaire?.is_internal || false,
  );

  const [isPasswordProtected, setIsPasswordProtected] = useState(false);

  useEffect(() => {
    if (template_id) {
      QuestionnaireAPI.get(Number(template_id))
        .then((questionnaireTemplate) => {
          const newQuestionnaire = {
            ...questionnaireTemplate,
            groups: mapQuestionsToGroups(
              questionnaireTemplate.questions,
              questionnaireTemplate.question_groups,
            ),
            name: questionnaireTemplate.name,
          };
          setQuestionnaire(newQuestionnaire);

          if (newQuestionnaire.due_date) {
            setDueDate(parseISO(newQuestionnaire.due_date.toString()));
          }
          setIsInternal(newQuestionnaire.is_internal);
          setActiveGroupId(newQuestionnaire.groups[0].id);
        })
        .catch((error: unknown) => {
          mainStore.toast.setErrorFromResponse(error);
          history.replace(
            generatePath(routes.PARTNERS_QUESTIONNAIRES_PATH, {
              workspace_id: Number(workspace_id),
              record_version_id: Number(record_version_id),
            }),
          );
        });
    } else {
      const groups: QuestionGroup[] = [createNewGroup("New Section", 0)];
      setActiveGroupId(groups[0].id);
      // @ts-expect-error TS(2345)
      setQuestionnaire({
        groups,
        name: "",
      });
    }
  }, [template_id]);
  const setAssignedIDs = (
    ids: number[],
    assignmentType: ReviewerType | ResponderType,
  ) => {
    if (!activeGroupId) {
      return;
    }
    const activeGroup = questionnaire.groups.find(
      (group) => group.id === activeGroupId,
    );
    const generalAssignments =
      activeGroup![assignmentType === REVIEWER ? "assignments" : "responders"];
    const assignmentUserIDs = generalAssignments.map((group) => group.user_id);
    ids.forEach((id) => {
      if (!assignmentUserIDs.includes(id)) {
        generalAssignments.push({
          user_id: id,
          assignment_type: assignmentType,
        });
      }
    });
    generalAssignments.forEach((assignment) => {
      if (!ids.includes(assignment.user_id)) {
        assignment._destroy = true;
      }
      if (ids.includes(assignment.user_id) && assignment._destroy) {
        assignment._destroy = false;
      }
    });

    const index = questionnaire.groups.findIndex(
      (group) => group.id === activeGroupId,
    );
    const newQuestionnaire = { ...questionnaire };
    newQuestionnaire.groups[index] = activeGroup!;
    setQuestionnaire(newQuestionnaire);
  };

  const save = async (saveAsTemplate = false) => {
    try {
      if (!questionnaire) {
        return;
      }

      if (!questionnaire.name.trim()) {
        return mainStore.toast.setErrorText("Name required");
      }

      if (!areGroupsValidForSubmit(questionnaire.groups)) {
        return mainStore.toast.setErrorText(
          "Names for each question and question choice are required",
        );
      }

      const questionnaireUpsert = prepareQuestionnaireUpsertRequest(
        questionnaire.name,
        questionnaire.risk_methodology_id,
        questionnaire.groups,
        isInternal,
        false,
        saveAsTemplate,
        isPasswordProtected,
        dueDate,
      );

      await QuestionnaireAPI.createForPartner(
        Number(workspace_id),
        Number(record_version_id),
        questionnaireUpsert,
      );

      if (saveAsTemplate) {
        mainStore.toast.setInfoText(
          `“${questionnaire.name}” has been saved as template!`,
          true,
        );
      }
      history.push(
        generatePath(routes.PARTNERS_QUESTIONNAIRES_PATH, {
          workspace_id,
          record_version_id,
        }),
      );
    } catch (error: unknown) {
      mainStore.toast.setErrorFromResponse(error);
    }
  };

  const onNameChange = (name: string): void => {
    setQuestionnaire((previousQuestionnaire) => ({
      ...previousQuestionnaire,
      name,
    }));
  };

  const onRiskMethodologyChange = (risk_methodology_id: number): void => {
    setQuestionnaire({
      ...questionnaire,
      risk_methodology_id,
    });
  };

  const onQuestionChange = (changedQuestion: Question): void => {
    setQuestionnaire((previousQuestionnaire) => ({
      ...previousQuestionnaire,
      groups: previousQuestionnaire.groups.map((group) =>
        group.id === activeGroupId
          ? {
              ...group,
              questions: group.questions.map((question) =>
                question.id === changedQuestion.id ? changedQuestion : question,
              ),
            }
          : group,
      ),
    }));
  };

  const onQuestionReorder = (questions: Question[]) => {
    setQuestionnaire((previousQuestionnaire) => ({
      ...previousQuestionnaire,
      groups: previousQuestionnaire.groups.map((group) =>
        group.id === activeGroupId
          ? {
              ...group,
              questions,
            }
          : group,
      ),
    }));
  };

  const onQuestionGroupChange = (newGroupId: number, question: Question) => {
    setQuestionnaire((previousQuestionnaire) => ({
      ...previousQuestionnaire,
      groups: previousQuestionnaire.groups.map((group) => {
        if (group.id === activeGroupId) {
          return {
            ...group,
            questions: filter(group.questions, (q) => q.id !== question.id),
          };
        } else if (group.id === newGroupId) {
          return {
            ...group,
            questions: [...group.questions, question],
          };
        }
        return group;
      }),
    }));
  };

  const onQuestionCopy = (questionToCopy: Question): void => {
    setQuestionnaire({
      ...questionnaire,
      groups: questionnaire.groups.map((group) =>
        group.id === activeGroupId
          ? {
              ...group,
              questions: group.questions.flatMap((question) =>
                question.id === questionToCopy.id
                  ? [questionToCopy, copyQuestion(questionToCopy)]
                  : question,
              ),
            }
          : group,
      ),
    });
  };

  const onQuestionDelete = (questionToDelete: Question): void => {
    setQuestionnaire({
      ...questionnaire,
      // @ts-expect-error TS(2322) FIXME: Type '{ questions: (Question<QuestionType> | null)... Remove this comment to see the full error message
      groups: questionnaire.groups.map((group) =>
        group.id === activeGroupId
          ? {
              ...group,
              questions: group.questions
                .map((question) => {
                  if (question.id !== questionToDelete.id) {
                    return question;
                  }

                  return question.isNew
                    ? null
                    : markQuestionDestroyed(question);
                })
                .filter((question) => Boolean(question)),
            }
          : group,
      ),
    });
  };

  const onQuestionAdd = (question: Question) => {
    setQuestionnaire({
      ...questionnaire,
      groups: questionnaire.groups.map((group) =>
        group.id === activeGroupId
          ? {
              ...group,
              questions: group.questions.concat(structuredClone(question)),
            }
          : group,
      ),
    });
  };

  const onGroupAdd = (group: QuestionGroup) => {
    setQuestionnaire((currQuestionnaire) => ({
      ...currQuestionnaire,
      groups: currQuestionnaire.groups.concat(group),
    }));
  };

  const onGroupDelete = (groupId: number) => {
    // @ts-expect-error TS(2345) FIXME: Argument of type '(currQuestionnaire: Questionnair... Remove this comment to see the full error message
    setQuestionnaire((currQuestionnaire) => ({
      ...currQuestionnaire,
      groups: currQuestionnaire.groups
        .map((group) => {
          if (group.id !== groupId) {
            return group;
          }

          return group.isNew
            ? null
            : {
                ...group,
                _destroy: true,
              };
        })
        .filter((group) => Boolean(group)),
    }));
  };

  const onGroupNameChange = (groupId: number, groupName: string) => {
    setQuestionnaire({
      ...questionnaire,
      groups: questionnaire.groups.map((group) =>
        group.id === groupId
          ? {
              ...group,
              name: groupName,
            }
          : group,
      ),
    });
  };

  const activeGroups = useMemo(
    () => questionnaire?.groups.filter((group) => !group._destroy),
    [questionnaire?.groups],
  );

  return (
    <FlexDashboardContent>
      <DashboardHeader
        title="Create & Complete Questionnaire"
        onBackClick={() =>
          history.push(
            generatePath(routes.PARTNERS_QUESTIONNAIRES_PATH, {
              workspace_id: Number(workspace_id),
              record_version_id: Number(record_version_id),
            }),
          )
        }
        LeftActionBar={<ViewModuleUsers />}
      />
      <FlexDashboardContentWrapper>
        <ModuleHeader>
          <QuestionnaireProgress
            questionnaireStatus={QuestionnaireStatus.NOT_STARTED}
          />
          <PopupMenu
            anchorNode={
              <Button
                label="Save and Review Questionnaire"
                size="de"
                theme={showSaveQuestionnairePopupOpen ? "active" : "primary"}
                onClick={() => {
                  setShowSaveQuestionnairePopupOpen(true);
                }}
              />
            }
            open={showSaveQuestionnairePopupOpen}
            placement="bottom right"
            onClose={() => {
              setShowSaveQuestionnairePopupOpen(false);
            }}
            mainMenu={
              <>
                <PopupMenuHeader
                  label="Save to Template?"
                  subtext="Do you want to save this questionnaire as a template?"
                  noHoverEffect
                />
                <PopupMenuContentItem
                  content={
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      <Button
                        label="Save Only"
                        size="de"
                        theme="primary"
                        onClick={() => {
                          setShowSaveQuestionnairePopupOpen(false);
                          save();
                        }}
                      />
                      <Button
                        label="Save as Template"
                        size="de"
                        theme="tertiary"
                        onClick={() => {
                          setShowSaveQuestionnairePopupOpen(false);
                          save(true);
                        }}
                      />
                    </div>
                  }
                />
              </>
            }
            menuStyle={{ width: 260 }}
          />
        </ModuleHeader>

        {questionnaire && activeGroupId && (
          <QuestionnaireBuilder
            groups={activeGroups}
            activeGroupId={activeGroupId}
            setActiveGroupId={(id) => {
              setActiveGroupId(id);
            }}
            onGroupAdd={onGroupAdd}
            onGroupDelete={onGroupDelete}
            onGroupNameChange={onGroupNameChange}
            onQuestionAdd={onQuestionAdd}
            onQuestionCopy={onQuestionCopy}
            onQuestionChange={onQuestionChange}
            onQuestionDelete={onQuestionDelete}
            onQuestionReorder={onQuestionReorder}
            onQuestionGroupChange={onQuestionGroupChange}
            name={questionnaire.name}
            onNameChange={onNameChange}
            riskMethodologyId={questionnaire.risk_methodology_id}
            onRiskMethodologyChange={onRiskMethodologyChange}
            setAssignedIDs={setAssignedIDs}
            isInternal={isInternal}
            setIsPublic={(isPublic) => setIsInternal(!isPublic)}
            isPasswordProtected={isPasswordProtected}
            setIsPasswordProtected={setIsPasswordProtected}
            isNew
            dueDate={dueDate}
            setDueDate={setDueDate}
          />
        )}
      </FlexDashboardContentWrapper>
    </FlexDashboardContent>
  );
};

export default observer(PartnersQuestionnaireCreatePage);
