import type { Contract } from "@themis/api/gen/models/contract";
import { AddNewRowLink } from "@themis/ui";
import { useClientDataGridBinding } from "@themis/ui-library/components/data-grid/binding/use-client-data-grid-binding";
import { DataGrid } from "@themis/ui-library/components/data-grid/data-grid";
import React from "react";
import { useIntl } from "react-intl";
import { useLocation, useRouteMatch } from "react-router-dom";

import { useContractsTableColumns } from "../../config/use-contracts-table-columns";

export function AccountContracts({ contracts }: { contracts: Contract[] }) {
  const { url } = useRouteMatch();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const { clientGridProps } = useClientDataGridBinding(contracts);

  const columns = useContractsTableColumns();

  return (
    <>
      <DataGrid columns={columns} {...clientGridProps} />

      <AddNewRowLink
        className="tw-mt-1"
        title={formatMessage({ defaultMessage: "Add Contract" })}
        to={{
          pathname: `${url}/new`,
          state: { from: location.pathname },
        }}
      />
    </>
  );
}
