/* eslint-disable no-param-reassign */
import { Info } from "@phosphor-icons/react";
import { SvgIcon } from "@themis/ui-library/components/data-display/svg-icon/svg-icon";
import { Tooltip } from "@themis/ui-library/components/data-display/tooltip/tooltip";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import {
  first,
  get,
  isEqual,
  kebabCase,
  keys,
  reduce,
  set,
  uniq,
} from "lodash";
import React, { useEffect, useMemo, useState } from "react";

import { Toggle } from "@/components/Elements";

import { useEffectExceptOnMount } from "../../../hooks/useEffectExceptOnMount";
import { iconForThemisModuleIdentifier } from "../../helpers/iconForThemisModuleIdentifier";
import { nameFromModuleWorkspace } from "../../helpers/nameForThemisModuleIdentifier";
import PermissionCheckbox from "./PermissionCheckbox";

// @ts-expect-error TS(7006) FIXME: Parameter 'array' implicitly has an 'any' type.
const addOrRemoveToArray = (array, value, add) =>
  add
    ? uniq([...(array || []), value])
    : // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
      (array || []).filter((action) => action !== value);

interface Props {
  disabled?: boolean;
  groupName?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  moduleWorkspaces?: any[];
  modulesLockDisabled?: boolean;
  modulesLocked?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onModuleLockChange?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  permissionGroup?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selection?: any;
}

function PermissionGroup({
  groupName,
  permissionGroup,
  selection,
  disabled,
  modulesLocked,
  modulesLockDisabled,
  onChange,
  onModuleLockChange = () => {},
  moduleWorkspaces,
}: Props) {
  // States
  const { all, actions, targets } = permissionGroup;
  const [selectedAll, setSelectedAll] = useState([]);
  const [selectedViewActions, setSelectedViewActions] = useState([]);
  const [selectedEditActions, setSelectedEditActions] = useState([]);
  const [selectedViewTargets, setSelectedViewTargets] = useState([]);
  const [selectedEditTargets, setSelectedEditTargets] = useState([]);
  const [editOnlyActions, setEditOnlyActions] = useState([]);

  // Hooks
  useEffect(() => {
    initializeSelection();
  }, [selection]);

  useEffect(() => {
    initializeEditOnlyActions();
  }, [permissionGroup]);

  useEffectExceptOnMount(() => {
    submitChanges();
  }, [
    selectedAll,
    selectedViewActions,
    selectedEditActions,
    selectedViewTargets,
    selectedEditTargets,
  ]);

  // Variables
  const availableTargets = useMemo(() => {
    if (!targets) {
      return [];
    }

    // @ts-expect-error TS(7006) FIXME: Parameter 'target' implicitly has an 'any' type.
    return targets.map((target) => first(keys(target)));
  }, []);

  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const viewAllChecked = selectedAll.includes("view");
  // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
  const editAllChecked = selectedAll.includes("edit");

  // Functions
  const initializeSelection = () => {
    if (selection.all) {
      const newSelectedAll = [];

      if (get(selection.all, ["state", "view"])) {
        newSelectedAll.push("view");
      }

      if (get(selection.all, ["state", "edit"])) {
        newSelectedAll.push("edit");
      }

      if (!isEqual(newSelectedAll, selectedAll)) {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
        setSelectedAll(newSelectedAll);
      }
    }

    const newSelectedActions = reduce(
      keys(selection.actions),
      (acc, actionKey) => {
        if (get(selection.actions, [actionKey, "state", "view"])) {
          // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          acc.view.push(actionKey);
        }
        if (get(selection.actions, [actionKey, "state", "edit"])) {
          // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          acc.edit.push(actionKey);
        }
        return acc;
      },
      { edit: [], view: [] },
    );

    if (!isEqual(newSelectedActions.view, selectedViewActions)) {
      setSelectedViewActions(newSelectedActions.view);
    }

    if (!isEqual(newSelectedActions.edit, selectedEditActions)) {
      setSelectedEditActions(newSelectedActions.edit);
    }

    if (selection.targets) {
      const newSelectedTargets = reduce(
        keys(selection.targets),
        (acc, moduleID) => {
          if (get(selection.targets, [moduleID, "state", "view"])) {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            acc.view.push(moduleID);
          }
          if (get(selection.targets, [moduleID, "state", "edit"])) {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
            acc.edit.push(moduleID);
          }
          return acc;
        },
        { edit: [], view: [] },
      );

      if (!isEqual(newSelectedTargets.view, selectedViewTargets)) {
        setSelectedViewTargets(newSelectedTargets.view);
      }

      if (!isEqual(newSelectedTargets.edit, selectedEditTargets)) {
        setSelectedEditTargets(newSelectedTargets.edit);
      }
    }
  };

  const initializeEditOnlyActions = () => {
    const filteredActions = actions
      // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
      .filter((action) => action.display_edit_only)
      // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
      .map((action) => action.key);

    if (!isEqual(filteredActions, editOnlyActions)) {
      setEditOnlyActions(filteredActions);
    }
  };

  const submitChanges = () => {
    const newChanges = {};

    const allChanges = reduce(
      selectedAll,
      (acc, allAction) => {
        acc = set(acc, ["state", allAction], true);
        return acc;
      },
      {},
    );

    const actionChanges = reduce(
      actions,
      (acc, { key }) => {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        if (selectedViewActions.includes(key)) {
          acc = set(acc, [key, "state", "view"], true);
        }

        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        if (selectedEditActions.includes(key)) {
          acc = set(acc, [key, "state", "edit"], true);
        }

        return acc;
      },
      {},
    );

    if (keys(allChanges).length > 0) {
      // @ts-expect-error TS(2339) FIXME: Property 'all' does not exist on type '{}'.
      newChanges.all = allChanges;
    }

    if (keys(actionChanges).length > 0) {
      // @ts-expect-error TS(2339) FIXME: Property 'actions' does not exist on type '{}'.
      newChanges.actions = actionChanges;
    }

    if (targets) {
      const targetChanges = reduce(
        targets,
        (acc, target) => {
          const moduleID = first(keys(target));

          // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          if (selectedViewTargets.includes(moduleID)) {
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            acc = set(acc, [moduleID, "state", "view"], true);
          }

          // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          if (selectedEditTargets.includes(moduleID)) {
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            acc = set(acc, [moduleID, "state", "edit"], true);
          }

          return acc;
        },
        {},
      );

      if (keys(targetChanges).length > 0) {
        // @ts-expect-error TS(2339) FIXME: Property 'targets' does not exist on type '{}'.
        newChanges.targets = targetChanges;
      }
    }

    if (targets) {
      const targetChanges = reduce(
        targets,
        (acc, target) => {
          const moduleID = first(keys(target));

          // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          if (selectedViewTargets.includes(moduleID)) {
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            acc = set(acc, [moduleID, "state", "view"], true);
          }

          // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          if (selectedEditTargets.includes(moduleID)) {
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            acc = set(acc, [moduleID, "state", "edit"], true);
          }

          return acc;
        },
        {},
      );

      if (keys(targetChanges).length > 0) {
        // @ts-expect-error TS(2339) FIXME: Property 'targets' does not exist on type '{}'.
        newChanges.targets = targetChanges;
      }
    }

    if (!isEqual(selection, newChanges)) {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      onChange(newChanges);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
  const handleViewActionChange = (key, add) => {
    const newViewActions = addOrRemoveToArray(selectedViewActions, key, add);
    setSelectedViewActions(newViewActions);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'key' implicitly has an 'any' type.
  const handleEditActionChange = (key, add) => {
    const newEditActions = addOrRemoveToArray(selectedEditActions, key, add);
    setSelectedEditActions(newEditActions);

    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    if (!editOnlyActions.includes(key)) {
      handleViewActionChange(key, add);
    }
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleID' implicitly has an 'any' type.
  const handleViewTargetChange = (moduleID, add) => {
    const newViewTargets = addOrRemoveToArray(
      selectedViewTargets,
      moduleID,
      add,
    );
    setSelectedViewTargets(newViewTargets);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'moduleID' implicitly has an 'any' type.
  const handleEditTargetChange = (moduleID, add) => {
    const newEditTargets = addOrRemoveToArray(
      selectedEditTargets,
      moduleID,
      add,
    );
    setSelectedEditTargets(newEditTargets);

    handleViewTargetChange(moduleID, add);
  };

  // @ts-expect-error TS(7006) FIXME: Parameter 'mode' implicitly has an 'any' type.
  const handleAllChange = (mode, add) => {
    let newSelectedAll = addOrRemoveToArray(selectedAll, mode, add);
    if (mode === "edit") {
      newSelectedAll = addOrRemoveToArray(newSelectedAll, "view", add);
    }
    setSelectedAll(newSelectedAll);

    if (mode === "edit") {
      // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
      setSelectedEditActions(add ? actions.map((action) => action.key) : []);
      setSelectedEditTargets(add ? availableTargets : []);
    }

    const newViewActions = add
      ? actions
          // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
          .map((action) => action.key)
          // @ts-expect-error TS(7006) FIXME: Parameter 'actionKey' implicitly has an 'any' type... Remove this comment to see the full error message
          .filter((actionKey) => !editOnlyActions.includes(actionKey))
      : [];
    setSelectedViewActions(newViewActions);
    setSelectedViewTargets(add ? availableTargets : []);
  };

  return (
    <div className="permission-detail-group">
      <div className="permission-detail-group-row permission-detail-group-header">
        <div>{groupName}</div>
        <div>Read</div>
        <div>Write</div>
      </div>

      <div className="permission-detail-group-row permission-detail-group-header">
        <div>{all.name}</div>
        <div>
          <PermissionCheckbox
            disabled={disabled || editAllChecked}
            checked={viewAllChecked}
            testID={`${kebabCase(all.name)}-view`}
            onChange={() => handleAllChange("view", !viewAllChecked)}
          />
        </div>
        <div>
          <PermissionCheckbox
            checked={editAllChecked}
            disabled={disabled}
            testID={`${kebabCase(all.name)}-edit`}
            onChange={() => handleAllChange("edit", !editAllChecked)}
          />
        </div>
      </div>

      {actions.map(
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        ({ key, name, display_edit_only: editOnly, description }) => {
          // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          const viewChecked = selectedViewActions.includes(key);
          // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          const editChecked = selectedEditActions.includes(key);

          return (
            <div
              key={key}
              className="permission-detail-group-row"
              data-testid="permission-detail-group-row"
            >
              <Stack direction="row" alignItems="center">
                <Typography variant="subtitle2" color="textSecondary">
                  {name}
                </Typography>

                <Tooltip title={description} placement="right">
                  <SvgIcon component={Info} fontSize="small" inheritViewBox />
                </Tooltip>
              </Stack>

              <div>
                {editOnly ? (
                  "-"
                ) : (
                  <PermissionCheckbox
                    disabled={disabled || editChecked}
                    checked={viewChecked}
                    testID={`${kebabCase(key)}-view`}
                    onChange={() => handleViewActionChange(key, !viewChecked)}
                  />
                )}
              </div>
              <div>
                <PermissionCheckbox
                  checked={editChecked}
                  disabled={disabled}
                  testID={`${kebabCase(key)}-edit`}
                  onChange={() => handleEditActionChange(key, !editChecked)}
                />
              </div>
            </div>
          );
        },
      )}

      {targets && (
        <div className="permission-detail-lock-modules">
          <div className="permission-detail-lock-modules-description">
            Select the modules that you would like locked in this role. The
            module selection when the role is being assigned to a user will be
            locked to the selected modules. The role cannot be locked or
            unlocked after creation
          </div>

          <div className="permission-detail-lock-modules-action">
            Lock Modules
            <div className="permission-detail-lock-modules-toggle">
              <Toggle
                active={modulesLocked}
                disabled={modulesLockDisabled}
                onChange={onModuleLockChange}
              />
              Modules Locked
            </div>
          </div>
        </div>
      )}

      {targets &&
        // @ts-expect-error TS(7006) FIXME: Parameter 'target' implicitly has an 'any' type.
        targets.map((target) => {
          const moduleID = first(keys(target));
          // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          const viewChecked = selectedViewTargets.includes(moduleID);
          // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
          const editChecked = selectedEditTargets.includes(moduleID);

          return (
            <div
              key={moduleID}
              className="permission-detail-group-row"
              data-testid="permission-detail-group-row"
            >
              <div className="permission-detail-row-name">
                <img
                  className="permission-detail-row-icon"
                  // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
                  src={iconForThemisModuleIdentifier(moduleID)}
                />
                {/* @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
                {nameFromModuleWorkspace(moduleID, moduleWorkspaces)}
              </div>
              <div>
                <PermissionCheckbox
                  checked={viewChecked}
                  disabled={modulesLocked || disabled || editChecked}
                  testID={`${kebabCase(moduleID)}-view`}
                  onChange={() =>
                    handleViewTargetChange(moduleID, !viewChecked)
                  }
                />
              </div>
              <div>
                <PermissionCheckbox
                  checked={editChecked}
                  disabled={modulesLocked || disabled}
                  testID={`${kebabCase(moduleID)}-edit`}
                  onChange={() =>
                    handleEditTargetChange(moduleID, !editChecked)
                  }
                />
              </div>
            </div>
          );
        })}
    </div>
  );
}

PermissionGroup.defaultProps = {
  selection: {},
  disabled: false,
  modulesLocked: false,
  modulesLockDisabled: false,
  moduleWorkspaces: [],
};

export default PermissionGroup;
