import React from "react";

export const PageContent = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode }
>(({ children }, ref) => (
  <div
    ref={ref}
    className="tw-mx-6 tw-mb-10 tw-mt-6 tw-flex tw-h-full tw-flex-grow tw-flex-col tw-overflow-auto tw-bg-neutral-25"
  >
    {children}
  </div>
));

PageContent.displayName = "PageContent";
