import { faker } from "@faker-js/faker";

import type {
  ShowMonitoringFinding200,
  ShowMonitoringFindingPathParams,
  ShowMonitoringFindingQueryResponse,
} from "../models/ShowMonitoringFinding";
import { createMonitoringFinding } from "./createMonitoringFinding";

export function createShowMonitoringFindingPathParams(): NonNullable<ShowMonitoringFindingPathParams> {
  return { id: faker.number.int() };
}

/**
 * @description OK
 */
export function createShowMonitoringFinding200(): NonNullable<ShowMonitoringFinding200> {
  return { data: createMonitoringFinding() };
}

/**
 * @description OK
 */
export function createShowMonitoringFindingQueryResponse(): NonNullable<ShowMonitoringFindingQueryResponse> {
  return { data: createMonitoringFinding() };
}
