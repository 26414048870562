import {
  Button,
  Checkbox,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@themis/ui";
import React from "react";
import { PiFadersBold } from "react-icons/pi";

import { FilterOperand } from "../../../hooks/use-filter-sort/types";
import { TASK_STATUSES, TaskStatus } from "../config/status";
import { useMyTasksFilterSort } from "../hooks/useMyTasksFilterSort";

export default function StatusFilterButton() {
  const { filters, applyFilterSort } = useMyTasksFilterSort();

  // If no status filter in query params, check all statuses
  const statusFilterValues = filters?.status?.value || [
    TaskStatus.done.value,
    TaskStatus.in_progress.value,
    TaskStatus.not_started.value,
  ];

  function handleStatusToggle(status: string, checked: boolean | string) {
    if (filters?.status?.value?.length === 1 && checked === false) {
      return; // need at least one status filter in current implementation
    }

    applyFilterSort({
      filtersToApply: {
        status: {
          operand: FilterOperand.ANY,
          value: checked
            ? [...statusFilterValues, status]
            : statusFilterValues.filter((v) => v !== status),
        },
      },
    });
  }

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button LeftIcon={PiFadersBold}>Filters</Button>
      </PopoverTrigger>
      <PopoverContent className="tw-grid tw-gap-2 tw-p-2">
        {TASK_STATUSES.map((status) => (
          <div className="tw-flex tw-items-center tw-gap-2" key={status.value}>
            <Checkbox
              checked={statusFilterValues.includes(status.value)}
              onCheckedChange={(checked) =>
                handleStatusToggle(status.value, checked)
              }
            />
            <Label className="tw-text-nowrap tw-text-sm tw-text-neutral-500">
              Status is {status.label}
            </Label>
          </div>
        ))}
      </PopoverContent>
    </Popover>
  );
}
