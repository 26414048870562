import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import type { Attestation, CreateAttestationMutationRequest } from "@/api/gen";

import { createAttestation } from "../../../../../app/javascript/api/gen/axios/attestationsController/createAttestation";
import { getAttestationsQueryKey } from "./use-attestations";

export const useCreateAttestation = <TError = Error, TContext = unknown>(
  companyId: number,
  {
    onSettled,
    ...options
  }: Partial<
    UseMutationOptions<
      Attestation,
      TError,
      CreateAttestationMutationRequest,
      TContext
    >
  > = {},
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (params) => {
      const response = await createAttestation(companyId, params);

      return response.data;
    },
    onSettled: (...args) => {
      queryClient.invalidateQueries({
        queryKey: getAttestationsQueryKey(),
      });

      onSettled?.(...args);
    },
    ...options,
  });
};
