import type { VariantProps } from "cva";
import React from "react";

import { cn } from "../../../lib/utils";
import { buttonBaseVariants } from "../../Button/ButtonBase";

type ButtonCellProps = VariantProps<typeof buttonBaseVariants> &
  React.ComponentPropsWithoutRef<"button"> & {
    label?: string;
    onClick: () => void;
  };

const ButtonCell = ({ label = "Click", color, onClick }: ButtonCellProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        buttonBaseVariants({
          color,
        }),
        "tw-h-full tw-w-full tw-justify-start tw-rounded-none tw-px-2",
      )}
    >
      {label}
    </button>
  );
};

export default ButtonCell;
