import type { CustomCellEditorProps } from "ag-grid-react";
import React from "react";

import { filterCellProps } from "../../../lib/utils";
import type { TextInputProps } from "../../Form/TextInput";
import { TextInput } from "../../Form/TextInput";

type Props = TextInputProps & CustomCellEditorProps;

const TextEditorCell = ({ value, onValueChange, ...props }: Props) => {
  const filteredProps = filterCellProps({ value, onValueChange, ...props });

  return (
    <TextInput
      className="tw-h-full tw-rounded-none tw-border-0"
      onChange={(e) => onValueChange(e.target.value)}
      onBlur={() => props.stopEditing()}
      value={value}
      autoFocus
      {...filteredProps}
    />
  );
};

TextEditorCell.displayName = "TextEditorCell";
export default TextEditorCell;
