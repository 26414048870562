import { http } from "msw";

import { createLegacyWebsiteMonitoringGroupResultUpdateMutationResponse } from "../createLegacyWebsiteMonitoringGroupResultUpdate";

export const legacyWebsiteMonitoringGroupResultUpdateHandler = http.put(
  "*/website_monitoring/:monitoring_group_result_id/monitoring_group_result_update",
  function handler(info) {
    return new Response(
      JSON.stringify(
        createLegacyWebsiteMonitoringGroupResultUpdateMutationResponse(),
      ),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
