import type { ReactNode } from "react";
import React from "react";

import { Button } from "../Button/Button";
import type { ButtonBaseProps } from "../Button/ButtonBase";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "./Dialog";

interface ConfirmationDialogProps {
  title: ReactNode;
  description: ReactNode;
  onCancel?: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  onIsOpenChange: (open: boolean) => void;
  confirmText?: string;
  cancelText?: string;
  confirmVariant?: ButtonBaseProps["color"];
}

const ConfirmationDialog = ({
  title,
  description,
  onCancel,
  onConfirm,
  isOpen = false,
  onIsOpenChange,
  confirmText = "Confirm",
  cancelText = "Cancel",
  confirmVariant = "primary",
}: ConfirmationDialogProps) => {
  const handleAction = (action?: () => void) => {
    onIsOpenChange(false);

    if (action) {
      action();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent onClose={() => handleAction(onCancel)}>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <DialogDescription>{description}</DialogDescription>
        <DialogFooter>
          <Button
            color={confirmVariant}
            onClick={() => handleAction(onConfirm)}
          >
            {confirmText}
          </Button>
          <Button color="transparent" onClick={() => handleAction(onCancel)}>
            {cancelText}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { ConfirmationDialog, type ConfirmationDialogProps };
