import type { FieldConfiguration } from "@themis/api/gen/models/fieldConfiguration";
import { type ComponentType } from "react";

import { getDynamicFieldValue } from "@/utils/fields/get-dynamic-field-value";

import { DateTimeValue } from "./date-time-value/date-time-value";
import { DateValue } from "./date-value/date-value";
import type { UnknownRecord } from "./field-configuration-types";
import { ReferenceValue } from "./reference-value/reference-value";
import { SelectValue } from "./select-value/select-value";
import { StatusValue } from "./status-value/status-value";
import { TextValue } from "./text-value/text-value";

interface BaseProps<
  TRecord extends UnknownRecord = UnknownRecord,
  TConfig extends FieldConfiguration = FieldConfiguration,
  TValue = TRecord[TConfig["path"]],
> {
  record: TRecord;
  configuration: TConfig;
  value: TValue;
}

type BaseDynamicValueComponent = ComponentType<BaseProps>;

const cells = {
  text: TextValue,
  date: DateValue,
  section: SelectValue,
  select: SelectValue,
  datetime: DateTimeValue,
  reference: ReferenceValue,
  status: StatusValue,
  textarea: TextValue,
  decimal: TextValue,
  numeric: TextValue,
  reviews: () => null,
  computed: () => null,
} satisfies Record<FieldConfiguration["field_type"], BaseDynamicValueComponent>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type BaseDynamicValueProps<T = any> = BaseProps<
  UnknownRecord,
  FieldConfiguration,
  T | T[] | undefined
>;

export const BaseDynamicValue = ({
  record,
  configuration,
  ...rest
}: Omit<BaseDynamicValueProps, "value">) => {
  const CellComponent: BaseDynamicValueComponent =
    cells[configuration.field_type];

  const value = getDynamicFieldValue(configuration, record);

  if (!CellComponent) {
    return null;
  }

  return (
    <CellComponent
      value={value}
      record={record}
      configuration={configuration}
      {...rest}
    />
  );
};
