import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { Page } from "@themis/ui-library/components/layout/page/page";
import { Breadcrumbs } from "@themis/ui-library/components/navigation/breadcrumbs/breadcrumbs";
import { Link } from "@themis/ui-library/components/navigation/link/link";
import { parseAsInteger, useQueryState } from "nuqs";
import { useIntl } from "react-intl";
import { generatePath, useParams } from "react-router";

import { useAccount } from "@/api/queries/accounts";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

import { routes } from "../../routes";
import { AccountDocumentContent } from "./account-document-content/account-document-content";

export function AccountDocumentDetailPage() {
  const { formatMessage } = useIntl();
  const { accountId } = useParams<{
    accountId: string;
  }>();
  const workspaceId = useRouteWorkspaceId();

  const [documentIndex, setDocumentIndex] = useQueryState(
    "document_index",
    parseAsInteger.withDefault(0),
  );

  const { data: accountData } = useAccount({
    workspaceId,
    accountId: Number(accountId),
    queryParams: {
      expand: "documents",
    },
  });

  const account = accountData?.data;

  return (
    <Page.Container>
      <Page.Header
        showBorderBottom
        title={
          <Breadcrumbs>
            <Link
              to={generatePath(routes.accounts.overview, {
                workspace_id: workspaceId,
              })}
            >
              {formatMessage({ defaultMessage: "Accounts" })}
            </Link>

            <Link
              to={generatePath(routes.accounts.detail.recordView, {
                workspace_id: workspaceId,
                accountId,
              })}
            >
              {account?.name}
            </Link>

            <Typography>
              {formatMessage(
                {
                  defaultMessage:
                    "{documentsPosition} of {documentsCount} Attachments",
                },
                {
                  documentsPosition: documentIndex + 1,
                  documentsCount: account?.documents?.length,
                },
              )}
            </Typography>
          </Breadcrumbs>
        }
      />

      <Page.Content padding={0} overflow="auto">
        {account && (
          <AccountDocumentContent
            documentIndex={documentIndex}
            onChangeDocumentIndex={setDocumentIndex}
            account={account}
          />
        )}
      </Page.Content>
    </Page.Container>
  );
}
