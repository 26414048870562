import { http } from "msw";

import { createQuestionnaireExportQueryResponse } from "../createQuestionnaireExport";

export const questionnaireExportHandler = http.get(
  "*/questionnaires/:id/export",
  function handler(info) {
    return new Response(
      JSON.stringify(createQuestionnaireExportQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
