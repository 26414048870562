import { Avatar } from "@themis/ui-library/components/data-display/avatar/avatar";
import { AvatarGroup } from "@themis/ui-library/components/data-display/avatar/avatar-group/avatar-group";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { Card } from "@themis/ui-library/components/surfaces/card/card";
import { Popover } from "@themis/ui-library/components/utils/popover/popover";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import { observer } from "mobx-react";
import { useEffect, useMemo, useRef, useState } from "react";

import { useMainStore } from "@/contexts/Store";
import type { User } from "@/stores/types/user-types";

const DEFAULT_USER_COUNT = 4;

function ViewModuleUsers() {
  const mainStore = useMainStore();

  const { themisModuleIdentifier } = mainStore.context;
  const { list: workspaces } = mainStore.moduleWorkspaces;

  const ref = useRef<HTMLDivElement>(null);
  const popover = useOpen();

  const [users, setUsers] = useState<User[]>([]);

  const moduleWorkspace = useMemo(
    () =>
      workspaces.find(
        (mws) => mws.themis_module.identifier === themisModuleIdentifier,
      ),
    [workspaces, themisModuleIdentifier],
  );

  useEffect(() => {
    async function getUsers() {
      if (!moduleWorkspace?.id) {
        return;
      }

      try {
        const res = await mainStore.permissions.getUsersAccessibleToModule(
          moduleWorkspace?.id,
        );
        setUsers(res);
      } catch {
        setUsers([]);
      }
    }

    getUsers();
  }, [mainStore.permissions, moduleWorkspace]);

  return (
    <>
      <AvatarGroup
        max={DEFAULT_USER_COUNT}
        total={users.length}
        slotProps={{
          surplus: {
            ref,
            size: "lg",
            onClick: popover.open,
          },
        }}
        data-testid="view-module-users"
      >
        {users.map((user) => (
          <Avatar
            key={`view-module-users-${user.id}`}
            colorSeed={user.icon_color_index}
            size="lg"
          >
            {user.initials}
          </Avatar>
        ))}
      </AvatarGroup>

      <Popover
        open={popover.isOpen && users.length > DEFAULT_USER_COUNT}
        onClose={popover.close}
        anchorEl={ref.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Card component={Stack} spacing={0.5}>
          <div className="tw-mb-3 tw-text-xs tw-font-bold tw-tracking-wide tw-text-primary-500">
            Module Users
          </div>

          {users.map((user) => (
            <div
              key={`view-module-users-${user.id}`}
              className="tw-flex tw-items-center tw-gap-1"
            >
              <div>
                <Avatar colorSeed={user.icon_color_index}>
                  {user.initials}
                </Avatar>
              </div>
              <div className="tw-text-xs tw-font-semibold tw-text-neutral-500">
                {user.full_name}
              </div>
            </div>
          ))}
        </Card>
      </Popover>
    </>
  );
}

export default observer(ViewModuleUsers);
