import * as AccordionPrimitive from "@radix-ui/react-accordion";
import * as React from "react";
import { PiCaretDownBold } from "react-icons/pi";

import { cn } from "../../lib/utils";

type IconPosition = "left" | "right";
type AccordionTriggerProps = React.ComponentPropsWithoutRef<
  typeof AccordionPrimitive.Trigger
> & {
  iconPosition?: IconPosition;
};

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("tw-border-b", className)}
    {...props}
  />
));
AccordionItem.displayName = "AccordionItem";

/**
 * AccordionTrigger component
 *
 * @param {Object} props - The component props
 * @param {string} [props.className] - Additional CSS classes to apply to the trigger
 * @param {React.ReactNode} props.children - The content of the trigger
 * @param {IconPosition} [props.iconPosition='right'] - The position of the icon ('left' or 'right')
 */
const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  AccordionTriggerProps
>(({ className, children, iconPosition = "right", ...props }, ref) => (
  <AccordionPrimitive.Header className="tw-flex">
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        "tw-flex tw-flex-1 tw-items-center tw-py-4 tw-font-sans tw-text-sm tw-font-medium tw-transition-all [&[data-state=open]>svg]:tw-rotate-180",
        {
          "tw-justify-start tw-gap-1": iconPosition === "left",
          "tw-justify-between": iconPosition === "right",
        },
        className,
      )}
      {...props}
    >
      {iconPosition === "left" && (
        <PiCaretDownBold className="tw-h-4 tw-w-4 tw-shrink-0 tw-transition-transform tw-duration-200" />
      )}
      {children}
      {iconPosition === "right" && (
        <PiCaretDownBold className="tw-h-4 tw-w-4 tw-shrink-0 tw-transition-transform tw-duration-200" />
      )}
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    // Overflow setting to prevent horizontal box-shadow clipping
    // https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue/72531380#72531380
    className="tw-overflow-y-clip tw-overflow-x-visible tw-text-sm tw-transition-all data-[state=closed]:tw-animate-accordion-up data-[state=open]:tw-animate-accordion-down"
    {...props}
  >
    {/* Padding adjusted to prevent vertical box-shadow clipping */}
    <div className={cn("tw-py-2.5", className)}>{children}</div>
  </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
