import React from "react";
import type { ReactElement } from "react";

import { styled } from "../../../../../../styles";
import type { BasePreviewRendererProps } from "../base-preview-renderer-props";

type ImagePreviewRendererProps = BasePreviewRendererProps;

const StyledImage = styled("img")({
  transition: "all 0.2s ease",
  pointerEvents: "none",
  userSelect: "none",
});

export function ImagePreviewRenderer({
  file,
  previewWidth,
  zoomLevel,
  onError,
  onLoaded,
}: ImagePreviewRendererProps): ReactElement {
  const width = previewWidth * zoomLevel;
  return (
    <StyledImage
      key={file.file_url}
      sx={{
        width,
      }}
      src={file.file_url}
      alt={file.file_name}
      onError={onError}
      onLoad={onLoaded}
    />
  );
}
