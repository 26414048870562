import type { ComponentType } from "react";
import type { Control, FieldValues, Path } from "react-hook-form";

import type { FieldConfiguration } from "@/api";

import { DateFormField } from "../form-fields/date-form-field/date-form-field";
import { TextInputFormField } from "../form-fields/text-input-form-field/text-input-form-field";
import { DynamicReferenceField } from "./dynamic-reference-field/dynamic-reference-field";
import { DynamicSelectField } from "./dynamic-select-field/dynamic-select-field";

interface BaseFieldComponentProps<TFieldValues extends FieldValues> {
  control: Control<TFieldValues>;
  label: string;
  name: Path<TFieldValues>;
  options: FieldConfiguration["options"];
  required: boolean;
  referenceType?: FieldConfiguration["reference_type"];
  multiselect?: boolean;
  workspaceId?: number;
  disabled?: boolean;
}

type BaseFieldComponent<TFieldValues extends FieldValues = FieldValues> =
  ComponentType<BaseFieldComponentProps<TFieldValues>>;

const fields = {
  text: TextInputFormField,
  date: DateFormField,
  reference: DynamicReferenceField,
  section: DynamicSelectField,
  select: DynamicSelectField,
  datetime: () => null,
  status: () => null,
  textarea: () => null,
  decimal: TextInputFormField,
  numeric: TextInputFormField,
  reviews: () => null,
  computed: () => null,
} satisfies Record<FieldConfiguration["field_type"], BaseFieldComponent>;

interface DynamicFieldProps<
  TField extends keyof typeof fields,
  TFieldValues extends FieldValues,
> extends BaseFieldComponentProps<TFieldValues> {
  fieldType: TField;
}

export const DynamicField = <
  TField extends keyof typeof fields,
  TFieldValues extends FieldValues,
>({
  disabled,
  fieldType,
  control,
  name,
  options,
  label,
  referenceType,
  required,
  workspaceId,
  multiselect,
}: DynamicFieldProps<TField, TFieldValues>) => {
  const FieldComponent: BaseFieldComponent<TFieldValues> | undefined =
    fields[fieldType];

  const isMultiSelectField =
    fieldType === "select" ||
    (fieldType === "reference" && referenceType === "User");

  const fieldProps: BaseFieldComponentProps<TFieldValues> = {
    control,
    name,
    options,
    label,
    required,
    disabled,
    ...(fieldType === "reference" && { referenceType, workspaceId }),
    ...(isMultiSelectField && { multiselect }),
  };

  return FieldComponent ? <FieldComponent {...fieldProps} /> : null;
};
