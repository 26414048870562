import { LinkButton, LinkIconButton } from "@themis/ui";
import { FilterOperand } from "@themis/ui-library/components/data-grid/state/data-grid-state";
import { usePersistedDataGridState } from "@themis/ui-library/components/data-grid/state/use-persisted-data-grid-state";
import { PiArchiveBold } from "react-icons/pi";
import { generatePath, useParams, useRouteMatch } from "react-router-dom";

import { recordTypePath } from "@/api";
import { useCompany } from "@/api/queries/companies";
import { useCreateProject, useProjects } from "@/api/queries/projects";
import { useCurrentUser } from "@/api/queries/users";
import { ErrorContainer } from "@/components/ErrorContainer";
import { Header } from "@/components/Layout/Header";
import { PageContent } from "@/components/Layout/PageContent";
import { PageLayout } from "@/components/Layout/PageLayout";
import Loading from "@/components/Loading";
import ImportButton from "@/features/misc/ImportButton";
import { ProjectSideBar } from "@/features/projects/components/ProjectSideBar";
import { getLocalStorageTableKey } from "@/utils/get-local-storage-table-key/get-local-storage-table-key";

import ProjectsEmptyState from "../components/ProjectsEmptyState";
import ProjectsTable from "../components/ProjectsTable/ProjectsTable";
import { PROJECTS_ARCHIVE_PATH, PROJECTS_ROUTES } from "../routes";

export default function Projects() {
  const { url } = useRouteMatch();
  const { workspace_id } = useParams<{ workspace_id: string }>();

  const persistedDataGridState = usePersistedDataGridState({
    tableKey: getLocalStorageTableKey(workspace_id, recordTypePath.projects),
  });

  const {
    data: user,
    isPending: isUserPending,
    isError: isUserError,
  } = useCurrentUser();

  const {
    data: company,
    isPending: isCompanyPending,
    isError: isCompanyError,
  } = useCompany("current");

  const isActiveWorkspaceInternal = user?.active_workspace.is_internal;

  const {
    data,
    isPending: isProjectsPending,
    isError: isProjectsError,
  } = useProjects(Number(company?.data.company.id), {
    ...persistedDataGridState.dataGridQueryParams,
    filters: {
      ...persistedDataGridState.dataGridQueryParams.filters,
      archived_at: {
        [FilterOperand.PRESENT]: null,
      },
      ...(isActiveWorkspaceInternal
        ? {}
        : {
            workspace_id: {
              [FilterOperand.ANY]: workspace_id,
            },
          }),
    },
  });

  const workspaceScopedProjects = data?.data || [];
  const totalCount = data?.meta.total_count || 0;

  const { mutateAsync: createProject } = useCreateProject({
    companyId: Number(company?.data.company.id),
  });

  if (isProjectsPending || isUserPending || isCompanyPending) {
    return <Loading loadingLayout="table" />;
  }

  if (isProjectsError || isUserError || isCompanyError) {
    return (
      <PageContent>
        <ErrorContainer
          backButtonProps={{
            linkTo: generatePath("/workspaces/:workspace_id/home", {
              workspace_id,
            }),
          }}
        >
          Could not load projects.
        </ErrorContainer>
      </PageContent>
    );
  }

  return (
    <div className="tw-flex tw-h-full">
      <ProjectSideBar
        dataGridState={persistedDataGridState.dataGridState}
        updateDataGridState={persistedDataGridState.updateDataGridState}
      />
      <PageLayout>
        <Header
          title={<>Projects</>}
          HeaderOptions={
            <>
              <LinkButton
                size="sm"
                to={(location) => ({
                  pathname: generatePath(`${url}${PROJECTS_ROUTES.details}`, {
                    project_id: "new",
                  }),
                  state: { from: location.pathname },
                })}
              >
                Add Project
              </LinkButton>
              <ImportButton recordType="projects" />
              <LinkIconButton
                className="tw-text-neutral-500"
                to={(location) => ({
                  pathname: generatePath(PROJECTS_ARCHIVE_PATH, {
                    workspace_id,
                  }),
                  state: { from: location.pathname },
                })}
                Icon={PiArchiveBold}
                color="tertiary"
                data-tooltip-id="tooltip"
                data-tooltip-place="bottom-end"
                data-tooltip-content="View archived projects"
              />
            </>
          }
        />
        {workspaceScopedProjects.length > 0 ? (
          <ProjectsTable
            projects={workspaceScopedProjects}
            createProject={createProject}
            workspace_id={workspace_id}
            companyId={company.data.company.id}
            persistedDataGridState={persistedDataGridState}
            totalCount={totalCount}
          />
        ) : (
          <ProjectsEmptyState />
        )}
      </PageLayout>
    </div>
  );
}
