import { useToast } from "@themis/ui";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { MenuItem } from "@themis/ui-library/components/navigation/menu/menu-item/menu-item";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { useIssuesReport } from "@/api/queries/exports";
import { useFetchFile } from "@/api/queries/use-fetch-file";
import { downloadFile } from "@/stores/helpers/AttachmentHelper";
import { getTableFiltersParam } from "@/stores/helpers/getTableFiltersParam";

function IMExportReport({ onClose }: { onClose?: () => void }) {
  const toast = useToast();
  const { formatMessage } = useIntl();
  const { workspace_id } = useParams<{
    workspace_id: string;
  }>();

  const { mutateAsync: fetchFile } = useFetchFile();
  const { mutateAsync: issuesReport } = useIssuesReport({
    workspaceId: Number(workspace_id),
    onSuccess: () => {
      toast({
        content: "Issue Report has been exported!",
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: "Failed to export document!",
        variant: "error",
      });
    },
  });

  async function downloadExcelReport(file_format: "xlsx" | "csv") {
    toast({
      content: "Issue Report is preparing to be exported",
      variant: "success",
    });

    if (onClose) {
      onClose();
    }

    const { data } = await issuesReport({
      filters: getTableFiltersParam(),
      file_format,
    });

    if (!data) {
      return;
    }

    const blob = await fetchFile(data.file_url);
    downloadFile(blob, data.file_name);
  }

  return (
    <>
      <Typography
        paddingX={2}
        paddingY={1}
        variant="h5"
        fontWeight="bold"
        color="primary"
      >
        {formatMessage({
          defaultMessage: "Export Issue & Action Plan Report",
        })}
      </Typography>
      <MenuItem onClick={() => downloadExcelReport("xlsx")}>XLSX</MenuItem>
      <MenuItem onClick={() => downloadExcelReport("csv")}>CSV</MenuItem>
    </>
  );
}

export default IMExportReport;
