import "./stylesheets/application.scss";
import "./stylesheets/tailwind.css";

import * as ActiveStorage from "@rails/activestorage";
import Rails from "@rails/ujs";
import ahoy from "ahoy.js";
// Add polyfills for wide compatibility: https://github.com/rails/webpacker/tree/5-x-stable#usage
// More discussion here: https://babeljs.io/docs/en/usage#polyfill
import "core-js/stable";
import mixpanel from "mixpanel-browser";
import { createRoot } from "react-dom/client";
import "regenerator-runtime/runtime";

import App from "@/app/app";

import { initLogRocket } from "./lib/logrocket";
import { init as initSentry } from "./lib/sentry";

interface CustomWindow extends Window {
  PUBLIC_MIXPANEL_TOKEN: string;
}

declare let window: CustomWindow;

Rails.start();
ActiveStorage.start();
initLogRocket();
initSentry();
ahoy.trackClicks("a");

mixpanel.init(window.PUBLIC_MIXPANEL_TOKEN, {
  debug: process.env.NODE_ENV === "development",
  autotrack: true,
});

const container = document.getElementById("root");

const root = createRoot(container!);

root.render(<App />);
