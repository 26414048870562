import classNames from "classnames";
import { observer } from "mobx-react";
import React, { useMemo } from "react";
import {
  PiBagBold,
  PiBellBold,
  PiChartBarBold,
  PiCheckSquareBold,
  PiFilesBold,
  PiGearBold,
  PiHouseBold,
  PiLineSegmentsBold,
  PiLockBold,
  PiStarBold,
  PiTagBold,
} from "react-icons/pi";
import {
  generatePath,
  Link,
  NavLink,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import ReviewsCountBadge from "@/components/settings/notificationsCenter/reviews/ReviewsCountBadge";

import { useMainStore } from "../../../contexts/Store";
import { PROJECTS_LIST_PATH } from "../../../features/projects/routes";
import { TaskCountBadge } from "../../../features/tasks/components/TaskCountBadge";
import { useIsFeatureEnabled } from "../../../hooks/useIsFeatureEnabled";
import { appRoutes } from "../../../routes/app-routes";
import { FEATURE_FLAG_ID } from "../../../stores/types/feature-flag-types";
import type {
  ModuleIdentifier,
  TopAndSubModuleToModuleInfoMap,
} from "../../../stores/types/module-workspaces-types";
import Accordion from "./components/Accordion";
import DashboardMenuItem from "./components/DashboardMenuItem";
import { NavigationLink } from "./components/navigation/NavigationLink";
import SidebarSectionHeader from "./components/SidebarSectionHeader";
import SidebarTag from "./components/SidebarTag";

type sectionType = "library" | "workflows" | "tools";

const libraryRoutes = [
  "policy", // Policies
  "procedures", // Procedures
  "documents", // Documents
  "training", // Training
  "control_mapping", // Control Inventory,
  "contracts",
  "accounts",
];

const workflowIdentifiers = [
  "issue_management", // Issue Management
  "marketing", // Marketing
  "new_product_approval", // Change Management
  "vendor_due_diligence", // Vendor Due Diligence
  "risk_assessment", // Questionnaires
  "risk_register", // Risk Register
  "complaints", // Complaints
  "audits", // Audits
  "conflicts_of_interest", // Form Filler
  "qa_tests_development", // Monitoring and Testing
  "key_risk_indicators", // Key Risk Indicators
];

function DashboardMenu() {
  const mainStore = useMainStore();
  const location = useLocation();
  const { activeWorkspace, workspaceID: workspaceId, isIW } = mainStore.context;

  const {
    canManageSettings,
    canManageTags,
    canViewReports,
    canManageAccounts,
  } = mainStore.userPermissions;

  const { list: moduleWorkspaces, favoriteModuleWorkspaces } =
    mainStore.moduleWorkspaces;

  const websiteMonitoringFeatureEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.COM_WEBSITE_MONITORING,
  );

  const newWebMonitoringFeatureEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_SEI_WEB_MONITORING,
  );

  const newQuestionnairesFeatureEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_QUESTIONNAIRES,
  );

  const filteredModuleWorkspaces = moduleWorkspaces.filter(
    (moduleWorkspace) => {
      const { identifier } = moduleWorkspace.themis_module;
      if (identifier === "accounts" && !canManageAccounts) {
        return false;
      }

      if (
        identifier === "website_monitoring" &&
        !websiteMonitoringFeatureEnabled
      ) {
        return false;
      }

      return true;
    },
  );
  const countAddedModuleWorkspaces = moduleWorkspaces.filter(
    (mw) => mw.added,
  ).length;

  const moduleInfo = mainStore.moduleWorkspaces
    .moduleInfo as TopAndSubModuleToModuleInfoMap;

  const { isCollaborativeOnly } = mainStore.workspaces;
  const isReportsMatch = !!useRouteMatch("/workspace-reports");

  const [libraryCollapsed, setLibraryCollapsed] = React.useState(
    localStorage.getItem("libraryCollapsed") === "true",
  );
  const [workflowsCollapsed, setWorkflowsCollapsed] = React.useState(
    localStorage.getItem("workflowsCollapsed") === "true",
  );
  const [toolsCollapsed, setToolsCollapsed] = React.useState(
    localStorage.getItem("toolsCollapsed") === "true",
  );

  const favoriteWorkspacesToRender = useMemo(
    () =>
      favoriteModuleWorkspaces
        .map((favoriteModule) =>
          filteredModuleWorkspaces.find(
            (moduleWorkpsace) =>
              moduleWorkpsace.id === favoriteModule.module_workspace_id,
          ),
        )
        .filter((moduleWorkspace) => moduleWorkspace && moduleWorkspace.added),
    [favoriteModuleWorkspaces, countAddedModuleWorkspaces],
  );

  const libraryWorkspacesToRender = useMemo(
    () =>
      filteredModuleWorkspaces
        .filter(
          (moduleWorkspace) =>
            moduleWorkspace.added &&
            !moduleWorkspace.is_favorited &&
            libraryRoutes.includes(moduleWorkspace.themis_module.identifier),
        )
        .sort(
          (a, b) =>
            libraryRoutes.indexOf(a.themis_module.identifier) -
            libraryRoutes.indexOf(b.themis_module.identifier),
        ),
    [filteredModuleWorkspaces, countAddedModuleWorkspaces],
  );

  const workflowWorkspacesToRender = useMemo(
    () =>
      filteredModuleWorkspaces
        .filter(
          (moduleWorkspace) =>
            moduleWorkspace.added &&
            !moduleWorkspace.is_favorited &&
            workflowIdentifiers.includes(
              moduleWorkspace.themis_module.identifier,
            ),
        )
        .sort(
          (a, b) =>
            workflowIdentifiers.indexOf(a.themis_module.identifier) -
            workflowIdentifiers.indexOf(b.themis_module.identifier),
        ),
    [filteredModuleWorkspaces, countAddedModuleWorkspaces],
  );

  const toolsWorkspacesToRender = useMemo(
    () =>
      filteredModuleWorkspaces.filter(
        (moduleWorkspace) =>
          moduleWorkspace.added &&
          !moduleWorkspace.is_favorited &&
          !libraryRoutes.includes(moduleWorkspace.themis_module.identifier) &&
          !workflowIdentifiers.includes(
            moduleWorkspace.themis_module.identifier,
          ) &&
          moduleInfo[moduleWorkspace.themis_module.identifier],
      ),
    [filteredModuleWorkspaces, countAddedModuleWorkspaces],
  );

  const trainingModuleWorkspace = moduleWorkspaces.find(
    (mw) => mw.themis_module.identifier === "training",
  );

  const attestationModule = moduleWorkspaces.find(
    (mw) => mw.themis_module.identifier === ("attestation" as ModuleIdentifier),
  );

  const newTrainingFeatureEnabled =
    useIsFeatureEnabled(FEATURE_FLAG_ID.GENG_TRAINING_MODULE_REVAMP) &&
    trainingModuleWorkspace?.added;

  const newAttestationsFeatureEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_ATTESTATION_MODULE_REVAMP,
  );

  const renderNewTrainingLink = (
    <NavigationLink
      url={generatePath(appRoutes.training, {
        workspace_id: workspaceId?.toString() || "",
      })}
      name="Trainings"
      moduleIdentifier="training"
      badge
    />
  );

  const attestationLink = newAttestationsFeatureEnabled ? (
    <NavigationLink
      url={generatePath(appRoutes.attestations, {
        workspace_id: workspaceId?.toString() || "",
      })}
      name="Attestations"
      moduleIdentifier="attestations"
      badge
    />
  ) : null;

  const webMonitoringLink = newWebMonitoringFeatureEnabled && (
    <NavigationLink
      url={generatePath(appRoutes.webMonitoring, {
        workspace_id: workspaceId?.toString() ?? "",
      })}
      name="Web Monitoring"
      moduleIdentifier="web-monitoring"
      badge
    />
  );

  const questionnairesLink = newQuestionnairesFeatureEnabled && (
    <NavigationLink
      url={generatePath(appRoutes.questionnaires, {
        workspace_id: workspaceId?.toString() ?? "",
      })}
      name="Questionnaires"
      moduleIdentifier="questionnaires"
      badge
    />
  );

  const isLinkSettingsActive = () =>
    location.pathname.startsWith("/settings/") &&
    !location.pathname.includes("/user/profile") &&
    !location.pathname.includes("/user/integrations");

  const isLinkTemplatesActive = location.pathname.startsWith(
    "/resources/compliance-templates",
  );

  const tagsShouldBeVisible =
    !(activeWorkspace && !activeWorkspace.is_internal) && canManageTags;

  const toolsWorkspacesExist =
    isIW ||
    isCollaborativeOnly ||
    (isIW && websiteMonitoringFeatureEnabled) ||
    toolsWorkspacesToRender.length > 0;

  const libraryWorkspacesExist =
    libraryWorkspacesToRender.length > 0 ||
    canManageAccounts ||
    tagsShouldBeVisible;

  function setCollapseState(section: sectionType) {
    switch (section) {
      case "library":
        setLibraryCollapsed((prev) => !prev);
        localStorage.setItem("libraryCollapsed", `${!libraryCollapsed}`);
        break;
      case "workflows":
        setWorkflowsCollapsed((prev) => !prev);
        localStorage.setItem("workflowsCollapsed", `${!workflowsCollapsed}`);
        break;
      default:
        setToolsCollapsed((prev) => !prev);
        localStorage.setItem("toolsCollapsed", `${!toolsCollapsed}`);
    }
  }

  return (
    <div className="dashboard-menu">
      <div className="dashboard-menu-list tw-mt-2">
        <ul className="settings-links" data-testid="settings-nav">
          <li
            data-testid="home-nav"
            data-tooltip-id="tooltip"
            data-tooltip-place="right"
            data-tooltip-content="Module summaries"
          >
            <NavLink to={`/workspaces/${workspaceId}/home`}>
              <PiHouseBold className="tw-h-4 tw-w-4 tw-stroke-[#6870b4]" />
              <span>Home</span>
            </NavLink>
          </li>
        </ul>
        <ul className="settings-links" data-testid="settings-nav">
          <li
            data-testid="my-tasks-nav"
            data-tooltip-id="tooltip"
            data-tooltip-content="View tasks assigned to you and others"
            data-tooltip-place="right"
          >
            <NavLink
              to="/my-tasks"
              className="tw-flex tw-items-center tw-justify-between"
            >
              <span className="tw-flex tw-items-center">
                <PiCheckSquareBold className="tw-h-4 tw-w-4 tw-stroke-[#6870b4]" />
                <span>Tasks</span>
              </span>
              <TaskCountBadge />
            </NavLink>
          </li>
          <li
            data-testid="projects-nav"
            data-tooltip-id="tooltip"
            data-tooltip-content="Organize and track the progress of multiple tasks"
            data-tooltip-place="right"
          >
            <NavLink
              to={generatePath(PROJECTS_LIST_PATH, {
                workspace_id: workspaceId!.toString(),
              })}
              className="tw-flex tw-items-center tw-justify-between"
            >
              <span className="tw-flex tw-items-center">
                <PiBagBold className="tw-h-4 tw-w-4 tw-stroke-[#6870b4]" />
                <span>Projects</span>
              </span>
            </NavLink>
          </li>
          <li
            data-testid="notifications-nav"
            data-tooltip-id="tooltip"
            data-tooltip-content="View your assigned comments and approvals"
            data-tooltip-place="right"
          >
            <NavLink
              to="/notifications/reviews"
              className="tw-flex tw-items-center tw-justify-between"
            >
              <span className="tw-flex tw-items-center">
                <PiBellBold className="tw-h-4 tw-w-4 tw-stroke-[#6870b4]" />
                <span>Review Items</span>
              </span>
              <ReviewsCountBadge />
            </NavLink>
          </li>

          {canManageSettings && (
            <li
              data-tooltip-id="tooltip"
              data-tooltip-content="Manage users, modules, and more"
              data-tooltip-place="right"
            >
              <NavLink
                to="/settings"
                data-testid="settings-nav-link"
                isActive={isLinkSettingsActive}
              >
                <PiGearBold className="tw-h-4 tw-w-4 tw-stroke-[#6870b4]" />
                <span>Settings</span>
              </NavLink>
            </li>
          )}

          {canViewReports && (
            <li
              data-tooltip-id="tooltip"
              data-tooltip-content="View and export reports"
              data-tooltip-place="right"
            >
              <NavLink to="/workspace-reports" replace={isReportsMatch}>
                <PiChartBarBold className="tw-h-4 tw-w-4 tw-stroke-[#6870b4]" />
                <span>Reports</span>
              </NavLink>
            </li>
          )}

          {tagsShouldBeVisible && (
            <li
              data-tooltip-id="tooltip"
              data-tooltip-content="Manage organization-wide tags"
              data-tooltip-place="right"
            >
              <NavLink to="/tags">
                <PiTagBold className="tw-h-4 tw-w-4 tw-stroke-[#6870b4]" />
                <span>Tags</span>
              </NavLink>
            </li>
          )}
        </ul>
        <hr />

        <SidebarSectionHeader
          title="Favorites"
          icon={
            <PiStarBold className="tw-mr-1 tw-h-5 tw-w-5 tw-stroke-neutral-500" />
          }
        />
        <ul className="dashboard-menu-list-links">
          {favoriteWorkspacesToRender
            .filter((moduleWorkspace) => !!moduleWorkspace)
            .map((moduleWorkspace) => {
              if (!moduleWorkspace) {
                return null;
              }

              return (
                <DashboardMenuItem
                  key={`favorite-module-${moduleWorkspace.id}`}
                  moduleInfo={moduleInfo}
                  moduleWorkspace={moduleWorkspace}
                />
              );
            })}

          {newTrainingFeatureEnabled &&
            trainingModuleWorkspace.is_favorited &&
            renderNewTrainingLink}

          {attestationModule?.is_favorited && attestationLink}
        </ul>

        <hr />

        {libraryWorkspacesExist && (
          <>
            <SidebarSectionHeader
              title="Library"
              icon={
                <PiFilesBold className="tw-mr-1 tw-h-5 tw-w-5 tw-stroke-neutral-500" />
              }
              setOpen={() => setCollapseState("library")}
              open={libraryCollapsed}
            />
            <Accordion collapsed={libraryCollapsed}>
              <ul className="dashboard-menu-list-links">
                {libraryWorkspacesToRender.map((moduleWorkspace) => (
                  <DashboardMenuItem
                    key={`library-module-${moduleWorkspace.id}`}
                    moduleInfo={moduleInfo}
                    moduleWorkspace={moduleWorkspace}
                  />
                ))}

                {newTrainingFeatureEnabled &&
                  !trainingModuleWorkspace.is_favorited &&
                  renderNewTrainingLink}

                {attestationLink}
                {questionnairesLink}
              </ul>
            </Accordion>

            <hr />
          </>
        )}

        {workflowWorkspacesToRender.length > 0 && (
          <>
            <SidebarSectionHeader
              title="Workflows"
              icon={
                <PiLineSegmentsBold className="tw-mr-1 tw-h-5 tw-w-5 tw-stroke-neutral-500" />
              }
              setOpen={() => setCollapseState("workflows")}
              open={workflowsCollapsed}
            />

            <Accordion collapsed={workflowsCollapsed}>
              <ul className="dashboard-menu-list-links">
                {workflowWorkspacesToRender.map((moduleWorkspace) => (
                  <DashboardMenuItem
                    key={`workflow-module-${moduleWorkspace.id}`}
                    moduleInfo={moduleInfo}
                    moduleWorkspace={moduleWorkspace}
                  />
                ))}
                {webMonitoringLink}
              </ul>
            </Accordion>
            <hr />
          </>
        )}

        {toolsWorkspacesExist && (
          <>
            <SidebarSectionHeader
              title="Tools"
              icon={
                <PiLockBold className="tw-mr-1 tw-h-5 tw-w-5 tw-stroke-neutral-500" />
              }
              setOpen={() => setCollapseState("tools")}
              open={toolsCollapsed}
            />

            <Accordion collapsed={toolsCollapsed}>
              <ul className="dashboard-menu-list-links">
                {toolsWorkspacesToRender.map((moduleWorkspace) => (
                  <DashboardMenuItem
                    key={`tool-module-${moduleWorkspace.id}`}
                    moduleInfo={moduleInfo}
                    moduleWorkspace={moduleWorkspace}
                  />
                ))}
                {isCollaborativeOnly && (
                  <li>
                    <Link
                      to="/resources/compliance-templates"
                      className={classNames("dashboard-menu-compliance-link", {
                        active: isLinkTemplatesActive,
                      })}
                    >
                      Templates
                      <SidebarTag bgColor="fuchsia-1" textColor="fuchsia-3">
                        New
                      </SidebarTag>
                    </Link>
                  </li>
                )}
              </ul>
            </Accordion>
          </>
        )}
      </div>
    </div>
  );
}

export default observer(DashboardMenu);
