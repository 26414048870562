import { faker } from "@faker-js/faker";

import type {
  GetCompanyMonitoringGroups200,
  GetCompanyMonitoringGroupsPathParams,
  GetCompanyMonitoringGroupsQueryParams,
  GetCompanyMonitoringGroupsQueryResponse,
} from "../models/GetCompanyMonitoringGroups";
import { createMonitoringGroup } from "./createMonitoringGroup";
import { createPaginatedMeta } from "./createPaginatedMeta";

export function createGetCompanyMonitoringGroupsPathParams(): NonNullable<GetCompanyMonitoringGroupsPathParams> {
  return { company_id: faker.number.int() };
}

export function createGetCompanyMonitoringGroupsQueryParams(): NonNullable<GetCompanyMonitoringGroupsQueryParams> {
  return {
    workspace_id: faker.number.int(),
    filters: {},
    limit: faker.number.int(),
    offset: faker.number.int(),
    sort_by: {},
    expand: faker.string.alpha(),
  };
}

/**
 * @description OK
 */
export function createGetCompanyMonitoringGroups200(): NonNullable<GetCompanyMonitoringGroups200> {
  return {
    data: faker.helpers.arrayElements([createMonitoringGroup()]) as any,
    meta: createPaginatedMeta(),
  };
}

/**
 * @description OK
 */
export function createGetCompanyMonitoringGroupsQueryResponse(): NonNullable<GetCompanyMonitoringGroupsQueryResponse> {
  return {
    data: faker.helpers.arrayElements([createMonitoringGroup()]) as any,
    meta: createPaginatedMeta(),
  };
}
