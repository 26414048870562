import { useToast } from "@themis/ui";
import { Divider } from "@themis/ui-library/components/data-display/divider/divider";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { Button } from "@themis/ui-library/components/inputs/button/button";
import { Checkbox } from "@themis/ui-library/components/inputs/checkbox/checkbox";
import { IconButton } from "@themis/ui-library/components/inputs/icon-button/icon-button";
import { Grid2 as Grid } from "@themis/ui-library/components/layout/grid2/grid2";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { useEffect, useState } from "react";
import { PiCaretLeftBold } from "react-icons/pi";
import { useIntl } from "react-intl";

import type { Field } from "@/api";
import { usePublicFormLayout } from "@/api/queries/share-link/use-public-form-layout";
import { useUpdatePublicFormLayout } from "@/api/queries/share-link/use-update-public-form-layout";

interface FieldsSelectorProps {
  fields: Array<Field>;
  moduleWorkspaceId: number;
  onClose: () => void;
  onBackToMenu: () => void;
  isOpen: boolean;
}

interface SelectedField {
  display_name: string;
  name: string;
  addToLink: boolean;
  required: boolean;
}

export function FieldsSelector({
  fields,
  moduleWorkspaceId,
  onClose,
  onBackToMenu,
  isOpen,
}: FieldsSelectorProps) {
  const { formatMessage } = useIntl();
  const toast = useToast();
  const { data } = usePublicFormLayout(moduleWorkspaceId);
  const { mutate } = useUpdatePublicFormLayout(moduleWorkspaceId);
  const [selectedFields, setSelectedFields] = useState<SelectedField[]>([]);
  const isSaveDisabled = !selectedFields.some((field) => field.addToLink);

  useEffect(() => {
    if (isOpen) {
      setSelectedFields(
        fields.map((field) => {
          const matchingField = data?.[field.name];

          return {
            display_name: field.display_name,
            name: field.name,
            addToLink: !!matchingField,
            required: matchingField?.is_required ?? false,
          };
        }),
      );
    }
  }, [data, fields]);

  const handleChange = (name: string, fieldType: "addToLink" | "required") => {
    setSelectedFields((prev) =>
      prev.map((field) => {
        if (field.name !== name) {
          return field;
        }

        return {
          ...field,
          [fieldType]: !field[fieldType],
          ...(fieldType === "addToLink" && field.addToLink
            ? { required: false }
            : {}),
        };
      }),
    );
  };
  const transformedFields = selectedFields.reduce(
    (acc, field) => {
      if (field.addToLink) {
        acc[field.name] = { is_required: field.required };
      }
      return acc;
    },
    {} as Record<string, { is_required: boolean }>,
  );

  const handleSave = () => {
    mutate(transformedFields, {
      onSuccess: () => {
        toast({
          content: formatMessage({
            defaultMessage: "Changes saved successfully!",
          }),
          variant: "success",
        });
      },
      onError: () => {
        toast({
          content: formatMessage({
            defaultMessage: "Failed to save changes.",
          }),
          variant: "error",
        });
      },
      onSettled: () => {
        onClose();
      },
    });
  };

  return (
    <Stack
      useFlexGap
      gap={0}
      sx={(theme) => ({
        boxSizing: "border-box",
        width: 400,
        py: theme.spacing(1),
        px: theme.spacing(2),
      })}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        sx={(theme) => ({
          py: theme.spacing(0.25),
          pb: theme.spacing(1.25),
        })}
      >
        <IconButton
          size="small"
          sx={(theme) => ({
            color: theme.palette.primary.main,
          })}
          onClick={onBackToMenu}
        >
          <PiCaretLeftBold />
        </IconButton>
        <Typography
          variant="h5"
          sx={(theme) => ({
            color: theme.palette.primary.main,
          })}
        >
          {formatMessage({
            defaultMessage: "Format Link",
          })}
        </Typography>
      </Stack>
      <Divider
        sx={(theme) => ({
          mx: theme.spacing(-2),
        })}
      />
      <Typography
        variant="h5"
        sx={(theme) => ({
          fontWeight: 600,
          py: theme.spacing(1),
          color: theme.palette.primary.main,
        })}
      >
        {formatMessage({
          defaultMessage: "Select fields to be included in the complaint form",
        })}
      </Typography>
      <Grid
        container
        columnSpacing={{ xs: 1 }}
        sx={(theme) => ({
          fontWeight: 600,
          py: theme.spacing(0.5),
        })}
      >
        <Grid size={6} />
        <Grid size={3} display="flex" justifyContent="center">
          <Typography
            variant="body2"
            sx={(theme) => ({ color: theme.palette.text.secondary })}
          >
            {formatMessage({
              defaultMessage: "Add to Link",
            })}
          </Typography>
        </Grid>
        <Grid size={3} display="flex" justifyContent="center">
          <Typography
            variant="body2"
            sx={(theme) => ({ color: theme.palette.text.secondary })}
          >
            {formatMessage({
              defaultMessage: "Required",
            })}
          </Typography>
        </Grid>
      </Grid>
      {selectedFields.map((field) => {
        return (
          <Grid
            key={field.name}
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1 }}
          >
            <Grid size={6} display="flex" alignItems="center">
              <Typography>{field.display_name}</Typography>
            </Grid>
            <Grid
              size={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Checkbox
                size="small"
                checked={field.addToLink}
                onClick={() => handleChange(field.name, "addToLink")}
              />
            </Grid>
            <Grid
              size={3}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Checkbox
                size="small"
                checked={field.required}
                disabled={!field.addToLink}
                onClick={() => handleChange(field.name, "required")}
              />
            </Grid>
          </Grid>
        );
      })}
      <Divider
        sx={(theme) => ({
          mx: theme.spacing(-2),
          mb: theme.spacing(1),
        })}
      />
      <Button
        sx={{ ml: "auto" }}
        onClick={handleSave}
        disabled={isSaveDisabled}
      >
        {formatMessage({
          defaultMessage: "Save",
        })}
      </Button>
    </Stack>
  );
}
