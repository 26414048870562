import { useIntl } from "react-intl";
import { z } from "zod";

export const useValidationSchema = () => {
  const { formatMessage } = useIntl();

  const commonColumnData = {
    name: z.string().min(1, {
      message: formatMessage({
        defaultMessage: "Column name is required",
      }),
    }),
    requiredColumn: z.optional(z.boolean()),
    multipleSelections: z.optional(z.boolean()),
    internal: z.optional(z.boolean()),
  };

  return z.discriminatedUnion("columnType", [
    z.object({
      columnType: z.literal("text"),
      columnData: z.object({ ...commonColumnData }),
    }),
    z.object({
      columnType: z.literal("select"),
      columnData: z.object({
        ...commonColumnData,
        options: z
          .array(
            z.object({
              name: z.string().min(1, {
                message: formatMessage({
                  defaultMessage: "Option name is required",
                }),
              }),
              color: z.string().nullable(),
            }),
          )
          .min(1)
          .refine(
            (options) => {
              const optionNames = options.map((option) => option.name);
              return new Set(optionNames).size === optionNames.length;
            },
            {
              message: formatMessage({
                defaultMessage: "Option names must be unique",
              }),
            },
          ),
      }),
    }),
    z.object({
      columnType: z.literal("link"),
      columnData: z.object({ ...commonColumnData }),
    }),
    z.object({
      columnType: z.literal("date"),
      columnData: z.object({ ...commonColumnData }),
    }),
    z.object({
      columnType: z.literal("checklist"),
      columnData: z.object({ ...commonColumnData }),
    }),
    z.object({
      columnType: z.literal("attachment"),
      columnData: z.object({ ...commonColumnData }),
    }),
    z.object({
      columnType: z.literal("user"),
      columnData: z.object({ ...commonColumnData }),
    }),
  ]);
};

export type ValidationSchema = z.infer<ReturnType<typeof useValidationSchema>>;

export type DropdownColumnSchema<
  Schema = ValidationSchema & { columnType: "select" },
> = {
  [Key in keyof Schema]: Schema[Key];
};
