import { DownloadSimple } from "@phosphor-icons/react";
import { useDownloadFile } from "@themis/shared/hooks/use-download-file/use-download-file";
import { Button } from "@themis/ui-library/components/inputs/button/button";
import { useIntl } from "react-intl";

import { useInteractiveMediaContext } from "../../interactive-media.context";

export function DownloadButton() {
  const { formatMessage } = useIntl();
  const { file } = useInteractiveMediaContext();
  const { downloadFile, isLoading } = useDownloadFile();

  if (!file) {
    return null;
  }

  return (
    <Button
      variant="text"
      color="inherit"
      loading={isLoading}
      startIcon={<DownloadSimple />}
      onClick={() => downloadFile(file.file_url, file.file_name)}
    >
      {formatMessage({
        defaultMessage: "Download",
      })}
    </Button>
  );
}
