import type { ChipProps } from "@themis/ui-library/components/data-display/chip/chip";
import { Chip } from "@themis/ui-library/components/data-display/chip/chip";
import type { CustomCellRendererProps } from "ag-grid-react";

type Status = "pending" | "approved" | "rejected";
type StatusCellProps = CustomCellRendererProps<{ status: Status }>;

const statusToColorMap: Record<Status, ChipProps["color"]> = {
  approved: "success",
  pending: "warning",
  rejected: "error",
};

const statusToTextMap: Record<Status, string> = {
  approved: "Approved",
  pending: "Pending",
  rejected: "Rejected",
};

export function StatusCell({ data }: StatusCellProps) {
  if (!data) {
    return null;
  }

  return (
    <Chip
      color={statusToColorMap[data.status]}
      label={statusToTextMap[data.status]}
    />
  );
}
