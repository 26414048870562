import type { DataWithIdAndCustomFields, PreviewTableColumn } from "@themis/ui";
import { Button, PreviewTable } from "@themis/ui";
import type { MonitoringGroupAssetChild } from "app/javascript/api/gen/models/MonitoringGroupAssetChild";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useState } from "react";

import type { Section } from "@/stores/types/section-tags";

import arrowUp from "../../../images/table-image/icon/arrow-up.svg";

interface Props<R extends DataWithIdAndCustomFields> {
  isDropdownOpen?: boolean;
  sectionTag?: Section;
  columns: PreviewTableColumn<R>[];
  rows?: R[];
  monitoringGroupAssetChild?: MonitoringGroupAssetChild;
}

function MonitoringGroupAssetChildSection<R extends DataWithIdAndCustomFields>({
  sectionTag,
  columns,
  rows,
  monitoringGroupAssetChild,
  ...props
}: Props<R>) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(props.isDropdownOpen);
  const sectionTableID = `section-table-${sectionTag?.id || 0}`;
  const [isDoc, setIsDoc] = useState(false);
  const [isImage, setIsImage] = useState(false);
  const [isMetadata, setIsMetadata] = useState(true);

  const sortOrder = ["Compliance", "MissingDisclosures", "LinksWithIssues"];
  const sortRows = rows?.sort((a, b) => {
    let comparison = String(
      b.monitoring_group_asset_child_result_created_at,
    )?.localeCompare(String(a.monitoring_group_asset_child_result_created_at));
    if (comparison !== 0) {
      return comparison;
    }
    const orderA = sortOrder.indexOf(String(a?.content_sub_type) || "");
    const orderB = sortOrder.indexOf(String(b?.content_sub_type) || "");
    comparison = orderA - orderB;
    if (comparison !== 0) {
      return comparison;
    }
    return a.id - b.id;
  });
  const rowsDoc = sortRows?.filter((row) => row.sub_type === "Documents");
  const rowsImage = sortRows?.filter((row) => row.sub_type === "Images");
  const rowsMetadata = sortRows?.filter((row) => row.sub_type === "Metadata");

  const activeClass = "tw-bg-primary-300";
  const inActiveClass = "tw-bg-neutral-200";

  const dropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  function setSubTypeValues(valueType: string) {
    if (valueType === "Doc") {
      setIsDoc(Boolean(true));
      setIsImage(Boolean(false));
      setIsMetadata(Boolean(false));
    } else if (valueType === "Image") {
      setIsDoc(Boolean(false));
      setIsImage(Boolean(true));
      setIsMetadata(Boolean(false));
    } else if (valueType === "Metadata") {
      setIsDoc(Boolean(false));
      setIsImage(Boolean(false));
      setIsMetadata(Boolean(true));
    }
  }

  const gridRows = () => {
    if (isDoc) {
      return rowsDoc;
    }
    if (isImage) {
      return rowsImage;
    }
    if (isMetadata) {
      return rowsMetadata;
    }
  };

  return (
    <>
      {monitoringGroupAssetChild && (
        <div className="dropdown-title-wrap" data-testid="dropdown-section">
          <div
            className={classNames("dropdown-title tw-items-center", {
              "dropdown-open": isDropdownOpen,
            })}
          >
            <div className="dropdown-title-block tw-items-center">
              <div className="dropdown-title-click tw-items-center">
                {monitoringGroupAssetChild?.data && (
                  <div className="tw-flex tw-justify-center tw-rounded-md">
                    <div className="dropdown-arrow" onClick={dropdownClick}>
                      <img src={arrowUp} alt="dropdown-arrow" />
                    </div>
                    <a
                      href={monitoringGroupAssetChild?.data}
                      target="_blank"
                      className="tw-w-[600px] tw-content-center 
                    tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap
                    tw-font-semibold
                    tw-text-secondary-300"
                      data-testid={`group-child-${monitoringGroupAssetChild?.id}`}
                      data-tooltip-id="tooltip"
                      data-tooltip-content={monitoringGroupAssetChild?.data}
                      data-tooltip-place="bottom"
                      rel="noreferrer"
                    >
                      {monitoringGroupAssetChild?.data}
                    </a>
                    <Button
                      onClick={() => setSubTypeValues("Metadata")}
                      className={classNames("tw-mx-0.5 tw-w-[100px]", {
                        [activeClass]: isMetadata,
                        [inActiveClass]: !isMetadata,
                      })}
                    >
                      Screenshot
                    </Button>
                    <Button
                      onClick={() => setSubTypeValues("Doc")}
                      className={classNames("tw-mx-0.5 tw-w-[100px]", {
                        [activeClass]: isDoc,
                        [inActiveClass]: !isDoc,
                      })}
                    >
                      Doc
                    </Button>
                    <Button
                      onClick={() => setSubTypeValues("Image")}
                      className={classNames("tw-mx-0.5 tw-w-[100px]", {
                        [activeClass]: isImage,
                        [inActiveClass]: !isImage,
                      })}
                    >
                      Image
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {isDropdownOpen && (
        <div
          className="dropdown-table tw-z-30"
          id={sectionTableID}
          data-testid="dropdown-table"
        >
          {rows && rows?.length > 0 && (
            <PreviewTable
              headerDisplay={false}
              rows={gridRows()}
              columns={columns}
              customCellClassName="tw-bg-neutral-25 tw-text-neutral-500"
              customRowClassName="tw-rounded-l-0 tw-border-x-0 tw-border-solid tw-border-neutral-100 tw-rounded-r-0 tw-border-l-0 tw-border-r-0 tw-border-solid tw-border-neutral-100"
            />
          )}

          {(!rows ||
            rows?.length < 1 ||
            (isDoc && (rowsDoc?.length || 0) < 1) ||
            (isImage && (rowsImage?.length || 0) < 1) ||
            (isMetadata && (rowsMetadata?.length || 0) < 1)) && (
            <div>No Results</div>
          )}
        </div>
      )}
    </>
  );
}

export default observer(MonitoringGroupAssetChildSection);
