import { checkContentOverflows } from "@themis/ui-library/utils/check-content-overflows";
import type { CustomCellRendererProps } from "ag-grid-react";
import { debounce } from "lodash";
import React, { useRef, useState } from "react";

import { Popover, PopoverAnchor, PopoverContent } from "../../Popover/Popover";

type Props = CustomCellRendererProps & {
  value: string;
};

function TextRenderCell({ value, valueFormatted }: Props) {
  const textRef = useRef<HTMLDivElement>(null);

  const [isHovering, setIsHovering] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const measureTextElement = (textElement: HTMLDivElement | null) => {
    setIsOverflowing(() => {
      if (textElement) {
        return checkContentOverflows(textElement);
      }

      return false;
    });
  };

  const debouncedHandleMouseEnter = debounce(() => {
    setIsHovering(true);
  }, 500);

  const handleOnMouseLeave = () => {
    debouncedHandleMouseEnter.cancel();
    setIsHovering(false);
  };

  return (
    <>
      <div
        ref={textRef}
        onMouseEnter={debouncedHandleMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        className="tw-flex tw-h-full tw-w-full tw-items-center tw-overflow-hidden"
      >
        <div
          ref={measureTextElement}
          className="tw-overflow-hidden tw-overflow-ellipsis tw-px-2 tw-font-sans tw-font-medium tw-text-neutral-500 read-only:tw-text-neutral-300"
        >
          {valueFormatted || value}
        </div>
      </div>
      <Popover open={isHovering && isOverflowing}>
        <PopoverAnchor virtualRef={textRef} />
        <PopoverContent
          align="center"
          side="bottom"
          onEscapeKeyDown={() => setIsHovering(false)}
        >
          <div className="tw-max-w-[250px] tw-break-words tw-p-2 tw-text-sm tw-text-neutral-500">
            {value || valueFormatted}
          </div>
        </PopoverContent>
      </Popover>
    </>
  );
}

export default TextRenderCell;
