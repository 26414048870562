import type { ReactElement } from "react";

import type { ButtonProps } from "../button/button";
import { Button } from "../button/button";

interface ToggleButtonProps extends ButtonProps {
  active: boolean;
}

export function ToggleButton({
  active,
  ...otherProps
}: ToggleButtonProps): ReactElement {
  const stateProps: ButtonProps = active
    ? { variant: "contained", color: "neutral" }
    : {
        variant: "text",
        color: "neutral",
        sx: {
          "&:hover": {
            bgcolor: "primary.lightest",
          },
        },
      };

  return <Button {...stateProps} {...otherProps} />;
}
