import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";

import { API_URL } from "../../components/constants";
import type { MainStore } from "../Main";
import type { IndexParams, ModuleStore } from "../types/module-store-types";

export default class Trainings implements ModuleStore {
  mainStore: MainStore;

  PLACEHOLDER_SECTION_TAG_ID = 0;
  // Observable objects
  data = {};
  lastTab = "All";
  historical = [];
  page = 1;
  pages = { [this.PLACEHOLDER_SECTION_TAG_ID]: 1 }; // Pages should be {section_tag_id: page_number} eg {base: 1, 13: 1, 14: 2} if the third section is on the second page.

  controller: AbortController | null = null;

  constructor(mainStore: MainStore) {
    makeObservable(this, {
      data: observable,
      lastTab: observable,
      historical: observable,
      setData: action,
      setLastTab: action,
      setHistorical: action,
      page: observable,
      setPage: action,
      setPages: action,
      setDefaultPages: action,
      clearPages: action,
    });

    this.mainStore = mainStore;
  }

  async index({
    workspaceID,
    tab = this.lastTab,
    sortParams,
    pages = { [this.PLACEHOLDER_SECTION_TAG_ID]: 1 },
    sectionTagId = null,
    tableFilters = [],
  }: IndexParams) {
    const controller = new AbortController();
    this.controller = controller;

    try {
      const params = {
        tab,
        table_filters: tableFilters,
        sort_field_name: sortParams?.field_name,
        sort_direction: sortParams?.direction,
        section_tag_id: sectionTagId,
      };

      const mergedPages = { ...this.pages, ...pages };
      const pages_string = JSON.stringify(mergedPages);

      // @ts-expect-error TS(2339) FIXME: Property 'pages' does not exist on type '{ table_t... Remove this comment to see the full error message
      params.pages = pages_string;

      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${workspaceID}/trainings`,
        headers: this.mainStore.getHeaders(),
        signal: controller.signal,
        params,
      });

      this.setPages(mergedPages);
      // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
      if (this.data?.record_versions) {
        this.setData({
          ...this.data,
          record_versions: [
            // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
            ...this.data.record_versions.filter(
              // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
              (item) => item.section_tag_id !== sectionTagId,
            ),
            ...response.data.record_versions.filter(
              // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
              (item) => item.section_tag_id === sectionTagId,
            ),
          ],
        });
      } else {
        this.setData(response.data);
      }
      this.setLastTab(tab);
      this.mainStore.fields.setList(response.data.fields);
    } catch (error) {
      window.console.log(`"Trainings#Index for Workspace" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'workspaceID' implicitly has an 'any' ty... Remove this comment to see the full error message
  async create(workspaceID, sectionTagId = null) {
    const data = { section_tag_id: sectionTagId };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/workspaces/${workspaceID}/trainings`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"Trainings#create" error ${error}`);
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async delete(recordVersionID) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/trainings/${recordVersionID}`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"Trainings#delete" error ${error}`);
    }
  }

  // POST /api/react/trainings/:id/finalize
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async finalize(recordVersionID) {
    try {
      const result = await legacyApi({
        method: "POST",
        url: `${API_URL}/trainings/${recordVersionID}/finalize`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.recordVersions.setCellsErrors(
          // @ts-expect-error TS(2339) FIXME: Property 'response' does not exist on type 'AxiosR... Remove this comment to see the full error message
          result.response.data.cells_errors,
        );
        return;
      }

      return true;
    } catch (error) {
      window.console.log(`"Trainings#finalize" error ${error}`);
    }
  }

  // POST /api/react/trainings/:id/unlock
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async unlock(recordVersionID) {
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/trainings/${recordVersionID}/unlock`,
        headers: this.mainStore.getHeaders(),
      });

      // remove unlocked record from UI, unless user is on 'All' tab.
      if (this.lastTab !== "All") {
        this.setData({
          ...this.data,
          // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
          record_versions: this.data.record_versions.filter(
            // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
            (item) => item.id !== recordVersionID,
          ),
        });
      }

      return true;
    } catch (error) {
      window.console.log(`"Trainings#unlock" error ${error}`);
      return false;
    }
  }

  // GET /api/react/trainings/:id/historical
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async historicalList(recordVersionID) {
    const params = { identifier: "training" };

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/trainings/${recordVersionID}/historical`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      this.setHistorical(response.data.record_versions);
    } catch (error) {
      this.setHistorical([]);
      window.console.log(`"Trainings#historicalList" error ${error}`);
    }
  }

  // PUT /api/react/trainings/:id/update_section
  // @ts-expect-error TS(7006) FIXME: Parameter 'recordVersionID' implicitly has an 'any... Remove this comment to see the full error message
  async updateSection(recordVersionID, sectionTagID) {
    const data = { section_tag_id: sectionTagID, identifier: "training" };

    try {
      await legacyApi({
        method: "PUT",
        url: `${API_URL}/trainings/${recordVersionID}/update_section`,
        headers: this.mainStore.getHeaders(),
        data,
      });
    } catch (error) {
      window.console.log(`"Trainings#updateSection" error ${error}`);
    }
  }

  // POST /api/react/trainings/:id/archive
  async archive(recordVersionID: number) {
    const params = { identifier: "training" };

    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/trainings/${recordVersionID}/archive`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      return true;
    } catch (error) {
      window.console.log(`"Trainings#archive" error ${error}`);
      return false;
    }
  }

  // POST /api/react/trainings/:id/archive_attestations
  async archiveAttestations(recordVersionID: number) {
    try {
      await legacyApi({
        method: "POST",
        url: `${API_URL}/trainings/${recordVersionID}/archive_attestations`,
        headers: this.mainStore.getHeaders(),
      });

      return true;
    } catch (error) {
      window.console.log(`"Trainings#archiveAttestations" error ${error}`);
      return false;
    }
  }

  // GET /api/react/trainings/archived
  async archivedList() {
    const params = { identifier: "training" };

    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/trainings/archived`,
        headers: this.mainStore.getHeaders(),
        params,
      });

      return response.data;
    } catch (error) {
      window.console.log(`"Trainings#archivedList" error ${error}`);
    }
  }

  // GET /api/react/workspaces/:id/trainings/archived_attestations
  async archivedAttestations(workspaceID: number | null) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/workspaces/${workspaceID}/trainings/archived_attestations`,
        headers: this.mainStore.getHeaders(),
      });

      return response.data;
    } catch (error) {
      window.console.log(`"Trainings#archivedAttestations" error ${error}`);
    }
  }

  // Actions
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setData(value) {
    this.data = value;
    // @ts-expect-error TS(2339) FIXME: Property 'record_versions' does not exist on type ... Remove this comment to see the full error message
    this.mainStore.recordVersions.setList(this.data.record_versions);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setLastTab(value) {
    this.lastTab = value;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setHistorical(value) {
    this.historical = value || [];
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  setPages(value) {
    const merged_pages = { ...this.pages, ...value };
    this.pages = merged_pages;
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'sectionTags' implicitly has an 'any' ty... Remove this comment to see the full error message
  setDefaultPages(sectionTags) {
    const new_pages = sectionTags?.reduce(
      // @ts-expect-error TS(7006) FIXME: Parameter 'object' implicitly has an 'any' type.
      (object, section) => ((object[section?.id] = 1), object),
      {},
    );
    const merged_pages = { ...this.pages, ...new_pages };
    this.pages = merged_pages;
  }

  clearPages() {
    this.pages = { [this.PLACEHOLDER_SECTION_TAG_ID]: 1 };
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'page' implicitly has an 'any' type.
  setPage(page) {
    this.page = page;
  }

  // Store Helpers

  cleanup() {
    this.setData({});
    this.setLastTab("All");
    this.setHistorical([]);
  }

  abort() {
    this.controller?.abort();
  }
}
