import { useEffect, useState } from "react";
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";

import Loading from "@/components/Loading";
import LoadingError from "@/components/loading-error/loading-error";
import CreativeView from "@/components/table/shared/creative-view/CreativeView";
import { useMainStore } from "@/contexts/Store";

import { routes } from "../routes";
import CreateQuestionnaireTemplatePage from "./CreateQuestionnaireTemplatePage";
import CreateRiskMethodology from "./CreateRiskMethodology";
import CustomerQuestionnaireDetailPage from "./cw/CustomerQuestionnaireDetailPage";
import CustomerQuestionnaireScoringPage from "./cw/CustomerQuestionnaireScoringPage";
import CWPartnerDocumentsPage from "./cw/CWPartnerDocumentsPage";
import PartnerQuestionnairePage from "./cw/PartnerQuestionnairePage";
import PartnerQuestionnaireReview from "./cw/PartnerQuestionnaireReview";
import EditQuestionnaireTemplatePage from "./EditQuestionnaireTemplatePage";
import EditRiskMethodology from "./EditRiskMethodology";
import PartnerDetailsPage from "./PartnerDetailsPage";
import PartnerDocumentsPage from "./PartnerDocumentsPage";
import PartnersPage from "./PartnersPage";
import PartnersQuestionnaireCreatePage from "./PartnersQuestionnaireCreatePage";
import PartnersQuestionnairesPage from "./PartnersQuestionnairesPage";
import PartnersScoringPage from "./PartnersScoringPage";
import QuestionnaireReviewPage from "./QuestionnaireReviewPage/QuestionnaireReviewPage";
import QuestionnaireScoringPage from "./QuestionnaireScoringPage";
import QuestionnaireTemplatesPage from "./QuestionnaireTemplatesPage";
import RADashboardPage from "./RADashboardPage";
import RiskAreasPage from "./RiskAreasPage/RiskAreasPage";
import RiskMethodologyPage from "./RiskMethodologyPage/RiskMethodologyPage";

function InternalRoutes() {
  const { workspace_id } = useParams<{ workspace_id: string }>();

  return (
    <Route path={routes.BASE_PATH}>
      <Switch>
        <Route exact path={routes.DASHBOARD_PATH}>
          <RADashboardPage />
        </Route>
        <Route exact path={routes.COLLABORATIVE_DASHBOARD_PATH}>
          <RADashboardPage isCollaborative />
        </Route>
        <Route exact path={routes.PARTNERS_PATH}>
          <PartnersPage />
        </Route>
        <Route path={routes.QUESTIONNAIRE_TEMPLATE_PATH}>
          <Switch>
            <Route exact path={routes.QUESTIONNAIRE_TEMPLATE_CREATION_PATH}>
              <CreateQuestionnaireTemplatePage />
            </Route>
            <Route exact path={routes.QUESTIONNAIRE_TEMPLATE_EDIT_PATH}>
              <EditQuestionnaireTemplatePage />
            </Route>
            <Route path="*">
              <QuestionnaireTemplatesPage />
            </Route>
          </Switch>
        </Route>

        <Route exact path={routes.METHODOLOGY_PATH}>
          <RiskMethodologyPage />
        </Route>
        <Route exact path={routes.RISK_AREA_PATH}>
          <RiskAreasPage />
        </Route>
        <Route exact path={routes.METHODOLOGY_CREATION_PATH}>
          <CreateRiskMethodology />
        </Route>
        <Route exact path={routes.METHODOLOGY_EDIT_PATH}>
          <EditRiskMethodology />
        </Route>
        <Route exact path={routes.PARTNERS_DETAILS_NEW_PATH}>
          <PartnerDetailsPage isNew />
        </Route>
        <Route exact path={routes.PARTNERS_DETAILS_EDIT_PATH}>
          <PartnerDetailsPage />
        </Route>
        <Route
          exact
          path={routes.PARTNERS_QUESTIONNAIRES_CREATE_WITH_TEMPLATE_PATH}
        >
          <PartnersQuestionnaireCreatePage />
        </Route>
        <Route exact path={routes.PARTNERS_QUESTIONNAIRES_CREATE_PATH}>
          <PartnersQuestionnaireCreatePage />
        </Route>
        <Route exact path={routes.PARTNERS_QUESTIONNAIRES_PATH}>
          <PartnersQuestionnairesPage />
        </Route>
        <Route exact path={routes.PARTNERS_SCORING_PATH}>
          <PartnersScoringPage />
        </Route>
        <Route exact path={routes.PARTNERS_DOCUMENTS_PATH}>
          <PartnerDocumentsPage />
        </Route>
        <Route exact path={routes.INTERNAL_QUESTIONNAIRE_REVIEW_PATH}>
          <PartnerQuestionnaireReview
            hideResponderChange
            showResponderList
            showRespondentFilter
          />
        </Route>
        <Route exact path={routes.QUESTIONNAIRE_REVIEW_PATH}>
          <QuestionnaireReviewPage />
        </Route>
        <Route exact path={routes.QUESTIONNAIRE_SCORING_PATH}>
          <QuestionnaireScoringPage />
        </Route>
        <Route path={routes.CREATE_ATTACHMENT_PATH}>
          <CreativeView />
        </Route>
        <Route path="*">
          <Redirect
            to={generatePath(routes.DASHBOARD_PATH, { workspace_id })}
          />
        </Route>
      </Switch>
    </Route>
  );
}

function PartnerRoutes() {
  const { workspace_id } = useParams<{ workspace_id: string }>();
  return (
    <Route path={routes.BASE_PATH}>
      <Switch>
        <Route exact path={routes.DASHBOARD_PATH}>
          <PartnerQuestionnairePage />
        </Route>
        <Route exact path={routes.CW_PARTNERS_DOCUMENTS_PATH}>
          <CWPartnerDocumentsPage />
        </Route>
        <Route exact path={routes.PARTNERS_QUESTIONNAIRE_REVIEW_PATH}>
          <PartnerQuestionnaireReview />
        </Route>

        <Route path={routes.CUSTOMER_QUESTIONNAIRE_DETAIL_REVIEW_PATH}>
          <CustomerQuestionnaireDetailPage />
        </Route>
        <Route path={routes.CUSTOMER_QUESTIONNAIRE_SCORING_PATH}>
          <CustomerQuestionnaireScoringPage />
        </Route>

        <Route path={routes.QUESTIONNAIRE_REVIEW_PATH}>
          <QuestionnaireReviewPage
            redirectPathAfterCompletion={routes.DASHBOARD_PATH}
            isPartner
          />
        </Route>
        <Route path="*">
          <Redirect
            to={generatePath(routes.DASHBOARD_PATH, { workspace_id })}
          />
        </Route>
      </Switch>
    </Route>
  );
}

export function RiskAssessmentRouter() {
  const mainStore = useMainStore();
  const isInternal = mainStore.context.activeWorkspace?.is_internal;

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const { workspaceID } = mainStore.context;
  const location = useLocation();
  const isRouterMatched = Boolean(
    useRouteMatch({
      path: `${routes.BASE_PATH}/:record_version_id`,
    }),
  );
  const isMainPage = Boolean(
    useRouteMatch({
      path: routes.PARTNERS_ASSESSMENTS_PATH,
      exact: true,
    }),
  );

  const fetchData = async () => {
    if (workspaceID) {
      try {
        if (isMainPage || isRouterMatched) {
          await mainStore.riskAssessmentPartners.index({ workspaceID });
        }
        await mainStore.riskMethodologies.index(workspaceID);
      } catch (err) {
        setError("There was an issue loading Risk Assessment module.");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [workspaceID, location.pathname]);

  if (error) {
    return <LoadingError loadingError="error" />;
  }

  if (loading) {
    return <Loading loadingLayout="table" />;
  }

  return isInternal ? <InternalRoutes /> : <PartnerRoutes />;
}
