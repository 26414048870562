import { useFormControlStateContext } from "@themis/shared/contexts/form-control-state-context/form-control-state-context";
import type { SelectProps } from "@themis/ui";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Select,
} from "@themis/ui";
import type { ReactNode } from "react";
import type { Control, FieldValues, Path } from "react-hook-form";

export interface SelectFormFieldProps<T extends FieldValues> {
  disabled?: boolean;
  label?: ReactNode;
  control: Control<T>;
  name: Path<T>;
  required?: boolean;
  items: SelectProps["items"];
  searchable?: SelectProps["searchable"];
  multiselect?: boolean;
  title?: string;
  renderSelected?: SelectProps["renderSelected"];
  renderOption?: SelectProps["renderOption"];
}

export function SelectFormField<T extends FieldValues>({
  disabled,
  control,
  items,
  name,
  label,
  required,
  searchable,
  multiselect,
  title,
  renderOption,
  renderSelected,
}: SelectFormFieldProps<T>) {
  const { disabled: disabledFormControlState } = useFormControlStateContext();

  return (
    <FormField
      control={control}
      name={name}
      required={required}
      render={({ field, fieldState }) => {
        const getSelected = () => {
          if (multiselect) {
            return Array.isArray(field.value) ? field.value.map(String) : [];
          }

          return field.value ? String(field.value) : field.value;
        };

        return (
          <FormItem>
            {label && <FormLabel>{label}</FormLabel>}

            <FormControl>
              <Select
                {...field}
                disabled={disabled || disabledFormControlState}
                error={!!fieldState.error}
                multiple={multiselect}
                items={items}
                searchable={searchable}
                selected={getSelected()}
                title={title}
                onSelect={field.onChange}
                renderSelected={renderSelected}
                renderOption={renderOption}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
}
