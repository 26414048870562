import { type ThemeOptions } from "@mui/material";

export const card: ThemeOptions["components"] = {
  MuiCard: {
    styleOverrides: {
      root: ({ theme }) => ({
        padding: theme.spacing(1),
      }),
    },
    variants: [
      {
        props: { variant: "contained" },
        style: ({ theme }) => ({
          borderColor: "transparent",
          backgroundColor: theme.palette.grey[100],
        }),
      },
    ],
  },
};
