import { TableTooltip } from "@themis/ui";
import { Avatar } from "@themis/ui-library/components/data-display/avatar/avatar";
import { Chip } from "@themis/ui-library/components/data-display/chip/chip";
import { useDetectOverflow } from "@themis/ui-library/hooks/use-detect-overflow/use-detect-overflow";
import { keyBy } from "lodash";

import { getFieldOptionPaletteColor } from "../../../../utils/get-field-option-palette-color";
import type { BaseDynamicValueProps } from "../base-dynamic-value";

type DynamicSelectValueProps = BaseDynamicValueProps<string>;

export const SelectValue = ({
  value,
  configuration,
}: DynamicSelectValueProps) => {
  const { isOverflowing, measureElement, visibleChildCount } =
    useDetectOverflow();

  if (!value) {
    return null;
  }

  const optionsMap = keyBy(configuration.options, "key");

  const options = (Array.isArray(value) ? value : [value])
    .map((item) => (item ? optionsMap[item] : undefined))
    .filter((item) => item != null);

  const notVisibleChildCount = options.length - visibleChildCount;

  const renderTags = (limit?: number) => {
    return options.slice(0, limit).map((option) => {
      if (!option) {
        return null;
      }

      return (
        <Chip
          key={option.key}
          label={option.value}
          colorSeed={option.key}
          color={getFieldOptionPaletteColor(option)}
          size="small"
        />
      );
    });
  };

  return (
    <div className="tw-relative tw-w-full">
      <TableTooltip
        content={
          <div className="tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-font-normal">
            {renderTags()}
          </div>
        }
        disabled={!isOverflowing}
      >
        <div className="tw-flex tw-w-full tw-flex-nowrap tw-gap-1 tw-overflow-hidden tw-overflow-ellipsis tw-text-nowrap tw-font-normal">
          {renderTags(visibleChildCount)}

          {notVisibleChildCount > 0 && (
            <Avatar size="lg">+{notVisibleChildCount}</Avatar>
          )}
        </div>
      </TableTooltip>

      {/* Invisible element used to calculate how many items can be seen */}
      <div
        className="tw-pointer-events-none tw-absolute tw-left-0 tw-right-8 tw-top-0 tw-flex tw-flex-nowrap tw-gap-1 tw-overflow-hidden tw-overflow-ellipsis tw-text-nowrap tw-font-normal"
        style={{ visibility: "hidden" }}
        ref={measureElement}
      >
        {renderTags()}
      </div>
    </div>
  );
};
