import type { FieldFormat } from "@themis/api/gen/models/field";
import { fieldFormat } from "@themis/api/gen/models/field";
import { Typography } from "@themis/ui-library/components/data-display/typography/typography";
import { FormControlLabel } from "@themis/ui-library/components/form/form-control-label/form-control-label";
import { RadioGroup } from "@themis/ui-library/components/inputs/radio-group/radio-group";
import { Radio } from "@themis/ui-library/components/inputs/radio/radio";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import type { ChangeEvent } from "react";
import { useIntl } from "react-intl";

interface NumberFormatRadioGroupProps {
  value: FieldFormat;
  onChange: (value: FieldFormat) => void;
}

export function NumberFormatRadioGroup({
  value,
  onChange,
}: NumberFormatRadioGroupProps) {
  const { formatMessage } = useIntl();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value as FieldFormat);
  };

  return (
    <Stack paddingLeft={1} paddingRight={3}>
      <RadioGroup value={value} onChange={handleChange}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormControlLabel
            value={fieldFormat.number}
            control={<Radio />}
            label={formatMessage({
              defaultMessage: "Number",
            })}
            slotProps={{
              typography: {
                variant: "body2",
                fontWeight: 600,
                color: "textPrimary",
              },
            }}
          />
          <Typography variant="body2" color="textSecondary">
            1,000.12
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <FormControlLabel
            value={fieldFormat.accounting}
            control={<Radio />}
            label={formatMessage({
              defaultMessage: "Accounting",
            })}
            slotProps={{
              typography: {
                variant: "body2",
                fontWeight: 600,
                color: "textPrimary",
              },
            }}
          />
          <Typography variant="body2" color="textSecondary">
            $ (1,000.12)
          </Typography>
        </Stack>
      </RadioGroup>
    </Stack>
  );
}
