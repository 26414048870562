import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@themis/ui";
import { Tooltip } from "@themis/ui-library/components/data-display/tooltip/tooltip";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import classNames from "classnames";
import { kebabCase } from "lodash";
import { observer } from "mobx-react";
import { PiGearSixBold } from "react-icons/pi";
import { useIntl } from "react-intl";
import { generatePath, Link, matchPath, useLocation } from "react-router-dom";

import mainStore from "@/stores/Main";
import type { ModuleIdentifier } from "@/stores/types/module-workspaces-types";

import { REPORT_ENABLED_MODULES } from "../../components/reports/constants";

export const MANAGE_APPROVALS_ENABLED_MODULES: ModuleIdentifier[] = [
  "policy",
  "marketing",
];

const MENU_ITEM_CLASSES =
  "tw-pl-4 tw-flex tw-items-center tw-text-sm tw-min-h-8 tw-font-medium tw-tracking-lesswide tw-leading-5";

function MoreOptionsButton({
  moduleIdentifier,
}: {
  moduleIdentifier: ModuleIdentifier;
}) {
  const moreButton = useOpen();
  const { formatMessage } = useIntl();

  const { workspaceID, moduleWorkspaceID } = mainStore.context;

  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const { hasModuleWriteAccess } = mainStore.userPermissions;
  const { pathname } = useLocation();
  const lastPartURL = pathname.split("/").pop();

  const moduleWorkspace = mainStore.moduleWorkspaces.list.find(
    (mw) => mw.id === moduleWorkspaceID,
  );
  const subModule = moduleWorkspace?.attached_module_workspaces.find(
    (mw) => mw.themis_module.identifier === lastPartURL?.replace(/-/g, "_"),
  );
  const subModulePath = subModule?.themis_module?.identifier.replace(/_/g, "-");

  const isNotificationPage = !!matchPath(
    pathname,
    "/workspaces/:workspace_id/modules/:module_identifier/notifications",
  );

  if (moduleIdentifier === null) {
    return;
  }

  return (
    <div>
      <div>
        <DropdownMenu onOpenChange={() => moreButton.toggle}>
          <DropdownMenuTrigger
            data-testid="header-popup-more"
            aria-label="More"
          >
            <Tooltip title={formatMessage({ defaultMessage: "More Options" })}>
              <div
                className={classNames(
                  "tw-flex tw-h-8 tw-w-8  tw-items-center tw-justify-center tw-rounded-md ",
                  {
                    "tw-rounded-md tw-bg-neutral-500": moreButton.isOpen,
                    "hover:tw-bg-primary-25": !moreButton.isOpen,
                  },
                )}
              >
                <PiGearSixBold
                  size="16px"
                  fill={moreButton.isOpen ? "#fff" : "#353549"}
                />
              </div>
            </Tooltip>
          </DropdownMenuTrigger>

          <DropdownMenuContent
            aria-label="More Menu Context"
            align="end"
            side="bottom"
            className="tw-w-60"
          >
            {mainStore.userPermissions.canSeeNotifications && (
              <Link
                to={generatePath(
                  "/workspaces/:workspace_id/modules/:moduleIdentifier/:subModule?/notifications",
                  {
                    workspace_id: Number(workspaceID),
                    moduleIdentifier: kebabCase(moduleIdentifier),
                    subModule: subModulePath,
                  },
                )}
                className={classNames({
                  "tw-pointer-events-none": isNotificationPage,
                })}
              >
                <DropdownMenuItem
                  disabled={isNotificationPage}
                  className={MENU_ITEM_CLASSES}
                >
                  {formatMessage({
                    defaultMessage: "Module Notifications",
                  })}
                </DropdownMenuItem>
              </Link>
            )}

            {MANAGE_APPROVALS_ENABLED_MODULES.includes(moduleIdentifier) &&
              isCurrentWorkspaceActive &&
              hasModuleWriteAccess && (
                <Link
                  to={`/workspaces/${workspaceID}/modules/${moduleIdentifier}/manage-approvals-steps`}
                >
                  <DropdownMenuItem className={MENU_ITEM_CLASSES}>
                    {formatMessage({
                      defaultMessage: "Manage Approvals",
                    })}
                  </DropdownMenuItem>
                </Link>
              )}

            {REPORT_ENABLED_MODULES.includes(moduleIdentifier) && (
              <Link to={`/workspace-reports/${moduleIdentifier}`}>
                <DropdownMenuItem className={MENU_ITEM_CLASSES}>
                  {formatMessage({ defaultMessage: "Reports" })}
                </DropdownMenuItem>
              </Link>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  );
}

export default observer(MoreOptionsButton);
