import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";

import type { ShowTrainingQueryResponse } from "@/api/gen";
import { showTraining } from "@/api/gen/axios/trainingsController";

export const getTrainingQueryKey = (trainingId: string): QueryKey => [
  "trainings",
  "detail",
  trainingId,
];

export const useTraining = <
  TError = Error,
  TData = ShowTrainingQueryResponse["data"],
>(
  trainingId: string,
  options?: Partial<
    UseQueryOptions<ShowTrainingQueryResponse["data"], TError, TData>
  >,
) =>
  useQuery({
    queryFn: async () => {
      const response = await showTraining(trainingId);
      return response.data;
    },
    queryKey: getTrainingQueryKey(trainingId),
    ...options,
  });
