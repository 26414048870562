import type { ReactNode } from "react";
import { isValidElement } from "react";

import { styled } from "../../../styles";
import { Stack } from "../../layout/stack/stack";
import { Card } from "../../surfaces/card/card";
import type { TypographyProps } from "../typography/typography";
import { Typography } from "../typography/typography";

export interface AttributeCardProps {
  label: string;
  value: ReactNode;
  slotProps?: {
    label?: TypographyProps;
    value?: TypographyProps;
  };
}

const StyledCard = styled(Card)(() => ({
  width: "fit-content",
  minHeight: 24,
  display: "flex",
}));

export function AttributeCard({
  label,
  value,
  slotProps,
  ...rest
}: AttributeCardProps) {
  return (
    <StyledCard variant="contained" {...rest}>
      <Stack direction="row" alignItems="center">
        <Typography variant="body2" fontWeight="bold" {...slotProps?.label}>
          {label}
        </Typography>

        {isValidElement(value) ? (
          value
        ) : (
          <Typography
            fontWeight="bold"
            variant="body2"
            color="textSecondary"
            {...slotProps?.value}
          >
            {value}
          </Typography>
        )}
      </Stack>
    </StyledCard>
  );
}
