import { CaretDown, Check, Plus } from "@phosphor-icons/react";
import type { PopoverContentProps } from "@radix-ui/react-popover";
import type { VariantProps } from "cva";
import { cva } from "cva";
import { isEmpty } from "lodash";
import type { ReactNode } from "react";
import React, { useMemo, useState } from "react";

import { cn } from "../../../lib/utils";
import { Button } from "../../Button/Button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../Command";
import { Popover, PopoverContent, PopoverTrigger } from "../../Popover/Popover";
import { Stack } from "../../stack/stack";
import { Checkbox } from "../Checkbox";
import { selectSorts } from "./selectSorts";

const defaultStyles =
  "tw-flex tw-w-full tw-bg-neutral-25 tw-px-3 tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-transition-colors";
const fontStyles = "tw-text-sm tw-font-medium tw-font-sans tw-text-neutral-500";
const focusStyles =
  "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300";
const disabledStyles = "disabled:tw-cursor-not-allowed disabled:tw-opacity-50";
const readOnlyStyles =
  "read-only:tw-bg-primaryDim-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";
const lockedStyles =
  "read-only:tw-bg-primary-25 read-only:focus-visible:tw-border-neutral-100 read-only:focus:tw-border-transparent read-only:focus:tw-ring-0";

const selectInputVariants = cva({
  base: [defaultStyles, fontStyles, focusStyles, disabledStyles],
  variants: {
    size: {
      sm: "tw-h-7",
      md: "tw-h-8",
      lg: "tw-h-9",
    },
  },
  defaultVariants: {
    size: "lg",
  },
});

interface SelectItem<T extends string = string> {
  label: string;
  value: T;
  /** @deprecated Use renderOption instead */
  Component?: (option: { value: T; label: string }) => React.ReactNode;
}

export interface CreateNewItemProps {
  onClose: () => void;
}

type BaseSelectProps = VariantProps<typeof selectInputVariants> &
  Omit<React.ComponentPropsWithoutRef<"button">, "onSelect"> & {
    renderSelected?: (
      option: { value?: string; label?: string },
      selected?: SelectItem,
    ) => React.ReactNode;
    items: Array<SelectItem>;
    renderOption?: (option: SelectItem, state: { index: number }) => ReactNode;
    className?: string;
    placeholder?: string;
    searchable?: boolean;
    sort?: "asc" | "desc";
    locked?: boolean;
    readOnly?: boolean;
    defaultOpen?: boolean;
    hideCaret?: boolean;
    alignItemList?: PopoverContentProps["align"];
    title?: string;
    error?: boolean;
    trigger?: ReactNode;
    /**
     * Allow creation of new item in the list. If provided,
     * a create option will be displayed at the bottom of the list.
     */
    createNewItem?: {
      selectItemLabel: string;
      createSelectItemComponent: (
        onClose: () => void,
        onCreate: (key: string) => void,
      ) => ReactNode;
    };
    popoverContentClassName?: string;
    onPopupClosed?: () => void;
  };

type SingleSelectedValue = string | null | undefined;
export type SingleSelectProps = BaseSelectProps & {
  multiple?: false;
  selected: SingleSelectedValue;
  onSelect: (value: string) => void;
};

type MultipleSelectedValue = Array<string>;
export type MultiSelectProps = BaseSelectProps & {
  multiple: true;
  selected: MultipleSelectedValue;
  onSelect: (value: Array<string>) => void;
};

type SelectProps<T extends boolean = boolean> = T extends true
  ? MultiSelectProps
  : SingleSelectProps;

const Select = React.forwardRef(
  <T extends boolean>(
    {
      size,
      placeholder = "- Select -",
      searchable,
      selected,
      renderSelected,
      onSelect,
      items,
      className,
      locked,
      readOnly,
      defaultOpen = false,
      hideCaret = false,
      alignItemList = "start",
      popoverContentClassName,
      sort,
      trigger,
      onPopupClosed = () => {},
      multiple,
      title,
      createNewItem,
      error,
      renderOption,
      ...rest
    }: SelectProps<T>,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const [open, setOpen] = useState(defaultOpen);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

    const shownItems = useMemo(() => {
      if (sort && multiple) {
        return selectSorts.selectionSort(sort, items, selected);
      }

      if (sort) {
        return selectSorts.defaultSort(sort, items);
      }

      return items;
    }, [items, multiple, selected, sort]);

    // Internal state to manage selected items in case of multiple select
    // This is required to manage the selected items before confirming the selection
    const [internalSelected, setInternalSelected] =
      useState<MultipleSelectedValue>(multiple ? selected : []);

    const disabled = locked || readOnly;

    const handleSelect = (newValue: string) => {
      if (multiple) {
        const newItems = internalSelected.includes(newValue)
          ? internalSelected.filter((item: string) => item !== newValue)
          : [...internalSelected, newValue];

        setInternalSelected(newItems);
      } else {
        setOpen(false);

        // Since we hide the selected item from the list, this ensures list doesn't glitch before closing popup
        setTimeout(() => onSelect(newValue), 100);
      }
    };

    const handleCreate = (value: string) => {
      handleSelect(value);
      if (multiple) {
        // also add the option to the currently "commited" selection
        const newSelection = [...selected, value];
        onSelect(newSelection);
      }
    };

    const handleCloseCreateDialog = () => {
      setIsCreateDialogOpen(false);
    };

    const handleConfirm = () => {
      if (!multiple) {
        return;
      }

      onSelect(internalSelected);
      setOpen(false);
    };

    const handleCancel = () => {
      if (!multiple) {
        return;
      }

      setOpen(false);
      setInternalSelected(selected);
    };

    const renderComponent = (selectedItem?: SelectItem) => {
      if (!selectedItem) {
        return null;
      }

      if (renderSelected) {
        return renderSelected(selectedItem || {});
      }

      if (renderOption) {
        return renderOption(selectedItem, { index: -1 });
      }

      const CustomComponent = selectedItem?.Component;
      if (CustomComponent) {
        return (
          <div key={selectedItem?.value} className="tw-mr-1">
            <CustomComponent {...selectedItem} />
          </div>
        );
      }

      return selectedItem.label;
    };

    const renderSingleSelection = () => {
      if (selected) {
        const selectedItem = items.find(
          (item: SelectItem) => item.value === selected,
        );
        return renderComponent(selectedItem);
      }

      return (
        <p
          className={cn("tw-text-neutral-200", {
            "tw-text-warning-300": error,
          })}
        >
          {placeholder}
        </p>
      );
    };

    const renderMultipleSelection = () => {
      if (!multiple || isEmpty(selected)) {
        return (
          <p
            className={cn("tw-text-neutral-200", {
              "tw-text-warning-300": error,
            })}
          >
            {placeholder}
          </p>
        );
      }

      const selectedItemsToRender = shownItems
        .filter(({ value }) => selected.includes(value))
        .slice(0, 3)
        .map((selectedItem: SelectItem) => renderComponent(selectedItem));

      return [
        ...selectedItemsToRender,
        selected.length > 3 && (
          <div
            key="more"
            className="tw-flex tw-min-h-6 tw-min-w-6 tw-items-center tw-justify-center tw-rounded-full tw-bg-neutral-50 tw-text-xxs tw-font-semibold tw-text-neutral-500"
          >
            +{selected.length - 3}
          </div>
        ),
      ];
    };

    const handleRenderOption: BaseSelectProps["renderOption"] = (
      option,
      state,
    ) => {
      if (renderOption) {
        return renderOption(option, state);
      }

      if (option.Component) {
        const { Component, ...props } = option;
        return <Component {...props} />;
      }

      return option.label;
    };

    return (
      <>
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger disabled={disabled} ref={ref} asChild>
            {trigger ?? (
              <button
                role="combobox"
                aria-expanded={open}
                className={cn(
                  selectInputVariants({ size }),
                  {
                    "tw-border-neutral-100": !open,
                    "tw-border-primary-300": open,
                    "tw-text-neutral-200": !selected,
                    [lockedStyles]: locked,
                    [readOnlyStyles]: readOnly,
                    "tw-cursor-default": disabled,
                  },
                  className,
                )}
                {...rest}
              >
                <Stack direction="row" spacing="0.5">
                  {multiple
                    ? renderMultipleSelection()
                    : renderSingleSelection()}
                </Stack>
                {!disabled && !hideCaret && (
                  <CaretDown
                    className={cn(
                      "tw-ml-auto tw-h-4 tw-w-4 tw-shrink-0 tw-text-neutral-500 tw-transition-transform",
                      { "tw-rotate-180": open },
                    )}
                  />
                )}
              </button>
            )}
          </PopoverTrigger>
          <PopoverContent
            align={alignItemList}
            className={cn("tw-min-w-60", popoverContentClassName, {
              "tw-hidden": isCreateDialogOpen,
            })}
            onCloseAutoFocus={onPopupClosed}
            onPointerDownOutside={(event) => {
              if (multiple) {
                event.preventDefault();
              }
            }}
            onFocusOutside={(event) => {
              if (multiple) {
                event.preventDefault();
              }
            }}
            onEscapeKeyDown={handleCancel}
          >
            {title && (
              <h6 className="tw-m-0 tw-px-4 tw-pt-2 tw-text-base tw-font-semibold tw-text-neutral-500">
                {title}
              </h6>
            )}
            {/* tabIndex={0} className="tw-outline-none" Assure command is focused for keyboard but outline invisible */}
            <Command tabIndex={0} className="tw-outline-none">
              {searchable && <CommandInput autoFocus placeholder="Search..." />}
              <CommandList
                className={cn(
                  {
                    "tw-border-t tw-border-solid tw-border-neutral-100":
                      searchable,
                  },
                  popoverContentClassName,
                )}
              >
                <div className="tw-max-h-72 tw-overflow-y-auto">
                  {searchable && <CommandEmpty>No results.</CommandEmpty>}
                  <CommandGroup>
                    {shownItems.map((option, index) => {
                      const { value, label } = option;

                      const isSelected = multiple
                        ? internalSelected.includes(value)
                        : selected === value;

                      return (
                        <CommandItem
                          key={value}
                          value={String(value)}
                          keywords={[label]}
                          onSelect={handleSelect}
                        >
                          {multiple && (
                            <Checkbox
                              tabIndex={-1}
                              size="md"
                              color="primary"
                              className="tw-mr-2"
                              checked={isSelected}
                            />
                          )}
                          {handleRenderOption(option, { index })}
                          {!multiple && (
                            <Check
                              className={cn("tw-ml-auto tw-h-4 tw-w-4", {
                                "tw-opacity-0": !isSelected,
                                "tw-text-secondary-300": isSelected,
                              })}
                            />
                          )}
                        </CommandItem>
                      );
                    })}
                  </CommandGroup>
                </div>
                {createNewItem && (
                  <CommandItem
                    tabIndex={0}
                    className="tw-cursor-pointer tw-border-x-0 tw-border-y tw-border-solid tw-border-primary-50 tw-py-2"
                    forceMount
                    onClick={(event) => {
                      event.preventDefault();
                    }}
                    onSelect={() => {
                      setIsCreateDialogOpen(true);
                    }}
                  >
                    <Plus className="tw-mr-2 tw-h-4 tw-w-4" />
                    {createNewItem.selectItemLabel}
                  </CommandItem>
                )}
              </CommandList>
            </Command>
            {multiple && (
              <section className="tw-align-center tw-flex tw-justify-end tw-gap-2 tw-px-4 tw-py-2">
                <Button onClick={handleConfirm}>Confirm</Button>
                <Button onClick={handleCancel} color="tertiary">
                  Cancel
                </Button>
              </section>
            )}
          </PopoverContent>
        </Popover>
        {isCreateDialogOpen &&
          createNewItem &&
          createNewItem.createSelectItemComponent(
            handleCloseCreateDialog,
            handleCreate,
          )}
      </>
    );
  },
);

Select.displayName = "Select";

export { Select, type SelectItem, type SelectProps };
