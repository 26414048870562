// UI Stores
// Data Stores
import { action, makeObservable, observable } from "mobx";

import AnnouncementsStore from "./data/Announcements";
import AttachedDocumentsStore from "./data/AttachedDocuments";
import AttachmentGroupsStore from "./data/AttachmentGroups";
import AttachmentsStore from "./data/Attachments";
import AttestationListsStore from "./data/AttestationLists";
import AttestationsStore from "./data/Attestations";
import AuditTrailsStore from "./data/AuditTrailsStore";
import AvroSchemasStore from "./data/AvroSchemas";
import ChangeManagementsStore from "./data/ChangeManagements";
import ChecklistQuestionsStore from "./data/ChecklistQuestions";
import ChecklistsStore from "./data/Checklists";
import CommentsStore from "./data/Comments";
import CompaniesStore from "./data/Companies";
import ComplaintsStore from "./data/Complaints";
import ConflictsStore from "./data/Conflicts";
import ContactsStore from "./data/Contacts";
import ControlCategoriesStore from "./data/ControlCategories";
import ControlMappingsStore from "./data/ControlMappings";
import ControlMappingTypesStore from "./data/ControlMappingTypes";
import CreativesStore from "./data/Creatives";
import CustomerSupportStore from "./data/CustomerSupport";
import DefaultApproversStore from "./data/DefaultApprovers";
import DepartmentsStore from "./data/Departments";
import DocumentsStore from "./data/Documents";
import FeatureFlagsStore from "./data/FeatureFlags";
import FieldOptionsStore from "./data/FieldOptions";
import FieldsStore from "./data/Fields";
import FilesStore from "./data/Files";
import FiltersStore from "./data/Filters";
import FinraComplaintsStore from "./data/FinraComplaints";
import FinraContactsStore from "./data/FinraContacts";
import HomeStore from "./data/Home";
import IssueManagementStore from "./data/IssueManagement";
import KeyRiskIndicatorsCompanyLibraryRecordsStore from "./data/KeyRiskIndicatorsCompanyLibraryRecordsStore";
import KeyRiskIndicatorsCompanyRiskCategoriesStore from "./data/KeyRiskIndicatorsCompanyRiskCategoriesStore";
import KeyRiskIndicatorsStore from "./data/KeyRiskIndicatorsStore";
import KeyRiskIndicatorsThemisLibraryRecordsStore from "./data/KeyRiskIndicatorsThemisLibraryRecordsStore";
import LinkedDocumentsStore from "./data/LinkedDocuments";
import MainCategoriesStore from "./data/MainCategories";
import ManageApprovalsStepsStore from "./data/ManageApprovalsSteps";
import ModuleAccessTokensStore from "./data/ModuleAccessTokensStore";
import ModuleConfigurationsStore from "./data/ModuleConfigurations";
import ModuleWorkspacesStore from "./data/ModuleWorkspaces";
import NotificationsStore from "./data/Notifications";
import NotificationsRulesStore from "./data/NotificationsRules";
import PermissionsStore from "./data/Permissions";
import PoliciesStore from "./data/Policies";
import ProceduresStore from "./data/Procedures";
import ProductsStore from "./data/Products";
import QA from "./data/QA";
import QuestionsStore from "./data/Questions";
import RecordsStore from "./data/Records";
import RecordVersionsStore from "./data/RecordVersions";
import ReportStore from "./data/ReportsStore";
import ReviewsStore from "./data/Reviews";
import RiskAssessmentPartnersStore from "./data/RiskAssessmentPartners";
import RiskAssessmentQuestionnairesStore from "./data/RiskAssessmentQuestionnaires";
import RiskMethodologiesStore from "./data/RiskMethodologies";
import RiskRegistersStore from "./data/RiskRegistersStore";
import RiskTypesStore from "./data/RiskTypes";
import SectionTagsStore from "./data/SectionTags";
import TablesStore from "./data/Tables";
import TagsStore from "./data/TagsStore";
import TaskDetailStore from "./data/TaskDetailStore";
import TasksStore from "./data/Tasks";
import ThemisAuditsStore from "./data/ThemisAudits";
import TrainingsStore from "./data/Trainings";
import UserPermissionsStore from "./data/UserPermissions";
import UsersStore from "./data/Users";
import VendorsStore from "./data/Vendors";
import WebSessionsStore from "./data/WebSessions";
import WorkspacesStore from "./data/Workspaces";
import WorkspaceSummaryStore from "./data/WorkspaceSummary";
import ZendeskStore from "./data/Zendesk";
// Other
import Auth0 from "./other/Auth0";
import Clearbit from "./other/Clearbit";
import type { ModuleIdentifier } from "./types/module-workspaces-types";
import ContextStore from "./ui/Context";
import DynamicTableStore from "./ui/DynamicTable";
import PageLoading from "./ui/PageLoading";
import Toast from "./ui/Toast";

export class MainStore {
  // UI Stores
  context: ContextStore;
  dynamicTable: DynamicTableStore;
  toast: Toast;
  pageLoading: PageLoading;
  areStoresInstantiated = false;
  isPublicForm = false;

  // Data Stores
  announcements: AnnouncementsStore;
  attachedDocuments: AttachedDocumentsStore;
  attachmentGroups: AttachmentGroupsStore;
  attachments: AttachmentsStore;
  attestationLists: AttestationListsStore;
  attestations: AttestationsStore;
  auditTrails: AuditTrailsStore;
  avroSchemas: AvroSchemasStore;
  changeManagements: ChangeManagementsStore;
  checklistQuestions: ChecklistQuestionsStore;
  checklists: ChecklistsStore;
  comments: CommentsStore;
  companies: CompaniesStore;
  complaints: ComplaintsStore;
  conflicts: ConflictsStore;
  contacts: ContactsStore;
  controlCategories: ControlCategoriesStore;
  controlMappings: ControlMappingsStore;
  controlMappingTypes: ControlMappingTypesStore;
  creatives: CreativesStore;
  customerSupport: CustomerSupportStore;
  defaultApprovers: DefaultApproversStore;
  departments: DepartmentsStore;
  documents: DocumentsStore;
  featureFlags: FeatureFlagsStore;
  fieldOptions: FieldOptionsStore;
  fields: FieldsStore;
  files: FilesStore;
  filters: FiltersStore;
  finraComplaints: FinraComplaintsStore;
  finraContacts: FinraContactsStore;
  home: HomeStore;
  issueManagement: IssueManagementStore;
  keyRiskIndicators: KeyRiskIndicatorsStore;
  keyRiskIndicatorsCompanyRiskCategories: KeyRiskIndicatorsCompanyRiskCategoriesStore;
  keyRiskIndicatorsCompanyLibrary: KeyRiskIndicatorsCompanyLibraryRecordsStore;
  keyRiskIndicatorsThemisLibrary: KeyRiskIndicatorsThemisLibraryRecordsStore;
  linkedDocuments: LinkedDocumentsStore;
  mainCategories: MainCategoriesStore;
  manageApprovalsSteps: ManageApprovalsStepsStore;
  moduleAccessTokens: ModuleAccessTokensStore;
  moduleConfigurations: ModuleConfigurationsStore;
  moduleWorkspaces: ModuleWorkspacesStore;
  notifications: NotificationsStore;
  notificationsRules: NotificationsRulesStore;
  permissions: PermissionsStore;
  policies: PoliciesStore;
  procedures: ProceduresStore;
  products: ProductsStore;
  qa: QA;
  questions: QuestionsStore;
  records: RecordsStore;
  recordVersions: RecordVersionsStore;
  reports: ReportStore;
  reviews: ReviewsStore;
  riskAssessmentPartners: RiskAssessmentPartnersStore;
  riskAssessmentQuestionnaires: RiskAssessmentQuestionnairesStore;
  riskMethodologies: RiskMethodologiesStore;
  riskRegisters: RiskRegistersStore;
  riskTypes: RiskTypesStore;
  sectionTags: SectionTagsStore;
  tables: TablesStore;
  tags: TagsStore;
  tasks: TasksStore;
  taskDetail: TaskDetailStore;
  themisAudits: ThemisAuditsStore;
  trainings: TrainingsStore;
  userPermissions: UserPermissionsStore;
  users: UsersStore;
  vendors: VendorsStore;
  webSessions: WebSessionsStore;
  workspaces: WorkspacesStore;
  workspaceSummary: WorkspaceSummaryStore;
  zendesk: ZendeskStore;

  // Other
  auth0: Auth0;
  clearbit: Clearbit;

  constructor() {
    makeObservable(this, {
      areStoresInstantiated: observable,
      setStoresAsInstantiated: action,
      isPublicForm: observable,
      setIsPublicForm: action,
    });

    // UI Stores
    this.context = new ContextStore(this);
    this.dynamicTable = new DynamicTableStore(this);
    this.pageLoading = new PageLoading(this);
    this.toast = new Toast(this);

    // Data Stores
    this.announcements = new AnnouncementsStore(this);
    this.attachedDocuments = new AttachedDocumentsStore(this);
    this.attachmentGroups = new AttachmentGroupsStore(this);
    this.attachments = new AttachmentsStore(this);
    this.attestationLists = new AttestationListsStore(this);
    this.attestations = new AttestationsStore(this);
    this.auditTrails = new AuditTrailsStore(this);
    this.avroSchemas = new AvroSchemasStore(this);
    this.changeManagements = new ChangeManagementsStore(this);
    this.checklistQuestions = new ChecklistQuestionsStore(this);
    this.checklists = new ChecklistsStore(this);
    this.comments = new CommentsStore(this);
    this.companies = new CompaniesStore(this);
    this.complaints = new ComplaintsStore(this);
    this.conflicts = new ConflictsStore(this);
    this.contacts = new ContactsStore(this);
    this.controlCategories = new ControlCategoriesStore(this);
    this.controlMappings = new ControlMappingsStore(this);
    this.controlMappingTypes = new ControlMappingTypesStore(this);
    this.creatives = new CreativesStore(this);
    this.customerSupport = new CustomerSupportStore(this);
    this.defaultApprovers = new DefaultApproversStore(this);
    this.departments = new DepartmentsStore(this);
    this.documents = new DocumentsStore(this);
    this.featureFlags = new FeatureFlagsStore(this);
    this.fieldOptions = new FieldOptionsStore(this);
    this.fields = new FieldsStore(this);
    this.files = new FilesStore(this);
    this.filters = new FiltersStore(this);
    this.finraComplaints = new FinraComplaintsStore(this);
    this.finraContacts = new FinraContactsStore(this);
    this.home = new HomeStore(this);
    this.issueManagement = new IssueManagementStore(this);
    this.keyRiskIndicators = new KeyRiskIndicatorsStore(this);
    this.keyRiskIndicatorsCompanyRiskCategories =
      new KeyRiskIndicatorsCompanyRiskCategoriesStore(this);
    this.keyRiskIndicatorsCompanyLibrary =
      new KeyRiskIndicatorsCompanyLibraryRecordsStore(this);
    this.keyRiskIndicatorsThemisLibrary =
      new KeyRiskIndicatorsThemisLibraryRecordsStore(this);
    this.linkedDocuments = new LinkedDocumentsStore(this);
    this.mainCategories = new MainCategoriesStore(this);
    this.manageApprovalsSteps = new ManageApprovalsStepsStore(this);
    this.moduleAccessTokens = new ModuleAccessTokensStore(this);
    this.moduleConfigurations = new ModuleConfigurationsStore(this);
    this.moduleWorkspaces = new ModuleWorkspacesStore(this);
    this.notificationsRules = new NotificationsRulesStore(this);
    this.notifications = new NotificationsStore(this);
    this.permissions = new PermissionsStore(this);
    this.policies = new PoliciesStore(this);
    this.procedures = new ProceduresStore(this);
    this.products = new ProductsStore(this);
    this.qa = new QA(this);
    this.questions = new QuestionsStore(this);
    this.records = new RecordsStore(this);
    this.recordVersions = new RecordVersionsStore(this);
    this.reports = new ReportStore(this);
    this.reviews = new ReviewsStore(this);
    this.riskAssessmentPartners = new RiskAssessmentPartnersStore(this);
    this.riskAssessmentQuestionnaires = new RiskAssessmentQuestionnairesStore(
      this,
    );
    this.riskMethodologies = new RiskMethodologiesStore(this);
    this.riskRegisters = new RiskRegistersStore(this);
    this.riskTypes = new RiskTypesStore(this);
    this.sectionTags = new SectionTagsStore(this);
    this.tables = new TablesStore(this);
    this.tags = new TagsStore(this);
    this.tasks = new TasksStore(this);
    this.taskDetail = new TaskDetailStore(this);
    this.themisAudits = new ThemisAuditsStore(this);
    this.trainings = new TrainingsStore(this);
    this.userPermissions = new UserPermissionsStore(this);
    this.users = new UsersStore(this);
    this.vendors = new VendorsStore(this);
    this.webSessions = new WebSessionsStore(this);
    this.workspaces = new WorkspacesStore(this);
    this.workspaceSummary = new WorkspaceSummaryStore(this);
    this.zendesk = new ZendeskStore(this);

    // Third-party
    this.auth0 = new Auth0(this);
    this.clearbit = new Clearbit(this);

    this.setStoresAsInstantiated();
  }

  getStore(moduleIdentifier: ModuleIdentifier) {
    if (moduleIdentifier === "policy") {
      return this.policies;
    } else if (moduleIdentifier === "procedures") {
      return this.procedures;
    } else if (moduleIdentifier === "issue_management") {
      return this.issueManagement;
    } else if (moduleIdentifier === "marketing") {
      return this.creatives;
    } else if (moduleIdentifier === "new_product_approval") {
      return this.changeManagements;
    } else if (moduleIdentifier === "training") {
      return this.trainings;
    } else if (moduleIdentifier === "complaints") {
      return this.complaints;
    } else if (moduleIdentifier === "documents") {
      return this.documents;
    } else if (moduleIdentifier === "audits") {
      return this.themisAudits;
    } else if (moduleIdentifier === "qa_tests_development") {
      return this.qa;
    } else if (moduleIdentifier === "conflicts_of_interest") {
      return this.conflicts;
    } else if (moduleIdentifier === "vendor_due_diligence") {
      return this.vendors;
    } else if (moduleIdentifier === "risk_register") {
      return this.riskRegisters;
    } else if (moduleIdentifier === "control_mapping") {
      return this.controlMappings;
    }
    return null;
  }

  setStoresAsInstantiated() {
    this.areStoresInstantiated = true;
  }

  setIsPublicForm(newValue: boolean) {
    this.isPublicForm = newValue;
  }

  /** To be deprecated in favor of API layer */
  getHeaders() {
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    const csrfToken = document.querySelector<HTMLMetaElement>(
      'meta[name="csrf-token"]',
    ).content;

    const headers = {
      "Content-Type": "application/json",
      "X-CSRF-Token": csrfToken,
    };

    const accessToken = this.webSessions.getAccessToken();
    if (accessToken && accessToken.length > 0) {
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      headers["X-Session-Token"] = accessToken;
    }

    return headers;
  }

  cleanup() {
    // Core Stores cleanup
    this.users.cleanup();
    this.companies.cleanup();
    this.workspaces.cleanup();
    this.moduleWorkspaces.cleanup();
    this.tables.cleanup();
    this.context.cleanup();

    // UI Stores
    this.dynamicTable.cleanup();
    this.pageLoading.cleanup();
    this.toast.cleanup();

    // Data Stores
    this.attachedDocuments.cleanup();
    this.attachmentGroups.cleanup();
    this.attachments.cleanup();
    this.attestationLists.cleanup();
    this.attestations.cleanup();
    this.changeManagements.cleanup();
    this.checklists.cleanup();
    this.comments.cleanup();
    this.complaints.cleanup();
    this.conflicts.cleanup();
    this.contacts.cleanup();
    this.controlCategories.cleanup();
    this.controlMappings.cleanup();
    this.controlMappingTypes.cleanup();
    this.creatives.cleanup();
    this.customerSupport.cleanup();
    this.defaultApprovers.cleanup();
    this.departments.cleanup();
    this.documents.cleanup();
    this.fields.cleanup();
    this.fieldOptions.cleanup();
    this.files.cleanup();
    this.filters.cleanup();
    this.finraComplaints.cleanup();
    this.finraContacts.cleanup();
    this.home.cleanup();
    this.issueManagement.cleanup();
    this.linkedDocuments.cleanup();
    this.mainCategories.cleanup();
    this.manageApprovalsSteps.cleanup();
    this.moduleConfigurations.cleanup();
    this.notifications.cleanup();
    this.notificationsRules.cleanup();
    this.permissions.cleanup();
    this.policies.cleanup();
    this.procedures.cleanup();
    this.products.cleanup();
    this.qa.cleanup();
    this.questions.cleanup();
    this.records.cleanup();
    this.recordVersions.cleanup();
    this.reports.cleanup();
    this.reviews.cleanup();
    this.riskAssessmentPartners.cleanup();
    this.riskAssessmentQuestionnaires.cleanup();
    this.riskMethodologies.cleanup();
    this.riskRegisters.cleanup();
    this.riskTypes.cleanup();
    this.sectionTags.cleanup();
    this.tags.cleanup();
    this.tasks.cleanup();
    this.themisAudits.cleanup();
    this.trainings.cleanup();
    this.userPermissions.cleanup();
    this.vendors.cleanup();
    this.webSessions.cleanup();

    // Third-party
    this.auth0.cleanup();
    this.clearbit.cleanup();
  }

  abort() {
    this.changeManagements.abort();
    this.complaints.abort();
    this.conflicts.abort();
    this.controlMappings.abort();
    this.creatives.abort();
    this.customerSupport.abort();
    this.documents.abort();
    this.finraComplaints.abort();
    this.policies.abort();
    this.procedures.abort();
    this.qa.abort();
    this.riskRegisters.abort();
    this.themisAudits.abort();
    this.trainings.abort();
    this.vendors.abort();
  }
}

const mainStore = new MainStore();

export default mainStore;
