import { faker } from "@faker-js/faker";

import type {
  UpdateField200,
  UpdateField403,
  UpdateFieldMutationRequest,
  UpdateFieldMutationResponse,
  UpdateFieldPathParams,
} from "../models/UpdateField";
import { createFieldConfiguration } from "./createFieldConfiguration";
import { createRecordTypePath } from "./createRecordTypePath";

export function createUpdateFieldPathParams(): NonNullable<UpdateFieldPathParams> {
  return {
    company_id: faker.number.int(),
    record_type: createRecordTypePath(),
    id: faker.number.int(),
  };
}

/**
 * @description Field updated successfully
 */
export function createUpdateField200(): NonNullable<UpdateField200> {
  return { data: createFieldConfiguration() };
}

/**
 * @description Forbidden
 */
export function createUpdateField403(): NonNullable<UpdateField403> {
  return { errors: faker.helpers.arrayElements([{}]) as any };
}

export function createUpdateFieldMutationRequest(): NonNullable<UpdateFieldMutationRequest> {
  return {
    display_name: faker.string.alpha(),
    required: faker.datatype.boolean(),
    internal_only: faker.datatype.boolean(),
  };
}

/**
 * @description Field updated successfully
 */
export function createUpdateFieldMutationResponse(): NonNullable<UpdateFieldMutationResponse> {
  return { data: createFieldConfiguration() };
}
