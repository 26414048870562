import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { useLoading } from "../../../hooks/useLoading";
import Loading from "../../Loading";
import Checkbox from "../shared/Checkbox";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";

function OperationalControlsAddNew() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [fields, setFields] = useState([]);
  const [rows, setRows] = useState([]);
  const { company } = mainStore.companies;

  // Variables
  const { activeWorkspace } = mainStore.context;
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id: recordVersionID } = useParams();
  const { selectedIDs: selectedRecordVersionsIDs } =
    mainStore.riskRegisters.operationalControlsData;
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === Number(recordVersionID),
  );

  // Hooks
  const loading = useLoading(fields);
  // @ts-expect-error TS(2339) FIXME: Property 'is_hidden' does not exist on type 'never... Remove this comment to see the full error message
  const onlyVisibleFields = fields.filter((field) => !field.is_hidden);

  // effects
  useEffect(() => {
    if (!activeWorkspace || !company?.id) {
      return;
    }

    const fetchData = async () => {
      if (!recordVersion) {
        await mainStore.riskRegisters.index({
          workspaceID: activeWorkspace.id,
        });
      }
      const data =
        await mainStore.riskRegisters.fetchOperationalControlsData(
          recordVersionID,
        );

      mainStore.departments.index(company.id);
      mainStore.users.indexForModules({ workspaceID: activeWorkspace.id });

      setFields(data.cm_fields);
      setRows(
        data.cm_record_versions.filter(
          // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
          (item) => !data.selected_record_versions_ids.includes(item.id),
        ),
      );

      mainStore.riskRegisters.setOperationalControlsData(
        data.selected_record_versions_ids,
        false,
      );
      mainStore.fieldOptions.setAll(data.cm_field_options);
    };

    fetchData();
  }, [activeWorkspace, company]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleMainCheckboxChange(event) {
    const { checked } = event.target;

    const newSelectedRecordVersionsIDs = checked
      ? [
          ...new Set([
            ...selectedRecordVersionsIDs,
            // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
            ...rows.map((item) => item.id),
          ]),
        ]
      : selectedRecordVersionsIDs.filter(
          // @ts-expect-error TS(2339) FIXME: Property 'id' does not exist on type 'never'.
          (item) => !rows.map((row) => row.id).includes(item),
        );
    handleSave(newSelectedRecordVersionsIDs);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleRegularCheckboxChange(event, id) {
    const { checked } = event.target;

    const newSelectedRecordVersionsIDs = checked
      ? [...selectedRecordVersionsIDs, id]
      : selectedRecordVersionsIDs.filter((item) => item !== id);
    handleSave(newSelectedRecordVersionsIDs);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  function handleSave(ids) {
    mainStore.riskRegisters.setOperationalControlsData(ids);
  }

  // Rendering
  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const renderField = (field) => (
    <ModuleTableColumn key={field.name} field={field} />
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'rv' implicitly has an 'any' type.
  const renderRow = (rv) => (
    <div
      className="rr-operational-controls-row-container tw-mr-[90px]"
      key={rv.id}
    >
      <Checkbox
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        checked={selectedRecordVersionsIDs.includes(rv.id)}
        onChange={(event) => handleRegularCheckboxChange(event, rv.id)}
        data-testid="rr-operational-controls-regular-checkbox"
      />
      <ModuleRecordVersion
        fields={onlyVisibleFields}
        isLockedRow
        recordVersion={rv}
        tableID={rv.table_id}
        tableName={rv.table_name}
        currentTableName={rv.table_name}
        moduleIdentifier="control_mapping"
      />
    </div>
  );

  return (
    <div className="settings-wrap company-users-settings-container detail-view-documents-wrap">
      {loading ? (
        <Loading loadingLayout="table" showTableHeader={false} />
      ) : (
        <div className="rr-operational-controls-wrapper rr-library-wrapper">
          <div
            className="rr-library-container"
            data-testid="rr-operational-controls-container"
          >
            <div
              className="rr-library-table-container"
              data-testid="rr-operational-controls-table"
            >
              <div className="rr-library-table-columns list-title-table">
                <Checkbox
                  checked={rows.every((item) =>
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                    selectedRecordVersionsIDs.includes(item.id),
                  )}
                  onChange={handleMainCheckboxChange}
                  data-testid="rr-operational-controls-main-checkbox"
                />
                {fields.map(renderField)}
              </div>
              <div className="rr-library-table-rows">{rows.map(renderRow)}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(OperationalControlsAddNew);
