import { action, makeObservable, observable } from "mobx";

import legacyApi from "@/api/legacy/legacy-api";
import { API_URL } from "@/components/constants";
import type { KRICompanyLibraryRecord } from "@/features/key-risk-indicators/pages/types";
import type { MainStore } from "@/stores/Main";
import type { LibraryRecordParams } from "@/stores/types/key-risk-indicators-types";

export default class KeyRiskIndicatorsCompanyLibraryRecordsStore {
  mainStore: MainStore;
  libraryRecords: KRICompanyLibraryRecord[] = [];
  checkedRowIDs: number[] = [];
  selectedSectionTagID: number | "unassigned" | null = null;
  editedRiskCategory = "";
  editedName = "";
  constructor(mainStore: MainStore) {
    makeObservable(this, {
      libraryRecords: observable,
      setLibraryRecords: action,
      editedRiskCategory: observable,
      setEditedRiskCategory: action,
      editedName: observable,
      setEditedName: action,
      checkedRowIDs: observable,
      setCheckedRowIDs: action,
      selectedSectionTagID: observable,
      setSelectedSectionTagID: action,
      clearForm: action,
      importLibrary: action,
    });
    this.setCheckedRowIDs = this.setCheckedRowIDs.bind(this);

    this.mainStore = mainStore;
  }

  async index({ companyID }: { companyID: number }) {
    try {
      const response = await legacyApi({
        method: "GET",
        url: `${API_URL}/companies/${companyID}/key_risk_indicators_company_library_records`,
        headers: this.mainStore.getHeaders(),
      });

      this.setLibraryRecords(response.data);
    } catch (error) {
      window.console.error(`"key_risk_indicators#index" error: ${error}`);
    }
  }

  async create({ companyID, name, riskCategory }: LibraryRecordParams) {
    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/companies/${companyID}/key_risk_indicators_company_library_records`,
        headers: this.mainStore.getHeaders(),
        data: {
          name,
          risk_category: riskCategory,
        },
      });

      // @ts-expect-error axios
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(response);
        return;
      }
      this.setLibraryRecords([...this.libraryRecords, response.data]);
      return response;
    } catch (error) {
      window.console.error(`"key_risk_indicators#create" error: ${error}`);
    }
  }

  async downloadLibraryTemplate(companyID: number) {
    try {
      const result = await legacyApi({
        method: "GET",
        url: `${API_URL}/companies/${companyID}/key_risk_indicators_company_library_records/xls_template`,
        headers: this.mainStore.getHeaders(),
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (result.isAxiosError) {
        this.mainStore.toast.setInfoText(
          "An error occurred. Please try again or contact Themis support if the problem persists.",
        );
        return;
      }

      return result.data;
    } catch (error) {
      window.console.log(
        `"KeyRiskIndicators#downloadLibraryTemplate" error ${error}`,
      );
    }
  }

  async update({ companyID, id, name, riskCategory }: LibraryRecordParams) {
    try {
      const response = await legacyApi({
        method: "PUT",
        url: `${API_URL}/key_risk_indicators_company_library_records/${id}`,
        headers: this.mainStore.getHeaders(),
        data: {
          company_id: companyID,
          name,
          risk_category: riskCategory,
        },
      });

      // @ts-expect-error axios
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(response);
        return;
      }
      this.setLibraryRecords(
        this.libraryRecords.map((record) =>
          record.id === id
            ? { ...record, name, risk_category: riskCategory }
            : record,
        ),
      );

      return response;
    } catch (error) {
      window.console.error(`"key_risk_indicators#update" error: ${error}`);
    }
  }

  async importLibrary(companyID: number, file: File) {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await legacyApi({
        method: "POST",
        url: `${API_URL}/companies/${companyID}/key_risk_indicators_company_library_records/import`,
        headers: {
          ...this.mainStore.getHeaders(),
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });

      // @ts-expect-error TS(2339) FIXME: Property 'isAxiosError' does not exist on type 'Ax... Remove this comment to see the full error message
      if (response.isAxiosError) {
        this.mainStore.toast.setErrorFromResponse(response);
        return;
      }
      this.setLibraryRecords([...this.libraryRecords, ...response.data]);
    } catch (error) {
      window.console.log(`"RiskRegisters#importLibrary" error ${error}`);
    }
  }

  async copyToKri({
    moduleWorkspaceID,
    companyID,
  }: {
    moduleWorkspaceID: number;
    companyID: number;
  }) {
    try {
      const params = {
        section_tag_id: this.selectedSectionTagID,
        module_workspace_id: moduleWorkspaceID,
        ids: this.checkedRowIDs,
      };
      await legacyApi({
        method: "PUT",
        params,
        url: `${API_URL}/companies/${companyID}/key_risk_indicators_company_library_records/copy_to_kri`,
        headers: this.mainStore.getHeaders(),
      });
    } catch (error) {
      window.console.log(`"key_risk_indicators#copyToKri" error ${error}`);
    }
  }

  async destroy({ id, companyID }: { id: number; companyID: number }) {
    try {
      await legacyApi({
        method: "DELETE",
        url: `${API_URL}/key_risk_indicators_company_library_records/${id}`,
        headers: this.mainStore.getHeaders(),
        params: { company_id: companyID },
      });

      this.setLibraryRecords(
        this.libraryRecords.filter((record) => record.id !== id),
      );
    } catch (error) {
      window.console.error(`"key_risk_indicators#destroy" error: ${error}`);
    }
  }

  setSelectedSectionTagID(value: number | "unassigned" | null) {
    this.selectedSectionTagID = value;
  }

  setLibraryRecords(value: KRICompanyLibraryRecord[]) {
    this.libraryRecords = value;
  }

  setCheckedRowIDs(value: number[]) {
    this.checkedRowIDs = value;
  }

  setEditedRiskCategory(value: string) {
    this.editedRiskCategory = value;
  }

  setEditedName(value: string) {
    this.editedName = value;
  }

  clearForm() {
    this.editedRiskCategory = "";
    this.editedName = "";
  }
}
