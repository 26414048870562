import type { SortDirection } from "ag-grid-community";
import type { CustomHeaderProps } from "ag-grid-react";
import type { ReactElement } from "react";
import React, { useEffect, useState } from "react";
import {
  PiArrowDown,
  PiArrowsDownUp,
  PiArrowUp,
  PiPushPin,
  PiPushPinFill,
} from "react-icons/pi";

import { cn } from "../../../lib/utils";
import { IconButton } from "../../Button/IconButton";

export interface HeaderProps extends CustomHeaderProps {
  leftIcon?: ReactElement;
}

const Header = ({
  displayName = "",
  column,
  context,
  leftIcon,
  progressSort,
}: HeaderProps) => {
  const gridRef = context.gridRef?.current;
  const isPinned = column.isPinned();
  const enablePinning = !column.getColDef().lockPinned;

  const [sort, setSort] = useState<SortDirection | undefined>(column.getSort());

  const handlePinClick = () => {
    gridRef.api?.applyColumnState({
      state: [{ colId: column.getColId(), pinned: isPinned ? null : "left" }],
    });
  };

  const onSortClicked = () => {
    progressSort();
  };

  useEffect(() => {
    const onSortChanged = () => {
      setSort(column.getSort());
    };
    column.addEventListener("sortChanged", onSortChanged);
    onSortChanged();
    return () => {
      column.removeEventListener("sortChanged", onSortChanged);
    };
  }, [column]);

  if (!displayName) {
    return null;
  }

  return (
    <div className="tw-group/header tw-flex tw-w-full tw-items-center tw-justify-between tw-gap-0.5 tw-px-2">
      {leftIcon}
      <span className="tw-mr-auto tw-truncate">{displayName}</span>
      <div className="tw-flex tw-items-center">
        {enablePinning && (
          <IconButton
            className={cn(
              "tw-transition tw-duration-200 group-hover/header:tw-flex",
              {
                "tw-hidden": !isPinned,
              },
            )}
            Icon={isPinned ? PiPushPinFill : PiPushPin}
            color="transparent"
            onClick={handlePinClick}
            aria-label="Pin column"
          />
        )}

        <IconButton
          onClick={onSortClicked}
          Icon={getSortIcon(sort)}
          color="transparent"
          aria-label="Sort column"
        />
      </div>
    </div>
  );
};

function getSortIcon(sort: SortDirection | undefined) {
  if (sort === "asc") {
    return PiArrowUp;
  }
  if (sort === "desc") {
    return PiArrowDown;
  }
  return PiArrowsDownUp;
}

export default Header;
