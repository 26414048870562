import { Portal } from "@radix-ui/react-tooltip";
import React from "react";
import type { ReactElement, ReactNode } from "react";

import { Tooltip, TooltipContent, TooltipTrigger } from "../Tooltip/Tooltip";

interface TableTooltipProps {
  children: ReactElement;
  content: ReactNode;
  disabled: boolean;
}

export function TableTooltip({
  children,
  content,
  disabled,
}: TableTooltipProps): ReactElement {
  return (
    <Tooltip
      disableHoverableContent={disabled}
      delayDuration={500}
      open={disabled ? false : undefined}
    >
      <TooltipTrigger asChild>{children}</TooltipTrigger>
      <Portal>
        <TooltipContent disableArrow side="bottom">
          <div className="tw-text-sans tw-max-w-[250px] tw-break-words tw-p-1 tw-text-sm tw-text-white">
            {content}
          </div>
        </TooltipContent>
      </Portal>
    </Tooltip>
  );
}
