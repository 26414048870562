import type { ThemeOptions } from "@mui/material";

export const backdrop: ThemeOptions["components"] = {
  MuiBackdrop: {
    styleOverrides: {
      root: () => ({
        zIndex: 2,
      }),
    },
  },
};
