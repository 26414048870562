import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import { useLoading } from "../../../hooks/useLoading";
import Loading from "../../Loading";
import Checkbox from "../shared/Checkbox";
import ModuleRecordVersion from "../shared/ModuleRecordVersion";
import ModuleTableColumn from "../shared/ModuleTableColumn";

function IMRelatedRisksAddNew() {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [fields, setFields] = useState([]);
  const [rows, setRows] = useState([]);
  const { company } = mainStore.companies;

  // Variables
  const { workspaceID } = mainStore.context;
  // @ts-expect-error TS(2339) FIXME: Property 'record_version_id' does not exist on typ... Remove this comment to see the full error message
  const { record_version_id: recordVersionID } = useParams();
  const { selectedIDs: selectedRecordsIDs } =
    mainStore.issueManagement.relatedRisksData;
  const recordVersion = mainStore.recordVersions.list.find(
    (rv) => rv.id === Number(recordVersionID),
  );

  // Hooks
  const loading = useLoading(fields);

  // effects
  useEffect(() => {
    (async () => {
      if (!workspaceID || !company?.id) {
        return;
      }

      if (!recordVersion) {
        await mainStore.issueManagement.index({ workspaceID });
      }
      const data =
        await mainStore.issueManagement.fetchRelatedRisksData(recordVersionID);

      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      const filteredFields = data.rr_fields.filter(
        (item) => item.position > 0 && !item.is_hidden,
      );
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Field[]' is not assignable to pa... Remove this comment to see the full error message
      setFields(filteredFields);
      setRows(
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        data.rr_records.filter(
          // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
          (item) => !data.selected_records_ids.includes(item.record.id),
        ),
      );

      mainStore.issueManagement.setRelatedRisksData(
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        data.selected_records_ids,
        false,
      );
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      mainStore.fieldOptions.setAll(data.rr_field_options);
    })();
  }, [company]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleMainCheckboxChange(event) {
    const { checked } = event.target;

    const newSelectedRecordsIDs = checked
      ? [
          ...new Set([
            ...selectedRecordsIDs,
            // @ts-expect-error TS(2339) FIXME: Property 'record' does not exist on type 'never'.
            ...rows.map((item) => item.record.id),
          ]),
        ]
      : selectedRecordsIDs.filter(
          // @ts-expect-error TS(2339) FIXME: Property 'record' does not exist on type 'never'.
          (item) => !rows.map((row) => row.record.id).includes(item),
        );
    handleSave(newSelectedRecordsIDs);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  function handleRegularCheckboxChange(event, id) {
    const { checked } = event.target;

    const newSelectedRecordsIDs = checked
      ? [...selectedRecordsIDs, id]
      : selectedRecordsIDs.filter((item) => item !== id);
    handleSave(newSelectedRecordsIDs);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ids' implicitly has an 'any' type.
  function handleSave(ids) {
    mainStore.issueManagement.setRelatedRisksData(ids);
  }

  // Rendering
  // @ts-expect-error TS(7006) FIXME: Parameter 'field' implicitly has an 'any' type.
  const renderField = (field) => (
    <ModuleTableColumn key={field.name} field={field} />
  );

  // @ts-expect-error TS(7006) FIXME: Parameter 'rv' implicitly has an 'any' type.
  const renderRow = (rv) => (
    <div
      className="rr-operational-controls-row-container tw-mr-[90px]"
      key={rv.id}
    >
      <Checkbox
        // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
        checked={selectedRecordsIDs.includes(rv.record.id)}
        onChange={(event) => handleRegularCheckboxChange(event, rv.record.id)}
        data-testid="im-related-risks-regular-checkbox"
      />
      <ModuleRecordVersion
        fields={fields}
        isLockedRow
        recordVersion={rv}
        tableID={rv.table_id}
        tableName={rv.table_name}
        currentTableName={rv.table_name}
        moduleIdentifier="risk_register"
      />
    </div>
  );

  return (
    <div className="settings-wrap company-users-settings-container detail-view-documents-wrap">
      {loading ? (
        <Loading loadingLayout="table" showTableHeader={false} />
      ) : (
        <div className="rr-operational-controls-wrapper rr-library-wrapper">
          <div
            className="rr-library-container"
            data-testid="im-related-risks-container"
          >
            <div
              className="rr-library-table-container"
              data-testid="im-related-risks-table"
            >
              <div className="rr-library-table-columns">
                <Checkbox
                  checked={rows.every((item) =>
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                    selectedRecordsIDs.includes(item.record.id),
                  )}
                  onChange={handleMainCheckboxChange}
                  data-testid="im-related-risks-main-checkbox"
                />
                {fields.map(renderField)}
              </div>
              <div className="rr-library-table-rows">{rows.map(renderRow)}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default observer(IMRelatedRisksAddNew);
