/* eslint-disable react/jsx-max-depth */
import * as Sentry from "@sentry/react";
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Toaster, TooltipProvider } from "@themis/ui";
import { UiLibraryProvider } from "@themis/ui-library/ui-library-provider/ui-library-provider";
import { NuqsAdapter } from "nuqs/adapters/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { BrowserRouter } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { IntercomProvider } from "react-use-intercom";

import ErrorBoundaryFallback from "../components/errors/ErrorBoundaryFallback";
import AppPopup from "../components/popup/AppPopup";
import PageLoading from "../components/table/shared/PageLoading";
import Toast from "../components/table/shared/Toast";
import { _1_MIN_MS } from "../constants/duration";
import { LocaleProvider } from "../contexts/locale-provider/locale-provider";
import { StoreContext } from "../contexts/Store";
import ApplicationRouting from "../routes/application-routing";
import mainStore from "../stores/Main";
import AppDataLoader from "./app-data-loader";
import { UserTracker } from "./user-tracker/user-tracker";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: _1_MIN_MS,
      placeholderData: keepPreviousData,
    },
  },
});

export default function App() {
  const environment = document.querySelector<HTMLMetaElement>(
    'meta[name="environment"]',
  );

  const isProduction = environment?.content === "production";

  return (
    <NuqsAdapter>
      <QueryClientProvider client={queryClient}>
        <DndProvider backend={HTML5Backend}>
          <StoreContext.Provider value={mainStore}>
            <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
              <LocaleProvider>
                <BrowserRouter>
                  <UiLibraryProvider>
                    <IntercomProvider
                      apiBase="https://api-iam.intercom.io"
                      appId={isProduction ? "nelqdu98" : "utvwzqip"}
                    >
                      <TooltipProvider>
                        <PageLoading />
                        <AppDataLoader production={isProduction} />
                        <UserTracker />

                        <ApplicationRouting />
                        <AppPopup />

                        <ReactTooltip
                          id="tooltip"
                          role="tooltip"
                          clickable
                          delayShow={300}
                          globalCloseEvents={{ clickOutsideAnchor: true }}
                          // The tables seem to have a z-index of 999 which causes the tooltips to clip under.
                          style={{
                            zIndex: 1500,
                            fontSize: 11,
                            paddingTop: 6,
                            paddingBottom: 6,
                            paddingRight: 12,
                            paddingLeft: 12,
                            maxWidth: 300,
                            wordBreak: "break-word",
                          }}
                        />
                        <Toast />
                        <Toaster />
                        <div id="portal-root" />

                        <ReactQueryDevtools buttonPosition="bottom-left" />
                      </TooltipProvider>
                    </IntercomProvider>
                  </UiLibraryProvider>
                </BrowserRouter>
              </LocaleProvider>
            </Sentry.ErrorBoundary>
          </StoreContext.Provider>
        </DndProvider>
      </QueryClientProvider>
    </NuqsAdapter>
  );
}
