import type { Comment } from "@themis/api/gen/models/comment";
import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import type { ReactElement } from "react";
import { useState } from "react";

import { useCreateComment } from "@/api/queries/comments/use-create-comment";
import type { User } from "@/stores/types/user-types";

import type { CommentsInputProps } from "../comments-input/comments-input";
import { CommentsInput } from "../comments-input/comments-input";
import { useFocusedComment } from "../use-focused-comment/use-focused-comment";

interface CreateCommentProps {
  inputSize?: CommentsInputProps["size"];
  internal?: boolean;
  parentComment?: Comment;
  placeholder: string;
  recordId: number;
  recordTypePath: RecordTypePath;
  skipRecordRefetch?: boolean;
  usersMap: Record<string, User>;
}

export function CreateComment({
  inputSize,
  parentComment,
  placeholder,
  internal = false,
  recordId,
  recordTypePath,
  skipRecordRefetch,
  usersMap,
}: CreateCommentProps): ReactElement {
  const { updateFocused } = useFocusedComment();

  const [inputValue, setInputValue] = useState("");

  const createComment = useCreateComment({
    recordId,
    recordType: recordTypePath,
    skipRecordRefetch,
  });

  const handleSubmit = () => {
    createComment.mutate(
      {
        comment: {
          content: inputValue,
          private: internal,
          parent_id: parentComment?.id,
        },
      },
      {
        onSuccess: (response) => {
          updateFocused(response.data.id);
          setInputValue("");
        },
      },
    );
  };

  return (
    <CommentsInput
      isPending={createComment.isPending}
      placeholder={placeholder}
      size={inputSize}
      usersMap={usersMap}
      value={inputValue}
      onSubmit={handleSubmit}
      onValueChange={setInputValue}
    />
  );
}
