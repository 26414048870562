import type { Filter } from "../types";
import { FilterOperand } from "../types";

export function parseFilter(
  operand: string,
  filterParamString: string,
): Filter | null {
  try {
    const castOperand = operand as FilterOperand;
    const filterValues = filterParamString.split(",");

    if (
      [FilterOperand.ANY, FilterOperand.NOT, FilterOperand.MATCH].includes(
        castOperand,
      )
    ) {
      return {
        operand: castOperand as
          | FilterOperand.ANY
          | FilterOperand.NOT
          | FilterOperand.MATCH,
        value: filterValues,
      };
    }

    if ([FilterOperand.PRESENT, FilterOperand.MISSING].includes(castOperand)) {
      return {
        operand: castOperand as FilterOperand.PRESENT | FilterOperand.MISSING,
      };
    }

    throw new Error(`Invalid filter operand: ${operand}`);
  } catch (error) {
    window.console.info(`Non-parsable fitler: ${filterParamString}`, error);
    return null;
  }
}
