import type { ColDef } from "ag-grid-community";

/**
 * Creates a comparator function for sorting based on a specified property of objects with an `id` field.
 *
 * @param data - The array of objects to be used for comparison.
 * @param propertyKey - The key of the property to compare.
 * @returns A comparator function that can be used in a column definition.
 */
export function createComparatorById<T extends { id: number }>({
  data,
  propertyKey,
}: {
  data: T[];
  propertyKey: keyof T;
}): ColDef["comparator"] {
  const getText = (id: string) =>
    String(data.find((item) => item.id === Number(id))?.[propertyKey] || "");

  return (idA, idB) => getText(idA).localeCompare(getText(idB));
}
