import { TableTooltip } from "@themis/ui";
import type { CustomCellRendererProps } from "ag-grid-react";
import type { ReactElement } from "react";

import { useDetectOverflow } from "../../../../hooks/use-detect-overflow/use-detect-overflow";
import { Typography } from "../../../data-display/typography/typography";
import { DefaultCellWrapper } from "../default-cell-wrapper/default-cell-wrapper";

type TextCellProps = CustomCellRendererProps;

export function TextCell({
  value,
  valueFormatted,
}: TextCellProps): ReactElement {
  const { measureElement, isOverflowing } = useDetectOverflow();

  const text = valueFormatted || value;

  return (
    <DefaultCellWrapper>
      <TableTooltip content={text} disabled={!isOverflowing}>
        <Typography
          ref={measureElement}
          color="textPrimary"
          variant="body1"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          <span>{text}</span>
        </Typography>
      </TableTooltip>
    </DefaultCellWrapper>
  );
}
