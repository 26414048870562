import type { Account } from "@themis/api/gen/models/account";
import { recordTypePath } from "@themis/api/gen/models/recordTypePath";
import {
  ConfirmationPopup,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  IconButton,
  useToast,
} from "@themis/ui";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import React, { useState } from "react";
import {
  PiCaretRightBold,
  PiDotsThreeOutlineVerticalFill,
} from "react-icons/pi";
import { useIntl } from "react-intl";

import {
  useDeleteAccount,
  useUpdateAccount,
} from "@/api/queries/accounts/accounts";
import { PdfExport } from "@/components/pdf-export/pdf-export";
import SlideMenu from "@/components/slideMenu/SlideMenu";
import { useMainStore } from "@/contexts/Store";
import SendThemisRecord from "@/features/misc/SendThemisRecord";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";
import { useIsFeatureEnabled } from "@/hooks/useIsFeatureEnabled";
import { FEATURE_FLAG_ID } from "@/stores/types/feature-flag-types";

import { useOpenTaskDetail } from "../../../../hooks/use-open-task-detail/use-open-task-detail";

const DROPDOWN_ITEM_STYLE =
  "tw-flex tw-w-[228px] tw-items-center tw-justify-start tw-gap-1 tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-primary-50 tw-px-4 tw-py-[10px] tw-justify-between";

export function AccountActionsCell({ account }: { account: Account }) {
  const toast = useToast();
  const workspaceId = useRouteWorkspaceId();
  const mainStore = useMainStore();
  const { isIW } = mainStore.context;
  const [showSlideMenu, setShowSlideMenu] = useState(false);
  const modelDisplayName = "Account";
  const { formatMessage } = useIntl();
  const pdfExportDialog = useOpen();
  const isPdfExportForAccountsEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_PDF_EXPORT_FOR_ACCOUNTS,
  );

  const { mutate: deleteAccount } = useDeleteAccount({
    workspaceId,
    onSuccess: () => {
      toast({
        content: formatMessage({
          defaultMessage: `${modelDisplayName} has been removed!`,
        }),
        variant: "success",
      });
    },
    onError: () => {
      toast({
        content: formatMessage({ defaultMessage: "Failed to remove account!" }),
        variant: "error",
      });
    },
  });

  const { mutate: updateAccount } = useUpdateAccount({
    workspaceId,
    accountId: account.id,
  });

  const handleOpenTaskDetail = useOpenTaskDetail({
    recordId: account.id,
    recordName: account.name,
    recordType: "accounts",
  });

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleActivateClick = () => {
    updateAccount(
      {
        status: "active",
      },
      {
        onSuccess: () => {
          toast({
            content: formatMessage(
              { defaultMessage: "Account has been activated!" },
              { model: modelDisplayName },
            ),
            variant: "success",
          });
        },
        onError: () => {
          toast({
            content: formatMessage({
              defaultMessage: "Failed to activate account!",
            }),
            variant: "error",
          });
        },
      },
    );
  };

  const handleClickDelete = () => {
    setIsConfirmationOpen(true);
  };

  const handleCancelDelete = () => {
    setIsConfirmationOpen(false);
  };

  const handleDeactivateClick = () => {
    updateAccount(
      {
        status: "inactive",
      },
      {
        onSuccess: () => {
          toast({
            content: formatMessage(
              { defaultMessage: "Account has been deactivated!" },
              { model: modelDisplayName },
            ),
            variant: "success",
          });
        },
        onError: () => {
          toast({
            content: formatMessage({
              defaultMessage: "Failed to deactivate account!",
            }),
            variant: "error",
          });
        },
      },
    );
  };

  const handleDelete = () => {
    setIsConfirmationOpen(false);
    deleteAccount(account.id);
  };

  const closeSendRecordSlideMenu = () => {
    setShowSlideMenu(false);
  };

  const openSendRecordSlideMenu = () => {
    setShowSlideMenu(true);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <IconButton
            aria-label={`${modelDisplayName} Actions`}
            aria-describedby={`account-id-${account.id}`}
            Icon={PiDotsThreeOutlineVerticalFill}
            color="transparent"
            className="tw-h-8 tw-w-8 tw-rounded-md"
          />
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="tw-py-0">
          {isIW && (
            <DropdownMenuItem
              key="Add-to-shared-workspace"
              onClick={openSendRecordSlideMenu}
              className={DROPDOWN_ITEM_STYLE}
            >
              <span className="tw-text-sm tw-font-medium">
                {formatMessage({ defaultMessage: "Send to Workspace" })}
              </span>
            </DropdownMenuItem>
          )}

          <DropdownMenuItem
            key="Create-task"
            onClick={handleOpenTaskDetail}
            className={DROPDOWN_ITEM_STYLE}
          >
            <span className="tw-text-sm tw-font-medium">
              {formatMessage({ defaultMessage: "Create task" })}
            </span>
            <PiCaretRightBold className="tw-h-4 tw-w-4 tw-pl-2" />
          </DropdownMenuItem>
          {isPdfExportForAccountsEnabled && (
            <DropdownMenuItem
              key="Export"
              onClick={pdfExportDialog.open}
              className={DROPDOWN_ITEM_STYLE}
            >
              <span className="tw-text-sm tw-font-medium">
                {formatMessage({ defaultMessage: "Export PDF Report" })}
              </span>
            </DropdownMenuItem>
          )}

          {account?.status === "active" && (
            <DropdownMenuItem
              key="Activate"
              onClick={handleActivateClick}
              className={DROPDOWN_ITEM_STYLE}
            >
              <span className="tw-text-sm tw-font-medium">
                {formatMessage({ defaultMessage: "Activate" })}
              </span>
            </DropdownMenuItem>
          )}

          {account?.status === "inactive" && (
            <DropdownMenuItem
              key="Deactivate"
              onClick={handleDeactivateClick}
              className={DROPDOWN_ITEM_STYLE}
            >
              <span className="tw-text-sm tw-font-medium">
                {formatMessage({ defaultMessage: "Deactivate" })}
              </span>
            </DropdownMenuItem>
          )}

          <DropdownMenuItem
            key="Delete"
            aria-label="Delete Account"
            onClick={handleClickDelete}
            className={`${DROPDOWN_ITEM_STYLE} tw-border-b-0`}
          >
            <span className="tw-text-sm tw-font-medium">
              {formatMessage({ defaultMessage: "Delete" })}
            </span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <ConfirmationPopup
        anchor
        align="end"
        title={formatMessage({ defaultMessage: "Delete Account" })}
        content={formatMessage({
          defaultMessage:
            "Actions are non-reversible. Are you sure you want to proceed?",
        })}
        open={isConfirmationOpen}
        onCancel={handleCancelDelete}
        onConfirm={handleDelete}
      />
      {showSlideMenu && (
        <SlideMenu
          open={showSlideMenu}
          closeSlideMenu={closeSendRecordSlideMenu}
        >
          <SendThemisRecord
            recordID={account.id}
            recordType="accounts"
            modelDisplayName={modelDisplayName}
            closeSlideMenu={closeSendRecordSlideMenu}
          />
        </SlideMenu>
      )}
      {isPdfExportForAccountsEnabled && (
        <PdfExport
          recordType={recordTypePath.accounts}
          recordId={account.id.toString()}
          open={pdfExportDialog.isOpen}
          onClose={pdfExportDialog.close}
        />
      )}
    </>
  );
}
