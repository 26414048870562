import { useIntl } from "react-intl";

import { useWorkspace } from "@/api/queries/workspaces/use-workspace";
import SubpageTabs from "@/components/subpage-tabs/SubPageTabs";
import type { CommentTab } from "@/components/table/shared/comments/CommentsSlideMenu/types";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";

interface CommentsTabsProps {
  collaborativeCommentsLength: number;
  privateCommentsLength: number;
}

export function CommentsTabs({
  collaborativeCommentsLength,
  privateCommentsLength,
}: CommentsTabsProps) {
  const { formatMessage } = useIntl();

  const workspaceId = useRouteWorkspaceId();
  const { data: workspace } = useWorkspace(workspaceId);

  const showTabs =
    workspace &&
    !workspace.is_internal &&
    workspace.user_can_view_private_comments;

  return (
    showTabs && (
      <SubpageTabs<{ commentsTab: CommentTab }>
        infoText={formatMessage({
          defaultMessage:
            "Collaborative comments can be seen by all users with access to this workspace. Internal comments can only be seen by users with access to the internal workspace.",
        })}
        searchParamName="commentsTab"
        options={[
          {
            activeValue: "collaborative",
            label: formatMessage({ defaultMessage: "Collaborative" }),
            count: collaborativeCommentsLength,
          },
          {
            activeValue: "internal",
            label: formatMessage({ defaultMessage: "Internal" }),
            count: privateCommentsLength,
          },
        ]}
      />
    )
  );
}
