import { useSearchParams } from "@themis/shared/hooks/use-search-params/use-search-params";
import {
  IconButton,
  LinkButton,
  SimpleTooltip,
  ToggleButtonGroup,
  ToggleButtonGroupItem,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@themis/ui";
import { usePersistedDataGridState } from "@themis/ui-library/components/data-grid/state/use-persisted-data-grid-state";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import classNames from "classnames";
import React, { useState } from "react";
import {
  PiDotsNineBold,
  PiInfoBold,
  PiListBulletsBold,
  PiPlusBold,
  PiTrashSimpleBold,
} from "react-icons/pi";
import { useIntl } from "react-intl";
import { generatePath } from "react-router-dom";

import { type Account, recordTypePath } from "@/api";
import ExportButton from "@/features/misc/ExportButton";
import FavoriteButton from "@/features/misc/favorites/FavoriteButton";
import ImportButton from "@/features/misc/ImportButton";
import { useRouteWorkspaceId } from "@/hooks/use-route-workspace-id";
import { getLocalStorageTableKey } from "@/utils/get-local-storage-table-key/get-local-storage-table-key";

import { useFields } from "../../../api/queries/fields/use-fields";
import { ColumnsSelect } from "../../../components/columns/columns-select/columns-select";
import { FilterSelect } from "../../../components/FilterSelect/FilterSelect";
import { getFiltersFieldData } from "../../../components/FilterSelect/utils/get-filters-field-data";
import { Header } from "../../../components/Layout/Header";
import { PageContent } from "../../../components/Layout/PageContent";
import { PageLayout } from "../../../components/Layout/PageLayout";
import { DeleteRecordsPopover } from "../../../components/records/delete-records-popover/delete-records-popover";
import { useMainStore } from "../../../contexts/Store";
import { useCompanyId } from "../../../hooks/use-company-id";
import { useIsFeatureEnabled } from "../../../hooks/useIsFeatureEnabled";
import { FEATURE_FLAG_ID } from "../../../stores/types/feature-flag-types";
import { AccountsList } from "../components/accounts-list/accounts-list";
import { routes } from "../routes";
import AccountsHeader from "./AccountsHeader";

export interface AccountSearchParams
  extends Record<string, string | undefined> {
  view?: AccountListView;
}

export type AccountListView = "grid" | "table";

function AccountsContainer({ children }: { children: React.ReactNode }) {
  const [{ view }] = useSearchParams<AccountSearchParams>();

  return (
    <div
      className={classNames(
        "tw-flex tw-min-w-96 tw-flex-1 tw-flex-col tw-gap-4 tw-overflow-auto",
        {
          "tw-px-16": view === "grid",
        },
      )}
    >
      {children}
    </div>
  );
}

function ActionsContainer({ children }: { children: React.ReactNode }) {
  return (
    <div className="tw-flex tw-items-center tw-justify-end tw-gap-1 tw-border-b tw-border-l-0 tw-border-r-0 tw-border-t-0 tw-border-solid tw-border-generals-underline tw-bg-white tw-px-6 tw-py-2">
      {children}
    </div>
  );
}

function TitleContainer({ children }: { children: React.ReactNode }) {
  return <div className="tw-flex tw-items-center tw-gap-1">{children}</div>;
}

export function Accounts() {
  const { formatMessage } = useIntl();
  const mainStore = useMainStore();
  const { companyId } = useCompanyId();
  const recordType = "accounts";
  const workspaceId = useRouteWorkspaceId();

  const deleteRecordsDialog = useOpen();
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  const [searchParams, setSearchParams] =
    useSearchParams<AccountSearchParams>();

  const { data: columns } = useFields({
    companyId,
    recordType: "accounts",
  });

  const accountsListView = searchParams.view || "table";

  const filtersFieldData = getFiltersFieldData<Account>(columns);

  const persistedDataGridState = usePersistedDataGridState({
    tableKey: getLocalStorageTableKey(workspaceId, recordTypePath.accounts),
  });

  const isCustomColumnsEnabled = useIsFeatureEnabled(
    FEATURE_FLAG_ID.GENG_CUSTOM_COLUMNS,
  );

  function handleViewChange(newView: AccountListView) {
    setSearchParams({ ...searchParams, view: newView }, true);
  }

  return (
    <PageLayout>
      <Header
        title={
          <TitleContainer>
            {formatMessage({
              defaultMessage: "Accounts",
            })}
            <Tooltip>
              <TooltipTrigger asChild>
                <IconButton Icon={PiInfoBold} color="transparent" size="sm" />
              </TooltipTrigger>
              <TooltipContent className="tw-box-border tw-inline-flex tw-w-72 tw-flex-col tw-items-start tw-gap-1 tw-px-6 tw-py-4">
                <h3 className="tw-text-base tw-font-semibold">
                  {formatMessage({
                    defaultMessage: "Accounts",
                  })}
                </h3>
                <p className="tw-font-medium">
                  {formatMessage({
                    defaultMessage:
                      "Accounts are business entities, Vendor or Partners used in modules like Vendor Due Diligence and Questionnaires.",
                  })}
                </p>
              </TooltipContent>
            </Tooltip>
          </TitleContainer>
        }
        HeaderOptions={
          <>
            <FavoriteButton
              passedModuleWorkspaceID={
                mainStore.moduleWorkspaces.list.find(
                  (mw) => mw.themis_module.identifier === recordType,
                )?.id
              }
            />
            <ExportButton recordType={recordType} />
            <ImportButton recordType={recordType} />
          </>
        }
      />
      <AccountsHeader />
      <ActionsContainer>
        <SimpleTooltip
          disabled={selectedRowIds.length === 0}
          title={formatMessage({ defaultMessage: "Delete" })}
        >
          {selectedRowIds.length > 0 && (
            <IconButton
              aria-label={formatMessage({ defaultMessage: "Delete" })}
              color={deleteRecordsDialog.isOpen ? "active" : "transparent"}
              Icon={PiTrashSimpleBold}
              onClick={deleteRecordsDialog.open}
            />
          )}
        </SimpleTooltip>
        <DeleteRecordsPopover
          open={deleteRecordsDialog.isOpen}
          onClose={deleteRecordsDialog.close}
          recordType="accounts"
          ids={selectedRowIds}
        />
        {filtersFieldData && (
          <FilterSelect
            filtersFieldData={filtersFieldData}
            dataGridState={persistedDataGridState.dataGridState}
            updateDataGridState={persistedDataGridState.updateDataGridState}
          />
        )}
        <ToggleButtonGroup
          size="lg"
          type="single"
          variant="transparent"
          value={accountsListView}
          onValueChange={handleViewChange}
        >
          <ToggleButtonGroupItem
            aria-label={formatMessage({ defaultMessage: "Table View" })}
            value="table"
            className="tw-px-3"
          >
            <PiListBulletsBold className="tw-h-4 tw-w-4" />
            {formatMessage({ defaultMessage: "List" })}
          </ToggleButtonGroupItem>
          <ToggleButtonGroupItem
            aria-label={formatMessage({ defaultMessage: "Grid View" })}
            value="grid"
            className="tw-px-3"
          >
            <PiDotsNineBold className="tw-h-4 tw-w-4" />
            {formatMessage({ defaultMessage: "Grid" })}
          </ToggleButtonGroupItem>
        </ToggleButtonGroup>
        {isCustomColumnsEnabled && companyId && (
          <ColumnsSelect
            companyId={companyId}
            recordType="accounts"
            workspaceId={workspaceId}
          />
        )}
        <LinkButton
          LeftIcon={PiPlusBold}
          to={generatePath(routes.accounts.detail.recordView, {
            workspace_id: workspaceId,
            accountId: "new",
          })}
        >
          {formatMessage({ defaultMessage: "Add Account" })}
        </LinkButton>
      </ActionsContainer>
      <PageContent>
        <AccountsContainer>
          <AccountsList
            filtersFieldData={filtersFieldData}
            onChangeSelectedRowIds={setSelectedRowIds}
            persistedDataGridState={persistedDataGridState}
          />
        </AccountsContainer>
      </PageContent>
    </PageLayout>
  );
}
