import type { ButtonProps } from "@mui/material";
import { Button } from "@mui/material";
import { useIntl } from "react-intl";

import {
  SimpleDialog,
  type SimpleDialogProps,
} from "../simple-dialog/simple-dialog";

export interface ConfirmationDialogProps extends SimpleDialogProps {
  onConfirm: () => void;
  onCancel?: () => void;
  slotProps?: SimpleDialogProps["slotProps"] & {
    confirm?: Partial<ButtonProps>;
    cancel?: Partial<ButtonProps>;
  };
}

export function ConfirmationDialog({
  open,
  onClose,
  onConfirm,
  onCancel,
  slotProps = {},
  children,
  ...rest
}: ConfirmationDialogProps) {
  const { formatMessage } = useIntl();

  const {
    confirm: confirmSlotProps,
    cancel: cancelSlotProps,
    ...restSlotProps
  } = slotProps;

  const handleCancel = () => {
    onCancel?.();
    onClose?.({}, "escapeKeyDown");
  };

  return (
    <SimpleDialog
      open={open}
      onClose={onClose}
      slotProps={restSlotProps}
      actions={
        <>
          <Button
            children={formatMessage({ defaultMessage: "Confirm" })}
            onClick={onConfirm}
            {...confirmSlotProps}
          />

          <Button
            children={formatMessage({ defaultMessage: "Cancel" })}
            color="inherit"
            variant="text"
            onClick={handleCancel}
            {...cancelSlotProps}
          />
        </>
      }
      {...rest}
    >
      {children}
    </SimpleDialog>
  );
}
