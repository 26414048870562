import type { SuppressKeyboardEventParams } from "ag-grid-community";

const GRID_CELL_CLASSNAME = "ag-cell";

function getAllFocusableElementsOf(el: HTMLElement) {
  return Array.from<HTMLElement>(
    el.querySelectorAll("button, input, a, [href], select, textarea"),
  ).filter((focusableEl) => focusableEl.tabIndex !== -1);
}

function getEventPath(event: Event): HTMLElement[] {
  const path: HTMLElement[] = [];
  let currentTarget: HTMLElement | null = event.target as HTMLElement;
  while (currentTarget) {
    path.push(currentTarget);
    currentTarget = currentTarget.parentElement;
  }
  return path;
}

/**
 * Capture whether the user is tabbing forwards or backwards and suppress keyboard event if tabbing
 * outside of the children
 */
export function suppressKeyboardEvent({ event }: SuppressKeyboardEventParams) {
  const { key, shiftKey } = event;
  const path = getEventPath(event);
  const isTabForward = key === "Tab" && shiftKey === false;
  const isTabBackward = key === "Tab" && shiftKey === true;
  const spaceKey = key === " ";
  const clickableElements = ["button", "a"];
  const { activeElement } = document;

  let suppressEvent = false;

  // Ignore space key events on clickable elements
  if (
    spaceKey &&
    clickableElements.includes(activeElement?.tagName.toLowerCase() || "")
  ) {
    event.preventDefault();
  }

  // Handle cell children tabbing
  if (isTabForward || isTabBackward) {
    const eGridCell = path.find((el) => {
      if (el.classList === undefined) {
        return false;
      }
      return el.classList.contains(GRID_CELL_CLASSNAME);
    });

    if (!eGridCell) {
      return suppressEvent;
    }

    const focusableChildrenElements = getAllFocusableElementsOf(eGridCell);

    const lastCellChildEl =
      focusableChildrenElements[focusableChildrenElements.length - 1];
    const [firstCellChildEl] = focusableChildrenElements;

    // Suppress keyboard event if tabbing forward within the cell and the current focused element is not the last child
    if (focusableChildrenElements.length === 0) {
      return false;
    }

    const currentIndex = focusableChildrenElements.indexOf(
      activeElement as HTMLElement,
    );

    if (isTabForward) {
      const isLastChildFocused =
        lastCellChildEl && activeElement === lastCellChildEl;

      if (!isLastChildFocused) {
        suppressEvent = true;
        if (currentIndex !== -1 || activeElement === eGridCell) {
          event.preventDefault();
          // If the identifier cell is not in edit mode, allow tabbing to the next cell
          if (
            activeElement?.children[0]?.id === "identifier-cell" &&
            focusableChildrenElements[1]?.classList.contains("tw-hidden")
          ) {
            suppressEvent = false;
          }

          // If the identifier cell is in edit mode, allow tabbing to the button
          if (
            lastCellChildEl &&
            activeElement?.id === "identifier-cell-text-input"
          ) {
            event.stopPropagation();
            focusableChildrenElements[1].focus();
          } else {
            focusableChildrenElements[currentIndex + 1].focus();
          }
        }
      } else if (activeElement?.id === "identifier-cell-button-edit") {
        suppressEvent = true;
      }
    }
    // Suppress keyboard event if tabbing backwards within the cell, and the current focused element is not the first child
    else {
      const cellHasFocusedChildren =
        eGridCell.contains(activeElement) && eGridCell !== activeElement;

      // Manually set focus to the last child element if cell doesn't have focused children
      if (!cellHasFocusedChildren) {
        lastCellChildEl.focus();
        event.preventDefault();
      }

      const isFirstChildFocused =
        firstCellChildEl && activeElement === firstCellChildEl;
      if (!isFirstChildFocused) {
        suppressEvent = true;
        if (currentIndex !== -1 || activeElement === eGridCell) {
          if (
            firstCellChildEl &&
            activeElement?.id === "identifier-cell-button-edit"
          ) {
            event.stopPropagation();
            focusableChildrenElements[0].focus();
          } else {
            event.preventDefault();
            focusableChildrenElements[currentIndex + 1].focus();
          }
        }
      } else if (activeElement?.id === "identifier-cell-text-input") {
        suppressEvent = true;
      }
    }
  }

  return suppressEvent;
}
