import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { ConfirmPopup } from "@/components/confirm-popup";
import { Button, Flex, Typography } from "@/components/Elements";
import {
  formatTimeAfterLongDate,
  stringToDateAndTimeSSSZ,
} from "@/components/helpers/DateFormatters";
import { Divider, IconMenu, IconMenuItem } from "@/components/IconMenu";
import Checkbox from "@/components/table/shared/Checkbox";
import CreativeViewCommentResponse from "@/components/table/shared/creative-view/CreativeViewCommentResponse";
import UserMentionsInput from "@/components/table/shared/UserMentionsInput";
import { UsersCircle } from "@/components/users-circle/users-circle";
import { useMainStore } from "@/contexts/Store";
import type { UserComment } from "@/stores/types/comment-types";

export interface CreativeViewCommentProps {
  comment: UserComment;
}

function CreativeViewComment({ comment }: CreativeViewCommentProps) {
  // MobX Stores
  const mainStore = useMainStore();
  const history = useHistory();
  const { taskDetail } = mainStore;

  // State
  const [replyInput, setReplyInput] = useState("");
  const [editInput, setEditInput] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [showDeleteCommentPopup, setShowDeleteCommentPopup] = useState(false);

  // Variables
  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const currentUserID = mainStore.users.user.id;
  const { id, author, replies, content, created_at, file } = comment;
  const isUserAuthor = author.id === currentUserID;
  const attachmentGroups = mainStore.attachmentGroups.list;

  const subscribeLabel = comment.is_subscribed_to_thread
    ? "Unsubscribe"
    : "Subscribe";

  const showViewTask = !!currentUserID && isUserAuthor;

  const formattedCreateDate = formatTimeAfterLongDate(
    stringToDateAndTimeSSSZ(created_at),
    { removeAt: true, monthFormat: "short" },
  );

  function handleUpdateSubscription() {
    mainStore.comments.updateSubscription(
      comment.id,
      !comment.is_subscribed_to_thread,
    );
  }

  function handleDeleteComment() {
    mainStore.comments.delete(id);
  }

  function handleResolveCommentToggle() {
    mainStore.comments.update(id, {
      resolved: !comment.resolved,
    });
  }

  function handleCreateReply(
    event:
      | React.KeyboardEvent<HTMLTextAreaElement>
      | React.KeyboardEvent<HTMLInputElement>,
  ) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      mainStore.comments.create({
        original_comment_id: id,
        content: replyInput,
      });
      setReplyInput("");
    }
  }

  async function handleEditComment() {
    await mainStore.comments.update(id, {
      content: editInput,
    });
    setEditMode(false);
    setEditInput("");
  }

  function handleOpenEditMode() {
    setEditInput(content);
    setEditMode(true);
  }

  function handleClickDelete() {
    setShowDeleteCommentPopup(true);
  }

  function handleViewTask() {
    history.push(`/notifications/tasks/${comment.id}/edit`);
  }

  function handleCopyLink() {
    mainStore.comments.copyCommentLink(comment);
  }

  const renderCommentEditActions = (
    <div className="comment-edit-actions" data-testid="comment-edit-actions">
      <Button
        data-testid="comment-update-confirm"
        onClick={handleEditComment}
        label="Update"
        size="sm"
      />
      <Button
        data-testid="comment-update-cancel"
        onClick={(event) => {
          event.stopPropagation();
          setEditMode(false);
        }}
        label="Cancel"
        theme="tertiary"
        size="sm"
      />
    </div>
  );

  return (
    <>
      <div className="comments-header" data-testid="cv-comment-header">
        <Flex alignCenter columnGap={8} data-testid="cv-comment-info">
          <UsersCircle
            initials={author.initials}
            iconColorIndex={author.icon_color_index}
          />
          <Flex column>
            <Typography
              label={author.full_name}
              weight="semiBold"
              color="generalMidnightDark"
              size="sm"
            />
            <Typography
              label={formattedCreateDate}
              weight="semiBold"
              color="extrasBlueGrayDarker"
              size="xs"
            />
          </Flex>
        </Flex>
        <div
          className="comment-actions"
          data-testid="comment-actions"
          onClick={(event) => event.stopPropagation()}
        >
          <Checkbox
            small
            padding
            disabled={!comment.user_can_update}
            checked={comment.resolved}
            onChange={handleResolveCommentToggle}
          />
          {isCurrentWorkspaceActive && (
            <IconMenu
              triggerTestId="comment-points-trigger"
              containerTestId="cv-comment-popup"
              icon="moreHorizontal"
            >
              {comment.user_can_update_content && (
                <IconMenuItem label="Edit" onClick={handleOpenEditMode} />
              )}
              {showViewTask && (
                <IconMenuItem label="View Comment" onClick={handleViewTask} />
              )}
              <IconMenuItem label="Copy Link" onClick={handleCopyLink} />
              <IconMenuItem
                label="Create Task"
                onClick={() => taskDetail.openFromComment(comment)}
              />
              <Divider />
              <IconMenuItem
                label={subscribeLabel}
                onClick={handleUpdateSubscription}
              />
              {comment.user_can_destroy && (
                <>
                  <Divider />
                  <IconMenuItem label="Delete" onClick={handleClickDelete} />
                </>
              )}
            </IconMenu>
          )}
          <ConfirmPopup
            title="Delete Comment"
            content="Are you sure you want to remove this comment?"
            showPopup={showDeleteCommentPopup}
            setShowPopup={setShowDeleteCommentPopup}
            onConfirmClick={handleDeleteComment}
          />
        </div>
      </div>
      <div
        className="comments-main-content"
        data-testid="cv-comment-main-content"
      >
        {(attachmentGroups?.length || 0) > 1 && (
          <img src={file.url} alt="preview" className="preview" />
        )}
        <UserMentionsInput
          singleLine={false}
          onClick={(event) => event.stopPropagation()}
          editable={editMode}
          content={editMode ? editInput : content}
          onChange={(e) => setEditInput(e.target.value)}
          style={{ borderColor: "#6D71F9" }}
          dataTestID="comment-sidebar-input-edit"
        />
        {editMode && renderCommentEditActions}
      </div>
      {replies.map((reply) => (
        <CreativeViewCommentResponse reply={reply} key={reply.id} />
      ))}
      {isCurrentWorkspaceActive &&
        mainStore.userPermissions.canAddComments &&
        !comment.resolved && (
          <UserMentionsInput
            editable
            singleLine={false}
            content={replyInput}
            onClick={(event) => event.stopPropagation()}
            onKeyDown={handleCreateReply}
            onChange={(e) => setReplyInput(e.target.value)}
            placeholder="Reply"
            dataTestID="comment-sidebar-input-reply"
          />
        )}
    </>
  );
}

export default CreativeViewComment;
