import { useIntl } from "react-intl";
import { z } from "zod";

import type { FieldConfigurationWithLayout } from "@/api/utils/fields/field-configuration-with-layout";
import { useDynamicValidationSchema } from "@/components/dynamic-field/use-dynamic-validation-schema/use-dynamic-validation-schema";
import regex from "@/features/accounts/config/regex";

export function useValidationSchema(
  customColumns: FieldConfigurationWithLayout[],
) {
  const { formatMessage } = useIntl();
  const dynamicFieldSchema = useDynamicValidationSchema(customColumns);

  return z.object({
    name: z
      .string()
      .min(1, formatMessage({ defaultMessage: "Name is required" })),
    account_types: z.array(z.string()).min(
      1,
      formatMessage({
        defaultMessage: "At least 1 account type is required",
      }),
    ),
    logo: z.object({
      signedId: z.string().optional(),
      url: z.string().optional(),
    }),
    description: z.string().optional(),
    mailing_city: z.string().optional(),
    mailing_postal_code: z.string().optional(),
    legal_name: z.string().optional(),
    website: z
      .string()
      .regex(
        regex.URL,
        formatMessage({ defaultMessage: "Valid URL is required" }),
      )
      .optional()
      .or(z.literal("")),
    phone: z.string().optional(),
    mailing_street: z.string().optional(),
    mailing_state: z.string().optional(),
    mailing_country: z.string().optional(),
    account_owner_id: z.coerce.number().optional(),
    main_contact_id: z.number().optional(),
    mainContactPhone: z.string(),
    mainContactEmail: z.string(),
    customFields: dynamicFieldSchema,
  });
}

export type AccountInfoSchema = z.infer<ReturnType<typeof useValidationSchema>>;
