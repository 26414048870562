import classNames from "classnames";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { useMainStore } from "@/contexts/Store";

import closeIcon2 from "../../../images/table-image/icon/close-icon2.svg";
import departmentBlue from "../../../images/table-image/icon/department-blue.svg";
import departmentIcon from "../../../images/table-image/icon/department.svg";
import { userColors } from "../../constants";

interface Props {
  objectID: number;
  moduleWorkspaceID?: number;
  selectableDepartmentIDs?: number[];
  selectableUserIDs?: number[];
  selectedDepartmentIDs?: number[];
  selectedUserIDs?: number[];
}

function DocumentFolderPermissions({
  objectID,
  moduleWorkspaceID,
  selectableDepartmentIDs,
  selectableUserIDs,
  ...props
}: Props) {
  // Import MobX stores
  const mainStore = useMainStore();

  // State
  const [query, setQuery] = useState("");
  const [selectedUserIDs, setSelectedUserIDs] = useState(props.selectedUserIDs);
  const [selectedDepartmentIDs, setSelectedDepartmentIDs] = useState(
    props.selectedDepartmentIDs,
  );
  // @ts-expect-error TS(2339) FIXME: Property 'folder_id' does not exist on type '{}'.
  const { folder_id: parentFolderID } = useParams();

  // Variables
  const departments = selectableDepartmentIDs
    ? mainStore.departments.departments.filter((item) =>
        selectableDepartmentIDs.includes(item.id),
      )
    : mainStore.departments.departments;
  const users = selectableUserIDs
    ? mainStore.users.users.filter((item) =>
        selectableUserIDs.includes(item.id),
      )
    : mainStore.users.users;

  const filteredDepartments = useMemo(
    () =>
      departments.filter((item) =>
        item.title.toLowerCase().includes(query.toLowerCase()),
      ),
    [departments, query],
  );

  const filteredUsers = useMemo(
    () =>
      users.filter(
        (item) => item.full_name?.toLowerCase().includes(query.toLowerCase()),
      ),
    [users, query],
  );

  const selectDepartments = useMemo(
    () =>
      filteredDepartments.filter(
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        (item) => !selectedDepartmentIDs.includes(item.id),
      ),
    [filteredDepartments, selectedDepartmentIDs],
  );

  const selectUsers = useMemo(
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    () => filteredUsers.filter((item) => !selectedUserIDs.includes(item.id)),
    [filteredUsers, selectedUserIDs],
  );

  const searchClasses = classNames({ "active-search": query.length > 0 });

  useEffect(() => {
    handleSubmit();
  }, [selectedUserIDs, selectedDepartmentIDs]);

  // Functions
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function addUserById(id) {
    // @ts-expect-error TS(2461) FIXME: Type 'number[] | undefined' is not an array type.
    setSelectedUserIDs((prevState) => [...prevState, id]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function addDepartmentById(id) {
    setSelectedDepartmentIDs((prevState) => [...prevState, id]);
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function removeUserById(id) {
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    setSelectedUserIDs((prevState) => prevState.filter((item) => item !== id));
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  function removeDepartmentById(id) {
    setSelectedDepartmentIDs((prevState) =>
      // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
      prevState.filter((item) => item !== id),
    );
  }

  function handleSubmit() {
    setQuery("");

    const userIDs =
      selectedUserIDs === props.selectedUserIDs ? undefined : selectedUserIDs;
    const departmentIDs =
      selectedDepartmentIDs === props.selectedDepartmentIDs
        ? undefined
        : selectedDepartmentIDs;

    if (userIDs || departmentIDs) {
      if (moduleWorkspaceID) {
        mainStore.sectionTags.updatePermissions(
          moduleWorkspaceID,
          objectID,
          userIDs,
          departmentIDs,
          parentFolderID,
        );
      }
    }
  }

  // Elements
  const listUsersElements = filteredUsers.slice(0, 1).map((elem) => (
    <div
      className="users-circle-block"
      key={elem.id}
      data-testid="user-list-item"
    >
      {selectUsers.map((user) => {
        const style = {
          background: !user.is_contact
            ? userColors[user.icon_color_index]
            : undefined,
          borderColor: user.is_contact
            ? userColors[user.icon_color_index]
            : undefined,
        };
        const usersClass = classNames("users-circle", {
          "users-circle-contact": user.is_contact,
        });

        return (
          <div
            key={user.id}
            className={searchClasses}
            onClick={() => {
              addUserById(user.id);
            }}
          >
            <div className="users">
              <div className={usersClass} style={style}>
                <span>{user.initials}</span>
              </div>
              <div
                data-testid="document-permissions-name"
                className="user-full-name"
              >
                {user.full_name}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  ));

  const listAvailableDepartments = filteredDepartments
    .slice(0, 1)
    .map((elem) => (
      <div className="procedures-popup-block" key={elem.id}>
        {selectDepartments.map((department) => (
          <div
            key={department.id}
            className={searchClasses}
            onClick={() => addDepartmentById(department.id)}
          >
            <div className="procedures-popup-element">
              <img
                src={departmentIcon}
                className="department"
                alt="department-icon"
              />
              <img
                src={departmentBlue}
                className="department-blue"
                alt="department-icon"
              />
              {department.title}
            </div>
          </div>
        ))}
      </div>
    ));

  const renderSelectedUsers = users
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    .filter((element) => selectedUserIDs.includes(element.id))
    .map((item) => (
      <li key={item.id}>
        {item.full_name}
        <span key={item.id} onClick={() => removeUserById(item.id)}>
          <img src={closeIcon2} alt="closeIcon2" />
        </span>
      </li>
    ));

  const renderSelectedDepartments = departments
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    .filter((element) => selectedDepartmentIDs.includes(element.id))
    .map((item) => (
      <li key={item.id}>
        {item.title}
        <span key={item.id} onClick={() => removeDepartmentById(item.id)}>
          <img src={closeIcon2} alt="closeIcon2" />
        </span>
      </li>
    ));

  return (
    <div
      className="table-dropdown procedures-dropdown-popup"
      data-testid="document-folder-permissions-dropdown"
    >
      <div className="procedures-popup-list">
        <div className="form-row">
          <input
            type="search"
            className="procedures-search"
            placeholder="Search for Users / Departments"
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>

        {/* @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'. */}
        {(selectedDepartmentIDs.length > 0 || selectedUserIDs.length > 0) && (
          <div className="users-dropdown-block">
            <ul>
              {renderSelectedDepartments}
              {renderSelectedUsers}
            </ul>
          </div>
        )}
        {selectUsers.length > 0 && (
          <div>
            <h4>All Available Users</h4>
            {listUsersElements}
          </div>
        )}
        {selectDepartments.length > 0 && (
          <div>
            <h4>All Available Departments</h4>
            {listAvailableDepartments}
          </div>
        )}
      </div>
    </div>
  );
}

export default DocumentFolderPermissions;
