import { Portal, TooltipTrigger } from "@radix-ui/react-tooltip";
import type { VariantProps } from "cva";
import { cva } from "cva";
import type { ChangeEvent, DragEvent } from "react";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ImAttachment } from "react-icons/im";
import { LuFilePlus2 } from "react-icons/lu";
import {
  PiArrowsCounterClockwiseBold,
  PiDownloadSimpleBold,
  PiSpinnerBold,
  PiTrashSimpleBold,
} from "react-icons/pi";

import { cn, handleDownload } from "../../lib/utils";
import { Button } from "../Button/Button";
import { IconButton } from "../Button/IconButton";
import { FileViewer } from "../FileViewer/FileViewer";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
import { Tooltip, TooltipContent, TooltipProvider } from "../Tooltip/Tooltip";

const readOnlyStyles =
  "tw-bg-primaryDim-25 focus-visible:tw-border-neutral-100 focus:tw-border-transparent focus:tw-ring-0 tw-text-neutral-300";
const lockedStyles =
  "tw-bg-primary-25 focus-visible:tw-border-neutral-100 focus:tw-border-transparent read-only:focus:tw-ring-0 tw-text-primaryDim-300";

const filePickerInputVariants = cva({
  base: [
    "tw-box-border tw-inline-flex tw-w-full tw-pl-3 tw-pr-1 tw-items-center tw-rounded-md tw-border tw-border-solid tw-border-neutral-100 tw-bg-neutral-25 tw-transition-colors",
    "tw-text-sm tw-text-neutral-200 tw-font-semibold tw-font-sans",
    "focus-visible:tw-ring-ring focus-visible:tw-outline-none focus-visible:tw-ring-1 focus-visible:tw-border-primary-300",
  ],
  variants: {
    size: {
      sm: "tw-h-7",
      md: "tw-h-8",
      lg: "tw-h-9",
    },
  },
  defaultVariants: {
    size: "lg",
  },
});

const Loading = ({ percentage }: { percentage: number }) => (
  <div className="tw-flex tw-h-full tw-items-center tw-justify-center">
    <span className="tw-w-6">{percentage}</span>
    <PiSpinnerBold className="tw-h-6 tw-w-6 tw-animate-spin tw-text-primary-300" />
  </div>
);

const Dropzone = ({
  multiple,
  onSelect,
}: {
  multiple?: boolean;
  onSelect: (file: File | FileList) => void;
}) => {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const [dragging, setDragging] = useState(false);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }

    onSelect(multiple ? event.target.files : event.target.files[0]);
  };

  const handleFileDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(false);

    if (!event.dataTransfer.files) {
      return;
    }

    onSelect(multiple ? event.dataTransfer.files : event.dataTransfer.files[0]);
  };

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragging(true);
  };

  const onClickSelect = () => {
    inputFileRef.current?.click();
  };

  return (
    <div
      className={cn(
        "tw-box-border tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-gap-3 tw-transition-colors",
        {
          "tw-rounded-md tw-border-solid tw-border-secondary-300 tw-bg-secondary-50 tw-text-neutral-500":
            dragging,
        },
      )}
      onDrop={handleFileDrop}
      onDragOver={handleDragOver}
      onDragExit={() => setDragging(false)}
    >
      <LuFilePlus2 className="tw-h-6 tw-w-6" />
      <div className="tw-text-center tw-font-sans tw-text-sm">
        {dragging ? <p>Drop file here</p> : <p>Drag & drop into this box</p>}
        {!dragging && <p>- or -</p>}
      </div>
      {!dragging && (
        <Button className="tw-mt-1" onClick={onClickSelect}>
          {`Select File${multiple ? "s" : ""}`}
        </Button>
      )}
      <input
        hidden
        multiple={multiple}
        type="file"
        ref={inputFileRef}
        onChange={handleFileChange}
      />
    </div>
  );
};

type FilePickerProps = VariantProps<typeof filePickerInputVariants> & {
  alignPopover?: "start" | "center" | "end";
  backwardCompatible?: boolean;
  className?: string;
  file?: { name: string; type: string; url: string } | File;
  isLoading?: boolean;
  isOpen?: boolean;
  locked?: boolean;
  multiple?: boolean;
  percentage?: number;
  placeholder?: string;
  readOnly?: boolean;
  trigger?: React.ReactNode;
  onClose?: () => void;
  onOpenChange?: (isOpen: boolean) => void;
  onRemoveFile?: () => void;
  onSelectFile?: (file: File) => void;
  onSelectFiles?: (files: FileList) => void;
};

const FilePicker = forwardRef<HTMLButtonElement, FilePickerProps>(
  (
    {
      alignPopover = "start",
      backwardCompatible = false,
      className,
      file,
      isLoading,
      isOpen,
      locked,
      multiple,
      percentage = 0,
      placeholder = "- Add Attachment -",
      readOnly,
      size,
      trigger,
      onClose,
      onOpenChange,
      onRemoveFile = () => {},
      onSelectFile = () => {},
      onSelectFiles = () => {},
    },
    ref,
  ) => {
    const disabled = locked || readOnly;
    const [isViewerVisible, setIsViewerVisible] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const textRef = useRef<HTMLDivElement | null>(null);
    const isRemoteFile = file && "url" in file;

    const isContainerTrigger = trigger || !file;

    const containerClasses = cn(filePickerInputVariants({ size }), {
      "tw-border-neutral-100": !isOpen,
      "tw-border-primary-300": isOpen,
      "tw-border-none tw-p-0 tw-bg-transparent": backwardCompatible,
      "tw-text-secondary-200": file && !disabled,
      [lockedStyles]: locked,
      [readOnlyStyles]: readOnly,
    });

    const handleFileSelected = (selectedFiles: File | FileList) => {
      if (multiple) {
        onSelectFiles(selectedFiles as FileList);

        return;
      }
      onSelectFile(selectedFiles as File);
    };

    useEffect(() => {
      if (textRef.current) {
        setShowTooltip(
          textRef.current.scrollWidth > textRef.current.clientWidth,
        );
      }
    }, [file]);

    const renderTrigger = () => {
      if (trigger) {
        return trigger;
      }

      return (
        <button
          aria-expanded={isOpen}
          className={cn(containerClasses, className)}
        >
          <div
            className={cn(
              "tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap",
            )}
          >
            {file?.name || placeholder}
          </div>
          <ImAttachment
            className={cn(
              "tw-ml-auto tw-mr-2 tw-h-4 tw-w-4 tw-shrink-0 tw-text-neutral-500 tw-transition-transform",
              {
                "tw-text-neutral-200": disabled,
              },
            )}
          />
        </button>
      );
    };

    const renderContainerEl = () => {
      if (isContainerTrigger) {
        return (
          <PopoverTrigger
            disabled={disabled}
            className={className}
            ref={ref}
            asChild
          >
            {renderTrigger()}
          </PopoverTrigger>
        );
      }

      return (
        <div
          aria-expanded={isOpen}
          className={cn(containerClasses, className, {
            "tw-bg-transparent": backwardCompatible,
          })}
        >
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div
                  ref={textRef}
                  className={cn(
                    "tw-cursor-pointer tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap",
                    {
                      "tw-text-primaryDim-300": backwardCompatible,
                      "tw-cursor-pointer": file.name,
                    },
                  )}
                  onClick={() => {
                    if (file) {
                      setIsViewerVisible(true);
                    }
                  }}
                >
                  {file.name || placeholder}
                </div>
              </TooltipTrigger>
              {showTooltip && file.name && (
                <Portal>
                  <TooltipContent
                    side="bottom"
                    className="tw-box-border tw-inline-flex tw-w-72 tw-flex-col tw-items-start tw-gap-1 tw-px-6 tw-py-4"
                  >
                    <p className="tw-break-all tw-text-sm tw-text-white">
                      {file.name}
                    </p>
                  </TooltipContent>
                </Portal>
              )}
            </Tooltip>
          </TooltipProvider>

          <div className="tw-ml-auto tw-flex tw-flex-row">
            <IconButton
              onClick={() =>
                handleDownload(
                  isRemoteFile ? file.url : URL.createObjectURL(file),
                  file.name,
                )
              }
              // eslint-disable-next-line react/no-unstable-nested-components
              Icon={() => (
                <PiDownloadSimpleBold
                  className={cn({
                    "tw-fill-primaryDim-300": backwardCompatible,
                  })}
                />
              )}
              color="transparent"
              size="lg"
            />
            {!disabled && (
              <>
                <PopoverTrigger asChild>
                  <IconButton
                    ref={ref}
                    Icon={PiArrowsCounterClockwiseBold}
                    color="transparent"
                    size="lg"
                  />
                </PopoverTrigger>
                <IconButton
                  onClick={onRemoveFile}
                  Icon={PiTrashSimpleBold}
                  color="transparent"
                  size="lg"
                />
              </>
            )}
          </div>
        </div>
      );
    };

    return (
      <>
        <Popover open={isOpen} onOpenChange={onOpenChange}>
          {renderContainerEl()}
          <PopoverContent
            onInteractOutside={onClose}
            onEscapeKeyDown={onClose}
            className={cn("tw-h-52 tw-w-80 tw-p-2 tw-font-sans")}
            align={isContainerTrigger ? alignPopover : "end"}
          >
            <div className="tw-h-full tw-rounded-md tw-bg-neutral-50 tw-text-neutral-300">
              {isLoading ? (
                <Loading percentage={percentage} />
              ) : (
                <Dropzone multiple={multiple} onSelect={handleFileSelected} />
              )}
            </div>
          </PopoverContent>
        </Popover>
        {file &&
          isViewerVisible &&
          createPortal(
            <FileViewer
              file={isRemoteFile ? file.url : file}
              type={file.type}
              onClose={() => setIsViewerVisible(false)}
            />,
            document.body,
          )}
      </>
    );
  },
);

FilePicker.displayName = "FilePicker";

export { FilePicker, type FilePickerProps };
