import React from "react";
import { type IconType } from "react-icons";

import type { ButtonProps } from "../../Button/Button";
import { Button } from "../../Button/Button";

type Props = ButtonProps & {
  Icon: IconType;
  text: string;
  color: string;
  onClick: () => void;
};

function IconButtonCell({ Icon, text, color, onClick }: Props) {
  return (
    <Button
      LeftIcon={Icon}
      onClick={onClick}
      color={color}
      className="tw-h-full tw-w-full tw-rounded-none tw-p-0 tw-text-sm tw-font-medium"
    >
      {text}
    </Button>
  );
}

export default IconButtonCell;
