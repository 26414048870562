import { faker } from "@faker-js/faker";

import type {
  LegacyWebsiteMonitoringGroupChildResultsUpdate200,
  LegacyWebsiteMonitoringGroupChildResultsUpdateMutationRequest,
  LegacyWebsiteMonitoringGroupChildResultsUpdateMutationResponse,
  LegacyWebsiteMonitoringGroupChildResultsUpdatePathParams,
} from "../models/LegacyWebsiteMonitoringGroupChildResultsUpdate";
import { createMonitoringGroupAssetChild } from "./createMonitoringGroupAssetChild";
import { createUpdateMonitoringGroupResolvedRequest } from "./createUpdateMonitoringGroupResolvedRequest";

export function createLegacyWebsiteMonitoringGroupChildResultsUpdatePathParams(): NonNullable<LegacyWebsiteMonitoringGroupChildResultsUpdatePathParams> {
  return { monitoring_group_asset_child_id: faker.number.int() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupChildResultsUpdate200(): NonNullable<LegacyWebsiteMonitoringGroupChildResultsUpdate200> {
  return { data: createMonitoringGroupAssetChild() };
}

export function createLegacyWebsiteMonitoringGroupChildResultsUpdateMutationRequest(): NonNullable<LegacyWebsiteMonitoringGroupChildResultsUpdateMutationRequest> {
  return { data: createUpdateMonitoringGroupResolvedRequest() };
}

/**
 * @description OK
 */
export function createLegacyWebsiteMonitoringGroupChildResultsUpdateMutationResponse(): NonNullable<LegacyWebsiteMonitoringGroupChildResultsUpdateMutationResponse> {
  return { data: createMonitoringGroupAssetChild() };
}
