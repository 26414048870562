import { type ThemeOptions } from "@mui/material";

export const inputBase: ThemeOptions["components"] = {
  MuiInputBase: {
    defaultProps: {
      placeholder: "Type here",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        minWidth: theme.width.input,
        backgroundColor: theme.palette.background.paper,
        fontSize: theme.typography.body1.fontSize,
      }),
      sizeSmall: ({ theme }) => ({
        fontSize: theme.typography.body2.fontSize,
      }),
      input: ({ theme }) => ({
        "&": {
          padding: theme.spacing(1, 2),
        },
      }),
      inputSizeSmall: ({ theme }) => ({
        "&": {
          padding: theme.spacing(0.5, 1),
        },
      }),
      multiline: {
        "&": {
          padding: 0,
        },
      },
    },
    variants: [
      {
        props: { type: "number" },
        style: {
          "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "input[type=number]": {
            MozAppearance: "textfield",
          },
        },
      },
    ],
  },
};
