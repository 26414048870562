import type { UseMutationOptions } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { createField } from "../../gen/axios/fieldsController";
import type {
  CreateFieldMutationRequest,
  CreateFieldMutationResponse,
  RecordTypePath,
} from "../../gen/models";
import { getLayoutsQueryKey } from "../layouts/use-layouts";
import { getRecordsQueryKey } from "../records/get-records-query-key";
import { getFieldsQueryKey } from "./use-fields";

interface CreateFieldMutationParams {
  companyId: number | undefined;
  recordType: RecordTypePath;
}

type CreateFieldMutationOptions = Partial<
  UseMutationOptions<
    CreateFieldMutationResponse,
    unknown,
    CreateFieldMutationRequest
  >
>;

export function useCreateField(
  { companyId, recordType }: CreateFieldMutationParams,
  options: CreateFieldMutationOptions = {},
) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: CreateFieldMutationRequest) => {
      if (companyId == null) {
        throw new Error("companyId is required");
      }

      return createField(companyId, recordType, data);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: getFieldsQueryKey(),
          refetchType: "all",
        }),
        queryClient.invalidateQueries({
          queryKey: getLayoutsQueryKey(),
          refetchType: "all",
        }),
      ]);
      await queryClient.invalidateQueries({
        queryKey: getRecordsQueryKey({ recordType }),
        refetchType: "all",
      });
    },
    ...options,
  });
}
