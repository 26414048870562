import type { CustomCellRendererProps } from "ag-grid-react";
import React from "react";
import { PiLinkSimpleBold } from "react-icons/pi";

import { cn } from "../../../lib/utils";
import { IconButton } from "../../Button/IconButton";
import type { TextInputProps } from "../../Form/TextInput";
import { TextInput } from "../../Form/TextInput";
import CustomLink from "./CustomLink";

type Props = TextInputProps & CustomCellRendererProps;

function LinkRenderCell({ value, ...props }: Props) {
  const editable = Boolean(props.colDef?.editable);

  return (
    <div className="tw-relative tw-h-full tw-w-full">
      <TextInput
        readOnly={!editable}
        className=" tw-h-full tw-w-[calc(100%-50px)] tw-truncate tw-rounded-none tw-border-0 tw-bg-transparent tw-text-secondary-300 !tw-placeholder-transparent read-only:tw-bg-transparent focus-visible:tw-ring-0 group-hover/cell:!tw-placeholder-neutral-200"
        placeholder={props.placeholder || "Add Link"}
        value={value}
        onChange={() => {}}
      />
      <CustomLink
        to={value}
        className="tw-absolute tw-right-0 tw-top-1 tw-mr-2"
      >
        <IconButton
          onClick={() => {}}
          Icon={PiLinkSimpleBold}
          color="transparent"
          size="lg"
          className={cn({
            "[&>svg]:tw-fill-transparent": !value,
            "group-hover/cell:[&>svg]:tw-fill-neutral-500": value,
            "group-hover/cell:[&>svg]:tw-fill-neutral-200": !value,
          })}
        />
      </CustomLink>
    </div>
  );
}

export default LinkRenderCell;
