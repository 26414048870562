import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  DeleteFieldMutationResponse,
  DeleteFieldPathParams,
} from "../../models/DeleteField";

/**
 * @description Delete an existing custom field for a specific record type
 * @summary Delete an existing field
 * @link /companies/:company_id/:record_type/fields/:id
 */
export async function deleteField(
  companyId: DeleteFieldPathParams["company_id"],
  recordType: DeleteFieldPathParams["record_type"],
  id: DeleteFieldPathParams["id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<ResponseConfig<DeleteFieldMutationResponse>["data"]> {
  const res = await client<DeleteFieldMutationResponse>({
    method: "delete",
    url: `/companies/${companyId}/${recordType}/fields/${id}`,
    ...options,
  });
  return res.data;
}
