import { isEqual } from "lodash";

import type { DataGridState } from "./data-grid-state";

export const defaultDataGridState: DataGridState = {
  filters: undefined,
  pageIndex: 0,
  pageSize: 10,
  sorts: undefined,
};

const paramsThatResetPage: Array<keyof DataGridState> = [
  "filters",
  "pageSize",
  "sorts",
];

export function computeGridState(
  _gridState: DataGridState | undefined,
  update: Partial<DataGridState>,
): DataGridState {
  const gridState = _gridState ?? defaultDataGridState;
  const shouldResetPage = paramsThatResetPage.some(
    (param) => param in update && !isEqual(update[param], gridState[param]),
  );
  const newState = {
    ...gridState,
    ...update,
  };
  if (shouldResetPage) {
    newState.pageIndex = 0;
  }
  return newState;
}
