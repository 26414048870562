import type { QueryKey, UseQueryOptions } from "@tanstack/react-query";
import { skipToken, useQuery } from "@tanstack/react-query";

import type {
  ListCommentsPathParams,
  ListCommentsQueryResponse,
} from "@/api/gen/models/ListComments";

import { listComments } from "../../gen/axios/commentsController";

interface UseCommentsParams {
  recordType: ListCommentsPathParams["record_type"];
  recordId: ListCommentsPathParams["record_id"];
}

export const getCommentsQueryKey = (params?: UseCommentsParams): QueryKey => [
  "comments",
  params,
];

export function useComments<
  TError = Error,
  TData = ListCommentsQueryResponse["data"],
>(
  { recordType, recordId }: UseCommentsParams,
  options?: Partial<
    UseQueryOptions<ListCommentsQueryResponse["data"], TError, TData>
  >,
) {
  return useQuery({
    queryKey: getCommentsQueryKey({ recordType, recordId }),
    queryFn:
      recordType && recordId
        ? async () => {
            const response = await listComments(recordType, recordId);
            return response.data;
          }
        : skipToken,
    ...options,
  });
}
