import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import { Stack } from "@themis/ui-library/components/layout/stack/stack";
import { Card } from "@themis/ui-library/components/surfaces/card/card";
import { ClickAwayListener } from "@themis/ui-library/components/utils/click-away-listener/click-away-listener";
import { Collapse } from "@themis/ui-library/components/utils/collapse/collapse";
import { alpha } from "@themis/ui-library/styles";
import type { ReactElement } from "react";
import { useState } from "react";
import { useIntl } from "react-intl";

import type { User } from "@/stores/types/user-types";

import type { CommentWithUser } from "../comment-with-user";
import { CreateComment } from "../create-comment/create-comment";
import { EditComment } from "../edit-comment/edit-comment";
import { useFocusedComment } from "../use-focused-comment/use-focused-comment";
import { CommentContent } from "./comment-content/comment-content";
import { CommentHeader } from "./comment-header/comment-header";
import { Reply } from "./reply/reply";

interface CommentProps {
  comment: CommentWithUser;
  replies: CommentWithUser[];
  recordId: number;
  recordName: string;
  recordTypePath: RecordTypePath;
  usersMap: Record<string, User>;
}

export function Comment({
  comment,
  replies,
  recordId,
  recordName,
  recordTypePath,
  usersMap,
}: CommentProps): ReactElement {
  const { formatMessage } = useIntl();
  const { getIsActive, scrollToWhenActive, resetFocused } = useFocusedComment();

  const [isEditMode, setIsEditMode] = useState(false);

  const handleClickAway = () => {
    if (getIsActive(comment.id)) {
      resetFocused();
    }
  };

  return (
    <div>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Card
          ref={(ref) => scrollToWhenActive(ref, comment.id)}
          component="article"
          elevation={0}
          sx={(theme) => ({
            backgroundColor: getIsActive(comment.id)
              ? theme.palette.primary.lightest
              : undefined,
            padding: 1,
            transition: theme.transitions.create(["background-color"]),
          })}
        >
          <Stack>
            <CommentHeader
              comment={comment}
              recordId={recordId}
              recordName={recordName}
              recordTypePath={recordTypePath}
              onEditClick={() => setIsEditMode(true)}
            />

            {isEditMode ? (
              <EditComment
                comment={comment}
                recordId={recordId}
                recordTypePath={recordTypePath}
                usersMap={usersMap}
                onClose={() => setIsEditMode(false)}
              />
            ) : (
              <CommentContent comment={comment} />
            )}
          </Stack>
        </Card>
      </ClickAwayListener>

      <Collapse in={!comment.resolved}>
        <Stack spacing={2} marginTop={2}>
          {replies.length > 0 && (
            <Stack px={2} spacing={2}>
              {replies.map((reply) => (
                <Reply
                  key={reply.id}
                  reply={reply}
                  recordId={recordId}
                  recordName={recordName}
                  recordTypePath={recordTypePath}
                  usersMap={usersMap}
                />
              ))}
            </Stack>
          )}

          <CreateComment
            internal={comment.private}
            parentComment={comment}
            placeholder={formatMessage({ defaultMessage: "Reply" })}
            recordId={recordId}
            recordTypePath={recordTypePath}
            usersMap={usersMap}
          />
        </Stack>
      </Collapse>
    </div>
  );
}
