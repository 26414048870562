import type { ThemeOptions } from "@mui/material";

export const chip: ThemeOptions["components"] = {
  MuiChip: {
    defaultProps: {
      size: "small",
    },
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: theme.typography.fontWeightBold,
      }),
      sizeSmall: ({ theme }) => ({
        fontSize: theme.typography.body2.fontSize,
      }),
    },
  },
};
