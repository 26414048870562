import MuiAvatarGroup, {
  type AvatarGroupProps as MuiAvatarGroupProps,
} from "@mui/material/AvatarGroup";
import { forwardRef } from "react";

import { Avatar, type AvatarProps } from "../avatar";

export interface AvatarGroupProps extends MuiAvatarGroupProps {
  slotProps?: {
    surplus?: Partial<AvatarProps>;
  };
}

export const AvatarGroup = forwardRef<HTMLDivElement, AvatarGroupProps>(
  ({ ...rest }, ref) => {
    return <MuiAvatarGroup ref={ref} slots={{ surplus: Avatar }} {...rest} />;
  },
);

AvatarGroup.displayName = "AvatarGroup";
