import {
  skipToken,
  useQuery,
  type UseQueryOptions,
} from "@tanstack/react-query";
import { millisecondsInHour } from "date-fns";

import type { RailsErrors } from "@/utils/errors";

import { listThemisRecordLayouts } from "../../gen/axios/layoutsController";
import type {
  Layout,
  LayoutLayoutType,
  ListThemisRecordLayoutsQueryResponse,
  RecordTypePath,
} from "../../gen/models";

interface UseLayoutsParams {
  companyId: number | undefined;
  recordType: RecordTypePath;
  workspaceId: number;
}

export const getLayoutsQueryKey = ({
  companyId,
  workspaceId,
  recordType,
}: Partial<UseLayoutsParams> = {}) =>
  [
    "layouts",
    companyId ? `companyId:${companyId}` : undefined,
    workspaceId ? `workspaceId:${workspaceId}` : undefined,
    recordType ? `recordType:${recordType}` : undefined,
  ].filter(Boolean);

export function useLayouts<
  TError = RailsErrors,
  TData = ListThemisRecordLayoutsQueryResponse["data"],
>(
  params: UseLayoutsParams,
  options?: Partial<
    UseQueryOptions<ListThemisRecordLayoutsQueryResponse["data"], TError, TData>
  >,
) {
  const { companyId, workspaceId, recordType } = params;

  return useQuery({
    queryKey: getLayoutsQueryKey(params),
    queryFn:
      !!companyId && !!recordType
        ? async () => {
            const response = await listThemisRecordLayouts(
              companyId,
              recordType,
              { workspace_id: workspaceId },
            );

            return response.data;
          }
        : skipToken,
    staleTime: millisecondsInHour,
    ...options,
  });
}

export function selectLayout(
  layoutType: LayoutLayoutType,
): (layouts: Layout[] | undefined) => Layout | undefined {
  return (layouts: Layout[] | undefined) =>
    layouts?.find((_layout) => _layout.layout_type === layoutType);
}
