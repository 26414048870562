import {
  alpha,
  buttonClasses,
  circularProgressClasses,
  type ThemeOptions,
} from "@mui/material";

import { getPaletteColor } from "../../../utils/get-palette-color";

export const button: ThemeOptions["components"] = {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: "contained",
    },
    styleOverrides: {
      icon: {
        alignItems: "center",
      },
      root: ({ theme }) => ({
        minWidth: 0,
        fontWeight: theme.typography.fontWeightBold,
        textTransform: "capitalize",
        paddingTop: 0,
        paddingBottom: 0,

        [`&.${buttonClasses.disabled}`]: {
          cursor: "not-allowed",
          pointerEvents: "auto",
        },
      }),
      contained: ({ theme, ownerState }) => {
        const paletteColor = getPaletteColor(theme, ownerState.color);

        return {
          border: "1px solid transparent",
          [`&.${buttonClasses.loading}`]: {
            color: paletteColor && alpha(paletteColor.contrastText, 0.2),
            backgroundColor: paletteColor?.main,
          },
        };
      },
      text: ({ theme, ownerState }) => {
        const paletteColor = getPaletteColor(theme, ownerState.color);

        return {
          border: "1px solid transparent",
          [`&.${buttonClasses.loading}`]: {
            color: paletteColor && alpha(paletteColor.main, 0.2),
          },
          [`.${buttonClasses.loadingIndicator}`]: {
            color: paletteColor?.main,
          },
        };
      },
      sizeSmall: {
        paddingLeft: 7,
        paddingRight: 7,
        fontSize: 12,
        minHeight: 28,
      },
      sizeMedium: {
        paddingLeft: 11,
        paddingRight: 11,
        fontSize: 14,
        minHeight: 32,
      },
      sizeLarge: {
        paddingLeft: 15,
        paddingRight: 15,
        fontSize: 16,
        minHeight: 36,
      },
      loadingIndicator: ({ theme, ownerState }) => {
        const paletteColor = getPaletteColor(theme, ownerState.color);

        return {
          width: 18,
          height: 18,
          color: paletteColor?.contrastText,
          [`.${circularProgressClasses.root}`]: {
            width: "100% !important",
            height: "100% !important",
          },
          [`.${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        };
      },
    },
  },
};
