import { http } from "msw";

import { createLegacyWebsiteMonitoringGroupResultsQueryResponse } from "../createLegacyWebsiteMonitoringGroupResults";

export const legacyWebsiteMonitoringGroupResultsHandler = http.get(
  "*/website_monitoring/:monitoring_group_asset_id/monitoring_group_results",
  function handler(info) {
    return new Response(
      JSON.stringify(createLegacyWebsiteMonitoringGroupResultsQueryResponse()),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
