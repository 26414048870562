import { DownloadButton } from "./components/download-button/download-button";
import { Footer } from "./components/footer/footer";
import { Header } from "./components/header/header";
import { Root } from "./components/root/root";
import { TitleBar } from "./components/title-bar/title-bar";
import { UploadDate } from "./components/upload-date/upload-date";
import { Viewer } from "./components/viewer/viewer";
import { ZoomControls } from "./components/zoom-controls/zoom-controls";

export const InteractiveMedia = {
  DownloadButton,
  Footer,
  Header,
  Root,
  TitleBar,
  UploadDate,
  Viewer,
  ZoomControls,
};
