import client from "@/api/client";
import type { ResponseConfig } from "@/api/client";

import type {
  LegacyWebsiteMonitoringGroupAssetPathParams,
  LegacyWebsiteMonitoringGroupAssetQueryResponse,
} from "../../models/LegacyWebsiteMonitoringGroupAsset";

/**
 * @description Website Monitoring - Asset
 * @summary Website Monitoring Asset (Legacy)
 * @link /website_monitoring/:monitoring_group_asset_id/monitoring_group_asset
 */
export async function legacyWebsiteMonitoringGroupAsset(
  monitoringGroupAssetId: LegacyWebsiteMonitoringGroupAssetPathParams["monitoring_group_asset_id"],
  options: Partial<Parameters<typeof client>[0]> = {},
): Promise<
  ResponseConfig<LegacyWebsiteMonitoringGroupAssetQueryResponse>["data"]
> {
  const res = await client<LegacyWebsiteMonitoringGroupAssetQueryResponse>({
    method: "get",
    url: `/website_monitoring/${monitoringGroupAssetId}/monitoring_group_asset`,
    ...options,
  });
  return res.data;
}
