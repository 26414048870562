import { http } from "msw";

import { createLegacyWebsiteMonitoringGroupChildResultsUpdateMutationResponse } from "../createLegacyWebsiteMonitoringGroupChildResultsUpdate";

export const legacyWebsiteMonitoringGroupChildResultsUpdateHandler = http.put(
  "*/website_monitoring/:monitoring_group_asset_child_id/monitoring_group_child_results_marked_resolved",
  function handler(info) {
    return new Response(
      JSON.stringify(
        createLegacyWebsiteMonitoringGroupChildResultsUpdateMutationResponse(),
      ),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  },
);
