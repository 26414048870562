import {
  DatePicker,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@themis/ui";
import dayjs from "dayjs";
import type { ReactNode } from "react";
import type { Control, FieldValues, Path } from "react-hook-form";

import { SHORT_API_FORMAT } from "../../constants";

interface DateFormFieldProps<T extends FieldValues> {
  control: Control<T>;
  name: Path<T>;
  label?: ReactNode;
  required?: boolean;
  disabled?: boolean;
}

export function DateFormField<T extends FieldValues>({
  control,
  name,
  label,
  required,
  disabled,
}: DateFormFieldProps<T>) {
  return (
    <FormField
      control={control}
      name={name}
      required={required}
      render={({ field }) => (
        <FormItem>
          {label && <FormLabel>{label}</FormLabel>}

          <FormControl>
            <DatePicker
              {...field}
              locked={disabled}
              closeOnDateSelect
              calendarProps={{
                mode: "single",
                selected: field.value ? dayjs(field.value).toDate() : undefined,
                onSelect: (date) => {
                  if (date) {
                    field.onChange(dayjs(date).format(SHORT_API_FORMAT));
                  }
                },
              }}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}
