import "./vendor-contracts.scss";

import { observer } from "mobx-react";
import React from "react";
import { PiPlusBold } from "react-icons/pi";
import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";

import { useAccountContracts } from "@/api/queries/contracts";
import LoadingWrapper from "@/components/loading-wrapper";
import ListTitleSettings from "@/components/table/settings/ListTitleSettings";
import Table from "@/components/table/Table";
import { useMainStore } from "@/contexts/Store";

import { routes } from "../../accounts/routes";
import ContractRow from "../components/ContractRow";
import { contractsFields } from "../constants";
import { useSortable } from "../utils/useSortable";

function VendorContracts({ accountId }: { accountId?: number | null }) {
  const mainStore = useMainStore();
  const { isCurrentWorkspaceActive } = mainStore.workspaces;

  const history = useHistory();
  const { pathname } = useLocation();
  const { workspace_id: workspaceID } = useParams<{
    workspace_id: string;
    vendor_id: string;
  }>();
  const { sortByFieldName, sortDirection, sortParams, setSortBy } =
    useSortable();
  const {
    data: contractsData,
    isLoading,
    error,
  } = useAccountContracts(Number(accountId), sortParams);

  // Functions
  function addNewContracts() {
    const contractsPath = generatePath(
      routes.accounts.detail.contracts.detail,
      {
        workspace_id: workspaceID,
        accountId: accountId!,
        contractId: "new",
      },
    );

    const params = new URLSearchParams({
      redirectTo: pathname,
    }).toString();

    history.push(`${contractsPath}?${params}`);
  }

  return (
    <Table>
      <LoadingWrapper
        loading={isLoading}
        errorText={error && "There was an issue loading the account contracts"}
        loadingLayout="table"
      >
        <div className="vendor-contracts table-list-wrap">
          <div className="list-title-table">
            <ul>
              <div className="list-column-wrap">
                <div className="list-title-table-wrap">
                  {contractsFields.map((field) => (
                    <ListTitleSettings
                      key={field.field_name}
                      title={field.display_name}
                      width={field.width}
                      sortable={field.sortable}
                      sortDirection={
                        field.sortable && sortByFieldName === field.field_name
                          ? sortDirection
                          : undefined
                      }
                      onClick={() =>
                        field.sortable && setSortBy(field.field_name)
                      }
                    />
                  ))}
                  <span className="stretch-cell" />
                </div>
              </div>
              <div className="vendor-contracts__table-title-indent" />
            </ul>
          </div>
          {contractsData?.contracts?.map((contract) => (
            <ContractRow key={contract.id} contract={contract} />
          ))}
          {isCurrentWorkspaceActive && (
            <div
              className="tw-sticky tw-left-[0px] tw-ml-[0px] tw-flex tw-w-fit tw-cursor-pointer tw-items-center tw-gap-3 tw-px-[7px] tw-py-[6px]"
              onClick={addNewContracts}
              data-testid="add-new-record-button"
            >
              <PiPlusBold className="tw-h-[18px] tw-w-[18px]" />
              <div className="tw-neutral-300 tw-text-sm">Add Contract...</div>
            </div>
          )}
        </div>
      </LoadingWrapper>
    </Table>
  );
}

export default observer(VendorContracts);
