import { http } from "msw";

import { createUpdateMutationResponse } from "../createUpdate";

export const updateHandler = http.put(
  "*/attestations/:id",
  function handler(info) {
    return new Response(JSON.stringify(createUpdateMutationResponse()), {
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
);
