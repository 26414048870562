import { cn, IconButton } from "@themis/ui";
import type { DataGridState } from "@themis/ui-library/components/data-grid/state/data-grid-state";
import type { LocationDescriptorObject, LocationKey } from "history";
import type { ReactElement } from "react";
import { PiCaretRightBold } from "react-icons/pi";
import { useIntl } from "react-intl";
import { NavLink } from "react-router-dom";
import { useLocalStorage } from "usehooks-ts";

import type { RecordTypePath } from "@/api/gen/models";

import { SideBarSections } from "./side-bar-sections";

const IS_OPEN_STORAGE_KEY = "SIDEBAR_OPEN";

export interface SideBarView {
  title: string;
  to: LocationDescriptorObject & { key: LocationKey };
}

export interface SideBarProps {
  dataGridState: DataGridState;
  recordType: RecordTypePath;
  title: string;
  updateDataGridState: (params: Partial<DataGridState>) => void;
  views: SideBarView[];
}

export function SideBar({
  dataGridState,
  recordType,
  title,
  updateDataGridState,
  views,
}: SideBarProps): ReactElement {
  const { formatMessage } = useIntl();

  const [isOpen, setIsOpen] = useLocalStorage(IS_OPEN_STORAGE_KEY, true);

  const handleToggle = () => {
    setIsOpen((_isOpen) => !_isOpen);
  };

  return (
    <div className="tw-relative">
      <IconButton
        className={cn(
          "tw-absolute tw-right-0 tw-top-[10px] tw-rotate-180 tw-transition-all tw-duration-75 active:tw-bg-primary-50 active:tw-text-neutral-500",
          { "tw-right-[-20px] tw-rotate-0  tw-pl-[10px]": !isOpen },
        )}
        color={isOpen ? "transparent" : "primary"}
        size="lg"
        Icon={PiCaretRightBold}
        onClick={handleToggle}
        aria-label={
          isOpen
            ? formatMessage({ defaultMessage: "Collapse sidebar" })
            : formatMessage({ defaultMessage: "Expand sidebar" })
        }
        aria-expanded={isOpen}
      />

      <aside
        className={cn(
          "tw-flex tw-h-full tw-w-[200px] tw-flex-col tw-overflow-y-auto tw-bg-primary-25 tw-transition-all",
          { "tw-w-0": !isOpen },
        )}
      >
        <header className="tw-p-3">
          <h4 className="tw-font-sans tw-text-[1rem] tw-font-semibold tw-text-neutral-500">
            {title}
          </h4>
        </header>

        <nav className="tw-border-0 tw-border-b tw-border-solid tw-border-primary-50">
          {views.map((view) => (
            <NavLink
              key={view.to.key}
              exact
              activeClassName="tw-bg-secondary-300 tw-text-white hover:tw-bg-secondary-300"
              className="tw-flex tw-w-full tw-px-3 tw-py-2 tw-text-primaryDim-300 tw-transition-all tw-duration-100 hover:tw-bg-primary-50 active:tw-bg-secondary-300 active:tw-text-white"
              to={view.to}
            >
              {view.title}
            </NavLink>
          ))}
        </nav>

        <SideBarSections
          recordType={recordType}
          dataGridState={dataGridState}
          updateDataGridState={updateDataGridState}
        />
      </aside>
    </div>
  );
}
