import { observer } from "mobx-react";
import React, { useCallback, useState } from "react";
import Popup from "reactjs-popup";

import { useMainStore } from "@/contexts/Store";

import { ButtonTrigger } from "./components";
import { TableMenuContent } from "./components/TableMenuContent";

interface Props {
  isSubModule?: boolean;
  allowHidingRequiredFields?: boolean;
  preventHidingColumnNames?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onlyIncludeOptions?: any[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resetMenuActions?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectAll?: (...args: any[]) => any;
  selectMode?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setSelectMode?: (...args: any[]) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setShowCheckbox?: (...args: any[]) => any;
}

const TableMenu = ({
  setShowCheckbox,
  selectAll,
  selectMode,
  setSelectMode,
  resetMenuActions,
  isSubModule,
  onlyIncludeOptions,
  allowHidingRequiredFields,
  preventHidingColumnNames,
}: Props) => {
  const mainStore = useMainStore();

  const [showPopup, setShowPopup] = useState(false);
  const [showColumnReorderContent, setShowColumnReorderContent] =
    useState(false);
  const [showSectionReorderContent, setShowSectionReorderContent] =
    useState(false);

  const { isAdmin } = mainStore.context;
  const { isCurrentWorkspaceActive } = mainStore.workspaces;
  const { canManageColumns } = mainStore.userPermissions;
  const hasPermissionToSeeButton =
    (isAdmin || canManageColumns) && isCurrentWorkspaceActive;
  const { menuActions } = mainStore.dynamicTable;
  const showOnlyColumnReorderContent = !isAdmin && canManageColumns;

  // @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
  const handleClick = (item) => {
    mainStore.dynamicTable.setSelectedMenuAction(item);
    switch (item) {
      // @ts-expect-error TS(2339) FIXME: Property 'selectAll' does not exist on type '{ sel... Remove this comment to see the full error message
      case menuActions.selectAll:
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        setShowCheckbox(true);
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        setSelectMode(true);
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        selectAll();
        closeMenu();
        break;
      case menuActions.selectIndividual:
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        setShowCheckbox(true);
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        setSelectMode(true);
        closeMenu();
        break;
      case menuActions.manageColumns:
        setShowColumnReorderContent(true);

        break;
      case menuActions.manageSections:
        setShowSectionReorderContent(true);
        break;
      default:
        break;
    }
  };

  const handlePopup = useCallback(() => {
    if (selectMode) {
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setSelectMode(false);
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      setShowCheckbox(false);
      setShowPopup(false);
      mainStore.dynamicTable.clearSelectedRecordVersionIDs();
      mainStore.dynamicTable.clearSelectedUserIDs();
      // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      resetMenuActions();
    } else {
      setShowPopup(true);
    }
  }, [showPopup, selectMode]);

  const closeMenu = () => {
    setShowColumnReorderContent(false);
    setShowSectionReorderContent(false);
    setShowPopup(false);
  };

  if (selectMode) {
    return (
      <ButtonTrigger
        testId="table-menu-btn-no-popup"
        hasPermissionToSeeButton={hasPermissionToSeeButton}
        showPopup={showPopup}
        inSelectMode={selectMode}
        onClick={handlePopup}
      />
    );
  }

  return (
    <Popup
      position="bottom left"
      className="table-menu"
      open={showPopup}
      onOpen={handlePopup}
      onClose={closeMenu}
      disabled={!hasPermissionToSeeButton}
      trigger={
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content="Table options"
          data-tooltip-place="left"
        >
          <ButtonTrigger
            testId="table-menu-btn-popup"
            // @ts-expect-error TS(2322) FIXME: Type '{ testId: string; isAdmin: boolean; hasPermi... Remove this comment to see the full error message
            isAdmin={isAdmin}
            hasPermissionToSeeButton={hasPermissionToSeeButton}
            canManageColumns={canManageColumns}
            showPopup={showPopup}
            inSelectMode={selectMode}
          />
        </div>
      }
      keepTooltipInside
      arrow={false}
    >
      <TableMenuContent
        allowHidingRequiredFields={allowHidingRequiredFields}
        preventHidingColumnNames={preventHidingColumnNames}
        isSubModule={isSubModule}
        showReorderPopup={
          showOnlyColumnReorderContent ? true : showColumnReorderContent
        }
        showSectionReorderPopup={showSectionReorderContent}
        handleClick={handleClick}
        onlyIncludeOptions={onlyIncludeOptions}
      />
    </Popup>
  );
};

export default observer(TableMenu);
