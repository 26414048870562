import { DotsThree } from "@phosphor-icons/react";
import type { RecordTypePath } from "@themis/api/gen/models/recordTypePath";
import { useToast } from "@themis/ui";
import { ConfirmationDialog } from "@themis/ui-library/components/feedback/dialog/confirmation-dialog/confirmation-dialog";
import { IconButton } from "@themis/ui-library/components/inputs/icon-button/icon-button";
import { DeleteMenuItem } from "@themis/ui-library/components/navigation/delete-menu-item/delete-menu-item";
import { DropdownMenu } from "@themis/ui-library/components/navigation/dropdown-menu/dropdown-menu";
import { MenuItem } from "@themis/ui-library/components/navigation/menu/menu-item/menu-item";
import { useOpen } from "@themis/ui-library/hooks/use-open/use-open";
import type { ReactElement } from "react";
import { useRef } from "react";
import { useIntl } from "react-intl";

import { useDeleteComment } from "@/api/queries/comments/use-delete-comment";
import { useCurrentUser } from "@/api/queries/users";

import type { CommentWithUser } from "../../comment-with-user";
import { useCopyCommentLink } from "../../use-copy-comment-link/use-copy-comment-link";
import { useCreateTaskFromComment } from "../../use-create-task-from-comment/use-create-task-from-comment";

interface CommentActionsProps {
  comment: CommentWithUser;
  recordId: number;
  recordTypePath: RecordTypePath;
  recordName: string;
  onEditClick: () => void;
}

export function CommentActions({
  comment,
  recordId,
  recordTypePath,
  recordName,
  onEditClick,
}: CommentActionsProps): ReactElement {
  const { formatMessage } = useIntl();
  const menuButtonRef = useRef(null);

  const { data: currentUser } = useCurrentUser();
  const toast = useToast();

  const { mutate: deleteComment } = useDeleteComment({
    recordType: recordTypePath,
    recordId,
  });
  const deleteConfirmationState = useOpen();

  const handleCopyLink = useCopyCommentLink({
    commentId: comment.id,
  });

  const handleCreateTask = useCreateTaskFromComment({
    comment,
    recordId,
    recordName,
    recordType: recordTypePath,
  });

  const canEdit = currentUser?.id === comment.author_id && !comment.resolved;
  const canDelete = currentUser?.id === comment.author_id;

  const handleDeleteComment = () => {
    deleteComment(comment.id, {
      onSuccess: () => {
        deleteConfirmationState.close();
        toast({
          content: formatMessage({ defaultMessage: "Comment deleted." }),
          variant: "success",
        });
      },
      onError: () => {
        toast({
          content: formatMessage({
            defaultMessage: "Failed to delete comment.",
          }),
          variant: "error",
        });
      },
    });
  };

  return (
    <>
      <DropdownMenu
        trigger={
          <IconButton ref={menuButtonRef} color="inherit">
            <DotsThree weight="bold" />
          </IconButton>
        }
      >
        {canEdit && (
          <MenuItem onClick={onEditClick}>
            {formatMessage({ defaultMessage: "Edit" })}
          </MenuItem>
        )}

        <MenuItem onClick={handleCopyLink}>
          {formatMessage({ defaultMessage: "Copy link" })}
        </MenuItem>

        <MenuItem onClick={handleCreateTask}>
          {formatMessage({ defaultMessage: "Create task" })}
        </MenuItem>

        {canDelete && (
          <DeleteMenuItem onClick={deleteConfirmationState.open}>
            {formatMessage({ defaultMessage: "Delete" })}
          </DeleteMenuItem>
        )}
      </DropdownMenu>

      <ConfirmationDialog
        title={formatMessage({ defaultMessage: "Delete comment" })}
        open={deleteConfirmationState.isOpen}
        slotProps={{
          confirm: {
            children: formatMessage({ defaultMessage: "Yes, Delete" }),
            color: "error",
          },
        }}
        onConfirm={handleDeleteComment}
        onClose={deleteConfirmationState.close}
      >
        {formatMessage({
          defaultMessage:
            "Are you sure you wish to delete this comment? Actions are non-reversible",
        })}
      </ConfirmationDialog>
    </>
  );
}
